import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, Params, Router } from '@angular/router';
import { Config } from 'protractor';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { profile } from './../../models/onboarding';
import { ErrorService } from './../../services/error.service';
import { cSessionService } from './../../services/session.service';
import { UtilsService } from './../../services/utils.service';

import { FtCreateVideoManagerService } from './../../Managers/ft-create-video-manager.service';

import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';

@Component({
  selector: 'app-ft-create-video-wizard-view',
  templateUrl: './ft-create-video-wizard-view.component.html',
  styleUrls: ['./ft-create-video-wizard-view.component.scss']
})
export class FtCreateVideoWizardViewComponent implements OnInit, OnDestroy {
  showNext: boolean = false;
  showPrevious: boolean = false;
  sUserEmail = "";
  sSessionId: any;
  showCancel: boolean = false;
  showPreview: boolean = false;
  showSave: boolean = false;
  breadcrumActive: boolean = false;
  ftLogin: boolean = false;
  saveButtonTitle: string = 'Generate';
  constructor(public videoManger: FtCreateVideoManagerService, private oSessionService: cSessionService, public router: Router, public onboardinManger: OnboardingManagerService, private location: Location, private config: ConfigManagerService, public errorService: ErrorService
    , private Utils_Service: UtilsService, private activatedRoute: ActivatedRoute) {

      this.oSessionService.cSessionService_SetGuestSession('');
      this.oSessionService.cSessionService_SetGuestEmail('');
      this.oSessionService.cSessionService_SetUserEmail('');

  }

  sub: Subscription;


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log("URL param",params);
      this.sUserEmail = params['email'];
      console.log("suerEmail", this.sUserEmail);
      if(this.sUserEmail !="" && this.router.url.includes('/ft/overview')){
        this.getStarted();
  
      }else
      {
        this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();
          
        if(this.sUserEmail === '')
        {
this.router.navigate(['ft/login']);
        }
      }
       
  
     
  });
    this.UpdateMenu();
    this.sub = this.router.events.subscribe((event: Event) => {
      this.UpdateMenu();
    })

  }

  getStarted() {

    this.videoManger.guestUser(this.sUserEmail).then(res => {

if(res['errorMessage'])
{
  this.errorService.handleError(res['errorMessage'],"Error",true);
  this.router.navigate(['ft/login']);
}else{

      this.sSessionId = res['guest_session_id'];

      this.oSessionService.cSessionService_SetGuestEmail(this.sUserEmail);
      this.oSessionService.cSessionService_SetGuestSession(this.sSessionId);

      console.log("gemailover", this.sUserEmail);
      console.log("gSessover", this.sSessionId);


      console.log("Started1");

      if (this.sSessionId !== '' && this.sUserEmail !== '') {
        console.log("Started2");

        if (!this.onboardinManger.profile) {
          console.log("Started3");

          this.getProfile(this.sUserEmail, this.sSessionId);
        } else if (this.config.Templates.length === 0) {
          console.log("Started4");
          this.config.getTemplates2(this.sUserEmail, this.sSessionId, this.onboardinManger.profile.allowed_templates);
        }
      }
    }}
    ).catch(err => {

      });



  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async getProfile(email, session) {
    console.log("onbtop");
    this.config.getVoices(this.sUserEmail, this.sSessionId);

    await this.onboardinManger.getUserProfile(email, session).then(async (res: profile) => {

      console.log("onb", res);

      await this.config.getTemplates(email, session, res.allowed_templates);
    });

    await this.onboardinManger.getUserVideosCount(email, session).then(count => {
    });

    console.log("Started4");

  }
  private UpdateMenu() {
    console.log("isrouter" , this.router.isActive('/ft/overview', false));

    console.log(this.router.url);

    if (this.router.url.includes('/ft/login')) {
      this.breadcrumActive = false;
      this.showSave = false;
      this.showCancel = false;
      this.showPrevious = false;
      this.videoManger.isEditMode = false; 
      if (!this.videoManger.isEditMode) {
        this.ftLogin = true;
        this.showNext = false;
        this.showCancel = false;
      }
    }
    if (this.router.url.includes('/ft/overview')) {
      this.breadcrumActive = true;
      this.ftLogin = false;
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.ftLogin = false;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.ftLogin = false;
      }
      this.saveButtonTitle = 'Generate';
    } else if (this.router.url.includes('/ft/looknfeel')) {
      this.breadcrumActive = true;
      this.ftLogin = false;
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = true;
        this.ftLogin = false;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.ftLogin = false;
      }
    } else if (this.router.url.includes('/ft/content')) {
      this.breadcrumActive = true;
      this.ftLogin = false;
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = true;
        this.ftLogin = false;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.ftLogin = false;
      }

    } else if (this.router.url.includes('/ft/other-setting')) {
      this.breadcrumActive = true;
      this.ftLogin = false;
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = true;
        this.showPrevious = true;
        this.ftLogin = false;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.ftLogin = false;
      }
    } else if (this.router.url.includes('/ft/preview')) {

      this.breadcrumActive = false;
      this.ftLogin = false;
      if (!this.videoManger.isEditMode) {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = false;
        this.saveButtonTitle = 'Back';
        this.ftLogin = false;

      }
      else {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.saveButtonTitle = 'Back';
        this.ftLogin = false;
      }
    }
  }
  back() {
    this.location.back();

  }
  next() {
    this.videoManger.submitForm.emit();
    this.UpdateMenu();
  }

  save() {

    this.videoManger.submitForm.emit("save");;
    this.UpdateMenu();
  }

  previous() {

    this.videoManger.previousSubmit.emit();
    this.UpdateMenu();
  }
  cancel() {
    console.log('cancel');
    this.UpdateMenu();
    this.router.navigate(['/login'])
      .then(() => {
        window.location.reload();
      });

  }




}

