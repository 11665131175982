import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';
import { cSessionService } from './session.service';



@Injectable(
  {
    providedIn: 'root'
  })

export class cClientService {
  constructor(private oHttpClient: HttpClient, private oRouter: Router,private oSessionService: cSessionService) { }

  ClientService_NavigateByURL(sURL: string | UrlTree) {
    return this.oRouter.navigateByUrl(sURL)
  }
  //config
  ClientService_GetConfigurationFileURL(sRoute: string) {
    return this.oHttpClient.get(environment.API_HOST + sRoute)
  }

  ClientService_PostUploadedVisuals(sRoute: string, oData: any) {
    return this.oHttpClient.post(environment.API_HOST + sRoute, oData);
  }
  //getUploadedVoiceIver(f_path)
  ClientService_PostUploadedVoiceOverFile(sRoute: string, oData: any) {
    return this.oHttpClient.post(environment.API_HOST + sRoute, oData);
  }
  //bulkUploadSignedUrl
  ClientService_GetSignedUrl(sRoute: string, oData: any) {
    console.log("ClientService_GetSignedUrl : sRoute :",sRoute);
    console.log("ClientService_GetSignedUrl : oData :",oData);
    return this.oHttpClient.post(environment.API_HOST + sRoute, oData);
  }

  ClientService_GetUploadedVisuals(oData: string) {
    return this.oHttpClient.get(oData);
  }


  ClientService_GetFile(url: string) {
    return this.oHttpClient.get(url, { responseType: 'blob' });
  }

  ClientService_PostYoutubeUpload(sRoute: string, oData: any, email: string, sessionId: string)
  {
    let params = new HttpParams();
    params = params.set('email', email);
    params = params.set('session_id', sessionId);
    return this.oHttpClient.post(environment.API_HOST + sRoute, oData, { 'params': params });
  }
  ClientService_PostHubspotUpload(url: string) {
    return this.oHttpClient.post<any>(url,"");
  }
  ClientService_GetPages(url: string){
    return this.oHttpClient.get<any>(url);
  }
  ClientService_PostVideoToFacebookPage(url: string, body: any){
    return this.oHttpClient.post<any>(url, JSON.stringify(body));
  }
  ClientService_PostVideoToInstagramPage(url: string, body: any){
    return this.oHttpClient.post<any>(url, JSON.stringify(body));
  }
  // get signed url
  ClientService_PostFilePathForSignedURL(sRoute, oData) {
    var formData: any = new FormData();
    let userSessionId = this.oSessionService.SessionService_GetSessionId();
    let userEmail = this.oSessionService.cSessionService_GetUserEmail();

    formData.append('session_id', userSessionId);
    formData.append('email', userEmail);
    formData.append('fpath', oData);
    return this.oHttpClient.post(environment.API_HOST + sRoute, formData);
  }
   // get user visual thumbnail
   ClientService_GetThumbnail(sRoute, oData) {
    var formData: any = new FormData();
    let userSessionId = this.oSessionService.SessionService_GetSessionId();
    let userEmail = this.oSessionService.cSessionService_GetUserEmail();

    formData.append('session_id', userSessionId);
    formData.append('email', userEmail);
    formData.append('visual_id', oData);
    return this.oHttpClient.post(environment.API_HOST + sRoute, formData);
  }
    // get public file signed url
    ClientService_PostPublicFilePathForSignedURL(sRoute, oData) {
    var formData: any = new FormData();
    formData.append('fpath', oData);
    return this.oHttpClient.post(environment.API_HOST + sRoute, formData);
  }


 getMetaInfo() {
    return this.oHttpClient.get(environment.API_HOST + 'meta_info');
  }

  getVideoTemplates(email,session_id) {
    return this.oHttpClient.get(environment.API_HOST + 'video_templates?email='+email+'&session_id='+session_id).toPromise();
  }

  getTemplates() {
    return this.oHttpClient.get(environment.API_HOST + 'template_meta_data').toPromise();
  }

  getThumbBatchSignedURL(email,session_id,urls:string[])
  {

 let formData = new FormData();
  formData.append('session_id', session_id);
  formData.append('email', email);
  formData.append('fpaths', JSON.stringify(urls));
  return this.oHttpClient.post(environment.API_HOST + 'signed_url_multi_download', {session_id:session_id, email:email, fpaths:urls}).toPromise();
  }
}



