import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer/public-api";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { cSessionService } from "src/app/services/session.service";
import { brand, brandList, MediaLibrary, SearchData, user, UserList } from "src/app/models/admin";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { Userservice } from "../../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { mediaLib } from "src/app/models/media";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { AdminMediaLibraryManagerService } from "src/app/Managers/adminManager/admin-media-library-manager.service";
import { ErrorService } from "src/app/services/error.service";
import { BrandService } from "../service/BrandService";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "app-manage-brand-media-libs",
  templateUrl: "./brand-media-libs.component.html",
  styleUrls: ["./brand-media-libs.component.scss"],
})
export class BrandMediaLibsComponent implements OnInit {

  brandDetail: any = [];
  brandId : string;
  brandMediaLibList: any =[];
  placementUser: NzDrawerPlacement = "right";
  selectedMedia : any;

  showSearchBox = false;
  listPages: number[] = [];
  searchTerm = "";
  searchTermText = "";
  showSortByDD = false;
  searchBy = "created";

  limit = 10;
  offset = 0;
  currentPage = 1;
  totalPages = 1;
  oTotalCount: any;

  sessionId: string;
  email: string;
  isDeleteModalVisible = false;
  sortType = -1;

  sSessionId: string;
  sUserEmail: string;
  modelUserSearchChanged = new Subject<string>();
  userListLoopLimit = 3;
  userList: any = [];
  isUserSearchLoading: boolean = false;
  selectedAddUsers: user[] = [];
  placementMediaLibraryAdmin: NzDrawerPlacement = "right";
  showAddMediaLibraryBrand = false;
  addMediaFormSubmitted = false;
  addMediaLibraryAdminForm: FormGroup;
  showAssignUserDropDown = true;
  Access_Control = {
    global : "global_access",
    limited : "limited_access",
  }

  @ViewChild('mediaLibraryListAutoComplete', { read: MatAutocompleteTrigger })
    autoCompleteUser: MatAutocompleteTrigger;

  constructor(
    public oSessionService: cSessionService,
    private adminBrandManager: AdminBrandManagerService,
    public router: Router,private route: ActivatedRoute,  private successMessageService: SuccessMessageModalService, public userService : Userservice,
    public mediaManager: MediaManagerService,
    private addMediaLibraryAdminFormBuilder: FormBuilder,
    private mediaLibraryManager: AdminMediaLibraryManagerService,
    public errorService: ErrorService,
    public BrandManagerService:BrandService,
    public _MatPaginatorIntl: MatPaginatorIntl
  ) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Manage Brands");
  }

  ngAfterViewInit(): void {
    // TODO autocomplete Brands change trigger
    this.modelUserSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          if (value.length > 1) {
            this.userList = [];
            this.userList = await this.userService
              .searchUser(
                this.offset,
                this.limit,
                "created",
                -1,
                "_id",
                value,

              )
              .then((resList) => {
                return (this.userList = resList);
              });

            this.isUserSearchLoading = false;
            return this.userList;
          }
        } else {
          return [];
        }
      });
  }
  populatePagination() {
    if (this.oTotalCount && this.oTotalCount > 0) {
      const maxPage = Math.ceil(this.oTotalCount / this.limit) || 1;
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
      } else {
        this.listPages = [1];
      }
    }
  }

  loadPage(page: number)  {
    if( page < 1 ) {
      page = 1;
    } else {
      if( page > this.listPages?.length ) {
        page = 1;
      }
      this.brandMediaLibList = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.reloadBrandMediaLibList();
    }
  }

  loadPageEvent($event){
    if($event.pageSize != this.limit){
      this.limit = $event.pageSize;
      this.loadPage(1);
    } else {
      this.loadPage($event.pageIndex + 1);
    }
  }
  ngOnInit(): void {

    this._MatPaginatorIntl.firstPageLabel = '';
    this._MatPaginatorIntl.lastPageLabel = '';
    this._MatPaginatorIntl.nextPageLabel = '';
    this._MatPaginatorIntl.previousPageLabel = '';

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.brandId = this.route.snapshot.params["id"];

    this.getBrandMediaLibList();
    this.getBrandProfile();
    window.addEventListener('scroll', this.scrollEvent, true);

  }

  scrollEvent = (event: any): void => {
    if(this.autoCompleteUser && this.autoCompleteUser.panelOpen){
      this.autoCompleteUser.updatePosition();
    }
  }

  getBrandProfile() {
    let __this = this;
    this.adminBrandManager
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {
        __this.brandDetail = brand;
      });
  }

  getBrandMediaLibList() {
    let __this = this;
    this.adminBrandManager
      .getBrandMediaLibList(this.brandId, 10, 0)
      .then((brandlib: brand) => {
        __this.brandMediaLibList = brandlib['result'];
        this.oTotalCount = brandlib['count'];
        __this.populatePagination();
        console.log("check11",brandlib.result );
      });
  }



  loadBrandMediaLib(mediaLib: any) {

    this.userService.selectedMediaId = [mediaLib._id.$oid];
        this.mediaManager.selectedmedialib = mediaLib;
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/media-libraries/${mediaLib._id.$oid}`]);
    // console.log("blist-id", id);
    // this.router.navigate(["/account-setting/manage-brand/account", id.$oid]);
  }


  searchBrand() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.reloadBrandMediaLibList();
  }

  clearSearchTerm() {
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.reloadBrandMediaLibList();
  }

  sortBySearchResult(value) {
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.sortType = -1;
    this.reloadBrandMediaLibList();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }

  }



  handleCancel(){
    this.isDeleteModalVisible = false;
  }


  sortTypeSearch(val : number){
    this.sortType = val;
    this.reloadBrandMediaLibList();
  }


  loadBrandMediaLibraryList() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/list-media-libraries`]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/media-libraries`]);
  }
  loadBrandBusiness() {
    this.router.navigate([`/account-setting/manage-brand/${this.brandId}/business-details`]);
  }
  loadBrandAccount() {
    this.router.navigate([`/account-setting/manage-brand/account/${this.brandId}`]);
  }

  openAddMediaLibraryBrand(): void {
    this.addMediaFormSubmitted = false;
    this.showAssignUserDropDown = true;
    this.addMediaLibraryAdminForm = this.addMediaLibraryAdminFormBuilder.group({
      name: "",
      activation_status: true,
      asset_source: "",
      access: this.Access_Control.limited,
      searchUserText: "",
      user_id: "",
    });
    this.showAddMediaLibraryBrand = true;
  }
  closeAddMediaLibraryBrand(): void {
    this.showAddMediaLibraryBrand = false;
  }
  changeAccessLimit (value){
    console.log(value);
    if(value == this.Access_Control.global){
      this.showAssignUserDropDown = false;
    } else {
      this.showAssignUserDropDown = true;
    }
  }
  setLibraryAccess(incomingLibraryAccess: string) {
    this.addMediaLibraryAdminForm
      .get("library_access")
      .setValue(incomingLibraryAccess);
    console.log("incomingLibraryAccess", incomingLibraryAccess);
  }
  setAssetSource(incomingAssetSource: string) {
    this.addMediaLibraryAdminForm
      .get("asset_source")
      .setValue(incomingAssetSource);
    console.log("assetSource", incomingAssetSource);
  }

  async addNewMediaLibraryBrand(): Promise<any> {
    this.addMediaFormSubmitted = true;

    if (this.addMediaLibraryAdminForm.invalid) {
      // TODO show Error notification
    } else if ( this.showAssignUserDropDown && (!this.selectedAddUsers || this.selectedAddUsers.length === 0) ) {
      this.errorService.handleError('Please assign user to media library', 'Add Media Library', true);
    } else {
      const accessControl = this.addMediaLibraryAdminForm.controls["access"].value;
      // TODO add brand
      const mediaLibraryInfo: MediaLibrary = {
        name: this.addMediaLibraryAdminForm.controls["name"].value,
        activation_status: this.addMediaLibraryAdminForm.controls["activation_status"].value,
        asset_source: this.addMediaLibraryAdminForm.controls["asset_source"].value,
        library_access: (accessControl == this.Access_Control.global ) ? 'global' : 'limited',
        user_id: this.sUserEmail,
        owner_type :'brand',
        brand_id : this.brandId
      };
      await this.mediaLibraryManager
        .addMediaLibray(mediaLibraryInfo)
        .then((libRes: any) => {
          this.addUserMediaLibrary(libRes.id.$oid);
          this.addBrandMediaLibrary(libRes.id.$oid);
        });
      this.showAddMediaLibraryBrand = false;
    }
  }
  addUserMediaLibrary(mediaLibraryId: any) {
    let userIds: string[] = this.selectedAddUsers.map((item) => {
      return item._id;
    });
    this.mediaLibraryManager
      .addUserMediaLibrary([mediaLibraryId], userIds)
      .then((res) => {});
  }

  addBrandMediaLibrary(mediaLibraryId: any) {
    this.BrandManagerService
      .addBrandMediaLibrary([mediaLibraryId], this.brandId)
      .then((res) => {
        this.getBrandMediaLibList();
      });
  }

  setAddUsers(user: user): void {
    const pos = this.selectedAddUsers.some((item) => {
      return item._id == user._id;
    });

    if (!pos) {
      this.selectedAddUsers.push(user);
    }
    this.addMediaLibraryAdminForm.controls["searchUserText"].setValue("");
  }

  removeAddUsers(user: user): void {
    var indexBrand = this.selectedAddUsers.indexOf(user);
    if (indexBrand > -1) {
      this.selectedAddUsers.splice(indexBrand, 1);
    }
  }

  viewAllSelectedUsers(val) {
    this.userListLoopLimit = val;
  }


  reloadBrandMediaLibList() {
    let __this = this;
    const search : SearchData = {
      brand_id : this.brandId,
      offset: this.offset,
      limit: this.limit,
      asc: this.sortType,
      search_term : this.searchTerm,
      search_by : "name",
      sort_by : this.searchBy
    }

    this.adminBrandManager
      .searchBrandMediaLibList(search)
      .then((brandlib: brand) => {
        __this.brandMediaLibList = brandlib['result'];
        this.oTotalCount = brandlib['count'];
        // __this.populatePagination();
        // console.log("check11",brandlib.result );
      });
  }

  deleteBrandMediaLibrary(){
    const bodyPost = {
      brand_id : this.brandId,
      media_lib_id : this.selectedMedia._id.$oid
    }
    this.BrandManagerService.deleteBrandMediaLibrary(bodyPost).then( (response) => {
      this.isDeleteModalVisible = false;
      if(response){

        const indexRecrod = this.brandMediaLibList?.findIndex(
          (item : any) => item?._id?.$oid == this.selectedMedia._id.$oid
        );

        if (indexRecrod > -1) {
          this.successMessageService.display("Media Library successfully deleted.", "Delete Media Library", true,true);
          this.brandMediaLibList.splice(indexRecrod, 1);
        }
      }
    }).catch( (err) =>{
      this.isDeleteModalVisible = false;
      this.errorService.handleError('Could not remove media library, Try again.', 'Delete Media Library', true);
    });
  }
  // searchBrand() {
  //   this.offset = 0;
  //   this.searchTerm = this.searchTermText;
  //   this.getBrandMediaLibList();
  // }

  // clearSearchTerm() {
  //   this.offset = 0;
  //   this.searchTerm = "";
  //   this.searchTermText = "";
  //   this.getBrandMediaLibList();
  // }
}
