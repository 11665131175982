import { AppRoutingModule } from "../app-routing.module";
import { locale } from "./account";
import { address } from "./common";

export class video
{
    
    email:string;
    session_id:string;
    fpath: string;
    public_url: string;
    comments: string[];
    user_clip_id:string='';
    likes: number=0;
    views: number= 0;
    publish_status : string;
    about: string;
    aspect_ratio: string;
    font_color: string;
    template_category: string;
    template_info: template_info;
    user_images: string[];
    user_clips: [];
    ci_dict:ci_dict;
    cta: [];
    use_pronto_clips: boolean=true;
    bucket_name: string;
    title: string;
    duration: number;
    key_terms: [];
    text_list : string[];
    clips?: string[];
    address:address;
    paths_dict:{ [key: string]: string };
    music_file: string;
    music_file_url?: string;
    created: string;
    days_ago: number;
    thumb: string;
    color: string;
    logo_img:string;
    second_logo_img:string;
    automated: boolean;
    template_name: string;
    locales: locale[];
    location_clips: [];
    locale_text_list: [];
    location_videos: [];
    vo_text:string;
    vo_text_file:string;
    selected_voice: string;
    voice_file:string;
    voice_file_url?: string;
    block_config: block_config[];
    url?:string;
    video_url?:string;
    phone?:string;
    schedule?: any;
    scheduleVideos?:video[];
    thumbnail?:string;
    loadingUrl:boolean;
    //block_clips:block_clips[];
    block_texts:block_texts[]=[];
    loadingThumbUrl?:boolean;
    is_hide_duration:boolean = false;
 
    watermark:boolean =false;
    block_clips:block_clips[];
    AllLocaleSelected:boolean =false;
    isLocationVideo:boolean =false;
    videoIndex?:any=0.3;
    loadingThumbnail?: string;
    thumbnail_path?: string;
 
}

export class template_info
{
    contents:content[];
    transforms:any;
}

export class content
{
    content:string;
    id:string;
    pos:number[];
    size:number[];
    text:string;
    font:string;
    source:string;
    stretch:string;
    ts:number[];
    parent?:any;
    url:string;
    thumbnail:string;
    loadingUrl:boolean;
    loadingThumbnail:boolean;

}
export class block_clips
{
    isSelected:boolean = false;
    clips:string[];
editclips:clip[];
loadingUrl :boolean = false;
isDisable:boolean = false;

}
export class clip
{
clip:string;
url:string;
thumbnail:string;
thumb:string;
ts:number[];
}
export class block_texts
{
    isDisable:boolean = false;
    no_texts:number;
    texts:string[]= [];

}
export class ci_dict
{
    url:string;
    address: address

}
export class block_config
{
    effects: effect[];
    block_args:  block_args;
id: string ="blockID";
    clip_idx:[];
    block_file_path:string;
    text_idx:[];
    loadingUrl?:boolean =false;
    loadingThumbnail?:boolean=false;
    thumbnail?:string;
    thumb?:string;
    url?:string;
    block_name:string;
video_gen:video_gen;


}

export class video_gen{
    content_info: content_info;
  
    count: number;
    duration: number;
    transform_info: any;
}
export class content_info
{
     clips:clip;
     content:any;
     images:any;

}
export class voice{
cps: number;
gender:string;
sample_voice:string;
voice_name: string;
name:string;

}
export class block_args
{
    num_clips: number;
    clip_pos: [];
    clip_trans: [];
 block_gen:any;
video_gen:video_gen;
    clip_times: [];
    clip_dims: [];
    num_texts: number;
    text_pos: [];
    text_times: [];
    text_titles: [];
    duration: number;
    fps: Number;
    clip_effects :effect[]
    
 }

export class effect
{
    effect_name:string;
    effect_args :effect_args;

}

export class effect_args
{
    font_size: number;
    color: string;
    font: string;
    theme: string;
    vh: number;
    vw: number;
    pos: []
    fps: number
}


export class scheduledVideos
{
    public date:string;
    public videos:video[]=[];
    public isActive: boolean = false;
}

export class duration
{
    id: string;
    public duration:number;
    public num_text:number;
    public pos:number[];
    public size:number[];
    public textlist:textinput[]=[];
    public placehoder:string;
}

export class textinput
{
    public placeholder:string;
    public value:string;
   
}