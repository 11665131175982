<div class="p-4 border-right">
    <div class="d-flex">
        <div class="formItm search-icon">
            <input id="loginEmail" type="email" value="" placeholder="Email Address"
                required>
            <label for="loginEmail" class="text-sm">Search videos</label>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                    fill="#A39FAA" />
            </svg>
        </div>
    </div>
    <div class="sidebar-textblock">
        <div class="custom-switch ">
            <input class="form-check-input ms-0 location" type="checkbox"
                id="Videos-check" checked="">
            <label for="Videos-check" class="text-sm text-gray-3 ps-1">All
                Videos</label>
        </div>
    </div>
    <p class="text-gray-2 text-xl">Top Videos</p>
    <div class="video-listed">
        <div id="container-view">
            <ul class="list p-0 m-0">
                <li *ngFor="let item of lTopVideos; let i = index"> 
                    <div class="video-outer" [class.active]="i == 0">
                        <div
                            class="video_wrapper video_wrapper_full js-videoWrapper">
                            <iframe class="videoIframe js-videoIframe"
                                src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen=""></iframe>
                            <figure class="video-baner"><img
                                    src="../assets/images/myVideos/v1.png"
                                    alt="video1"> </figure>
                            <button class="videoPoster js-videoPoster"></button>
                        </div>
                        <a href="../dashboardVideo/dashboardvideo_Overview.html"
                            class="text-sm p-3 m-0 text-gray-2 d-block float-none">Family
                            chiropractic</a>
                        <span class="video-view">Views <b>675</b></span>
                    </div>
                    <hr>
                </li>
            </ul>
        </div>
    </div>
</div>