
<nav style="position: relative;">
    <div class="sidebar-header ps-3 pb-3" style="padding: 3px 20px">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">
            Edit Background Music
            <p class="mb-0 mt-1 text-xs text-gray-3">Hover to preview a sound, and select to add it to your video.</p>
        </h3>
        <div class="closeRightSideBar pe-2" style="top: 0;"  (click)="closeAudio()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="side-content flex-grow-1 pt-0">
        <div class="replaceVideoTop">
            <div class="replaceVideoTopAction d-flex justify-content-between align-items-center">
                <div class="radio_select_wrapp2">
                    <label class="radio_select" *ngFor="let option of lBreadcrumbOptions ; let i = index;"><input type="radio" name="filterEditClip1"
                            class="radio_input_element" [checked]="option.filter==oVisualType" (click)="changeTab(option.filter)">
                        <div class="radio_caption"><span>{{option.name}}</span></div>
                    </label>
                    
                </div>
                <div>
                    <button class="ms-2" data-bs-toggle="modal" data-bs-target="#uploadModal">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3.68314 5.28489C3.86918 5.48953 4.17083 5.48953 4.35687 5.28489L7.5109 1.81579L7.51281 9.30267C7.51288 9.57922 7.73715 9.80335 8.01373 9.80326C8.29031 9.80318 8.51446 9.57891 8.51439 9.30236L8.51247 1.81592L11.6664 5.28489C11.8524 5.48953 12.1541 5.48953 12.3401 5.28489C12.5262 5.08026 12.5262 4.74848 12.3401 4.54385L8.34849 0.153475C8.16245 -0.0511582 7.8608 -0.0511584 7.67476 0.153475L3.68314 4.54385C3.49709 4.74848 3.49709 5.08026 3.68314 5.28489ZM1.00157 7.23702C1.00157 6.96047 0.777364 6.73628 0.500787 6.73628C0.22421 6.73628 0 6.96047 0 7.23702V8.48879C0 12.6371 3.36316 16 7.51181 16H8.51538C12.664 16 16.0272 12.6371 16.0272 8.48879V7.23702C16.0272 6.96047 15.803 6.73628 15.5264 6.73628C15.2498 6.73628 15.0256 6.96047 15.0256 7.23702V8.48879C15.0256 12.084 12.1109 14.9985 8.51538 14.9985H7.51181C3.91631 14.9985 1.00157 12.084 1.00157 8.48879V7.23702Z"
                                fill="#7A7A7A" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="">
            <div class="row row-cols-2 replaceVideoRow pronto-asset-audio" *ngIf="!this.bTurnOff">
                <div *ngFor="let visual of  this.lUserVisualsList; let i = index" style="position: relative;">
                    <div class="audioCard">
                        <div class="audioCardTop">
                            <button class="muteBtn" (mouseover)="AudioSidePanelComponent_PlayAudio(visual.url)" (mouseout)="AudioSidePanelComponent_StopAudio(visual.url)">
                                <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4.55469 3.33032C4.55469 2.01822 5.64358 0.955322 6.98779 0.955322C8.332 0.955322 9.4209 2.01822 9.4209 3.33032V6.08032C9.4209 7.39243 8.332 8.45532 6.98779 8.45532C5.64358 8.45532 4.55469 7.39243 4.55469 6.08032V3.33032ZM6.98779 1.70532C6.06793 1.70532 5.32304 2.43243 5.32304 3.33032V6.08032C5.32304 6.97822 6.06793 7.70532 6.98779 7.70532C7.90765 7.70532 8.65255 6.97822 8.65255 6.08032V3.33032C8.65255 2.43243 7.90765 1.70532 6.98779 1.70532Z"
                                        fill="#FF8369" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.06911 4.78027C3.28128 4.78027 3.45329 4.94817 3.45329 5.15527V6.00527C3.45329 7.90817 5.03824 9.45527 6.98769 9.45527C8.93714 9.45527 10.5221 7.90817 10.5221 6.00527V5.15527C10.5221 4.94817 10.6941 4.78027 10.9063 4.78027C11.1184 4.78027 11.2904 4.94817 11.2904 5.15527V6.00527C11.2904 8.32238 9.36148 10.2053 6.98769 10.2053C4.61389 10.2053 2.68494 8.32238 2.68494 6.00527V5.15527C2.68494 4.94817 2.85694 4.78027 3.06911 4.78027Z"
                                        fill="#FF8369" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.56751 3.89746C7.19189 3.76302 6.78363 3.76302 6.40801 3.89746C6.20881 3.96876 5.98811 3.86892 5.91507 3.67448C5.84202 3.48003 5.9443 3.2646 6.1435 3.1933C6.6899 2.99774 7.28562 2.99774 7.83202 3.1933C8.03122 3.2646 8.1335 3.48003 8.06046 3.67448C7.98741 3.86892 7.76672 3.96876 7.56751 3.89746Z"
                                        fill="#FF8369" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.30457 4.9678C7.0974 4.91438 6.88329 4.91438 6.67611 4.9678C6.47097 5.02069 6.26075 4.90124 6.20656 4.70101C6.15237 4.50077 6.27474 4.29556 6.47988 4.24267C6.81567 4.15609 7.16501 4.15609 7.5008 4.24267C7.70594 4.29556 7.82831 4.50077 7.77412 4.70101C7.71994 4.90124 7.50971 5.02069 7.30457 4.9678Z"
                                        fill="#FF8369" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.98769 9.45532C7.19986 9.45532 7.37186 9.62322 7.37186 9.83032V11.3303C7.37186 11.5374 7.19986 11.7053 6.98769 11.7053C6.77552 11.7053 6.60352 11.5374 6.60352 11.3303V9.83032C6.60352 9.62322 6.77552 9.45532 6.98769 9.45532Z"
                                        fill="#FF8369" />
                                </svg>
                            </button>
                            <div class="audioCardTopWave"  (mouseover)="AudioSidePanelComponent_PlayAudio(visual.url)" (mouseout)="AudioSidePanelComponent_StopAudio(visual.url)">
                                <img src="../assets/images/audioWave.png" alt="audioWave" />
                            </div>
                        </div>
                        <div class="audioCardBody">
                            <p class="text-xs m-0 text-gray-2">Open your<br>{{visual?.name | slice:0:22}}</p>
                            <p *ngIf="visual?.name === ''" class="text-xs m-0 text-gray-2">Audio file.</p>
                        </div>
                        <div class="audioCardFooter">
                            <p class="m-0 text-xxs text-gray-6">00.{{visual.duration}}</p>
                        </div>
                    </div>
                    <div class="custom-radiobtn">
                        <label>
                            <input type="radio" class="check form-check-input radioButton" [checked]="visual.isSelected" (click)="changeVisual(i,visual)">
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pagination -->
    <ul id="pagination" class="ps-3">
        <li>
            <a href="javascript:void(0)" class="preview-page" (click)="nCurrentPage==1?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage-1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
        </li>
        <li *ngFor="let page of lPagination;index as i">
            <a href="javascript:void(0)" (click)="MediaLibrarySharedComponent_FetchPage(page)" [ngClass]="i+1==nCurrentPage?'add_color':''"
            >{{page}}</a>
        </li>
        <li>
            <a href="javascript:void(0)" class="next-page" (click)="nCurrentPage==lPagination.length?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage+1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                        fill="white" />
                </svg>
            </a>
        </li>

    </ul>
    <div class="side-footer side-content d-flex align-items-center">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSideBar" (click)="AudioSidePanelComponent_SaveMusicFileForVideo()">Replace</button>
        <button *ngIf="bSavedNotClicked" class="d-inline-block small-pad text-gray-3 font-weight-noral text-md ms-3" (click)="AudioSidePanelComponent_RevertMusicFile()">
            Revert to original
        </button>
    </div>


</nav>

<!-- Modal pop-up Start-->
<div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Video, Image or Audio File</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Device)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span *ngIf="sUploadedVoiceOverFileName" class="file-message">{{sUploadedVoiceOverFileName}} file selected</span> 
                                <input #file class="file-input" type="file" accept="audio/mp3" (change)="ContentComponent_UploadedVoiceOverFile($event)">
                            </div>
                        </div>
                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link"  (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-device" type="button" role="tab"
                                    aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->