import { Injectable, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { brand, MediaLibrary, UserProfile } from "src/app/models/admin";
import { cSessionService } from "src/app/services/session.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService  {

  hideDropDownOnDocumentClick : Subject<boolean> = new Subject();
  selectedMediaLibrary : MediaLibrary;
  constructor(public oSessionService: cSessionService) {
  }

  hideAllDropDown() {
    this.hideDropDownOnDocumentClick.next(true);
  }
  dropDownHideSubscribe() {
    return this.hideDropDownOnDocumentClick;
  }

  setHeaderBreadCrumps(currentPage: string , object : any = undefined) {
    this.oSessionService.headerBreadCrumps.currentPage= currentPage;
    switch (currentPage) {
      case "brand":
        this.oSessionService.setAccountSettingHeaderName("Manage Brands");
        this.oSessionService.setHeaderBreadcrumps("brandDetail", object);
        break;
      case "user":
        this.oSessionService.setAccountSettingHeaderName("Users");
        this.oSessionService.setHeaderBreadcrumps("userProfile", object);
        break;
      case "library":
        this.oSessionService.setAccountSettingHeaderName("Media Libraries");
        this.oSessionService.setHeaderBreadcrumps("mediaLibraryProfile", object);
        break;
    }
  }
}