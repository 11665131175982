
<div    [ngClass]="{
    'px-4': !isSlideout,
    'py-5':  !isSlideout }">
<h5 class="modal-title  mb-3"  *ngIf="!isSlideout" id="exampleModalLabel">Select from My Media Library</h5>
<div class="dropdown dropdownBtn" *ngIf="!bHideMediaLibrarySearchOptions || !isSlideout">
    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="ms-2 dropdownBtnText">All Media Libraries</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end dropdownMenu rounded-4 mt-1 w-100" id="menu-set">
        <div class="dropdown-item ">
            <div class="formItm search-icon searchDropdown">
                <form [formGroup]="fSearchLibraryForm">
                <input id="search-feild" type="search" formControlName="name" placeholder="Search your video" required="">
                <label for="search-feild" class="text-sm">Search media library</label>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z" fill="#A39FAA"></path>
                </svg>
                </form>
            </div>
        
            <div class=" d-flex row py-2">
                <div class="col-lg-3"  *ngFor="let media of this.mediaManager.mediaLibs" style="    height: 55px; width:33% !important;">
                    <label class="customRadio mb-2" style="display: inline-grid; grid-template-columns: 15px 1fr;">
                        <input type="radio" name="libraryAccessRadio" [checked]="media.name==sSelectedMediaLibrary" (change)="MediaLibrarySharedComponent_ChangeMedialibrary($event,media)" [value]="media.name">
                        <span class="customRadioCheck"></span>
                        <span class="customRadioText text-sm text-wrap"  style="word-break: break-all !important;">{{media.name }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container mx-0 px-0 business-media" id="container-view">
    <div class="row filter-post"  *ngIf="!this.mediaPopUpLoc">
        <div class="col-md-12 p-0">
            <ul class="breadcrumb-tabs nav mx-2">
                <li *ngFor="let option of lBreadcrumbOptions ; let i = index;" class="nav-item" role="presentation" (click)="MediaLibrarySharedComponent_SelectMediaType(option.filter)">
                    <a class="nav-link"  [class.active]="option.filter==sVisualType">{{option.name}}</a>
                </li>
                
            </ul>
        </div>
    </div>

    <div class="row video-header mx-0 " *ngIf="!bHideMediaLibrarySearchOptions">
        <ul class="container p-0 mb-1 d-flex justify-content-between">
            <li class="col-md-4 mb-2">
                <div class="formItm search-icon searchDropdown">
                    <input id="search-feild" type="search" [(ngModel)]="search" value=""
                        placeholder="Search your video" required="" data-filter>
                    <label for="search-feild" class="text-sm">Search media</label>
                    <svg width="16" height="16" viewBox="0 0 16 16" (click)="searchMediaLibrary()" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                            fill="#A39FAA"></path>
                    </svg>


                </div>
            </li>
            <!-- <li class="col-md-4 mb-2 d-flex justify-content-end" id="selectpreff">
                <div class="formItm down_arrow me-0 mt-4 text-end">

                    <select>
                        <option selected>Last Modified</option>
                        <option>Select 1</option>
                        <option>Select 2</option>
                        <option>Select 3</option>
                    </select>

                </div>
            </li> -->
            <!-- <li *ngIf="bDisplayMediaButtons" class="col-md-6 mb-0 text-end">
                <ul class="text-end d-block mt-3 pt-2">
                    <li class="pe-4 text-sm m-0">
                        <a  href="javascript:void(0)" class="text-gray-3" data-sidebar="#multiselectmedia" data-custom-select>Select Media</a>
                    </li>
                    <li class="m-0">
                        <button class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2" data-bs-toggle="modal" data-bs-target="#uploadModal">
                            Add Media
                        </button>
                    
                    </li>
                </ul>
            </li> -->
        </ul>
    </div>

    <div class="row grid select-media overflow-auto" [ngStyle]="{'height':isSlideout? '295px':'324px', 'overflow-y':isSlideout? 'scroll':''}">
            <!-- <ng-container *ngIf="!bSelectMultipleVisuals"> -->
            <li style="padding-right:0px;" *ngFor="let visual of this.mediaManager.visuals" [ngStyle]="{'margin-bottom':isSlideout? '16px':''}">
                    <!-- audio -->
                    <!-- <section data-tags="Audio" *ngIf="visual.visual_type=='audio'">
                        <div class="video-outer audio_outer">
                            
                           <div class="pageloader media-loader-outer" style="width: 100%;" *ngIf="visual.loadingThumbnail" >
                        <div id="loader-inner" class="media-loader-inner"></div>
                            </div>
                            <div class="audio_wrapper">
                                <a href="javascript:void(0)">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M3.625 3C3.625 1.68789 4.68789 0.625 6 0.625C7.31211 0.625 8.375 1.68789 8.375 3V5.75C8.375 7.06211 7.31211 8.125 6 8.125C4.68789 8.125 3.625 7.06211 3.625 5.75V3ZM6 1.375C5.10211 1.375 4.375 2.10211 4.375 3V5.75C4.375 6.64789 5.10211 7.375 6 7.375C6.89789 7.375 7.625 6.64789 7.625 5.75V3C7.625 2.10211 6.89789 1.375 6 1.375ZM2.17505 4.44995C2.38216 4.44995 2.55005 4.61784 2.55005 4.82495V5.67495C2.55005 7.57784 4.09716 9.12495 6.00005 9.12495C7.90294 9.12495 9.45005 7.57784 9.45005 5.67495V4.82495C9.45005 4.61784 9.61794 4.44995 9.82505 4.44995C10.0322 4.44995 10.2 4.61784 10.2 4.82495V5.67495C10.2 7.86571 8.51691 9.66831 6.375 9.85839V11C6.375 11.2071 6.20711 11.375 6 11.375C5.79289 11.375 5.625 11.2071 5.625 11V9.85838C3.48314 9.66825 1.80005 7.86567 1.80005 5.67495V4.82495C1.80005 4.61784 1.96794 4.44995 2.17505 4.44995ZM5.43413 3.56714C5.80078 3.4327 6.19929 3.4327 6.56594 3.56714C6.76039 3.63843 6.97581 3.5386 7.04711 3.34415C7.11841 3.14971 7.01858 2.93428 6.82413 2.86298C6.29078 2.66742 5.70929 2.66742 5.17594 2.86298C4.98149 2.93428 4.88166 3.14971 4.95296 3.34415C5.02425 3.5386 5.23968 3.63843 5.43413 3.56714ZM6.30917 4.63748C6.10695 4.58406 5.89795 4.58406 5.69572 4.63748C5.49548 4.69037 5.29027 4.57092 5.23738 4.37068C5.18449 4.17045 5.30394 3.96524 5.50417 3.91235C5.83194 3.82577 6.17295 3.82577 6.50072 3.91235C6.70096 3.96524 6.8204 4.17045 6.76751 4.37068C6.71462 4.57092 6.50941 4.69037 6.30917 4.63748Z"
                                            fill="#FF8369" />
                                    </svg>
                                </a>
                                <figure class="mb-0">
                                    <img [src]="visual.thumb" alt="audio-line">
                                </figure>
                            </div>
                            <p class="text-xs m-0">Open your<br>{{visual?.name | slice:0:22}}</p>
                            <span class="audio-time text-gray-3 text-xxs">00.55</span>
                        </div>
                        <div class="custom-radioButton">
                            <label [for]="visual.visual_path" (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)">
                                <input [type]="bSelectionType==true?'radio':'checkbox'" name="radiobtn" [id]="visual.visual_path" class="check form-check-input" [checked]="visual.isSelected" (change)="MediaLibrarySharedComponent_PassSelectedVisual(false)">
                            </label>
                        </div>
                    </section> -->
                <!-- image -->
                    <section data-tags="Images" *ngIf="visual.visual_type=='image'">
                        <div class="video-outer" style="min-width: 130px !important;  " (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)"  [ngClass]="visual.isSelected?'bgrid videoWrapperActive' : ''">
                            
                           <div class="pageloader media-loader-outer" style="width: 100%;" *ngIf="visual.loadingThumbnail" >
                        <div id="loader-inner" class="media-loader-inner"></div>
                            </div>
                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                <figure class="video-baner">
                                    <img [src]="visual.url" alt="video1">
                                </figure>
                            </div>
                            <p  *ngIf="!isSlideout" style="cursor:pointer ;    padding-left: 5px;
                            ">{{visual?.visual_path.split('/').pop()  | slice: 0 :15}}</p>
                        </div>

                        <div class="custom-radioButton d-none">
                            <label [for]="visual.visual_path" (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)">
                                <input [type]="bSelectionType==true?'radio':'checkbox'" name="radiobtn" [id]="visual.visual_path" class="check form-check-input" [checked]="visual.isSelected" (change)="MediaLibrarySharedComponent_PassSelectedVisual(false)">
                            </label>
                        </div>
                    </section>

                    <!-- video -->
                    <section data-tags="Videos" *ngIf="visual.visual_type=='clip'" >
                        <div class="video-outer" style="min-width: 130px !important;  "    (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)"  [ngClass]="visual.isSelected?'bgrid videoWrapperActive' : ''" >
                           
                            <div class="pageloader media-loader-outer" style="width: 100%;" *ngIf="visual.loadingThumbnail" >
                                <div id="loader-inner" class="media-loader-inner"></div>
                                    </div>
                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                <video controls controlsList="nodownload noplaybackrate"  class="videoIframe js-videoIframe" [muted]="true" [src]="visual.url" ></video>
                                <figure class="video-baner" *ngIf="!visual.isSelected"><img  [src]="visual.thumbnail"  alt="video1">
                                    <span class="video-duration">00:{{visual.duration}}</span>
                                </figure>
                                <button class="videoPoster js-videoPoster"></button> 
                            </div>
                            <p  *ngIf="!isSlideout" style="cursor:pointer ;  padding-left: 5px;
                            ">{{visual?.visual_path.split('/').pop() | slice: -15}}</p>
                        </div>
                        <div class="custom-radioButton d-none">
                            <label [for]="visual.visual_path" (click)="MediaLibrarySharedComponent_PushSelectedVisual(visual)">
                                <input [type]="bSelectionType==true?'radio':'checkbox'" name="radiobtn" [id]="visual.visual_path" class="check form-check-input" [checked]="visual.isSelected" (change)="MediaLibrarySharedComponent_PassSelectedVisual(false)">
                            </label>
                        </div>
                    </section>
                </li>
    </div>

    <!-- pagination index -->
    <ul id="pagination" *ngIf="this.mediaManager.visuals.length>0">
        <li>
            <a href="javascript:void(0)" class="preview-page" (click)="nCurrentPage==1?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage-1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
        </li>
        <li *ngFor="let page of lPagination;index as i">
            <a href="javascript:void(0)" (click)="MediaLibrarySharedComponent_FetchPage(page)" [ngClass]="i+1==nCurrentPage?'add_color':''"
            >{{page}}</a>
        </li>
        <li>
            <a href="javascript:void(0)" class="next-page" (click)="nCurrentPage==lPagination.length?'':MediaLibrarySharedComponent_FetchPage(nCurrentPage+1)">
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                        fill="white" />
                </svg>
            </a>
        </li>

    </ul>
    <div *ngIf="!bHideMediaLibrarySearchOptions">
        <button class="btn btn-md2 btn-secondary btn-round-6 mt-4 mb-2" href="javascript:void" [disabled]="lSelectedUserVisualsList.length==0" (click)="MediaLibrarySharedComponent_PassSelectedVisual(true)" data-bs-dismiss="modal" aria-label="Close">Select</button>
    </div>

</div>
</div>
