import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cDataService } from './../../../services/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { cClientService } from './../../../services/client.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { Console } from 'console';
import { environment } from './../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AccountManagerService } from 'src/app/Managers/account-manager.service';
import { ErrorService } from 'src/app/services/error.service';
 
declare var $ : any;

@Component({
  selector: 'app-dashboard-socialmedia',
  templateUrl: './dashboard-socialmedia.component.html',
  styleUrls: ['./dashboard-socialmedia.component.scss']
})
export class DashboardSocialmediaComponent implements OnInit {

  DisconnectSocialForm : FormGroup;
  connected_social_platforms: any;

  oUserName : any;
  oSocialChannel : string;
  sDisplayDisconnectErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  sSessionId: string;
  sUserEmail: string;
  pages: any = [];
  nIncomingPage : any;
  oVideoDetails : any;
  sDisplayResponseMessage : string; 
  bDisplaySuccessMessage : boolean = false;
  oSocialPlatformValue : any = '';
  return_url:string;

  constructor( 
    private oSessionService: cSessionService, 
    private oFormBuilder: FormBuilder,  
    private oDataService: cDataService,
    public onboardingManagger:OnboardingManagerService,
    public videoManager:VideoManagerService,
    private oClientService : cClientService,
    private errorService:ErrorService,
    private accountManager: AccountManagerService
    ) 
    {


    }

  async ngOnInit() 
  {



   this.connected_social_platforms = this.onboardingManagger.profile.connected_social_platforms;
   this.sSessionId = this.oSessionService.SessionService_GetSessionId();
   this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();  
  this.oVideoDetails = this.videoManager.selectedVideo;

  if(!this.oVideoDetails)
  {
    let video_id = this.oSessionService.SessionService_GetVideoId();
   await this.videoManager.getVideoById(this.sUserEmail,this.sSessionId,video_id).then(res=>{

      this.oVideoDetails = res;
      this.videoManager.selectedVideo = res;
    })

    
  }

  this.return_url = environment.WS_HOST + '/dashboard/socialmedia?video_id='+this.videoManager.selectedVideo?.fpath;
  console.log('video',this.oVideoDetails);
    this.DisconnectSocialForm = this.oFormBuilder.group(
      {
        platform : '',
      }); 



 

  }
  
  DashboardSocialMediaComponent_ShareVideoOnSocialChannel(value: any,event)
  {
    event.stopPropagation();
    this.oSocialPlatformValue = value;
    if(value == 'google' && this.connected_social_platforms.google)
    {
      $("#share-modal-Youtube").modal("show")
    }
    else if(value == 'linkedin' && this.connected_social_platforms.linkedin)
    {
      $("#share-modal-linkedin").modal("show");
    }
    else if((value == 'facebook' && this.connected_social_platforms.facebook) || (value == 'instagram' && this.connected_social_platforms.facebook))
    {
      $("#share-modal").modal("show")
      this.pages = [];
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      let url = cDataService.faceBookPages + '?' + params.toString();
      try
      {
        this.oDataService.DataService_GetFacebookPages(url).subscribe(oResponse =>
        {
          console.log('pages',oResponse.pages);
          this.pages = oResponse.pages;
          if(this.pages)
          {
            this.nIncomingPage = this.pages[0];
          }
          console.log("CustomEditVideo_FacebookChange : Reponse ==> ", oResponse);
        },
        (error)=>
        {
          if(error.url)
          {
            console.log("CustomEditVideo_FacebookChange : Error ==> ", error);
            window.location.assign(error.url);
          }
          else
          {
            console.log("CustomEditVideo_FacebookChange : Error ==> ", error);
          }
        });
      }
      catch (oError) 
      {
        console.log('CustomEditVideo_FacebookInstaChange: exception:');
        console.log(oError);
      }
    }
  }

  
  DashboardSocialMediaComponent_SocialMediaChannelsLink(oData: any) 
  {
    let sURL: string="";
    console.log(oData);
    if(oData == 'google')
    {
      sURL = this.oDataService.googleJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId; 
    }
    else if(oData == 'facebook')
    {

      sURL = this.oDataService.faceBookJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId; 
   
    }
    else if(oData == 'linkedin')
    {
      sURL = this.oDataService.linkedInJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId; 
    }

    console.log(sURL);
    this.oSessionService.cSessionService_SetRedirectURL(3);
    this.oSessionService.cSessionService_SetVideoId(this.videoManager.selectedVideo.fpath);
    location.assign(sURL);
  }
  DashboardSocialMediaComponent_DisconnectSocialChannel(value: any,event)
  {
    event.stopPropagation();
    this.oSocialChannel = value;

    this.accountManager.disconnectSocialPlatform(this.sUserEmail,this.sSessionId,value).then(res=>{
      switch (value) {
        case 'google':
         { this.onboardingManagger.profile.connected_social_platforms.google = false;
          break;
         }
         case 'facebook':
           { this.onboardingManagger.profile.connected_social_platforms.facebook = false;
            break;
           }
           case 'linkedin':
             { this.onboardingManagger.profile.connected_social_platforms.linkedin = false;
              break;
             }
        default:
          break;
      }
    }).catch(err=>{
      
      this.errorService.handleError(err.errorMessage,err.errorType,true);
    });
  }
 
  DashboardSocialMediaComponent_SelectPageForShare(nIncomingPage)
  {
    this.nIncomingPage = nIncomingPage;
  }
  DashboardSocialMediaComponent_ShareVideo()
  {
    try
    {
      console.log("video to share"+this.oVideoDetails);
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
      params = params.set('return_url',encodeURIComponent(this.return_url));
      params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
      
      params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
      let pageId = this.nIncomingPage.id;
      let selectedPage: any;
      this.pages.forEach(function (value)
      {
        if (value.id == pageId)
        {
          selectedPage = value;
        }
      });
      let reqBody = { page: { access_token: selectedPage.access_token, id: selectedPage.id, name: selectedPage.name }, video_id: this.oVideoDetails.fpath, title: this.oVideoDetails.title, description: this.oVideoDetails.about };
      let facebookInstaParams = new HttpParams();
      facebookInstaParams = params.set('email', this.sUserEmail);
      params = facebookInstaParams.set('session_id', this.sSessionId);
      if(this.oSocialPlatformValue == 'facebook')
      {
        console.log('step1',cDataService.faceBookUpload + '?' + facebookInstaParams.toString());
        let facebookUrl = cDataService.faceBookUpload + '?' + facebookInstaParams.toString();
        this.oClientService.ClientService_PostVideoToFacebookPage(facebookUrl, reqBody).subscribe(oResponse =>
        {
          console.log('response from facebook video upload ==>', oResponse);
          this.bDisplaySuccessMessage = true;
          this.sDisplayResponseMessage = oResponse.message;
          setTimeout(() => { this.bDisplaySuccessMessage = false;},5000);
        },
        error =>
        {
          this.bDisplayErrorMessage = true;
          this.sDisplayResponseMessage = error.error.errorMessage;
          setTimeout(() => { this.bDisplayErrorMessage = false;},5000);
        });
      }
      else
      {
        let instagramUrl = cDataService.instagramUpload + '?' + facebookInstaParams.toString();
        this.oClientService.ClientService_PostVideoToInstagramPage(instagramUrl, reqBody).subscribe(oResponse =>
        {
          console.log('response from instagram video upload ==>', oResponse);
          this.bDisplaySuccessMessage = true;
          this.sDisplayResponseMessage = oResponse.message;
          setTimeout(() => { this.bDisplaySuccessMessage = false;},5000);
        },
        error =>
        {
          this.bDisplayErrorMessage = true;
          this.sDisplayResponseMessage = error.error.errorMessage;
          setTimeout(() => { this.bDisplayErrorMessage = false;},5000);
        });
      }
    }
    catch (oError)
    {
      console.log('CustomEditVideo_PostVideo: exception:');
      console.log(oError);
      this.bDisplayErrorMessage = true;
      this.sDisplayResponseMessage = oError;
      setTimeout(() => { this.bDisplayErrorMessage = false;},5000);
    }
  }

  // Youtube Section
  DashboardSocialMediaComponent_ShareVideoOnYoutube()
  {
    const youTubeUploadBody =
    {
      category: '22'
    };
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
      params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
      params = params.set('return_url',encodeURIComponent(this.return_url));
      params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
      let url = '';
      if(this.oSocialPlatformValue == 'google')
      {
      let keyTermCombination = this.oVideoDetails.key_terms.join(',');
      console.log("Incoming Key Terms ==>>>>",keyTermCombination);
      params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
      params = params.set('keywords', encodeURIComponent(keyTermCombination));
      params = params.set('privacyStatus', encodeURIComponent('public'));
      url = cDataService.youTubeUpload + '?' + params.toString();
      console.log("YouTube URl====>",url);
      }
    this.oSessionService.cSessionService_SetRedirectURL(3);
    window.open(url);
  }
  // Linkedin Section
  DashboardSocialMediaComponent_ShareVideoOnLinkedIn()
  {
    let params = new HttpParams();
    params = params.set('email', this.sUserEmail);
    params = params.set('session_id', this.sSessionId);
    params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
    params = params.set('return_url',encodeURIComponent(this.return_url));
    params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
    params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
    let url = '';
    if(this.oSocialPlatformValue == 'linkedin')
    {
      params = params.set('privacyStatus', encodeURIComponent('public'));
      url = cDataService.LinkedInUpload + '?' + params.toString();
      console.log("LinkedIn URL : ==> ",url);
    }
    this.oSessionService.cSessionService_SetRedirectURL(3);
    window.open(url);
  }
}
