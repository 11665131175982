<app-header></app-header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <form>
            <div class="container">
                <ul class="breadcrumb my-3">
                    <li><a href="#">Account Settings</a></li>
                    <li>Login and Security</li>
                </ul>
                <div class=" br12 bg-white page-shadow curve-bg h-80vh pb-0 d-flex flex-column">

                    <div class="accountSecInner flex-grow-1 row" id="row-main">
                        <app-account-sidebar class="col-md-3 pe-0 pb-5 position-relative sidebar" id="sidebar-left"></app-account-sidebar>


                        <div class="col-md-9 border-start ps-0" id="content">
                            <div class="px-5 py-4">

                                <!-- <div class="account-heading"> -->
                                    <div class="row mb-5">
                                        <div class="col-md-8">
                                            <h3 class="weight-700 text-gray-2 mb-1">Storage Details</h3>
                                            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results</p>
                                        </div>
                                        <div class="col-md-4 text-end">
                                            <!-- <a class="text-gray-3 text-sm me-4" href="#">Cancel</a> -->
                                            <button type="button" href="billing.html"
                                                class="weight-normal btn btn-secondary btn-round-6 px-4 py-3 edit-btn ">Edit Details</button>
                                                <button type="button" href="billing.html"
                                                class="d-none weight-normal btn btn-secondary btn-round-6 px-4 py-3 save-btn ">Save Details</button>

                                        </div>
                                    </div>
                                <!-- </div> -->

                                <div class="row row-cols-md-2 g-0 justify-content-between">

                                    <div class="col-md-4 text-start">

                                        <div class="text-md text-gray-2 pb-4 mb-5"> Maximum Space</div>
                                        <div class="text-md text-gray-2 pb-0 mb-5"> Maximum Videos</div>
                                        <div class="text-md text-gray-2 pb-0 mb-5"> Maximum Length of video</div>

                                    </div>

                                    <div class="col-md-8 text-start">
                                        <div class="w-50">
                                            <div class="mb-5 pb-4 position-relative">
                                        <div class="progress rounded-pill">
                                            <div class="progress-bar bg-primary-3 rounded-pill progress-bar-striped" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            <p class="text-xs text-gray-3 position-absolute" style="bottom:-22px;left:2px;">0.200 GB of 3.0 GB used</p>
                                        </div>
                                        </div>

                                            <div class=" mb-5">
                                                <p id="max-videos" class="text-gray-2 text-md weight-600  mt-1">5/30</p>
                                                <div class="formItm finput inputfield p-0">
                                                    <input id="companyName" type="text" value=""
                                                         name="textfeild" class="formData p-0" required>
                                                    <!-- <label for="companyName" class="text-sm">Agency Name</label> -->
                                                </div>

                                            </div>
                                            <div class="pb-0  mb-5">
                                                <p id="max-length" class="text-gray-2 text-md weight-600 mt-1">60 seconds</p>
                                                <div class="formItm linput inputfield p-0">
                                                    <input id="companyName" type="text" value=""
                                                         name="textfeild" class="formData p-0" required>
                                                    <!-- <label for="companyName" class="text-sm">Agency Name</label> -->
                                                </div>
                                            </div>
                                        </div>
                                </div>


                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        </form>
    </section>

    <!-- Modal pop-up Start-->
    <div class="modal fade" id="signout-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body">
                    <button type="button" class="btn-close cust-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <h5>Are you sure you want to sign out from all devices?</h5>
                    <p class="text-sm text-gray-3 pt-1 pb-4">You will be logged out from all the devices you have logged
                        in from</p>
                    <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        data-bs-dismiss="modal">Cancel</a>
                    <a href="#" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm btnShadow">Disconnect</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->

    <!-- Modal pop-up Start-->
    <div class="modal fade" id="delete-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body">
                    <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <h5 class="mb-4">Delete Account</h5>
                    <div class="bg-yellow border-6 p-3 mb-4 row rounded-1">
                        <div class="col-1 p-0">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z" fill="#FED99F"/>
                                </svg>

                        </div>
                        <div class="col-11 p-0">
                            <h5 class="mb-2 mt-1">Are you sure you want to delete your account?</h5>
                            <p class="text-sm text-gray-3 mb-0">This cannot be reversed. You will no longer have access to your Prontopia  account, including all drafted, scheduled, and published videos.</p>
                        </div>
                    </div>
                    <p class="text-sm text-gray-3 mb-3">Total videos created: <b class="text-black">25</b></p>
                    <div class="mb-4 pb-3">
                        <div class="form-check">
                        <input class="form-check-input bg-primary-1 shadow-none border-0" type="checkbox" checked value="" id="flexCheckDefault">
                        <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                            I confirm that i want to delete my account
                        </label>
                      </div>
                    </div>

                    <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
                    <a href="#" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm">Delete</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->
    <div class="overlay"></div>