<app-header></app-header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Media</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results</p>
                        </div>  
                    
                    </div>
                </div>
            <div class="accountSecInner">
                <div class="accountSecNav py-5">
                    <app-business-sidebar></app-business-sidebar>
                </div>
                <div class="accountSecContent py-5 pe-4 pb-2">
                    <div class="video-header row m-0 px-2">
                        <p class="text-lg text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                            <a routerLink="/business-profile/user" class="me-2">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z" fill="#404243"/>
                                </svg>
                            </a>
                            Estelle Bates
                        </p>
                    </div>
                   
                                    <div class="container" id="container-view">
                                        <div class="row filter-post">
                                            <div class="col-md-12">
                                                <ul class="breadcrumb-tabs nav mx-2">
                                                    <li class="nav-item">
                                                        <a class="nav-link" routerLink="/business-profile/business-plus/account">Account</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link active">Published Video</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" routerLink="/business-profile/business-plus/manage-libraries">Manage Libraries</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="video-header row mx-0 my-2">
                                            <p class="text-lg text-gray-2 col-lg-4 mb-0 pt-2">
                                                Published Videos (4)
                                            </p>
                                            <ul class="text-end d-block col-lg-8 mb-1">
                                                <div class="d-flex float-end">
                                                <li class="searchbox-slide m-0 p-0">
                                                    <div class="searchbox">
                                                        <input type="search" placeholder="Search your video" name="search" class="searchbox-input" onkeyup="buttonUp();" required>
                                                        <input type="submit" class="searchbox-submit" value="GO">
                                                        <span class="searchbox-icon">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z" fill="#A39FAA"></path>
                                                                </svg>
                                                        </span>
                                                    </div>
                                                </li>
                                                    <!-- <li id="selectpreff" class="m-0 p-0">
                                                        <div class="formItm down_arrow me-0">         
                       
                                                            <select>
                                                                <option>Last Modified</option>
                                                                <option>Select 1</option>
                                                                <option>Select 2</option>
                                                                <option>Select 3</option>
                                                            </select>
                                                         
                                                        </div>
                                                    </li> -->
                                                    </div>
                                        
                                            </ul>
        
                                        </div>

                                        <div class="row row-4 grid m-0">
                                            <li>
                                                <div class="video-outer">     
                                                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                       <div class="share-edit">
                                                        <button id="toggle-slow"  class="showSingle" target="1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9ZM12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8Z" fill="#7A7A7A"/>
                                                            </svg>
                                                            </button>
                                                        <div id="div1"  class="targetDiv" >
                                                            <button>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.06279 2.06309C2.95243 1.17345 4.28369 0.833313 5.99967 0.833313H7.33301C7.60915 0.833313 7.83301 1.05717 7.83301 1.33331C7.83301 1.60946 7.60915 1.83331 7.33301 1.83331H5.99967C4.38232 1.83331 3.38025 2.15985 2.76989 2.7702C2.15954 3.38055 1.83301 4.38263 1.83301 5.99998V9.99998C1.83301 11.6173 2.15954 12.6194 2.76989 13.2298C3.38025 13.8401 4.38232 14.1666 5.99967 14.1666H9.99967C11.617 14.1666 12.6191 13.8401 13.2295 13.2298C13.8398 12.6194 14.1663 11.6173 14.1663 9.99998V8.66665C14.1663 8.3905 14.3902 8.16665 14.6663 8.16665C14.9425 8.16665 15.1663 8.3905 15.1663 8.66665V9.99998C15.1663 11.716 14.8262 13.0472 13.9366 13.9369C13.0469 14.8265 11.7157 15.1666 9.99967 15.1666H5.99967C4.28369 15.1666 2.95243 14.8265 2.06279 13.9369C1.17314 13.0472 0.833008 11.716 0.833008 9.99998V5.99998C0.833008 4.284 1.17314 2.95274 2.06279 2.06309Z" fill="#7A7A7A"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.131 0.675703C12.8732 0.602846 13.6091 0.928804 14.34 1.65976C15.071 2.39071 15.3969 3.12659 15.3241 3.86882C15.2538 4.58483 14.822 5.17825 14.34 5.6602L9.08089 10.9193C8.93076 11.0646 8.73453 11.1916 8.54518 11.2867C8.35694 11.3813 8.13522 11.4648 7.92385 11.495L5.91464 11.782C5.41991 11.8501 4.94656 11.715 4.61447 11.3842C4.28166 11.0528 4.14553 10.5793 4.21829 10.0817C4.21834 10.0814 4.21839 10.081 4.21844 10.0807L4.5046 8.07755C4.50464 8.07725 4.50468 8.07696 4.50473 8.07666C4.53475 7.8625 4.61777 7.6392 4.71306 7.4496C4.80855 7.25961 4.93713 7.06221 5.08625 6.91309L10.3396 1.65976C10.8215 1.17781 11.415 0.745986 12.131 0.675703ZM12.2286 1.67092C11.858 1.7073 11.4714 1.94215 11.0467 2.36687L5.79336 7.6202C5.74248 7.67108 5.67106 7.77035 5.60655 7.89869C5.54197 8.02717 5.50509 8.14351 5.49501 8.21574L5.49478 8.21736L5.20811 10.224L5.20784 10.2259C5.17426 10.4547 5.24151 10.5974 5.32014 10.6757C5.39958 10.7548 5.54586 10.823 5.77712 10.7915C5.77751 10.7914 5.77791 10.7914 5.77831 10.7913L7.78243 10.505C7.85105 10.4952 7.966 10.4586 8.0961 10.3932C8.22326 10.3293 8.32595 10.2575 8.383 10.203L13.6329 4.95309C14.0576 4.52838 14.2925 4.14179 14.3289 3.77113C14.3627 3.4267 14.2353 2.96924 13.6329 2.36687C13.0305 1.76449 12.5731 1.63711 12.2286 1.67092Z" fill="#7A7A7A"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80462 2.28521C10.0705 2.21067 10.3465 2.3658 10.421 2.63169C10.8204 4.05643 11.9356 5.17274 13.3692 5.57892C13.6349 5.6542 13.7893 5.9306 13.714 6.19629C13.6387 6.46197 13.3623 6.61633 13.0966 6.54105C11.3302 6.04057 9.95207 4.66354 9.45814 2.90162C9.3836 2.63573 9.53872 2.35975 9.80462 2.28521Z" fill="#7A7A7A"/>
                                                                    </svg>
                                                                    
                                                            </button>
                                                            <br>
                                                            <button>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0404 4.70933 14.0408 6.31064 14.2437 8.15875C14.2738 8.43324 14.0758 8.6802 13.8013 8.71034C13.5268 8.74048 13.2798 8.54239 13.2497 8.2679C13.0793 6.71602 12.2397 5.37066 11.0213 4.5239C10.7946 4.36631 10.7385 4.05473 10.8961 3.82797Z" fill="white"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13447 3.858C5.29389 4.08347 5.24035 4.39549 5.01488 4.55492C3.8087 5.40777 2.98173 6.75284 2.82361 8.29758C2.79549 8.57229 2.55 8.77218 2.2753 8.74406C2.00059 8.71594 1.80069 8.47046 1.82881 8.19575C2.01735 6.35382 3.00373 4.75222 4.43755 3.73841C4.66302 3.57898 4.97504 3.63252 5.13447 3.858Z" fill="white"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.0144 13.7333C5.13959 13.4872 5.44061 13.3892 5.68674 13.5143C6.39052 13.8723 7.19293 14.0733 8.04007 14.0733C8.85757 14.0733 9.62274 13.8906 10.3079 13.5571C10.5562 13.4362 10.8555 13.5396 10.9763 13.7879C11.0972 14.0361 10.9938 14.3354 10.7456 14.4562C9.92406 14.8561 9.00923 15.0733 8.04007 15.0733C7.03387 15.0733 6.07628 14.8344 5.23339 14.4057C4.98726 14.2805 4.88921 13.9795 5.0144 13.7333Z" fill="white"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03986 1.92667C7.29243 1.92667 6.68652 2.53257 6.68652 3.28C6.68652 4.02742 7.29243 4.63333 8.03986 4.63333C8.78728 4.63333 9.39319 4.02743 9.39319 3.28C9.39319 2.53257 8.78728 1.92667 8.03986 1.92667ZM5.68652 3.28C5.68652 1.98029 6.74015 0.926666 8.03986 0.926666C9.33957 0.926666 10.3932 1.98029 10.3932 3.28C10.3932 4.57971 9.33957 5.63333 8.03986 5.63333C6.74015 5.63333 5.68652 4.57971 5.68652 3.28Z" fill="white"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21954 10.0733C2.47212 10.0733 1.86621 10.6792 1.86621 11.4267C1.86621 12.1741 2.47212 12.78 3.21954 12.78C3.96697 12.78 4.57288 12.1741 4.57288 11.4267C4.57288 10.6792 3.96697 10.0733 3.21954 10.0733ZM0.866211 11.4267C0.866211 10.127 1.91983 9.07333 3.21954 9.07333C4.51925 9.07333 5.57288 10.127 5.57288 11.4267C5.57288 12.7264 4.51925 13.78 3.21954 13.78C1.91983 13.78 0.866211 12.7264 0.866211 11.4267Z" fill="white"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7801 10.0733C12.0327 10.0733 11.4268 10.6792 11.4268 11.4267C11.4268 12.1741 12.0327 12.78 12.7801 12.78C13.5275 12.78 14.1334 12.1741 14.1334 11.4267C14.1334 10.6792 13.5275 10.0733 12.7801 10.0733ZM10.4268 11.4267C10.4268 10.127 11.4804 9.07333 12.7801 9.07333C14.0798 9.07333 15.1334 10.127 15.1334 11.4267C15.1334 12.7264 14.0798 13.78 12.7801 13.78C11.4804 13.78 10.4268 12.7264 10.4268 11.4267Z" fill="white"/>
                                                                    </svg>                                                                
                                                            </button>
                                                        </div>
                                                       </div>
                                                        <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                                    <figure class="video-baner"><img src="../assets/images/myVideos/v1.png" alt="video1">
                                                        <span class="video-duration">00.55</span>
                                                    </figure>
                                                        <button class="videoPoster js-videoPoster"></button>
                                                    </div>
                                                     <a href="../dashboardVideo/dashboardvideo_Overview.html" class="text-sm p-3 m-0 text-gray-2 d-block">Family chiropractic center of richmond</a>
                                                     
                                                 </div>
                                            </li>
                                            <li>
                                                <div class="video-outer">     
                                                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                        <div class="share-edit">
                                                            <button id="toggle-slow"  class="showSingle" target="2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9ZM12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8Z" fill="#7A7A7A"/>
                                                                </svg>
                                                                </button>
                                                            <div id="div2"  class="targetDiv" style="display: block;">
                                                                <button>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.06279 2.06309C2.95243 1.17345 4.28369 0.833313 5.99967 0.833313H7.33301C7.60915 0.833313 7.83301 1.05717 7.83301 1.33331C7.83301 1.60946 7.60915 1.83331 7.33301 1.83331H5.99967C4.38232 1.83331 3.38025 2.15985 2.76989 2.7702C2.15954 3.38055 1.83301 4.38263 1.83301 5.99998V9.99998C1.83301 11.6173 2.15954 12.6194 2.76989 13.2298C3.38025 13.8401 4.38232 14.1666 5.99967 14.1666H9.99967C11.617 14.1666 12.6191 13.8401 13.2295 13.2298C13.8398 12.6194 14.1663 11.6173 14.1663 9.99998V8.66665C14.1663 8.3905 14.3902 8.16665 14.6663 8.16665C14.9425 8.16665 15.1663 8.3905 15.1663 8.66665V9.99998C15.1663 11.716 14.8262 13.0472 13.9366 13.9369C13.0469 14.8265 11.7157 15.1666 9.99967 15.1666H5.99967C4.28369 15.1666 2.95243 14.8265 2.06279 13.9369C1.17314 13.0472 0.833008 11.716 0.833008 9.99998V5.99998C0.833008 4.284 1.17314 2.95274 2.06279 2.06309Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.131 0.675703C12.8732 0.602846 13.6091 0.928804 14.34 1.65976C15.071 2.39071 15.3969 3.12659 15.3241 3.86882C15.2538 4.58483 14.822 5.17825 14.34 5.6602L9.08089 10.9193C8.93076 11.0646 8.73453 11.1916 8.54518 11.2867C8.35694 11.3813 8.13522 11.4648 7.92385 11.495L5.91464 11.782C5.41991 11.8501 4.94656 11.715 4.61447 11.3842C4.28166 11.0528 4.14553 10.5793 4.21829 10.0817C4.21834 10.0814 4.21839 10.081 4.21844 10.0807L4.5046 8.07755C4.50464 8.07725 4.50468 8.07696 4.50473 8.07666C4.53475 7.8625 4.61777 7.6392 4.71306 7.4496C4.80855 7.25961 4.93713 7.06221 5.08625 6.91309L10.3396 1.65976C10.8215 1.17781 11.415 0.745986 12.131 0.675703ZM12.2286 1.67092C11.858 1.7073 11.4714 1.94215 11.0467 2.36687L5.79336 7.6202C5.74248 7.67108 5.67106 7.77035 5.60655 7.89869C5.54197 8.02717 5.50509 8.14351 5.49501 8.21574L5.49478 8.21736L5.20811 10.224L5.20784 10.2259C5.17426 10.4547 5.24151 10.5974 5.32014 10.6757C5.39958 10.7548 5.54586 10.823 5.77712 10.7915C5.77751 10.7914 5.77791 10.7914 5.77831 10.7913L7.78243 10.505C7.85105 10.4952 7.966 10.4586 8.0961 10.3932C8.22326 10.3293 8.32595 10.2575 8.383 10.203L13.6329 4.95309C14.0576 4.52838 14.2925 4.14179 14.3289 3.77113C14.3627 3.4267 14.2353 2.96924 13.6329 2.36687C13.0305 1.76449 12.5731 1.63711 12.2286 1.67092Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80462 2.28521C10.0705 2.21067 10.3465 2.3658 10.421 2.63169C10.8204 4.05643 11.9356 5.17274 13.3692 5.57892C13.6349 5.6542 13.7893 5.9306 13.714 6.19629C13.6387 6.46197 13.3623 6.61633 13.0966 6.54105C11.3302 6.04057 9.95207 4.66354 9.45814 2.90162C9.3836 2.63573 9.53872 2.35975 9.80462 2.28521Z" fill="#7A7A7A"/>
                                                                        </svg>
                                                                        
                                                                </button>
                                                                <br>
                                                                <button class="active">
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0404 4.70933 14.0408 6.31064 14.2437 8.15875C14.2738 8.43324 14.0758 8.6802 13.8013 8.71034C13.5268 8.74048 13.2798 8.54239 13.2497 8.2679C13.0793 6.71602 12.2397 5.37066 11.0213 4.5239C10.7946 4.36631 10.7385 4.05473 10.8961 3.82797Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13447 3.858C5.29389 4.08347 5.24035 4.39549 5.01488 4.55492C3.8087 5.40777 2.98173 6.75284 2.82361 8.29758C2.79549 8.57229 2.55 8.77218 2.2753 8.74406C2.00059 8.71594 1.80069 8.47046 1.82881 8.19575C2.01735 6.35382 3.00373 4.75222 4.43755 3.73841C4.66302 3.57898 4.97504 3.63252 5.13447 3.858Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.0144 13.7333C5.13959 13.4872 5.44061 13.3892 5.68674 13.5143C6.39052 13.8723 7.19293 14.0733 8.04007 14.0733C8.85757 14.0733 9.62274 13.8906 10.3079 13.5571C10.5562 13.4362 10.8555 13.5396 10.9763 13.7879C11.0972 14.0361 10.9938 14.3354 10.7456 14.4562C9.92406 14.8561 9.00923 15.0733 8.04007 15.0733C7.03387 15.0733 6.07628 14.8344 5.23339 14.4057C4.98726 14.2805 4.88921 13.9795 5.0144 13.7333Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03986 1.92667C7.29243 1.92667 6.68652 2.53257 6.68652 3.28C6.68652 4.02742 7.29243 4.63333 8.03986 4.63333C8.78728 4.63333 9.39319 4.02743 9.39319 3.28C9.39319 2.53257 8.78728 1.92667 8.03986 1.92667ZM5.68652 3.28C5.68652 1.98029 6.74015 0.926666 8.03986 0.926666C9.33957 0.926666 10.3932 1.98029 10.3932 3.28C10.3932 4.57971 9.33957 5.63333 8.03986 5.63333C6.74015 5.63333 5.68652 4.57971 5.68652 3.28Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21954 10.0733C2.47212 10.0733 1.86621 10.6792 1.86621 11.4267C1.86621 12.1741 2.47212 12.78 3.21954 12.78C3.96697 12.78 4.57288 12.1741 4.57288 11.4267C4.57288 10.6792 3.96697 10.0733 3.21954 10.0733ZM0.866211 11.4267C0.866211 10.127 1.91983 9.07333 3.21954 9.07333C4.51925 9.07333 5.57288 10.127 5.57288 11.4267C5.57288 12.7264 4.51925 13.78 3.21954 13.78C1.91983 13.78 0.866211 12.7264 0.866211 11.4267Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7801 10.0733C12.0327 10.0733 11.4268 10.6792 11.4268 11.4267C11.4268 12.1741 12.0327 12.78 12.7801 12.78C13.5275 12.78 14.1334 12.1741 14.1334 11.4267C14.1334 10.6792 13.5275 10.0733 12.7801 10.0733ZM10.4268 11.4267C10.4268 10.127 11.4804 9.07333 12.7801 9.07333C14.0798 9.07333 15.1334 10.127 15.1334 11.4267C15.1334 12.7264 14.0798 13.78 12.7801 13.78C11.4804 13.78 10.4268 12.7264 10.4268 11.4267Z" fill="white"/>
                                                                        </svg>                                                                
                                                                </button>
                                                            </div>
                                                           </div>
                                                        <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                                    <figure class="video-baner"><img src="../assets/images/myVideos/v2.png" alt="video1">
                                                        <span class="video-duration">00.55</span>
                                                    </figure>
                                                        <button class="videoPoster js-videoPoster"></button>
                                                    </div>
                                                     <a href="../dashboardVideo/dashboardvideo_Overview.html" class="text-sm p-3 m-0 text-gray-2 d-block">Family chiropractic center of richmond</a>
                                                   
                                                 </div>
                                            </li>
                                            <li>
                                                <div class="video-outer">     
                                                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                        <div class="share-edit">
                                                            <button id="toggle-slow"  class="showSingle" target="3"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9ZM12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8Z" fill="#7A7A7A"/>
                                                                </svg>
                                                                </button>
                                                            <div id="div3"  class="targetDiv">
                                                                <button>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.06279 2.06309C2.95243 1.17345 4.28369 0.833313 5.99967 0.833313H7.33301C7.60915 0.833313 7.83301 1.05717 7.83301 1.33331C7.83301 1.60946 7.60915 1.83331 7.33301 1.83331H5.99967C4.38232 1.83331 3.38025 2.15985 2.76989 2.7702C2.15954 3.38055 1.83301 4.38263 1.83301 5.99998V9.99998C1.83301 11.6173 2.15954 12.6194 2.76989 13.2298C3.38025 13.8401 4.38232 14.1666 5.99967 14.1666H9.99967C11.617 14.1666 12.6191 13.8401 13.2295 13.2298C13.8398 12.6194 14.1663 11.6173 14.1663 9.99998V8.66665C14.1663 8.3905 14.3902 8.16665 14.6663 8.16665C14.9425 8.16665 15.1663 8.3905 15.1663 8.66665V9.99998C15.1663 11.716 14.8262 13.0472 13.9366 13.9369C13.0469 14.8265 11.7157 15.1666 9.99967 15.1666H5.99967C4.28369 15.1666 2.95243 14.8265 2.06279 13.9369C1.17314 13.0472 0.833008 11.716 0.833008 9.99998V5.99998C0.833008 4.284 1.17314 2.95274 2.06279 2.06309Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.131 0.675703C12.8732 0.602846 13.6091 0.928804 14.34 1.65976C15.071 2.39071 15.3969 3.12659 15.3241 3.86882C15.2538 4.58483 14.822 5.17825 14.34 5.6602L9.08089 10.9193C8.93076 11.0646 8.73453 11.1916 8.54518 11.2867C8.35694 11.3813 8.13522 11.4648 7.92385 11.495L5.91464 11.782C5.41991 11.8501 4.94656 11.715 4.61447 11.3842C4.28166 11.0528 4.14553 10.5793 4.21829 10.0817C4.21834 10.0814 4.21839 10.081 4.21844 10.0807L4.5046 8.07755C4.50464 8.07725 4.50468 8.07696 4.50473 8.07666C4.53475 7.8625 4.61777 7.6392 4.71306 7.4496C4.80855 7.25961 4.93713 7.06221 5.08625 6.91309L10.3396 1.65976C10.8215 1.17781 11.415 0.745986 12.131 0.675703ZM12.2286 1.67092C11.858 1.7073 11.4714 1.94215 11.0467 2.36687L5.79336 7.6202C5.74248 7.67108 5.67106 7.77035 5.60655 7.89869C5.54197 8.02717 5.50509 8.14351 5.49501 8.21574L5.49478 8.21736L5.20811 10.224L5.20784 10.2259C5.17426 10.4547 5.24151 10.5974 5.32014 10.6757C5.39958 10.7548 5.54586 10.823 5.77712 10.7915C5.77751 10.7914 5.77791 10.7914 5.77831 10.7913L7.78243 10.505C7.85105 10.4952 7.966 10.4586 8.0961 10.3932C8.22326 10.3293 8.32595 10.2575 8.383 10.203L13.6329 4.95309C14.0576 4.52838 14.2925 4.14179 14.3289 3.77113C14.3627 3.4267 14.2353 2.96924 13.6329 2.36687C13.0305 1.76449 12.5731 1.63711 12.2286 1.67092Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80462 2.28521C10.0705 2.21067 10.3465 2.3658 10.421 2.63169C10.8204 4.05643 11.9356 5.17274 13.3692 5.57892C13.6349 5.6542 13.7893 5.9306 13.714 6.19629C13.6387 6.46197 13.3623 6.61633 13.0966 6.54105C11.3302 6.04057 9.95207 4.66354 9.45814 2.90162C9.3836 2.63573 9.53872 2.35975 9.80462 2.28521Z" fill="#7A7A7A"/>
                                                                        </svg>
                                                                        
                                                                </button>
                                                                <br>
                                                                <button>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0404 4.70933 14.0408 6.31064 14.2437 8.15875C14.2738 8.43324 14.0758 8.6802 13.8013 8.71034C13.5268 8.74048 13.2798 8.54239 13.2497 8.2679C13.0793 6.71602 12.2397 5.37066 11.0213 4.5239C10.7946 4.36631 10.7385 4.05473 10.8961 3.82797Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13447 3.858C5.29389 4.08347 5.24035 4.39549 5.01488 4.55492C3.8087 5.40777 2.98173 6.75284 2.82361 8.29758C2.79549 8.57229 2.55 8.77218 2.2753 8.74406C2.00059 8.71594 1.80069 8.47046 1.82881 8.19575C2.01735 6.35382 3.00373 4.75222 4.43755 3.73841C4.66302 3.57898 4.97504 3.63252 5.13447 3.858Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.0144 13.7333C5.13959 13.4872 5.44061 13.3892 5.68674 13.5143C6.39052 13.8723 7.19293 14.0733 8.04007 14.0733C8.85757 14.0733 9.62274 13.8906 10.3079 13.5571C10.5562 13.4362 10.8555 13.5396 10.9763 13.7879C11.0972 14.0361 10.9938 14.3354 10.7456 14.4562C9.92406 14.8561 9.00923 15.0733 8.04007 15.0733C7.03387 15.0733 6.07628 14.8344 5.23339 14.4057C4.98726 14.2805 4.88921 13.9795 5.0144 13.7333Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03986 1.92667C7.29243 1.92667 6.68652 2.53257 6.68652 3.28C6.68652 4.02742 7.29243 4.63333 8.03986 4.63333C8.78728 4.63333 9.39319 4.02743 9.39319 3.28C9.39319 2.53257 8.78728 1.92667 8.03986 1.92667ZM5.68652 3.28C5.68652 1.98029 6.74015 0.926666 8.03986 0.926666C9.33957 0.926666 10.3932 1.98029 10.3932 3.28C10.3932 4.57971 9.33957 5.63333 8.03986 5.63333C6.74015 5.63333 5.68652 4.57971 5.68652 3.28Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21954 10.0733C2.47212 10.0733 1.86621 10.6792 1.86621 11.4267C1.86621 12.1741 2.47212 12.78 3.21954 12.78C3.96697 12.78 4.57288 12.1741 4.57288 11.4267C4.57288 10.6792 3.96697 10.0733 3.21954 10.0733ZM0.866211 11.4267C0.866211 10.127 1.91983 9.07333 3.21954 9.07333C4.51925 9.07333 5.57288 10.127 5.57288 11.4267C5.57288 12.7264 4.51925 13.78 3.21954 13.78C1.91983 13.78 0.866211 12.7264 0.866211 11.4267Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7801 10.0733C12.0327 10.0733 11.4268 10.6792 11.4268 11.4267C11.4268 12.1741 12.0327 12.78 12.7801 12.78C13.5275 12.78 14.1334 12.1741 14.1334 11.4267C14.1334 10.6792 13.5275 10.0733 12.7801 10.0733ZM10.4268 11.4267C10.4268 10.127 11.4804 9.07333 12.7801 9.07333C14.0798 9.07333 15.1334 10.127 15.1334 11.4267C15.1334 12.7264 14.0798 13.78 12.7801 13.78C11.4804 13.78 10.4268 12.7264 10.4268 11.4267Z" fill="white"/>
                                                                        </svg>                                                                
                                                                </button>
                                                            </div>
                                                           </div>
                                                        <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                                    <figure class="video-baner"><img src="../assets/images/myVideos/v4.png" alt="video1">
                                                        <span class="video-duration">00.55</span>
                                                    </figure>
                                                        <button class="videoPoster js-videoPoster"></button>
                                                    </div>
                                                     <a href="../dashboardVideo/dashboardvideo_Overview.html" class="text-sm p-3 m-0 text-gray-2 d-block">Family chiropractic center of richmond</a>
                                                    
                                                 </div>
                                            </li>
                                            <li>
                                                <div class="video-outer">     
                                                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                                        <div class="share-edit">
                                                            <button id="toggle-slow"  class="showSingle" target="4"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9ZM12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8Z" fill="#7A7A7A"/>
                                                                </svg>
                                                                </button>
                                                            <div id="div4"  class="targetDiv">
                                                                <button>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.06279 2.06309C2.95243 1.17345 4.28369 0.833313 5.99967 0.833313H7.33301C7.60915 0.833313 7.83301 1.05717 7.83301 1.33331C7.83301 1.60946 7.60915 1.83331 7.33301 1.83331H5.99967C4.38232 1.83331 3.38025 2.15985 2.76989 2.7702C2.15954 3.38055 1.83301 4.38263 1.83301 5.99998V9.99998C1.83301 11.6173 2.15954 12.6194 2.76989 13.2298C3.38025 13.8401 4.38232 14.1666 5.99967 14.1666H9.99967C11.617 14.1666 12.6191 13.8401 13.2295 13.2298C13.8398 12.6194 14.1663 11.6173 14.1663 9.99998V8.66665C14.1663 8.3905 14.3902 8.16665 14.6663 8.16665C14.9425 8.16665 15.1663 8.3905 15.1663 8.66665V9.99998C15.1663 11.716 14.8262 13.0472 13.9366 13.9369C13.0469 14.8265 11.7157 15.1666 9.99967 15.1666H5.99967C4.28369 15.1666 2.95243 14.8265 2.06279 13.9369C1.17314 13.0472 0.833008 11.716 0.833008 9.99998V5.99998C0.833008 4.284 1.17314 2.95274 2.06279 2.06309Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.131 0.675703C12.8732 0.602846 13.6091 0.928804 14.34 1.65976C15.071 2.39071 15.3969 3.12659 15.3241 3.86882C15.2538 4.58483 14.822 5.17825 14.34 5.6602L9.08089 10.9193C8.93076 11.0646 8.73453 11.1916 8.54518 11.2867C8.35694 11.3813 8.13522 11.4648 7.92385 11.495L5.91464 11.782C5.41991 11.8501 4.94656 11.715 4.61447 11.3842C4.28166 11.0528 4.14553 10.5793 4.21829 10.0817C4.21834 10.0814 4.21839 10.081 4.21844 10.0807L4.5046 8.07755C4.50464 8.07725 4.50468 8.07696 4.50473 8.07666C4.53475 7.8625 4.61777 7.6392 4.71306 7.4496C4.80855 7.25961 4.93713 7.06221 5.08625 6.91309L10.3396 1.65976C10.8215 1.17781 11.415 0.745986 12.131 0.675703ZM12.2286 1.67092C11.858 1.7073 11.4714 1.94215 11.0467 2.36687L5.79336 7.6202C5.74248 7.67108 5.67106 7.77035 5.60655 7.89869C5.54197 8.02717 5.50509 8.14351 5.49501 8.21574L5.49478 8.21736L5.20811 10.224L5.20784 10.2259C5.17426 10.4547 5.24151 10.5974 5.32014 10.6757C5.39958 10.7548 5.54586 10.823 5.77712 10.7915C5.77751 10.7914 5.77791 10.7914 5.77831 10.7913L7.78243 10.505C7.85105 10.4952 7.966 10.4586 8.0961 10.3932C8.22326 10.3293 8.32595 10.2575 8.383 10.203L13.6329 4.95309C14.0576 4.52838 14.2925 4.14179 14.3289 3.77113C14.3627 3.4267 14.2353 2.96924 13.6329 2.36687C13.0305 1.76449 12.5731 1.63711 12.2286 1.67092Z" fill="#7A7A7A"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80462 2.28521C10.0705 2.21067 10.3465 2.3658 10.421 2.63169C10.8204 4.05643 11.9356 5.17274 13.3692 5.57892C13.6349 5.6542 13.7893 5.9306 13.714 6.19629C13.6387 6.46197 13.3623 6.61633 13.0966 6.54105C11.3302 6.04057 9.95207 4.66354 9.45814 2.90162C9.3836 2.63573 9.53872 2.35975 9.80462 2.28521Z" fill="#7A7A7A"/>
                                                                        </svg>
                                                                        
                                                                </button>
                                                                <br>
                                                                <button>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0404 4.70933 14.0408 6.31064 14.2437 8.15875C14.2738 8.43324 14.0758 8.6802 13.8013 8.71034C13.5268 8.74048 13.2798 8.54239 13.2497 8.2679C13.0793 6.71602 12.2397 5.37066 11.0213 4.5239C10.7946 4.36631 10.7385 4.05473 10.8961 3.82797Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13447 3.858C5.29389 4.08347 5.24035 4.39549 5.01488 4.55492C3.8087 5.40777 2.98173 6.75284 2.82361 8.29758C2.79549 8.57229 2.55 8.77218 2.2753 8.74406C2.00059 8.71594 1.80069 8.47046 1.82881 8.19575C2.01735 6.35382 3.00373 4.75222 4.43755 3.73841C4.66302 3.57898 4.97504 3.63252 5.13447 3.858Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.0144 13.7333C5.13959 13.4872 5.44061 13.3892 5.68674 13.5143C6.39052 13.8723 7.19293 14.0733 8.04007 14.0733C8.85757 14.0733 9.62274 13.8906 10.3079 13.5571C10.5562 13.4362 10.8555 13.5396 10.9763 13.7879C11.0972 14.0361 10.9938 14.3354 10.7456 14.4562C9.92406 14.8561 9.00923 15.0733 8.04007 15.0733C7.03387 15.0733 6.07628 14.8344 5.23339 14.4057C4.98726 14.2805 4.88921 13.9795 5.0144 13.7333Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03986 1.92667C7.29243 1.92667 6.68652 2.53257 6.68652 3.28C6.68652 4.02742 7.29243 4.63333 8.03986 4.63333C8.78728 4.63333 9.39319 4.02743 9.39319 3.28C9.39319 2.53257 8.78728 1.92667 8.03986 1.92667ZM5.68652 3.28C5.68652 1.98029 6.74015 0.926666 8.03986 0.926666C9.33957 0.926666 10.3932 1.98029 10.3932 3.28C10.3932 4.57971 9.33957 5.63333 8.03986 5.63333C6.74015 5.63333 5.68652 4.57971 5.68652 3.28Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21954 10.0733C2.47212 10.0733 1.86621 10.6792 1.86621 11.4267C1.86621 12.1741 2.47212 12.78 3.21954 12.78C3.96697 12.78 4.57288 12.1741 4.57288 11.4267C4.57288 10.6792 3.96697 10.0733 3.21954 10.0733ZM0.866211 11.4267C0.866211 10.127 1.91983 9.07333 3.21954 9.07333C4.51925 9.07333 5.57288 10.127 5.57288 11.4267C5.57288 12.7264 4.51925 13.78 3.21954 13.78C1.91983 13.78 0.866211 12.7264 0.866211 11.4267Z" fill="white"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7801 10.0733C12.0327 10.0733 11.4268 10.6792 11.4268 11.4267C11.4268 12.1741 12.0327 12.78 12.7801 12.78C13.5275 12.78 14.1334 12.1741 14.1334 11.4267C14.1334 10.6792 13.5275 10.0733 12.7801 10.0733ZM10.4268 11.4267C10.4268 10.127 11.4804 9.07333 12.7801 9.07333C14.0798 9.07333 15.1334 10.127 15.1334 11.4267C15.1334 12.7264 14.0798 13.78 12.7801 13.78C11.4804 13.78 10.4268 12.7264 10.4268 11.4267Z" fill="white"/>
                                                                        </svg>                                                                
                                                                </button>
                                                            </div>
                                                           </div>
                                                        <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/XULUBg_ZcAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                                    <figure class="video-baner"><img src="../assets/images/myVideos/v3.png" alt="video1">
                                                        <span class="video-duration">00.55</span>
                                                    </figure>
                                                        <button class="videoPoster js-videoPoster"></button>
                                                    </div>
                                                     <a href="../dashboardVideo/dashboardvideo_Overview.html" class="text-sm p-3 m-0 text-gray-2 d-block">Family chiropractic center of richmond</a>
                                                  
                                                 </div>
                                            </li>
    
                                        </div>
                                    </div>    
                </div>
                </div>
            </div>
         
        </div>
    </form>
    </section>