import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { Component, ElementRef, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { mediaFile, visual } from './../../../models/media';
import {  video } from './../../../models/video';
import { ErrorService } from './../../../services/error.service';
import { LoaderService } from './../../../services/loader.service';
import { cSessionService } from './../../../services/session.service';

@Component({
  selector: 'app-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.scss']
})
export class SingleVideoComponent implements OnInit,OnDestroy {

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('videoPreview') videoPreview: ElementRef;
  sSessionID: string = "";
  sUserEmail: string = "";
  oTotalCount: any;
  lLocales: any = [];
  lSelectedLocations: any = [];
  totalSelectedLocals: any = [];
  nCurrentPage = 1;
  lPagination: any = [];
  nPageSize: number = 100;
  uploadedVdeo:any;
  videoPopup: boolean = false;
  vidPreview: boolean = true;
  lUserSelectedVisuals: any = [];
  bDisplayMyMediaSection: boolean = false;
  isModalVisible: boolean = false;
  lClipsList: any = [];
  
sub;
  constructor(public videoManager: CreateVideoManagerService, public loader: LoaderService, private oSessionService: cSessionService, public onBoardingManager: OnboardingManagerService, private oRoutes: Router, private accountManager: AccountManagerService,private errorService:ErrorService,private mediaManger:MediaManagerService) { }

  ngOnDestroy(): void {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }
  ngOnInit(): void {

    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.sub = this.videoManager.submitForm.subscribe(c => {

      console.log(this.uploadedVdeo );

if(this.uploadedVdeo && (this.lSelectedLocations.length > 0 ||  this.AllLocaleSelected))
{
      let vid = new video();
    
      vid.email=this.sUserEmail,
      vid.session_id= this.sSessionID,
      vid.user_clip_id=this.uploadedVdeo.fpath,
      vid.title=this.uploadedVdeo.filename,
      vid.template_name= "",
      vid.locales=  this.lSelectedLocations,
      vid.AllLocaleSelected= this.AllLocaleSelected
      
this.videoManager.DirectLocalizeVideo(vid).then(res=>{
  this.showPopUp();
  
  // check
})
}else if((this.lSelectedLocations.length == 0 && !this.AllLocaleSelected))
{
  this.errorService.handleError("Please select locale","Error",true,true);
}else
{
  this.errorService.handleError("Please select a video","Error",true,true);
}
    });

    this.openLocation();
    
    let vid = new video();
  
    vid.AllLocaleSelected = this.AllLocaleSelected;
    vid.locales = this.AllLocaleSelected ? [] : this.lSelectedLocations;

    // Extract images and clips from user media
    if (this.bDisplayMyMediaSection) {
      
      this.lUserSelectedVisuals as visual;
      this.lClipsList.push(this.lUserSelectedVisuals.visual_path);
    }

  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
  removeSelectedVisuals(oIncommingVisual: any) {

    let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
    this.lUserSelectedVisuals.splice(nVisualIndex, 1);
    
  }
  private back() {
    if (this.onBoardingManager.loggedUser.videoCount > 0) {
      this.oRoutes.navigate(['dashboard/myvideos']);
    }
    else {
      this.oRoutes.navigate(['/dashboard/first-time-user']);

    }
  }

  CreateVideoOverviewComponent_SelectedLocation(event, oData) {

    this.AllLocaleSelected = false;
    if (event.target.checked) {
      // Pushing the object into array
      this.lSelectedLocations.push(oData);
      this.totalSelectedLocals = this.lSelectedLocations.length;
    }
    else {
      let removeIndex = this.lSelectedLocations.findIndex(itm => itm === oData);
      if (removeIndex !== -1)
        this.lSelectedLocations.splice(removeIndex, 1);
      if (this.lSelectedLocations.length == 0) {
        this.totalSelectedLocals = [];
      }
    }
    console.log("the Selected location List is ===>>>", this.lSelectedLocations);
  }

  public AllLocaleSelected: boolean = false;
  CreateVideoOverViewComponent_SelectAllLocations(event) {
    if (event.target.checked) {

      this.AllLocaleSelected = true;
      this.lSelectedLocations = [];
      this.lLocales.forEach(e => {
        e.selected = true;
        this.lSelectedLocations.push(e);
      });

      this.totalSelectedLocals = this.oTotalCount;
    }
    else {
      this.lSelectedLocations = [];
      this.AllLocaleSelected = false;
      this.lLocales.forEach(e => {
        e.selected = false;
      });
      this.totalSelectedLocals = [];
    }
    console.log("the All locations List is ===>>>", this.lSelectedLocations);
  }

  CreateVideoOverviewComponent_DrawPagination(nIncommingVisualCount: number) {
    this.lPagination = [];
    this.nPageSize = Math.ceil(nIncommingVisualCount / 10);
    for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
      this.lPagination.push(nPaginationIndex);
    }

  }


  CreateVideoOverviewComponent_fetchNextPage(incommingPage) {
    if (incommingPage !== this.nCurrentPage) {
      this.nCurrentPage = incommingPage;

      let num_items = 10;
      let num_skip = (incommingPage - 1) * 10;
      this.accountManager.getLocale(this.sUserEmail, this.sSessionID, num_items, num_skip).then(res => {

        this.lLocales = res['results'];


        this.lLocales.forEach(ele => {
          if (this.AllLocaleSelected) {
            ele.selected = true;
          } else {
            this.lSelectedLocations.forEach(ee => {

              if (ele.id == ee.id) {
                ele.selected = true;
              }
            });
          }

        });

      });
    }
  }


  visibleLocation = false;
  placementLocation: NzDrawerPlacement = 'right';
  openLocation(): void {

    if (!(this.lLocales.length > 0)) {
      this.accountManager.getLocale(this.sUserEmail, this.sSessionID, 10, 0).then(res => {
        console.log('locations', this.lLocales);
        this.lLocales = res['results'];

        this.oTotalCount = res['count']
        this.CreateVideoOverviewComponent_DrawPagination(this.oTotalCount);
        this.visibleLocation = true;
      });
    } else {
      this.visibleLocation = true;
    }

  }
  closeLocation(): void {
    this.visibleLocation = false;
  }
  UploadVideo(event:any)
  {
    

    this.ContentComponent_UploadFile(event.target.files[0]);
    
  }
  async ContentComponent_UploadFile(fileToUpload: any) {
    console.log("ContentComponent_UploadFile : File To Upload ==> ", fileToUpload);
    const video = document.createElement('video');
    video.src   = window.URL.createObjectURL(fileToUpload);
  let media = new mediaFile()
  media.file = fileToUpload;
  media.name = fileToUpload.name.split('.').slice(0, -1).join('.');

    try {
      console.log(" Upload single media called");
      await this.mediaManger.uploadMediaFilesAdmin(this.sUserEmail,this.sSessionID,media).then((result: visual) => {
        
      this.uploadedVdeo =   { filename: result.name, fpath: result.visual_path,duration: video.duration.toFixed(2), thumb: result.thumbnail, url: result.url, size: fileToUpload.size };
        console.log('upload', this.uploadedVdeo)
        // this.loader.hideLoader();
      setTimeout(() => {this.closeModal.nativeElement.click();}, 1000);
       
      },
        (error) => {
 
          console.error("ContentComponent_UploadFile : Error ==>", error);


          this.errorService.handleError('An error occurred while creating video. Please try again.',error.errorType,true,true);

          setTimeout(() => {this.closeModal.nativeElement.click();}, 1000);
        });
    }
    catch (error) {
      this.errorService.handleError(error.errorMessage,error.errorType,true);
      console.error("ContentComponent_UploadFile : ERROR ==>", error);
    }
  }
  close(){
    this.videoPopup = false;
  }
  previewVideo(){
    this.videoPreview.nativeElement.play();
    this.vidPreview = false;
  }
  displayVideoPopup(){
    this.videoPopup = true;

  }

   // modal media library
   visible = false;
   showMediaModal() {
    console.log('Button ok clicked!');
     this.visible = true;
     this.bDisplayMyMediaSection = true;
   }
   handleMediaOk(): void {
     this.visible = true;
   }
   closeMediaModal(): void {
     this.visible = false;
   }
   mediaSelected(event) {
    console.log("visual", event);
    this.lUserSelectedVisuals = event;
    console.log(this.lUserSelectedVisuals);
    var result =  this.lUserSelectedVisuals[0] 
    this.uploadedVdeo =   { filename: result.name, fpath: result.visual_path, thumb: result.thumbnail, url: result.url, };

    this.visible = false;
  }

  
  showPopUp(): void {
    console.log('Button ok clicked!');
    this.isModalVisible = true;
    
  }
  hidePopUP(): void {
    console.log('Button cancel clicked!');
    this.isModalVisible = false;
    //this.back();
    this.oRoutes.navigate(['my-videos/locationVideos']);
  }
  
    
  }
