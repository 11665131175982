
<app-dashboard-sidepanels></app-dashboard-sidepanels>
<app-header></app-header>
<section class="dashboard main-body bg-gray-7 h-100 width-72">
    <div class="container">
        <div class=" br12 bg-white page-shadow curve-bg h-80vh d-flex flex-column dashboard-main">
            <div class="py-4 px-4 pb-0 account-heading">
                <div class="row">
                    <div class="col-md-5">
                        <h3 class="weight-700 text-gray-2">What will you  <span class="text-primary-3">create</span> today?</h3>
                        <p class="mx-0 my-0 text-sm text-gray-3">Manage and create new videos.</p>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7">
                        <div class="text-end d-block">
                            <!-- <a href="#" id="mr-2"
                                class="weight-normal btn btn-outline-gray-radius md-btn mt-4 d-inline-block me-3 text-gray-2 text-xs btnShadow"><svg
                                    width="17" height="16" viewBox="0 0 17 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M1.33402 0.833313C1.61019 0.833313 1.83406 1.05717 1.83406 1.33331V12.6666C1.83406 13.4972 2.50359 14.1666 3.33418 14.1666H14.6684C14.9446 14.1666 15.1685 14.3905 15.1685 14.6666C15.1685 14.9428 14.9446 15.1666 14.6684 15.1666H3.33418C1.95126 15.1666 0.833984 14.0495 0.833984 12.6666V1.33331C0.833984 1.05717 1.05786 0.833313 1.33402 0.833313Z"
                                        fill="#7A7A7A" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M14.3269 4.28691C14.5366 4.46655 14.561 4.7822 14.3813 4.99191L11.3144 8.57191L11.3131 8.57344C10.617 9.37934 9.38031 9.42621 8.62757 8.67353L7.99204 8.03805C7.65486 7.69678 7.09344 7.71624 6.77332 8.08609C6.77312 8.08632 6.77292 8.08656 6.77272 8.08679L3.71384 11.6585C3.53421 11.8683 3.21855 11.8927 3.00879 11.7131C2.79902 11.5335 2.7746 11.2178 2.95423 11.0081L6.01447 7.43476L6.01583 7.43317C6.70887 6.63077 7.94618 6.57011 8.70232 7.33405C8.7027 7.33444 8.70309 7.33484 8.70348 7.33523L9.33473 7.96642C9.67522 8.30688 10.2382 8.28728 10.5557 7.92045C10.5558 7.92024 10.556 7.92003 10.5562 7.91982L13.6218 4.34136C13.8014 4.13164 14.1171 4.10726 14.3269 4.28691Z"
                                        fill="#7A7A7A" />
                                </svg>
                                 View Analytics</a> -->

                            <a (click)="createNewVideo()"
                                class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block text-xs"><svg
                                    width="17" height="16" viewBox="0 0 17 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.68363 4.98126C7.46518 4.17 5.83312 5.04234 5.83312 6.50486V9.51855C5.83312 10.9811 7.46518 11.8534 8.68363 11.0421L10.9468 9.53531C12.0354 8.8105 12.0354 7.21291 10.9468 6.4881L8.68363 4.98126ZM6.83319 6.50486C6.83319 5.84008 7.57504 5.44356 8.12888 5.81232L10.392 7.31916C10.8868 7.64862 10.8868 8.3748 10.392 8.70425L8.12888 10.2111C7.57504 10.5799 6.83319 10.1833 6.83319 9.51855V6.50486Z"
                                        fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.49999 15.9904C12.9186 15.9904 16.5006 12.413 16.5006 7.99999C16.5006 3.58699 12.9186 0.009552 8.49999 0.009552C4.08137 0.009552 0.499359 3.58699 0.499359 7.99999C0.499359 12.413 4.08137 15.9904 8.49999 15.9904ZM8.49999 14.9916C12.3663 14.9916 15.5005 11.8614 15.5005 7.99999C15.5005 4.13862 12.3663 1.00836 8.49999 1.00836C4.63369 1.00836 1.49944 4.13862 1.49944 7.99999C1.49944 11.8614 4.63369 14.9916 8.49999 14.9916Z"
                                        fill="white" />
                                </svg>
                                Create a New Video</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accountSecInner flex-grow-1">
                <div class="accountSecContent video-dashboard">
                    <div class="row row-cols-md-2 g-0 justify-content-between">
                        <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                            <div class="video-header row m-0">
                                <app-dashboard-breadcrumb class="col-lg-3"></app-dashboard-breadcrumb>
                                <ul class="text-end d-block col-lg-9 d-none" style="padding-right: 0;"> 
                                    <li>
                                        <form class="searchbox searchbox-open" [formGroup]="fSearchVideo">
                                            <input type="search" placeholder="Search for a video" name="search" formControlName="title"
                                                class="searchbox-input" >
                                            <input type="submit" class="searchbox-submit" value="GO">
                                            <span class="custom-searchbox-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                        fill="#A39FAA"></path>
                                                </svg>
                                            </span>
                                        </form>
                                    </li>
                                    <!-- <div class="formItm search-icon">
                                        <input id="loginEmail" type="email" value="" placeholder="Email Address" required>
                                        <label for="loginEmail" class="text-sm">Search your video</label>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                fill="#A39FAA" />
                                        </svg>
    
                                    </div> -->
                                </ul>
                            </div>
                            <div style="margin-left:35px ;">
                                <app-schuled-videos [dashboard-scheduled]="true" (nEmittedScheduledVideosCount)="SchuledVideosComponent_GetVideosCount($event)"></app-schuled-videos>
                        </div>
                    </div>

                    </div>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>

    </div>
</section>

<div class="overlay"></div>