<div class="py-4 px-4 account-heading">
  <div class="row">
    <div class="col-md-8 px-4">
      <h3 class="weight-700 text-gray-2">Media Libraries</h3>
      <p class="mx-0 my-0 text-sm">
        Effortlessly organize and access your media assets for efficient video production
      </p>
    </div>

    <div class="col-md-4 text-end">
      <!-- <button type="button"
                                class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 float-end mt-1 text-xs"
                                   
                                    (click)="openMedia()">
                                    Add library</button> -->
      <button type="button" class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 float-end mt-1 text-xs"
        (click)="openAddMediaLibraryAdmin()">
        Add Library
      </button>

      <!-- <a
        href="javascript:void(0)"
        class="float-end filter-btn d-inline-block my-1 ms-2 me-4"
        (click)="openFilter()"
      >
        <svg
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
            fill="#404243"
          ></path>
        </svg>
      </a> -->
    </div>
  </div>
</div>
<div class="row video-header mx-0">
  <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
    <li class="col-md-4 mb-2 pe-3">
      <div class="formItm search-icon searchDropdown">
        <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search Media Library"
          required="" (keyup.enter)="searchBrand()" />
        <label for="search-feild" class="text-sm" style="cursor: default">Search Media Library</label>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="searchBrand()"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
            fill="#A39FAA"></path>
        </svg>
      </div>
      <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
        <div>{{ searchTerm }}</div>
        <mat-icon class="link">close</mat-icon>
      </div>
    </li>
    <li class="col-md-2 mb-2 ps-2" id="selectpreff">
      <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
        <div class="dropdown dropdownBtn">
          <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()"
            (blur)="hideSearchSortTypeDD($event)">
            <span class="dropdownBtnText"><strong>Sort by </strong>
              {{ searchBy == "created" ? "Created" : "" }}
              {{ searchBy == "_id" ? "Email" : "" }}
              {{ searchBy == "name" ? "Name" : "" }}
            </span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
            <li>
              <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('name')">By Name
              </a>
            </li>
            <li>
              <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
              </a>
            </li>
          </ul>
        </div>

        <!-- <select>
                      <option selected>Last Modified</option>
                  </select> -->
        <div class="sort-arrows pos-right">
          <!-- <mat-icon *ngIf="sortType == -1" (click)="sortTypeSearch(1)"
                      >download</mat-icon
                    >
                    <mat-icon *ngIf="sortType == 1" (click)="sortTypeSearch(-1)"
                      >upload</mat-icon> -->
          <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1"
            (click)="sortTypeSearch(1)">
          <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1"
            (click)="sortTypeSearch(-1)">
        </div>
      </div>
    </li>

    <li class="col-md-4 mb-2">
    </li>
  </ul>
</div>
<div class="accountSecInner flex-grow-1">
  <div class="accountSecContent">
    <div class="accountSecContent pb-2">
      <div class="data-table m-0 pt-4 overflow-auto overflow-table">
        <div class="my-3">
          <p>
            <span class="text-lg">Total Media Libraries: </span>
            <b class="weight-600"> {{mediaLibList?.result? mediaLibList?.result.length :
              '0'}}/{{mediaLibList?.total_count}}</b>
          </p>
        </div>
        <table class="table overflow-auto tableCustom table-hover mt-4 mb-0 ">
          <thead>
            <tr>
              <th scope="col" class="tableCheck">
                <div class="custom-switch">
                  <input type="checkbox" class="check form-check-input d-none" id="checkAll" />
                </div>
              </th>
              <th scope="col">Library Name</th>
              <th scope="col">Users</th>
              <th scope="col">Brands</th>
              <!-- <th scope="col">Assets</th> -->
              <th scope="col">Status</th>
              <th scope="col" class="tableAction">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let media of mediaLibList?.result">
              <td scope="row" class="tableCheck">
                <div class="custom-switch">
                  <input type="checkbox" class="check form-check-input" />
                </div>
              </td>
              <td>
                <a [routerLink]="[
                    '/account-setting/media-libraries/',
                    media?._id?.$oid,
                    'account'
                  ]">{{ media.name }}</a>
              </td>
              <td>{{ media.users ? media.users : 0 }}</td>
              <td>{{ media.brands ? media.brands : 0 }}</td>
              <!-- <td>{{ media.asset_source ? media.asset_source : 0 }}</td> -->
              <td>{{ media.activation_status }}</td>
              <td class="tableAction">
                <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                  <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                      fill="#7A7A7A" />
                  </svg>
                </a>
                <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a [routerLink]="[
                        '/account-setting/media-libraries/',
                        media?._id?.$oid,
                        'account'
                      ]">Detail</a>
                  </li>
                  <!-- <li (click)="showModal(media)">Inactive</li> -->
                  <li class="link" *ngIf="media.activation_status != 'active'"
                    (click)="isVisible = true; mediaObj = media">
                    Delete
                  </li>
                  <li>
                    <a [routerLink]="[
                        '/account-setting/media-libraries/',
                        media?._id?.$oid,
                        'media'
                      ]">View Media
                    </a>
                  </li>
                </ul>
              </td>
            </tr>

            <!-- <tr *ngFor="let media of this.mediaManger.mediaLibs">
                                                <td scope="row" class="tableCheck">
                                                    <div class="custom-switch">
                                                        <input type="checkbox" class="check form-check-input">
                                                    </div>
                                                </td>
                                                <td><a routerLink="/account-setting/media-library"
                                                        (click)="UserMediaComponent_NavigateToMediaSection(media)">{{media.name}}</a>
                                                </td>
                                                <td>{{media.total_items}}</td>
                                                <td>{{media.activation_status}}</td>
                                                <td class="tableAction">
                                                    <a href="javascript:void(0)" id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown">
                                                        <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                                                fill="#7A7A7A" />
                                                        </svg>
                                                    </a>
                                                    <ul class="dropdown-menu add_location page-shadow"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li
                                                            (click)="UserMediaComponent_NavigateToMediaSection(media)">
                                                            Edit</li>
                                                        <li *ngIf="media.activation_status == 'active'" (click)="showModal(media)">Inactive</li>
                                                          <li  *ngIf="media.activation_status != 'active'" (click)="updateMediaLibrary(media)">Active</li>
                                                        
                                                    </ul>
                                                </td>
                                            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 text-end" style="padding-right:42px ">
        <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="mediaLibList?.total_count"
          [pageSize]="limit" [pageSizeOptions]="[15, 20, 25, 50]" (page)="loadPageEvent($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>



    <div class="overlay sidebarOverlay"></div>

    <!-- Add library sidbar end -->

    <!-- data filter  sidebar Start-->
    <nz-drawer [nzClosable]="false" [nzVisible]="visibleFilter" [nzPlacement]="placementFilter"
      (nzOnClose)="closeFilter()" [nzWidth]="440">
      <ng-container *nzDrawerContent>
        <nav class="rightSideBar sidebar-block" style="right: 0">
          <div class="sidebar-header">
            <h3 class="text-gray-2 weight-700 d-flex align-items-center">
              Filters
              <a href="javascript:void(0)" class="text-xs text-primary-4 weight-normal ms-3" (click)="clearAll()">Clear
                All</a>
            </h3>
            <div class="closeRightSideBar" (click)="closeFilter()">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                  fill="#F2F2F5" />
                <path
                  d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                  fill="#A7ABB6" />
              </svg>
            </div>
          </div>
          <div class="sidebar-content pt-0 flex-grow-1 overflow-auto" style="height: 160px">
            <div class="custom-select-box mb-4">
              <h3 class="text-gray-2 text-lg mb-2">Account Status</h3>
              <div class="custom-switch">
                <label>
                  <input type="checkbox" (change)="checkValueActive(isActive)" [(ngModel)]="isActive"
                    class="check form-check-input" />
                  Active
                </label>
              </div>
              <div class="custom-switch">
                <label>
                  <input type="checkbox" (change)="checkValueDeactive(isDeactive)" [(ngModel)]="isDeactive"
                    class="check form-check-input" />
                  Deactive
                </label>
              </div>
            </div>
          </div>
          <div class="side-footer side-content d-flex align-items-center">
            <!-- <button (click)="apply()" class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4">Apply</button> -->
          </div>
        </nav>
      </ng-container>
    </nz-drawer>
    <!-- data filter sidebar end-->

    <!-- Add media library (Admin) sidbar start -->
    <nz-drawer [nzClosable]="false" [nzVisible]="showAddMediaLibraryAdmin" [nzPlacement]="placementMediaLibraryAdmin"
      (nzOnClose)="closeAddMediaLibraryAdmin()" [nzWidth]="478">
      <ng-container *nzDrawerContent>
        <nav>
          <div class="sidebar-header">
            <h3 class="text-gray-2 weight-700">Add Media Library</h3>
            <div class="closeRightSideBar" (click)="closeAddMediaLibraryAdmin()">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                  fill="#F2F2F5" />
                <path
                  d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                  fill="#A7ABB6" />
              </svg>
            </div>
          </div>
          <form [formGroup]="addMediaLibraryAdminForm" *ngIf="addMediaLibraryAdminForm">
            <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
              <div class="formItm mb-0">
                <input formControlName="name" type="text" name="name" required="" />
                <label for="name" class="text-sm">Library Name</label>
                <span *ngIf="
                  addMediaLibraryAdminForm?.controls['name']?.hasError(
                    'required'
                  ) &&
                  (addMediaLibraryAdminForm?.controls['name']?.touched ||
                    addMediaFormSubmitted)
                " class="error">
                  Please enter Library Name.</span>
              </div>
              <div class="formItm mb-3">
                <div class="d-flex">
                  <label class="btnSwitch">
                    <input class="px-2" formControlName="status" type="checkbox" formControlName="activation_status" />
                    <span class="btnSwitchCheck"></span>
                    Active
                  </label>
                </div>
              </div>
              <!-- <div class="formItm down_arrow">
              <p class="text-md text-gray-3 mt-4 mb-0">Asset Source</p>
              <div class="dropdown dropdownSelect">
                <button
                  class="dropdown-toggle text-md"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ addMediaLibraryAdminForm?.controls["asset_source"].value }}
                </button>
                <ul class="dropdown-menu dropdownMenu">
                  <li class="selected">
                    <a
                      class="dropdown-item text-md"
                      (click)="setAssetSource('Manual Upload')"
                      >Manual Upload</a
                    >
                  </li>
                </ul>
              </div>
               <select class="form-select" aria-label="Default select example" 
    (change) = "getAssetSource($any($event.target).value)">
    <option value="default" >select option</option>
      <option value="Manual Upload" >Manual Upload</option>
      
    </select> 
            </div> -->

              <!-- <div class="formItm pt-0 mb-3">
              <a
                href="javascript:void(0)"
                class="text-sm mt-3 d-block text-primary-4 d-inline-block"
              >
                Configure Access
              </a>
            </div> -->

              <div class="mt-4 pt-5">
                <p class="text-lg text-black weight-600">
                  Library Access and Usage
                </p>

                <div class="form-check ms-1">
                  <input class="form-check-input" type="radio" name="access" formControlName="access"
                    value="global_access" (click)="changeAccessLimit('global_access')" />
                  <label class="form-check-label"> Global Access </label>
                </div>
                <div class="form-check  ms-1">
                  <input formControlName="access" value="limited_access" class="form-check-input" type="radio"
                    name="access" (click)="changeAccessLimit('limited_access')" />
                  <label class="form-check-label"> Limited Access </label>
                </div>
              </div>

              <div class="formItm down_arrow pt-15" *ngIf="showAssignUserDropDown">
                <div class="lib-access">
                  <span *ngIf="selectedAddUsers.length == 0 && addMediaFormSubmitted" class="error">
                    Please select atleast one user.</span>
                  <p class="weight-600 my-4">Assign Users</p>
                  <div class="search-sec p-3 mt-3">
                    <p class="text-md pb-3">
                      Search Users
                      <!-- <button
                  type="button"
                  class="btn-close float-end"
                  aria-label="Close"
                ></button> -->
                      <mat-form-field>
                        <input formControlName="searchUserText" #mediaLibraryListAutoComplete (ngModelChange)="
                          this.modelUserSearchChanged.next($event)
                        " matInput aria-label="State" [matAutocomplete]="autoCompleteUser" />
                        <mat-autocomplete #autoCompleteUser="matAutocomplete">
                          <mat-option *ngIf="isUserSearchLoading" class="is-loading">Loading...</mat-option>
                          <ng-container *ngIf="!isUserSearchLoading">
                            <mat-option *ngFor="let user of userList.result" [value]="user?._id"
                              (click)="setAddUsers(user)">
                              <span><b>{{ user._id }}</b></span>
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                      </mat-form-field>
                    </p>
                    <ul class="p-0">
                      <div class="part1">
                        <li class="d-block text-md" *ngFor="
                          let selecteduser of selectedAddUsers
                            | slice: 0:userListLoopLimit
                        ">
                          {{selecteduser._id}}
                          <button type="button" (click)="removeAddUsers(selecteduser)" class="btn-close float-end"
                            aria-label="Close"></button>
                        </li>
                      </div>
                      <a href="javascript:void(0)" *ngIf="
                        selectedAddUsers.length > 3 && userListLoopLimit == 3
                      " (click)="viewAllSelectedUsers(2000)" class="vall text-md">View All</a>
                      <a href="javascript:void(0)" *ngIf="
                        selectedAddUsers.length > 3 && userListLoopLimit > 3
                      " (click)="viewAllSelectedUsers(3)" class="vall text-md">Show Less</a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="side-footer side-content d-flex align-items-center ps-0">
              <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5"
                (click)="addNewMediaLibraryAdmin()">
                <span class="py-1 d-block">Add Media Library</span>
              </button>

            </div>
          </form>
        </nav>
      </ng-container>
    </nz-drawer>
    <!-- Add media library (Admin) sidbar end -->

    <!-- Modal pop-up Start-->
    <nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
      (nzOnCancel)="handleCancel()">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

            <h5>Are you sure you want to Delete this media library?</h5>
            <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
              You'll not be able to watch this Library again.
            </p>
            <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
            <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm" (click)="deleteMediaLib(mediaObj)">Delete</a>
          </div>
        </div>
      </div>
    </nz-modal>
  </div>