import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cSessionService } from './../../../services/session.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { locale } from './../../../models/account';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ErrorService } from './../../../services/error.service';
declare var $: any;
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  @ViewChild('resetField') resetField: ElementRef;
  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  fSearchForm: FormGroup;
  public fAddLocationForm: FormGroup;
  fEditLocationForm: FormGroup;
  sSessionId: string = "";
  sUserEmail: string = "";
  lLocales: any = [];
  totalLocals: any;
  oTotalCount: any;
  oLocationName: any;
  nPageSize: number = 10;
  nCurrentPage = 1;
 public lPagination: any = [];
  oLocationId: any;
  linesR: any = [];
  lines: any = [];
  lCSVList: any = [];
  bCloseModal: boolean = false;
  constructor(private oFormBuilder: FormBuilder,  public accountManger: AccountManagerService, private oSessionService: cSessionService,
    public errorService:ErrorService) { }

  ngOnInit() {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Locations");  

    this.InitializeAddLocationsForm();
    this.accountManger.getLocale(this.sUserEmail, this.sSessionId,this.nPageSize,0).then(res => {
this.oTotalCount = res['count'];
if(this.oTotalCount>this.nPageSize){
  this.totalLocals = this.nPageSize;
}

this.createPagination();
    }).catch(err => {


    });

    
  }
  creLocals(){
    this.oTotalCount = 0;
    this.totalLocals = 0;
    this.accountManger.getLocale(this.sUserEmail, this.sSessionId,this.nPageSize,0).then(res => {
      this.oTotalCount = res['count'];
      if(this.oTotalCount>this.nPageSize){
        this.totalLocals = this.nPageSize;
      }
      
      this.createPagination();
          }).catch(err => {
      
      
          });
}

  createPagination()
  {
    
    this.lPagination=[];
    let pages = Math.ceil(this.oTotalCount/this.nPageSize);
    for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) 
    {
      this.lPagination.push(nPaginationIndex);
    }
    console.log("Pagination List : ==> ",this.lPagination);
  }
  loadPage(pageNumber:number)
  {
    if(pageNumber!==this.nCurrentPage)
      {
        this.nCurrentPage=pageNumber;
        let skipVideos = (pageNumber-1)*this.nPageSize;

        this.accountManger.getLocale(this.sUserEmail, this.sSessionId,this.nPageSize,skipVideos).then(res => {
        this.totalLocals =res['results'].length;
              });  

      }
  }

  deleteLocale(locale: locale) {
    this.accountManger.deleteLocale(this.sUserEmail, this.sSessionId, locale).then(res => {
      if(res){
       
        this.totalLocals = this.totalLocals -1;
        if(this.totalLocals%this.nPageSize==0){
              
          this.creLocals();
          }
      }
    }).catch(err => {
          
        this.errorService.handleError(err.errorMessage,err.errorType,true);

    });
  }


  addLocale() {

    if (this.fAddLocationForm.value.name != '') {

      let loc = {
        id: this.fAddLocationForm.value.id,
        address:
        {
          city: this.fAddLocationForm.value.city,
          state: this.fAddLocationForm.value.state,
          line1: this.fAddLocationForm.value.line1,
          line2: this.fAddLocationForm.value.line2,
          postal_code: this.fAddLocationForm.value.postal_code
        },
        name: this.fAddLocationForm.value.name,
        url: this.fAddLocationForm.value.url,
        phone: this.fAddLocationForm.value.phone
      } as locale;

      let locals = [loc];

      if (loc.id == '') {
        this.accountManger.createLocale(this.sUserEmail, this.sSessionId, locals).then(res => {
          if(res){
            this.totalLocals = this.totalLocals +1;
            if(this.totalLocals>=this.nPageSize){
              
            this.creLocals();
            }
          }
          this.InitializeAddLocationsForm();
          this.closeLocation();

        }).catch(err => {
          this.errorService.handleError(err.errorMessage,err.errorType,true); });

      } else {
        this.accountManger.editLocale(this.sUserEmail, this.sSessionId, loc).then(res => {
          this.InitializeAddLocationsForm();
          this.closeLocation();

        }).catch(err => {

          
        this.errorService.handleError(err.errorMessage,err.errorType,true);
        });
      }




    } else {
      this.fAddLocationForm.markAllAsTouched();
    }
  }


  


  editLocation(loc: locale) {
    this.fAddLocationForm.get('id').setValue(loc.id);
    this.fAddLocationForm.get('name').setValue(loc.name);
    this.fAddLocationForm.get('city').setValue(loc.address.city);
    this.fAddLocationForm.get('state').setValue(loc.address.state);
    this.fAddLocationForm.get('line1').setValue(loc.address.line1);
    this.fAddLocationForm.get('line2').setValue(loc.address.line2);
    this.fAddLocationForm.get('postal_code').setValue(loc.address.postal_code);
    this.fAddLocationForm.get('url').setValue(loc.url);
    this.fAddLocationForm.get('phone').setValue(loc.phone);

    this.openLocation();
  }

  InitializeAddLocationsForm() {
    this.fAddLocationForm = this.oFormBuilder.group(
      {
        id: [''],
        name: ['', [Validators.required]],
        city: [''],
        state: [''],
        line1: [''],
        line2: [''],
        postal_code: [''],
        url: [''],
        phone: [''],
      })
  }


  LocationComponent_InitializeSearchLocationForm() {
    this.fSearchForm = this.oFormBuilder.group({ search: '' });
  }


  async LocationsComponent_ReadCSVFile(event): Promise<void> {
    this.lines = [];
    this.linesR = [];
    let file = event.target.files[0];
    console.log("LocationsComponent_ReadCSVFile : csv To read ==> ", file);
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = [];
      allTextLines = csv.split(/\r|\n|\r/);

      console.log("-------------" + allTextLines);
      let headers = allTextLines[0].split(';');
      let data = headers;
      for (let j = 0; j < headers.length; j++) {
        this.lines.push(data[j]);
      }
      console.log("the keys ===>>>", this.lines);
      let arrl = allTextLines.length;
      let rows = [];
      for (let i = 1; i < arrl - 1; i++) {
        console.log('rows>>>>>>>'+allTextLines[i].split(';'));
        if(allTextLines[i].split(';') != '')
        rows.push(allTextLines[i].split(';'));
        console.log('row-----'+rows);

      }

      console.log('rows---------',rows);
      for (let j = 0; j < arrl - 1; j++) {
        this.linesR.push(rows[j]);
      }
      console.log("the data in rows ===>>>", this.linesR);
      this.LocationsComponent_CreateKeyValuePairs();
    }
  }
  LocationsComponent_CreateKeyValuePairs() {
    let lPayload = []
    let lHeaders = [];
    lHeaders = this.lines[0].split(',');
    for (let nRowsIndex = 0; nRowsIndex < this.linesR.length; nRowsIndex++) {
      let obj = {}
      let nValueOfRow: any;
      if (this.linesR[nRowsIndex] != undefined) {
        nValueOfRow = this.linesR[nRowsIndex]
        nValueOfRow = nValueOfRow[0].split(',');
        for (let nFirstRowIndex = 0; nFirstRowIndex < nValueOfRow.length; nFirstRowIndex++) {
          obj[lHeaders[nFirstRowIndex]] = nValueOfRow[nFirstRowIndex]
        }
        lPayload.push(obj);
      }
    }
    lPayload.forEach(c => {
      this.lCSVList.push({
        name: c.name,
        address:
        {
          city: c.city,
          state: c.state,
          line1: c.line1,
          line2: c.line2,
          postal_code: c.postal_code
        },
        url: c.url,
        phone: c.phone
      })

    });

    console.log("The Final Payload ==>>>>", this.lCSVList);
    this.LocationsComponent_SendAddCSVLocationRequest(this.lCSVList)
  }
  LocationsComponent_SendAddCSVLocationRequest(lIncommingCSVlist) {
    let body =
    {
      email: this.sUserEmail,
      session_id: this.sSessionId,
      locales: lIncommingCSVlist
    }

    this.accountManger.createLocale(this.sUserEmail, this.sSessionId, lIncommingCSVlist).then(res => {



  this.InitializeAddLocationsForm();

     }).catch(err => { 
      this.errorService.handleError(err.errorMessage,err.errorType,true);});
    this.bCloseModal = true;
  }
  LocationsComponent_ResetField() {
    this.resetField.nativeElement.value = "";
  }

visibleLocation = false;
  placementLocation: NzDrawerPlacement = 'right';
  openLocation(): void {
    this.visibleLocation = true;
  }
  closeLocation(): void {
    this.visibleLocation = false;
  }

  
// modal Upload Location File
isVisible = false;
showModal(): void {
  this.isVisible = true;
}
handleCancel(): void {
  this.isVisible = false;
}

}
