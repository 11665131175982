 
                        <div class="py-4 px-4 account-heading">
                            <div class="row">
                                <div class="col-md-7 d-flex align-items-center">
                                    <h3 class="weight-700 text-gray-2">My Videos</h3>
                                </div>
                                <div class="col-md-5">
                                    <div class="d-flex justify-content-end">
                                        <div class="formItm search-icon d-none">
                                            <input id="loginEmail" type="email" value="" placeholder="Email Address" required>
                                            <label for="loginEmail" class="text-sm">Search your video</label>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                    fill="#A39FAA" />
                                            </svg>
        
                                        </div>
        
        
                                        <a (click)="createNewVideo()"
                                            class="weight-normal btn btn-primary btn-round-6 md-btn mt-4 d-inline-block"><svg
                                                width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.68363 4.98126C7.46518 4.17 5.83312 5.04234 5.83312 6.50486V9.51855C5.83312 10.9811 7.46518 11.8534 8.68363 11.0421L10.9468 9.53531C12.0354 8.8105 12.0354 7.21291 10.9468 6.4881L8.68363 4.98126ZM6.83319 6.50486C6.83319 5.84008 7.57504 5.44356 8.12888 5.81232L10.392 7.31916C10.8868 7.64862 10.8868 8.3748 10.392 8.70425L8.12888 10.2111C7.57504 10.5799 6.83319 10.1833 6.83319 9.51855V6.50486Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.49999 15.9904C12.9186 15.9904 16.5006 12.413 16.5006 7.99999C16.5006 3.58699 12.9186 0.009552 8.49999 0.009552C4.08137 0.009552 0.499359 3.58699 0.499359 7.99999C0.499359 12.413 4.08137 15.9904 8.49999 15.9904ZM8.49999 14.9916C12.3663 14.9916 15.5005 11.8614 15.5005 7.99999C15.5005 4.13862 12.3663 1.00836 8.49999 1.00836C4.63369 1.00836 1.49944 4.13862 1.49944 7.99999C1.49944 11.8614 4.63369 14.9916 8.49999 14.9916Z"
                                                    fill="white" />
                                            </svg>
                                            Create a New Video</a>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                      <app-schuled-videos (nEmittedScheduledVideosCount)="SchuledVideosComponent_GetVideosCount($event)"></app-schuled-videos>  

                       
            