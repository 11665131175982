import { Component, OnInit } from '@angular/core';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { ErrorService } from './../../../services/error.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-dashboard-download',
  templateUrl: './dashboard-download.component.html',
  styleUrls: ['./dashboard-download.component.scss']
})
export class DashboardDownloadComponent implements OnInit {

  oVideoDetails : any;
  sUserEmail : string;
  sSessionId : string;
  sSignedURL : string;
  constructor(private oSessionService : cSessionService,private loader : LoaderService, private oWebSocketService : cWebSocketService, private oDataService : cDataService,public videoManager:VideoManagerService,  public errorService:ErrorService) { }

  async ngOnInit() 
  {
 
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();  
    this.sSignedURL = this.videoManager.selectedVideo.video_url;
  }

  DashboardHeaderComponent_DownloadVideo()
  {

    this.loader.showLoader();
    fetch(this.sSignedURL)
    .then(response => response.blob())
    .then(blob => 
    {
      console.log("signed Url", this.sSignedURL);
      this.loader.hideLoader();
      console.log("blob",  blob);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "pronto.mp4";
      link.click();
    })
    .catch(error=>
    {
      this.loader.hideLoader();
      this.errorService.handleError(error.errorMessage,error.errorType,true);
      console.error("DashboardHeaderComponent_DownloadVideo : Error ==>",error);
    });
  }


}
