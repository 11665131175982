import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cDataService } from './../../services/data.service';
import { cWebSocketService } from './../../services/websocket.service';

@Component({
  selector: 'app-public-videos',
  templateUrl: './public-videos.component.html',
  styleUrls: ['./public-videos.component.scss']
})
export class PublicVideosComponent implements OnInit {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  bDisplayProntoDownload: boolean = true;
  oPublicUrl: string;
  sVideoID: string;
  fCommentsForm: FormGroup;
  oVideoDetails : any;
  lKeyTerms : any = [];
  bEnablePostingComment : boolean = false;
  lComments : any = [];
  nTotalViews : number = 0;
  nTotalLikes : number = 0;
  bLiked: boolean;

  constructor(private oWebSocketService: cWebSocketService, private oFormBuilder: FormBuilder, private oActivatedRoute: ActivatedRoute,private oDataService : cDataService) 
  {
    this.sVideoID = this.oActivatedRoute.snapshot.queryParams['video_id'];
  }

  ngOnInit(): void 
  {
    this.PublicVideosComponent_InitializeCommentsForm();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.PublicVideosComponent_SendPublicVideoDetailRequest();
    this.PublicVideosComponent_DetectChange();
  }
  PublicVideosComponent_InitializeCommentsForm()
  {
    this.fCommentsForm = this.oFormBuilder.group
    ({
      video_id: this.sVideoID,
      comment: ''
    });
    this.bEnablePostingComment=false;
  }
  PublicVideosComponent_CopyToClipBoard() 
  {
    navigator.clipboard.writeText(this.oPublicUrl);
    console.log(navigator.clipboard.writeText(this.oPublicUrl))
  }
  PublicVideosComponent_DisplayProntoDownload()
  {
    this.bDisplayProntoDownload = true
  }
  PublicVideosComponent_PlayVideo()
  {
    this.videoPreview.nativeElement.play();
  }
  PublicVideosComponent_ResetVideo()
  {
    this.videoPreview.nativeElement.load();
    var element = document.getElementById("TemplateThumbnail");
    element.classList.remove("videoWrapperActive");
  }
  PublicVideosComponent_SendPublicVideoDetailRequest()
  {
    let oPublishVideoRequest = 
    {
      video_id : this.sVideoID, 
    }
    this.oWebSocketService.WebSocket_RegisterEvent("published_video_data_result", this.PublicVideosComponent_HandlePublicVideoRequestResponse);
    console.log(" PublicVideosComponent_SendPublicVideoDetailRequest  : Public Video Request to WebSocket ==> ", oPublishVideoRequest);
    this.oWebSocketService.WebSocket_SendMessage("published_video_data_request", JSON.stringify(oPublishVideoRequest));
  }
  PublicVideosComponent_HandlePublicVideoRequestResponse = async (InComingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("PublicVideosComponent_HandlePublicVideoRequestResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("PublicVideosComponent_HandlePublicVideoRequestResponse : ==>>", oFormatedIncommingMessage);
      this.oPublicUrl = oFormatedIncommingMessage.video.public_url;
      this.oVideoDetails = oFormatedIncommingMessage.video;
      this.lComments = this.oVideoDetails.comments;
      this.nTotalViews = this.oVideoDetails.views;
      this.nTotalLikes = this.oVideoDetails.likes;
      let lRemoveDuplicates = new Set(this.oVideoDetails.key_terms);
      this.lKeyTerms = [...lRemoveDuplicates];
      this.PublicVideosComponent_AddViewRequest();
    }
  }
  PublicVideosComponent_DetectChange()
  {
    this.fCommentsForm.get('comment').valueChanges.subscribe(data=>
    {
      //console.log("data : ==> ", data);
      this.bEnablePostingComment = data===""?false:true;
    });
  }
  PublicVideosComponent_AddCommentRequest()
  {
    this.oWebSocketService.WebSocket_UnRegisterEvent("add_video_comment_result", this.PublicVideosComponent_HandleAddCommentRequestResponse);
    this.oWebSocketService.WebSocket_RegisterEvent("add_video_comment_result", this.PublicVideosComponent_HandleAddCommentRequestResponse);
    console.log("PublicVideosComponent_AddCommentRequest : Add Comment Request to WebSocket ==> ", this.fCommentsForm.value);
    this.oWebSocketService.WebSocket_SendMessage("add_video_comment_request", JSON.stringify(this.fCommentsForm.value));
  }
  PublicVideosComponent_HandleAddCommentRequestResponse = (InComingMessage) => 
  {
    console.log("PublicVideosComponent_HandleAddCommentRequestResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if(oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("PublicVideosComponent_HandlePublicVideoRequestResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("Result ==>",oFormatedIncommingMessage);
      this.lComments.unshift(this.fCommentsForm.get('comment').value);
      this.PublicVideosComponent_InitializeCommentsForm();
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("add_video_comment_result", this.PublicVideosComponent_HandleAddCommentRequestResponse);
  }
  PublicVideosComponent_AddViewRequest()
  {
    this.oWebSocketService.WebSocket_RegisterEvent("add_video_view_result", this.PublicVideosComponent_HandleAddViewReponse);
    let oPayload = { video_id : this.sVideoID}
    this.oWebSocketService.WebSocket_SendMessage("add_video_view_request", JSON.stringify(oPayload));
  }
  PublicVideosComponent_HandleAddViewReponse = (InComingMessage) => 
  {
    console.log("PublicVideosComponent_HandleAddViewReponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("PublicVideosComponent_HandleAddViewReponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      this.nTotalViews++;
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("add_video_comment_result", this.PublicVideosComponent_HandleAddViewReponse);
  }
  PublicVideosComponent_AddLikeRequest()
  {
    this.oWebSocketService.WebSocket_RegisterEvent("add_video_like_result", this.PublicVideosComponent_HandleAddLikeReponse);
    let oPayload = { video_id : this.sVideoID}
    this.oWebSocketService.WebSocket_SendMessage("add_video_like_request", JSON.stringify(oPayload));
    this.bLiked = !this.bLiked
  }
  PublicVideosComponent_HandleAddLikeReponse = (InComingMessage) => 
  {
    console.log("PublicVideosComponent_HandleAddLikeReponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("PublicVideosComponent_HandleAddLikeReponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      this.nTotalLikes++;
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("add_like_result", this.PublicVideosComponent_HandleAddLikeReponse);
  }
  // async PublicVideosComponent_DownloadVideo()
  // {
  //   let sFpath = this.oPublicUrl.substr(this.oPublicUrl.indexOf('user_videos'),this.oPublicUrl.length-1);
  //   console.log("path : ",sFpath);
  //   let sPublicURL = await this.oDataService.DataService_GetPublicSignedURLForDownload(sFpath);

  //   fetch(sPublicURL)
  //   .then(response => response.blob())
  //   .then(blob => 
  //   {
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = "pronto.mp4";
  //     link.click();
  //   })
  //   .catch(error=>
  //   {
  //     console.error("PublicVideosComponent_DownloadVideo : ==>",error);
  //   });
  // }

}
