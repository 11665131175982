import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ErrorService } from './../../../services/error.service';

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {

  fLoginForm: FormGroup;
  bDisplayPassword: boolean = false;
  bDisplayConfirmPassword: boolean = false;
  specialCharacterPassword = false;
  specialCharacterRegex= '^[A-Za-z0-9]*$';
  code:string;
  email:string
  isPasswordMatched = false;
  oFormContrl: FormControl;
  
  constructor(private oFormBuilder: FormBuilder,public activeroute:ActivatedRoute,private route:Router,private onboardingManager:OnboardingManagerService,public errorService:ErrorService) { }

  ngOnInit(): void {
     
    this.activeroute.queryParams.subscribe(params => {
      this.code = params['code'];
      this.email = params['email'];
      
  });


    //  const passRejex = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z][a-zA-Z0-9]{7,}$';
     
    //  const passRejex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*])(?=.{10,})/;
      
    this.fLoginForm = this.oFormBuilder.group(
    {
      newPassword: this.oFormBuilder.control('', [
        this.validateRegexs('(?=.{8,})', 'length'),
        this.validateRegexs('(?=.*?[A-Z])(?=.*?[a-z])(?=.*[0-9])', 'text'),
        this.validateRegexs('(?=.*?[#?!@$%^&*-])', 'specialcharacter'),
        
      ]),
      
      confirmPassword: ['', [Validators.required ]]

    });


    

 
  }

 get newPassword(): FormControl {
  return this.fLoginForm.get('newPassword') as FormControl;
}
 
  validateRegexs(regex: string, key: string): ValidatorFn {

  const regexExp = new RegExp(regex)
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = regexExp.test(control.value);
    console.log(valid+"---"+control.value );
    const res = {} as any;
    if(!valid) {
      res[key] = {value: control.value}
      return res;
    }
    return null;
  };
}





  confirmPasswordChnage(){
    console.log("Value updated");
    if(this.fLoginForm.controls["newPassword"].value == this.fLoginForm.controls["confirmPassword"].value){
        this.isPasswordMatched = true;
    }
    else{
        this.isPasswordMatched = false;
    }
    
  }



    resetComponent_TogglePasswordVisibility()
  {
    this.bDisplayPassword = !this.bDisplayPassword
  }
    resetComponent_ToggleConfirmPasswordVisibility()
  {
    this.bDisplayConfirmPassword = !this.bDisplayConfirmPassword
  }


  resetPassword()
  {
    console.log(this.fLoginForm.invalid);
    console.log(this.fLoginForm);
    if(this.fLoginForm.valid)
    {
      this.onboardingManager.updateForgotPassword(this.email,this.code,this.fLoginForm.controls["newPassword"].value ).then(res=>
        {

           this.route.navigate(['/password-reset-success-message']);
        }).catch(err=>
          {
            this.errorService.handleError(err.errorMessage,err.errorType,true);

          });
    }
  }

}


function newPassword() {
  throw new Error('Function not implemented.');
}

