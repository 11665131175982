import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { video } from '../../models/video';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpClient) { }

  Videos(email,session_id,location_videos,publish_status,sortType,num_items,num_skip):Promise<unknown>
  {
    let sort=""
    if(sortType == "Last Modified")
    {
sort="&last_modified=true";
    }else
    {
      sort="&created=true";
    }
    let status="";
if(publish_status !='')
{
  status="&publish_status="+publish_status;
}

     return this.http.get(environment.API_HOST + 'videos?email='+email+'&session_id='+session_id+'&location_videos='+location_videos+ status+'&num_items='+num_items+'&num_skip='+num_skip+sort).toPromise();
  }

  searchVideo(email,session_id,title,last_modified,publish_status,sortType,num_items,num_skip):Promise<unknown>
  {
    let sort=""
    if(sortType == "Last Modified")
    {
sort="&last_modified=true";
    }else
    {
      sort="&created=true";
    }
    let status="";
    if(publish_status !='')
    {
      status="&publish_status="+publish_status;
    }

     return this.http.get(environment.API_HOST + 'search_video?email='+email+'&session_id='+session_id+'&title='+title+'&last_modified='+last_modified+ status+'&num_items='+num_items+'&num_skip='+num_skip+sort).toPromise();
  }

  updateStatus(email,session_id,video_id,publish_status):Promise<unknown>
  {
     return this.http.post(environment.API_HOST + 'update_video_status',{email:email,session_id:session_id,video_id:video_id,publish_status:publish_status}).toPromise();
  }

  replaceClip(email,session_id,video_id,replace_id):Promise<unknown>
  {
     return this.http.post(environment.API_HOST + 'replace_clip',{email:email,session_id:session_id,video_id:video_id,replace_id:replace_id}).toPromise();
  }

  getUserVideoCount(email,session_id)
  {

      return this.http.get(environment.API_HOST + 'videos_count?email='+email+'&session_id='+session_id ).toPromise();
  }

  generateVideo(email,session_id,video_id):Promise<unknown>
  {
     return this.http.post(environment.API_HOST + 'generate_video',{email:email,session_id:session_id,video_id:video_id}).toPromise();
  }

  generateVideo2(email,session_id,video_id):Promise<unknown>
  {
     return this.http.post(environment.API_HOST + 'generate_video_v2',{email:email,session_id:session_id,video_id:video_id}).toPromise();
  }
  generatedVideoStatus(email,session_id,gv_task_id):Promise<unknown>
  {
    return this.http.get(environment.API_HOST + 'generate_video_status?email='+email+'&session_id='+session_id+'&gv_task_id='+gv_task_id).toPromise();
  }

  generatedAutomatedVideos(email,session_id):Promise<unknown>
  {
    return this.http.get(environment.API_HOST + 'automated_videos?email='+email+'&session_id='+session_id).toPromise();
  }


  saveScheduleVideo(payload):Promise<unknown>
  {
    return this.http.post(environment.API_HOST + 'schedule_video', payload).toPromise();
  }
  deleteScheduleVideo(email,session_id,schedule_id):Promise<unknown>
  {
    return this.http.request('delete',environment.API_HOST + 'schedule_video', {body:{email:email,session_id:session_id,schedule_id:schedule_id}}).toPromise();
  }


  deleteVideo(email,session_id,visual_id)
  {

   return this.http.delete(environment.API_HOST +'delete_visual?email='+email+'&session_id='+session_id+'&visual_id='+visual_id ).toPromise();;
  }

  getScheduleVideos(email,session_id,schedule_id):Promise<unknown>
  {
    return this.http.get(environment.API_HOST + 'scheduled_videos?email='+email+'&session_id='+session_id+'&schedule_id='+schedule_id  ).toPromise();
  }


  getVideosByScheduleId(email,session_id,schedule_id):Promise<unknown>
  {
    return this.http.post(environment.API_HOST + 'scheduled_videos', {email:email,session_id:session_id,schedule_id}).toPromise();
  }

  createVideo(video:video)
  {
    return this.http.post(environment.API_HOST + 'create_video',video).toPromise();
  }

  direct_localized_video(video:video)
  {
    return this.http.post(environment.API_HOST + 'direct_localized_video',video).toPromise();
  }

  getTemplateInfo(template_name:string)
  {
    return this.http.get(environment.API_HOST + 'template_info?template_name='+template_name).toPromise();
  }
  getTemplateContent(template_name:string,type,duration)
  {
    return this.http.get(environment.API_HOST + 'template_content?template_name='+template_name+'&type='+type+'&duration='+duration).toPromise();
  }


  getVideo(email,session_id,video_id)
   {
    console.log('vidoe_id',video_id)
    return this.http.get(environment.API_HOST + 'get_video?email='+email+'&session_id='+session_id+'&video_id='+video_id).toPromise();


  }

  cloneVideo(email,session_id,video_id)
   {
    let formData = new FormData();
    formData.append('session_id', session_id);
    formData.append('email', email);
    formData.append('video_id', video_id);
    return this.http.post(environment.API_HOST + 'clone_video', formData).toPromise();

  }
  getSignedURL(email,session_id,video_id)
  {

 let formData = new FormData();
  formData.append('session_id', session_id);
  formData.append('email', email);
  formData.append('fpath', video_id);
  return this.http.post(environment.API_HOST + 'signed_url_for_download', formData).toPromise();
  }

  getAllSignedURLs(data)
  {
    return this.http.post(environment.API_HOST_ADMIN + 'signed_url_multi_download', data).toPromise();
  }

  getBatchSignedURL(email,session_id,urls:string[])
  {

 let formData = new FormData();
  formData.append('session_id', session_id);
  formData.append('email', email);
  formData.append('fpaths', JSON.stringify(urls));
  return this.http.post(environment.API_HOST + 'signed_url_multi_download', {session_id:session_id, email:email, fpaths:urls}).toPromise();
  }

  getThumbBatchSignedURL(email,session_id,urls:string[])
  {

 let formData = new FormData();
  formData.append('session_id', session_id);
  formData.append('email', email);
  formData.append('fpaths', JSON.stringify(urls));
  return this.http.post(environment.API_HOST + 'get_thumbnails', {session_id:session_id, email:email, fpaths:urls}).toPromise();
  }

  addcomment(video_id,comment)
  {
    return this.http.post(environment.API_HOST + 'video_comment', {video_id:video_id,comment:comment}).toPromise();
  }

  deleteComment(video_id,comment)
  {
    return this.http.delete(environment.API_HOST + 'video_comment?video_id='+video_id+'&comment='+comment).toPromise();
  }
  guestUser(guest_email)
  {
    return this.http.post(environment.API_HOST + 'guest', {email:guest_email}).toPromise();
  }
}
