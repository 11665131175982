 
        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
            <div class="tab-pane fade show active">

                <div class="content_area ps-5 ms-4">
                    <div class="row">
                        <form [formGroup]="fOtherSettingForm">
                            <div class="col-sm-12 col-md-12 col-lg-10">
                                <!-- <div *ngIf="bDisplayErrorMsg" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show col-md-12" role="alert"> 
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"  xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                            fill="#842029" />
                                    </svg>
                                    <div>
                                        {{sErrorMessage}}
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                    fill="#FF8369" />
                                            </svg>
                                        </button>
                                    </div>
                                </div> -->
                                <p class="text-md text-gray-3 mb-2 primaryFont">Select the information you want shown at the end of your video</p>
                                <div class="d-flex align-items-start check_toggle_content pb-2">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input website" type="checkbox"
                                                id="flexSwitchCheckChecked" [checked]="bURLToggle" (change)="OtherSettingComponent_URLToggle($event)">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Website</p>
                                        <div class="row website-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5"  *ngIf="bURLToggle">
                                                <div class="formItm">
                                                    <input id="website-address " type="text" value="https://www."
                                                        placeholder="Enter website address" name="textfeild"
                                                        formControlName="url" required>
                                                        
                                                    <label for="website-address" class="text-sm">URL</label>
                                                     <span *ngIf="this.fOtherSettingForm.get('url').errors && this.fOtherSettingForm.get('url').touched" class="error">This field is required with valid URL.</span>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-items-start check_toggle_content pb-2">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input address" type="checkbox"
                                                id="flexSwitchCheckChecked" [checked]="bAddressToggle" (change)="OtherSettingComponent_AddressToggle($event)">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Address</p>
                                        <div class="row address-div"  *ngIf="bAddressToggle">
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address1" type="text"  placeholder="line1"
                                                        name="textfeild" formControlName="line1" required>
                                                    <label for="address1" class="text-sm">Address</label>
                                       <span *ngIf="fOtherSettingForm.get('line1').hasError('required')  && fOtherSettingForm.controls['line1'].touched" class="error">This field is required.</span>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address2" type="text"  placeholder="line2"
                                                        name="textfeild" formControlName="line2" required >
                                                    <label for="address2" class="text-sm">Apartment, suite, building, etc.</label>
                          
                                                         
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="city" type="text"  placeholder="city"
                                                        name="textfeild" formControlName="city" required >
                                                    <label for="city" class="text-sm">City</label>
                                 <span *ngIf="fOtherSettingForm.get('city').hasError('required') && fOtherSettingForm.get('city').touched" class="error">This field is required.</span>
                      
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="State" type="text"  placeholder="State"
                                                        name="textfeild" formControlName="state" required >
                                                    <label for="State" class="text-sm">State</label>
                              <span *ngIf="fOtherSettingForm.get('state').hasError('required') && fOtherSettingForm.get('state').touched" class="error">This field is required.</span>
                                      
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Zipcode" type="text"  placeholder="Postal Code"
                                                        name="textfeild" formControlName="postal_code" required >
                                                    <label for="Zipcode" class="text-sm">Zipcode</label>
                                          <span *ngIf="fOtherSettingForm.get('postal_code').hasError('required') && fOtherSettingForm.get('postal_code').touched" class="error">This field is required.</span>
                             
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Country" type="text"  placeholder="Country"
                                                        name="textfeild" formControlName="country" required >
                                                    <label for="Country" class="text-sm">Country</label>
                                   
                              
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="d-flex align-items-start check_toggle_content">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input phone" type="checkbox"
                                                id="flexSwitchCheckChecked" (change)="OtherSettingComponent_PhoneToggle($event)" [checked]="bPhoneToggle">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Phone Number</p>
                                        <div class="row phone-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5">

                                                <div class="formItm" *ngIf="bPhoneToggle">
                                                    <input id="phone-no" type="text" 
                                                        placeholder="Enter your phone"  name="textfeild"
                                                        formControlName="phone"> 
                                                    <label for="phone-no" class="text-sm">Phone</label>
                                                     <span *ngIf="fOtherSettingForm.get('phone').hasError('required') && this.fOtherSettingForm.get('phone').touched" class="error">This field is required.</span>
                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                             
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>

 
<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessage}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z" fill="#FF8369"></path>
                            <path d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z" fill="#FF8369"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="28" height="29" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                            
                            
                    </div>
                    <div class="col-11 p-0">
                       
                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>
              
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal" routerLink="/dashboard/myvideos">Go Back</a>
               
            </div>
        </div>
    </div>
</div>
<!-- error modal end -->