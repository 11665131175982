import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountManagerService } from "./../../../Managers/account-manager.service";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
import { cDataService } from "./../../../services/data.service";
import { cSessionService } from "./../../../services/session.service";
import { cWebSocketService } from "./../../../services/websocket.service";
import { ErrorService } from "./../../../services/error.service";
import { mediaFile } from "src/app/models/media";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { Userservice } from "../users/service/user-service";
import { user } from "src/app/models/admin";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UploadFileDialogComponent } from "../../upload-file-dialog/upload-file-dialog.component";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
declare var $: any;

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  fUserProfileForm: FormGroup;
  sSessionId: string;
  sUserEmail: any;
  bDisplaySubscriptionButton: boolean;
  // sFacebookSigninLink: string
  // sGoogleSigninLink: string;
  // sLinkedInSigninLink: string
  DisconnectSocialForm: FormGroup;
  connected_social_platforms: any;
  oSocialChannel: string;
  oUserName: any;
  sDisplayDisconnectErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  bDisabledButtons: boolean = false;
  bEditButton: boolean = true;
  userLogoPath = "";
  defaultMediaLibrary: any;

  constructor(
    private oSessionService: cSessionService,
    private oFormBuilder: FormBuilder,
    private oWebSocketService: cWebSocketService,
    private oRouter: Router,
    private oDataService: cDataService,
    private accountManager: AccountManagerService,
    public onboardingManger: OnboardingManagerService,
    private oActivatedRoute: ActivatedRoute,
    public errorService: ErrorService,
    private mediaManger: MediaManagerService,
    private adminManger: Userservice,
    private dialog: MatDialog,
    public successMessage: SuccessMessageModalService,
  ) {}

  ngOnInit() {
    const authResult = this.oActivatedRoute.snapshot.queryParams["auth_result"];
    if (
      authResult != null &&
      (authResult == "google_auth_successful" ||
        authResult == "fb_auth_successful" ||
        authResult == "linkedin_auth_successful")
    ) {
      const email = this.oActivatedRoute.snapshot.queryParamMap.get("email");
      const sessionId =
        this.oActivatedRoute.snapshot.queryParamMap.get("session_id");
      this.oSessionService.cSessionService_SetUserEmail(email);
      this.oSessionService.cSessionService_SetSessionId(sessionId);
    }

    this.fUserProfileForm = this.oFormBuilder.group({
      session_id: this.onboardingManger.loggedUser.session_id,
      _id: this.onboardingManger.profile._id,
      email: [this.onboardingManger.loggedUser.email, Validators.required],

      name: ["", Validators.required],
      get_pronto_newsletter: this.onboardingManger.profile.get_pronto_newsletter
        ? this.onboardingManger.profile.get_pronto_newsletter
        : false,
    });

    this.GetUserProfile();

    this.DisconnectSocialForm = this.oFormBuilder.group({
      platform: "",
    });

    this.oSessionService.setAccountSettingHeaderName("Account Overview");
    console.log(this.onboardingManger.profile);
    if (
      this.onboardingManger.profile.user_logo_file &&
      this.onboardingManger.profile.user_logo_file != undefined
    ) {
      console.log("path logo ", this.onboardingManger.profile.user_logo_file);
      this.updateUserAvatarPath(this.onboardingManger.profile.user_logo_file);
    }
  }

  GetUserProfile() {
    if (!this.onboardingManger.profile) {
      this.onboardingManger
        .getUserProfile(
          this.onboardingManger.loggedUser.email,
          this.onboardingManger.loggedUser.session_id
        )
        .then((res) => {
          this.initializeForm();
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          console.log(err);
        });
    } else {
      this.initializeForm();
    }
  }

  private initializeForm() {
    const username = this.onboardingManger.profile.username
      ? this.onboardingManger.profile.username
      : this.onboardingManger.profile.first_name;
    console.log("this.onboardingManger.profile", this.onboardingManger.profile);
    console.log(username);
    this.fUserProfileForm.get("name").setValue(username);
    this.bDisplaySubscriptionButton =
      this.onboardingManger.profile.is_subscribed_to_email == 0 ? false : true;
    this.onboardingManger.profile.get_pronto_newsletter &&
    this.onboardingManger.profile.get_pronto_newsletter == true
      ? this.onboardingManger.profile.get_pronto_newsletter
      : false;

    // console.log(
    //   this.onboardingManger?.profile?.media_libs.length,
    //   this.onboardingManger?.profile?.media_libs[0]._id?.$oid,
    //   this.onboardingManger?.profile?.media_libs
    // );
    if( this.onboardingManger?.profile?.media_libs && this.onboardingManger?.profile?.media_libs[0]){
      if(this.onboardingManger?.profile?.media_libs[0]._id?.$oid){
        this.defaultMediaLibrary = this.onboardingManger?.profile?.media_libs[0]._id?.$oid
      } else {
        this.defaultMediaLibrary = this.pickOtherMediaLibrary();
      }
    }

    // console.log(" User default media library ", this.defaultMediaLibrary);
  }

  pickOtherMediaLibrary(){
    const activeMadiaLibrary = this.onboardingManger?.profile?.media_libs.filter( (mediaLibrary) =>  {
      return (mediaLibrary._id?.$oid);
    });
    return ( activeMadiaLibrary && activeMadiaLibrary.length > 0 ) ? activeMadiaLibrary[0]._id?.$oid : undefined;
  }

  toggleSubscription() {
    this.onboardingManger.profile.is_subscribed_to_email =
      this.onboardingManger.profile.is_subscribed_to_email == 1 ? 0 : 1;
    this.bDisplaySubscriptionButton =
      this.onboardingManger.profile.is_subscribed_to_email == 0 ? false : true;
  }

  async updateProfile() {
    const userInfo: user = {
      _id: this.fUserProfileForm.controls["_id"].value,
      email: this.fUserProfileForm.controls["email"].value,
      get_pronto_newsletter:
        this.fUserProfileForm.controls["get_pronto_newsletter"].value,
    };

    await this.adminManger.updateUser(userInfo);
    this.onboardingManger.profile.get_pronto_newsletter =
      this.fUserProfileForm.controls["get_pronto_newsletter"].value;
  }

  MyAccountComponent_ConnectSocialChannels(value: string) {
    // console.log(this.onboardingManger);
    let sURL: string;
    if (value == "facebook") {
      sURL =
        this.oDataService.faceBookJoinLink +
        "?" +
        "email=" +
        this.onboardingManger.loggedUser.email +
        "&session_id=" +
        this.onboardingManger.loggedUser.session_id;
    } else if (value == "google") {
      sURL =
        this.oDataService.googleJoinLink +
        "?" +
        "email=" +
        this.onboardingManger.loggedUser.email +
        "&session_id=" +
        this.onboardingManger.loggedUser.session_id;
    } else if (value == "linkedin") {
      sURL =
        this.oDataService.linkedInJoinLink +
        "?" +
        "email=" +
        this.onboardingManger.loggedUser.email +
        "&session_id=" +
        this.onboardingManger.loggedUser.session_id;
    }

    // console.log(sURL);
    this.oSessionService.cSessionService_SetRedirectURL(2);
    // console.log("url ", sURL);
    location.assign(sURL);
  }

  MyAccountComponent_ResetView() {
    this.initializeForm();
    this.bDisabledButtons = false;
    this.bEditButton = true;
  }

  MyAccountComponent_SendProfileUpdateRequest() {
    if (this.fUserProfileForm.valid) {
      this.onboardingManger.profile.username =
        this.fUserProfileForm.get("name").value;
      this.onboardingManger
        .updateUserProfile(this.onboardingManger.profile)
        .then((res) => {
          console.log(res);
          this.initializeForm();
          this.bDisabledButtons = false;
          this.bEditButton = true;
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          console.log(err);
        });
    } else {
      this.fUserProfileForm.markAllAsTouched();
    }
  }

  MyAccountComponent_DisconnectSocialChannel(value) {
    this.accountManager
      .disconnectSocialPlatform(
        this.onboardingManger.loggedUser.email,
        this.onboardingManger.loggedUser.session_id,
        value
      )
      .then((res) => {
        switch (value) {
          case "google": {
            this.onboardingManger.profile.connected_social_platforms.google =
              false;
            break;
          }
          case "facebook": {
            this.onboardingManger.profile.connected_social_platforms.facebook =
              false;
            break;
          }
          case "linkedin": {
            this.onboardingManger.profile.connected_social_platforms.linkedin =
              false;
            break;
          }
          default:
            break;
        }
      })
      .catch((err) => {
        this.errorService.handleError(err.errorMessage, err.errorType, true);
      });
  }

  MyAccountComponent_EditForm() {
    this.bEditButton = false;
    this.bDisabledButtons = true;
  }

  MyAccountComponent_UnsubscriptionModal() {
    console.log("working");
    $("#subscription-popup").modal("show");
  }

  logoFile: any;

  updateProfileLog() {
    this.adminManger
      .updateUser({
        _id: this.oSessionService.cSessionService_GetUserEmail(),
        user_logo_file: this.logoFile.signed_url,
      })
      .then((response) => {
        console.log("upload logo data updated...", response);
      });
  }

  uploadUserLogo(event) {
    let file = event.target.files[0];

    if (file) {
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;
      console.log(this.onboardingManger.profile);

      if (this.defaultMediaLibrary) {
        this.mediaManger
        .uploadUserAvatar(
          this.oSessionService.cSessionService_GetUserEmail(),
          this.oSessionService.SessionService_GetSessionId(),
          this.defaultMediaLibrary,
          media
        )
        .then((res: any) => {
          this.logoFile = res;
          this.logoFile.size = file.size;
          this.onboardingManger.profile.user_logo_file = res.fpath;
          this.adminManger
            .updateUser({
              _id: this.oSessionService.cSessionService_GetUserEmail(),
              user_logo_file: res.fpath,
            })
            .then(() => {
              this.updateUserAvatarPath(res.fpath);
            });
        })
        .catch((error) => {
          setTimeout(() => {
            this.bDisplayErrorMessage = false;
          }, 5000);
        });
      } else {
        this.errorService.handleError("User Default Library is missing ", 'Upload Logo', true);
      }
    }
  }

  updateUserAvatarPath(fPath) {
    const fpahArray = [fPath];
    // console.log(fpahArray);
    this.mediaManger
      .getMediaThumbandURL(
        this.oSessionService.cSessionService_GetUserEmail(),
        this.oSessionService.SessionService_GetSessionId(),
        fpahArray
      )
      .then((resThumb: any) => {
        // console.log(" resThumb", resThumb, resThumb.output[0]);
        this.userLogoPath = "";
        if (resThumb && resThumb.output) {
          this.userLogoPath = resThumb.output[0][fpahArray[0]];
          this.oSessionService.userProfileLogoPath =
            resThumb.output[0][fpahArray[0]];
        }
      });
  }

  deleteProfilePic() {
    this.adminManger
      .updateUser({
        _id: this.oSessionService.cSessionService_GetUserEmail(),
        user_logo_file: "",
      })
      .then(() => {
        this.userLogoPath = "";
        this.oSessionService.userProfileLogoPath = "";
        this.onboardingManger.profile.user_logo_file = "";
      });
  }
  deleteProfilePicModal() {
    // console.log("working");
    $("#DeleteProfilepic-popup").modal("show");
  }
  async uploadUserProfileLogo() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100wv";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "pronto-dialog";
    dialogConfig.closeOnNavigation = true;

    dialogConfig.data = {
      uploadFor: "image_video_logo",
      multipleUpload: false,
      dropBoxText: "",
      heading: "Upload a Logo",
      allowDuplicate: true,
      mediaLibraryId:this.defaultMediaLibrary,
    };

    let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

    dialogModal.afterClosed().subscribe((data: any) => {
      if (data?.status == "success" || data?.type == "success") {
        console.log("Success called");
        const res = data.result[0];
        console.log("rescheck", res);

        this.logoFile = res;
        this.logoFile.size = res.size;
        this.onboardingManger.profile.user_logo_file = res.visual_path;
        this.successMessage.displayMessage('Updating profile data.');
        this.adminManger
          .updateUser({
            _id: this.oSessionService.cSessionService_GetUserEmail(),
            user_logo_file: res.visual_path,
          })
          .then(() => {
            this.updateUserAvatarPath(res.visual_path);
          });
        this.successMessage.close();
      }
      else {
        console.log("rescheck2");

      }
    });
  }
}
