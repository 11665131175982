import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ErrorService } from './../../../services/error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  fResetPasswordForm : FormGroup
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  bDisplaySuccessMsg: boolean = false;
  sSuccessMsg: string;
  constructor(private oFormBuilder: FormBuilder, private onboardingManager:OnboardingManagerService,private oRouter : Router,public errorService:ErrorService) { }

  ngOnInit(): void 
  {
    this.ResetPasswordComponent_InitializeResetPasswordForm()
     
  }
  ResetPasswordComponent_InitializeResetPasswordForm()
  {
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
    this.fResetPasswordForm = this.oFormBuilder.group(
      {
         email: [null, [Validators.required,Validators.email, Validators.pattern(sEmailRejex)]],
      });
  }
  ResetPasswordComponent_SendResetPasswordRequest()
  {
    if(this.fResetPasswordForm.valid)
    {

this.onboardingManager.forgotPassword(this.fResetPasswordForm.get('email').value).
then(_=>
  {
    this.oRouter.navigateByUrl("/verify-email-message");
  }).catch(err=>
    {
      this.errorService.handleError(err.errorMessage,err.errorType,true);
       this.sErrorMessage = "You have entered an invalid email, user not found";
      this.bDisplayErrorMsg = true;
      setTimeout(()=>{
       this.bDisplayErrorMsg = false
      },5000)
    });
     }
    else
    {
      this.fResetPasswordForm.markAllAsTouched();
    }
  }
 
}
