<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Brands</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results</p>
                        </div>
                    </div>
                </div>
                <div class="accountSecInner">
                    <div class="accountSecNav py-5">
                        <app-business-sidebar></app-business-sidebar>
                    </div>
                    <div class="accountSecContent py-5 pe-4 pb-2">
                        <div class="video-header row m-0 px-2">
                            <p class="text-lg text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                                <a href="agencyUsers_brands.html" class="me-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                            fill="#404243" />
                                    </svg>
                                </a>
                                Lakme Fashion
                            </p>
                        </div>
                        <div class="container mx-0 px-0 business-media" id="container-view">
                            <div class="row filter-post">
                                <div class="col-md-12">
                                    <ul class="breadcrumb-tabs nav mx-2">
                                        <li class="nav-item">
                                            <a routerLink="/business-profile/brands/account" class="nav-link active">Account</a>
                                        </li>
                                        <li class="nav-item">
                                            <a routerLink="/business-profile/brands/business-details" class="nav-link">Business
                                                Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a routerLink="/business-profile/brands/published-video" class="nav-link">Published
                                                Video</a>
                                        </li>
                                        <li class="nav-item">
                                            <a routerLink="/business-profile/brands/media-library" class="nav-link">Media
                                                Library</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row p-0 m-0 mt-4">
                                <div class="col-md-6">
                                    <div class="formItm">
                                        <input id="userName" type="text" value="Lakme Fashion"
                                            placeholder="Lakme Fashion" name="textfeild" required>
                                        <label for="userName" class="text-sm">User
                                            Name</label>
                                    </div>
                                </div>

                                <div class="col-md-6">

                                </div>

                                <div class="col-md-6 px-4">
                                    <div class="row mt-1 ac-overview">
                                        <div class="col-md-10 px-0">
                                            <p class="text-lg text-gray-2 pb-0 mb-2">Email
                                                Preferences</p>
                                            <p class="text-xs text-gray-3 pb-1">The choice
                                                is yours. Decide if you'd like to receive
                                                emails from us.</p>
                                        </div>
                                        <div class="col-md-2 text-end px-0 pe-4">
                                            <a href="#"
                                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2">Unsbscribe</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="video-header row m-0 px-2">
                                        <p class="text-lg text-gray-2 pb-0 mb-2 col-lg-6">
                                            Users
                                            Brands
                                            <span class="d-block text-xs text-gray-3">Total
                                                User <b>3</b></span>
                                        </p>
                                        <ul class="text-end d-block col-lg-6 px-0 mb-0">

                                            <li>
                                                <button
                                                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-5 px-4"
                                                    data-sidebar="#assignNewuser">Assign New
                                                    User
                                                </button>

                                            </li>
                                        </ul>

                                    </div>
                                    <table class="table tableCustom table-hover mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="tableCheck">
                                                    <div class="custom-switch">
                                                        <input type="checkbox" class="check form-check-input"
                                                            id="checkAll">
                                                    </div>
                                                </th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Brands</th>
                                                <th scope="col">Published Videos</th>
                                                <th scope="col">Media Uploads</th>
                                                <th scope="col">Last Login</th>
                                                <th scope="col" class="tableAction">Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of lUserBrands">
                                                <td scope="row" class="tableCheck">
                                                    <div class="custom-switch">
                                                        <input type="checkbox" class="check form-check-input">
                                                    </div>
                                                </td>
                                                <td><a class="b_userName" href="agencyUsers_brandsAccount.html">Estelle
                                                        Bates</a></td>
                                                <td>si@ja.net</td>
                                                <td>3</td>
                                                <td>12</td>
                                                <td>53</td>
                                                <td>03/09/2021</td>
                                                <td class="tableAction">
                                                    <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                        <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                                                fill="#7A7A7A" />
                                                        </svg>
                                                    </a>
                                                    <ul class="dropdown-menu add_location page-shadow"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li>Edit</li>
                                                        <li>Deactivate</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row p-0 m-0 mt-3 mb-1">
                                <div class="col-sm-12 col-md-12 col-lg-12">

                                    <p class="text-lg text-gray-2 pt-3 m-0">Connected Social
                                        Account</p>
                                    <p class="text-xs text-gray-3 py-1">Services that you
                                        use to Connected with social Account</p>


                                    <div class="row mt-4">
                                        <div class="col-sm-6col-md-6 col-lg-6">

                                            <div class="d-flex select-channels">
                                                <img width="40px" height="40px"
                                                    src="../assets/images/dashboardVideo/Facebook.png" alt="sc">
                                                <p class="px-3">
                                                    <b class="d-block">Facebook<img width="12px" height="12px"
                                                            src="../assets/images/dashboardVideo/verifyCheck.png"
                                                            alt="sc"></b>
                                                    Your Facebook account is connected to
                                                    Prontopia . <a href="#">Estelle Bates</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-sm-6col-md-6 col-lg-6">
                                            <div class="d-flex select-channels">
                                                <img width="40px" height="40px"
                                                    src="../assets/images/dashboardVideo/Instagram.png" alt="sc">
                                                <p class="px-3">
                                                    <b class="d-block">Instagram<img width="12px" height="12px"
                                                            src="../assets/images/dashboardVideo/verifyCheck.png"
                                                            alt="sc"></b>
                                                    Your Instagram account is connected to
                                                    Prontopia . <a href="#">Estelle Bates</a>

                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row p-0 m-0 mt-4 pt-2 border-top">
                                <div class="col-sm-12 col-md-12 col-lg-12">

                                    <p class="text-lg text-gray-2 pt-3 m-0">Billing Address
                                    </p>
                                    <p class="text-xs text-gray-3 py-1 mb-2">Sed ut
                                        perspiciatis unde omnis iste natus error sit
                                        voluptatem accusantium</p>
                                    <div class="row">
                                        <div class="col-sm-6col-md-6 col-lg-6">
                                            <div class="address-box">

                                                <p class="px-3 text-xs text-gray-3 m-0">
                                                    <span class="d-block text-lg text-gray-2 pb-2">Address</span>
                                                    Family Chiropractic center of
                                                    Richmond<br>
                                                    123 Main Street City, State 0000

                                                </p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>
    </form>
</section>
<div class="overlay sidebarOverlay"></div>
<app-business-profile-sidepanels></app-business-profile-sidepanels>