<!-- Create video Header -->
<header class="header header-1 drop-shadow" >
    <div class="container">
        <nav class="navbar navbar-expand-lg" style="padding-right: 50px;">
            <div class="d-flex w-100">
                <div class="col d-flex align-items-center">
                   
                    <a  [routerLink]="['']" *ngIf="!this.videoManger.isEditMode && ftLogin">
                        <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                    </a>
                    <h3 class="text-gray-1 mb-0" *ngIf="!this.videoManger.isEditMode && !ftLogin">Create a Video</h3>

                    <h5 class="text-gray-1 pt-2" *ngIf="this.videoManger.isEditMode && !ftLogin">{{this.videoManger.selectedVideo.title}}
                    </h5>
                   <span class="ms-2 text-xs bg-gray-3 text-white labelTag" *ngIf="this.videoManger.isEditMode && !ftLogin">{{this.videoManger.selectedVideo.duration}}s</span>
                </div>
                <div class="col text-end" *ngIf="!this.videoManger.previewMode || this.videoManger.isVideoGenerated">
                    <a class="text-gray-6 text-sm me-4" href="javascript:void(0)"
                        (click)="cancel()" *ngIf="this.showCancel">Cancel</a>
                        <a (click)="previous()"
                        class="text-gray-6 text-sm me-4 " *ngIf="this.showPrevious">Previous</a>
                    <a (click)="next()"
                        class="btn btn-md btn-secondary btn-round-6 " *ngIf="this.showNext">Next</a>
                        <a (click)="next()"
                        class="btn btn-md btn-secondary btn-round-6 " *ngIf="this.showPreview">Preview</a>
                        <a (click)="save()"
                        class="btn btn-md btn-secondary btn-round-6 " *ngIf="this.showSave">{{this.saveButtonTitle}}</a>
                </div>
            </div>

        </nav>
    </div>
</header>
 
<section class="accountSec main-body bg-gray-7 h-100 w-100 width-72">
    <div class="container" style="margin-top:10px">
        <div class="row align-items-center">
            <div class="col-md-6" >
                <!-- <app-create-video-breadcrumb *ngIf="!this.isTimeLineView"  [enableMenu]="this.videoManger.selectedVideo != undefined"></app-create-video-breadcrumb> -->
                <app-ftue-create-video-breadcrumb *ngIf="this.breadcrumActive && !ftLogin"  [enableMenu]="this.videoManger.selectedVideo != undefined"></app-ftue-create-video-breadcrumb>
            </div>
            
        </div>
        <router-outlet></router-outlet>
    </div>
</section>

