import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { VideoManagerService } from './../../Managers/video-manager.service';
import { video } from '../../models/video';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';
import { cVideoDetails } from './../../shared/models/video-details';
import { ErrorService } from './../../services/error.service';

@Component({
  selector: 'app-schuled-videos',
  templateUrl: './schuled-videos.component.html',
  styleUrls: ['./schuled-videos.component.scss']
})
export class SchuledVideosComponent implements OnInit, OnDestroy {
  @Output() nEmittedScheduledVideosCount = new EventEmitter();
  videos: any = [];
  videoScheduledNotFound: string;
  sSelectedVideoId: string;
  lScheduledVideos: any = [];
  videoListForm: FormGroup;
  sSessionId: string;
  sUserEmail: string;
  bDisplayLoader: boolean = false;
  lTemplateCategory: any = [];
  lTemplateNames: any = [];
  sSelectedscheduled: any;
  oSetTime: any;
  bIsPublish: boolean = false;
  oSearchVideoSubscription: any;
  addCustomClass: string;
  headActive: boolean = false;
  editListActive: boolean = false;
  duration: boolean = true;
  oClosePopup: any=0;
  schedule_id:string=undefined;
  

  constructor(private oWebSocketService: cWebSocketService, private oSessionService: cSessionService, private oTemplateConfigService: cTemplateConfigService, public videoManager: VideoManagerService,
    private oFormBuilder: FormBuilder, private oRouter: Router, private oEditVideoService: cEditVideoService, private oDataService: cDataService,public errorService:ErrorService) {
    if (this.oRouter.url === '/dashboard/scheduled-videos') {
      this.addCustomClass = 'custom-empty-state'
    }
    else {
      this.addCustomClass = ''
    }
  }

  ngOnInit(): void {


    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.videoManager.getAutomatedVideos(this.sUserEmail, this.sSessionId).then(res => {
      console.log("res", res);

    }).catch(err => {
          
      this.errorService.handleError(err.error.errorMessage,err.error.errorType,true);

  });
    this.videoListForm = this.oFormBuilder.group(
      {
        email: this.oSessionService.cSessionService_GetUserEmail(),
        session_id: this.oSessionService.SessionService_GetSessionId(),
        publish_status: "scheduled",
        num_skip: 0,
        num_items: 10
      });
  }
  ngOnDestroy() {
    if (this.oSearchVideoSubscription)
      this.oSearchVideoSubscription.unsubscribe();
  }
  

  CreatedVideosComponent_NavigateToCreateVideo() {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
    this.oRouter.navigate(['wizard/overview']);
  }

  delete()
  {
    this.schedule_id = this.videoManager.selectedVideo.schedule.schedule_id;
    this.videoManager.deteSchedule(this.sUserEmail,this.sSessionId,this.schedule_id).then(res=>{
    this.videoManager.selectedVideo.schedule = undefined;
    this.schedule_id = undefined;
    window.location.reload();
      
    }).catch(err => {
          
      this.errorService.handleError(err.error.errorMessage,err.error.errorType,true);

  });
  }


  navigateToOverview(video: video) {
    this.videoManager.selectedVideo = video;

    console.log(this.videoManager.selectedVideo);
    this.oRouter.navigate(['/dashboard/overview']);
  }
  editList(vide) {
    this.editListActive = true;
    document.getElementById(vide).classList.toggle('d-none');
    console.log("list", vide)
  }
  accordhead() {
    this.headActive = true;
  }
  changePanel(event, panel) {
    console.log(event);
    console.log(panel);
    panel.isActive = event;
  }
 

  videoduration(video){
    video.is_hide_duration = true;
    console.log("index");
  }

  DashboardHeaderComponent_GetClickLocation(event)
  {
    this.oClosePopup=0;
    if(event.clientX <= 65)
    {
      this.oClosePopup=1;
      setTimeout(() => {this.oClosePopup=0;}, 200);
    }
  }

     // modal scheduled video
     isVisible = false;
     showModal(video): void {
      this.videoManager.selectedVideo = video;
      console.log("Logsssssss:",video);
       this.isVisible = true;
     }
     handleOk(): void {
       console.log('Button ok clicked!');
       this.isVisible = true;
     }
     handleCancel(): void {
       console.log('Button cancel clicked!');
       this.isVisible = false;
     }

}