import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { Router } from '@angular/router';
import { cWebSocketService } from './../../../../services/websocket.service';
import { cSessionService } from './../../../../services/session.service';

import { cDataService } from './../../../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';
import { cTemplateConfigService } from './../../../../services/template.service';
import { cEditVideoService } from './../../../../services/edit-video.service';
import { Subject, Subscription } from 'rxjs';

import { CreateVideoManagerService } from './../../../../Managers/create-video-manager.service';
import { ConfigManagerService } from './../../../../Managers/config-manager.service';
import { block_config, content, video } from './../../../../models/video';

import { block_clips, clip } from '../../../../models/video';

declare var $: any;

@Component({
  selector: 'app-video-timeline',
  templateUrl: './video-timeline.component.html',
  styleUrls: ['./video-timeline.component.scss']
})
export class VideoTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('videoClips', { static: false }) videoClips: ElementRef;
  @ViewChild('audioWaveform', { static: false }) audioWaveform: NgWaveformComponent;
  @ViewChild('voiceoverWaveform', { static: false }) voiceoverWaveform: NgWaveformComponent;
  @ViewChild('width1', { static: false }) width1: ElementRef;
  eventsSubject: Subject<void> = new Subject<void>()
  audioSource: any = "";
  voiceoverSource: any = "";
  bUploadVoiceOverFile: boolean;
  oUploadedVoiceOverFile: any;
  nVoiceOverFileSize: number = 0;
  sSessionId: any;
  sUserEmail: any;
  sUploadedVoiceOverFileName: string = "No file choosen.";
  bShowTextToSpeechSection: boolean = true;
  sSelectedVoiceGender: string;
  oUserVoiceOverList: any = [];
  oVoiceOverList: any = [];
  sVoiceName: string;
  nSelectedVoiceIndex: number;
  fVoiceoverForm: FormGroup;
  oAudio = new Audio();
  lCallToAction: any = ['', ''];
  bToggleAudioButton: boolean = true;
  bToggleVoiceoverButton: boolean = true;
  lcta: any = [];
  sVideoId: string;
  // sEditVideoId: string;
  oEditVideoConfiguration: any;
  lBlockConfigurations: block_config[] = [];
  bLetProntoChoose: boolean = true;
  bDisplayMyMediaSection: boolean = false;
  bDisplayUploadSection: boolean = true;
  lFilestoUpload: any = [];
  lUploadedFiles: any = [];
  lUserSelectedVisuals: any = [];
  sResetTextOverLays: string;
  bAudioSidepanel: boolean = false;
  nIncomingVoiceoverFile: any;
  lBlockConfigurationsCopy: block_config[] = [];
  lDurationsList: any = [];
  bResponse: boolean = false;
  oSelectedBlock: content;
  sSelectedBlockId: string;
  lBlockClips: any = [];
  lAllowedFonts: any = [];
  oMusicFile: any;
  bDisplayBlockSidePanel: boolean = false;
  bDisplayBlockTextSidePanel: boolean = false;
  lTemplateCategory: any[];
  oVideoDetails: any;
  oSelectedTemplate: any;
  bShowSelectedTemplate: boolean = false;
  nSelectedTemplateIndex: any;
  oLooknFeelForm: any;
  fLooknFeelForm: FormGroup;
  lKey_terms: any;
  oPrimaryColor: any;
  oFontColor: any;
  sTemplateChange: any;
  oUploadedLogo: any;
  nUploadedLogoSize: any;
  sUploadedLogoName: any;
  lKeyTermsTags: any;
  lChangedTemplate: any = [];
  sDisplayResponseMessage: string;
  bDisplayErrorMessage: boolean = false;
  video: video;
  bVoiceOverSidePanel: boolean = false;
  sSelectedVideoId: any;
  //nSetPollingTimer : any;
  selectedBlock: any;
  lsVideoDuration: any;
  lScaleBreakPoints: any[] = [];
  addCustomClass: string;
  scaleListWidth: number;
  bIsAudio: boolean;
  session: cSessionService;
  blockClips: block_clips[] = [];
  clips: content[];
  texts: content[];
  constructor(
    private oRouter: Router,
    private oWebSocketService: cWebSocketService,
    private oSessionService: cSessionService,
    private oDataService: cDataService,
    private oFormBuilder: FormBuilder,
    private oTemplateConfigService: cTemplateConfigService,
    private sSessionService: cSessionService,
    private oEditVideoService: cEditVideoService,
    public videoManager: CreateVideoManagerService,
    public config: ConfigManagerService) {
    this.session = oSessionService;
  }

  fContentForm: any;
  sub: Subscription;
  ngOnInit() {


 

    this.sub = this.videoManager.submitForm.subscribe(c => {


      let cc = this.clips.map(a => a.source);

      this.videoManager.selectedVideo.template_info.contents.filter(c => c.content === 'clip').forEach((ele, index) => {

        console.log('clip', cc[index]);

        ele.source = cc[index];

      });

      let tt = this.texts.map(a => a.text);
      this.videoManager.selectedVideo.template_info.contents.filter(c => c.content === 'text' && !c.parent && c.id != 'address' && c.id != 'url' && c.id != 'phone').forEach((ele, index) => {
        ele.text = tt[index];
      });




      this.videoManager.selectedVideo.clips = cc;
      this.videoManager.selectedVideo.text_list = tt;


      console.log('selecteVideo', this.videoManager.selectedVideo);
      this.videoManager.createVideo(this.videoManager.selectedVideo).then(res => {
        this.videoManager.selectedVideo['video_id'] = this.videoManager.selectedVideo.fpath;
        this.oRouter.navigateByUrl('wizard/preview');
      });
    });
    // this.InitializeJqueryCode();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.lcta = this.videoManager.selectedVideo.cta;


    this.clips = [...this.videoManager.selectedVideo.template_info.contents.filter(c => c.content == 'clip')];


    this.texts = [...this.videoManager.selectedVideo.template_info.contents.filter(c => c.content == 'text' && !c.parent && c.id != 'address' && c.id != 'url' && c.id != 'phone')];
    console.log('clips', this.clips);
    console.log('texts', this.texts);

    if (this.videoManager.selectedVideo.voice_file_url) {

      this.voiceoverSource = this.videoManager.selectedVideo.voice_file_url;
    }
    else {
      this.voiceoverSource = "";
    }

    if (this.videoManager.selectedVideo.music_file_url) {
      this.bIsAudio = true;
      this.audioSource = this.videoManager.selectedVideo.music_file_url;
    }
    else {
      this.bIsAudio = false;
    }


    this.video = cloneDeep(this.videoManager.selectedVideo)
 
    this.scaleListWidth = 216 * this.clips.length - 1;
   
    this.lTemplateCategory = this.config.Templates;
     
    this.VideoTimelineViewComponent_CalculateScaleBreakPoints();
   
    this.oUserVoiceOverList = this.config.voices;
    this.lDurationsList = [];
    

  }

  ngAfterViewInit() {
   
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.session.VideoSettingEditClip = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTitle = false;
    this.session.visibleVideo = false;
    this.session.visibleAudio = false;
  }
 
  VideoTimelineViewComponent_CalculateScaleBreakPoints() {
    this.lsVideoDuration = this.videoManager.durations;
    this.lScaleBreakPoints = [];
    let nCurrentBreakPoint: number = 0;
    const nBreakPoint: number = this.videoManager.selectedVideo.duration / 6;
    for (let nBreakPointIndex = 0; nBreakPointIndex <= 6; nBreakPointIndex++) {
      let lBreakPointPartition: string[] = [];
      const nNextbreakPoint: number = nCurrentBreakPoint + nBreakPoint;
      if (nBreakPointIndex !== 6) {
        for (let nBreakPointPartitionIndex = nCurrentBreakPoint; nBreakPointPartitionIndex < Math.floor(nNextbreakPoint); nBreakPointPartitionIndex++) {
          lBreakPointPartition.push("span" + nBreakPointPartitionIndex);
        }
      }
      this.lScaleBreakPoints.push({ breakpoint: nCurrentBreakPoint, breakPointPartition: lBreakPointPartition });
      nCurrentBreakPoint += nBreakPoint;
    }
  }
  VideoTimelineViewComponent_SetVideoForm() {
    this.fLooknFeelForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        video_id: this.sVideoId,
        template_category: [this.oLooknFeelForm.template_category, [Validators.required]],
        key_terms: [this.oLooknFeelForm.key_terms],
        template_name: [this.oLooknFeelForm.template_name],
        color: [this.oLooknFeelForm.color],
        font_color: [this.oLooknFeelForm.font_color],
        oUploadedLogo: [this.oLooknFeelForm.oUploadedLogo],
        nUploadedLogoSize: [this.oLooknFeelForm.nUploadedLogoSize],
      });
    this.lKey_terms = this.oLooknFeelForm.key_terms
    this.oPrimaryColor = this.oLooknFeelForm.color;
    this.oFontColor = this.oLooknFeelForm.font_color;
    this.sTemplateChange = this.oLooknFeelForm.template_category;
    this.oUploadedLogo = this.oLooknFeelForm.oUploadedLogo;
    this.nUploadedLogoSize = this.oLooknFeelForm.nUploadedLogoSize;
    if (this.oLooknFeelForm.oUploadedLogo) {
      this.sUploadedLogoName = this.oLooknFeelForm.oUploadedLogo.filename;
    }
  }

  VideoTimelineComponent_DragAndDropCTA(event: CdkDragDrop<any[]>) {
    console.log("VideoTimelineComponent_DragAndDropBlock  : ==>", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.lcta = event.container.data;
    console.log("CTA's configuration after : ==> ", this.lcta);
  }

  OnAudioPlayButtonClick() {



    if (this.voiceoverWaveform) {
      this.voiceoverWaveform.pause();
    }
    this.bToggleVoiceoverButton = true
    this.audioWaveform.play();
    this.bToggleAudioButton = false
    this.videoClips.nativeElement.pause();

  }
  OnAudioPauseButtonClick() {
    this.audioWaveform.pause();
    this.bToggleAudioButton = true
  }
  OnVoiceoverPlayButtonClick() {
    this.bToggleAudioButton = true;
    if (this.audioWaveform)
      this.audioWaveform.pause();
    if (this.voiceoverWaveform)
      this.voiceoverWaveform.play();
    this.bToggleVoiceoverButton = false
    this.videoClips.nativeElement.pause();
  }
  OnVoiceoverPauseButtonClick() {


    this.voiceoverWaveform.pause();
    this.bToggleVoiceoverButton = true
  }
  LoadAudioFile(event) {
    // console.log("VideoTimelineComponent_LoadUploadedBackgroundMusic :==========> ", event);
  }
  renderedAudioFile(event) {
    // console.log("CustomEditVideo_RenderUploadedBackgroundMusic :==========> ", event);
  }

  ReplaceText(oIncommingEmitedBlock: any) {
    if (oIncommingEmitedBlock) {
      this.selectedBlock_text = oIncommingEmitedBlock;
      this.videoManager.selectedVideo.template_info.contents.forEach(cont => {
        if (cont.id == this.selectedBlock_text.id) {
          cont.text = this.selectedBlock_text.text;
        }

      });


    }
    this.closeVideo();
    this.closeTitle();
  }


  RevertText() {

    this.videoManager.selectedVideo.template_info.contents.forEach(cont => {
      if (cont.id == this.selectedBlock_text.id) {
        this.selectedBlock_text.text = cont.text;
      }

    });
    this.closeTitle();
  }

  VideoTimelineComponent_EmitedBlockToSave(oIncommingEmitedBlock: any) {
  
    console.log('selected block', this.oSelectedBlock)
    this.clips.forEach(ele => {
      if (ele.id === this.oSelectedBlock.id) {
        console.log('clip before change', ele);
        ele.source = oIncommingEmitedBlock['replaceclip'];
        ele.thumbnail = oIncommingEmitedBlock['thumbnail'];
        ele.url = oIncommingEmitedBlock['url'];
        console.log('clip after change', ele);
      }
    });
    console.log(this.clips)
    this.closeVideo();
    this.closeTitle();
  }
  VideoTimelineComponent_EmitedBlockToRevert(oIncommingEmitedBlock: any,) {
    this.closeVideo();
    this.closeTitle();
  }

  VideoTimelineComponent_DragAndDropBlock(event: CdkDragDrop<any[]>) {
    console.log("VideoTimelineComponent_DragAndDropBlock  : ==>", event);
    if (event.previousContainer === event.container) {

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.clips = event.container.data;
    console.log('clips---------------------', this.clips);

  }

  Text_DragAndDropBlock(event: CdkDragDrop<any[]>) {
    console.log("VideoTimelineComponent_DragAndDropBlock  : ==>", event);
    if (event.previousContainer === event.container) {

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.texts = event.container.data;


  }
  VideoTimelineComponent_SelectBlock(oIncommingBlock: any, index) {


    this.oSelectedBlock = oIncommingBlock;
 
    this.bDisplayBlockSidePanel = true;
    
  }
  VideoTimelineComponent_SelectBlockForText(oIncommingBlock: any, index) {


   this.oSelectedBlock = oIncommingBlock;
    this.bDisplayBlockTextSidePanel = true;
 
  }
  VideoTimelineComponent_SendUpdateRequest(oIncommingPayload: any) {
    this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.VideoTimelineComponent_HandleUserVideoResponse);
    this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oIncommingPayload));
  }
  VideoTimelineComponent_HandleUserVideoResponse = (InComingMessage) => {
    if (JSON.parse(InComingMessage)["errorMessage"]) {

      this.sDisplayResponseMessage = 'An error occurred while creating video. Please try again.';
      console.log("CreateVideoContentComponent_HandleContentResponse : Request Error ==>", InComingMessage)
      $('#error-popup').modal("show");
    }
    else {
      const oResponse = JSON.parse(InComingMessage);
      console.log("VideoTimelineComponent_HandleUserVideoResponse : Response ==> ", oResponse);
      this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);

      this.oRouter.navigateByUrl('/dashboard/overview');
    }
  }
  async VideoTimelineComponent_SelectTemplateCategory(select, index) {
    let oName: any;
    this.nSelectedTemplateIndex = index;
    this.oSelectedTemplate = select;
    await this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategories(this.oSelectedTemplate).then(result => {
      this.lChangedTemplate = result;
      oName = this.lChangedTemplate[0];
    });

    this.fLooknFeelForm.patchValue({ template_category: this.oSelectedTemplate, template_name: oName.sName });
    this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
  }
  
  VideoTimelineComponent_UpdateVideo(bUpdateVideo: boolean) {
    console.log("Update video ==> ", bUpdateVideo);
    if (bUpdateVideo) {
      this.fContentForm = this.oSessionService.cSessionService_GetContentForm();
      this.fContentForm.lBlockConfig = this.lBlockConfigurations;
      this.fContentForm.cta = this.lcta;
      this.oSessionService.cSessionService_SetContentForm(this.fContentForm);
      const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
      console.log("Updated Video Request : ==> ", oEditVideoForm);
      this.VideoTimelineComponent_SendUpdateRequest(oEditVideoForm);
    }
  }
  async VideoTimeline_IncomingAudioFile(event) {
    console.log("the emitted event is ====>>>>", event);

    if (event.url) {
      this.bIsAudio = true;
      this.bToggleAudioButton = true;
      this.audioSource = event.url;
      console.log("If Condition ==>>");
    }
    else if (!event) {
      this.bIsAudio = false;
      this.videoManager.selectedVideo.music_file = "";
      console.log("empty message");
    }
    else if (event) {
      this.bIsAudio = true;
      this.videoManager.selectedVideo.music_file = event;
      const oPlayAudioFile = await this.oDataService.DataService_GetSignedURLForDownload(event);
      this.audioSource = oPlayAudioFile;
      console.log("Else Condition ==>>");
    }
    this.closeAudio();
  }
  VideoTimeline_IncomingVoiceOverFile(event) {
    if (event.url) {
      this.bToggleVoiceoverButton = true;
      this.voiceoverSource = event.url;
      console.log("If Condition ==>>");
    } else if (event.visual_path) {
      this.videoManager.selectedVideo.voice_file = event.visual_path;
    }
    else {
      this.voiceoverSource = "";
      this.videoManager.selectedVideo.voice_file = '';
      console.log("Else Case For Voice Over ==>>>");
    }
  }

  async VideoTimeLineComponent_GetIncomingVideoDetails(oEditVideoConfiguration) {
    console.log("Video Config Details ======================>>>>>>>>>>>>>", oEditVideoConfiguration);
    let oMusicfile = oEditVideoConfiguration.music_file;
    if (oEditVideoConfiguration.music_file != "") {
      this.bIsAudio = true;
    }
    else {
      this.bIsAudio = false;
    }
    const oIncomingMusicFile = await this.oDataService.DataService_GetSignedURLForDownload(oMusicfile);
    this.audioSource = oIncomingMusicFile;
    this.oMusicFile = oEditVideoConfiguration;
    if (oEditVideoConfiguration.vo_file) {
      let oAudioFile = oEditVideoConfiguration.vo_file;
      const IncomingAudioFile = await this.oDataService.DataService_GetSignedURLForDownload(oAudioFile);
      this.voiceoverSource = IncomingAudioFile;
    }
    else {
      let oAudioFile = oEditVideoConfiguration.vo_text_file;
      if (oAudioFile) {
        this.voiceoverSource = await this.oDataService.DataService_GetSignedURLForDownload(oAudioFile);
      }
      else {
        this.voiceoverSource = "";
      }
    }

  }


  VideoTimelineComponent_PLayVideo(oIncommingId) {
    this.sSelectedVideoId = oIncommingId;
    console.log("Video ID:::", oIncommingId);
    this.lBlockConfigurations.forEach(element => {
      var video = <HTMLVideoElement>document.getElementById(element.id);
      if (element.id == oIncommingId) {
        video.play();
        this.voiceoverWaveform.pause();
        this.bToggleVoiceoverButton = true
        this.bToggleAudioButton = true
        this.audioWaveform.pause();
      }
      else {
        video.pause();
      }
    });
  }

  onPaused() {
    this.bToggleAudioButton = true;
  }
  onVoiceOverPaused() {
    this.bToggleVoiceoverButton = true;
  }
  VideoTimelineComponent_GetThumbnail(oIncommingClipPath) {
    let thumb = oIncommingClipPath.substr(0, 15) + 'thumbs/' + oIncommingClipPath.substr(15, oIncommingClipPath.length);
    thumb = 'https://storage.googleapis.com/pronto-video/' + thumb.replace(".mp4", "_thumb.jpg").replace(".mov", "_thumb.jpg").replace(".avi", "_thumb.jpg");
    return thumb;
  }
  async VideoTimelineViewComponent_GetClipsPreview() {
   
  }
  async VideoTimelineViewComponent_GetBlocksPreview() {
    console.log("Current Blocks ==> ", this.lBlockConfigurations);
    for (let nBlockIndex = 0; nBlockIndex < this.lBlockConfigurations.length; nBlockIndex++) {
      const oCurrentBlock = this.lBlockConfigurations[nBlockIndex]
      if (oCurrentBlock.url == "") {
      }
    }
    this.lBlockConfigurationsCopy = cloneDeep(this.lBlockConfigurations);
  }

  VideoTimelineViewComponent_GetVideoDetails() {
    this.oWebSocketService.WebSocket_RegisterEvent('video_data_result', this.VideoTimelineViewComponent_HandleSingleVideoResponse);
    let obj =
    {
      email: this.sUserEmail,
      session_id: this.sSessionId,
      video_id: this.sVideoId
    };
    this.oWebSocketService.WebSocket_SendMessage("video_data_request", JSON.stringify(obj));
  }
  VideoTimelineViewComponent_HandleSingleVideoResponse = (IncomingMessage) => {

    console.log("VideoTimelineViewComponent_HandleSingleVideoResponse : video Details => ", JSON.parse(IncomingMessage));
    if (JSON.parse(IncomingMessage)['errorMessage']) {
      console.error("VideoTimelineViewComponent_HandleSingleVideoResponse : ERROR ==>", JSON.parse(IncomingMessage)['errorMessage']);
    }
    else {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
      if (oFormatedIncommingMessage.video.block_config[0].block_file_path) {
        this.VideoTimelineViewComponent_UpdateBlockConfigurations(oFormatedIncommingMessage.video.block_config);
      }
      else {
        //poll over single video details until block_file_path is generated
        // this.nSetPollingTimer = setTimeout(() => {this.VideoTimelineViewComponent_GetVideoDetails()}, 10000);
      }
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('video_data_result', this.VideoTimelineViewComponent_HandleSingleVideoResponse);
  }
  async VideoTimelineViewComponent_UpdateBlockConfigurations(lIncommingBlocks: any) {
    for (let nBlockIndex = 0; nBlockIndex < lIncommingBlocks.length; nBlockIndex++) {
      const block_file_path = lIncommingBlocks[nBlockIndex].block_file_path;
      let url = await this.oDataService.DataService_GetSignedURLForDownload(block_file_path);
      let thumb = await this.oDataService.DataService_GetUserVisualThumbnail(block_file_path);
      let thumb_url = await this.oDataService.DataService_GetSignedURLForDownload(thumb);
      this.lBlockConfigurations[nBlockIndex].block_file_path = block_file_path;
      this.lBlockConfigurations[nBlockIndex].url = url;
    }
    console.log("Block Configuration : ==> ", this.lBlockConfigurations);
  }
  VideoTimelineComponent_RemoveHighlightBlock(event) {
    this.oSelectedBlock = null
  }
  


  openAudio(): void {
    this.session.visibleAudio = true;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleVoiceover = false;
    this.session.VideoSettingEditClip = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
  }
  closeAudio(): void {
    this.session.visibleAudio = false;
  }

  openVideo() {
    this.session.visibleVideo = true;
    this.session.visibleTitle = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.VideoSettingEditClip = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;

  }
  closeVideo(): void {
    this.session.visibleVideo = false;

  }
  selectedBlock_text: content;
  selectedBlock_text_index: number;
  openTitle(block, index): void {

    this.selectedBlock_text = block;
    this.selectedBlock_text_index = index;

    this.session.visibleTitle = true;
    this.session.visibleVideo = false;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.VideoSettingEditClip = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
  }
  closeTitle(): void {
    this.session.visibleTitle = false;
  }


  openVoiceover(): void {
    this.session.visibleVoiceover = true;
    this.session.visibleAudio = false;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.VideoSettingEditClip = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
  }
  closeVoiceover(): void {
    this.session.visibleVoiceover = false;
  }

  openVideoSettingEditClip(): void {

    this.session.VideoSettingEditClip = true;
    this.session.visibleVoiceover = false;
    this.session.visibleAudio = false;
    this.session.visibleVideo = false;
    this.session.visibleTitle = false;

    this.session.visiblePreviewVideo = false;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
  }
  closeVideoSettingEditClip(): void {
    this.session.VideoSettingEditClip = false;
  }





}
