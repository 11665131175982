import { environment } from './../../../../../environments/environment';
import { cDataService } from "src/app/services/data.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { brand, UserList } from "src/app/models/admin";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { BrandService } from "../service/BrandService";
import { cSessionService } from "src/app/services/session.service";
import { Userservice } from "../../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { ErrorService } from "src/app/services/error.service";
import { mediaFile } from "src/app/models/media";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { LoaderService } from "src/app/services/loader.service";
import { MediaService } from "src/app/services/dataServices/media.service";

@Component({
  selector: "app-manage-brand-account",
  templateUrl: "./manage-brand-account.component.html",
  styleUrls: ["./manage-brand-account.component.scss"],
})
export class ManageBrandAccountComponent implements OnInit {
  showAssignNewUserDrawer = false;
  placementUser: NzDrawerPlacement = "right";
  brandId: string;
  userId: any;
  brandDetail: brand = {};
  public assignUserForm: FormGroup;
  public updateBrandDetailForm: FormGroup;
  userList: any = [];
  modelUserSearchChanged = new Subject<string>();
  isUserSearchLoading: boolean = false;
  brandUserList: any = [];
  limit = 100;
  offset = 0;
  brandName = "";
  errorBrandEmptry = false;
  selectedUser: any;
  isEdit = false;
  showFacebookTokenInput = false;
  facebookAccessTokenParam = "";
  socialConnectData = {
    fbAccessToken: "",
    fbPageId: "",
    youtubeClientId: "",
    youtubeClientSecret: "",
    youTubeAccessToken: "",
    linkedInClientSecret: "",
    linkedInClientId: "",
    instagramAccessToken: "",
    instagramPageId: "",
    isFBConnected: false,
    isYTConnected: false,
    isLinkedInConnected: false,
    isIsntagramConnected: false,
    showYTInputDialog: false,
    showLinkedInConnectModal: false,
    showInstagramConnectModal: false,
  };
  public brandSocialForm: FormGroup;
  @ViewChild("userListAutoComplete", { read: MatAutocompleteTrigger })
  autoCompleteUser: MatAutocompleteTrigger;
  @ViewChild('searchUserField')  searchUserField: ElementRef;

  constructor(
    private userService: Userservice,
    private assignUserFormBuilder: FormBuilder,
    private updateBrandDetailFormBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private adminBrandManager: AdminBrandManagerService,
    private brandService: BrandService,
    public oSessionService: cSessionService,
    private successMessageService: SuccessMessageModalService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private mediaManger: MediaManagerService,
    private loader: LoaderService,
    public mediaService: MediaService
  ) {
    this.oSessionService.setAccountSettingHeaderName("Manage Brands");
  }

  ngAfterViewInit(): void {
    this.brandSocialForm = this.formBuilder.group({
      fbAccessToken: ["", [Validators.required, Validators.minLength(100)]],
      youTubeAccessToken: [
        "",
        [Validators.required, Validators.minLength(100)],
      ],
      linkedInAccessToken: [
        "",
        [Validators.required, Validators.minLength(100)],
      ],
    });
    this.modelUserSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          this.userList = await this.userService
            .searchUser(this.offset, this.limit, "created", -1, "_id", value)
            .then((resList) => {
              return (this.userList = resList);
            });

          this.isUserSearchLoading = false;
          return this.userList;
        } else {
          return [];
        }
      });
  }
  ngOnInit(): void {
    this.brandId = this.route.snapshot.params["id"];

    this.getBrandProfile();

    this.getBrandUserList();
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    if (this.autoCompleteUser && this.autoCompleteUser.panelOpen) {
      this.autoCompleteUser.updatePosition();
    }
  };

  getBrandProfile() {
    let __this = this;
    this.adminBrandManager
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {
        __this.brandDetail = brand;
        __this.userService.selectedMediaId = brand.media_libs
          ? brand.media_libs
          : undefined;
        __this.adminBrandManager.selectedBrandProfile = brand;
        __this.updateBrandDetailForm = this.updateBrandDetailFormBuilder.group({
          name: brand.name,
        });
        __this.brandName = brand.name;
        this.getFBAccessToken();
        this.getLinkedInAccessToken();
        this.getYoutTubeAccessToken();
        this.getInstagramAccessToken();
      });
  }
  // add assign new user drawer
  openAssignNewUserDrawer(): void {
    // if (this.userList.length == 0) {
    //   this.getUserList();
    // }
    this.assignUserForm = this.assignUserFormBuilder.group({
      brand: "",
      user: "",
      searchUserText: "",
    });
    this.showAssignNewUserDrawer = true;
    setTimeout( () => {
      if(this.searchUserField){
        this.searchUserField.nativeElement.focus();
      }
    }, 500)
  }
  closeAssignNewUserDrawer(): void {
    this.showAssignNewUserDrawer = false;
  }

  getUserId(id: any) {
    this.userId = id;
    console.log("userId", this.userId);
  }
  assignUserBrand() {
    if (this.userId && this.userId !== "") {
      this.adminBrandManager
        .addUserBrand([this.brandId], [this.userId], false)
        .then((response: any) => {
          if (response) {
            if (response && response.records && response.records.length > 0) {
              response.records.map((record) => {
                console.log(record);
                console.log(record.user._id);
                // this.userService.getUserProfile(record.user._id, false).then((brandDetail : any) => {

                //   this.brandUserList.unshift({_id : record.user_brand_id, brand : brandDetail});
                // });
              });
            } else {
              // this.assignBrandSubmit = false;
              // this.showAssignNewBrandDrawer = false;
              // this.errorService.handleError(response.result, "info", true);
            }

            this.userId = undefined;
            this.getBrandUserList();
            this.showAssignNewUserDrawer = false;
          }
        });
    } else {
      this.errorService.handleError(
        "Please select a user",
        "Assign user to brand",
        true,
        false,
        true
      );
    }
    // this.showAssignNewUserDrawer = false;
  }
  getBrandUserList() {
    this.adminBrandManager
      .getBrandUsers(this.brandId, false)
      .then((resList) => {
        this.brandUserList = resList["records"];
      });
  }

  loadBrandMediaLibraryList() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/list-media-libraries`,
    ]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/media-libraries`,
    ]);
  }
  loadBrandBusiness() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/business-details`,
    ]);
  }

  deleteAssignedUser(id: string) {
    this.adminBrandManager.deleteAssignedUser(id).then((res) => {
      const indexRecrod = this.brandUserList.findIndex(
        (user) => user._id.$oid == id
      );
      if (indexRecrod > -1) {
        this.successMessageService.display(
          "User has been deleted.",
          "Delete User",
          true,
          true
        );
        this.brandUserList.splice(indexRecrod, 1);
      }
    });
  }

  saveBrandDetail() {
    const brand = {
      _id: this.brandDetail._id.$oid,
      name: this.brandName,
    };
    if (this.brandName != "") {
      this.brandService.updateBrandDetails(brand).then((res) => {
        this.brandDetail.name = this.brandName;
      });
    } else {
      this.errorBrandEmptry = true;
    }

    this.isEdit = false;
  }
  // getUserList() {
  //   let __this = this;
  //   this.userService
  //     .getUsers(this.offset, this.limit, "created", -1, "", "_id")
  //     .then((list: UserList) => {
  //       console.log("userList", JSON.stringify(list));

  //       __this.userList = list['result'];
  //     });
  // }
  showDeleteUserModal = false;

  showDeleteUserModel(User: string) {
    this.selectedUser = User;
    this.showDeleteUserModal = true;
  }

  deleteUser() {
    this.showDeleteUserModal = false;
    this.deleteAssignedUser(this.selectedUser);
  }
  Change() {
    this.isEdit = true;
  }

  saveLinkedInAccessToken(disconnect = false){
    if (!disconnect) {
      if (
        this.socialConnectData.linkedInClientId != "" &&
        this.socialConnectData.linkedInClientSecret != ""
      ) {
        this.brandService
          .updateBrandLinkedInAccessToken({
              brand_id: this.brandId,
              client_id: this.socialConnectData.linkedInClientId,
              client_secret: this.socialConnectData.linkedInClientSecret,
              return_url : environment.WEB_URL+ window.location.pathname
            }
          )
          .then((result : any) => {
            this.socialConnectData.isLinkedInConnected = true;
            this.socialConnectData.showLinkedInConnectModal = false;
            window.open(result?.url);
          });
      }
    } else {
      this.socialConnectData.linkedInClientId = "";
      this.socialConnectData.linkedInClientSecret = "";
      this.brandService.deleteLinkedInAccessToken(this.brandId).then(() => {
        this.socialConnectData.isLinkedInConnected = false;
        this.socialConnectData.showLinkedInConnectModal = false;
      });
    }
  }
  updateInstagramAccessToken(disconnect = false){
    if (!disconnect) {
      if (
        this.socialConnectData.instagramPageId != "" &&
        this.socialConnectData.instagramAccessToken != ""
      ) {
        this.brandService
          .updateBrandInstagramAccessToken({
              brand_id: this.brandId,
              page_id: this.socialConnectData.instagramPageId,
              access_token: this.socialConnectData.instagramAccessToken,
              return_url : environment.WEB_URL+ window.location.pathname
            }
          )
          .then((result : any) => {
            this.socialConnectData.isIsntagramConnected = true;
            this.socialConnectData.showInstagramConnectModal = false;
          });
      }
    } else {
      console.log("Delete instagram ..");
      this.socialConnectData.instagramPageId = "";
      this.socialConnectData.instagramAccessToken = "";
      this.brandService.deleteInstagramAccessToken(this.brandId).then(() => {
        this.socialConnectData.isIsntagramConnected = false;
        this.socialConnectData.showInstagramConnectModal = false;
      });
    }
  }
  saveFacebookAccessToken(disconnect = false) {
    if (!disconnect) {
      if (
        this.socialConnectData.fbAccessToken != "" &&
        this.socialConnectData.fbPageId != ""
      ) {
        this.brandService
          .updateFBAccessToken(
            this.socialConnectData.fbAccessToken,
            this.brandId,
            this.socialConnectData.fbPageId
          )
          .then(() => {
            this.socialConnectData.isFBConnected = true;
            this.showFacebookTokenInput = false;
          });
      }
    } else {
      this.socialConnectData.fbAccessToken = "";
      this.brandService.deleteFBAccessToken(this.brandId).then(() => {
        this.socialConnectData.isFBConnected = false;
        this.showFacebookTokenInput = false;
      });
    }
  }

  getFBAccessToken = () => {
    this.brandService
      .getFBAccessToken(this.brandId)
      .then((res: any) => {
        if (res && res.result && res.result._id) {
          this.socialConnectData.isFBConnected = true;
          // this.showFacebookTokenInput = false;
        }
      })
      .catch(() => {
        // this.showFacebookTokenInput = false;
      });
  };
  getLinkedInAccessToken = () => {
    this.brandService
      .getLinkedInAccessToken(this.brandId)
      .then((res: any) => {
        if (res && res.result && res.result._id) {
          this.socialConnectData.isLinkedInConnected = true;
          // this.showFacebookTokenInput = false;
        }
      })
      .catch(() => {
        // this.showFacebookTokenInput = false;
      });
  };
  getInstagramAccessToken = () => {
    this.brandService
      .getInstagramAccessToken(this.brandId)
      .then((res: any) => {
        if (res && res.result && res.result._id) {
          this.socialConnectData.isIsntagramConnected = true;
        }
      });
  };

  disConnectLinkedInt(){

  }
  saveYouTubeAccessToken(disconnect = false) {
    if (!disconnect) {
      if (
        this.socialConnectData.youtubeClientSecret != ""
      ) {
        const dataYT = {
          brand_id: this.brandId,
          secrets_file: this.socialConnectData.youtubeClientSecret,
          return_url : window.location.pathname
        };
        console.log(dataYT);
        this.brandService.updateYouTubeAccessToken(dataYT).then((result : any) => {
          this.socialConnectData.isYTConnected = true;
          this.socialConnectData.showYTInputDialog = false;
          console.log(result);
          window.open(result.url);
        });
      }
    } else {
      this.socialConnectData.fbAccessToken = "";
      this.brandService.deleteYouTubeAccessToken(this.brandId).then(() => {
        this.socialConnectData.isYTConnected = false;
        this.socialConnectData.showYTInputDialog = false;
      });
    }
  }

  getYoutTubeAccessToken = () => {
    this.brandService
      .getYouTubeAccessToken(this.brandId)
      .then((res: any) => {
        if (res && res.result) {
          this.socialConnectData.isYTConnected = true;
          // this.showFacebookTokenInput = false;
        }
      })
      .catch(() => {
        // this.showFacebookTokenInput = false;
      });
  };

  youtubeFilePath = undefined;
  uploadYouTubeSecretFile = async (event) => {
    let file = event.target.files[0];

    if (file) {
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;

      if (this.userService.selectedMediaId) {
        try {
          this.mediaService
            .DataService_BulkUpload(
              this.oSessionService.cSessionService_GetUserEmail(),
              this.oSessionService.SessionService_GetSessionId(),
              this.userService.selectedMediaId[0]?._id?.$oid,
              media.name,
              file.type,
              this.brandId,
              "user",
              undefined,
              true
            )
            .then((dataUpload: any) => {
              var signed_url = dataUpload["signed_url"];
              var fpath = dataUpload["fpath"];
              this.socialConnectData.youtubeClientSecret = fpath;
              this.mediaService
                .DataService_UploadFileOnSignedUrl(file, signed_url)
                .then(async (es) => {
                  this.loader.hideLoader();
                  this.socialConnectData.youtubeClientSecret = dataUpload.fpath;
                  this.youtubeFilePath = dataUpload.fpath;
                })
                .catch((error) => {
                  console.log(error);
                  this.loader.hideLoader();
                });
            })
            .catch((error) => {
              console.log(error);
              this.loader.hideLoader();
            });
        } catch (err) {
          console.log(err);
          this.loader.hideLoader();
        }
      }
    }
  };
}
