import { mediaLib } from 'src/app/models/media';
import { MediaLibSearch } from './../../../../models/media';
import { Injectable } from '@angular/core';
import { UserDataInfo } from "src/app/models/user";
import { brand, brandList } from 'src/app/models/admin';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from 'src/app/services/dataServices/admin.service';
import { cSessionService } from 'src/app/services/session.service';
import { user, UserList } from "src/app/models/admin";
import { ErrorService } from 'src/app/services/error.service';
import { rejects } from 'assert';
import { HttpClient } from '@angular/common/http';
import { ApiURL } from 'src/app/shared/conf/api-path';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminMediaLibraryPageService {
  sessionId: any;
  email: any;
  selectedUser: user;
  public mediaLibraryDetail: brand = {};
  public mediaLibraryList: MediaLibSearch = undefined;
  selectedBrandProfile : brand;
  constructor(
    private errorService: ErrorService,
    private loader: LoaderService,
    private dataService: AdminService,
    private http: HttpClient,
    public oSessionService: cSessionService) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();

  }

  public getListMedia(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sort: string,
    asc: number,
    search_term = "",
    search_by = "_id",
    showLoader = true
  ) {

    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .getListMediaLibraries(email, sessionId, offset, limit, sort, asc, search_by, search_term)
        .then((res) => {
          if (showLoader)
            this.loader.hideLoader();

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader)
            this.loader.hideLoader();
            
          reject(err);
        });
    });

    return promise;
  }

  public searchUser(name: string): Promise<UserDataInfo[]> {
    return new Promise((resolve, reject) => {

      console.log("userList3", name);
      return resolve([
        { id: "1", name: "Sharjeel bhai " },
        { id: "2", name: "Wasim bhai " },
        { id: "3", name: "muneeb " },
      ])
    });
  }

  public getBrandProfile(id: string, showLoader = true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {

      this.dataService.getBrandProfile(id)
        .then((res: brand) => {
          this.mediaLibraryDetail = res;
          if (showLoader)
            this.loader.hideLoader();
          
          
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader)
            this.loader.hideLoader();
            
          reject(err);
        });
    });

    return promise;
  }


  addUserBrand(brandId:any, userIds: any, showLoader= true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {

      this.dataService.addUserBrand(brandId, userIds)
        .then((res) => {

          if (showLoader)
            this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          
          console.log("addUserBrandErr",err);
          if (showLoader)
            this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  
  async getMediaLibraryProfile(
    _id: string
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get, {_id})
    .toPromise();
  }

  deleteMediaLibrary(
    _id: string
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.media.delete, {_id})
    .toPromise();
  }

  getMediaLibraryData(
    _id: string
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get, {_id})
    .toPromise();
  }

  getBrandUsers(brandId:any, showLoader= true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {

      this.dataService.listBrandsUsers(brandId)
        .then((res) => {

          if (showLoader)
            this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);          
          if (showLoader)
            this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public updateBrand(brand: brand) {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService
        .updateBrand(brand)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError("Here is ErrorMessage", "Error", true);
          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }

  updateMediaLibDetails = ( brand: any ) : Promise<any> => {
    return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.media.update, brand).toPromise();
  }
  updateMediaLibAccess = ( _id: any, library_access:any) : Promise<any> => {
    return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.media.update, _id, library_access).toPromise();
  }

}
