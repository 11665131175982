export const environment = {
  IS_AZURE: true,
  production: true,
  API_HOST: 'https://app.prontopia.com/app/',
  API_HOST_ADMIN: '/api/',
  WS_HOST: 'https://app.prontopia.com',
  WEB_URL: 'https://app-admin.prontopia.com',
  WS_PATH: '/ws',
  PRONTO_USER_SESSION_TIMEOUT: 7200000, // 2 Hours

   // for business plans
  business_plan : 'business',
  bDisplayLocales: false,
  // business_plan : 'business_plus',
  // business_plan : 'enterprise_agency',
  // business_plan : 'enterprise_franchesees',
};
