import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cSessionService } from './../../../services/session.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-timeline-view-process',
  templateUrl: './timeline-view-process.component.html',
  styleUrls: ['./timeline-view-process.component.scss']
})
export class TimelineViewProcessComponent implements OnInit , OnDestroy {

  @Input() bResCheck;
  sVideoTitle : string;
  sVideoDuration : string;
  nProgress: number = 30;
  oInterval: NodeJS.Timeout;
  bubbleArr : any = [true , false , false , false];
nInterval : any;
nCurrentIndex : number = 0;
  constructor(private oSessionService : cSessionService,public videoManager:VideoManagerService) { }

  ngOnInit(): void 
  {
    this.sVideoTitle = this.videoManager?.selectedVideo?.title;
    this.sVideoDuration = this.videoManager?.selectedVideo?.duration.toString();
    this.nProgress =0

    interval(500).pipe(takeWhile(() => this.nProgress !=90)).subscribe(() => {
    
      this.nProgress = this.nProgress + 5
    });
    this.oInterval = setInterval(() => {this.TimelineViewProcessComponent_FillProgressBar();}, 2000);
    this.nInterval = setInterval(() => {
      this.TimelineViewProcessComponent_DisplayBubbles();
    }, 4000);
  }
  ngOnDestroy(): void {clearInterval(this.nInterval)}

  TimelineViewProcessComponent_FillProgressBar()
  {
    if(this.bResCheck == true)
    {
      this.nProgress=100;
      clearInterval(this.oInterval);
    }
  }
  TimelineViewProcessComponent_DisplayBubbles()
  {    
    for (let nBubbleIndex = 0; nBubbleIndex < this.bubbleArr.length; nBubbleIndex++) 
    {
      if(this.nCurrentIndex == nBubbleIndex)
      {
        this.bubbleArr[nBubbleIndex]=true;
      }
      else
      {
        this.bubbleArr[nBubbleIndex]=false;
      }
      
    }
    this.nCurrentIndex++;
    if(this.nCurrentIndex==this.bubbleArr.length){this.nCurrentIndex=0;}
  }
  }