<app-header></app-header>
<section class="dashboard main-body bg-gray-7 h-100">
    <div class="container">
        <div class=" br12 bg-white page-shadow curve-bg pb-0">
            <div class="py-4 px-4 pb-0 account-heading">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="weight-700 text-gray-2"> Connect with your <span class="text-primary-3">customers</span></h3>
                        <p class="mx-0 my-0 text-sm text-gray-3">Analyze your views and impressions.</p>
                    </div>

                </div>
            </div>
            <div class="h-80vh pb-0 d-flex flex-column reports-sec">

                <div class="video-header row m-0">
                    <app-reports-breadcrumb class="col-lg-3 nav"></app-reports-breadcrumb>
                    <ul class="text-end d-block col-lg-9">


                        <li id="selectpreff">
                            <div class="formItm down_arrow">
                                <div class="dropdown dropdownBtn">
                                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <span class="ms-2 dropdownBtnText">Last 30 days</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                        <li class="active"><a class="dropdown-item text-md" href="#;">Last 30 days</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                 <!-- if no reports are there -->
                 <div class="d-none row m-0">
                    <app-empty-reports></app-empty-reports>
                   </div>
                <div class="row m-0 flex-grow-1">
                    <app-reports-sidebar-videos class="col-lg-3"></app-reports-sidebar-videos>
                    <div class="col-lg-9 p-4">
                        <div class="row">
                            <div class="col-lg-6 dashboard-reach">
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-1">Watch Time</h6>
                                        <p class="text-gray-3 text-xs h-end">The total amount of time your video has been watched by
                                            viewers to-date.</p>
                                        <h5 class="align-self-end">3hrs<span>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                        fill="#00C599"></path>
                                                </svg>
                                                20%</span></h5>
                                    </article>
                                </div>
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-3">Top Performing video</h6>
                                        <div class="video-outer top-perform">
                                            <div class="video_wrapper video_wrapper_full js-videoWrapper pt-2 mt-1">
                                                <iframe class="videoIframe js-videoIframe"
                                                    src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                                    title="YouTube video player" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen=""></iframe>
                                                <figure class="video-baner"><img src="../assets/images/myVideos/v2.png"
                                                        alt="video1"> </figure>
                                                <button class="videoPoster js-videoPoster"></button>
                                            </div>
                                            <div class="top-perform-text">
                                                <p class="text-md p-0 m-0 ">Family chiropractic
                                                    center of richmond</p>
                                                <p class="d-flex p-0 m-0">
                                                    <span class="video-view text-xxs pe-5 text-gray-3">Views
                                                        <b class="d-block text-md text-gray-2">675</b></span>
                                                    <span class="video-view text-xxs text-gray-3">Watch
                                                        Time <b class="d-block text-md text-gray-2">30
                                                            Min</b></span>
                                                </p>
                                            </div>

                                        </div>
                                    </article>
                                </div>
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-1">Shares</h6>
                                        <p class="text-gray-3 text-xs h-end">How many times your video has been shared.</p>
                                        <h5 class="align-self-end">425<span>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                        fill="#00C599"></path>
                                                </svg>
                                                10%</span></h5>
                                    </article>
                                </div>
                            </div>
                            <div class="col-lg-6 dashboard-reach">
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-1">CTA Interactions</h6>
                                        <p class="text-gray-3 text-xs h-end">Total Interactions</p>
                                        <h5 class="align-self-end right-top">500<span>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                        fill="#00C599"></path>
                                                </svg>
                                                10%</span></h5>

                                        <div id="chart">
                                            <!-- <apx-chart [grid]="chartOptions.grid" [series]="chartOptions.series"
                                                [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
                                                [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis"
                                                [colors]="chartOptions.colors" [markers]="chartOptions.markers"
                                                [legend]="chartOptions.legend" [tooltip]="chartOptions.tooltip"
                                                [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis"></apx-chart> -->
                                                <div class="progress-bars">
                                                    <ul>
                                                        <li>
                                                            <span>Website</span>
                                                            <p><span style="width: 30%;"></span>30%</p>
                                                        </li>
                                                        <li>
                                                            <span>E-mail</span>
                                                            <p><span style="width: 80%;"></span>80%</p>
                                                        </li>
                                                        <li>
                                                            <span>Phone</span>
                                                            <p><span style="width: 50%;"></span>50%</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>