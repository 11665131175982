import { time } from "console";
import { block_args } from "./video";

export class templateConfig
{
    public block_config :any;
    public  block_names :any;
    public   block_params :any;
    public  clip_effect_names :any;
    public  durations :any;
    public second_logos :any;
    public  template_categories :any;
    public template_config :any;
}


export class templatesCategory
{
    public category:string;
    public templates:template[];
}

export class template
{
      preview_path: string;
      template_name:string;
      thumb: string;

      preview_url?:string;
      thumbnail?:string;
      loadingPreviewUrl:boolean;
      loadingThumbnailUrl?:boolean;
      allowed_fonts:[];
      categories:[];
      tags:[];
      second_logo:boolean = false;
      templateBlockConfiguration : templateBlockConfiguration[];
      previews: preview[];
}
 export class preview
    {
        duration: number;
        preview: string;
        url:string;
        thumbnail:string;
        thumb: string;
    }

export class templateBlockConfiguration
{
    public timeframe:number;;
    public blocks:block_args[]=[];
}