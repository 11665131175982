import { BrandMediaLibsComponent } from './components/myAccount/manage-brand/list-media-libs/brand-media-libs.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountCompanyComponent } from './components/on-boarding/create-account-company/create-account-company.component';
import { CreateAccountComponent } from './components/on-boarding/create-account/create-account.component';
import { ForgotPasswordComponent } from './components/on-boarding/forgot-password/forgot-password.component';
import { LoginComponent } from './components/on-boarding/login/login.component';
import { ResetPasswordComponent } from './components/on-boarding/reset-password/reset-password.component';
import { SorryComponent } from './components/on-boarding/sorry/sorry.component';
import { MyAccountComponent } from './components/myAccount/my-account/my-account.component';
import { BillingComponent } from './components/myAccount/billing/billing.component';
import { SubscriptionComponent } from './components/myAccount/subscription/subscription.component';
import { LoginSecurityComponent } from './components/myAccount/login-security/login-security.component';
import { AuthenticationComponent } from './components/on-boarding/authentication/authentication.component';
import { AuthenticationCodeComponent } from './components/on-boarding/authentication-code/authentication-code.component';
import { OverviewComponent } from './components/video-wizard-view/overview/overview.component';
import { LooknFeelComponent } from './components/video-wizard-view/looknfeel/looknfeel.component';
import { ContentComponent } from './components/video-wizard-view/content/content.component';
import { OtherSettingComponent } from './components/video-wizard-view/other-setting/other-setting.component';
import { SocialChannelsComponent } from './components/social-channels/social-channels.component';
import { EditVideoOverviewComponent } from './components/edit-video/wizard-view/edit-video-overview/edit-video-overview.component';
import { EditVideoContentComponent } from './components/edit-video/wizard-view/edit-video-content/edit-video-content.component';
import { EditVideoOtherSettingComponent } from './components/edit-video/wizard-view/edit-video-other-setting/edit-video-other-setting.component';
import { PreviewLocaleComponent } from './components/edit-video/wizard-view/preview-locale/preview-locale.component';
import { PublishedVideosComponent } from './components/dashboard-videos/published-videos/published-videos.component';
import { DraftVideosComponent } from './components/dashboard-videos/draft-videos/draft-videos.component';
import { ScheduledVideosComponent } from './components/dashboard-videos/scheduled-videos/scheduled-videos.component';
import { PreviewComponent } from './components/video-wizard-view/preview/preview.component';
import { DashboardMyvideoComponent } from './components/dashboard/dashboard-myvideo/dashboard-myvideo.component';
import { DashboardSchduledVideosComponent } from './components/dashboard/dashboard-schduled-videos/dashboard-schduled-videos.component';
import { BrandIdentityComponent } from './components/business-profile/brand-identity/brand-identity.component';
import { UserMediaComponent } from './components/business-profile/user-media/user-media.component';
import { CustomersExperienceComponent } from './components/business-profile/customers-experience/customers-experience.component';
import { SocialsChannelsComponent } from './components/business-profile/socials-channels/socials-channels.component';
import { ReportsReachComponent } from './components/reports/reports-reach/reports-reach.component';
import { ReportsEngagementComponent } from './components/reports/reports-engagement/reports-engagement.component';
import { ReportsAudienceComponent } from './components/reports/reports-audience/reports-audience.component';
import { DashboardSocialmediaComponent } from './components/dashboard/dashboard-socialmedia/dashboard-socialmedia.component';
import { DashboardDownloadComponent } from './components/dashboard/dashboard-download/dashboard-download.component';
import { DashboardOverviewComponent } from './components/dashboard/dashboard-overview/dashboard-overview.component';
import { EditVideoLookFeelComponent } from './components/edit-video/wizard-view/edit-video-look-feel/edit-video-look-feel.component';
import { UserComponent } from './components/business-profile/user/user.component';
import { UserAccountComponent } from './components/business-profile/user/user-account/user-account.component';
import { UserPublishedVideoComponent } from './components/business-profile/user/user-published-video/user-published-video.component';
import { UserManageLibrariesComponent } from './components/business-profile/user/user-manage-libraries/user-manage-libraries.component';
import { MediaLibraryComponent } from './components/business-profile/media-library/media-library.component';
import { BrandsComponent } from './components/business-profile/brands/brands.component';
import { BrandsAccountComponent } from './components/business-profile/brands/brands-account/brands-account.component';
import { BrandsBusinessDetailsComponent } from './components/business-profile/brands/brands-business-details/brands-business-details.component';
import { BrandsPublishedVideoComponent } from './components/business-profile/brands/brands-published-video/brands-published-video.component';
import { BrandsMediaLibraryComponent } from './components/business-profile/brands/brands-media-library/brands-media-library.component';
import { FranchiseesComponent } from './components/business-profile/franchisees/franchisees.component';
import { FrancheseesAccountComponent } from './components/business-profile/franchisees/franchesees-account/franchesees-account.component';
import { FrancheseesPublishedVideoComponent } from './components/business-profile/franchisees/franchesees-published-video/franchesees-published-video.component';
import { FrancheseesMediaLibraryComponent } from './components/business-profile/franchisees/franchesees-media-library/franchesees-media-library.component';
import { EnterpriseMediaComponent } from './components/business-profile/enterprise-media/enterprise-media.component';
import { EnterpriseMediaLibraryComponent } from './components/business-profile/enterprise-media-library/enterprise-media-library.component';
import { AuthGuard } from './services/auth.guard';
import { LocalesGuard } from './services/locales.guard';
import { TimelineViewProcessComponent } from './components/published-video/timeline-view-process/timeline-view-process.component';
import { TimelineViewComponent } from './components/published-video/timeline-view/timeline-view.component';
import { VideoTimelineComponent } from './components/edit-video/timeline-view/video-timeline/video-timeline.component';
import { FirstTimeDashboardComponent } from './components/dashboard/first-time-dashboard/first-time-dashboard.component';
import { ManageCommentsComponent } from './components/dashboard/manage-comments/manage-comments.component';
import { SelectPlanComponent } from './components/on-boarding/select-plan/select-plan.component';
import { CompletePaymentComponent } from './components/on-boarding/complete-payment/complete-payment.component';
import { PaywallPaymentFailedComponent } from './components/on-boarding/paywall-payment-failed/paywall-payment-failed.component';
import { PaywallPaymentSuccessComponent } from './components/on-boarding/paywall-payment-success/paywall-payment-success.component';
import { PublishVideoTimelineViewFunFectComponent } from './components/published-video/publish-video-timeline-view-fun-fect/publish-video-timeline-view-fun-fect.component';
import { CommentSectionComponent } from './components/published-video/comment-section/comment-section.component';
import { PublicVideosComponent } from './components/public-videos/public-videos.component';
import { PublicPageComponent } from './components/published-video/public-page/public-page.component';
import { FtueLoginComponent } from './components/first-time-user-experience/ftue-login/ftue-login.component';
import { FtueOverviewComponent } from './components/first-time-user-experience/ftue-overview/ftue-overview.component';
import { FtueLooknfeelComponent } from './components/first-time-user-experience/ftue-looknfeel/ftue-looknfeel.component';
import { FtueContentComponent } from './components/first-time-user-experience/ftue-content/ftue-content.component';
import { FtueOtherSettingComponent } from './components/first-time-user-experience/ftue-other-setting/ftue-other-setting.component';
import { FtuePreviewComponent } from './components/first-time-user-experience/ftue-preview/ftue-preview.component';
import { VerifyResetPasswordComponent } from './components/verify-reset-password/verify-reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SupportComponent } from './components/on-boarding/support/support.component';
import { BrandsIdentityComponent } from './components/myAccount/brand-identity/brand-identity.component';
import { LocationsComponent } from './components/myAccount/locations/locations.component';
import { SociallChannelsComponent } from './components/myAccount/sociall-channels/sociall-channels.component';
import { StorageDetailsComponent } from './components/myAccount/storage-details/storage-details.component';
import { MediaLibrariesComponent } from './components/myAccount/media-libraries-page/list/media-libraries.component';
import { PublisheddVideoComponent } from './components/myAccount/publishedd-video/publishedd-video.component';
import { MediaPageLibraryyComponent } from './components/myAccount/media-libraries-page/media/media-libraryy.component';
import { MediaPlatformsComponent } from './components/myAccount/media-platforms/media-platforms.component';
import { UsersComponent } from './components/myAccount/users/user-list/users.component';
import { UserAccountsComponent } from './components/myAccount/users/user-account/user-account.component';
import { UserAccountPublishedVideosComponent } from './components/myAccount/users/user-published-videos/user-published-videos.component';
import { UserAccountScheduleVideoComponent } from './components/myAccount/users/user-account-schedule-video/user-account-schedule-video.component';
import { UserAccountMediaLibrariesComponent } from './components/myAccount/users/user-account-media-libraries/user-account-media-libraries.component';
import { TimeoutErrorComponent } from './shared/timeout-error/timeout-error.component';
import { ManageBrandComponent } from './components/myAccount/manage-brand/manage-brand.component';
import { ManageBrandAccountComponent } from './components/myAccount/manage-brand/account/manage-brand-account.component';
import { ManageBrandPublishedVideosComponent } from './components/myAccount/manage-brand/manage-brand-published-videos/manage-brand-published-videos.component';
import { ManageBrandScheduleVideosComponent } from './components/myAccount/manage-brand/manage-brand-schedule-videos/manage-brand-schedule-videos.component';
import { ManageBrandMediaLibrariesComponent } from './components/myAccount/manage-brand/manage-brand-media-libraries/manage-brand-media-libraries.component';
import { LocationVideosComponent } from './components/dashboard-videos/location-videos/location-videos.component';
import { ConfirmResetPasswordComponent } from './components/on-boarding/confirm-reset-password/confirm-reset-password.component';
import { PasswordResetSuccessMessageComponent } from './components/on-boarding/password-reset-success-message/password-reset-success-message.component';
import { VerifyEmailMessageComponent } from './components/on-boarding/verify-email-message/verify-email-message.component';
import { AccountSettingComponent } from './components/myAccount/account-setting.component';
import { DashboardVideosComponent } from './components/dashboard-videos/dashboard-videos.component';
import { BusinessProfileComponent } from './components/business-profile/business-profile.component';
import{ CreateVideoWizardComponent} from './components/video-wizard-view/create-video-wizard.component';
import { SelectedVideoGaurd } from './services/gaurds/selected-video.guard';
import { CheckselectedvideoGuard } from './services/gaurds/checkselectedvideo.guard';
import { DashboardGuard } from './services/gaurds/dashboard.guard';
import { SubscriptionGuard } from './services/gaurds/subscription.guard';


import {FtCreateVideoWizardViewComponent} from './components/ft-create-video-wizard-view/ft-create-video-wizard-view.component';
import {FtContentComponent} from './components/ft-create-video-wizard-view/ft-content/ft-content.component';
import {FtLooknfeelComponent} from './components/ft-create-video-wizard-view/ft-looknfeel/ft-looknfeel.component';
import {FtOtherSettingComponent} from './components/ft-create-video-wizard-view/ft-other-setting/ft-other-setting.component';
import {FtOverviewComponent} from './components/ft-create-video-wizard-view/ft-overview/ft-overview.component';
import {FtPreviewComponent} from './components/ft-create-video-wizard-view/ft-preview/ft-preview.component';
import {FtLoginComponent} from './components/ft-create-video-wizard-view/ft-login/ft-login.component';
import { SplashscreenComponent } from './shared/splashscreen/splashscreen.component';
import { LocaliseExistingVideosComponent } from './components/localise-existing-videos/localise-existing-videos.component';
import { SingleVideoComponent } from './components/localise-existing-videos/single-video/single-video.component';
import { MultipleVideosComponent } from './components/localise-existing-videos/multiple-videos/multiple-videos.component';
import { ManageBrandsBusinessDetailsComponent } from './components/myAccount/manage-brand/manage-brands-business-details/manage-brands-business-details.component';
import { MediaLibraryAccountComponent } from './components/myAccount/media-libraries-page/account/media-library-account';


const routes: Routes = [
  { path: '',   component: SplashscreenComponent },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password-reset-success-message',
    component: PasswordResetSuccessMessageComponent
  },
  {
    path: 'verify-email-message',
    component: VerifyEmailMessageComponent
    
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'confirm-reset-password',
    component: ConfirmResetPasswordComponent
  },
  {
    path: 'createAccount',
    component: CreateAccountComponent
  },
  {
    path: 'create-account/select-plan',
    component: SelectPlanComponent
  },
  {
    path: 'create-account/complete-payment',
    component: CompletePaymentComponent
  },
  {
    path: 'create-account/paywall-failed',
    component: PaywallPaymentFailedComponent
  },
  {
    path: 'create-account/paywall-success',
    component: PaywallPaymentSuccessComponent
  },
  {
    path: 'authentication',
    component: AuthenticationComponent
  },
  {
    path: 'authentication-code',
    component: AuthenticationCodeComponent
  },
  {
    path: 'sorry',
    component: SorryComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'verify_reset-password',
    component: ConfirmResetPasswordComponent
  },
  {
    path: 'createAccountCompany',
    component: CreateAccountCompanyComponent
  },
 
  {
    path: 'subscription',
    component: SubscriptionComponent,
  },
  {
    path: 'business-profile' , canActivate: [AuthGuard],
    component: BusinessProfileComponent,
    children: [
      { path: '', redirectTo: 'business-profile', pathMatch: 'full' },
      { path: 'brand-identity', component: BrandsIdentityComponent },
      { path: 'user-media', component: MediaLibrariesComponent },
      { path: 'social-channels', component: SociallChannelsComponent },
    ]}, 
  {
    path: 'account-setting' , canActivate: [AuthGuard,SubscriptionGuard],
    component: AccountSettingComponent,
    children: [
      // { path: '', redirectTo: 'myAccount', pathMatch: 'full' },
      { path: '',  component: UsersComponent, },
      { path: 'myAccount', component: MyAccountComponent },
      { path: 'brand-identity', component: BrandsIdentityComponent },
      { path: 'locations', component: LocationsComponent },
      {
        path: 'subscription',
        component: SubscriptionComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },  
      {
        path: 'loginSecurity',
        component: LoginSecurityComponent,
      },
      {
        path: 'locations',
        component: LocationsComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'user-account/:id',
        component: UserAccountsComponent,
      },
      {
        path: 'user-account/:id/user-media-libraries',
        component: UserAccountMediaLibrariesComponent,
      },
      {
        path: 'user-published-videos',
        component: UserAccountPublishedVideosComponent,
      },
      {
        path: 'manage-brand',
        component: ManageBrandComponent,
      },
      {
        path: 'manage-brand/account/:id',
        component: ManageBrandAccountComponent,
      },
      {
        path: 'manage-brand/:id/media-libraries/:mediaLibId',
        component: ManageBrandMediaLibrariesComponent,
      },
      {
        path: 'manage-brand/:id/list-media-libraries',
        component: BrandMediaLibsComponent,
      },
      {
        path: 'manage-brand/:id/business-details',
        component: ManageBrandsBusinessDetailsComponent,
      },
      {
        path: 'manage-brand/published-videos',
        component: ManageBrandPublishedVideosComponent,
      },
      {
        path: 'manage-brand/schedule-videos',
        component: ManageBrandScheduleVideosComponent,
      },
      {
        path: 'manage-brand/media-libraries',
        component: ManageBrandMediaLibrariesComponent,
      },
      {
        path: 'user-schedule-video',
        component: UserAccountScheduleVideoComponent,
      },
      {
        path: 'user-media-libraries',
        component: UserAccountMediaLibrariesComponent,
      },
      {
        path: 'social-channels',
        component: SociallChannelsComponent,
      },
      {
        path: 'storage-details',
        component: StorageDetailsComponent,
      },
      {
        path: 'media-libraries',
        component: MediaLibrariesComponent,
      },
      {
        path: 'media-library-detail/:id',
        component: MediaLibrariesComponent,
      },
      {
        path: 'published-videos',
        component: PublisheddVideoComponent,
      },
      {
        path: 'media-libraries/:id/media',
        component: MediaPageLibraryyComponent,
      },
      {
        path: 'media-library/:id',
        component: MediaPageLibraryyComponent,
      },
      {
        path: 'media-platforms',
        component: MediaPlatformsComponent,
      },
      {
        path: 'media-libraries/:id/account',
        component: MediaLibraryAccountComponent,
      }
    ]
  },
  {
    path: 'billing',
    component: BillingComponent,
  },
  
  {
    path: 'support',
    component: SupportComponent,
  },

  
  {
    path: 'my-videos', canActivate: [AuthGuard],
    component: DashboardVideosComponent , children:[
      { path: '', redirectTo: 'publishedVideo', pathMatch: 'full' },
      { path: 'publishedVideo', component: PublishedVideosComponent },
      { path: 'locationVideos', component: LocationVideosComponent, canActivate: [ LocalesGuard]},
      { path: 'draftsVideo', component: DraftVideosComponent     },
      { path: 'scheduledVideos', component: ScheduledVideosComponent}

    ]
  },
  {
    path: 'wizard', canActivate: [AuthGuard,SubscriptionGuard],
    component: CreateVideoWizardComponent , children:[
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: OverviewComponent },
      { path: 'looknfeel', component: LooknFeelComponent, canActivate:[SelectedVideoGaurd]},
      { path: 'content', component: ContentComponent, canActivate:[SelectedVideoGaurd]     },
      { path: 'other-setting', component: OtherSettingComponent, canActivate:[SelectedVideoGaurd]},
      {
        path: 'timeline-view', canActivate: [SelectedVideoGaurd],
        component: VideoTimelineComponent
      },
      { path: 'preview', component: PreviewComponent, canActivate:[SelectedVideoGaurd]}
       

    ]
  },
  
  //first time user routing
  {
    path: 'ft', 
    component: FtCreateVideoWizardViewComponent , children:[
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'login', component: FtLoginComponent },
      { path: 'overview', component: FtOverviewComponent },
      { path: 'looknfeel', component: FtLooknfeelComponent},
      { path: 'content', component: FtContentComponent },
      { path: 'other-setting', component: FtOtherSettingComponent},
      { path: 'preview', component: FtPreviewComponent}
       

    ]
  },
  //localise-existing-videos routing
  {
    path: 'localise-existing-videos', 
    component: LocaliseExistingVideosComponent,canActivate:[SubscriptionGuard], children:[
      { path: '', redirectTo: 'single-video', pathMatch: 'full' },
      { path: 'single-video', component: SingleVideoComponent },
      { path: 'multiple-videos', component: MultipleVideosComponent },
       

    ]
  },
 
  { 
    path: 'social-channels', canActivate: [AuthGuard],
    component: SocialChannelsComponent
  },
  {
    path: 'edit-video/overview', canActivate: [AuthGuard],
    component: EditVideoOverviewComponent
  },
  {
    path: 'edit-video/content', canActivate: [AuthGuard],
    component: EditVideoContentComponent
  },
  {
    path: 'edit-video/other-setting', canActivate: [AuthGuard],
    component: EditVideoOtherSettingComponent
  },
  {
    path: 'edit-video/look-feel', canActivate: [AuthGuard],
    component: EditVideoLookFeelComponent
  },
  {
    path: 'edit-video/preview-locale', canActivate: [AuthGuard],
    component: PreviewLocaleComponent
  },
  {
    path: 'dashboard/myvideos', canActivate: [AuthGuard],
    component: DashboardMyvideoComponent
  },
  {
    path: 'dashboard/scheduled-videos', canActivate: [AuthGuard],
    component: DashboardSchduledVideosComponent
  },
  {
    path: 'dashboard/first-time-user', canActivate: [AuthGuard],
    component: FirstTimeDashboardComponent
  },
  // {
  //   path: 'business-profile/brand-identity', canActivate: [AuthGuard],
  //   component: BrandIdentityComponent
  // },
  {
    path: 'business-profile/brands',   
    component: BrandsComponent
  },
  {
    path: 'business-profile/brands/account',   
    component: BrandsAccountComponent
  },
  {
    path: 'business-profile/brands/business-details',  
    component: BrandsBusinessDetailsComponent
  },
  {
    path: 'business-profile/brands/published-video',  
    component: BrandsPublishedVideoComponent
  },
  {
    path: 'business-profile/brands/media-library', 
    component: BrandsMediaLibraryComponent
  },
  // {
  //   path: 'business-profile/user-media', canActivate: [AuthGuard],
  //   component: UserMediaComponent
  // },
  {
    path: 'business-profile/media-library',   
    component: MediaLibraryComponent
  },
  {
    path: 'business-profile/franchesees',  
    component: FranchiseesComponent
  },
  {
    path: 'business-profile/franchesees/account',   
    component: FrancheseesAccountComponent
  },{
    path: 'business-profile/franchesees/published-video',  
    component: FrancheseesPublishedVideoComponent
  },{
    path: 'business-profile/franchesees/media-library', 
    component: FrancheseesMediaLibraryComponent
  },
  {
    path: 'business-profile/customers-experience', 
    component: CustomersExperienceComponent
  },
  // {
  //   path: 'business-profile/social-channels', canActivate: [AuthGuard],
  //   component: SocialsChannelsComponent
  // },
  {
    path: 'business-profile/user',  
    component: UserComponent
  },
  {
    path: 'business-profile/business-plus/account',  
    component: UserAccountComponent
  },
  {
    path: 'business-profile/business-plus/published-video',  
    component: UserPublishedVideoComponent
  },
  {
    path: 'business-profile/business-plus/manage-libraries',  
    component: UserManageLibrariesComponent
  },
  {
    path: 'business-profile/enterprise-media',  
    component: EnterpriseMediaComponent
  },
  {
    path: 'business-profile/enterprise-media-library', 
    component: EnterpriseMediaLibraryComponent
  },
  {
    path: 'reports/reach',  
    component: ReportsReachComponent
  },
  {
    path: 'reports/engagement',  
    component: ReportsEngagementComponent
  },
  {
    path: 'reports/audience', 
    component: ReportsAudienceComponent
  },
  {
    path: 'dashboard/socialmedia', canActivate: [AuthGuard],
    component: DashboardSocialmediaComponent
  },
  {
    path: 'dashboard/download', canActivate: [AuthGuard],
    component: DashboardDownloadComponent
  },
  {
    path: 'dashboard/overview', canActivate: [AuthGuard],
    component: DashboardOverviewComponent
  },
  {
    path: 'published-video/timeline-view-processed', canActivate: [AuthGuard],
    component: TimelineViewProcessComponent
  },
  {
    path: 'published-video/timeline-view', canActivate: [AuthGuard],
    component: TimelineViewComponent
  },
 
  {
    path: 'manage-comments', canActivate: [AuthGuard],
    component: ManageCommentsComponent
  },
  {
    path: 'published-video/timeline-Viewfunfect', canActivate: [AuthGuard],
    component: PublishVideoTimelineViewFunFectComponent
  },
  {
    path: 'published-video/comment-section',
    component: CommentSectionComponent
  },
  {
    path: 'public-videos',
    component: PublicVideosComponent
  },
  {
    path: 'public-page',
    component: PublicPageComponent
  },
  {
    path: 'ftue-login',
    component: FtueLoginComponent
  },
  {
    path: 'ftue-overview',
    component: FtueOverviewComponent
  },
  {
    path: 'ftue-looknfeel',
    component: FtueLooknfeelComponent
  },
  {
    path: 'ftue-content',
    component: FtueContentComponent
  },
  {
    path: 'ftue-other-settings',
    component: FtueOtherSettingComponent
  },
  {
    path: 'ftue-preview',
    component: FtuePreviewComponent
  },
  {
    path: 'verify_reset_password',
    component: ConfirmResetPasswordComponent
  },
  {
    path: 'verify_email',
    component: VerifyEmailComponent
  },
  {
    path: 'timeout-error',
    component: TimeoutErrorComponent
  },
  {
    path: 'preloader',
    component: SplashscreenComponent
  },
  { path: '**', canActivate: [AuthGuard] , component:LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
