import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SuccessMessageModalService {
  show: boolean = false;
  showSuccessModal : boolean = false;
  message: string;
  title: string;
  showHead = true;
  buttonTitle: string = "Ok";
  showBtn = true;
  showLoader = true;
  constructor() {}

  display(
    error: any,
    errorTitle: string,
    showDialogue: boolean,
    autoClose?: boolean,
    showBtnForm = true
  ) {
    this.show = showDialogue;
    this.message = error;
    this.title = errorTitle;
    this.showBtn = showBtnForm;

    if (autoClose) {
      setTimeout(() => {
        this.show = false;
      }, 2000);
    }
  }

  close() {
    this.show = false;
  }
   //Without Header....
   displayMessage(
    error: any,
    errorTitle = "",
    showDialogue = true,
    showHeader = false,
    autoClose = false,
    showLoader = true
  ) {
    // console.log(" info pop called..");
    this.show = showDialogue;
    this.showSuccessModal = true;
    this.message = error;
    this.show
    this.title = errorTitle;
    this.showHead = showHeader;

    if (autoClose) {
      setTimeout(() => {
        this.show = false;
      }, 3000);
    }
  }
}
