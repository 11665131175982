import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgWaveformModule } from 'ng-waveform';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../app-routing.module';
import { NgZorroAntdModule } from './../ng-zorro-antd.module';
import { AlertModule } from './../_alert/alert.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TimelineViewSidepanelsComponent } from './timeline-view-sidepanels/timeline-view-sidepanels.component'; 
import { MediaLibrarySharedComponent } from './media-library-shared/media-library-shared.component';
import { LoaderComponent } from './loader/loader.component';
import { OnboardingAsideSectionComponent } from './onboarding-aside-section/onboarding-aside-section.component';
import { EmptyReportsComponent } from './empty-reports/empty-reports.component';
import { CreatedVideosComponent } from './created-videos/created-videos.component';
import { ScheduledPopupComponent } from './scheduled-popup/scheduled-popup.component';
import { BusinessProfileSidepanelsComponent } from './business-profile-sidepanels/business-profile-sidepanels.component';
import { SchuledVideosComponent } from './schuled-videos/schuled-videos.component';
import { MediaLibraryPopupComponent } from './media-library-popup/media-library-popup.component';
import { AudioSidepanelComponent } from './audio-sidepanel/audio-sidepanel.component';
import { BlockSidepanelComponent } from './block-sidepanel/block-sidepanel.component';
import { VoiceoverSidepanelComponent } from './voiceover-sidepanel/voiceover-sidepanel.component';
import { FtueCreateVideoBreadcrumbComponent } from './ftue-create-video-breadcrumb/ftue-create-video-breadcrumb.component';
import { TextSidepanelComponent } from './text-sidepanel/text-sidepanel.component';
import { LocationsCreatedVideosComponent } from './locations-created-videos/locations-created-videos.component';
import { TimeoutErrorComponent } from './timeout-error/timeout-error.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { VideoSidepanelComponent } from './video-sidepanel/video-sidepanel.component';

import { AccountSidebarComponent } from './account-sidebar/account-sidebar.component';
import { BusinessSidebarComponent } from './business-sidebar/business-sidebar.component';
import { MyvideosSidebarComponent } from './myvideos-sidebar/myvideos-sidebar.component';
import { CreateVideoHeaderComponent } from './create-video-header/create-video-header.component';
import { CreateVideoBreadcrumbComponent } from './create-video-breadcrumb/create-video-breadcrumb.component';
import { EditVideoHeaderComponent } from './edit-video-header/edit-video-header.component';
import { EditVideoBreadcrumbComponent } from './edit-video-breadcrumb/edit-video-breadcrumb.component';
import { EditVideoSidepanelsComponent } from './edit-video-sidepanels/edit-video-sidepanels.component';
import { DashboardBreadcrumbComponent } from './dashboard-breadcrumb/dashboard-breadcrumb.component';
import { DashboardSidepanelsComponent } from './dashboard-sidepanels/dashboard-sidepanels.component';
import { ReportsBreadcrumbComponent } from './reports-breadcrumb/reports-breadcrumb.component';
import { ReportsSidebarVideosComponent } from './reports-sidebar-videos/reports-sidebar-videos.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { PaginationListComponent } from './pagination-list/pagination-list.component';
import { SearchInUserList } from './filters/search-in-list.filter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SecondsToTime } from '../services/pipes/seconds-to-time.pipe';

@NgModule({
  declarations: [
    BusinessProfileSidepanelsComponent,
    HeaderComponent,
    TimelineViewSidepanelsComponent,
    MediaLibrarySharedComponent,
    LoaderComponent,
    OnboardingAsideSectionComponent,
    EmptyReportsComponent,
    CreatedVideosComponent,
    ScheduledPopupComponent,    
    MediaLibraryPopupComponent,
    AudioSidepanelComponent,
    BlockSidepanelComponent,
    VoiceoverSidepanelComponent,
    SchuledVideosComponent,
    FtueCreateVideoBreadcrumbComponent,
    TextSidepanelComponent,
    TimeoutErrorComponent,
    LocationsCreatedVideosComponent,
    VideoSidepanelComponent,
    EditVideoHeaderComponent,
    EditVideoBreadcrumbComponent,
    EditVideoSidepanelsComponent,
    AccountSidebarComponent,    
    BusinessSidebarComponent,
    MyvideosSidebarComponent,
    CreateVideoHeaderComponent,
    CreateVideoBreadcrumbComponent,
    DashboardBreadcrumbComponent,
    DashboardSidepanelsComponent,
    ReportsBreadcrumbComponent,
    ReportsSidebarVideosComponent,
    DashboardHeaderComponent,
    VideoSidepanelComponent,
    PaginationListComponent,
    SearchInUserList,
    SecondsToTime,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AlertModule,
    NgZorroAntdModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgWaveformModule,
    SlickCarouselModule,
    MatAutocompleteModule,
    MatIconModule,
    NzDrawerModule,      
    MatFormFieldModule, 
    MatPaginatorModule,
    MatInputModule],
  exports: [
    BusinessProfileSidepanelsComponent,
    HeaderComponent,
    LoaderComponent,
    OnboardingAsideSectionComponent,           
    TimelineViewSidepanelsComponent,
    MediaLibrarySharedComponent,
    LoaderComponent,
    OnboardingAsideSectionComponent,
    EmptyReportsComponent,
    CreatedVideosComponent,
    ScheduledPopupComponent,    
    MediaLibraryPopupComponent,
    AudioSidepanelComponent,
    BlockSidepanelComponent,
    VoiceoverSidepanelComponent,
    SchuledVideosComponent,
    FtueCreateVideoBreadcrumbComponent,
    TextSidepanelComponent,
    TimeoutErrorComponent,
    LocationsCreatedVideosComponent,
    EditVideoHeaderComponent,
    EditVideoBreadcrumbComponent,
    EditVideoSidepanelsComponent,
    AccountSidebarComponent,    
    BusinessSidebarComponent,
    MyvideosSidebarComponent,
    CreateVideoHeaderComponent,
    CreateVideoBreadcrumbComponent,
    DashboardBreadcrumbComponent,
    DashboardSidepanelsComponent,
    ReportsBreadcrumbComponent,
    ReportsSidebarVideosComponent,
    DashboardHeaderComponent,
    VideoSidepanelComponent  ,
    SecondsToTime,  
  ]
})
export class SharedModuleModule { }
