import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { cIntercomService } from './intercom.service';
import { cSessionService } from './session.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {

    constructor(private router: Router, private oSessionService: cSessionService, private oIntercomService : cIntercomService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
    {
        
        const accessToken = this.oSessionService.SessionService_GetSessionId();
      
      console.log(accessToken);
        if (accessToken) 
        {
           // this.oSessionService.SessionService_CheckSessionExpiry();
            return true;
        }
        else 
        {
            localStorage.clear();
            //Remove intercom code
            this.oIntercomService.cIntercomService_RemoveLoggedInUserIntercomCredentials();
            return false;
        }
    }
}
