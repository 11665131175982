    <!-- Create video Header -->
    <header class="header header-1 drop-shadow" *ngIf="bCreateVideoMode">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="d-flex w-100">
                    <div class="col d-flex align-items-center">
                        <div class="back_cta"> <a (click)="FTUEContentComponent_NavigateToLogin()">
                                <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                            </a></div>
                        <h3 class="text-gray-1 mb-0">Create a Video</h3>
                    </div>
                    <div class="col text-end">
                        <a class="text-gray-6 text-sm me-4" routerLink="ftue-looknfeel">Previous</a>
                        <a  class="btn btn-md btn-secondary btn-round-6" (click)="CreateVideoContentComponent_SendContentFormRequest()">Next</a>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <!-- Edit video Header -->
    <!-- <app-edit-video-header *ngIf="!bCreateVideoMode" (bEmittedSendUpdateVideoRequest)="CreateVideoContentComponent_UpdateVideo($event)"></app-edit-video-header> -->

<section class="accountSec main-body bg-gray-7">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <app-ftue-create-video-breadcrumb></app-ftue-create-video-breadcrumb>
            </div>
            <!-- <div class="col-md-6" *ngIf="!bCreateVideoMode">
                <div class="justify-content-end d-flex">
                    <div class="pt-0 form-check form-switch custom-switch d-flex align-items-center toggle_switch">
                        <input checked class="form-check-input mx-2 order-2 form-check-input-active-both" type="checkbox" id="MyMediaLibrary" (change)="ContentComponent_ToggleView($event)" [disabled]="sDisableEditModeToggle=='true'">
                        <label class="text-md order-1" for="MyMediaLibrary">Timeline</label>
                        <label class="text-md order-3 text-gray-6" for="MyMediaLibrary">Wizard</label>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow pb-0" id="pills-tabContent">
            <div class="tab-pane fade show active">
                <div class="content_area pb-4 border-bottom">
                    <ul class="process_tabs nav">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" [ngClass]="oHighlightedTabs?.content?'new':''" (click)="ContentComponent_CheckVideoContentTab()" id="Video-Content-tab" data-bs-toggle="pill"
                                data-bs-target="#Video-Content" type="button" role="tab" aria-controls="Video-Content"
                                aria-selected="false"><svg class="white" width="24" height="18" viewBox="0 0 24 18"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="18" rx="6" fill="#FF8369" />
                                    <path
                                        d="M8.66699 12.0838C8.66699 12.6029 9.04444 12.8154 9.50594 12.5565L14.9875 9.47257C15.449 9.21305 15.449 8.78797 14.9875 8.52845L9.50594 5.44425C9.04441 5.18473 8.66699 5.39699 8.66699 5.91689V12.0838Z"
                                        fill="white" />
                                </svg>
                                Video Content</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" [ngClass]="oHighlightedTabs?.overlays?'new':''" (click)="ContentComponent_CheckTextOverlaysTab()" id="Text-Overlays-tab" data-bs-toggle="pill"
                                data-bs-target="#Text-Overlays" type="button" role="tab" aria-controls="Text-Overlays"
                                aria-selected="false"><svg width="21" height="15" viewBox="0 0 21 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.868 7.14H15.22L14.548 9H13.396L16.42 0.684H17.68L20.692 9H19.54L18.868 7.14ZM18.556 6.252L17.044 2.028L15.532 6.252H18.556Z"
                                        fill="#483D8F"></path>
                                    <path
                                        d="M5.4 1.28H9.04L14.4 15H11.24L10.26 12.38H4.02L3.04 15H0.04L5.4 1.28ZM9.34 9.96L8.04 6.48C7.88 6.05333 7.59333 5.15333 7.18 3.78H7.08C6.66667 5.15333 6.38 6.05333 6.22 6.48L4.92 9.96H9.34Z"
                                        fill="#FF8369"></path>
                                </svg>Text Overlays</button>
                        </li>
                        <li class="nav-item" role="presentation" (click)="ContentComponent_CheckVoiceOverTextTab()">
                            <button class="nav-link" [ngClass]="oHighlightedTabs?.voiceover?'new':''" id="Voiceover-text-tab" data-bs-toggle="pill"
                                data-bs-target="#Voiceover-text" type="button" role="tab" aria-controls="Voiceover-text"
                                aria-selected="true"><svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.825413 10.1808C0.374187 10.1808 0 9.80661 0 9.35538V4.63401C0 4.18278 0.374187 3.80859 0.825413 3.80859C1.27664 3.80859 1.65083 4.18278 1.65083 4.63401V9.35538C1.65083 9.81761 1.27664 10.1808 0.825413 10.1808Z"
                                        fill="#FF8369"></path>
                                    <path
                                        d="M4.12717 11.7544C3.67595 11.7544 3.30176 11.3802 3.30176 10.929V3.07102C3.30176 2.61979 3.67595 2.24561 4.12717 2.24561C4.5784 2.24561 4.95258 2.61979 4.95258 3.07102V10.929C4.95258 11.3912 4.5784 11.7544 4.12717 11.7544Z"
                                        fill="#FF8369"></path>
                                    <path
                                        d="M7.42893 13.3282C6.9777 13.3282 6.60352 12.954 6.60352 12.5028V1.49729C6.60352 1.04606 6.9777 0.671875 7.42893 0.671875C7.88015 0.671875 8.25434 1.04606 8.25434 1.49729V12.5028C8.25434 12.954 7.88015 13.3282 7.42893 13.3282Z"
                                        fill="#FF8369"></path>
                                    <path
                                        d="M10.7307 11.7544C10.2795 11.7544 9.90527 11.3802 9.90527 10.929V3.07102C9.90527 2.61979 10.2795 2.24561 10.7307 2.24561C11.1819 2.24561 11.5561 2.61979 11.5561 3.07102V10.929C11.5561 11.3912 11.1819 11.7544 10.7307 11.7544Z"
                                        fill="#FF8369"></path>
                                    <path
                                        d="M14.0324 10.1808C13.5812 10.1808 13.207 9.80661 13.207 9.35538V4.63401C13.207 4.18278 13.5812 3.80859 14.0324 3.80859C14.4837 3.80859 14.8579 4.18278 14.8579 4.63401V9.35538C14.8579 9.81761 14.4837 10.1808 14.0324 10.1808Z"
                                        fill="#FF8369"></path>
                                </svg>
                                Voiceover text</button>
                        </li>
                    </ul>
                </div>

                <div class="tab-content">
                    <!-- ------------------------------------------- Video Content ------------------------------------------ -->
                    <!-- ------------------------------------------- Video Content ------------------------------------------ -->
                    <!-- ------------------------------------------- Video Content ------------------------------------------ -->
                    <!-- ------------------------------------------- Video Content ------------------------------------------ -->
                    <!-- ------------------------------------------- Video Content ------------------------------------------ -->

                    <div class="fade" [ngClass]="sSelectedTab==='video content'?'show fade':'d-none'" role="tabpanel" aria-labelledby="Video-Content-tab">
                        <div class="content_area">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="d-flex align-items-start check_toggle_content">
                                        <div class="check_toggle">
                                            <div class="form-switch toggle_switch">
                                                <input class="form-check-input" type="checkbox" [checked]="bLetProntoChoose" id="flexSwitchCheckChecked" (change)="ContentComponent_ToggleLetProntoChoose($event)">
                                            </div>
                                        </div>
                                        <div class="check_content w-100">
                                            <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Let Prontopia  Choose</p>
                                            <p class="text-xs text-gray-3 pb-1">We'll select clips for your video based on the category,
                                                tags, and other information you've provided.</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-12 col-lg-4">
                                    <div class="d-flex align-items-start check_toggle_content">
                                        <div class="check_toggle">
                                            <div class="form-switch toggle_switch">
                                                <input class="form-check-input" type="checkbox" id="MyMediaLibrary"  disabled>
                                            </div>
                                        </div>
                                        <div class="check_content active check_content0 w-100">
                                            <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">My Media Library</p>
                                            <p class="text-xs text-gray-3 pb-1">We'll piece together content you've uploaded to
                                                create your video.</p>
                                            <div class="mb-4" *ngIf="bDisplayMyMediaSection">
                                                <a class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad mt-3">
                                                    <svg
                                                        width="18" height="17" viewBox="0 0 18 17" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6.77845 0.976562C4.90113 0.976562 3.39191 1.34693 2.37083 2.36671C1.34951 3.38672 0.978516 4.89464 0.978516 6.77006V11.0931C0.978516 12.9685 1.34951 14.4764 2.37083 15.4964C3.39191 16.5162 4.90113 16.8866 6.77845 16.8866H11.107C12.9843 16.8866 14.4935 16.5162 15.5146 15.4964C16.5359 14.4764 16.9069 12.9685 16.9069 11.0931V6.77006C16.9069 4.89464 16.5359 3.38672 15.5146 2.36671C14.4935 1.34693 12.9843 0.976562 11.107 0.976562H6.77845ZM2.47852 6.77006C2.47852 5.04299 2.82894 4.02915 3.43081 3.42804C4.03292 2.8267 5.04867 2.47656 6.77845 2.47656H11.107C12.8367 2.47656 13.8525 2.8267 14.4546 3.42804C15.0565 4.02915 15.4069 5.04299 15.4069 6.77006V11.0931C15.4069 12.8201 15.0565 13.834 14.4546 14.4351C13.8525 15.0364 12.8367 15.3866 11.107 15.3866H6.77845C5.04867 15.3866 4.03292 15.0364 3.43081 14.4351C2.82894 13.834 2.47852 12.8201 2.47852 11.0931V6.77006ZM7.60063 7.86563C7.60063 7.33803 7.78109 7.16526 7.85973 7.12013C7.94001 7.07405 8.18317 7.00622 8.64049 7.26903L8.64102 7.26934L9.56328 7.80184L10.4867 8.33501L10.4878 8.33568C10.9436 8.59777 11.0045 8.84089 11.0045 8.93197C11.0045 9.02305 10.9436 9.26616 10.4878 9.52826L10.4867 9.52892L9.56328 10.0621L8.64102 10.5946L8.64051 10.5949C8.18503 10.8566 7.94208 10.7886 7.86085 10.7418C7.78025 10.6953 7.60063 10.5208 7.60063 9.99831V8.93197V7.86563ZM9.38875 5.96899C8.65607 5.54768 7.81726 5.41498 7.11302 5.81919C6.40712 6.22437 6.10063 7.01707 6.10063 7.86563V8.93197V9.99831C6.10063 10.8447 6.40796 11.6357 7.1119 12.0414C7.81518 12.4467 8.65421 12.3173 9.38875 11.8949L9.3899 11.8943L10.3133 11.3611L11.2356 10.8286L11.2362 10.8283C11.9704 10.4059 12.5045 9.7449 12.5045 8.93197C12.5045 8.11901 11.9704 7.45802 11.2361 7.03563L11.2356 7.03533L10.3133 6.50283L9.3899 5.96965L9.38875 5.96899Z"
                                                            fill="#4F4F4F" />
                                                    </svg>
                                                    Select a file
                                                </a>
                                            </div>
                                            <div class="media_list" *ngIf="bDisplayMyMediaSection">
                                                <ul>
                                                    <li class="mb-3" *ngFor="let visual of lUserSelectedVisuals">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img [src]="visual.thumb" alt="Media Library"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title">{{visual.name}}</p>
                                                                    <p class="text-xs sub_title">{{ContentComponent_ReturnFileSize(visual.size)}}</p>
                                                                </div>
                                                                <div class="cta d-flex align-items-center" (click)="ContentComponent_RemoveSelectedVisual(visual)">
                                                                    <a href="javascript:void(0)" class=" me-3">
                                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M7.00056 0.998805C6.44823 0.998805 6.00048 1.44599 6.00048 1.99761H10.0008C10.0008 1.44599 9.55304 0.998805 9.00071 0.998805H7.00056ZM11.0009 1.99761C11.0009 0.894361 10.1054 0 9.00071 0H7.00056C5.8959 0 5.0004 0.894361 5.0004 1.99761H2.00016H1.00008H0.50004C0.223875 1.99761 0 2.2212 0 2.49701C0 2.77283 0.223875 2.99642 0.50004 2.99642H1.00008V10.9869C1.00008 13.745 3.23883 15.9809 6.00048 15.9809H10.0008C12.7624 15.9809 15.0012 13.745 15.0012 10.9869V2.99642H15.5012C15.7774 2.99642 16.0013 2.77283 16.0013 2.49701C16.0013 2.2212 15.7774 1.99761 15.5012 1.99761H15.0012H14.0011H11.0009ZM2.00016 10.9869V2.99642H6.00048H10.0008H14.0011V10.9869C14.0011 13.1934 12.2101 14.9821 10.0008 14.9821H6.00048C3.79116 14.9821 2.00016 13.1934 2.00016 10.9869ZM6.00048 11.4863V5.49343C6.00048 5.21762 5.7766 4.99403 5.50044 4.99403C5.22427 4.99403 5.0004 5.21762 5.0004 5.49343V11.4863C5.0004 11.7621 5.22427 11.9857 5.50044 11.9857C5.7766 11.9857 6.00048 11.7621 6.00048 11.4863ZM11.0009 5.49343V11.4863C11.0009 11.7621 10.777 11.9857 10.5008 11.9857C10.2247 11.9857 10.0008 11.7621 10.0008 11.4863V5.49343C10.0008 5.21762 10.2247 4.99403 10.5008 4.99403C10.777 4.99403 11.0009 5.21762 11.0009 5.49343Z"
                                                                                fill="#FF8369" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li> -->
                                                    <!-- <li class="mb-3">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img src="../assets/images/video-thum.png" alt="Media Library"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title">ShootVideo_38237.mov</p>
                                                                    <p class="text-xs sub_title"> 34MB</p>
                                                                </div>
                                                                <div class="cta d-flex align-items-center">
                                                                    <a href="javascript:void(0)" class=" me-3">
                                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M7.00056 0.998805C6.44823 0.998805 6.00048 1.44599 6.00048 1.99761H10.0008C10.0008 1.44599 9.55304 0.998805 9.00071 0.998805H7.00056ZM11.0009 1.99761C11.0009 0.894361 10.1054 0 9.00071 0H7.00056C5.8959 0 5.0004 0.894361 5.0004 1.99761H2.00016H1.00008H0.50004C0.223875 1.99761 0 2.2212 0 2.49701C0 2.77283 0.223875 2.99642 0.50004 2.99642H1.00008V10.9869C1.00008 13.745 3.23883 15.9809 6.00048 15.9809H10.0008C12.7624 15.9809 15.0012 13.745 15.0012 10.9869V2.99642H15.5012C15.7774 2.99642 16.0013 2.77283 16.0013 2.49701C16.0013 2.2212 15.7774 1.99761 15.5012 1.99761H15.0012H14.0011H11.0009ZM2.00016 10.9869V2.99642H6.00048H10.0008H14.0011V10.9869C14.0011 13.1934 12.2101 14.9821 10.0008 14.9821H6.00048C3.79116 14.9821 2.00016 13.1934 2.00016 10.9869ZM6.00048 11.4863V5.49343C6.00048 5.21762 5.7766 4.99403 5.50044 4.99403C5.22427 4.99403 5.0004 5.21762 5.0004 5.49343V11.4863C5.0004 11.7621 5.22427 11.9857 5.50044 11.9857C5.7766 11.9857 6.00048 11.7621 6.00048 11.4863ZM11.0009 5.49343V11.4863C11.0009 11.7621 10.777 11.9857 10.5008 11.9857C10.2247 11.9857 10.0008 11.7621 10.0008 11.4863V5.49343C10.0008 5.21762 10.2247 4.99403 10.5008 4.99403C10.777 4.99403 11.0009 5.21762 11.0009 5.49343Z"
                                                                                fill="#FF8369" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                    <li class="mb-3">
                                                        <figure class="media_figure">
                                                            <span class="media"><img
                                                                    src="../assets/images/video-thum.png"
                                                                    alt="Media Library"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title text-gray-12">Image23_ko.jpg
                                                                    </p>
                                                                    <p class="text-xs sub_title text-primary-3">File Error</p>
                                                                </div>
                                                                <div class="cta d-flex align-items-center">
                                                                    <a href="javascript:void(0)" class=" me-3 text-xs text-primary-3">Retry
                                                                    </a>

                                                                </div>

                                                            </figcaption>
                                                        </figure>
                                                    </li> -->
                                                <!-- </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                    <!-- upload success section -->
                                    <div class="d-flex align-items-start check_toggle_content">
                                        <div class="check_toggle">
                                            <div class="form-switch toggle_switch">
                                                <input class="form-check-input" type="checkbox" id="UploadMyOwn" [checked]="bDisplayUploadSection"  (change)="ContentComponent_ToggleFileUploadSection($event)">
                                            </div>
                                        </div>
                                        <div class="check_content active check_content1 w-100">
                                            <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Upload My Own...</p>
                                            <p class="text-xs text-gray-3 pb-1">Upload your own clips and images for Prontopia  to use
                                                in your video. These files will also be added to your
                                                Media Library.</p>
                                            <div class="mb-4" *ngIf="bDisplayUploadSection">
                                                <button (click)="ContentComponent_ToggleFileUploadType(false)" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow"
                                                data-bs-toggle="modal" data-bs-target="#uploadModal">
                                                <svg class="me-1" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                                                            fill="#4F4F4F"></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                            <rect width="16.0013" height="15.9809" fill="white"></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                Upload a file
                                            </button>
                                            </div>

                                            <div class="media_list" *ngIf="bDisplayUploadSection">
                                                <ul>
                                                    <li class="mb-3" *ngFor="let file of lUploadedFiles">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img [src]="file.thumb" alt="Media Library"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title">{{file.filename | slice:0:30}}</p>
                                                                    <p class="text-xs sub_title">{{ContentComponent_ReturnFileSize(file.size)}}</p>
                                                                </div>
                                                                <div class="cta d-flex align-items-center">
                                                                    <a  class=" me-3" (click)="ContentComponent_RemoveFileFromList(file.fpath)">
                                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M7.00056 0.998805C6.44823 0.998805 6.00048 1.44599 6.00048 1.99761H10.0008C10.0008 1.44599 9.55304 0.998805 9.00071 0.998805H7.00056ZM11.0009 1.99761C11.0009 0.894361 10.1054 0 9.00071 0H7.00056C5.8959 0 5.0004 0.894361 5.0004 1.99761H2.00016H1.00008H0.50004C0.223875 1.99761 0 2.2212 0 2.49701C0 2.77283 0.223875 2.99642 0.50004 2.99642H1.00008V10.9869C1.00008 13.745 3.23883 15.9809 6.00048 15.9809H10.0008C12.7624 15.9809 15.0012 13.745 15.0012 10.9869V2.99642H15.5012C15.7774 2.99642 16.0013 2.77283 16.0013 2.49701C16.0013 2.2212 15.7774 1.99761 15.5012 1.99761H15.0012H14.0011H11.0009ZM2.00016 10.9869V2.99642H6.00048H10.0008H14.0011V10.9869C14.0011 13.1934 12.2101 14.9821 10.0008 14.9821H6.00048C3.79116 14.9821 2.00016 13.1934 2.00016 10.9869ZM6.00048 11.4863V5.49343C6.00048 5.21762 5.7766 4.99403 5.50044 4.99403C5.22427 4.99403 5.0004 5.21762 5.0004 5.49343V11.4863C5.0004 11.7621 5.22427 11.9857 5.50044 11.9857C5.7766 11.9857 6.00048 11.7621 6.00048 11.4863ZM11.0009 5.49343V11.4863C11.0009 11.7621 10.777 11.9857 10.5008 11.9857C10.2247 11.9857 10.0008 11.7621 10.0008 11.4863V5.49343C10.0008 5.21762 10.2247 4.99403 10.5008 4.99403C10.777 4.99403 11.0009 5.21762 11.0009 5.49343Z"
                                                                                fill="#FF8369" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- upload failed section -->
                                    <div class="d-flex align-items-start check_toggle_content" *ngIf="lFailedToUpload.length>0">
                                        <div class="check_toggle">
                                            <div class="form-switch toggle_switch">
                                                <input class="form-check-input" type="checkbox" id="UploadMyOwn" (change)="ContentComponent_ToggleFailedToUploadSection($event)">
                                            </div>
                                        </div>
                                        <div class="check_content active check_content1 w-100">
                                            <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Failed to upload Visuals</p>
                                            <div class="media_list" *ngIf="bDisplayFailedToUploadSection">
                                                <ul>
                                                    <li class="mb-3" *ngFor="let file of lFailedToUpload">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img [src]="file.thumb" alt="Media Library"></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title text-gray-12">{{file.name | slice:0:30}}</p>
                                                                    <p class="text-xs sub_title text-primary-3">File Error</p>
                                                                </div>
                                                                <div class="cta d-flex align-items-center">
                                                                    <a  class=" me-3" (click)="ContentComponent_RemoveFailedFileFileFromList(file.name)">
                                                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M7.00056 0.998805C6.44823 0.998805 6.00048 1.44599 6.00048 1.99761H10.0008C10.0008 1.44599 9.55304 0.998805 9.00071 0.998805H7.00056ZM11.0009 1.99761C11.0009 0.894361 10.1054 0 9.00071 0H7.00056C5.8959 0 5.0004 0.894361 5.0004 1.99761H2.00016H1.00008H0.50004C0.223875 1.99761 0 2.2212 0 2.49701C0 2.77283 0.223875 2.99642 0.50004 2.99642H1.00008V10.9869C1.00008 13.745 3.23883 15.9809 6.00048 15.9809H10.0008C12.7624 15.9809 15.0012 13.745 15.0012 10.9869V2.99642H15.5012C15.7774 2.99642 16.0013 2.77283 16.0013 2.49701C16.0013 2.2212 15.7774 1.99761 15.5012 1.99761H15.0012H14.0011H11.0009ZM2.00016 10.9869V2.99642H6.00048H10.0008H14.0011V10.9869C14.0011 13.1934 12.2101 14.9821 10.0008 14.9821H6.00048C3.79116 14.9821 2.00016 13.1934 2.00016 10.9869ZM6.00048 11.4863V5.49343C6.00048 5.21762 5.7766 4.99403 5.50044 4.99403C5.22427 4.99403 5.0004 5.21762 5.0004 5.49343V11.4863C5.0004 11.7621 5.22427 11.9857 5.50044 11.9857C5.7766 11.9857 6.00048 11.7621 6.00048 11.4863ZM11.0009 5.49343V11.4863C11.0009 11.7621 10.777 11.9857 10.5008 11.9857C10.2247 11.9857 10.0008 11.7621 10.0008 11.4863V5.49343C10.0008 5.21762 10.2247 4.99403 10.5008 4.99403C10.777 4.99403 11.0009 5.21762 11.0009 5.49343Z"
                                                                                fill="#FF8369" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -------------------------------------------- Text Overlay ------------------------------------------ -->
                    <!-- -------------------------------------------- Text Overlay ------------------------------------------ -->
                    <!-- -------------------------------------------- Text Overlay ------------------------------------------ -->
                    <!-- -------------------------------------------- Text Overlay ------------------------------------------ -->
                    <!-- -------------------------------------------- Text Overlay ------------------------------------------ -->
                    <div class="fade" [ngClass]="sSelectedTab==='text overlays'?'show':'d-none'" role="tabpanel" aria-labelledby="Text-Overlays-tab">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 col-md-1 col-lg-1 bg-gray-9">
                                    <ul id="main_nav" class="nav nav-dots sticky">
                                        <li *ngFor="let duration of lBlockConfigurations">
                                            <a class="nav-link scrollto" [ngClass]="sSelectedBlockID==duration.sBlockID?'active':''" [ngClass]="duration.lTextAreaDetails.length == '0'? 'disabled': ''"
                                             [attr.data-bs-target]="'#'+duration.sBlockID" (click)="ContentComponent_HighlightSelectedBlock(duration.sBlockID)"> {{duration.nDuration}}s </a>
                                            </li>
                                    </ul>
                                </div>
                                <div class="col-sm-1 col-md-1 col-lg-11">
                                    <section class="mt-4 video-timeline-outer">
                                        <ng-container *ngFor="let block of lBlockConfigurations;index as nOuterindex">
                                            <article  *ngIf="block.lTextAreaDetails.length > 0" class="video-timeline" [ngClass]="sSelectedBlockID==block.sBlockID?'active':''" [id]="block.sBlockID">
                                                <div class="check_content video-text row">
                                                    <ng-container *ngFor="let text of block.lTextAreaDetails;index as nInnerindex;">
                                                        <div class="col-sm-12 col-md-12 col-lg-12 custom-input" *ngIf="text.placeholder=='Add Title'">
                                                            <div class="formItm" style="width: 49%;">
                                                                <input id="titleName" type="text" [value]="text.value" [placeholder]="text.placeholder" class="" maxlength="40" #blockTitle (change)="ContentComponent_PopulateTextList($event,nOuterindex,nInnerindex)">
                                                                <span class="text-limit bg-white ps-1">{{blockTitle.value.length}}/40 words</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-6 custom-input" *ngIf="text.placeholder!=='Add Title'">
                                                            <div class="formItm">
                                                                <input id="titleName" type="text" [value]="text.value" [placeholder]="text.placeholder" class="" maxlength="40" #blockTitle (change)="ContentComponent_PopulateTextList($event,nOuterindex,nInnerindex)">
                                                                <span class="text-limit bg-white ps-1">{{blockTitle.value.length}}/40 words</span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </article>
                                        </ng-container>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <!-- ------------------------------------------- Voiceover text ------------------------------------------ -->
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------- -->

                    <div class="fade" [ngClass]="sSelectedTab==='voiceover text'?'show':'d-none'" role="tabpanel" aria-labelledby="Voiceover-text-tab">

                        <div class="content_area">
                            <div class="row voicover-block">
                                <div class="col-sm-12 col-md-12 col-lg-5">
                                  <form [formGroup]="fContentForm">

                                    <!-- <div class="mb-4 pb-2 voicover-btn">
                                        <button (click)="ContentComponent_ToggleFileUploadType(true)" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad"
                                        data-bs-toggle="modal" data-bs-target="#uploadModal">
                                        <svg class="me-1" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                                                    fill="#4F4F4F">
                                                </path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Upload a File
                                    </button>
                                        <span class="text-sm text-gray-3 d-inline-block mx-3">or</span><a class="text-gray-2 text-md" href="javascript:void(0)" (click)="ContentComponent_TextToSpeech()">Enter text for Voiceover</a>
                                    </div> -->
                                    <!-- Uploaded voice over file -->
                                    <div class="media_list" [ngClass]="nVoiceOverFileSize!==0?'d-block':'d-none'">
                                        <ul>
                                            <li class="mb-3" >
                                                <figure class="media_figure">
                                                    <span class="media">
                                                        <img src="../assets/images/business_Profile/audio-line.png" alt="vo-file"></span>
                                                    <figcaption>
                                                        <div class="caption w-100">
                                                            <p class="text-xs title">{{sUploadedVoiceOverFileName | slice:0:30}}</p>
                                                            <p class="text-xs sub_title"> {{ContentComponent_ReturnFileSize(nVoiceOverFileSize)}}</p>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        </ul>
                                    </div>
                                    <div [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
                                        <div class="formItm custom-textarea">
                                            <textarea id="companyName1" type="text" placeholder="Enter Voiceover text" class="" formControlName="vo_text"></textarea>
                                            <label for="companyName1" class="text-sm">Your Message</label>
                                        </div>
                                    </div>
                                    <div class="custom-radio mb-1" [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
                                        <label for="companyName1" class="text-sm d-block text-gray-3 mb-2">Select type of voice</label>
                                        <div class="custom-radios mt-3">
                                            <div class="cus-radio-btm me-2 pe-1">
                                                <input type="radio" id="color-1" name="color" [checked]="sSelectedVoiceGender==='male'?true:false" (click)="ContentComponent_SelectVoiceGender('male','create')">
                                                <label for="color-1">
                                                    <span>
                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80667 0.855111C9.08816 0.59602 9.52906 0.605373 9.79875 0.878123C10.0767 1.1592 10.0649 1.60979 9.7749 1.87672L4.05049 7.14554C3.77781 7.39652 3.35428 7.39635 3.08181 7.14512L0.224653 4.51071C-0.0651365 4.24351 -0.0764627 3.79291 0.201696 3.51209C0.471612 3.23959 0.912515 3.23059 1.19379 3.48994L3.56681 5.67795L8.80667 0.855111ZM0.789687 3.89819L0.789544 3.89806L0.789687 3.89819Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    Male
                                                </label>
                                            </div>
                                            <div class="cus-radio-btm">
                                                <input type="radio" id="color-2" name="color" [checked]="sSelectedVoiceGender==='female'?true:false" (click)="ContentComponent_SelectVoiceGender('female','create')">
                                                <label for="color-2">
                                                    <span>
                                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M8.80667 0.855111C9.08816 0.59602 9.52906 0.605373 9.79875 0.878123C10.0767 1.1592 10.0649 1.60979 9.7749 1.87672L4.05049 7.14554C3.77781 7.39652 3.35428 7.39635 3.08181 7.14512L0.224653 4.51071C-0.0651365 4.24351 -0.0764627 3.79291 0.201696 3.51209C0.471612 3.23959 0.912515 3.23059 1.19379 3.48994L3.56681 5.67795L8.80667 0.855111ZM0.789687 3.89819L0.789544 3.89806L0.789687 3.89819Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    Female
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cus-select mt-4" [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
                                        <div class="select">
                                            <label for="companyName1" class="text-sm text-gray-3">Select voice</label>
                                            <div class="selectWrapper">
                                                <div class="selectCustom js-selectCustom">
                                                    <div class="selectCustom-trigger">{{sVoiceName | titlecase}}</div>
                                                    <div class="selectCustom-options">
                                                        <div class="selectCustom-option" *ngFor="let item of oVoiceOverList;let i=index;" (click)="ContentComponent_SelectVoice(item,i)">{{item.name | titlecase}}
                                                            <span class="play_staus">
                                                                <!-- <svg width="16" height="12" viewBox="0 0 16 12" [ngClass]="i !== nSelectedVoiceIndex ? 'd-none' : 'd-block'"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M14.0907 0.967677C14.5411 0.553132 15.2465 0.568097 15.678 1.0045C16.1227 1.45422 16.1038 2.17517 15.6398 2.60225L6.48079 11.0324C6.0445 11.4339 5.36685 11.4337 4.93089 11.0317L0.359446 6.81663C-0.104218 6.38912 -0.12234 5.66815 0.322713 5.21884C0.754579 4.78285 1.46002 4.76844 1.91007 5.1834L5.70689 8.68422L14.0907 0.967677ZM1.2635 5.8366L1.26327 5.83639L1.2635 5.8366Z"
                                                                        fill="#2B2F32" />
                                                                </svg> -->
                                                               <span class="play" (mouseover)="ContentComponent_PlayAudio(item.sample_voice)" (mouseout)="ContentComponent_StopAudio(item.sample_voice)" [ngClass]="i == nSelectedVoiceIndex ? 'd-none' : 'd-block'">
                                                                <svg width="8" height="8" viewBox="0 0 8 8"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.666992 7.08335C0.666992 7.60239 1.04444 7.81494 1.50594 7.55599L6.98752 4.47209C7.44904 4.21256 7.44904 3.78748 6.98752 3.52796L1.50594 0.443766C1.04441 0.184243 0.666992 0.396502 0.666992 0.916401V7.08335Z" fill="#2B2F32" />
                                                        </svg>
                                                               </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="mt-4 text-sm text-gray-3 d-inline-block">or</span>
                                    <div class="mt-4 mb-4 pb-2 voicover-btn">
                                        <button (click)="ContentComponent_ToggleFileUploadType(true)" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad"
                                        data-bs-toggle="modal" data-bs-target="#uploadModal">
                                        <svg class="me-1" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                                                    fill="#4F4F4F">
                                                </path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Upload a File
                                    </button>

                                        <!-- <a class="text-gray-2 text-md" href="javascript:void(0)" (click)="ContentComponent_TextToSpeech()">Enter text for Voiceover</a> -->
                                    </div>

                                 </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="offset-md-2 col-md-10 col-lg-7 mt-2">
                        <!-- Alert for Error Message-->
                        <div *ngIf="bDisplayErrorMessage" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z" fill="#842029" />
                            </svg>
                            <div>
                                {{sDisplayResponseMessage}}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z" fill="#FF8369" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- Alert For File upload success-->
                        <div *ngIf="bSuccessMessage"
                            class=" alert alert-success d-flex align-items-center alert-dismissible fade show"
                            style="margin: 15px" role="alert">
                            <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 0C6.26801 0 0 6.26801 0 14V15C0 22.732 6.26801 29 14 29V29C21.732 29 28 22.732 28 15V14C28 6.26801 21.732 0 14 0V0Z"
                                                fill="#00C599" />
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M21.0045 9.94163C21.178 10.1234 21.1713 10.4112 20.9894 10.5846L12.0848 19.0732C11.9088 19.2409 11.632 19.2408 11.4562 19.0729L7.01176 14.8286C6.83006 14.6551 6.82353 14.3672 6.99718 14.1857C7.17082 14.0041 7.45889 13.9976 7.64059 14.1711L11.7709 18.1154L20.3611 9.92649C20.543 9.75313 20.8311 9.75991 21.0045 9.94163Z"
                                                    fill="#00C599" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M19.9219 9.43247C20.3598 9.01505 21.0456 9.03012 21.4652 9.46955C21.8975 9.9224 21.8792 10.6484 21.4281 11.0784L12.5234 19.5671C12.0992 19.9714 11.4404 19.9711 11.0166 19.5664L6.57212 15.322C6.12133 14.8916 6.10371 14.1656 6.53641 13.7132C6.95627 13.2741 7.64212 13.2596 8.07967 13.6775L11.771 17.2026L19.9219 9.43247ZM7.45106 14.3352L7.45084 14.335Z"
                                                    fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="15.5556" height="16.1111" fill="white"
                                                        transform="translate(6.22266 6.44434)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                            <div>
                                {{sDisplayResponseMessage}}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg
                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                            fill="#FF8369" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- upload media -->
<div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload Media</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23"  viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z" fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white" transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File </span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                    <span *ngIf="bUploadVoiceOverFile" class="file-message">{{sUploadedVoiceOverFileName}}</span>
                                    <span *ngIf="!bUploadVoiceOverFile" class="file-message">{{nFileToUploadIndex}} out of {{lFilestoUpload.length}} uploaded.</span>
                                <input *ngIf="bUploadVoiceOverFile" class="file-input" type="file" accept="audio/*"  (change)="ContentComponent_UploadedVoiceOverFile($event)">
                                <input *ngIf="!bUploadVoiceOverFile" class="file-input" multiple type="file" accept="image/* video/*"  (change)="ContentComponent_SelectFilesForUpload($event)">
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="pills-drive" role="tabpanel" aria-labelledby="pills-drive-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23"   viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z" fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white" transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Drive)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here
                                </span>
                                <span class="file-message">No file selected</span>
                                <input class="file-input" type="file" multiple>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-facebook" role="tabpanel"
                            aria-labelledby="pills-facebook-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23"   viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Facebook)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input class="file-input"
                                    type="file" multiple>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-dropbox" role="tabpanel"
                            aria-labelledby="pills-dropbox-tab">
                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23"   viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Dropbox)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message">No file selected</span> <input class="file-input"
                                    type="file" multiple>
                            </div>

                        </div> -->

                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link active" id="pills-device-tab" data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span>
                                </button>
                            </li>
                            <!-- <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-drive-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-drive" type="button" role="tab"
                                    aria-controls="pills-drive" aria-selected="false">
                                    <img src="../assets/images/business_Profile/drive.png" alt="tab-icon">
                                    <span>Google Drive</span></button>
                            </li>
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-facebook-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-facebook" type="button" role="tab"
                                    aria-controls="pills-facebook" aria-selected="false">
                                    <img src="../assets/images/business_Profile/facebook.png" alt="tab-icon">
                                    <span>Facebook</span></button>
                            </li>
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link" id="pills-dropbox-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-dropbox" type="button" role="tab"
                                    aria-controls="pills-dropbox" aria-selected="false">
                                    <img src="../assets/images/business_Profile/dropbox.png" alt="tab-icon">
                                    <span>Dropbox</span></button>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <!-- Modal pop-up Start-->
    <div class="modal fade" id="selectMedia-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body" *ngIf="bDisplayMyMediaSection">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
           <h5>Select from My Media Library
               <span class="text-sm text-gray-3 pt-1 pb-3 d-block weight-normal">{{lUserSelectedVisuals?.length}} media selected</span>
           </h5>
           <app-media-library-popup  [bInputEnableMultiSelection]=true (lEmittedFilesToUpload)="ContentComponent_lEmittedFilesToUpload($event)" [oInputVisualToRemove]="oInputVisualToRemove"></app-media-library-popup>
            </div>
             </div>
        </div>
    </div>
    <!-- Modal pop-up End-->
