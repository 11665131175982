 
<app-header></app-header>
<section class="dashboard accountSec main-body bg-gray-7 h-100">
    <div class="container">
        <div class=" br12 bg-white page-shadow curve-bg h-80vh pb-0 d-flex flex-column">
            <div class="row accountSecInner flex-grow-1" id="row-main"> 
                <app-myvideos-sidebar class="col-md-3 pe-0 pb-5 position-relative sidebar" id="sidebar-left"></app-myvideos-sidebar>
                <div class="col-md-9 border-start ps-0" id="content" [ngClass]="this.oSessionService.isSidebarActive == true? '':'isSidebarActiveFalse'">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div class="">
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </div>
</section>
