import { Injectable } from '@angular/core';
import { Session } from 'protractor';
import { locale } from '../models/account';
import { AccountsettingService } from '../services/dataServices/accountsetting.service';
import { OnboardingDataService } from '../services/dataServices/onboarding-data.service';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService {

  constructor(public dataService:AccountsettingService,public loader:LoaderService) { }

  disconnectSocialPlatform(email,session_id,platform):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.disconnectSocialPlatform(email,session_id,platform).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  cancelSubscription(email,session_id):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.cancelSubscription(email,session_id).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  
  cancelReason(email,session_id,password,reason,message):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.cancelReason(email,password,session_id,reason,message).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
      console.log(err);
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  ContactUs(email,user_email,subject,message,name):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.contactUs(email,user_email,subject,message,name).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  updateUserPlan(email,session_id,plan):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.updateUserPlan(email,session_id,plan).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }


  ManageBilling(email,session_id):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.ManageBilling(email,session_id).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();
      // this.profile = res;
      if(res['redirect_url'] !='')
      { 
      resolve(res['redirect_url']);
      }else
      {
        reject('');
      }
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

public locales:locale[]=[];
  getLocale(email,session_id,num_items=10,num_skip=0):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.getLocales(email,session_id,num_items,num_skip).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();

       console.log(res)

       this.locales = res['results'];

       console.log(this.locales)
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }


  deleteLocale(email,session_id,locale:locale):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.deleteLocales(email,session_id,locale).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();

 

       this.locales = this.locales.filter(c=> c.id != locale.id);

       
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  createLocale(email,session_id,locales:locale[]):Promise<unknown>
  {
   this.loader.showLoader();
   let promise = new Promise((resolve, reject) => {
   this.dataService.createLocales(email,session_id,locales).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();

     

        
   locales.forEach(c=>{this.locales.push(c)});
       
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }

  editLocale(email,session_id,locale:locale):Promise<unknown>
  {
   this.loader.showLoader();

   locale.email = email;
   locale.session_id = session_id;
   let promise = new Promise((resolve, reject) => {
   this.dataService.updateLocale(locale).toPromise().then(
     (res:any)=>
     {
       this.loader.hideLoader();

       console.log(res)

        this.locales.forEach(c=>
          {
if(c.id == locale.id)
{
  c.address = locale.address;
  c.email = locale.email;
  c.name = locale.name;
  c.phone = locale.phone;
  c.url = locale.url;
}
          })

        
      // this.profile = res;
       
       resolve(res);
     },
     (err:any)=>{
       this.loader.hideLoader();
       reject(err);
     }
   )});
   return promise;
  }
  
}
