<!-- <app-header></app-header>

<main class="main">
  <section class="page pageNoSide accountSec main-body bg-gray-7 h-100">
    <div class="container d-flex flex-column h-100">
      <ul class="breadcrumb my-3">
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0)">Manage Account</a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0)">Manage Brands</a>
        </li>
      </ul>
      <div
        class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden flex-grow-1"
        id="row-main"
      >
        <app-account-sidebar
          class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
          id="sidebar-left"
        ></app-account-sidebar>

        <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content">  -->

<div class="data-table pt-4 px-0 m-0 d-flex flex-column h-100">
  <div class="table-header border-bottom pb-4 row m-0 px-4">
    <div class="col-6 px-0 text-start">
      <h3 class="weight-700 text-gray-2 mb-1">Manage Brands</h3>
      <p class="mx-0 my-0 text-sm">
        Add and manage all your brands in one place with ease
      </p>
    </div>
    <div class="col-6 px-0 text-end">
      <button type="button" (click)="openAddNewBrandDrawer()"
        class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-0 float-end mt-1 text-xs"
        data-sidebar="#addNewBrand">
        Add Brand
      </button>
      <!-- <div class="float-end dropdown dropdownBtn btn-dropDown d-inline-block ms-4 divader-left"></div> -->

      <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
        <li>
          <a class="dropdown-item text-md" href="javascript:void(0);">Bulk Action</a>
        </li>
      </ul>

      <!-- <a href="javascript:void(0)" class="float-end filter-btn d-inline-block my-1 ms-2 me-5"
        data-sidebar="#filterAgencyUser" (click)="openFilterUserDrawer()">
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
            fill="#404243"></path>
        </svg>
      </a> -->


      <!-- <div class="float-end searchbox-slide d-inline-block m-0 p-0">
        <div class="searchbox" [ngClass]="{'showSearch' : showSearchBox}">
          <input type="search" placeholder="Search your video" name="search" class="searchbox-input"
            onkeyup="buttonUp();" required="" />
          <input type="submit" class="searchbox-submit" value="GO" />
          <span class="searchbox-icon" (click)="showSearchBox = true;">
            <img src="../assets/images/search-icon.svg" width="16" height="16" alt="" />
          </span>
        </div>
      </div> -->

    </div>
  </div>
  <div class="row video-header mx-0">
    <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
      <li class="col-md-4 mb-2 pe-3">
        <div class="formItm search-icon searchDropdown">
          <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search brand"
            required="" (keyup.enter)="searchBrand()" />
          <label for="search-feild" class="text-sm" style="cursor: default">Search Brand</label>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="searchBrand()"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
              fill="#A39FAA"></path>
          </svg>
        </div>
        <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
          <div>{{ searchTerm }}</div>
          <mat-icon class="link">close</mat-icon>
        </div>
      </li>
      <li class="col-md-2 mb-2 ps-2" id="selectpreff">
        <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
          <div class="dropdown dropdownBtn">
            <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()"
              (blur)="hideSearchSortTypeDD($event)">
              <span class="dropdownBtnText"><strong>Sort by </strong>
                {{ searchBy == "created" ? "Created" : "" }}
                {{ searchBy == "_id" ? "Email" : "" }}
                {{ searchBy == "name" ? "Name" : "" }}
              </span>
            </button>
            <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
              <li>
                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('name')">By Name
                </a>
              </li>
              <li>
                <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
                </a>
              </li>
            </ul>
          </div>
          <div class="sort-arrows pos-right">
            <!-- <mat-icon *ngIf="sortType == -1" (click)="sortTypeSearch(1)"
              >download</mat-icon
            >
            <mat-icon *ngIf="sortType == 1" (click)="sortTypeSearch(-1)"
              >upload</mat-icon> -->

            <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1"
              (click)="sortTypeSearch(1)">
            <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1"
              (click)="sortTypeSearch(-1)">
          </div>
          <!-- <select>
                        <option selected>Last Modified</option>
                    </select> -->
        </div>
      </li>

      <li class="col-md-4 mb-2">
      </li>
    </ul>
  </div>
  <div class="flex-grow-1 overflow-auto">
    <div class="my-3">
      <p>
        <span class="text-lg">Total Brands: </span>
        <b class="weight-600"> {{this.brandList?.result? this.brandList?.result.length :
          '0'}}/{{brandList?.total_count}}</b>
        <!-- <span
          class="ms-3 d-inline-block bg-blue text-xs border-7 px-4 py-1 rounded"
          ><svg
            class="me-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#66AFD9"
            />
            <path
              d="M7.99999 9.4002C8.32799 9.4002 8.59999 9.1282 8.59999 8.8002V4.8002C8.59999 4.4722 8.32799 4.2002 7.99999 4.2002C7.67199 4.2002 7.39999 4.4722 7.39999 4.8002V8.8002C7.39999 9.1282 7.67199 9.4002 7.99999 9.4002Z"
              fill="white"
            />
            <path
              d="M8.73601 10.8963C8.69601 10.8003 8.64001 10.7123 8.56801 10.6323C8.48801 10.5603 8.40001 10.5043 8.30401 10.4643C8.11201 10.3843 7.88801 10.3843 7.69601 10.4643C7.60001 10.5043 7.51201 10.5603 7.43201 10.6323C7.36001 10.7123 7.30401 10.8003 7.26401 10.8963C7.22401 10.9923 7.20001 11.0963 7.20001 11.2003C7.20001 11.3043 7.22401 11.4083 7.26401 11.5043C7.30401 11.6083 7.36001 11.6883 7.43201 11.7683C7.51201 11.8403 7.60001 11.8963 7.69601 11.9363C7.79201 11.9763 7.89601 12.0003 8.00001 12.0003C8.10401 12.0003 8.20801 11.9763 8.30401 11.9363C8.40001 11.8963 8.48801 11.8403 8.56801 11.7683C8.64001 11.6883 8.69601 11.6083 8.73601 11.5043C8.77601 11.4083 8.80001 11.3043 8.80001 11.2003C8.80001 11.0963 8.77601 10.9923 8.73601 10.8963Z"
              fill="white"
            />
          </svg>
          Your current plan allows you to create a maximum of 100 users.
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#upgrade-popup"
            class="d-inline-block ms-5 px-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-x text-secondary-25"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              /></svg
          ></a>
        </span> -->
      </p>
    </div>
    <div class="overflow-auto overflow-table brandlist">
      <table class="table tableCustom table-hover mb-0">
        <thead>
          <tr>
            <th scope="col" class="tableCheck">
              <!-- <div class="custom-switch">
                <input type="checkbox" class="check form-check-input" id="checkAll" />
              </div> -->
            </th>
            <th scope="col" class="uname-width">Name</th>
            <th scope="col">Website</th>
            <th scope="col">Industry</th>
            <th scope="col">Users</th>
            <th scope="col">Published Videos</th>
            <th scope="col" class="tableAction">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let brand of brandList?.result">
            <td scope="row" class="tableCheck">
              <div class="custom-switch">
                <input type="checkbox" class="check form-check-input" />
              </div>
            </td>
            <td>

              <a class="b_userName" (click)="loadBrandProfile(brand._id)" href="javascript:void(0)">{{brand.name}}</a>
            </td>
            <td>{{brand.website}}</td>
            <td>{{brand.industry}}</td>
            <td>{{brand.user_count ? brand.user_count : '0'}}</td>
            <td>{{brand.published_video_count? brand.published_video_count: '0'}}</td>
            <td class="tableAction">
              <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                    fill="#7A7A7A"></path>
                </svg>
              </a>
              <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item text-md" href="#;"
                    [routerLink]="['/account-setting/manage-brand/account/', brand._id.$oid]">Brand Detail</a>
                </li>
                <!-- <li>
                  <a class="dropdown-item text-md"
                    [routerLink]="['/account-setting/manage-brand', brand._id.$oid, 'media-libraries']">View Media</a>
                </li> -->
                <li>
                  <a class="dropdown-item text-md"
                    [routerLink]="['/account-setting/manage-brand', brand._id.$oid, 'list-media-libraries']">Media
                    Libraries</a>
                </li>
                <li (click)="isDeleteModalVisible = true; selectedBrand = brand"><a href="javascript:void(0)"
                    class="dropdown-item text-md">Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 text-end" style="padding-right:42px ">
      <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="brandList?.total_count"
        [pageSize]="limit" [pageSizeOptions]="[15, 20, 25, 50]" (page)="loadPageEvent($event)"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <!-- <div class="table-pagination pb-3 px-4 pagination-list" *ngIf="brandList?.total_count > 0 ">
    <div class="row">
      <div class="col-sm-4">
        <p class="text-gray-3 text-sm m-0">
          Showing {{ ( currentPage == 1) ? (1) : ((currentPage - 1) * limit) + 1 }} -          
          {{((currentPage > 1 && currentPage < listPages.length ) ? (offset + 1) :   offset) + brandList?.count}}
          out of {{brandList?.total_count}}</p>
      </div>
      <div class="col-sm-8 text-end">
        <ul id="pagination" class="m-0">
          <li (click)="loadPage(currentPage - 1)">
            <a href="javascript:void(0)" class="preview-page">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                  fill="#7A7A7A"></path>
              </svg>
            </a>
          </li>
          <li *ngFor="let page of listPages" class="{{ (currentPage == page) ? 'active' : '' }}"
            (click)="loadPage(page)">
            <a>{{page}}</a>
          </li>
          <li (click)="loadPage(currentPage + 1)">
            <a href="javascript:void(0)" class="next-page">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                  fill="white"></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <!-- <div class="table-pagination py-3 px-4">
    <div class="row">
      <div class="col-sm-4">
        <p class="text-gray-3 text-sm m-0">Showing 1-10 out of 44</p>
      </div>
      <div class="col-sm-8 text-end">
        <ul id="pagination" class="m-0">
          <li>
            <a href="#" class="preview-page">
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                  fill="#7A7A7A"
                ></path>
              </svg>
            </a>
          </li>
          <li class="active">
            <a href="#">1</a>
          </li>
          <li>
            <a href="#">2</a>
          </li>
          <li>
            <a href="#">3</a>
          </li>
          <li>
            <a href="#">...</a>
          </li>
          <li>
            <a href="#">8</a>
          </li>
          <li>
            <a href="#">9</a>
          </li>
          <li>
            <a href="#">10</a>
          </li>
          <li>
            <a href="#" class="next-page">
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                  fill="white"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
</div>

<!-- </div>
      </div>
    </div>
  </section>
</main> -->


<!-- add new drawer drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showAddNewBrand" [nzPlacement]="placementUser"
  (nzOnClose)="closeAddNewBrandDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Add Brand</h3>
        <div class="closeRightSideBar" (click)="closeAddNewBrandDrawer()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
              fill="#F2F2F5" />
            <path
              d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
              fill="#A7ABB6" />
          </svg>
        </div>
      </div>
      <form [formGroup]="addBrandForm" *ngIf="addBrandForm" (ngSubmit)="addNewBrand()"
        (keydown.enter)="$event.preventDefault()">
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto overflow-x-hidden">
          <div class="formItm">
            <input id="name" type="text" placeholder="brandName" name="name" formControlName="name" required="" />
            <label for="name" class="text-sm">Brand Name</label>
            <span
              *ngIf="addBrandForm?.controls['name']?.hasError('required') && (addBrandForm?.controls['name']?.touched || addBrandFormSubmitted)"
              class="error">
              Please enter Brand Name.</span>
          </div>
          <div class="formItm">
            <input id="website" type="url" placeholder="website" name="website" formControlName="website" required="" />
            <label for="website" class="text-sm">Website</label>
            <span
              *ngIf="addBrandForm?.controls['website']?.hasError('required') && (addBrandForm?.controls['website']?.touched || addBrandFormSubmitted)"
              class="error">
              Please enter website.</span>
          </div>
          <!-- <div class="formItm">
            <input id="industry" type="text" placeholder="industry" name="industry" formControlName="industry"
              required="" />
            <label for="industry" class="text-sm">Industry</label>
            <span
              *ngIf="addBrandForm?.controls['industry']?.hasError('required') && (addBrandForm?.controls['industry']?.touched || addBrandFormSubmitted)"
              class="error">
              Please enter industry.</span>
          </div> -->
          <div class="formItm down_arrow pt-0">
            <div class="lib-access">
              <p class="weight-600 my-4">Select Industry</p>
              <div class="search-sec p-3 mt-3">
                <select class="form-select" aria-label="Default select example" formControlName="industry"
                  style="padding:10px">
                  <option value="" disabled selected>Please select Industry</option>
                  <option value="{{industry.name}}" *ngFor="let industry of industryList?.result">{{industry.name}}
                  </option>
                </select>
                <span
                  *ngIf="addBrandForm?.controls['industry']?.hasError('required') && (addBrandForm?.controls['industry']?.touched || addBrandFormSubmitted)"
                  class="error">
                  Please enter industry.</span>
              </div>
            </div>
          </div>
          <div class="formItm down_arrow pt-0">
            <div class="lib-access">
              <p class="weight-600 mt-4">Assign Users</p>
              <p class="weight-600 my-0 assign-user">Search for an existing user in this account and assign them to this
                brand</p>
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3">
                  <!-- Search Users<button
                  type="button"
                  class="btn-close float-end"
                  aria-label="Close"
                ></button> -->
                  <mat-form-field>
                    <input formControlName="searchUserText" #userListAutoComplete
                      (ngModelChange)="this.modelUserSearchChanged.next($event)" matInput aria-label="State"
                      [matAutocomplete]="autoCompleteUser" (keyup.enter)="openWarningNote()">
                    <mat-autocomplete #autoCompleteUser="matAutocomplete">
                      <mat-option *ngIf="isUserSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isUserSearchLoading">
                        <mat-option *ngFor="let user of userList.result" [value]="user?._id
                      " (click)="setAddUsers(user._id)">
                          <span><b>{{user._id}}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                </p>
                <ul class="p-0">
                  <div class="part1">
                    <li class="d-block text-md"
                      *ngFor="let selecteduser of selectedAddUsers | slice:0:userListLoopLimit "> {{selecteduser}}
                      <button type="button" (click)="removeAddUsers(selecteduser)" class="btn-close float-end"
                        aria-label="Close"></button>
                    </li>
                  </div>
                  <a href="javascript:void(0)" *ngIf="selectedAddUsers.length > 3 && userListLoopLimit  == 3"
                    class="vall text-md" (click)="viewAllSelectedUsers(2000)">View All</a>
                  <a href="javascript:void(0)" *ngIf="selectedAddUsers.length > 3 && userListLoopLimit  > 3"
                    class="vall text-md" (click)="viewAllSelectedUsers(3)">Show Less</a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.showwarning" class="alert alert-warning alert-dismissible fade show d-flex pe-0 mt-3"
          role="alert">
          <img class="mt-2 toast-warning-icon" src="../../../../assets/images/warning-icon.png" height="30px">
          <p class="toast-font ps-3">Attention: Please add this user to your account before assigning to a brand.
            <br>You can do this in the "Users" tab under Account Settings.
          </p>
          <button (click)="closeWarningNote()" type="button" class="close close-toast" data-dismiss="alert"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="side-footer side-content d-flex align-items-center">
          <button [disabled]="!this.addBrandForm.valid"
            class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
            <span class="py-1 d-block">Add Brand</span>
          </button>
          <!-- <button class="d-inline-block small-pad text-gray-2 font-weight-noral text-md ms-3">
                  Deactivate User
              </button> -->
        </div>

      </form>
    </nav>
  </ng-container>
</nz-drawer>
<!-- add new drawer drawer end -->


<!-- user filter drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showFilterUserDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeFilterUserDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <!-- <nav id="filterAgencyUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700 d-flex align-items-center">
          Filters
          <a href="javascript:void(0)" class="text-xs text-primary-4 weight-normal ms-3">Clear All</a>
        </h3>
        <div class="closeRightSideBar" (click)="closeFilterUserDrawer()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
              fill="#F2F2F5" />
            <path
              d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
              fill="#A7ABB6" />
          </svg>
        </div>
      </div>
      <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
        <div class="custom-select-box mb-4">
          <h3 class="text-gray-2 text-lg mb-2">White Label</h3>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              First
            </label>
          </div>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Last
            </label>
          </div>
        </div>
        <div class="custom-select-box mb-4">
          <h3 class="text-gray-2 text-lg mb-2">Account Status</h3>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Active
            </label>
          </div>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Deactive
            </label>
          </div>
        </div>
      </div>
      <div class="side-footer d-flex align-items-center">
        <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
          <span class="py-1 d-block">Apply</span>
        </button>
      </div>
    </nav>
  </ng-container>
</nz-drawer>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isDeleteModalVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
  (nzOnCancel)="handleCancel()">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

        <h5>Are you sure you want to Delete this Brand?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Brand again.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm" (click)="deleteBrand()">Delete</a>
      </div>
    </div>
  </div>
</nz-modal>
<!-- user filter drawer end -->

<!-- <div class="overlay sidebarOverlay"></div>
<div class="overlay sidebarOverlay2"></div> -->