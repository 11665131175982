import { UserProfile } from './../models/admin';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { user } from '../models/onboarding';

@Injectable({
  providedIn: 'root'
})
export class cSessionService {

  userProfileLogoPath: any;
    // todo: util service
    accountSettingHeaderName = "Account Overview";
    public headerBreadCrumps =  {
      currentPage : "",
      userProfile : { _id : ""}, 
      brandDetail : undefined
    }

  constructor(private oRouter : Router) {
    
  }

  setAccountSettingHeaderName(value:string)
  {
    setTimeout(() => {
      this.accountSettingHeaderName = value;
    }, 0);
  }

  setHeaderBreadcrumps(key : string , value:any)
  {
    // console.log(key, value);
    setTimeout(() => {
      switch (key) {
        case 'userProfile': 
          this.headerBreadCrumps.currentPage = 'user';
          this.headerBreadCrumps.userProfile = value;
          break;
        case 'brandDetail': 
          this.headerBreadCrumps.currentPage = 'brand';
          this.headerBreadCrumps.brandDetail = value;
          break;
      }
      // console.log(" RESET...");
      // console.log(this.headerBreadCrumps);      
    }, 0);
  }

  dashboardVideoListView = false;
  dashboardVideoGridView = false;

  visibleVideo = false;
  visibleTitle = false;
  visibleVoiceover = false;
  visibleAudio = false;
  VideoSettingEditClip = false;
  visiblePreviewVideo = false; 
  visiblePreviewOverview = false;
  visibleAnalytics = false;
  visibleVideoSettings = false;    
  userProfile : UserProfile;
  organization_id : string | any;
  isSidebarActive = true;
  
  //lsSocialConnectRedirectURL
   cSessionService_SetRedirectURL(oData)
   {
    localStorage.setItem('lsSocialConnectRedirectURL', JSON.stringify(oData))
   }
   cSessionService_GetRedirectURL()
   {
    return JSON.parse(localStorage.getItem('lsSocialConnectRedirectURL'));
   }
  //lsUserEmail
  cSessionService_SetUserEmail(sData)
  {
    localStorage.setItem('lsUserEmail', sData);
  }

  getOrganizationId() : string{
    
    return (localStorage.getItem('organization_id'));
  }
  
  setUserProfile(profile){    
    this.userProfile = profile; 
    this.organization_id = 
    localStorage.setItem('organization_id', profile.organization_id.$oid);    
    console.log( " organization ... ", this.getOrganizationId());
  }

  cSessionService_SetLoggedUser(sData)
  {
    localStorage.setItem('LoggedUser', sData);
  }

  cSessionService_GetLoggedUser():user
  {
 if(localStorage.getItem('LoggedUser'))
 {
  //  return JSON.parse(localStorage.getItem('LoggedUser')) as user;
 }
 return null;
  }
  cSessionService_GetUserEmail()
  {
    return localStorage.getItem('lsUserEmail');
  }
   //lsSessionID
   cSessionService_SetSessionId(sData)
   {
     localStorage.setItem('lsSessionId', sData);
   }
   SessionService_GetSessionId()
   {
     return localStorage.getItem('lsSessionId');
   }
   //lsUsername
   cSessionService_SetUsername(sData)
   {
     localStorage.setItem('lsUsername', sData);
   }
   cSessionService_GetUsername()
   {
     return localStorage.getItem('lsUsername');
   }
  //lsPassword
  cSessionService_SetUserPassword(sData)
  {
    localStorage.setItem('lsPassword', sData);
  }
  cSessionService_GetUserPassword()
  {
    return localStorage.getItem('lsPassword');
  }
  //lsUserLogo
  cSessionService_SetUserLogo(oData)
  {
    localStorage.setItem('lsUserLogo', JSON.stringify(oData));
  }
  cSessionService_GetUserLogo()
  {
    return JSON.parse(localStorage.getItem('lsUserLogo'));
  }
  // Create video section start
  // Create video section start
  // Create video section start

  // lsOverviewForm
  cSessionService_SetOverviewForm(oData)
  {
    localStorage.setItem('lsOverviewForm', JSON.stringify(oData));
  }
  cSessionService_GetOverviewForm()
  {
    return JSON.parse(localStorage.getItem('lsOverviewForm'));
  }
  // lsLooknFeelForm
  cSessionService_SetLooknFeelForm(oData)
  {
    localStorage.setItem('lsLooknFeelForm', JSON.stringify(oData));
  }
  cSessionService_GetLooknFeelForm()
  {
    return JSON.parse(localStorage.getItem('lsLooknFeelForm'));
  }
  //lsOtherSettingForm
  cSessionService_SetOtherSettingForm(oData)
  {
    localStorage.setItem('lsOtherSettingForm', JSON.stringify(oData));
  }
  cSessionService_GetOtherSettingForm()
  {
    return JSON.parse(localStorage.getItem('lsOtherSettingForm'));
  }
  //lsContentForm
  cSessionService_SetContentForm(oData)
  {
    localStorage.setItem('lsContentForm', JSON.stringify(oData));
  }
  cSessionService_GetContentForm()
  {
    return JSON.parse(localStorage.getItem('lsContentForm'));
  }
  //generate video payload
  cSessionService_SetGenerateVideoPayload(oData)
  {
    localStorage.setItem('lsGenerateVideoPayload', JSON.stringify(oData));
  }
  cSessionService_GetGenerateVideoPayload()
  {
    return JSON.parse(localStorage.getItem('lsGenerateVideoPayload'));
  }
  //lsVideoId
  cSessionService_SetVideoId(sData)
  {
    localStorage.setItem('lsVideoId', sData);
  }
  SessionService_GetVideoId()
  {
    return localStorage.getItem('lsVideoId');
  }  
  //lsResetTextOverlay
  cSessionService_SetResetTextOverlayCheck(sData)
  {
    localStorage.setItem('lsResetTextOverlay', sData);
  }
  SessionService_GetResetTextOverlayCheck()
  {
    return localStorage.getItem('lsResetTextOverlay');
  }
  //lsVideoTitle
  cSessionService_SetVideoTitle(sData)
  {
    localStorage.setItem('lsVideoTitle', JSON.stringify(sData));
  }
  SessionService_GetVideoTitle()
  {
    return JSON.parse(localStorage.getItem('lsVideoTitle'));
  }
  //lsKeyTermsTag
  cSessionService_SetKeyTermsTags(sData)
  {
    localStorage.setItem('lsKeyTermsTag', JSON.stringify(sData));
  }
  SessionService_GetKeyTermsTags()
  {
    return JSON.parse(localStorage.getItem('lsKeyTermsTag'));
  }
  //lsVideoDuration
  cSessionService_SetVideoDuration(sData)
  {
    localStorage.setItem('lsVideoDuration', JSON.stringify(sData));
  }
  SessionService_GetVideoDuration()
  {
    return JSON.parse(localStorage.getItem('lsVideoDuration'));
  }
  //lsVideoCreationDate
  cSessionService_SetVideoCreationDate(sData)
  {
    localStorage.setItem('lsVideoCreationDate', JSON.stringify(sData));
  }
  cSessionService_GetVideoCreationDate()
  {
    return JSON.parse(localStorage.getItem('lsVideoCreationDate'));
  }
   //lsGeneratedVideo
   cSessionService_SetGeneratedVideo(sData)
   {
     localStorage.setItem('lsGeneratedVideo', JSON.stringify(sData));
   }
   SessionService_GetGeneratedVideo()
   {
     return JSON.parse(localStorage.getItem('lsGeneratedVideo'));
   }
  //lsSendGeneratedVideoRequest
  cSessionService_SetGeneratedVideoCheck(sData)
  {
    localStorage.setItem('lsSendGeneratedVideoRequest', JSON.stringify(sData));
  }
  cSessionService_GetGeneratedVideoCheck()
  {
    return JSON.parse(localStorage.getItem('lsSendGeneratedVideoRequest'));
  }
  //locales
  cSessionService_SetLocales(oData)
  {
    localStorage.setItem('lsLocales', JSON.stringify(oData));
  }
  cSessionService_GetLocales()
  {
    return JSON.parse(localStorage.getItem('lsLocales'));
  }
   //lsHighlightTabs
   cSessionService_SetHighlightTabs(sData)
   {
     localStorage.setItem('lsHighlightTabs', JSON.stringify(sData));
   }
   cSessionService_GetHighlightTabs()
   {
    return JSON.parse(localStorage.getItem('lsHighlightTabs'));
   }
   cSessionService_ResetHighlightedTabs()
    {
      const lsHighlightTabs = {content : true,overlays : false,voiceover : false};
      localStorage.setItem('lsHighlightTabs', JSON.stringify(lsHighlightTabs));
    }
  //reset forms
  SessionService_RemoveCreateVideoSettings()
  {
    localStorage.removeItem('lsOtherSettingForm');
    localStorage.removeItem('lsLooknFeelForm');
    localStorage.removeItem('lsOverviewForm');
    localStorage.removeItem('lsContentForm');
    localStorage.removeItem('lsGeneratedVideo');
    localStorage.removeItem('lsVideoId');
    localStorage.removeItem('lsVideoDuration');
    localStorage.removeItem('lsVideoTitle');
    localStorage.removeItem('lsVideoCreationDate');
    localStorage.removeItem('lsVideoDetails');
    localStorage.setItem('lsResetTextOverlay', 'false');
    this.cSessionService_SetGeneratedVideoCheck(false);
    this.cSessionService_ResetHighlightedTabs();
  }
  
  // Create video section end
  // Create video section end
  // Create video section end

   //lsVideoDetails
   cSessionService_SetVideoDetails(sData)
   {
     localStorage.setItem('lsVideoDetails', JSON.stringify(sData));
   }
   SessionService_GetVideoDetails()
   {
     return JSON.parse(localStorage.getItem('lsVideoDetails'));
   }
  //lsVideoStatus
  cSessionService_SetVideoStatus(sData)
  {
    localStorage.setItem('lsVideoStatus', JSON.stringify(sData));
  }
  cSessionService_GetVideoStatus()
  {
    return JSON.parse(localStorage.getItem('lsVideoStatus'));
  }
  //lsUserVoiceOverList
  cSessionService_SetUserVoiceOverList(oData)
  {
    localStorage.setItem('lsUserVoiceOverList', JSON.stringify(oData));
  }
  cSessionService_GetUserVoiceOverList()
  {
    return JSON.parse(localStorage.getItem('lsUserVoiceOverList'));
  }  
  //lsBrandIdentityForm
  cSessionService_SetBrandIdentityForm(oData)
  {
    localStorage.setItem('lsBrandIdentityForm', JSON.stringify(oData));
  }
  cSessionService_GetBrandIdentityForm()
  {
    return JSON.parse(localStorage.getItem('lsBrandIdentityForm'));
  }
  //lsCustomerExperienceForm
  cSessionService_SetCustomerExperienceForm(oData)
  {
    localStorage.setItem('lsCustomerExperienceForm', JSON.stringify(oData));
  }
  cSessionService_GetCustomerExperienceForm()
  {
    return JSON.parse(localStorage.getItem('lsCustomerExperienceForm'));
  }
  //lsTemplateConfig
  cSessionService_SetTemplatesConfiguration(oData)
  {
    localStorage.setItem('lsTemplateConfig', JSON.stringify(oData));
  }
  cSessionService_GetTemplatesConfiguration()
  {
    return JSON.parse(localStorage.getItem('lsTemplateConfig'));
  }
  //lsMetaInfo
  cSessionService_SetMetaInfo(sData)
  {
    localStorage.setItem('lsMetaInfo', JSON.stringify(sData));
  }
  SessionService_GetMetaInfo()
  {
    return JSON.parse(localStorage.getItem('lsMetaInfo'));
  }
   //lsEditVideoId
   cSessionService_SetEditVideoId(sData)
   {
     localStorage.setItem('lsEditVideoId', JSON.stringify(sData));
   }
  //  SessionService_GetEditVideoId()
  //  {
  //    return JSON.parse(localStorage.getItem('lsEditVideoId'));
  //  }
   //lsOriginalVideoBlockConfig
   cSessionService_SetOriginalVideoBlockConfig(sData)
   {
     localStorage.setItem('lsOriginalVideoBlockConfig', JSON.stringify(sData));
   }
   SessionService_GetOriginalVideoBlockConfig()
   {
     return JSON.parse(localStorage.getItem('lsOriginalVideoBlockConfig'));
   }

  //  lsOriginalVideothumbUrl
  cSessionService_SetOriginalVideoThumbUrl(sData)
   {
     localStorage.setItem('lsOriginalVideothumbUrl', JSON.stringify(sData));
   }
   cSessionService_GetOriginalVideoThumbUrl()
   {
     return JSON.parse(localStorage.getItem('lsOriginalVideothumbUrl'));
   }
  //lsSessionExpiryTime
  SessionService_StartSessionExpiryTimer()
  {
    const oDate = new Date();
    const nSessionExpiryTime = oDate.getTime() + environment.PRONTO_USER_SESSION_TIMEOUT;
    //const nSessionExpiryTime = oDate.getTime() + 300000;
    
    localStorage.setItem("lsSessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }

  SessionService_CheckSessionExpiry()
  {
    const oDate = new Date();
    const nSessionExpiryTime = JSON.parse(localStorage.getItem("lsSessionExpiryTime"));
    const nCurrentTime = oDate.getTime();

    if (nSessionExpiryTime === null)
    {
      // console.log("SessionService_CheckSessionExpiry: SessionExpiryTime not found in localstorage. So, terminate the current Session");
      localStorage.clear();
      this.oRouter.navigateByUrl('/login');
    }
    else
    {
      // console.log("SessionService_CheckSessionExpiry: Value of SessionExpiryTime {%s}", nSessionExpiryTime);
      // console.log("SessionService_CheckSessionExpiry: Current Time {%s}", nCurrentTime);
      // compare the expiry time of the item with the current time
      if (nCurrentTime > nSessionExpiryTime)
      {
        localStorage.removeItem("lsSessionExpiryTime");
        // console.log("SessionService_CheckSessionExpiry: Current Session is expired. So, logging out");
        localStorage.clear();
        this.oRouter.navigateByUrl('/login');
      }
    }
  }
  //SetTimeOut
  SessionService_SetVideoTimeout()
  {
    let lsVideoTimeout = [
      {'duration': 6 , 'timeout': 30000},
      {'duration':15, 'timeout': 60000},
      {'duration':30, 'timeout': 120000},
      {'duration':60, 'timeout': 150000},
    ]
    localStorage.setItem('lsVideoTimeout', JSON.stringify(lsVideoTimeout));
  }
  //Get Timeout
  SessionService_GetVideoTimeout()
  {
    return JSON.parse(localStorage.getItem('lsVideoTimeout'));
  }
  //lsMediaLibrary
  cSessionService_SetMediaLibrary(sData)
  {
    localStorage.setItem('lsMediaLibrary', JSON.stringify(sData));
  }
  cSessionService_GetMediaLibrary()
  {
    return JSON.parse(localStorage.getItem('lsMediaLibrary'));
  }
  cSessionService_SetPublicURL(sData)
  {
    localStorage.setItem('public_url',JSON.stringify(sData));
  }
  cSessionService_GetPublicURL()
  {
    return JSON.parse(localStorage.getItem('public_url'));
  }
  //lsSetBusinessPlan
  cSessionService_SetBusinessPlan(sData)
  {
    localStorage.setItem('lsSetBusinessPlan',JSON.stringify(sData));
  }
  cSessionService_GetBusinessPlan()
  {
    return JSON.parse(localStorage.getItem('lsSetBusinessPlan'));
  }
  //lsSetRedirectionMessage
  cSessionService_SetRedirectionMessage(sData)
  {
    localStorage.setItem('lsSetRedirectionMessage',JSON.stringify(sData));
  }
  cSessionService_GetRedirectionMessage()
  {
    return JSON.parse(localStorage.getItem('lsSetRedirectionMessage'));
  }  
  //lsSetBusinessPlan
  cSessionService_SetUserSelectedBusinessPlan(sData)
  {
    localStorage.setItem('lsSetUserSelectedBusinessPlan',JSON.stringify(sData));
  }
  cSessionService_GetUserSelectedBusinessPlan()
  {
    return JSON.parse(localStorage.getItem('lsSetUserSelectedBusinessPlan'));
  }
  //lsSetBusinessPlan
  cSessionService_SetUserVideosCount(sData)
  {
    localStorage.setItem('lsSetUserVideosCount',JSON.stringify(sData));
  }
  cSessionService_GetUserVideosCount()
  {
    return JSON.parse(localStorage.getItem('lsSetUserVideosCount'));
  }

    cSessionService_SetGuestEmail(sData)
  {
    localStorage.setItem('guestEmail',sData);
  }
  cSessionService_GetGuestEmail()
  {
    return (localStorage.getItem('guestEmail'));
  }

      cSessionService_SetGuestSession(sData)
  {
    localStorage.setItem('guestSession',sData);
  }
  cSessionService_GetGuestSession()
  {
    return (localStorage.getItem('guestSession'));
  }
  
  
}
