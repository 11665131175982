import { BrandData } from './../../../../models/brand';
import { Subject } from 'rxjs';
import { UserProfile } from './../../../../models/onboarding';
import { Component, OnInit, ChangeDetectorRef,ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminBrandManagerService } from 'src/app/Managers/adminManager/admin-brand-manager.service';
import { BrandInfo } from 'src/app/models/brand';
import { user } from 'src/app/models/admin';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { ErrorService } from 'src/app/services/error.service';
import { Userservice } from '../service/user-service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountsComponent implements OnInit {

  isDeactivateModalVisible = false;
  isactivateModalVisible = false;
  showAssignNewBrandDrawer = false;
  userId = "";
  profileData : UserProfile;
  placementUser: NzDrawerPlacement = "right";
  userBrandsList : any[];
  searchBrandText: "";
  modelBrandSearchChanged = new Subject<string>();
  selectedAddUserBrands: BrandData[] = [];
  // Add user Form
  public profileForm: FormGroup;
  profileFormSubmitted = false;
  brandList: BrandInfo;
  isBrandSearchLoading: boolean = false;
  public assignBrandToUserForm: FormGroup;
  userAccountId : string = '';
  showResetPasswordDrawer: boolean = false;
  assignBrandSubmit = false;
  subscribedNews = false;
  subscribedEmail = false;
  selectedBrand : any;

  @ViewChild('brandListAutoComplete', { read: MatAutocompleteTrigger })  autoCompleteBrand: MatAutocompleteTrigger;
  @ViewChild('searchBrandField')  searchBrandField: ElementRef;

  constructor(private location: Location,
    private router : Router, private route: ActivatedRoute,
    private profileFormBuilder: FormBuilder,
    private userService: Userservice,
    // private userService: Userservice,
    private brandManager : AdminBrandManagerService,
    private assignBrandToUserFormBuilder: FormBuilder,
    private errorService: ErrorService,
    private mediaManager : MediaManagerService,
    private successMessageService : SuccessMessageModalService,
    private cd : ChangeDetectorRef) {

     }


  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];
    if( this.userId &&  this.userId != ""){
      this.userService.getUserProfile(this.userId).then((profile : UserProfile) => {
        this.profileData = profile;
        this.mediaManager.selectedmedialib = profile?.media_lib;
        this.userService.selectedMediaId = profile?.media_lib?._id?.$oid;
        this.userService.selectedUserMediaEmail = profile?._id;
        this.userService.setUserHeaderBreadCrumps(profile);
        this.userService.selectedUser = profile;
        console.log(profile);
        console.log(this.profileData.user_role[0], this.profileData.user_role);
        this.profileForm = this.profileFormBuilder.group({
          _id: this.profileData._id,
          email: this.profileData._id,
          role: this.profileData?.user_role[0],
          name : (this.profileData.username != undefined && this.profileData.username) ? this.profileData.username : (this.profileData.first_name + " " + this.profileData.last_name),
          allow_share_brand_account: (this.profileData?.allow_share_brand_account) ? this.profileData.allow_share_brand_account : false,
          allow_share_personal_account: (this.profileData?.allow_share_personal_account) ? this.profileData.allow_share_personal_account : false,
          allow_share_company_account: (this.profileData?.allow_share_company_account) ? this.profileData.allow_share_company_account : false,
          allow_download_videos: (this.profileData?.allow_download_videos) ? this.profileData.allow_download_videos : false,
          get_pronto_newsletter : (this.profileData?.get_pronto_newsletter) ? this.profileData.get_pronto_newsletter : false,
          account_changes : (this.profileData?.account_changes) ? Boolean(this.profileData.account_changes) : false,
          subscribe_email_changes : (this.profileData?.subscribe_email_changes) ?
          Boolean(this.profileData?.subscribe_email_changes) : false
        });

        this.subscribedNews = this.profileData.get_pronto_newsletter;
        this.subscribedEmail = (this.profileData.subscribe_email_changes != undefined && this.profileData.subscribe_email_changes) ? true : false;

        this.userService.getUserBrandList(this.profileData._id).then((brands : any) =>{
          this.userBrandsList = <any>brands?.records;
        });
      });
    }

    this.modelBrandSearchChanged
    .pipe(debounceTime(200), distinctUntilChanged())
    .subscribe(async (value) => {
     if(value.length > 1){
      this.brandManager.getListBrand("","",0, 30, "name", -1, value,"name", false).then((brand : BrandInfo) => {
        this.brandList = brand;
      })
      this.isBrandSearchLoading = false;
      return this.brandList;
      } else {
        return [];
      }

    });
    window.addEventListener('scroll', this.scrollEvent, true);

  }

  scrollEvent = (event: any): void => {
    if(this.autoCompleteBrand && this.autoCompleteBrand.panelOpen){
      this.autoCompleteBrand.updatePosition();
    }
  }

  updateUserRole(role : string) {
    this.profileForm.controls['role'].setValue(role);
  }
  async updateProfile(){
    this.profileFormSubmitted = true;
    if (this.profileForm.invalid) {
      // TODO show Error notification
    } else {
      // TODO add user
      const userInfo: user = {
        _id : this.profileForm.controls["_id"].value,
        username : this.profileForm.controls["name"].value,
        allow_download_videos : this.profileForm.controls["allow_download_videos"].value,
        allow_share_brand_account : this.profileForm.controls["allow_share_brand_account"].value,
        allow_share_company_account : this.profileForm.controls["allow_share_company_account"].value,
        allow_share_personal_account : this.profileForm.controls["allow_share_personal_account"].value,
        subscribe_email_changes : this.profileForm.controls["subscribe_email_changes"].value,
        get_pronto_newsletter : this.profileForm.controls["get_pronto_newsletter"].value,
        user_role : [this.profileForm.controls["role"].value],
      }

      this.subscribedNews = ( userInfo.subscribe_email_changes == 'true' ) ? true : false;
      this.subscribedEmail = ( userInfo.subscribe_email_changes == 'true' ) ? true : false;

      this.profileData.username = this.profileForm.controls["name"].value;
      this.userService.togglePageLoader(true);
      await this.userService.updateUser(userInfo);
      this.userService.togglePageLoader(false);
    }
  }
  // add assign brand drawer
  openAssignNewBrandDrawer(): void {
    this.showAssignNewBrandDrawer = true;
    this.selectedAddUserBrands = [];
    this.assignBrandToUserForm = this.assignBrandToUserFormBuilder.group({
      _id : this.profileData._id,
      email : this.profileData._id,
      searchBrandText : "",
      // name : this.profileData.username + " " + this.profileData.last_name,
      name : this.profileData.username,
    });

    setTimeout( () => {
      if(this.searchBrandField){
        this.searchBrandField.nativeElement.focus();
      }
    }, 500)

  }
  closeAssignNewBrandDrawer(): void {
    this.showAssignNewBrandDrawer = false;
  }
  backward(){
    this.location.back();
  }
     // modal Deactivate user
     openDeactivateModal(): void {
      this.isDeactivateModalVisible = true;
    }
    closeDeactivateModal(): void {

      this.isDeactivateModalVisible = false;
      this.cd.detectChanges();
    }
     openActivateModal(): void {
      this.isactivateModalVisible = true;
    }
    closeActivateModal(): void {
      this.isactivateModalVisible = false;
    }

    setAddUserBrands(brand: BrandData): void {
      const pos = this.selectedAddUserBrands.some(item => { return (item._id == brand._id); });
      if(!pos) {
        this.assignBrandToUserForm?.controls["searchBrandText"].setValue("");
        this.selectedAddUserBrands.push(brand);
      }
    }

    removeAddUserBrands(brand: BrandData): void {
      var indexBrand = this.selectedAddUserBrands.indexOf(brand);
      if (indexBrand > -1) {
        this.selectedAddUserBrands.splice(indexBrand, 1);
      }
    }

    loadUserMediaLibrary(){
      this.router.navigate([`/account-setting/user-account/${this.userId}/user-media-libraries`]);
    }

    assignBranToUser(){
      this.assignBrandSubmit = true;
      const brandIds : string[] = this.selectedAddUserBrands.map( (brand) => (brand._id.$oid ));
      this.userService.updateUserBrands(this.profileData._id,brandIds).then((response : any) => {

        if(response && response.records && response.records.length > 0) {
          this.assignBrandSubmit = false;

          response.records.map( (record) =>{
            this.brandManager.getBrandProfile(record.brand._id.$oid, false).then((brandDetail : any) => {

              this.userBrandsList.unshift({_id : record.user_brand_id, brand : brandDetail});
              this.showAssignNewBrandDrawer = false;
            });
          })

        } else {
          this.assignBrandSubmit = false;
          this.showAssignNewBrandDrawer = false;
          // this.errorService.handleError(response.result, "info", true);
        }
      }).catch(err=>{
        this.assignBrandSubmit = false;
        this.showAssignNewBrandDrawer = false;
        this.errorService.handleError(err.message, "error", true);
      })
    }


  openResetPasswordDrawer(): void {
      this.showResetPasswordDrawer = true;
    }
  closeResetPasswordDrawer(): void {
    this.showResetPasswordDrawer = false;
  }

  deactivateUser(){
    this.userService.deActivateUserAccount(this.profileData._id).then((res) => {
      this.successMessageService.display("User has been deactivated", "Deactivate User", true , true);
      this.profileData.status = 'inactive';
    })
  }
  activateUser(){
    this.userService.activateUserAccount(this.profileData._id).then((res) => {
      this.successMessageService.display("User has been activated", "Activate User", true , true);
      this.profileData.status = 'active';
    })
  }

  deleteAssignedBrand(id : string){
    this.userService.deleteAssignedBrand(id).then((res) => {
      const indexRecrod = this.userBrandsList.findIndex(
        (brand) => brand._id.$oid == id
      );
      if (indexRecrod > -1) {
        this.successMessageService.display("Brand has been deleted.", "Delete Brand", true , true);
        this.userBrandsList.splice(indexRecrod, 1);
      }
    });
  }

  unSubscribed(){
    const permission = {
      _id: this.profileData._id,
      subscribe_email_changes: false,
      get_pronto_newsletter: false,
    };
    this.profileForm?.controls['get_pronto_newsletter'].setValue(false);
    this.profileForm?.controls['subscribe_email_changes'].setValue(false);
    this.subscribedNews =  false;
    this.subscribedEmail = false;
    this.updateUserPermission(permission);
  }


  subscribed(){
    const permission = {
      _id: this.profileData._id,
      subscribe_email_changes: true,
      get_pronto_newsletter: true,
    };
    this.profileForm?.controls['get_pronto_newsletter'].setValue(true);
    this.profileForm?.controls['subscribe_email_changes'].setValue(true);
    this.subscribedNews =  true;
    this.subscribedEmail = true;
    this.updateUserPermission(permission);
  }

  async updateUserPermission(permission : any) {
    await this.userService.updateUserPermission(permission);
  }

  resetUserPwd(){
    this.userService.resetUserPermission(this.profileData._id).then((res) => {
      this.showResetPasswordDrawer = false;
      this.successMessageService.display("Email has been sent to user", "Reset Password", true , true);
    }).catch((err) => {
      this.showResetPasswordDrawer = false;
      this.errorService.handleError("Issue resetting password", "Reset Password", true , true);
    });
  }

  showDeleteBrandModal = false;

  showDeleteBrandModel(brand : string){
    this.selectedBrand = brand;
    this.showDeleteBrandModal = true;
  }

  deleteBrand(){
    this.showDeleteBrandModal = false;
    this.deleteAssignedBrand(this.selectedBrand);
  }
}
