import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { OnboardingManagerService } from '../../../Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {

  fContactUsForm : FormGroup;
  lEnterPriseOptions : any = [
    {name: 'Enterprise Pricing' , value: 'enterprise-pricing'},
    {name: 'Enterprise Other' , value: 'enterprise-other'}
  ]
  sSelectedEnterpriseOption: any;
  sUserEmail: any;
  @Input() showAsDialogue : boolean = false;
  fUserProfileForm : FormGroup;
  bDisplayContactUsAlertMessage: boolean =false;
  sAlertMessage: any;
  constructor(private oSessionService : cSessionService, private oRouter : Router, private oFormBuilder : FormBuilder,
    private oWebSocketService: cWebSocketService,private accountManger:AccountManagerService ,private onboradingManager:OnboardingManagerService, public planManager: SubscriptionplanManagerService) { }

  ngOnInit(): void {
    this.InitializeJqueryCode();
    this.SelectPlanComponent_InitializeContactUsForm();
    this.sUserEmail = this.onboradingManager.profile.email;
    this.CreateAccountCompanyComponent_InitializeUserProfileForm();
    this.sSelectedEnterpriseOption = "Enterprise Pricing";

  }
  CreateAccountCompanyComponent_InitializeUserProfileForm()
  {
    this.fUserProfileForm=this.oFormBuilder.group(
    {
      company_name: [''],
      url: [''],
      business_type: [''],
      industry: [''],
      address: this.oFormBuilder.group({
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '' 
      }),
      phone: [''],
      email: this.sUserEmail,
      color: '',
      logo_file: '',
      greeting: '',
      mood: '',
      location:[''],
      name : [''],
      is_subscribed_to_email : [0],
      plan : ['']
    });
  }
  SelectPlanComponent_SetBusinessPlan(sIncommingPlan : string)
  {
    this.fUserProfileForm.patchValue({
      plan: sIncommingPlan
    })
    this.onboradingManager.selectPlan(this.onboradingManager.profile.email,sIncommingPlan).then((url:string)=>{
      window.location.href =url;

    });

    }
  CreateAccountCompanyComponent_HandleUpdateProfileResponse = (InComingMessage) => {
    
    if (InComingMessage['errorMessage']) 
    {
      console.error('updateProfile_HandleUpdateProfileResponse: Profile Update Error ==> ', InComingMessage);
    }
    else 
    {
      console.log('CreateAccountCompanyComponent_GetProfileData : Profile Update Response ==> ', InComingMessage);
      this.oRouter.navigateByUrl('/login')
    }
    
  };
  SelectPlanComponent_InitializeContactUsForm()
  {
    this.fContactUsForm = this.oFormBuilder.group(
    {
      name: ['', [Validators.required]],
      user_email: ['' , [Validators.required , Validators.email]],
      message: ['', [Validators.required]],
      subject: ['', [Validators.required]],
    });
  }
  SelectPlanComponent_SelectedEnterprise(oIncommingOption)
  {
    console.log("Franchise Option::",oIncommingOption);
    
    this.sSelectedEnterpriseOption = oIncommingOption.value
  }
  SelectPlanComponent_SendContactUsRequest()
  {
   this.fContactUsForm.patchValue({
     subject: this.sSelectedEnterpriseOption
   })


   this.accountManger.ContactUs(this.sUserEmail,this.fContactUsForm.get('user_email').value,this.fContactUsForm.get('subject').value,this.fContactUsForm.get('message').value,this.fContactUsForm.get('name').value).then(res=>{});
  
  }
  SelectPlanComponent_HandleContactUsResponse = (InComingMessage) => 
  {
    if (JSON.parse(InComingMessage)["errorMessage"]) 
    {
      console.error("SelectPlanComponent_HandleContactUsResponse : Error ==> ", InComingMessage);
    }
    else 
    {
      const oResponse = JSON.parse(InComingMessage);
      console.log("SelectPlanComponent_HandleContactUsResponse : Response ==> ", oResponse);
      this.oSessionService.cSessionService_SetBusinessPlan('enterprise');
      this.oWebSocketService.WebSocket_UnRegisterEvent("contact_us_result", this.SelectPlanComponent_HandleContactUsResponse);
      this.bDisplayContactUsAlertMessage = true;
      setTimeout(() => {this.bDisplayContactUsAlertMessage = false}, 3000);
      this.sAlertMessage = oResponse.message
    }
  }
  SelectPlanComponent__CreateWebSocket() 
  {
    console.log("Login_CreateWebSocket: Call WebSocket_Create");
    this.oWebSocketService.WebSocket_CreateWebSocket();
  }
  
  InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
    
$(document ).ready(function() {
  $("[data-sidebar]").click(function() {
    var sideBarId = $(this).attr("data-sidebar");
    $(".rightSideBar").removeClass("active");
    $(".sidebarOverlay").addClass("active");
    $(sideBarId).addClass("active");
    $("[data-sidebar]").removeClass("active");
    $(this).addClass("active");
  });
  
  
  $(".sidebarOverlay, .closeRightSideBar").click(function() {
    $(".rightSideBar").removeClass("active");
    $(".sidebarOverlay").removeClass("active");
    $("body").removeClass("sidbar_contentslide");
    $("[data-sidebar]").removeClass("active");
  });
  
  $("[data-sidebar2]").click(function() {
    var sideBarId = $(this).attr("data-sidebar2");
    $(".rightSideBarTutorialsVideo").removeClass("active");
    $(".sidebarOverlay2").addClass("active");
    $(sideBarId).addClass("active");
    $("[data-sidebar]").removeClass("active");
    $(this).addClass("active");
  });
  
  
  $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
    $(".rightSideBarTutorialsVideo").removeClass("active");
    $(".sidebarOverlay2").removeClass("active");
    $("body").removeClass("sidbar_contentslide");
    $("[data-sidebar]").removeClass("active");
  });

  // data-custom-select
  $("[data-custom-select]").click(function() {
    $("body").addClass("sidbar_contentslide");
  });
  // end data-custom-select

  
  $(".customAccordionBody").slideUp();
  $(".customAccordion:first-child .customAccordionBody").slideDown();
  $(".customAccordionAction").on("click", function(){
    if($(this).parent(".customAccordion").hasClass("active")) {}
    else {
      $(".customAccordion").removeClass("active");
      $(this).parent(".customAccordion").addClass("active");
      $(".customAccordionBody").slideUp();
      $(this).siblings(".customAccordionBody").slideDown();
    }
  });

// dropdownBtn
  $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
    var dropVal = $(this).text();
    $(this).parent("li").siblings().children().removeClass("active");
    $(this).addClass("active");
    $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
});
// end dropdownBtn

// toggle-class
$("[toggle-class]").on("click", function () {
  var toggleClass = $(this).attr("toggle-class");
  $(toggleClass).toggleClass("active");
});
// end toggle-class

$(".animationBg").mousemove(function(e){
  var amountMovedX = (e.pageX / 6);
  var amountMovedY = (e.pageY / 6);
  $(this).css('background-position', amountMovedX + 'px ' + amountMovedY + 'px');
});
// $(".animationBg").mouseout(function(e){
//   $(this).css('background-position', 'center');
// });


// $(".datepicker").datepicker({
//   dateFormat: 'dd-mm-yy',
// });

$('#flexSwitchCheckChecked').change(function () {
  if ($(this).is(":checked")) {
      $('.price-info').addClass('year_value');
      $('.flexSwitchCheckCheckedMonth').removeClass('active');
      $('.flexSwitchCheckCheckedYear').addClass('active');
  } else {
      $('.price-info').removeClass('year_value');
      $('.flexSwitchCheckCheckedMonth').addClass('active');
      $('.flexSwitchCheckCheckedYear').removeClass('active');
  }
});

$(".flexSwitchCheckCheckedYear").on("click", function () {
  $('.price-info').addClass('year_value');
  $('.flexSwitchCheckCheckedMonth').removeClass('active');
  $('.flexSwitchCheckCheckedYear').addClass('active');

  $("#flexSwitchCheckChecked").prop("checked", true);
  $('.flexSwitchCheckCheckedMonth').removeClass('active');
  $('.flexSwitchCheckCheckedYear').addClass('active');
});
$(".flexSwitchCheckCheckedMonth").on("click", function () {
  $('.price-info').removeClass('year_value');
  $('.flexSwitchCheckCheckedMonth').addClass('active');
  $('.flexSwitchCheckCheckedYear').removeClass('active');

  $("#flexSwitchCheckChecked").prop("checked", false);
  $('.flexSwitchCheckCheckedMonth').addClass('active');
  $('.flexSwitchCheckCheckedYear').removeClass('active');
});


});
    `;
    document.body.appendChild(chatScript);
  }
  test(){
    this.onfocus()
  }
  onfocus(){}

  visibleContact = false;
  placementContact: NzDrawerPlacement = 'right';
  openContact(): void {
    this.visibleContact = true;
  }
  closeContact(): void {
    this.visibleContact = false;
  }

  closePlanModal() {
    if(this.showAsDialogue)
    {
    this.planManager.closePlanDialogue();
    }else
    {
      this.oRouter.navigate(['/login']);
    }
  }
}
