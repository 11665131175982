import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cSessionService } from 'src/app/services/session.service';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { cDataService } from './../../services/data.service';
 

@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss']
})
export class AccountSidebarComponent implements OnInit, OnDestroy  {
  bDisplaySubscriptionToggler : boolean
 
  bLocales: boolean;
  isSubscriptionEnable:boolean = true;
  constructor(private oRouter : Router ,private configManger:ConfigManagerService, private onboarding:OnboardingManagerService,private oDataService : cDataService,  public oSessionService: cSessionService,) { 
    console.log("Router : ==> ",oRouter.url);
   this.isSubscriptionEnable = (this.configManger.metaInfo?.stripe_enabled) ? this.configManger.metaInfo.stripe_enabled : false;
  }
  ngOnInit(): void {
    this.AccountSidebarComponent_LocalesCheck();
    this.oDataService.bDisplaySubscriptionToggler.subscribe(res=>{
      this.bDisplaySubscriptionToggler = res
    })
    console.log("isActive1",  this.oSessionService.isSidebarActive);
    
  }
 
  ngOnDestroy() {
    this.oSessionService.isSidebarActive = true;
  }
  AccountSidebarComponent_LocalesCheck()
  {

    this.bLocales = this.onboarding.profile.localization_enabled;
    
  }

 sidebarBtn(){
   const sideBtn =  document.getElementById('sidebar-left');
  sideBtn.classList.toggle('collapsed');
  
  console.log("hey mubneeb",sideBtn);
  if(this.oSessionService.isSidebarActive){
    this.oSessionService.isSidebarActive = false;
  }
  else{
    
    this.oSessionService.isSidebarActive = true;
  }

  console.log("isActive2",  this.oSessionService.isSidebarActive);
 }
}
