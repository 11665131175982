import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { user, UserList, UserProfile } from "src/app/models/admin";
import { mediaLib } from "src/app/models/media";
import { AdminService } from "src/app/services/dataServices/admin.service";
import { MediaService } from "src/app/services/dataServices/media.service";
import { ErrorService } from "src/app/services/error.service";
import { LoaderService } from "src/app/services/loader.service";
import { cSessionService } from "src/app/services/session.service";
import { ApiURL } from "src/app/shared/conf/api-path";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  constructor(private http: HttpClient, private mediaService: MediaService) { }

  updateBrandDetails = (brand: any): Promise<any> => {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.update, brand)
      .toPromise();
  };

  addBrandMediaLibrary(mediaLibraryId: any, brandId: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.addbrandMediaLib, {
        media_lib_ids: mediaLibraryId,
        brand_id: brandId,
      })
      .toPromise();
  }

  updateFBAccessToken(access_token: string, brand_id: any, page_id: string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.fbAccessToken, {
        access_token,
        brand_id,
        page_id
      })
      .toPromise();
  }
  updateBrandLinkedInAccessToken( data : any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.linkedInAccessToken, data)
      .toPromise();
  }
  updateBrandInstagramAccessToken( data : any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.instagramAccessToken, data)
      .toPromise();
  }
  getFBAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getfbAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  getLinkedInAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getLinkedinAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  getInstagramAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getInstagramAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  deleteInstagramAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deleteInstagramAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  deleteFBAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deletefbAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  deleteLinkedInAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deleteLinkedInAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  updateYouTubeAccessToken(data: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.youTubeAccessToken, data)
      .toPromise();
  }
  getYouTubeAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getyouTubeAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  deleteYouTubeAccessToken(brand_id: any) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deleteyouTubeAccessToken, {
        brand_id,
      })
      .toPromise();
  }
  uploadFontFile(data: any) {
    return new Promise((resolve, reject) => {

      return this.mediaService.
        DataService_BulkUpload(
          data.email,
          data.session_id,
          data.media_lib,
          data.file.name,
          data.content_type,
          data.brandId,
          undefined,
          true
        )
        .then((res: any) => {
          this.mediaService.uploadFontToGoogleStorage(data.file, res.signed_url).then((result: any) => {
            this.addBrandFont(data.brandId, res.fpath).then((response: any) => {
              resolve(res);
            }).catch(err => {
              reject(err);
            })
          }).catch(err => {
            reject(err);
          })
        }).catch(err => {
          reject(err);
        });
    })
  }

  public addBrandFont(brand_id: string, font_url: any) {

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.addBrandFont, {brand_id, font_url})
      .toPromise();

  }
  deleteBrandFont(brand_id: string, font_url: any) {

    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.deleteBrandFont, {brand_id, font_url})
      .toPromise();
  }

  getDefaultFontLists() {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.getAllFont,{})
      .toPromise();
  }

  deleteBrandMediaLibrary(data) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.delete_brand_media_library,data)
      .toPromise();
  }
}

function err(reason: any): PromiseLike<never> {
  throw new Error("Function not implemented.");
}


