<div class="timelineViewOnProntoTop">
    <div class="row download-section">
        
        <div class="col-sm-12 col-md-12 col-lg-3">
            <div class="download-box">
                <figure>
                    <img src="../assets/images/dashboardVideo/d3.png" alt="icon" class="dIcon">
                    <img src="../assets/images/dashboardVideo/d3-active.png" alt="icon" class="dIcon-active">
                </figure>
                <h4 class="text-xxl weight-400">Standard Video</h4>
                <div class="text-wrap">
                    <p class="text-xs text-gray-3 mb-0">
                        Lower quality than HD and 4K, but
                        renders a smaller file size ideal for
                        sending as an attachment.
                    </p>
                </div>
                <ul class="pb-1">
                    <li>960 x 540 
                        <a href="javascript:void(0)" (click)="TimelineViewProntoComponent_DownloadVideo()">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.99821 0.104248C7.72536 0.104164 7.50411 0.325287 7.50404 0.598141L7.50215 7.99217L4.3839 4.56214C4.20036 4.36025 3.90278 4.36025 3.71924 4.56214C3.53569 4.76404 3.53569 5.09137 3.71924 5.29327L7.65712 9.62488C7.84066 9.82678 8.13824 9.82678 8.32179 9.62488L12.2597 5.29327C12.4432 5.09137 12.4432 4.76404 12.2597 4.56214C12.0761 4.36025 11.7785 4.36025 11.595 4.56214L8.49024 7.97733L8.49213 0.598447C8.4922 0.325593 8.27107 0.104333 7.99821 0.104248Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M1.09161 7.21927C1.09161 6.94642 0.870415 6.72522 0.597561 6.72522C0.324707 6.72522 0.103516 6.94642 0.103516 7.21927V8.45428C0.103516 12.5471 3.4214 15.865 7.5142 15.865H8.50426C12.5971 15.865 15.9149 12.5471 15.9149 8.45428V7.21927C15.9149 6.94642 15.6938 6.72522 15.4209 6.72522C15.148 6.72522 14.9269 6.94642 14.9269 7.21927V8.45428C14.9269 12.0014 12.0514 14.8769 8.50426 14.8769H7.5142C3.9671 14.8769 1.09161 12.0014 1.09161 8.45428V7.21927Z"
                                    fill="#FF8369"></path>
                            </svg>
                        </a>
                    </li>
                  
                </ul>
                <button class="btn btn-secondary btn-round-6 d-inline-block " style="opacity: 1;"
                (click)="TimelineViewProntoComponent_DownloadVideo()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.99821 0.104248C7.72536 0.104164 7.50411 0.325287 7.50404 0.598141L7.50215 7.99217L4.3839 4.56214C4.20036 4.36025 3.90278 4.36025 3.71924 4.56214C3.53569 4.76404 3.53569 5.09137 3.71924 5.29327L7.65712 9.62488C7.84066 9.82678 8.13824 9.82678 8.32179 9.62488L12.2597 5.29327C12.4432 5.09137 12.4432 4.76404 12.2597 4.56214C12.0761 4.36025 11.7785 4.36025 11.595 4.56214L8.49024 7.97733L8.49213 0.598447C8.4922 0.325593 8.27107 0.104333 7.99821 0.104248Z"
                            fill="#FF8369"></path> 
                        <path
                            d="M1.09161 7.21927C1.09161 6.94642 0.870415 6.72522 0.597561 6.72522C0.324707 6.72522 0.103516 6.94642 0.103516 7.21927V8.45428C0.103516 12.5471 3.4214 15.865 7.5142 15.865H8.50426C12.5971 15.865 15.9149 12.5471 15.9149 8.45428V7.21927C15.9149 6.94642 15.6938 6.72522 15.4209 6.72522C15.148 6.72522 14.9269 6.94642 14.9269 7.21927V8.45428C14.9269 12.0014 12.0514 14.8769 8.50426 14.8769H7.5142C3.9671 14.8769 1.09161 12.0014 1.09161 8.45428V7.21927Z"
                            fill="#FF8369"></path>
                    </svg>Download All</button>
            </div>
        </div>
        
    </div>
</div>