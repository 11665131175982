<app-header></app-header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <form>
            <div class="container">
                <ul class="breadcrumb my-3">
                    <li><a href="javascript:void(0)">Business Profile</a></li>
                    <li>Brand Identity</li>
                </ul>
                <div class=" br12 bg-white page-shadow curve-bg h-80vh pb-0 mb-5">
                    <div class="py-4 px-4 account-heading">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="weight-700 text-gray-2">Business Profile</h3>
                                <p class="mx-0 my-0 text-sm">A complete profile will help us to understand your business
                                    and provide you with the best videos.</p>
                            </div>

                        </div>
                    </div>
                    <div class="accountSecInner">
                        <div class="accountSecNav py-5">
                            <app-business-sidebar></app-business-sidebar>

                        </div>
                        <div class="accountSecContent py-5 pe-4">

                            <div class="video-header row m-0 px-2">
                                <p class="text-lg text-gray-2 pb-1 px-0 col-lg-6 mb-0">
                                    <a routerLink="/business-profile/enterprise-media" class="me-2">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                                fill="#404243"></path>
                                        </svg>
                                    </a>
                                    Media Library A
                                </p>
                                <ul class="text-end d-block col-lg-6 px-0 mb-0">
                                    <li class="pe-4 text-sm">
                                        <a href="javascript:void(0)" class="text-gray-3" data-sidebar="#multiselectmedia"
                                            data-custom-select>Select Media</a>
                                    </li>
                                    <li>
                                        <button class="btn weight-600 text-xs btn btn-primary btn-round-6 px-3 py-2"
                                            data-bs-toggle="modal" data-bs-target="#uploadModal"><svg class="me-1"
                                                width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                                                        fill="#4F4F4F"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="16.0013" height="15.9809" fill="white"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Upload Your Media1

                                        </button>

                                    </li>
                                </ul>

                            </div>
                           <app-media-library-shared></app-media-library-shared>
                        </div>
                        <div class="wtermarklogo">
                            <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </section>
    <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Upload a Video or Audio File</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="form-group files">


                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                                aria-labelledby="pills-device-tab">

                                <div class="file-drop-area">
                                    <figure>
                                        <svg width="23" viewBox="0 0 17 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                    fill="#7A7A7A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"
                                                        transform="translate(0 0.00958252)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Device)</span>
                                    <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                        drag & drop your file here</span>
                                    <span class="file-message">No file selected</span> <input class="file-input"
                                        type="file" multiple>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="pills-drive" role="tabpanel"
                                aria-labelledby="pills-drive-tab">
                                <div class="file-drop-area">
                                    <figure>
                                        <svg width="23"  viewBox="0 0 17 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                    fill="#7A7A7A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"
                                                        transform="translate(0 0.00958252)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Drive)</span>
                                    <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                        drag & drop your file here</span>
                                    <span class="file-message">No file selected</span> <input class="file-input"
                                        type="file" multiple>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-facebook" role="tabpanel"
                                aria-labelledby="pills-facebook-tab">
                                <div class="file-drop-area">
                                    <figure>
                                        <svg width="23"  viewBox="0 0 17 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                    fill="#7A7A7A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"
                                                        transform="translate(0 0.00958252)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Facebook)</span>
                                    <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                        drag & drop your file here</span>
                                    <span class="file-message">No file selected</span> <input class="file-input"
                                        type="file" multiple>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-dropbox" role="tabpanel"
                                aria-labelledby="pills-dropbox-tab">
                                <div class="file-drop-area">
                                    <figure>
                                        <svg width="23" viewBox="0 0 17 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                    fill="#7A7A7A" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16.0013" height="15.9809" fill="white"
                                                        transform="translate(0 0.00958252)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </figure>
                                    <span class="choose-file-button">Upload a File (Dropbox)</span>
                                    <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                        drag & drop your file here</span>
                                    <span class="file-message">No file selected</span> <input class="file-input"
                                        type="file" multiple>
                                </div>

                            </div>

                            <ul class="nav mb-3" id="pills-tab" role="tablist">
                                <li class="col nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-device-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-device" type="button" role="tab"
                                        aria-controls="pills-device" aria-selected="true">
                                        <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                        <span>My Device</span></button>
                                </li>
                                <li class="col nav-item" role="presentation">
                                    <button class="nav-link" id="pills-drive-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-drive" type="button" role="tab"
                                        aria-controls="pills-drive" aria-selected="false">
                                        <img src="../assets/images/business_Profile/drive.png" alt="tab-icon">
                                        <span>Google Drive</span></button>
                                </li>
                                <li class="col nav-item" role="presentation">
                                    <button class="nav-link" id="pills-facebook-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-facebook" type="button" role="tab"
                                        aria-controls="pills-facebook" aria-selected="false">
                                        <img src="../assets/images/business_Profile/facebook.png" alt="tab-icon">
                                        <span>Facebook</span></button>
                                </li>
                                <li class="col nav-item" role="presentation">
                                    <button class="nav-link" id="pills-dropbox-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-dropbox" type="button" role="tab"
                                        aria-controls="pills-dropbox" aria-selected="false">
                                        <img src="../assets/images/business_Profile/dropbox.png" alt="tab-icon">
                                        <span>Dropbox</span></button>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Select Media  sidebar Modal Start-->
    <nav id="selectmedia" class="rightSideBar right-0 sidebar-block">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700">Customer_Success.png</h6>
            <a href="javascript:void(0)" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
            <div class="closeRightSideBar">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                            fill="#7A7A7A" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="11.8" height="11.8" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>

        <div class="sidebar-content">
            <div class="seleted-media mb-3">
                <img src="../assets/images/business_Profile/img-sel.png" alt="sel">
            </div>

            <ul class="media-tags">
                <p class="text-gray-2 text-lg mb-2">Keywords</p>
                <li>High Temp<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Growth<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Success<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Growth<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Success<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>High Temp<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li class="d-block">
                    <a href="javascript:void(0)" class="text-primary-3 text-md"><svg class="me-1" width="12" height="12"
                            viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                fill="#FF8369" />
                        </svg>
                        Add a Keyword</a>
                </li>
            </ul>

            <ul class="used-in my-4 px-0 py-4 border-bottom border-top">
                <p class="text-gray-2 text-lg mb-3">Used In</p>
                <li class="d-block text-gray-2 text-xs">
                    <figure><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.666626 7.08372C0.666626 7.60276 1.04408 7.81531 1.50557 7.55635L6.98715 4.47245C7.44867 4.21293 7.44867 3.78785 6.98715 3.52833L1.50557 0.444132C1.04405 0.184609 0.666626 0.396868 0.666626 0.916767V7.08372Z"
                                fill="#2B2F32" />
                        </svg>
                    </figure>Introduction123.mov
                </li>
                <li class="d-block text-gray-2 text-xs">
                    <figure><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.666626 7.08372C0.666626 7.60276 1.04408 7.81531 1.50557 7.55635L6.98715 4.47245C7.44867 4.21293 7.44867 3.78785 6.98715 3.52833L1.50557 0.444132C1.04405 0.184609 0.666626 0.396868 0.666626 0.916767V7.08372Z"
                                fill="#2B2F32" />
                        </svg>
                    </figure>Promotional_event2.mov
                </li>
            </ul>

            <div class="m-0">
                <p class="text-gray-2 text-lg mb-3">Media Info</p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs">Media Library</span>
                    <span class="text-gray-2 text-xs weight-600 text-end">Library A</span>
                </p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs ">Published on</span>
                    <span class="text-gray-2 text-xs weight-600 text-end">June 23, 2021</span>
                </p>
                <p class="d-flex cruunt-plan">
                    <span class="text-gray-2 text-xs ">Image Size</span>
                    <span class="text-gray-2 text-xs weight-600 text-end">160.01 MB</span>
                </p>
            </div>
        </div>

    </nav>
    <!-- Select Media sidebar Modal end-->

    <!-- Multi Select media  sidebar Modal Start-->
    <nav id="multiselectmedia" class="rightSideBar right-0 sidebar-block">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700">4 Items Selected</h6>
            <a href="javascript:void(0)" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
            <div class="closeRightSideBar">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                            fill="#7A7A7A" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="11.8" height="11.8" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>

        <div class="sidebar-content">
            <div class="seleted-media mb-4">
                <img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                <div class="autoplay seleted-media-slider mt-3">
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                    <div class="slider-content"><img src="../assets/images/business_Profile/img-sel.png" alt="sel">
                        <a href="javascript:void(0)" class="close-remove">
                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.82983 2.99897L5.62996 1.19946C5.85694 0.972567 5.85694 0.597017 5.62996 0.370122C5.40299 0.143227 5.02731 0.143227 4.80034 0.370122L3.0002 2.16963L1.20006 0.370122C0.973081 0.143227 0.5974 0.143227 0.370426 0.370122C0.143452 0.597017 0.143452 0.972567 0.370426 1.19946L2.17057 2.99897L0.370426 4.79848C0.143452 5.02538 0.143452 5.40093 0.370426 5.62782C0.487826 5.74518 0.636533 5.79995 0.785241 5.79995C0.933948 5.79995 1.08265 5.74518 1.20006 5.62782L3.0002 3.82831L4.80034 5.62782C4.91774 5.74518 5.06644 5.79995 5.21515 5.79995C5.36386 5.79995 5.51256 5.74518 5.62996 5.62782C5.85694 5.40093 5.85694 5.02538 5.62996 4.79848L3.82983 2.99897Z"
                                    fill="white" />
                            </svg>

                        </a>
                    </div>
                </div>
            </div>

            <ul class="media-tags">
                <p class="text-gray-2 text-lg mb-2">Keywords</p>
                <li>High Temp<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Growth<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Success<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Growth<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>Success<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li>High Temp<a href=""><svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.273304 7.86667C0.203312 7.86667 0.136652 7.84 0.0833245 7.79C-0.0199979 7.68667 -0.0199979 7.51667 0.0833245 7.41333L3.55962 3.93333L0.0766585 0.453333C0.0266638 0.403333 0 0.336667 0 0.266667C0 0.196667 0.0266638 0.13 0.0766585 0.08C0.129986 0.0266667 0.196646 0 0.266638 0C0.336631 0 0.403291 0.0266667 0.456618 0.0766667L3.93625 3.55667L7.41255 0.0766667C7.46254 0.0266667 7.5292 0 7.5992 0C7.66919 0 7.73585 0.0266667 7.78918 0.0766667C7.8925 0.18 7.8925 0.35 7.78918 0.453333L4.31288 3.93333L7.78584 7.40667C7.83584 7.45667 7.8625 7.52333 7.8625 7.59333C7.8625 7.66333 7.83584 7.73 7.78584 7.78333C7.73585 7.83667 7.66919 7.86 7.59586 7.86C7.52254 7.86 7.45921 7.83333 7.40588 7.78333L3.93625 4.31L0.459951 7.79C0.409957 7.84 0.343297 7.86667 0.273304 7.86667Z"
                                fill="#7A7A7A" />
                        </svg></a></li>
                <li class="d-block">
                    <a href="javascript:void(0)" class="text-primary-3 text-md"><svg class="me-1" width="12" height="12"
                            viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                fill="#FF8369" />
                        </svg>
                        Add a Keyword</a>
                </li>
            </ul>

        </div>

    </nav>
    <!-- Multi Select media sidebar Modal end-->

    <!-- Media upload  sidebar Modal Start-->
    <nav id="uploadmedia" class="rightSideBar right-0 sidebar-block">
        <div class="sidebar-header">
            <h6 class="text-gray-2 weight-700">Uploading 6/7 items</h6>
            <a href="javascript:void(0)" class="del-media" data-bs-toggle="modal" data-bs-target="#delMedia-popup">
                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.9809 7.60102C15.214 7.83249 15.592 7.83249 15.8251 7.60102C16.0583 7.36954 16.0583 6.99425 15.8251 6.76277L9.73081 0.711821C8.77491 -0.237272 7.22509 -0.237275 6.26919 0.71182L0.174851 6.76277C-0.0582836 6.99425 -0.0582836 7.36954 0.174851 7.60102C0.407986 7.83249 0.785971 7.83249 1.01911 7.60102L7.11345 1.55006C7.60308 1.06392 8.39692 1.06392 8.88655 1.55007L14.9809 7.60102Z"
                        fill="#7A7A7A" />
                </svg>
            </a>
            <div class="closeRightSideBar">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                            fill="#7A7A7A" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="11.8" height="11.8" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>

        <div class="sidebar-content">
            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-success btn"><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-success btn "><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-success btn"><svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.80667 0.854745C9.08816 0.595654 9.52906 0.605007 9.79875 0.877757C10.0767 1.15884 10.0649 1.60943 9.7749 1.87635L4.05049 7.14517C3.77781 7.39615 3.35428 7.39598 3.08181 7.14475L0.224653 4.51034C-0.0651365 4.24314 -0.0764627 3.79254 0.201696 3.51172C0.471612 3.23923 0.912515 3.23022 1.19379 3.48957L3.56681 5.67758L8.80667 0.854745ZM0.789687 3.89782L0.789544 3.89769L0.789687 3.89782Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-danger btn"><svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.34163 9.83333C0.25414 9.83333 0.170815 9.8 0.104156 9.7375C-0.0249974 9.60833 -0.0249974 9.39583 0.104156 9.26667L4.44953 4.91667L0.0958232 0.566667C0.0333298 0.504167 0 0.420833 0 0.333333C0 0.245833 0.0333298 0.1625 0.0958232 0.1C0.162483 0.0333333 0.245807 0 0.333298 0C0.420789 0 0.504113 0.0333333 0.570773 0.0958333L4.92031 4.44583L9.26569 0.0958333C9.32818 0.0333333 9.4115 0 9.49899 0C9.58648 0 9.66981 0.0333333 9.73647 0.0958333C9.86562 0.225 9.86562 0.4375 9.73647 0.566667L5.3911 4.91667L9.7323 9.25833C9.7948 9.32083 9.82813 9.40417 9.82813 9.49167C9.82813 9.57917 9.7948 9.6625 9.7323 9.72917C9.66981 9.79583 9.58648 9.825 9.49483 9.825C9.40317 9.825 9.32401 9.79167 9.25735 9.72917L4.92031 5.3875L0.574939 9.7375C0.512446 9.8 0.429121 9.83333 0.34163 9.83333Z"
                            fill="white" />
                    </svg>
                </button>
            </div>

            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-processing btn">
                </button>
            </div>

            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-processing btn">
                </button>
            </div>

            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-danger btn"><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.6752 9.89998C9.6752 10.269 9.3692 10.575 9.0002 10.575C8.6312 10.575 8.3252 10.269 8.3252 9.89998V5.39998C8.3252 5.03098 8.6312 4.72498 9.0002 4.72498C9.3692 4.72498 9.6752 5.03098 9.6752 5.39998V9.89998ZM9.6391 11.961C9.7201 12.051 9.7831 12.15 9.8281 12.258C9.8731 12.366 9.9001 12.483 9.9001 12.6C9.9001 12.717 9.8731 12.834 9.8281 12.942C9.7831 13.059 9.7201 13.149 9.6391 13.239C9.5491 13.32 9.4501 13.383 9.3421 13.428C9.2341 13.473 9.1171 13.5 9.0001 13.5C8.8831 13.5 8.7661 13.473 8.6581 13.428C8.5501 13.383 8.4511 13.32 8.3611 13.239C8.2801 13.149 8.2171 13.059 8.1721 12.942C8.1271 12.834 8.1001 12.717 8.1001 12.6C8.1001 12.483 8.1271 12.366 8.1721 12.258C8.2171 12.15 8.2801 12.051 8.3611 11.961C8.4511 11.88 8.5501 11.817 8.6581 11.772C8.8741 11.682 9.1261 11.682 9.3421 11.772C9.4501 11.817 9.5491 11.88 9.6391 11.961Z"
                            fill="white" />
                    </svg>
                </button>
            </div>

            <div class="up-media">
                <div class="media-type">
                    <img src="../assets/images/business_Profile/upload-img.png" alt="1">
                </div>
                <p class="media-title">Customer_success.png</p>
                <span class="file-size">21kb</span>
                <button class="btn-danger btn"><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.6752 9.89998C9.6752 10.269 9.3692 10.575 9.0002 10.575C8.6312 10.575 8.3252 10.269 8.3252 9.89998V5.39998C8.3252 5.03098 8.6312 4.72498 9.0002 4.72498C9.3692 4.72498 9.6752 5.03098 9.6752 5.39998V9.89998ZM9.6391 11.961C9.7201 12.051 9.7831 12.15 9.8281 12.258C9.8731 12.366 9.9001 12.483 9.9001 12.6C9.9001 12.717 9.8731 12.834 9.8281 12.942C9.7831 13.059 9.7201 13.149 9.6391 13.239C9.5491 13.32 9.4501 13.383 9.3421 13.428C9.2341 13.473 9.1171 13.5 9.0001 13.5C8.8831 13.5 8.7661 13.473 8.6581 13.428C8.5501 13.383 8.4511 13.32 8.3611 13.239C8.2801 13.149 8.2171 13.059 8.1721 12.942C8.1271 12.834 8.1001 12.717 8.1001 12.6C8.1001 12.483 8.1271 12.366 8.1721 12.258C8.2171 12.15 8.2801 12.051 8.3611 11.961C8.4511 11.88 8.5501 11.817 8.6581 11.772C8.8741 11.682 9.1261 11.682 9.3421 11.772C9.4501 11.817 9.5491 11.88 9.6391 11.961Z"
                            fill="white" />
                    </svg>
                </button>
            </div>

        </div>
        <ul class="sideNav-btn px-3">
            <a href="" class="weight-normal text-primary-4 text-sm py-3">Cancel Upload</a>
        </ul>
    </nav>
    <!-- media upload sidebar Modal end-->

    <!-- Modal pop-up Start-->
    <div class="modal fade" id="delMedia-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body">
                    <button type="button" class="btn-close cust-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    <h5>Are you sure you want to delete the selected media files?</h5>
                    <p class="text-sm text-gray-3 pt-1 pb-4">The files will be permanently deleted. You can't undo this
                        action.</p>
                    <a href="javascript:void(0)" id="sidebarCollapse" class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        data-bs-dismiss="modal">Cancel</a>
                    <a class="mt-3 text-gray-3 text-sm">Delete</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->
    <div class="overlay sidebarOverlay"></div>
    <script>
        $(function ($) {
            $.autofilter();
        });
    </script>

    <script>
        $(document).on('change', '.file-input', function () {
            var filesCount = $(this)[0].files.length;

            var textbox = $(this).prev();

            if (filesCount === 1) {
                var fileName = $(this).val().split('\\').pop();
                textbox.text(fileName);
            } else {
                textbox.text(filesCount + ' files selected');
            }
        });

        $('.autoplay').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        });
    </script>