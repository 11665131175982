import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cDataService } from './../../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cIntercomService } from './../../../services/intercom.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ErrorService } from './../../../services/error.service';
declare var $ : any
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit 
{
  sSessionId : string;
  sUserEmail : string;
  sManageBillingURL : string;
  sStripePublicKey : string;
  stripe : any;
  bDisplayPassword: boolean;
  public fCancelSubscriptionForm: FormGroup;
  sDisplayCancelSubscriptionErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  bStripeIntegrationSuccess : boolean = false;
  bStripeIntegrationFailed : boolean = false;
  fContactUsForm : FormGroup;
  sSelectedEnterpriseOption: any = "Enterprise-pricing";
  lEnterPriseOptions : any = [
    {name: 'Enterprise Pricing' , value: 'enterprise-pricing'},
    {name: 'Enterprise Other' , value: 'enterprise-other'}
  ]
  sAlertMessage: any;
  bDisplayAlertMessage: boolean = false;
  sSeletecUserPlan: any;
  sUserPlan: any;
  nUserVideoCount: any;
  messageHere = "message"
  bDisplayContactUsAlertMessage: boolean;
  constructor(
    private oSessionService: cSessionService, 
    private oWebSocketService: cWebSocketService, 
    private oRouter: Router, 
    private oDataService : cDataService ,
    private oFormBuilder :FormBuilder,
    private oActivatedRoute : ActivatedRoute,
    public accountManager : AccountManagerService,
    public onboardingManager : OnboardingManagerService,
    private oIntercomService : cIntercomService,
   public errorService:ErrorService
    ) 
    {
    this.oDataService.bDisplaySubscriptionToggler.next(true)
    }

  ngOnInit(): void 
  {
    
    this.sSessionId = this.onboardingManager.loggedUser.session_id;
    this.sUserEmail = this.onboardingManager.loggedUser.email;
    this.SubscriptionComponent_InitializeCancelSubscriptionForm();
    this.SubscriptionComponent_InitializeContactUsForm();


    setTimeout(()=>{this.messageHere = " "},1000);
    const sSubscriptionResult = this.oActivatedRoute.snapshot.queryParams['result'];
    console.log("SubscriptionComponent ngonInit :  Stripe Subscription Result ==> ",sSubscriptionResult);
    if(sSubscriptionResult)
    {
      if(sSubscriptionResult === 'success'){
        this.bStripeIntegrationSuccess = true;
        setTimeout(()=>{this.bStripeIntegrationSuccess = false},5000);
      }
      else
      {
        this.bStripeIntegrationFailed = true;
        setTimeout(()=>{  this.bStripeIntegrationFailed = false},5000);
      }
    }

    //Todo Stripe configuration
    this.SubscriptionComponent_SendStripeConfigurationRequest();

   
    //Todo: user count need to be updated
    this.nUserVideoCount = this.oSessionService.cSessionService_GetUserVideosCount()
    this.oSessionService.setAccountSettingHeaderName("Subscription");

  }
 
  SubscriptionComponent_InitializeContactUsForm()
  {
    this.fContactUsForm = this.oFormBuilder.group(
    {
      name: [null, [Validators.required]],
      user_email: [null , [Validators.required]],
      message: [null, [Validators.required]],
      subject: [this.sSelectedEnterpriseOption, [Validators.required]],

    });
  }
  SubscriptionComponent_SelectedEnterprise(oIncommingOption)
  {
    console.log("Franchise Option::",oIncommingOption);
    this.fContactUsForm.get('subject').setValue(oIncommingOption);
    this.sSelectedEnterpriseOption = oIncommingOption.value
  }
  SubscriptionComponent_SendContactUsRequest()
  {
    this.visibleContact = false;
console.log("contact us", this.fContactUsForm)

this.accountManager.ContactUs(this.sUserEmail,this.fContactUsForm.get('user_email').value,this.sSelectedEnterpriseOption,this.fContactUsForm.get('message').value,this.fContactUsForm.get('name').value).then(res=>
  {
     
     
    this.onboardingManager.profile.plan ='enterprise';
 
    this.bDisplayContactUsAlertMessage = true;
    setTimeout(() => {this.bDisplayContactUsAlertMessage = false}, 3000);
    this.sAlertMessage = res['message'];



  }).catch(err=>
    {this.errorService.handleError(err.errorMessage,err.errorType,true);
      console.error(" ContactUsResponse : ", err);
    });


   
  }
 
  SubscriptionComponent_TogglePasswordVisibility()
  {
    this.bDisplayPassword = !this.bDisplayPassword
  }
  SubscriptionComponent_InitializeCancelSubscriptionForm()
  {
 
    this.fCancelSubscriptionForm = this.oFormBuilder.group({
      reason: ['',Validators.required],
      message:['',Validators.required],
      password :['',Validators.required],
      session_id: this.sSessionId,
      email: this.sUserEmail
    });
  }
  SubscriptionComponent_GenerateDummyUID() 
  {
    var dCurrentDate = new Date().getTime();
    const sUserID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) 
    {
      var randomNumber = (dCurrentDate + Math.random() * 16) % 16 | 0;
      dCurrentDate = Math.floor(dCurrentDate / 16);
      return (c == 'x' ? randomNumber : (randomNumber & 0x3 | 0x8)).toString(16);
    });
    return sUserID;
  }
  // stripe configuration event
  SubscriptionComponent_SendStripeConfigurationRequest()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('stripe_config_result', this.SubscriptionComponent_HandleStripeConfigResponse);
    this.oWebSocketService.WebSocket_SendMessage('stripe_config_request', JSON.stringify('dummy'));
  }
  SubscriptionComponent_HandleStripeConfigResponse = (InComingMessage) =>
  {
    console.log("SubscriptionComponent_HandleStripeConfigResponse :  Stripe Configuration Result => ", InComingMessage);
    if(JSON.parse(InComingMessage)['errorMessage'])
    {
      console.error("SubscriptionComponent_HandleStripeConfigResponse :  Stripe Configuration Error => ", InComingMessage);
    }
    else
    {
      this.sStripePublicKey = JSON.parse(InComingMessage).public_key;
    }
  }



  SubscriptionComponent_SendSubscriptionRequest(plan)
  {
    this.closeUpgrade();


    this.manageBilling();
    }
 
  SubscriptionComponent_SendCancelSubscriptionReasonRequest()
  {
this.closeSubscriptionCancle();
  if(this.fCancelSubscriptionForm.valid)
  {
this.accountManager.cancelReason(this.sUserEmail,this.sSessionId,this.fCancelSubscriptionForm.get('password').value,this.fCancelSubscriptionForm.get('reason').value,this.fCancelSubscriptionForm.get('message').value).then(res=>
  {
   this.SubscriptionComponent_SendManageCancelSubscriptionRequest();
  })
  .catch(err=>
    { 
      this.errorService.handleError(err.errorMessage,err.errorType,true);
      console.log("sub error", this.sDisplayCancelSubscriptionErrorMessage);
     
    });

  }else
  {
    this.fCancelSubscriptionForm.markAsTouched();
  }
  }
  
  SubscriptionComponent_SendManageCancelSubscriptionRequest()
  {
    this.accountManager.cancelSubscription(this.sUserEmail,this.sSessionId).then(res=>
      {
        localStorage.clear();
      
        this.oIntercomService.cIntercomService_RemoveLoggedInUserIntercomCredentials();
        this.oRouter.navigate(["/login"]);
      }).catch(err=>
        {
          this.errorService.handleError(err,'Error',true);
        });
   
  }

 

  SubscriptionComponent_DisplaySubscriptionModal()
  {
    console.log("working");

    this.manageBilling();
  }

 

  visibleUpgrade = false;
  placementUpgrade: NzDrawerPlacement = 'right';
  private manageBilling() {
    this.accountManager.ManageBilling(this.onboardingManager.loggedUser.email, this.onboardingManager.loggedUser.session_id).then((url: string) => {
      window.location.href = url;
    });
  }

  openUpgrade(): void {
    this.manageBilling();
  }
  closeUpgrade(): void {
    this.visibleUpgrade = false;
  }

  
  visibleContact = false;
  placementContact: NzDrawerPlacement = 'right';
  openContact(): void {
    this.visibleContact = true;
  }
  closeContact(): void {
    this.visibleContact = false;
  }

  visibleSubscriptionCancle = false;
  placementSubscriptionCancle: NzDrawerPlacement = 'right';
  openSubscriptionCancle(): void {
    this.visibleSubscriptionCancle = true;
  }
  closeSubscriptionCancle(): void {
    this.visibleSubscriptionCancle = false;
    
  }


}