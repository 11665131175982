<div class="modal-dialog m-0">
    <div class="modal-content">
        <div class="modal-header">
            <div class="closeRightSideBar" (click)="ScheduledVideoComponent_ResetView()" (click)="handleCancel()" style="top: 0px;
            right: 5px;">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                    <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                </svg>
            </div>
        </div>
        <div class="modal-body px-0 pt-0">
            <div class="border-bottom bg-gray-9 pt-4 px-40 autoCreateModalTop">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="modal-title text-gray-2 mb-3">Auto-Create and Publish</h3>
                        <h5 class="mb-3">
                            Prontopia  can create videos and publish them to all your connected social channels
                            automatically.
                        </h5>
                        <p class="text-sm text-gray-3">
                            Schedule a video to be auto-created by Pronto's smart Al and published every week, month, or
                            even year. You can also add multiple messages to test when posting your video to try out multiple
                            visual and content versions of your creation.                        </p>

                    </div>
                    <div class="col-md-6 text-center">
                        <img class="img-fluid" src="../assets/images/videoTimeline/autoCreateModalImg.png"
                            alt="autoCreateModalImg" />
                    </div>
                </div>
            </div>

            <div class="pt-40 px-40" style="overflow-y: auto;
            height: 470px;">
                <!-- Alert for Disconnect Social channel Error Message start -->
                <div *ngIf="bDisplayErrorMessage" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show"
                role="alert">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                        fill="#842029" />
                </svg>
                <div>
                    {{sDisplayErrorMessage}}
                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                        aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
                </div>
                <!-- Alert for Disconnect Social channel Error Message End -->

                <!-- Alert For Stripe subscription Success start-->
                <div *ngIf="bDisplaySuccesMessage"
                class=" alert alert-success d-flex align-items-center alert-dismissible fade show"
                style="margin: 15px " role="alert">
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 0C6.26801 0 0 6.26801 0 14V15C0 22.732 6.26801 29 14 29V29C21.732 29 28 22.732 28 15V14C28 6.26801 21.732 0 14 0V0Z"
                                    fill="#00C599" />
                                <g >
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M21.0045 9.94163C21.178 10.1234 21.1713 10.4112 20.9894 10.5846L12.0848 19.0732C11.9088 19.2409 11.632 19.2408 11.4562 19.0729L7.01176 14.8286C6.83006 14.6551 6.82353 14.3672 6.99718 14.1857C7.17082 14.0041 7.45889 13.9976 7.64059 14.1711L11.7709 18.1154L20.3611 9.92649C20.543 9.75313 20.8311 9.75991 21.0045 9.94163Z"
                                        fill="#00C599" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.9219 9.43247C20.3598 9.01505 21.0456 9.03012 21.4652 9.46955C21.8975 9.9224 21.8792 10.6484 21.4281 11.0784L12.5234 19.5671C12.0992 19.9714 11.4404 19.9711 11.0166 19.5664L6.57212 15.322C6.12133 14.8916 6.10371 14.1656 6.53641 13.7132C6.95627 13.2741 7.64212 13.2596 8.07967 13.6775L11.771 17.2026L19.9219 9.43247ZM7.45106 14.3352L7.45084 14.335Z"
                                        fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="15.5556" height="16.1111" fill="white"
                                            transform="translate(6.22266 6.44434)" />
                                    </clipPath>
                                </defs>
                            </svg>
                <div>
                    Video scheduled successfully.
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg
                            width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Alert For Stripe subscription Success End-->

                <p class="text-sm text-gray-3">
                    How often should Prontopia  auto-create and publish new videos?                </p>
                <div class="dropdown dropdownBtn selectionDurationDropDown">
                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="dropdownBtnText">{{this.selectduration | titlecase}}</span>
                    </button>
                    <ul class="dropdown-menu dropdownMenu">
                        <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectAutomateInterval('weekly')">Every Week</a></li>
                        <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectAutomateInterval('monthly')">Once a Month</a></li>
                      <!--  <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectAutomateInterval('annually')">Every Year</a></li>-->
                        <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectAutomateInterval('once')">On Specific Dates</a></li>
                    </ul>
                </div>
                <div class="pt-3" style="min-height: 330px">
                    <div   [hidden]="!(this.selectduration === 'weekly')"  >
                        <p class="text-sm text-gray-3">
                            Which days of the week and time of day should a new video be published?                        </p>
                        <div class="row">
                                    <div class="radio_select_wrapp pt-1 me-5 col-12">
                                        <label class="radio_select" *ngFor="let day of lSelectedWeekDay"> </label></div>
                                        <div class="d-flex">
                                    <div class="radio_select_wrapp pt-1 me-5">
                                        <label class="radio_select" *ngFor="let day of this.lSelectedWeekDay">
                                            <input type="checkbox" name="durationOption" [checked]="day.checked" (change)="ScheduledPopupComponent_lSelectedWeekDayValue(day.value)"
                                                class="radio_input_element">
                                            <div class="radio_caption"><span>{{day.name}}</span></div>
                                        </label>
                                    </div>
                                    <div class="dropdown dropdownBtn">
                                        <button class="dropdown-toggle text-md" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="dropdownBtnText">{{this.timeRange | titlecase}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu">
                                            <li><a class="dropdown-item text-md active" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('morning')">Morning</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('afternoon')">Afternoon</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('evening')">Evening</a></li>
                                        </ul>
                                    </div>
                                    </div>


                        </div>
                        </div>
                    <div   [hidden]="!(this.selectduration === 'monthly')"  >
                        <p class="text-sm text-gray-3">
                            Select which months of the year you want a new video to be published                        </p>
                        <div class="row">

                                    <div class="radio_select_wrapp pt-1 me-5 col-12">
                                        <label class="radio_select" *ngFor="let month of lSelectedMonth">
                                            <input type="checkbox" name="durationOptionMonth" [checked]="month.checked" (change)="ScheduledPopupComponent_lSelectedMonth(month.name)"
                                                class="radio_input_element">
                                            <div class="radio_caption month-name"><span>{{month.name}}</span></div>
                                        </label>
                                    </div>
                                <div class="col-12">
                                    <p class="text-sm text-gray-3">
                                        Which day of each month and time of day should a new video be published?                                    </p>
                                </div>

                                    <div class="dropdown dropdownBtn me-4 col-md-2">
                                        <label class="dropdown-toggle" (click)="ScheduledVideoComponent_DisplayDaysDropdown()">
                                             <span style="font-size: 14px;">Select day</span>
                                           <!---  <input type="text" class="p-0 datepickermonthly text-md border-0" [(ngModel)]="this.SelectDate" placeholder="Select Date" (focusout)="ScheduledVideoComponent_SelectDate($event)" /> -->
                                        </label>
                                        <div *ngIf="bToggleDaysDropdown" class="box shadow radio_select_wrapp pt-1 me-5 select-date">
                                            <label class="radio_select" *ngFor="let day of automateMonthlyDays">
                                                <input type="checkbox" name="durationOptionMonthDays" class="radio_input_element" [checked]="day.checked" (change)="ScheduledPopupComponent_lSelectedDays(day.name)">
                                                <div class="radio_caption day-name">
                                                    <span>{{day.name}}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="dropdown dropdownBtn col-md-2">
                                        <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false" (click)="ScheduledVideoComponent_HideDaysDropdown()">
                                            <span class="dropdownBtnText">{{this.timeRange | titlecase}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu">
                                            <li><a class="dropdown-item text-md active" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('morning')">Morning</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('afternoon')">Afternoon</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('evening')">Evening</a></li>

                                         </ul>
                                    </div>

                           </div>

                    </div>
                    <div   [hidden]="!(this.selectduration === 'annually')" >
                        <p class="text-sm text-gray-3">
                            Speficy a Date and Time preference
                        </p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <div class="dropdown dropdownBtn me-4">
                                        <label class="dropdown-toggle">

                                         <!--   <span style="font-size: 14px;">{{this.SelectDate}}</span>-->
                                         <!---   <input type="text" class="p-0 datepicker datepickerinput text-md border-0 w-auto" [(ngModel)]="this.SelectDate" placeholder="Select Date" (focusout)="ScheduledVideoComponent_SelectDate($event)" />-->

                                            <!-- <nz-date-picker nzBorderless [(ngModel)]="this.SelectDate" [disabledDate]="disabledDate"  [nzSuffixIcon]="null"></nz-date-picker> -->
                                        </label>
                                    </div>
                                    <div class="dropdown dropdownBtn">
                                        <button class="dropdown-toggle text-md" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="dropdownBtnText">{{this.timeRange | titlecase}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu">
                                            <li><a class="dropdown-item text-md active" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('morning')">Morning</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('afternoon')">Afternoon</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('evening')">Evening</a></li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div   [hidden]="!(this.selectduration === 'once')" >
                        <p class="text-sm text-gray-3">
                            Speficy a Dates and Time preference
                        </p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <div>
                                    <div class="dropdown dropdownBtn me-2 mb-2" *ngFor="let date of listdates; let i = index">
                                        <label class="dropdown-toggle w-auto">

                                           <!--   <input type="text" class="p-0 datepicker text-md border-0 datepickerinput" [(ngModel)]="this.SelectDate" placeholder="Select Dates" (focusout)="ScheduledVideoComponent_SelectDate($event)" />  -->

                                             <nz-date-picker nzBorderless [(ngModel)]="listdates[i]" [nzSuffixIcon]="null"   [nzDisabledDate]="disabledDate" (nzOnOpenChange)="ScheduledVideoComponent_SelectDate($event)"></nz-date-picker>

                                            </label>
                                            <button (click)="removeDate(i)" *ngIf="!(i==0)" class="ps-2 btn btn-secondary"
                                            style="background-color:#f2f2f5; border:1px solid #f2f2f5; color:rgb(0, 0, 0);
                                            padding: 13px;margin-left: 10px;">
                                        <i nz-icon nzType="delete" nzTheme="outline" style="color: red;"></i>
                                        </button>
                                    </div>

                                </div>
                                <div>
                                    <button (click)="addDate()" class="btn btn-secondary"
                                    style="background-color:#f2f2f5; border:1px solid #f2f2f5; color:rgb(124 124 124);
                                    font-weight: 700;padding: 14px;
                                        margin-right: 10px;
                                        font-size: 12px;">
                                    Add date
                                <img src="../../../assets/images/plus-sign-svgrepo-com.svg" style="height: 17px;
                                width: 17px;
                                padding-left: 5px;
                                padding-top: 0px;">
                                </button></div>
                                    <div class="dropdown dropdownBtn">
                                        <button class="dropdown-toggle text-md" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="dropdownBtnText">{{this.timeRange | titlecase}}</span>
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu">
                                            <li><a class="dropdown-item text-md active" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('morning')">Morning</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('afternoon')">Afternoon</a></li>
                                            <li><a class="dropdown-item text-md" href="javascript:void(0);" (click)="ScheduledVideoComponent_SelectTimeRange('evening')">Evening</a></li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                     </div>
                </div>


            </div>
        </div>
        <div class="modal-footer pb-40">
            <button style="position: absolute;
            left: 37px;
            bottom: 8px;" *ngIf="!this.videoManager.selectedVideo?.schedule" class="weight-normal btn btn-primary btn-round-6 px-4 py-3" (click)="ScheduledVideoComponent_SetScheduledVideo()" [disabled]="!bEnableSetScheduleButton">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.87957 4.67878C5.69984 4.46937 5.38417 4.44511 5.17449 4.62461C4.96481 4.80411 4.94053 5.11938 5.12025 5.32879L7.66515 8.29405C8.07528 8.77194 8.74076 8.94285 9.33085 8.72185L12.676 7.469C12.9346 7.37216 13.0656 7.0843 12.9687 6.82604C12.8717 6.56779 12.5835 6.43695 12.3249 6.53379L8.9797 7.78664C8.783 7.86031 8.56118 7.80334 8.42446 7.64404L5.87957 4.67878Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.5008 8.00021C16.5008 12.4132 12.9188 15.9906 8.50015 15.9906C4.08152 15.9906 0.499512 12.4132 0.499512 8.00021C0.499512 3.58721 4.08152 0.00976562 8.50015 0.00976562C12.9188 0.00976562 16.5008 3.58721 16.5008 8.00021ZM15.5007 8.00021C15.5007 11.8616 12.3664 14.9918 8.50015 14.9918C4.63385 14.9918 1.49959 11.8616 1.49959 8.00021C1.49959 4.13883 4.63385 1.00857 8.50015 1.00857C12.3664 1.00857 15.5007 4.13883 15.5007 8.00021Z"
                        fill="white" />
                </svg>
                <span class="ms-2">Set Schedule</span>
            </button>

            <button style="position: absolute;
            left: 37px;
            bottom: 8px;" *ngIf="this.videoManager.selectedVideo?.schedule" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="ScheduledVideoComponent_SetScheduledVideo()"  >
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.87957 4.67878C5.69984 4.46937 5.38417 4.44511 5.17449 4.62461C4.96481 4.80411 4.94053 5.11938 5.12025 5.32879L7.66515 8.29405C8.07528 8.77194 8.74076 8.94285 9.33085 8.72185L12.676 7.469C12.9346 7.37216 13.0656 7.0843 12.9687 6.82604C12.8717 6.56779 12.5835 6.43695 12.3249 6.53379L8.9797 7.78664C8.783 7.86031 8.56118 7.80334 8.42446 7.64404L5.87957 4.67878Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.5008 8.00021C16.5008 12.4132 12.9188 15.9906 8.50015 15.9906C4.08152 15.9906 0.499512 12.4132 0.499512 8.00021C0.499512 3.58721 4.08152 0.00976562 8.50015 0.00976562C12.9188 0.00976562 16.5008 3.58721 16.5008 8.00021ZM15.5007 8.00021C15.5007 11.8616 12.3664 14.9918 8.50015 14.9918C4.63385 14.9918 1.49959 11.8616 1.49959 8.00021C1.49959 4.13883 4.63385 1.00857 8.50015 1.00857C12.3664 1.00857 15.5007 4.13883 15.5007 8.00021Z"
                        fill="white" />
                </svg>
                <span class="ms-2">Update Schedule</span>
            </button>


            <button style="position: absolute;
            left: 260px;
            bottom: 8px;" *ngIf="this.videoManager.selectedVideo?.schedule" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="delete()">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.87957 4.67878C5.69984 4.46937 5.38417 4.44511 5.17449 4.62461C4.96481 4.80411 4.94053 5.11938 5.12025 5.32879L7.66515 8.29405C8.07528 8.77194 8.74076 8.94285 9.33085 8.72185L12.676 7.469C12.9346 7.37216 13.0656 7.0843 12.9687 6.82604C12.8717 6.56779 12.5835 6.43695 12.3249 6.53379L8.9797 7.78664C8.783 7.86031 8.56118 7.80334 8.42446 7.64404L5.87957 4.67878Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.5008 8.00021C16.5008 12.4132 12.9188 15.9906 8.50015 15.9906C4.08152 15.9906 0.499512 12.4132 0.499512 8.00021C0.499512 3.58721 4.08152 0.00976562 8.50015 0.00976562C12.9188 0.00976562 16.5008 3.58721 16.5008 8.00021ZM15.5007 8.00021C15.5007 11.8616 12.3664 14.9918 8.50015 14.9918C4.63385 14.9918 1.49959 11.8616 1.49959 8.00021C1.49959 4.13883 4.63385 1.00857 8.50015 1.00857C12.3664 1.00857 15.5007 4.13883 15.5007 8.00021Z"
                        fill="white" />
                </svg>
                <span class="ms-2">Delete Schedule</span>
            </button>

        </div>
    </div>


<div class="modal fade" id="del-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body p-4">
                <h5>Are you sure you want to delete this Schedule?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">The schedule will be permanently deleted from your account.
                </p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                    data-bs-dismiss="modal">cancel</a>
                <a href="javascript:void(0)" (click)="delete()" id="sidebarCollapse" data-bs-dismiss="modal" class="mt-3 text-gray-3 text-sm"> Delete</a>
            </div>
        </div>
    </div>
</div>