import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(public onboardingManager:OnboardingManagerService,public subscriptionPlan:SubscriptionplanManagerService)
  {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //; TODO will remove after checking 
      // if (this.onboardingManager.profile.user_status == "confirmed_not_subscribed")
      // {
      //   this.subscriptionPlan.showPlanDialogue();
      //   return false;
      // }
    return true;
  }
  
}
