import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../Managers/media-manager.service';
import { mediaFile, visual } from './../../models/media';
import {block_clips, content} from './../../models/video'
import {  video } from './../../models/video';
 
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
 

@Component({
  selector: 'app-block-sidepanel',
  templateUrl: './block-sidepanel.component.html',
  styleUrls: ['./block-sidepanel.component.scss']
})
export class BlockSidepanelComponent implements OnInit, OnChanges {
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('close') close: ElementRef;
  @Input() oInputSelectedBlock: content;
  oSelectedBlockCopy: any;
  lBlockClips: any = [];
  lAllowedFonts: any = [];
  lTextEffects: any = [];
  lTransitions: any = [];
  lClipEffects: any = [];
  lDirections: any = ["up", "down", "left", "right"];
  @Output() oEmittedBlockToSave = new EventEmitter();
  @Output() oEmittedBlockToRevert = new EventEmitter();
  @Output() oEmittedRemoveHighlightBlock = new EventEmitter();
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  oFiletoUpload: any;
  sFileName: string = "No file choosen";
  bEnableMediaSelection: boolean = false;
  bFetchMediaContents: boolean = false;
  bFetchBlocksMediaContent: boolean = false;
  oTemplateConfigurations: any;
  oVideoDetails: video;
  oSelectedClip: any;
  bProntoClips: boolean = false;
  lSidePanelBlockTabsOptions: any = [];
  ActiveSelectedTabValue: any;
  sSelectedClip: string = undefined;
  bMyAssest: boolean = false;
  lMediaLibraries: any = [];
  sMediaLibraryName: boolean = true;
  lReplaceClips: visual[] = [];
  subClips: block_clips[] =[];
  nFileNumber: any = 0;
  clip_path:string;
  editClips: any;
  @Output() onClose = new EventEmitter<any>();
  isThumbnail : boolean =true;
  public videoUrl:string;
  // oClipToRelace : any;
 block_clips:any =[
    {
    url:["https://storage.googleapis.com/pronto-clip-bucket/pronto_visuals/industries/Art/16568_woman_sitting_on_a_chair_in_front_of_a_desk_in_a_small_office_by_Ami_Bornstein_Artgrid-HD_H264-HD.mp4?Expires=1661246804&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=YV5y66WB5KcXSXiG6UzppMEXVO1WJAo8cR3A0MIiSX%2FuiK6GkL07n9gtNeHIniP%2BSGCgrwnXt4O8BTYERbI4pDk4lnhOaSCqdeqh2YWNH6v%2F5fPsBgL0e7bdBRDp%2BzuGdIPfT%2B%2FyPf3WrmGUvybE1lOM8Xkur3jYJeffEEZjVFHNJh8zsjMk1hdcOGQhnp%2FqBCuCZP9D9W2KUhUpCe6FhxwL9GBnQcKsg6rmYKKTTuEbkgQpes57RwXtoMz5H4%2Fbrex1LZ0SG7vMw6UqqV0s40M9jlyR3RxBF%2FEj9y7OKiZwJ9Y20T%2FRJ0KKaYsq%2B7mvNDqPnltwDJYPbeG4H2TEmw%3D%3D",
    ]},
    {
      url:["https://storage.googleapis.com/pronto-clip-bucket/pronto_visuals/industries/dentist/videoblocks-little-girl-sitting-in-dental-chair-playing-with-dental-tools-and-toys-preparation-for-teeth-cleaning_ryeodj9tdg_1080__D.mp4?Expires=1661246805&GoogleAccessId=pronto-service-account%40pronto-project.iam.gserviceaccount.com&Signature=hdAu0eKD1532hjLxyrgLbmy%2BlRlHLLkcgPO2U42f8g1CmfgkVxlhyJBU6lFtZs7CXJ6DeEXOfO7%2F3pMfTapAtanm6RHG3s3piL3kF295xV2MycFhINw%2B%2FzPplXQhrQvPpgYzG5EulrDQaTX98PtbRRKC56hAtG9%2FSbWZ%2FzUaA8Ho6%2B4ZQgMP5a5MHUZPMK7oDrIKoMF2Z8b9UulGKHSV%2BIsBYWiMY7odXrUaFCe4zYHcfd200e%2BbXjPPPDhT4A0yb%2B4KMlxVm6i7LnmsvBW91aKcY9%2B898zSbOwqDL9QeorDX%2Fylc9liPuGFWBeUpLC3XNFmyh3cMNfBlJ9U9F4WbQ%3D%3D"
      ]}
    ]
  constructor(private oSessionService: cSessionService ,private oDataService: cDataService, public videoManger: CreateVideoManagerService, public config: ConfigManagerService, public mediaLib: MediaManagerService) { }
  ngOnChanges() {
 
    this.videoUrl =this.videoManger.selectedVideo?.video_url+'#t='+this.oInputSelectedBlock?.ts[0]+','+(this.oInputSelectedBlock?.ts[0] + this.oInputSelectedBlock?.ts[1]);
    this.currentTime=[];
    this.currentTime.push(this.oInputSelectedBlock?.ts[0]);
    this.currentTime.push(this.oInputSelectedBlock?.ts[0]+this.oInputSelectedBlock?.ts[1]);
 
   
    if (this.oInputSelectedBlock !== null) {

    this.clip_path  = this.oInputSelectedBlock.source;


      this.sSelectedClip = undefined;
      this.bEnableMediaSelection = false;
      this.bFetchMediaContents = false;
      this.bFetchBlocksMediaContent = false;
    }
    if (this.lBlockClips) {
      this.TimelineViewSidePanelsComponent_ToggleClipReplace(this.lBlockClips);
    }
  }

  ngOnInit(): void {
    this.ActiveSelectedTabValue = "tab4-2-tab"
    this.lSidePanelBlockTabsOptions = [
      { name: 'Replace Clips', value: "tab4-2-tab" },
    ]
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.videoManger.selectedVideo.fpath;
   
    this.videoUrl =this.videoManger.selectedVideo.video_url+'#t='+this.oInputSelectedBlock?.ts[0]+','+(this.oInputSelectedBlock?.ts[0] + this.oInputSelectedBlock?.ts[1]);
    

    this.clip_path  = this.oInputSelectedBlock.source;
    this.oVideoDetails = this.videoManger.selectedVideo;

    console.log("Template Configuration : ==>", this.config.template_config);
    console.log("Template name : ==>", this.videoManger.selectedVideo.template_name);
    this.lAllowedFonts = this.config.template_config[this.videoManger.selectedVideo.template_name]?.allowed_fonts;//  this.oTemplateConfigurations.template_config[this.oVideoDetails.template_name].allowed_fonts;
    this.lTextEffects = this.config.text_effect_names;
    this.lTransitions = this.config.transition_names;
    this.lClipEffects = this.config.clip_effect_names; 
    this.BlockSidePanelComponent_GetMedialibrariesList();
    this.VideoTimelineComponent_InitializeJqueryCode();
    this.TimelineViewSidePanelsComponent_GetProntoClipRequest();
  }
  //text titles
  PopulateTextList(event: any, nIncommingTextIndex: number) {
    let sValue = event.target.value;
    console.log("PopulateTextList At index {%s} : ==> ", nIncommingTextIndex);
  }
  //text effects
  SelectEffectName(sIncommingEffectName: string, nIncommingEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_name = sIncommingEffectName;
    console.log("Effect Name : ==> ", this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_name);
  }
  ChangeFontSize(event: any, nIncommingEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.font_size = parseInt(event.target.value);
    console.log("Font Size : ==> ", this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.font_size);
  }
  SelectFontFamily(nIncommingFontNumber: string, nIncommingEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.font = nIncommingFontNumber;
    console.log("Font Family : ==> ", this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.font);
  }
  SelectFontColor(event: any, nIncommingEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.color = event.target.value;
    console.log("Font Color : ==> ", this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.color);
  }
  SelectBackgroundColor(event: any, nIncommingEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.theme = event.target.value;
    console.log("Background Color : ==> ", this.oSelectedBlockCopy.effects[nIncommingEffectIndex].effect_args.theme);
  }
  SelectTransitionName(sIncommingTransitionName: string, nIncommingTransitionIndex: number) {
    this.lDirections = [];
    if (sIncommingTransitionName === 'fresh_transition') {
      this.lDirections = ["down", "right"];
    }
    else {
      this.lDirections = ["up", "down", "left", "right"];
    }
    this.oSelectedBlockCopy.block_args.clip_trans['trans_name'] = sIncommingTransitionName;

    this.SelectTransitionDirection(this.lDirections[0], nIncommingTransitionIndex);
  }
  SelectTransitionDirection(sIncommingTransitionDirection: string, nIncommingTransitionDirectionIndex: number) {
    this.oSelectedBlockCopy.block_args.clip_trans['trans_args']['direction'] = sIncommingTransitionDirection;
  }

  public selectedClipEffect: string = "";
  SelectClipEffectName(sIncommingClipEffectName: any, nIncommingClipEffectIndex: number) {
    this.selectedClipEffect = sIncommingClipEffectName;
    console.log("selected clip" + this.selectedClipEffect)

    this.oSelectedBlockCopy.effects[nIncommingClipEffectIndex].effect_name = sIncommingClipEffectName;
    this.oSelectedBlockCopy.effects[nIncommingClipEffectIndex].effect_args.theme = '';


    console.log("Clip Effect Name : ==> ", this.oSelectedBlockCopy.effects[nIncommingClipEffectIndex].effect_name);
    console.log("Clip Effect : ==> ", this.oSelectedBlockCopy.effects[nIncommingClipEffectIndex]);
  }
  SelectClipEffectColor(event: any, nIncommingClipEffectIndex: number) {
    this.oSelectedBlockCopy.effects[nIncommingClipEffectIndex].effect_args.theme = event.target.value;
    console.log("Clip Effect Color : ==> ", this.oSelectedBlockCopy.effect[nIncommingClipEffectIndex].effect_args.theme);
  }
  VideoTimelineComponent_SelectClipsCategory(event: any) {
    let sClipCategory = event.target.value;
    if (sClipCategory === "pronto") {
      this.bMyAssest = false;
     
    }
    else {
      this.bMyAssest = true;
    }
  }
replacement_visual_path:string;
selectedClip :visual;
  slectedClip(visual: visual) {
    this.lReplaceClips.map(c => { c.isSelected = false });
    visual.isSelected = true;

    this.replacement_visual_path = visual.visual_path; 
    this.selectedClip = visual;

    
  }
  slectedSubClip(subClips: block_clips) {
    this.subClips.map(c => { c.isSelected = false });
    subClips.isSelected = true;
  }
  
  TimelineViewSidePanelsComponent_ToggleClipReplace(sIncommingClip: any) {
    this.oSelectedClip = sIncommingClip;
    this.bEnableMediaSelection = true;
    this.sSelectedClip = sIncommingClip;
    this.bEnableMediaSelection = true;
    console.log("Clip to replace : ==> ", sIncommingClip);
  }
  TimelineViewSidePanelsComponent_EnableMediaLibrary() {
    this.bFetchMediaContents = true;
    this.bFetchBlocksMediaContent = false;
  }
  loadingclip: boolean = false;
  TimelineViewSidePanelsComponent_GetProntoClipRequest() {
    if (!this.bProntoClips) {

      this.loadingclip = true;
      this.mediaLib.getReplaceClip(this.sUserEmail, this.sSessionId, this.sVideoId, this.clip_path).then(res => {
        this.loadingclip = false;
        console.log(this.mediaLib.replacementClips);
        this.lReplaceClips = this.mediaLib.replacementClips;
        this.bProntoClips = true;
      }).catch(err => {
        this.loadingclip = false;
      });

  }
  }
  VideoTimelineComponent_GenerateThumbnail(oIncommingClipPath) {
    let thumb = oIncommingClipPath.substr(0, 15) + 'thumbs/' + oIncommingClipPath.substr(15, oIncommingClipPath.length);
    thumb = 'https://storage.googleapis.com/pronto-video/' + thumb.replace(".mp4", "_thumb.jpg").replace(".mov", "_thumb.jpg").replace(".avi", "_thumb.jpg");
    return thumb;
  }
  VideoTimelineComponent_HandleReplaceClipResponse = async (InComingMessage) => {
    console.log("VideoTimelineComponent_HandleReplaceClipResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) {
      console.error("VideoTimelineComponent_HandleReplaceClipResponse : Error => ", oFormatedIncommingMessage["errorMessage"]);
    }
    else {
      this.lReplaceClips = [];
      console.log("VideoTimelineComponent_HandleReplaceClipResponse: Response ", oFormatedIncommingMessage);
      let lClips = oFormatedIncommingMessage.new_clips;
      for (let nIndex = 0; nIndex < lClips.length; nIndex++) {
        let clip_path = lClips[nIndex];
        let clip_url = await this.oDataService.DataService_GetSignedURLForDownload(clip_path);
        let clip_thumb = this.VideoTimelineComponent_GenerateThumbnail(clip_path);
        let clip_thumb_url = clip_thumb;
        const oFile = { clip_path: clip_path, clip_thumb: clip_thumb, clip_thumb_url: clip_thumb_url, clip_url: clip_url, type: 'clip' };
        this.bProntoClips = true;
      }
      console.log("the list of files==>>>>", this.lReplaceClips);
    }
  }
  ContentComponent_lEmittedFilesToUpload(lIncommingFiles: any) {
   const oFile = { clip_path: lIncommingFiles[0].visual_path, clip_thumb: lIncommingFiles[0].thumb, clip_thumb_url: lIncommingFiles[0].thumb, clip_url: lIncommingFiles[0].url, type: lIncommingFiles[0].visual_type };
    const nBlockIndex = this.lBlockClips.findIndex(clip => clip.clip_path === this.oSelectedClip.clip_path);
    this.lBlockClips[nBlockIndex] = oFile;
    this.bEnableMediaSelection = true;
    this.TimelineViewSidePanelsComponent_ToggleClipReplace(this.lBlockClips[nBlockIndex]);
    console.log("Clip To Update : ==> ", this.lBlockClips[nBlockIndex]);
  }
  ContentComponent_lEmittedFilesToUploadForBlocksReplace(lIncommingFiles: any) {


    this.replacement_visual_path = lIncommingFiles[0].visual_path
    this.selectedClip = lIncommingFiles[0];

  }
  BlockSidepanelComponent_SelectFilesForUpload(event) {
    this.oFiletoUpload = event.target.files[0];
    {
      if (this.oFiletoUpload.type.indexOf('image') > -1 || this.oFiletoUpload.type.indexOf('video') > -1) {
        this.BlockSidepanelComponent_UploadFile(this.oFiletoUpload);
      }
      else {
        console.log("BlockSidepanelComponent_SelectFilesForUpload : Please upload image/video file");
      }
    }
  }
  async BlockSidepanelComponent_UploadFile(fileToUpload: any) {
    console.log("ContentComponent_UploadFile : File To Upload ==> ", fileToUpload);


    let media = new mediaFile();
    media.file = fileToUpload;
    media.name = fileToUpload.name;

    this.mediaLib.uploadMediaFilesAdmin(this.sUserEmail,this.sSessionId,media).then((res:visual)=>{
  
      this.sFileName = res.name;
      this.nFileNumber = 1;
    this.bEnableMediaSelection = true;
      this.replacement_visual_path  =res.visual_path;

      console.log(this.replacement_visual_path)
      this.closeModal.nativeElement.click();
      setTimeout(() => { this.nFileNumber = 0; this.sFileName = "" }, 2000);

    }).catch(error=>{
      this.closeModal.nativeElement.click();
    });


  }
 
  BlockSidePanelComponent_GetMedialibrariesList() {
    }
  BlockSidePanelComponent_HandleMediaLibraryListResponse = (IncomingMessage) => {
    console.log("BlockSidePanelComponent_HandleMediaLibraryListResponse: IncomingMessage", JSON.parse(IncomingMessage));

    if (JSON.parse(IncomingMessage).errorMessage) {
      console.error("BlockSidePanelComponent_HandleMediaLibraryListResponse : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
    }
    else {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
      this.lMediaLibraries = oFormatedIncommingMessage.media_libs;
    }
  }
 
  //save edits
  VideoTimelineComponent_SaveEdits() {
    this.lReplaceClips.map(c => { c.isSelected = false });
    console.log('original', this.oInputSelectedBlock);
   

 this.oEmittedBlockToSave.emit({replaceclip:this.replacement_visual_path,thumbnail:this.selectedClip.thumbnail,url:this.selectedClip.url});
    this.DismissSidePanel();
  }
  //revert changes
  VideoTimelineComponent_RevertChanges() {
 
    this.replacement_visual_path ='';
    this.lReplaceClips.map(c => { c.isSelected = false });
    this.oEmittedBlockToRevert.emit('');
    this.DismissSidePanel();
  }
  DismissSidePanel() {
    this.close.nativeElement.click();
    this.sSelectedClip = undefined;
    let removeScript = document.getElementById('alpha');
    if (removeScript) {
      removeScript.remove();
    }
    this.ActiveSelectedTabValue = "tab4-2-tab"
    this.oEmittedRemoveHighlightBlock.emit()

  }
  VideoTimelineComponent_SelectedTab(tab) {
    this.ActiveSelectedTabValue = tab.value;
  }
  VideoTimelineComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
    });
    $("[data-sidebar]").click(function() {
      var sideBarId = $(this).attr("data-sidebar");
      $(".rightSideBar").removeClass("active");
      $(".sidebarOverlay").addClass("active");
      $(sideBarId).addClass("active");
    });


    $(".sidebarOverlay, .closeRightSideBar").click(function() {
      $(".rightSideBar").removeClass("active");
      $(".sidebarOverlay").removeClass("active");
      $("body").removeClass("sidbar_contentslide");
    });
    `;
    document.body.appendChild(chatScript);
  }

  currentTime:number[]=[];
  public onTimeUpdate(value){

  
 if(this.currentTime[1] <= value.target.currentTime)
 {
  this.currentTime[0] = this.oInputSelectedBlock?.ts[0];
  value.target.currentTime =  this.oInputSelectedBlock?.ts[0];
 
 }
    
}

  closeVideoSettingEditClip(): void {
    this.onClose.emit();
      }
      thumbDisplay(incommingUrl){
        this.isThumbnail = false;
        console.log("thumbDisplay",this.isThumbnail);
        
      }
}
