import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cVideoDetails } from './../../../shared/models/video-details';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ErrorService } from './../../../services/error.service';

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss']
})
export class DashboardOverviewComponent implements OnInit {
  sAddContentSlide: string;
  oVideoDetails : any;
  lKeyterms : any = [];
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  bLoading : boolean = false;
  sErrorMessage : string;
  bDisplayErrorMsg : boolean = false;
  lTemplateCategory : any = [];
  lTemplateNames : any = [];
  oSetTimeOut: any;
  lVideoTimeout: any= [];
  nVideoDuration: number;
  nVideoTimeOut:any;
  oClosePopup: any=0;

  constructor( private oWebSocketService: cWebSocketService,public videoManager:VideoManagerService,private oDataService : cDataService, private oSessionService : cSessionService, private oRouter : Router,private oTemplateConfigService : cTemplateConfigService,public errorService:ErrorService) { }

  ngOnInit(): void 
  {
   
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();

    this.lKeyterms =  this.videoManager.selectedVideo.key_terms;
if(this.videoManager.selectedVideo?.schedule)
{
  this.videoManager.getSchedulesVideos(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo.schedule['schedule_id']).then(res=>{
    this.videoManager.selectedVideo.scheduleVideos = res['video_list'];
  }).catch(err => {
          
    this.errorService.handleError(err.errorMessage,err.errorType,true);

});
}
  }
  DashboardOverviewComponent_PlayVideo()
  {
    var video = <HTMLVideoElement> document.getElementById("videoPreview");
    video.play();
  }
 
  
   

 
 

  DashboardHeaderComponent_GetClickLocation(event)
  {
    this.oClosePopup=0;
    if(event.clientX <= 65)
    {
      this.oClosePopup=1;
      setTimeout(() => {this.oClosePopup=0;}, 200);
    }
  }
   // modal scheduled video
   isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  checktag(){
  console.log(this.videoManager.selectedVideo?.key_terms, "checktag")
}

}