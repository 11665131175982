import { Injectable } from '@angular/core';
import { UserDataInfo } from "src/app/models/user";
import { brand, brandList, SearchData } from 'src/app/models/admin';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from 'src/app/services/dataServices/admin.service';
import { cSessionService } from 'src/app/services/session.service';
import { user, UserList } from "src/app/models/admin";
import { ErrorService } from 'src/app/services/error.service';
import { rejects } from 'assert';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiURL } from 'src/app/shared/conf/api-path';

@Injectable({
  providedIn: 'root'
})
export class AdminBrandManagerService {
  sessionId: any;
  email: any;
  selectedUser: user;
  public brandDetail: brand = {};
  public brandList: brandList[] = [];
  selectedBrandProfile : brand;
  constructor(
    private errorService: ErrorService,
    private loader: LoaderService,
    private http : HttpClient,
    private dataService: AdminService,
    public oSessionService: cSessionService) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();

  }

  public addBrand(brand: brand) {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService
        .addBrands(this.email, brand)
        .then((res) => {
          this.loader.hideLoader();

          // let brandLst = new brandList();
          // brandLst._id = brand._id;
          // brandLst.name = brand.name
          // brandLst.website = brand.website;
          // brandLst.industry = brand.industry;
          // brandLst.users = brand.users;
          // brandLst.public_videos = brand.public_videos;
          // brandLst.media_uploads = brand.media_uploads;
          // this.brandList.push(brandLst);
          
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError("Here is ErrorMessage", "Error", true);
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public getListBrand(
    email: string,
    sessionId: string,
    offset: number,
    limit: number,
    sort: string,
    asc: number,
    search_term = "",
    search_by = "_id",
    showLoader = true
  ) {

    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .getListBrands(email, sessionId, offset, limit, sort, asc, search_by, search_term)
        .then((res) => {
          if (showLoader)
            this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader)
            this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public searchUser(name: string): Promise<UserDataInfo[]> {
    return new Promise((resolve, reject) => {

      console.log("userList3", name);
      return resolve([
        { id: "1", name: "Sharjeel bhai " },
        { id: "2", name: "Wasim bhai " },
        { id: "3", name: "muneeb " },
      ])
    });
  }

  public getBrandProfile(id: string, showLoader = true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      console.log(id);
      this.dataService.getBrandProfile(id)
        .then((res: brand) => {
          this.brandDetail = res;
          if (showLoader)
            this.loader.hideLoader();
          
          
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);

          if (showLoader)
            this.loader.hideLoader();
            
          reject(err);
        });
    });

    return promise;
  }

 


  addUserBrand(brandId:any, userIds: any, showLoader= true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {

      this.dataService.addUserBrand(brandId, userIds)
        .then((res) => {

          if (showLoader)
            this.loader.hideLoader();

          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          
          console.log("addUserBrandErr",err);
          if (showLoader)
            this.loader.hideLoader();
            
          reject(err);
        });
    });

    return promise;
  }

  getBrandUsers(brandId:any, showLoader= true) {
    if (showLoader)
      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {

      this.dataService.listBrandsUsers(brandId)
        .then((res) => {

          if (showLoader)
            this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);          
          if (showLoader)
            this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public updateBrand(brand: brand, showLoader = true) {
    if( showLoader ) {
      this.loader.showLoader(); 
    }
    let promise = new Promise((resolve, reject) => {
      this.dataService
        .updateBrand(brand)
        .then((res) => {
          this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError("Here is ErrorMessage", "Error", true);
          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }

  deleteBrand(
    _id: string
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.brand.delete, {_id})
    .toPromise();
  }

  deleteAssignedUser(
    _id : string
  ) {  
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.user.delete_user_brand, { _id : _id})
    .toPromise();
  }
  getBrandMediaLibList(
    brand_id: string,
    limit, 
    offset
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.media.list_brand_media_libs, {brand_id,limit,offset})
    .toPromise();
  }

  searchBrandMediaLibList(search : SearchData
  ) {
    return this.http
    .post(environment.API_HOST_ADMIN + ApiURL.admin.media.list_brand_media_libs, search)
    .toPromise();
  }

  public getIndustryList() {

      this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataService
        .getIndustryList()
        .then((res) => {
            this.loader.hideLoader();
          resolve(res);
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
            this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }
}
