import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';
 
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service'; 
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';

@Component({
  selector: 'app-voiceover-sidepanel',
  templateUrl: './voiceover-sidepanel.component.html',
  styleUrls: ['./voiceover-sidepanel.component.scss']
})
export class VoiceoverSidepanelComponent implements OnInit {
  bUploadVoiceOverFile : boolean;
  oUploadedVoiceOverFile : any;
  nVoiceOverFileSize : number = 0;
  sSessionId: any;
  sUserEmail: any;
  sUploadedVoiceOverFileName : string = "No file choosen.";
  bShowTextToSpeechSection: boolean = true;
  sSelectedVoiceGender : string="male";
  oUserVoiceOverList: any = [];
  oVoiceOverList : any = [];
  public sVoiceName : string="";
  nSelectedVoiceIndex : number;
  fVoiceoverForm : FormGroup;
  oAudio = new Audio();
  oSavedFormData : any;
  sVideoId: string;
  // sEditVideoId: string;
  lBlockConfigurations : any = [];
  bLetProntoChoose : boolean = true;
  bDisplayMyMediaSection : boolean = false;
  bDisplayUploadSection : boolean = true;
  lFilestoUpload : any = [];
  lUploadedFiles : any = [];
  lUserSelectedVisuals : any = [];
  sResetTextOverLays : string;
  bAudioSidepanel : boolean = false;
  va_text:string="va text";
  
  @Output() onClose = new EventEmitter<any>();
  @Output() oVoiceOverFileurl = new EventEmitter<string>();
  constructor(
    private oWebSocketService: cWebSocketService,
    private oSessionService : cSessionService,
    private oDataService : cDataService,
    private oFormBuilder: FormBuilder,
    private oTemplateConfigService: cTemplateConfigService,
    public videoManager: CreateVideoManagerService,
    public congifManager: ConfigManagerService
    ) { }

  ngOnInit() {
    
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
   // this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    // this.sEditVideoId = this.oSessionService.SessionService_GetEditVideoId();
   
    this.congifManager.voices.sort(this.sortAlphaNum);
    this.oUserVoiceOverList = this.congifManager.voices;
 
   this.va_text = this.videoManager.selectedVideo.vo_text;
 console.log('voices',this.congifManager.voices);
  //this.oUserVoiceOverList.sort
 //console.log('voices2',this.oVoiceOverList);
 //console.log('voicesaaa',this.oVoiceOverList.sort(this.sortAlphaNum))
   this.sSelectedVoiceGender =  this.congifManager.voices.filter(c=> c.name === this.videoManager.selectedVideo.selected_voice)[0]?.gender;
   // this.oSavedFormData = this.oSessionService.cSessionService_GetContentForm();
   this.ContentComponent_SelectVoiceGender(this.sSelectedVoiceGender,'create');
   this.sVoiceName = this.videoManager.selectedVideo.selected_voice;
 
    // if(this.nIncomingVoiceoverFile)
    // {
    //   this.voiceoverSource=this.nIncomingVoiceoverFile.url;
    // }
   /* if(this.oSavedFormData)
    {
      this.fVoiceoverForm = this.oFormBuilder.group(
      {
        session_id: this.oSavedFormData.session_id,
        email: this.oSavedFormData.email,
        vo_text : [this.oSavedFormData.vo_text],
        selected_voice : [this.oSavedFormData.selected_voice],
        pronto_clips : [this.oSavedFormData.pronto_clips],
        oUploadedVoiceOverFile : [this.oSavedFormData.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [this.oSavedFormData.nVoiceOverFileSize],
        sGender : [this.oSavedFormData.sGender],
        lUploadedFiles : [this.oSavedFormData.lUploadedFiles],
        lUserSelectedVisuals : [this.oSavedFormData.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.oSavedFormData.bShowTextToSpeechToggle,
        bLetProntoChooseToggle : this.oSavedFormData.bLetProntoChooseToggle,
        bMyMediaLibraryToggle : this.oSavedFormData.bMyMediaLibraryToggle,
        bUploadSectionToggle : this.oSavedFormData.bUploadSectionToggle,
        lBlockConfig : [this.oSavedFormData.lBlockConfig],
        music_file : [this.oSavedFormData.music_file],
        cta : [this.oSavedFormData.cta]
      });
        this.bLetProntoChoose = this.oSavedFormData.bLetProntoChooseToggle;
        this.bDisplayMyMediaSection = this.oSavedFormData.bMyMediaLibraryToggle;
        this.bDisplayUploadSection = this.oSavedFormData.bUploadSectionToggle;
        this.bShowTextToSpeechSection = this.oSavedFormData.bShowTextToSpeechToggle;
        this.nVoiceOverFileSize = this.oSavedFormData.nVoiceOverFileSize;
        this.lUploadedFiles = this.oSavedFormData.lUploadedFiles;
        this.lUserSelectedVisuals = this.oSavedFormData.lUserSelectedVisuals;
        this.lBlockConfigurations=this.oSavedFormData.lBlockConfig;
        console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
        if(this.oSavedFormData.oUploadedVoiceOverFile)
        {
          this.sUploadedVoiceOverFileName = this.oSavedFormData.oUploadedVoiceOverFile.filename;
        }
        else
        {
          this.ContentComponent_SelectVoiceGender(this.oSavedFormData.sGender,'edit');
        }
    }
    else
    {
      this.fVoiceoverForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        vo_text : [''],
        selected_voice : [''],
        pronto_clips : [this.bLetProntoChoose],
        sGender : ['male'],
        oUploadedVoiceOverFile : [this.oUploadedVoiceOverFile],
        nVoiceOverFileSize : [0],
        lUploadedFiles : [this.lUploadedFiles],
        lUserSelectedVisuals : [this.lUserSelectedVisuals],
        bShowTextToSpeechToggle : this.bShowTextToSpeechSection,
        bLetProntoChooseToggle : this.bLetProntoChoose,
        bMyMediaLibraryToggle : this.bDisplayMyMediaSection,
        bUploadSectionToggle : this.bDisplayUploadSection,
        lBlockConfig : [[]],
        music_file : [this.oSavedFormData.music_file],
        cta : ['']
      });
      //by default male voice will be selected
      this.ContentComponent_SelectVoiceGender('male','create');
      const nSelectedDuraction  = this.oSessionService.cSessionService_GetOverviewForm().duration;
      const sSelectedTemplateName = this.oSessionService.cSessionService_GetLooknFeelForm().template_name;
      this.lBlockConfigurations=this.oTemplateConfigService.TemplateConfigService_FetchTemplateBlocks(nSelectedDuraction,sSelectedTemplateName);
      console.log("ContentComponent : BLOCK CONFIG ==> ",this.lBlockConfigurations);
      this.fVoiceoverForm.patchValue({lBlockConfig : this.lBlockConfigurations});
    }*/
  }

  ContentComponent_ToggleFileUploadType(nIncommingType : boolean)
  {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  async ContentComponent_UploadedVoiceOverFile(event) 
  {   
    this.ContentComponent_UploadVoiceover();
    let file = event.target.files[0];
    if(file)
    {
    this.nVoiceOverFileSize = file.size;
    console.log("ContentComponent_UploadFile : File To Upload ==> ", file);
    var oFormData: any = new FormData();
    oFormData.append('session_id',this.sSessionId);
    oFormData.append('email',this.sUserEmail);
    oFormData.append('file',file);
    try 
      {
        await this.oDataService.DataService_UploadUserVoiceOverFile(oFormData).subscribe((result : any) =>
        {
        console.log("ContentComponent_UploadedVoiceOverFile : Response ==>", result);
        this.oUploadedVoiceOverFile = result;
        this.sUploadedVoiceOverFileName = result.filename;
       
      
     
      //  this.fVoiceoverForm.patchValue({oUploadedVoiceOverFile : this.oUploadedVoiceOverFile, nVoiceOverFileSize : this.nVoiceOverFileSize});
        },
        (error)=>
        {
          this.oUploadedVoiceOverFile=null;
          // this.sDisplayResponseMessage = error.error.errorMessage;
          // this.bDisplayErrorMessage = true;
          // setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
          console.error("ContentComponent_UploadedVoiceOverFile : Error ==>", error);
        });
      } 
      catch (error) 
      {
        console.error("ContentComponent_UploadedVoiceOverFile : ERROR ==>", error);
      }
    }
  }
  ContentComponent_UploadVoiceover() 
  {
    this.bShowTextToSpeechSection = false;
  // // this.fVoiceoverForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
  //  this.fVoiceoverForm.removeControl('vo_text');
  //  this.fVoiceoverForm.removeControl('selected_voice');
  //  this.fVoiceoverForm.removeControl('sGender');
  //  this.fVoiceoverForm.removeControl('vo_text_file');
  //  this.fVoiceoverForm.addControl('oUploadedVoiceOverFile', new FormControl('', [Validators.required]));
  }
  ContentComponent_TextToSpeech() 
  {
    this.bShowTextToSpeechSection = true;
   // this.oVoiceOverFileurl.emit('');
    this.nVoiceOverFileSize=0;
 //   this.fVoiceoverForm.patchValue({nVoiceOverFileSize : this.nVoiceOverFileSize});
    this.sUploadedVoiceOverFileName;
//    this.fVoiceoverForm.patchValue({bShowTextToSpeechToggle : this.bShowTextToSpeechSection});
 ///   this.fVoiceoverForm.removeControl('oUploadedVoiceOverFile');
 //   this.fVoiceoverForm.addControl('vo_text', new FormControl("", [Validators.required]));
  //  this.fVoiceoverForm.setControl('vo_text', this.oFormBuilder.control(""));
//    this.fVoiceoverForm.addControl('selected_voice', new FormControl('', [Validators.required]));
    this.ContentComponent_SelectVoiceGender('male','create');
  }
  ContentComponent_SelectVoiceGender(value : any ,mode : string)
  {
    this.sSelectedVoiceGender=value;
    this.sVoiceName="";
    this.oVoiceOverList = [];
    this.oUserVoiceOverList.forEach(element => 
    {
      if(element.gender == value)
      {
        this.oVoiceOverList.push(element);
      }
    });
 
    if(mode==="create")
    {
      this.ContentComponent_SelectVoice(this.oVoiceOverList[2],2);
    }
    else
    {
      const nVoiceIndex = this.oVoiceOverList.findIndex((element : any)=>element.value === this.videoManager.selectedVideo.selected_voice);
      this.ContentComponent_SelectVoice(this.oVoiceOverList[nVoiceIndex],nVoiceIndex);
    }
   // this.fVoiceoverForm.addControl('sGender', new FormControl());
   // this.fVoiceoverForm.setControl('sGender', this.oFormBuilder.control(value));
  }
  ContentComponent_SelectVoice(select,index)
  {
console.log("voice",select);
    this.nSelectedVoiceIndex = index;
    this.sVoiceName = select?.name;

  
   // this.fVoiceoverForm.patchValue({selected_voice : select.value});
  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_PlayAudio(filepath) 
  {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    this.oAudio = new Audio();
    this.oAudio.src = filepath;
    this.oAudio.load();
    this.oAudio.play();
  }
  ContentComponent_StopAudio(filepath)
  {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename)
      {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
      }
  }
  VideoTimelineView_SubmitfVoiceoverForm()
  {

    console.log("voice",  this.videoManager.selectedVideo);
    console.log("bShowTextToSpeechSection",  this.bShowTextToSpeechSection);
    if (this.bShowTextToSpeechSection) {
      this.videoManager.selectedVideo.vo_text = this.va_text;
      this.videoManager.selectedVideo.selected_voice = this.sVoiceName;
      this.videoManager.selectedVideo.voice_file='';
      this.oVoiceOverFileurl.emit('')
    }
    //if user uploads voice over file
    else {
      console.log("File Path",   this.oUploadedVoiceOverFile.fpath);
      this.videoManager.selectedVideo.voice_file = this.oUploadedVoiceOverFile.fpath;
      this.videoManager.selectedVideo.selected_voice='';
      this.videoManager.selectedVideo.vo_text='';
      this.oVoiceOverFileurl.emit(this.oUploadedVoiceOverFile)
  }

   // this.oSavedFormData = this.oSessionService.cSessionService_GetContentForm();
   // console.log("Getting Form From Local Storage ====>>>",this.oSavedFormData)
   // this.fVoiceoverForm.patchValue({
   //  music_file : this.oSavedFormData.music_file,
   // }); 
  //  this.oSessionService.cSessionService_SetContentForm(this.fVoiceoverForm.value);
   // console.log("Form Value From TimeLine ==>>>",this.fVoiceoverForm.value);

   this.closeVoiceover();
  }




   
  closeVoiceover(): void {

    this.onClose.emit();
        //this.visibleMedia = false;
      }



  sortAlphaNum(a, b) {
    console.log(a);
    console.log(b);
   let reA = /[^a-zA-Z]/g;
   let reN = /[^0-9]/g;
  var aA = a.name.replace(reA, "");
  var bA = b.name.replace(reA, "");
  
  if (aA === bA) {
    var aN = parseInt(a.name.replace(reN, ""), 10);
    var bN = parseInt(b.name.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}
}
