import { Injectable } from '@angular/core';
import { UserDataInfo } from "src/app/models/user";
import { brand, brandList, MediaLibrary } from 'src/app/models/admin';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from 'src/app/services/dataServices/admin.service';
import { cSessionService } from 'src/app/services/session.service';
import { user, UserList } from "src/app/models/admin";
import { ErrorService } from 'src/app/services/error.service';
import { rejects } from 'assert';
import { MediaLibSearch } from 'src/app/models/media';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { ApiURL } from 'src/app/shared/conf/api-path';


@Injectable({
    providedIn: 'root'
})
export class AdminMediaLibraryManagerService {
    sessionId: any;
    email: any;
    selectedUser: user;
    public brandDetail: brand = {};
    public mediaList: MediaLibSearch;
    constructor(
        private errorService: ErrorService,
        private loader: LoaderService,
        private dataService: AdminService,
        private http: HttpClient,
        public oSessionService: cSessionService) {
        this.sessionId = this.oSessionService.SessionService_GetSessionId();
        this.email = this.oSessionService.cSessionService_GetUserEmail();

    }

    public getListMedia(
        email: string,
        sessionId: string,
        offset: number,
        limit: number,
        sort: string,
        asc: number,
        search_term = "",
        search_by = "_id",
        showLoader = true, 
        is_organization = false,
    ) {

        if (showLoader)
            this.loader.showLoader();

        let promise = new Promise((resolve, reject) => {
            this.dataService
                .getMediaLibraries(email, sessionId, offset, limit, sort, asc, search_by, search_term, is_organization)
                .then((res) => {
                    if (showLoader)
                        this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);

                    if (showLoader)
                        this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    public getListUserMediaLibrary(
      email: string,
      search = "",      
      showLoader = true
  ) {

      if (showLoader)
          this.loader.showLoader();

      let promise = new Promise((resolve, reject) => {
          this.dataService
              .getUserMediaLibraries(email, search)
              .then((res) => {
                  if (showLoader){
                      this.loader.hideLoader();
                  }
                  resolve(res);
              })
              .catch((err) => {
                  this.errorService.handleError(err.errorMessage, err.errorType, true);

                  if (showLoader){
                      this.loader.hideLoader();
                  }
                      
                  reject(err);
              });
      });

      return promise;
  }

    //   public searchUser( name : string) : Promise<UserDataInfo[]> {
    //     return  new Promise((resolve, reject) => {

    //       console.log("userList3", name);
    //       return resolve([
    //         { id: "1", name : "Sharjeel bhai "},
    //         { id: "2", name : "Wasim bhai "},
    //         { id: "3", name : "muneeb "},
    //       ])
    //     });    
    //   }

    //   public getBrandProfile(id : string, showLoader= true)
    //   {
    //   if(showLoader)
    //   this.loader.showLoader();

    //     let promise = new Promise((resolve, reject) =>{

    //       this.dataService.getBrandProfile(id)
    //         .then((res: brand) => {
    //           this.brandDetail = res;
    //           if(showLoader)
    //             this.loader.hideLoader();

    //             console.log("getbrand",JSON.stringify(res));


    //           resolve(res);
    //         })
    //         .catch((err) => {
    //           this.errorService.handleError(err.errorMessage, err.errorType, true);

    //           if(showLoader)
    //             this.loader.hideLoader();
    //           reject(err);
    //         });
    //     });

    //     return promise;
    //   }



    addMediaLibray(mediaLibObj : MediaLibrary, showLoader= true){
            if (showLoader)
              this.loader.showLoader();
        
            let promise = new Promise((resolve, reject) => {
        
              this.dataService.addMediaLibray(mediaLibObj)
                .then((res) => {
        
                  if (showLoader){
                    this.loader.hideLoader();
                  }

                  resolve(res);
                })
                .catch((err) => {
                  this.errorService.handleError(err, 'Add Media Library', true);
                  
                  if (showLoader){
                    this.loader.hideLoader();
                  }

                  reject(err);
                });
            });
        
            return promise;
          
    }

    addUserMediaLibrary(mediaLibraryId:any, userIds: any, showLoader= true) {
      if (showLoader)
        this.loader.showLoader();
  
      let promise = new Promise((resolve, reject) => {
  
        this.dataService.addUserMediaLibrary(mediaLibraryId, userIds)
          .then((res) => {
  
            if (showLoader)
              this.loader.hideLoader();
            resolve(res);
          })
          .catch((err) => {
            this.errorService.handleError(err.errorMessage, err.errorType, true);
            
            if (showLoader)
              this.loader.hideLoader();

            reject(err);
          });
      });
  
      return promise;
    }

    getMediaLibraryUsers(
      media_lib_id : string
    ) {
      var data = { media_lib_id  };
      return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get_media_lib_users, data)
      .toPromise();
    }
    deleteMediaLibraryUsers(_id : string) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.del_media_lib_users, {_id})
      .toPromise();
  }
}
