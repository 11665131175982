<!-- <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content"> -->

<div class="data-table pt-4 px-0 m-0 d-flex flex-column">
  <div class="table-header border-bottom pb-4 row m-0 px-4">
    <div class="col-6 px-0 text-start">
      <h3 class="weight-700 text-gray-2 mb-1">Users</h3>
      <p class="mx-0 my-0 text-sm">
        Efficiently manage user access and permissions for your brands
      </p>
    </div>
    <div class="col-6 px-0 text-end">
      <button type="button" (click)="openAddNewUserDrawer()"
        class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-0 float-end mt-1 text-xs"
        data-sidebar="#addAgencyUser">
        Add User
      </button>
      <div class="float-end dropdown dropdownBtn btn-dropDown d-inline-block ms-4 divader-left"></div>

    </div>
  </div>
  <!-- Search Layer -->
  <div class="row video-header mx-0">
    <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
      <li class="col-md-4 mb-2 pe-3">
        <div class="formItm search-icon searchDropdown">
          <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search user"
            required="" (keyup.enter)="searchUser()" />
          <label for="search-feild" class="text-sm" style="cursor: default">Search User</label>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="searchUser()"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
              fill="#A39FAA"></path>
          </svg>
        </div>
        <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
          <div>{{ searchTerm }}</div>
          <mat-icon class="link">close</mat-icon>
        </div>
      </li>
      <li class="col-md-2 mb-2 ps-2" id="selectpreff">
        <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
          <div class="dropdown dropdownBtn">
            <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()"
              (blur)="hideSearchSortTypeDD($event)">
              <span class="dropdownBtnText"><strong>Sort by </strong>
                {{ searchBy == "created" ? "Created" : "" }}
                {{ searchBy == "_id" ? "Email" : "" }}
                {{ searchBy == "username" ? "Name" : "" }}
              </span>
            </button>
            <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
              <li>
                <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('username')">By
                  Name
                </a>
              </li>
              <li>
                <a class="dropdown-item text-md" (click)="sortBySearchResult('_id')">By Email
                </a>
              </li>
              <li>
                <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
                </a>
              </li>
            </ul>
          </div>

          <div class="sort-arrows pos-right">
            <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1"
              (click)="sortTypeSearch(1)">
            <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1"
              (click)="sortTypeSearch(-1)">
          </div>
        </div>
      </li>

      <li class="col-md-4 mb-2"></li>
    </ul>
  </div>

  <!-- Search Layer ends -->

  <div class="flex-grow-1 mb-5">
    <div class="my-3 px-2">
      <p>
        <span class="text-lg">Total User: </span>
        <b class="weight-600 spinner" *ngIf="loadingSearchUser == true">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </b>
        <b class="weight-600" *ngIf="loadingSearchUser != true">
          {{ userList?.count }}/{{ userList?.total_count }}</b>
        <span *ngIf="showCreatedUserMsg"
          class="showHide ms-3 d-inline-block bg-blue text-xs border-7 px-4 py-1 rounded">
          <img class="me-2" src="../assets/images/explaination-icon.svg" width="16" height="16" />

          User has been created. Invitation email has been sent.

        </span>

      </p>
    </div>

    <div class="userdetails ">
      <table class="table tableCustom table-hover mb-0">
        <thead>
          <tr>
            <th scope="col link" [ngClass]="{ active: searchBy === 'username' }" (click)="sortDataBy('username')"
              class="uname-width">
              Name
              <img src="../../../../../assets/images/DataSort-1.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === 'username' && sortType == -1">
              <img src="../../../../../assets/images/DataSort-3.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === 'username' && sortType == 1">
            </th>
            <th scope="col link" [ngClass]="{ active: searchBy === '_id' }" (click)="sortDataBy('_id')">
              Email
              <img src="../../../../../assets/images/DataSort-1.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === '_id' && sortType == -1">
              <img src="../../../../../assets/images/DataSort-3.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === '_id' && sortType == 1">
            </th>
            <th scope="col">User Role</th>
            <th scope="col">Brands</th>
            <th scope="col">Published Videos</th>
            <th scope="col">Share Personal Account</th>
            <th scope="col">Share Brand Account</th>
            <th scope="col">Share Company Account</th>
            <th scope="col">Download Video Access</th>
            <th scope="col">Last Login</th>
            <th scope="col link" [ngClass]="{ active: searchBy === 'created' }" (click)="sortDataBy('created')">
              Created
              <img src="../../../../../assets/images/DataSort-1.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === 'created' && sortType == -1">
              <img src="../../../../../assets/images/DataSort-3.svg" style="margin-left:5px; height:15px"
                *ngIf="searchBy === 'created' && sortType == 1">
            </th>
            <th scope="col" class="tableAction">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of userList?.result" [ngClass]="{'disable' : user.status === 'inactive'}">
            <td>
              <a *ngIf="user?.username" class="b_userName" (click)="loadUserProfile(user._id)"
                href="javascript:void(0)">{{ user.username }}</a>
              <a *ngIf="!user?.username" class="b_userName" (click)="loadUserProfile(user._id)"
                href="javascript:void(0)">{{ user.username }}</a>
            </td>
            <td>{{ user._id }}</td>
            <td class="text-capitalize">
              {{
              user.user_role &&
              user.user_role.length > 0 &&
              user.user_role[0] == "org_admin"
              ? "admin"
              : user.user_role
              }}
            </td>
            <td>{{ user.brand_count }}</td>
            <td>
              {{
              user.published_video_count != undefined
              ? user.published_video_count
              : 0
              }}
            </td>
            <td>
              <label class="btnSwitch">
                <input type="checkbox" (click)="updatePermission('personal', false, user._id)"
                  *ngIf="user.allow_share_personal_account" checked />
                <input type="checkbox" (click)="updatePermission('personal', true, user._id)"
                  *ngIf="!user.allow_share_personal_account" />
                <span class="btnSwitchCheck"></span>
              </label>
            </td>

            <td>
              <label class="btnSwitch">
                <input type="checkbox" (click)="updatePermission('brand', false, user._id)"
                  *ngIf="user.allow_share_brand_account" checked />
                <input type="checkbox" (click)="updatePermission('brand', true, user._id)"
                  *ngIf="!user.allow_share_brand_account" />
                <span class="btnSwitchCheck"></span>
              </label>
            </td>

            <td>
              <label class="btnSwitch">
                <input type="checkbox" (click)="updatePermission('company', false, user._id)"
                  *ngIf="user.allow_share_company_account" checked />
                <input type="checkbox" (click)="updatePermission('company', true, user._id)"
                  *ngIf="!user.allow_share_company_account" />
                <span class="btnSwitchCheck"></span>
              </label>
            </td>
            <td>
              <label class="btnSwitch">
                <input type="checkbox" (click)="updatePermission('download', false, user._id)"
                  *ngIf="user.allow_download_videos" checked />
                <input type="checkbox" (click)="updatePermission('download', true, user._id)"
                  *ngIf="!user.allow_download_videos" />
                <span class="btnSwitchCheck"></span>
              </label>
            </td>

            <td>
              {{ user?.last_login?.$date ? user?.last_login?.$date : "Never" }}
            </td>

            <td>{{ user.created?.$date | date : "dd/MM/yyyy" }}</td>

            <td class="tableAction">
              <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                <img src="../assets/images/row-ellipses.svg" width="24" height="6" alt="open" />
              </a>
              <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                <li class="link">
                  <a [routerLink]="['/account-setting/user-account/', user._id]">Edit Account Details</a>
                </li>
                <li class="link" [routerLink]="[
                    '/account-setting/user-account/',
                    user._id,
                    '/user-media-libraries'
                  ]" (click)="loadUserProfileMedia(user._id)">
                  Media Library
                </li>
                <li class="link" (click)="resetUserPwd(user._id)">
                  Reset Password
                </li>
                <li *ngIf="user?.status != 'active'" class="link" (click)="openActivateModal(user._id)">
                  Activate Account
                </li>
                <li *ngIf="user?.status == 'active'" class="link" (click)="openDeactivateModal(user._id)">
                  Deactivate Account
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="table-pagination pb-3 px-4 pagination-list" *ngIf="userList?.total_count > 0">
    <div class="row">
      <!-- <div class="col-sm-4">
        <p class="text-gray-3 text-sm m-0">
          Showing {{ currentPage == 1 ? 1 : (currentPage - 1) * limit + 1 }} -
          {{
            (currentPage > 1 && currentPage < listPages.length
              ? offset + 1
              : offset) + userList?.count
          }}
          out of {{ userList?.total_count }}
        </p>
      </div> -->
      <div class="col-sm-12 text-end" style="padding-right:42px ">
        <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="userList?.total_count"
          [pageSize]="limit" [pageSizeOptions]="[15, 20, 25, 50]" (page)="loadPageEvent($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<!-- user reset password drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showResetPasswordDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeResetPasswordDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <!-- resert password not displayed yet -->

    <nav id="resetPasswordUser">
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Reset Password Link</h3>
        <div class="closeRightSideBar" (click)="closeResetPasswordDrawer()">
          <img src="../assets/images/close-drawer.svg" alt="close" />
        </div>
      </div>
      <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
        <p class="text-md">
          The reset password link is created and copied to the clipboard.
        </p>
        <a href="#" class="text-primary-3 text-lg">https://pronto.ai/resetpassword/erifhdjfdah</a>

        <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-3 mt-4">
          <span class="py-1 d-block">Send Link to User’s Email</span>
        </button>
      </div>
    </nav>
  </ng-container>
</nz-drawer>
<!-- user reset password drawer end -->

<!-- add new user drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showAddNewUserDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeAddNewUserDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <!-- <nav id="addAgencyUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Add User</h3>
        <div class="closeRightSideBar" (click)="closeAddNewUserDrawer()">
          <img src="../assets/images/close-drawer.svg" alt="close" />
        </div>
      </div>
      <!-- Form -->
      <form [formGroup]="addUserForm" *ngIf="addUserForm" (ngSubmit)="addNewUser()">
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
          <div class="formItm">
            <input id="name" formControlName="name" type="text" placeholder="Name" name="name" required="" />
            <label for="name" class="text-sm">Name </label>
            <span *ngIf="
                addUserForm?.controls['name']?.hasError('required') &&
                (addUserForm?.controls['name']?.touched || addUserFormSubmitted)
              " class="error">
              Please enter Name.</span>
          </div>

          <div class="formItm">
            <input id="email" formControlName="email" type="email" value="Si@ja.net" placeholder="User Email"
              name="email" required="" />
            <label for="email" class="text-sm">Email</label>
            <span *ngIf="
                addUserForm?.controls['email']?.hasError('required') &&
                (addUserForm?.controls['email']?.touched ||
                  addUserFormSubmitted)
              " class="error">Please enter Email.</span>
          </div>

          <div class="formItm down_arrow pt-2">
            <p class="text-md text-gray-3 mb-0">Select Role</p>
            <div class="dropdown dropdownSelect">
              <button class="dropdown-toggle text-md text-capitalize" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{
                addUserForm?.controls["role"]?.value == "org_admin"
                ? "Admin"
                : addUserForm?.controls["role"]?.value
                }}
              </button>
              <ul class="dropdown-menu dropdownMenu">
                <li>
                  <a class="dropdown-item text-md" (click)="setAddUserRole('org_admin')">Admin</a>
                </li>
                <li class="selected">
                  <a class="dropdown-item text-md" (click)="setAddUserRole('creator')">Creator</a>
                </li>
                <li>
                  <a class="dropdown-item text-md" (click)="setAddUserRole('viewer')">Viewer</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="formItm down_arrow pt-0">
            <div class="lib-access"><span *ngIf="selectedAddUserBrands.length == 0 && addUserFormSubmitted"
                class="error">
                Please select atleast one brand.</span>
              <p class="weight-600 my-4">Assign Brand</p>
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3 full-length-field">
                  <mat-form-field>
                    <input formControlName="searchBrandText" #brandListAutoComplete
                      (ngModelChange)="this.modelBrandSearchChanged.next($event)" matInput aria-label="State"
                      [matAutocomplete]="autoCompleteBrand" (blur)="hideDropDowns()" />
                    <mat-autocomplete #autoCompleteBrand="matAutocomplete">
                      <mat-option *ngIf="isBrandSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isBrandSearchLoading">
                        <mat-option *ngFor="let brand of brandList.result" [value]="brand?.name"
                          (click)="setAddUserBrands(brand)">
                          <span><b>{{ brand.name }}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>

                </p>

                <ul class="p-0">
                  <div class="part1">
                    <li class="d-block text-md" *ngFor="
                        let selectedBrand of selectedAddUserBrands
                          | slice : 0 : brandListLoopLimit
                      ">
                      {{ selectedBrand.name }}
                      <button type="button" (click)="removeAddUserBrands(selectedBrand)" class="btn-close float-end"
                        aria-label="Close"></button>
                    </li>
                  </div>
                  <a *ngIf="
                      selectedAddUserBrands.length > 3 &&
                      brandListLoopLimit == 3
                    " href="javascript:void(0)" class="vall text-md" (click)="viewAllSelectedBrands(2000)">View All</a>
                  <a *ngIf="
                      selectedAddUserBrands.length > 3 && brandListLoopLimit > 3
                    " href="javascript:void(0)" class="vall text-md" (click)="viewAllSelectedBrands(3)">Show Less</a>
                </ul>
              </div>
            </div>
          </div>

          <div class="pt-3">
            <p class="text-lg text-black weight-600 mb-0">Permissions</p>
            <span class="text-xs text-gray-1 d-inline-block mb-3 mt-1">Share on social accounts</span>
            <div class="form-check mb-2 tableCustom">
              <div class="tableCheck">
                <input formControlName="companyAccounts" *ngIf="addUserForm?.controls['companyAccounts'].value == true"
                  class="form-check-input shadow-none" type="checkbox" checked="checked" id="flexCheckDefault1" />
                <input formControlName="companyAccounts" *ngIf="addUserForm?.controls['companyAccounts'].value == false"
                  class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault1" />
              </div>
              <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault1">
                Company Accounts
              </label>
            </div>
            <div class="form-check mb-2 tableCustom">
              <div class="tableCheck">
                <input formControlName="brandAccount" *ngIf="addUserForm?.controls['brandAccount'].value == true"
                  class="form-check-input shadow-none" checked="checked" type="checkbox" value=""
                  id="flexCheckDefault2" />
                <input formControlName="brandAccount" *ngIf="addUserForm?.controls['brandAccount'].value == false"
                  class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault2" />
              </div>
              <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault2">
                Brand Accounts
              </label>
            </div>
            <div class="form-check mb-2 tableCustom">
              <div class="tableCheck">
                <input formControlName="personalAccounts" *ngIf="
                    addUserForm?.controls['personalAccounts'].value == true
                  " class="form-check-input shadow-none" checked="checked" type="checkbox" value=""
                  id="flexCheckDefault3" />
                <input formControlName="personalAccounts" *ngIf="
                    addUserForm?.controls['personalAccounts'].value == false
                  " class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault3" />
              </div>
              <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault3">
                Personal Accounts
              </label>
            </div>
            <span class="text-xs text-gray-1 my-3 d-inline-block">Downloads</span>
            <div class="form-check mb-4 tableCustom">
              <div class="tableCheck">
                <input formControlName="videoDonwload" *ngIf="addUserForm?.controls['videoDonwload'].value == true"
                  class="form-check-input shadow-none" checked="checked" type="checkbox" value=""
                  id="flexCheckDefault4" />
                <input formControlName="videoDonwload" *ngIf="addUserForm?.controls['videoDonwload'].value == false"
                  class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault4" />
              </div>
              <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault4">
                Download Video Access
              </label>
            </div>
          </div>
          <div class="formItm down_arrow pt-0">
            <div class="lib-access">
              <p class="weight-600 my-4">Select Libraries</p>
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3 full-length-field">
                  <mat-form-field>
                    <input matInput #mediaLibraryListAutoComplete formControlName="searchMediaText" (ngModelChange)="
                        this.modelMediaSearchChanged.next($event)
                      " aria-label="mediasearch" [matAutocomplete]="autoCompleteMedia" (blur)="hideDropDowns()" />
                    <mat-autocomplete #autoCompleteMedia="matAutocomplete">
                      <mat-option *ngIf="isMediaSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isMediaSearchLoading">
                        <mat-option *ngFor="let media of mediaLibraryList?.result" [value]="media.name"
                          (click)="setAddUserMediaLibrary(media)">
                          <span><b>{{ media.name }}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- Search Libraries<button type="button" class="btn-close float-end" aria-label="Close"></button> -->
                </p>
                <ul class="p-0">
                  <div class="part1">
                    <li class="d-block text-md" *ngFor="
                        let selectedMediaLibrary of selectedAddUserMediaLibraries
                          | slice : 0 : mediaLibrariesListLoopLimit
                      ">
                      {{ selectedMediaLibrary.name }}
                      <button (click)="
                          removeAddUserMediaLibrary(selectedMediaLibrary)
                        " type="button" class="btn-close float-end" aria-label="Close"></button>
                    </li>
                  </div>
                  <a href="javascript:void(0)" *ngIf="
                      selectedAddUserMediaLibraries.length > 3 &&
                      mediaLibrariesListLoopLimit == 3
                    " class="vall text-md" (click)="viewAllSelectedMediaLibraries(2000)">View All</a>
                  <a href="javascript:void(0)" *ngIf="
                      selectedAddUserMediaLibraries.length > 3 &&
                      mediaLibrariesListLoopLimit > 3
                    " class="vall text-md" (click)="viewAllSelectedMediaLibraries(3)">Show Less</a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Form Ends -->
        <div class="side-footer side-content d-flex align-items-center">
          <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
            <span class="py-1 d-block">Send Invite</span>
          </button>
        </div>
      </form>
    </nav>
  </ng-container>
</nz-drawer>
<!-- add new user drawer end -->

<!-- user account detail drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showAccountDetailsDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeAccountDetailsDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <!-- <nav id="editAgencyUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Edit Selected Users</h3>
        <!-- <p class="text-gray-3 text-xs mb-0">5 Users Selected</p> -->
        <div class="closeRightSideBar" (click)="closeAccountDetailsDrawer()">
          <img src="../assets/images/close-drawer.svg" alt="close" />
        </div>
      </div>
      <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
        <div class="edit-t-detail">
          <p class="d-flex cruunt-plan m-0">
            <span class="text-gray-2 text-xs">Account Status</span>
            <span class="text-gray-2 text-xs weight-600 text-end">Active</span>
          </p>
          <p class="d-flex cruunt-plan mb-0 mt-4">
            <span class="text-gray-2 text-xs">Email Preferences</span>
            <span class="text-gray-2 text-xs weight-600 text-end">Subscribed</span>
          </p>
          <a href="#" class="text-sm text-primary-3">Unsubscribe</a>
        </div>
        <div class="edit-t-detail border-top pt-4 mt-4">
          <div class="form-switch custom-slidetoggle py-2">
            <input class="form-check-input" type="checkbox" checked="" /><label class="text-md text-gray-2">White
              Label</label>
          </div>
        </div>
      </div>
      <div class="side-footer side-content d-flex align-items-center ps-0" style="position: absolute; bottom: 30px">
        <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-3">
          <span class="py-1 d-block" (click)="deActivateUser()">Deactivate Accounts</span>
        </button>
      </div>
    </nav>
  </ng-container>
</nz-drawer>
<!-- user account detail drawer end -->

<!-- user filter drawer start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showFilterUserDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeFilterUserDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <!-- <nav id="filterAgencyUser" class="rightSideBar right-0 sidebar-block"> -->
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700 d-flex align-items-center">
          Filters
          <a href="javascript:void(0)" class="text-xs text-primary-4 weight-normal ms-3">Clear All</a>
        </h3>
        <div class="closeRightSideBar" (click)="closeFilterUserDrawer()">
          <img src="../assets/images/close-drawer.svg" alt="close" />
        </div>
      </div>
      <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
        <div class="custom-select-box mb-4">
          <h3 class="text-gray-2 text-lg mb-2">White Label</h3>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              First
            </label>
          </div>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Last
            </label>
          </div>
        </div>
        <div class="custom-select-box mb-4">
          <h3 class="text-gray-2 text-lg mb-2">Account Status</h3>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Active
            </label>
          </div>
          <div class="custom-switch">
            <label>
              <input type="checkbox" class="check form-check-input" />
              Deactive
            </label>
          </div>
        </div>
      </div>
      <div class="side-footer d-flex align-items-center">
        <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
          <span class="py-1 d-block">Apply</span>
        </button>
      </div>
    </nav>
  </ng-container>
</nz-drawer>
<!-- user filter drawer end -->

<!-- users limit Modal pop-up Start-->
<nz-modal [(nzVisible)]="isUserLimitModalVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="true"
  (nzOnCancel)="closeUserLimitModal()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="ps-1 pt-0 mb-4">
          <h5 class="mb-2">You've reached your add new users limit.</h5>
          <p class="text-sm text-gray-3 mb-0">
            Upgrade your subscription plan to create more new users.
          </p>
        </div>

        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          (click)="UpgradeUserLimitModal()">Upgrade</a>
        <a class="mt-3 text-gray-3 text-sm" (click)="closeUserLimitModal()">Cancel</a>
      </div>
    </div>
  </div>
</nz-modal>
<!-- users limit Modal pop-up End-->

<!-- Deactivate Modal pop-up Start-->
<nz-modal [(nzVisible)]="isDeactivateModalVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="true"
  (nzOnCancel)="closeDeactivateModal()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h5>
          Are you sure you want to deactivate the account of the selected user?
        </h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          The selected user will no longer have access to his Prontopia  account.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeDeactivateModal()">Cancel</a>
        <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm"
          (click)="deActivateUser()">Deactivate</a>
      </div>
    </div>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="isActivateModalVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="true"
  (nzOnCancel)="closeActivateModal()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h5>
          Are you sure you want to activate the account of the selected user?
        </h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          The selected user will get access to his Prontopia  account.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeActivateModal()">Cancel</a>
        <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm"
          (click)="activateUser()">Activate</a>
      </div>
    </div>
  </div>
</nz-modal>