import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';

@Component({
  selector: 'app-paywall-payment-failed',
  templateUrl: './paywall-payment-failed.component.html',
  styleUrls: ['./paywall-payment-failed.component.scss']
})
export class PaywallPaymentFailedComponent implements OnInit {

  constructor( private activatedRoute: ActivatedRoute,public onboarding:OnboardingManagerService) { 


    this.activatedRoute.queryParams.subscribe((params: Params) => {
this.plan  = params['plan'];
 
    });
  }
plan:string='';

  ngOnInit(): void {
  }

  retry()
  {
    if(this.plan != '')
    {
  this.onboarding.selectPlan(this.onboarding.profile.email,this.plan).then((url:string)=>{
   if(url != '')
   {
    window.location.href =url;
   }
  });
}
  }
  

}
