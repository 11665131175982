import { OnboardingDataService } from "./onboarding-data.service";
import { SearchData, VisualList } from "./../../models/admin";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { locale } from "../../../app/models/account";
import { mediaLib, visual } from "../../../app/models/media";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiURL } from "src/app/shared/conf/api-path";
import { cSessionService } from "../session.service";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(
    private http: HttpClient,
    private sessionService: cSessionService,
    private navSite: ActivatedRoute
  ) {}

  getvisual(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip,
    search_keyword
  ) {
    if (search_keyword === "") {
      return this.getVisualListByMediaLib(
        email,
        session_id,
        visual_type,
        media_lib,
        num_items,
        num_skip
      );
    } else {
      return this.searchVisualListByMediaLib(
        email,
        session_id,
        visual_type,
        media_lib,
        num_items,
        num_skip,
        search_keyword
      );
    }
  }

  getAdminMediaList(search: SearchData) {
    if (search.sort_by == "name") {
      search.sort_by = "_id";
    }
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.visual.getAll, search)
      .toPromise();
  }

  getVisualListByMediaLib(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip
  ) {
    if (media_lib == "") {
      return this.http.get(
        environment.API_HOST +
          "visuals?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_type=" +
          visual_type +
          "&num_items=" +
          num_items +
          "&num_skip=" +
          num_skip
      );
    }
    return this.http.get(
      environment.API_HOST +
        "visuals?email=" +
        email +
        "&session_id=" +
        session_id +
        "&visual_type=" +
        visual_type +
        "&media_lib=" +
        media_lib +
        "&num_items=" +
        num_items +
        "&num_skip=" +
        num_skip
    );
  }
  getThumbBatchSignedURL(email, session_id, urls: string[]) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpaths", JSON.stringify(urls));
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.visual.getThumbnails, {
        session_id: session_id,
        email: email,
        fpaths: urls,
      })
      .toPromise();
  }
  searchVisualListByMediaLib(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip,
    search_keyword
  ) {
    if (media_lib == "") {
      return this.http.get(
        environment.API_HOST +
          "visuals?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_type=" +
          visual_type +
          "&num_items=" +
          num_items +
          "&num_skip=" +
          num_skip +
          "&search_keyword=" +
          search_keyword
      );
    }
    return this.http.get(
      environment.API_HOST +
        "visuals?email=" +
        email +
        "&session_id=" +
        session_id +
        "&visual_type=" +
        visual_type +
        "&media_lib=" +
        media_lib +
        "&num_items=" +
        num_items +
        "&num_skip=" +
        num_skip +
        "&search_keyword=" +
        search_keyword
    );
  }

  getVisualCountBy(email, session_id, visual_type, media_lib) {
    return this.http.get(
      environment.API_HOST +
        "visuals?email=" +
        email +
        "&session_id=" +
        session_id +
        "&visual_type=" +
        visual_type +
        "&media_lib=" +
        media_lib
    );
  }

  deleteVisual(email, session_id, visual_id) {
    return this.http.post(
      environment.API_HOST_ADMIN + ApiURL.admin.visual.delete,
      { visual_id }
    );
  }

  getVisual(email, session_id, visual_id) {
    return this.http
      .get(
        environment.API_HOST +
          "get_visual?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_id=" +
          visual_id
      )
      .toPromise();
  }

  getAdminMediaVisual(email, session_id, visual_fpath) {
    const visual = {
      email: email,
      visual_id: visual_fpath,
    };
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.visual.get, visual)
      .toPromise();
  }

  getMediaData(email, session_id, id) {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get, { _id: id })
      .toPromise();
  }

  getReplaceClips(email, session_id, visual_id, replace_id) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .get(
        environment.API_HOST +
          "replacement_clips?email=" +
          email +
          "&session_id=" +
          session_id +
          "&video_id=" +
          visual_id +
          "&replace_id=" +
          replace_id
      )
      .toPromise();
  }

  getPronotoaudio(email, session_id, num_items, num_skip) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .get(
        environment.API_HOST +
          "audios?email=" +
          email +
          "&session_id=" +
          session_id +
          "&num_items=" +
          num_items +
          "&num_skip=" +
          num_skip
      )
      .toPromise();
  }

  getduration(email, session_id, visual_id) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .get(
        environment.API_HOST +
          "video_duration?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_id=" +
          visual_id
      )
      .toPromise();
  }
  updateVisual(email, session_id, visual_id, keyterms) {
    return this.http.post(environment.API_HOST + "update_visual", {
      email: email,
      session_id: session_id,
      visual_id: visual_id,
      key_terms: keyterms,
    });
  }

  getMediaLibrary(email, session_id, mediaLibStatus) {
    return this.http.get(
      environment.API_HOST +
        "media_libs?email=" +
        email +
        "&session_id=" +
        session_id +
        "&filter_status=" +
        mediaLibStatus
    );
  }

  updateMediaLibrary(
    mediaLibName,
    libActivationStatus,
    sUserEmail,
    sSessionId
  ) {
    return this.http.post(environment.API_HOST + "update_media_lib", {
      email: sUserEmail,
      session_id: sSessionId,
      lib_name: mediaLibName,
      activation_status: libActivationStatus,
    });
  }

  getSignedURL(email, session_id, video_id) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpath", video_id);
    return this.http
      .post(environment.API_HOST_ADMIN + "signed_url_for_download", formData)
      .toPromise();
  }
  getBatchSignedURL(email, session_id, urls: string[]) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpaths", JSON.stringify(urls));
    return this.http
      .post(
        environment.API_HOST_ADMIN + ApiURL.admin.visual.signedUrlMultiDownload,
        {
          session_id: session_id,
          email: email,
          fpaths: urls,
        }
      )
      .toPromise();
  }

  addMediaLibrary(
    email,
    session_id,
    lib_name,
    keyterms,
    description,
    category
  ) {
    return this.http.post(environment.API_HOST + "media_lib", {
      email: email,
      session_id: session_id,
      lib_name: lib_name,
      key_terms: keyterms,
      description: description,
      category: category,
    });
  }

  updloadVisualToMedia(email, session_id, file, is_logo, key_terms, media_lib) {
    let formda = new FormData();
    formda.append("email", email);
    formda.append("session_id", session_id);
    formda.append("file", file);
    formda.append("is_logo", is_logo);
    formda.append("key_terms", key_terms);
    formda.append("media_lib", media_lib);
    return this.http.post(environment.API_HOST + "user_file", formda);
  }

  //BulkUploadvisuals
  async DataService_BulkUpload(
    email,
    session_id,
    media_lib,
    file,
    content_type,
    brandId = undefined,
    ownerType = "user",
    isFont = undefined,
    isSecret = undefined
  ) {

    return await this.uploadMediaForAdmin(
      email,
      session_id,
      media_lib,
      file,
      content_type,
      brandId,
      ownerType,
      isFont,
      isSecret
    );
    //  }
  }

  uploadMediaForAdmin(
    email,
    session_id,
    media_lib,
    file,
    content_type,
    brandId = undefined,
    ownerType = "user",
    isFont = undefined,
    isSecret = undefined
  ) {
    const organizationId = this.sessionService.getOrganizationId();
    let formda = new FormData();
    formda.append("email", email);
    formda.append("session_id", session_id);
    formda.append("filename", file);
    formda.append("media_lib_id", media_lib);
    formda.append("organization_id", organizationId);
    formda.append("content_type", content_type);
    let isOwnerTypeExists = false;
    if (brandId) {
      formda.append("brand_id", brandId);
      formda.append("owner_type", "brand");
      isOwnerTypeExists = true;
    }
    if (isFont) {
      formda.append("is_font", isFont);
    }
    if (isSecret) {
      formda.append("is_secret", isSecret);
    }
    if (ownerType && !isOwnerTypeExists) {
      formda.append("owner_type", ownerType);
    }

    return this.http
      .post(environment.API_HOST_ADMIN + "signed_url_for_upload", formda)
      .toPromise();
  }

  updateMedia(mediaId: string, fPath: string) {
    const media = {
      _id: mediaId,
      fpath: fPath,
    };
    return this.http
      .post(environment.API_HOST_ADMIN + "update_media", media)
      .toPromise();
  }

  uploadBulkMediaForAdmin(email, session_id, media_lib, file, content_type) {
    const organizationId = this.sessionService.getOrganizationId();
    let formda = new FormData();
    formda.append("email", email);
    formda.append("filename", file);
    formda.append("media_lib_id", media_lib);
    formda.append("organization_id", organizationId);
    formda.append("content_type", content_type);
    return this.http
      .post(environment.API_HOST_ADMIN + "signed_url_for_upload", formda)
      .toPromise();
  }

  // DataService_BulkUploadForBrands(email, session_id, media_lib, file, content_type) {
  //   const organizationId = this.sessionService.getOrganizationId();
  //   let formda = new FormData();
  //   formda.append("email", email);
  //   formda.append("filename", file);
  //   formda.append("media_lib_id", media_lib);
  //   formda.append("brand_id", media_lib);
  //   formda.append("organization_id", organizationId);
  //   formda.append("content_type", content_type);

  //   return this.http
  //     .post(environment.API_HOST_ADMIN + "signed_url_for_upload", formda)
  //     .toPromise();
  // }
  //bulk upload
  DataService_UploadFileOnSignedUrl(file: any, sSignedURL: string) {
    if(environment['IS_AZURE'] != undefined && environment['IS_AZURE'] == true){
      return this.http.put(sSignedURL, file,
        { headers:{
        "x-ms-blob-type": "BlockBlob"
      }}).toPromise();
    } else {
      return this.http.put(sSignedURL, file).toPromise();
    }
  }
  uploadFontToGoogleStorage(file: any, sSignedURL: string) {
    if (environment['IS_AZURE'] != undefined && environment['IS_AZURE'] == true) {
      return this.http.put(sSignedURL, file,
        {
          headers: {
            "x-ms-blob-type": "BlockBlob"
          }
        }).toPromise();
    } 
    else{

      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/octet-stream');
      return this.http.put(sSignedURL, file, { headers: httpHeaders }).toPromise();

    }
  }

  AddUserMedia(
    email,
    session_id,
    file,
    media_lib,
    filesize,
    brandId = undefined,
    fpath = undefined
  ) {
    let formda = new FormData();
    const organizationId = this.sessionService.getOrganizationId();
    formda.append("email", email);
    formda.append("session_id", session_id);
    formda.append("filename", file);
    formda.append("size", filesize);
    formda.append("media_lib_id", media_lib);
    formda.append("fpath", fpath);
    if (brandId) {
      formda.append("brand_id", brandId);
    }
    formda.append("organization_id", organizationId);
    return this.http
      .post(environment.API_HOST_ADMIN + "add_user_media", formda)
      .toPromise();
  }

  addAdminMedia(
    email: string,
    session_id: string,
    file: any,
    media_lib_id: string
  ) {
    let formda = new FormData();
    // formda.append("email", email);
    formda.append("user_id", email);
    formda.append("file", file);
    formda.append("media_lib_id", media_lib_id);
    return this.http
      .post(environment.API_HOST_ADMIN + "upload_media", formda)
      .toPromise();
  }
}
