<nav style="position:relative">
    <div class="sidebar-header ps-3 pb-3" style="padding: 3px 20px">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">Edit Title 1
            <p class="mb-0 mt-1 text-xs text-gray-3">These settings apply to single block</p>
        </h3>
        <div class="closeRightSideBar pe-2" style="top: 0;" (click)="DismissSidePanel()" (click)="closeTitle()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0Z"
                    fill="#F2F2F5" />
                <path
                    d="M11.0599 10.0001L13.3599 7.70011C13.6499 7.41011 13.6499 6.93011 13.3599 6.64011C13.0699 6.35011 12.5899 6.35011 12.2999 6.64011L9.99986 8.94011L7.69986 6.64011C7.40986 6.35011 6.92986 6.35011 6.63986 6.64011C6.34986 6.93011 6.34986 7.41011 6.63986 7.70011L8.93986 10.0001L6.63986 12.3001C6.34986 12.5901 6.34986 13.0701 6.63986 13.3601C6.78986 13.5101 6.97986 13.5801 7.16986 13.5801C7.35986 13.5801 7.54986 13.5101 7.69986 13.3601L9.99986 11.0601L12.2999 13.3601C12.4499 13.5101 12.6399 13.5801 12.8299 13.5801C13.0199 13.5801 13.2099 13.5101 13.3599 13.3601C13.6499 13.0701 13.6499 12.5901 13.3599 12.3001L11.0599 10.0001Z"
                    fill="#A7ABB6" />
            </svg>

        </div>
    </div>
  <div class="side-content pt-0 pb-0">
        <div class="video_wrapper video_wrapper_full js-videoWrapper" *ngIf="this.videoManager.selectedVideo.video_url !==''">
            <video controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true" 
            [src]="this.videoUrl"  [currentTime]="this.currentTime[0]" (timeupdate)="onTimeUpdate($event)"   [id]="this.videoManager.selectedVideo.fpath">
            </video>
            <button class="videoPoster js-videoPoster"></button>
            <nz-slider [nzMin]="0"  style="width:100%; position: absolute; bottom:6px"  nzRange  [(ngModel)]="this.currentTime" [nzMax]="this.videoManager.selectedVideo?.duration"   [nzIncluded]="true"      ></nz-slider>
 
        </div>
    </div> 
    <ul class="nav breadcrumb-tabs" id="myTab" role="tablist">
        <li *ngFor="let tab of lSidePanelBlockTabsOptions" class="nav-item pe-0" role="presentation" (click)="TextSidepanelComponent_SelectedTab(tab)">
            <button class="nav-link"[class.active]="ActiveSelectedTabValue == tab.value" id="{{tab.value}}" data-bs-toggle="tab"
                type="button" role="tab" aria-controls="tab1" aria-selected="true">{{tab.name}}</button>
        </li>
    </ul>
    <div class="side-content flex-grow-1 border-top" style="padding: 5px 22px">
        <div class="tab-content text-gray-3 text-xs" id="myTabContent">
            <div *ngIf="ActiveSelectedTabValue == 'tab2'">
                <div class="col-sm-12 col-md-12 col-lg-12 custom-input">

                    <div class="formItm formItmPad"  >
                      
                        <input id="titleName" type="text" [disabled]=" this.selectedBlock?.text.length>199"  [(ngModel)]="this.text" placeholder="Title" class="" maxlength="200"  >
                        <span class="titles-numbers">1</span>
                    </div>
                    
           <!--         <div *ngFor="let tt of  this.selectedBlockCopy?.texts; index as nTextIndex; trackBy: trackByFn" >
                    <div class="formItm formItmPad"  *ngIf="this.selectedBlockCopy?.texts" [ngClass]="nTextIndex==2?'custom-textarea':''">
                        <ng-container *ngIf="nTextIndex ==0">
                            <input id="titleName" type="text" [disabled]="tt.length>199"  [(ngModel)]="this.selectedBlockCopy?.texts[nTextIndex]" placeholder="Title" class="" maxlength="200"  >
                            <span class="titles-numbers">1</span>
                        </ng-container>
                        <ng-container *ngIf="nTextIndex ==1">
                            <input id="titleName" type="text" [disabled]="tt.length>199" [(ngModel)]="this.selectedBlockCopy?.texts[nTextIndex]" placeholder="Sub-Title" class="" maxlength="200"  >
                            <span class="titles-numbers">2</span>
                        </ng-container>
                        <ng-container *ngIf="nTextIndex ==2 ">
                            <textarea id="Add_Description"type="text" [disabled]="tt.length>199" [(ngModel)]="this.selectedBlockCopy?.texts[nTextIndex]" placeholder="Description" maxlength="200" ></textarea>
                            <span class="titles-numbers">3</span>
                        </ng-container>
                    </div>
                </div>-->
                    <!-- <div class="formItm formItmPad">
                        <input id="titleName" type="text"
                            value="Dolor ipsum dolor sit amet, conse"
                            placeholder="Add Title" class="">
                            <span class="titles-numbers">2</span>
                    </div> -->
                    <!-- <div class="formItm formItmPad">
                        <input id="titleName" type="text"
                            value="Dolor ipsum dolor sit amet, conse"
                            placeholder="Add Title" class="">
                            <span class="titles-numbers">3</span>
                    </div> -->
                    <!-- <div class="formItm custom-textarea formItmPad">

                        <textarea id="Add_Description"
                            type="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</textarea>
                            <span class="titles-numbers">4</span>
                    </div> -->
                   
                </div>
            </div>
            <div *ngIf="ActiveSelectedTabValue == 'ta'">
                <div class="side-content p-2 pt-0 flex-grow-1">
         
                    <label for="" class="text-gray-2 mt-4 text-md">Edit Elements</label>
                    <p class="text-gray-3 text-xs mb-3">
                        Please select the element you want to edit.
                    </p>
                    <!-- <ng-container *ngFor="let effect of this.selectedBlockCopy?.effects; index as nEffectIndex;">
                        <div class="border-color mb-3 pb-4">
                            <span class="text-numbers mt-2">{{nEffectIndex+1}}</span>
                        <div class="theme-color d-flex ">
                            <ul class="m-0 ps-0 pe-4">
                                <label class="d-block text-md text-gray-2 pb-3">Visual Color</label>
                                <li>
                                    <input class="form-check-input coloris" type="checkbox"
                                        [value]="effect?.effect_args.color"
                                        (change)="TextSidepanelComponent_GetPrimaryColor($event,nEffectIndex)"
                                        id="checkboxNoLabel" aria-label="...">
                                    <figure class="color-pic no-bg">
                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                [attr.d]="primaryColorIconPath"
                                                [attr.fill]="effect?.effect_args.color" />
                                        </svg>
                                    </figure>
                                </li>
                            </ul>
                        </div>
                        <p class="text-gray-3 text-xs">Select a font to be used in the video</p>
                        <div class="dropdown dropdownSelect">
                            <button class="dropdown-toggle text-md" [ngClass]="effect.effect_args.font.replace('-Bold.ttf','').replace('-Bold.otf','').replace('-ExtraBold.ttf','').replace('-Medium.ttf','').replace('-Regular.ttf','')" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{effect.effect_args.font.replace("-Bold.ttf","").replace("-Bold.otf","").replace("-ExtraBold.ttf","").replace("-Medium.ttf","").replace("-Regular.ttf","")}}
                            </button>
                            <ul class="dropdown-menu dropdownMenu">
                                <li *ngFor="let font of lAllowedFonts;" [class.active]="effect.effect_args.font===font"
                                    [ngClass]="font.replace('-Bold.ttf','').replace('-Bold.otf','').replace('-ExtraBold.ttf','').replace('-Medium.ttf','').replace('-Regular.ttf','')">
                                    <a (click)="SelectFontFamily(font,nEffectIndex)" class="dropdown-item text-md" href="javascript:void(0)">{{font.replace("-Bold.ttf","").replace("-Bold.otf","").replace("-ExtraBold.ttf","").replace("-Medium.ttf","").replace("-Regular.ttf","")}}</a></li>
                            </ul>
                        </div>
                        <div class="mt-5">
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="text-gray-2 text-md">Effects</label>
                                    <div class="dropdown dropdownSelect mt-3">
                                        <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{effect.effect_name.replace("_effect","").replace("_"," ").replace("_"," ")  | titlecase}}
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu custom-dropdown">
                                            <li *ngFor="let text of lTextEffects" [ngClass]="effect.effect_name===text?'active':''"><a class="dropdown-item text-md" href="javascript:void(0)" (click)="SelectEffectName(text,nEffectIndex)">{{text.replace("_effect","").replace("_"," ").replace("_"," ")  | titlecase}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-5 offset-md-2">
                                    <label class="text-gray-2 text-md">Font Size</label>
                                    <div class="dropdown dropdownSelect mt-3">
                                        <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{effect.effect_args.font_size}}
                                        </button>
                                        <ul class="dropdown-menu dropdownMenu custom-dropdown">
                                            <li [ngClass]="effect.effect_args.font_size===size?'active':''" *ngFor="let size of effect.effect_args.font_size % 2 == 0?lEvenFontSizes:lOddFontSizes"><a (click)="ChangeFontSize(size,nEffectIndex)" class="dropdown-item text-md" href="javascript:void(0)">{{size}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </ng-container> -->
                </div>
            </div>
        
        </div>
    </div>
    <div class="side-footer side-content d-flex align-items-center">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 dismissSidepanel" (click)="VideoTimelineComponent_SaveEdits()">Replace</button>
        <button class="d-inline-block small-pad text-gray-3 font-weight-noral text-md ms-3 dismissSidepanel" (click)="VideoTimelineComponent_RevertChanges()">
            Revert to original
        </button>
        
    </div>
</nav>
