import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from '../..//Managers/create-video-manager.service';
 
 



@Component({
  selector: 'app-ftue-create-video-breadcrumb',
  templateUrl: './ftue-create-video-breadcrumb.component.html',
  styleUrls: ['./ftue-create-video-breadcrumb.component.scss']
})
export class FtueCreateVideoBreadcrumbComponent implements OnInit {
  showMenu : string;
   sGetVideoId: string;
   public bDisplayTimelineBreadcrumb:boolean = false;
   @Input() enableMenu : boolean=false;
   sClassText = 'disabled-breadcrumb'
   constructor(private oRouter : Router , public videoManger:CreateVideoManagerService ) { 
     this.showMenu = this.oRouter.url
   }
   ngOnInit() {
     
 
    
     
   }
 
 }
 