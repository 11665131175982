
<!-- error message -->
<section *ngIf="bDisplayErrorMsg  " class="accountSec main-body bg-gray-7 h-100 mt-2" >
    <app-timeout-error [lInputErrorMessage]="sErrorMessage"></app-timeout-error>
</section>
<body  class="{{sAddContentSlide}}" *ngIf="!bDisplayErrorMsg">
    <app-dashboard-header  ></app-dashboard-header>
    <main class="main">
        <section class="page accountSec main-body bg-gray-7 h-100">

            <div class="container">
                <ul class="breadcrumb-tabs nav">
                    <li class="nav-item" role="presentation">
                        <a routerLink="dashboard-overview" class="nav-link active">Overview</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a routerLink="/manage-comments" class="nav-link">Manage Comments</a>
                    </li>
                </ul>
                <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
                    <div class="tab-pane fade show active">

                        <div class="content_area">
                            <div class="container" id="container-view">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-5 ">
                                        <li class="ps-2 pe-2">
                                            <div class="video-outer video-overview" *ngIf="this.videoManager.selectedVideo">
                                                <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                                    <video  id="videoPreview" controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350" [src]=" this.videoManager.selectedVideo?.video_url">
                                                    </video>
                                                    <figure class="video-baner">
                                                        <img [src]=" this.videoManager.selectedVideo?.thumbnail" alt="video">
                                                    </figure>
                                                    <button class="videoPoster js-videoPoster" (click)="DashboardOverviewComponent_PlayVideo()"></button>
                                                </div>
                                            </div>

                                            <div class="my-3 tooltip_link" *ngIf="this.videoManager.selectedVideo?.schedule">
                                                <p class="text-gray-3 text-lg" >Schedule to publish on
                                                    <b class="text-sm">   {{ this.videoManager.selectedVideo?.schedule.coming_schedule | date:'fullTime'}}</b>
                                                    &nbsp;
                                                <a class="text-primary-4 text-lg" (click)="showModal()">update schedule</a>
                                                </p>
                                                <!-- <a class=" text-xs text-gray-3">
                                                    <svg class="me-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M13.6569 13.6569C16.7811 10.5327 16.7811 5.46735 13.6569 2.34315C10.5327 -0.78105 5.46735 -0.78105 2.34315 2.34315C-0.78105 5.46732 -0.78105 10.5327 2.34315 13.6569C5.46732 16.7811 10.5327 16.7811 13.6569 13.6569ZM7.99922 5.6C7.5574 5.6 7.19922 5.24183 7.19922 4.8C7.19922 4.35818 7.5574 4 7.99922 4C8.44105 4 8.79922 4.35818 8.79922 4.8C8.79922 5.24183 8.44105 5.6 7.99922 5.6ZM7.99922 11.9999C7.5574 11.9999 7.19922 11.6418 7.19922 11.1999V7.19994C7.19922 6.75812 7.5574 6.39994 7.99922 6.39994C8.44105 6.39994 8.79922 6.75812 8.79922 7.19994V11.1999C8.79922 11.6418 8.44105 11.9999 7.99922 11.9999Z"
                                                            fill="#A39FAA" />
                                                    </svg>Preview Info
                                                    <span>
                                                        This is a low-resolution, watermarked preview of the video. The
                                                        quality of the final rendered video will be as you specify. Once you
                                                        complete the publishing process, you'll be able to download, view on
                                                        pronto, or share your video right from the platform.
                                                    </span>
                                                </a> -->

                                            </div>
                                        </li>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-5">
                                        <h3 class="primaryFont weight-700 text-gray-2">{{ this.videoManager.selectedVideo?.title}}</h3>

                                        <ul class="video_address ms-0 ps-0 mt-4 pt-1">

                                            <li class="d-block">
                                                <figure>
                                                    <svg width="24" height="14" viewBox="0 0 24 14" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.64817 7.16999C1.45061 6.93291 1.45061 6.58854 1.64818 6.35146C7.03544 -0.113245 16.9646 -0.113245 22.3518 6.35146C22.5494 6.58854 22.5494 6.93291 22.3518 7.16999C16.9646 13.6347 7.03544 13.6347 1.64817 7.16999ZM0.495845 5.39118C6.4828 -1.79315 17.5172 -1.79315 23.5042 5.39118C24.1653 6.18453 24.1653 7.33692 23.5042 8.13027C17.5172 15.3146 6.4828 15.3146 0.495843 8.13027C-0.165282 7.33692 -0.165281 6.18453 0.495845 5.39118ZM14.2936 6.76072C14.2936 8.02746 13.2667 9.05436 12 9.05436C10.7333 9.05436 9.70637 8.02746 9.70637 6.76072C9.70637 5.49398 10.7333 4.46709 12 4.46709C13.2667 4.46709 14.2936 5.49398 14.2936 6.76072ZM15.7936 6.76072C15.7936 8.85589 14.0952 10.5544 12 10.5544C9.90484 10.5544 8.20637 8.85589 8.20637 6.76072C8.20637 4.66556 9.90484 2.96709 12 2.96709C14.0952 2.96709 15.7936 4.66556 15.7936 6.76072Z"
                                                            fill="#BABCBE" />
                                                    </svg>
                                                </figure>
                                                <p>Category</p>
                                                <span class="pill">{{ this.videoManager.selectedVideo?.template_category}}</span>
                                            </li>
                                            <li class="d-block">
                                                <figure>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10.4272 1.40053L4.17534 1.19751L4.17509 1.1977C3.36301 1.1977 2.59171 1.48131 2.06533 2.05061C1.49707 2.61884 1.1716 3.39014 1.21242 4.20222L1.41544 10.453C1.49603 11.5486 1.94289 12.5229 2.71417 13.2942L11.7665 22.3465C12.4153 23.0372 13.308 23.3616 14.1609 23.3616C15.0128 23.3616 15.9054 22.9964 16.5553 22.3465L22.3196 16.5822C23.6591 15.2427 23.6591 13.0911 22.3196 11.7516L13.2684 2.69927C12.4972 1.92799 11.4821 1.48216 10.4272 1.40053ZM3.8101 12.1169C3.32243 11.6292 3.03883 11.0201 2.99802 10.3305L2.83578 4.15927C2.83578 3.79403 2.95717 3.46962 3.20101 3.22578C3.44486 2.98193 3.76927 2.86054 4.13451 2.86054L10.3853 3.06356C11.0352 3.10436 11.6443 3.38902 12.1309 3.87564L21.1832 12.9279C21.8728 13.6186 21.8728 14.7552 21.1832 15.4448L15.42 21.2091C14.7293 21.8987 13.5928 21.8987 12.9031 21.2091L3.8101 12.1169ZM10.7923 7.69222C10.7923 5.98745 9.41191 4.56628 7.66633 4.56628C5.96156 4.56628 4.54039 5.98745 4.54039 7.69222C4.54039 9.43783 5.96156 10.8182 7.66633 10.8182C9.3711 10.8182 10.7923 9.4378 10.7923 7.69222ZM9.16809 7.73308C9.16809 8.54519 8.51924 9.23483 7.66633 9.23483C6.85422 9.23483 6.16458 8.54519 6.16458 7.73308C6.16458 6.92097 6.85422 6.23133 7.66633 6.23133C8.47844 6.23133 9.16809 6.88017 9.16809 7.73308Z"
                                                            fill="#BABCBE" />
                                                    </svg>
                                                </figure>
                                                <p>Keywords</p>
                                                <span class="pill" *ngFor="let tag of  this.videoManager.selectedVideo?.key_terms">{{tag}}</span>
                                            </li>
                                        </ul>

                                        <div class="accordian-section" *ngIf="this.videoManager.selectedVideo?.schedule">
                                        <h3>Schedule</h3>
                                        <!-- <p>The video is scheduled for 12:45 PM, Every Monday in August 2021.</p> -->
                                        <div class="accodion-body">
                                        <nz-collapse nzAccordion [nzBordered]="false">
                                            <nz-collapse-panel

                                              *ngFor="let video of this.videoManager?.selectedVideo?.scheduleVideos;"
                                              [nzHeader]="video.created | date:'h:mm a, MMMM d, y'"
                                              [nzActive]="false"
                                              [nzExtra]="extraTpl"
                                              [ngStyle]="{
                                                background: '#FFFFFF',
                                                'border-radius': '6px',
                                                'margin-bottom': '24px',
                                                'border': '1px solid #F2F2F5'
                                              }" >
                                            <ng-template #extraTpl>
                                                <!-- You can use stopPropagation if you don't want the panel to toggle -->
                                                <img *ngIf="true" src="../../../../assets/images/dashboardVideo/green tick.svg"  width="20" height="20">
                                                <img *ngIf="false" src="../../../../assets/images/dashboardVideo/schedule.svg"  width="20" height="20">
                                              </ng-template>
                                              <div class="accodion-inner">

                                                <div class="video-outer" style="border: 1px solid #EEEEEE;border-radius: 6px;">
                                                    <div class="video_wrapper video_wrapper_full js-videoWrapper" style="padding-top:225px">

                                                        <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true" [src]="video.video_url" [id]="video.fpath" style="height: 225px">
                                                            <span class="video-duration mr-2">00:{{video.duration}}</span></video>

                                                            <button class="videoPoster js-videoPoster" ></button>
                                                            <a href="javascript:void(0)"class=" text-sm text-gray-2 text-truncate" [title]="video.title ">{{video.title}}
                                                            </a>
                                                    </div>
                                                </div>

                                                <!-- <div class="video-outer video-overview" *ngIf="this.videoManager.selectedVideo">
                                                    <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                                        <video  id="videoPreview" controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350" [src]=" this.videoManager.selectedVideo?.url">
                                                        </video>
                                                        <figure class="video-baner">
                                                            <img [src]=" this.videoManager.selectedVideo?.thumbnail" alt="video">
                                                        </figure>
                                                        <button class="videoPoster js-videoPoster" (click)="DashboardOverviewComponent_PlayVideo()"></button>
                                                    </div>
                                                </div> -->

                                              </div>
                                            </nz-collapse-panel>
                                          </nz-collapse>

                                          <!-- <nz-collapse nzAccordion>
                                            <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
                                              <p>{{ panel.name }} content</p>
                                            </nz-collapse-panel>
                                          </nz-collapse> -->

                                        </div>
                                    </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wtermarklogo">
                        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                    </div>
                </div>
            </div>

        </section>
        <app-dashboard-sidepanels></app-dashboard-sidepanels>
    </main>
    <div class="overlay"></div>
    <nz-modal
    [(nzVisible)]="isVisible"
    nzCentered
    [nzWidth]="1800"
    [nzBodyStyle]="{height:'94vh'}"
    [nzFooter]=null
    [nzClosable] = false
    (nzOnCancel)="handleCancel()"
  >
  <app-scheduled-popup (click)="DashboardHeaderComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup" (onClose)="handleCancel()"></app-scheduled-popup>
</nz-modal>


</body>