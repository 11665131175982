import { SafeUrl } from "@angular/platform-browser";
import { SearchData } from "./admin";

export  class visual
{
    isSelected:boolean = false;
    ext: string;
    key_terms?: string[]=[];
    media_lib: string | any;
    name?:string;
    size: number
    thumb: string;
    duration: string;
    videos_used_in: [];
    visual_path: string;
    visual_type?: string;
    assetType:string; //pronoto| user
url:string;
thumbnail:string;
 loadingThumbnail:boolean = true;
 loadingUrl:boolean = true;
 created?: date;
}
export class date {
  $date?: string;
}


export class mediaLib
{
    _id? : string | any;
    name?: string;
    activation_status?: string;
    total_items?: number;
    industry?: string;
    owner?: string;
    owner_type?: string;
    created?: string;
    published_video_count? : number | 0;
    media_uploads? : number | 0;
    library_access?: string;
    lib_id?: string;
}

export class MediaLibSearch extends SearchData
{
    _id?: string;
   result? : mediaLib[];
   records? : mediaLib[];
}


export class mediaFile
{
    status:any;
    file:File;
    name: string;
    error:string;
    uri:SafeUrl;
    loadingThumbnail? : boolean | string
    _id?: string;

}

export class clip
{
    clip_path:string;
    clip_url:string;
    type:string='clip;'
 loading:boolean
}