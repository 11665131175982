import { EventEmitter, Injectable } from '@angular/core';
import { block_args,duration, video } from '../models/video';
import { interval, } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { VideoService } from '../services/dataServices/video.service';
import { LoaderService } from '../services/loader.service';
import { template, templatesCategory } from '../models/template';
import { ConfigManagerService } from './config-manager.service';
import { OnboardingManagerService } from './onboarding-manager.service';
import { ErrorService } from '../services/error.service';


@Injectable({
  providedIn: 'root'
})
export class CreateVideoManagerService {

  constructor(public dataservice: VideoService, public loader: LoaderService, public configManager: ConfigManagerService, public onboardManger: OnboardingManagerService, public errorService: ErrorService) {

  }
  public submitForm: EventEmitter<string> = new EventEmitter();
  public previousSubmit: EventEmitter<number> = new EventEmitter();

  public previewMode: boolean = false;
  public selectedVideo: any;
  public selectedTemplate: template;
  public selectedCategory: templatesCategory;
  public selectedDuration: number;
  public isEditMode: boolean = false;
  public isFirstTime: boolean = false;
  public durations: duration[] = [];
  public blocksconfig: block_args[] = [];


  public setEditVideo(email, session_id, video: video) {


    this.selectedVideo = video;
    this.isEditMode = true;
    this.isFirstTime = false;
console.log('video',video);
    this.selectedVideo.email = email;
    this.selectedVideo.session_id = session_id;


    this.selectedCategory = this.configManager.Templates.filter(c => c.category === this.selectedVideo?.template_category)[0];
    this.selectedTemplate = this.selectedCategory?.templates.filter(c => c.template_name === video.template_name)[0];


    if (this.selectedTemplate) {
      this.changeTemplate(this.selectedTemplate);
    }
    this.getsignedThumbandURLForBlock(email, session_id);
  }

  public newVideo() {
    console.log("NEw video Click")
    this.selectedVideo = undefined;
    this.isEditMode = false;
    this.isFirstTime = false;
  }
  public firstTimeVideo() {
    this.selectedVideo = undefined;
    this.isEditMode = false;
    this.isFirstTime = true;
  }

  getTemplateForVideo(name) {


  }
  public changeTemplate(template: template) {
    this.durations = [];
    this.selectedTemplate = template;

    let numSize = 0;
    console.log('template to Change', template);

    if (template) {
      this.dataservice.getTemplateContent(template.template_name, 'text', this.selectedVideo?.duration).then(res => {
        let texts = res['template_data'];
        console.log('templatesss', texts);
        let prevText:string[] = [];
        this.durations = [];
        texts.forEach((txt, index) => {

          if (txt.text != '_address_' && txt.text != '_url_' && txt.text != '_phone_') {

            this.durations.forEach(d => {

              if (d.placehoder == txt.text) {
                if (txt.ts[0] <= d.duration) {
                  d.duration = txt.ts[0];
                }
              }
            });

            if (!prevText.includes(txt.text)) {
              prevText.push(txt.text);
              let dur = new duration();
              dur.id = template.template_name + index;
              dur.duration = txt.ts[0];
              dur.pos = txt.pos;
              dur.size = txt.size;
              dur.placehoder = txt.text,
                dur.textlist.push({ placeholder: 'Enter text', value: "" });


              this.durations.push(dur);
            }
          }
        });

      });
    }

    /* let blocktemplate = this.selectedTemplate.templateBlockConfiguration.filter(c => c.timeframe == this.selectedVideo.duration)[0];
     this.blocksconfig = blocktemplate.blocks;
 
     blocktemplate.blocks.forEach((ele, index) => {
 
       console.log('element', ele);
       let dur = new duration();
       dur.id = template.template_name + index;
       dur.duration = numSize;
       dur.num_text = ele.num_texts
 
 
 
       for (let nTextAreaToPush = 0; nTextAreaToPush < ele.num_texts; nTextAreaToPush++) {
         if (nTextAreaToPush == 0) {
           dur.textlist.push({ placeholder: "Add Title", value: "" });
         }
         else {
           if (nTextAreaToPush == ele.num_texts - 1) {
             if (ele.num_texts == 2) {
               dur.textlist.push({ placeholder: "Add Subtitle " + nTextAreaToPush, value: "" });
             }
             else {
               dur.textlist.push({ placeholder: "Add Description", value: "" });
             }
           }
           else {
             dur.textlist.push({ placeholder: "Add Subtitle " + nTextAreaToPush, value: "" });
           }
         }
       }
 
 
       if (index == 0) {
         this.durations.push(dur);
       } else {
         this.durations.push(dur);
       }
 
       console.log('video_gen ', ele.video_gen);
       numSize += ele.video_gen?.duration;
 
     });*/
    console.log(this.durations);


  }

  public createVideo(video: video) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.createVideo(video)
        .then(res => {

          this.loader.hideLoader();
          resolve(res);
        }).catch(err => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }

  public DirectLocalizeVideo(video: video) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.direct_localized_video(video)
        .then(res => {

          this.loader.hideLoader();
          resolve(res);
        }).catch(err => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }


  updateVideoStatus(email, session_id, video_id, status): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.updateStatus(email, session_id, video_id, status).then(res => {
        this.loader.hideLoader();
        resolve(res);
      }).catch(err => {
        this.loader.hideLoader();
        reject(err);
      });
    });
    return promise;
  }
  getVideo(email, session_id, video_id): Promise<unknown> {
    let promise = new Promise((resolve, reject) => {
      this.dataservice.getVideo(email, session_id, video_id).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      });

    });
    return promise;

  }
  generateVideo(email, session_id, video_id): Promise<unknown> {

    let promise = new Promise((resolve, reject) => {
      this.dataservice.generateVideo2(email, session_id, video_id).then(async (res:any) => {

        await this.getVideo(email, session_id, video_id).then(async (resi:any) => {
        
          this.selectedVideo = resi['video'] as video;

          await this.dataservice.getSignedURL(email, session_id, video_id).then(result => {
            this.selectedVideo.video_url = result['url'];

          });
          if (this.selectedVideo.thumb != '')
            await this.dataservice.getSignedURL(email, session_id, this.selectedVideo.thumb).then(result => {
              this.selectedVideo.thumbnail = result['url'];

            });

        });


        this.gv_task_id = res['gv_task_id'];

        resolve(res)
      }).catch(err => {
        reject(err);
      });

    });
    return promise;
  }
  public gv_task_id: string;
  public isVideoGenerated: boolean = false;
  generateVideoStatus(email, session_id, gv_task_id): Promise<unknown> {
    if (this.isVideoGenerated) {
      this.isVideoGenerated = false;
    }

    let promise = new Promise((resolve, reject) => {
      interval(2000).pipe(takeWhile(() => !this.isVideoGenerated)).subscribe(() => {




        this.dataservice.generatedVideoStatus(email, session_id, gv_task_id).then((data:any) => {
          if (data['task_status'] == 'success') {
            this.isVideoGenerated = true;
            resolve(data)
          } else {
            this.isVideoGenerated = false;
          }

        }).catch(err => {
          this.isVideoGenerated = true;
          reject(err);
        });
      });
    });

    return promise;
  }

  getsignedThumbandURLForBlock(email, session_id) {

    if (this.selectedVideo.music_file) {
      console.log('musicfile',this.selectedVideo.music_file);
      this.dataservice.getSignedURL(email, session_id, this.selectedVideo.music_file).then(res => {

        this.selectedVideo.music_file_url = res['url'];


      }).catch(err => {

      });
    }
    if (this.selectedVideo.voice_file) {

      console.log('voice_file',this.selectedVideo.voice_file);
      this.dataservice.getSignedURL(email, session_id, this.selectedVideo.voice_file).then(res => {

        this.selectedVideo.voice_file_url = res['url'];


      }).catch(err => {

      });
    }

    if (this.selectedVideo.vo_text_file) {

      console.log('vo_text_file',this.selectedVideo.vo_text_file);
      this.dataservice.getSignedURL(email, session_id, this.selectedVideo.vo_text_file).then(res => {

        this.selectedVideo.voice_file_url = res['url'];

      }).catch(err => {

      });
    }


    let batchUrl: string[] = [];

    this.selectedVideo.template_info?.contents?.filter(c => c.content == 'clip').forEach(ele => {
if(ele.source)
{
      batchUrl.push(ele.source);
      ele.loadingUrl = true;
}

    });



    this.dataservice.getThumbBatchSignedURL(email, session_id, batchUrl).then(res => {

      let output = res['output'];
      console.log('output', output);
      this.selectedVideo.template_info?.contents?.filter(c => c.content == 'clip').forEach(ele => {
        ele.loadingThumbnail = false;
        ele.thumbnail = output.filter(a => a.fpath == ele.source)[0].thumb_signed_url;
        setTimeout(() => {
          ele.loadingThumbnail = false;
        }, 2000);
      });

    });

    this.dataservice.getBatchSignedURL(email, session_id, batchUrl).then(res => {

      let output = res['output'];
      console.log('output', output);
      this.selectedVideo.template_info?.contents?.filter(c => c.content == 'clip').forEach(ele => {
        ele.loadingUrl = false;
        ele.url = output.filter(a => a[ele.source])[0][ele.source];
        setTimeout(() => {
          ele.loadingUrl = false;
        }, 2000);
      });

    });


  }

}