import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from '../..//Managers/create-video-manager.service';
 
 



@Component({
  selector: 'app-create-video-breadcrumb',
  templateUrl: './create-video-breadcrumb.component.html',
  styleUrls: ['./create-video-breadcrumb.component.scss']
})
export class CreateVideoBreadcrumbComponent implements OnInit {
 showMenu : string;
  sGetVideoId: string;
  public bDisplayTimelineBreadcrumb:boolean = false;
  @Input() enableMenu : boolean=false;
  @Input() localiseExistingVideos;
  sClassText = 'disabled-breadcrumb'
  constructor(private oRouter : Router , public videoManger:CreateVideoManagerService ) { 
    this.showMenu = this.oRouter.url
  }
  ngOnInit() {
    

   
  }

}
