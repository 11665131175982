import { StringMap } from "@angular/compiler/src/compiler_facade_interface";
import { MediaLibrary } from "./admin";
import { address } from "./common";

export class user
{
name:string;
session_id:string;
email:string;
 videoCount:number=0;
 draftVideoCount:number=0;
 publishedVideoCount:number =0;
 ScheduledVideoCount:number=0;
 LocationVideoCount:number=0;


 getVideoCount():number
 {
 return  this.draftVideoCount + this.publishedVideoCount +this.ScheduledVideoCount+this.LocationVideoCount;
 }
}

export class socialplateforms
{
    facebook:boolean;
    google:boolean;
    hubspot:boolean;
    instagram:boolean;
    linkedin:boolean;
    microsoft:boolean;
    twitter:boolean;
    youtube:boolean;
}

export class profile
{

    password?:string;
    session_id?:string;
    business_name?: string;
    url?: string;
    business_type?: string;
    address?: address;
    phone?: string;
    email?: string;
    color?: string;
    font_color?: string;
    logo_file?: string;
    logo_file_url?: string;
    greeting?: string;
    mood?: string;
    location?: string;
    name?: string;
    username?: string;
    is_subscribed_to_email?: Number;
    key_terms?:[];
    industry?: string;
    allowed_media_libs?:[];
    allowed_templates?:string[];
    connected_social_platforms?:socialplateforms;
    created_at?:string;
    first_name?:string;
    last_name?:string;
    localization_enabled?:boolean;
    plan?:string;
    status?:string;
    vertical?:string;
    company_name? :string;
    is_onboarding?:boolean =false;
    user_status?: string;
    next_billing_date?:string;
    media_lib? : any;
    media_libs? : MediaLibrary[];
    get_pronto_newsletter? : any;
    user_logo_file?: string;
    _id?: string;
    organization_id?: StandardId;

}

export class StandardId{
    $oid?: string
}
export class UserProfile {
    _id?: string;
    allow_download_videos?: string;
    allow_share_brand_account?: string;
    allow_share_company_account?: string;
    allow_share_personal_account?: string;
    created?: string;
    first_name?: string;
    last_name?: string;
    subscribe_email_changes?: string;
    updated?: string;
    username?: string;
    media_lib?: string | any;
    media_libs?: MediaLibrary[];
    media_uploads?: number | 0;
    public_videos?:number  | 0;
    session_id?: string;
    status?: string;
    user_role?:string | string[];
    brand_accounts?: boolean | false;
    personal_accounts?: boolean | false;
    download_access?: boolean | false;
    company_accounts?: boolean | false;
    get_pronto_newsletter?: boolean | false;
    account_changes?: boolean | false;
}