

                <div class="pt-4 px-0 d-flex flex-column">
                    <div class="data-table m-0 d-flex flex-column flex-grow-1">
                        <div class="table-header row m-0 px-4">

                            <div class="col-5 px-0 text-start">
                                <h3 class="weight-700 text-gray-2 mb-1">Locations</h3>
                                <p class="mx-0 my-0 text-sm">Keep your business information up to date for
                                    the best </p>
                            </div>
                            <div class="col-7 px-0 text-end">
                                <button type="button"  (click)="showModal()"
                                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-0 float-end mt-1 text-xs">Upload
                                    Location File
                                </button>
                                <div class="float-end border-start d-inline-block ps-3 py-1 ms-1 mt-2"><a
                                        class="text-gray-6 text-sm me-4" href="javascript:void(0)"
                                        (click)="openLocation()"
                                        >Add a location</a>
                                </div>
                                <div
                                    class="float-end dropdown dropdownBtn btn-dropDown d-inline-block ms-4 ">
                                </div>

                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                    <li>
                                        <a class="dropdown-item text-md " href="#;">Bulk Action</a>
                                    </li>
                                </ul>
                             
                            </div>
                        </div>
                        <div class="flex-grow-1">

                            <div class="py-3 my-3 px-5 border-top">
                                <p class="m-0"><span class="text-lg text-gray-2">Total Locations </span>
                                    <span
                                        class="badge rounded-pill bg-gray-3 ms-2 w-30 d-inline-block custom-badge text-xs">{{oTotalCount}}</span>
                                        
                                </p>
                            </div>
                            <table class="table overflow-auto tableCustom table-hover mt-3 mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="tableCheck">
                                            <div class="custom-switch">
                                                <!-- <input type="checkbox" class="check form-check-input"
                                                    id="checkAll"> -->
                                            </div>
                                        </th>
                                        <th scope="col" class="uname-width">Locations</th>
                                        <th scope="col">Address 1</th>
                                        <th scope="col">Address 2</th>
                                        <th scope="col">City</th>
                                        <th scope="col">State</th>
                                        <th scope="col">Zip</th>
                                        <th scope="col">Website</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col" class="tableAction">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of this.accountManger.locales;let i as index">
                                        <td scope="row" class="tableCheck">
                                            <div class="custom-switch">
                                                <input type="checkbox" class="check form-check-input">
                                            </div>
                                        </td>
                                        <td><a class="b_userName">{{item.name}}</a></td>
                                        <td>{{item.address.line1}}</td>
                                        <td>{{item.address.line2}}</td>
                                        <td>{{item.address.city}}</td>
                                        <td>{{item.address.state}}</td>
                                        <td>{{item.address.postal_code}}</td>
                                        <td>{{item.url}}</td>
                                        <td>{{item.phone}}</td>
                                        <td class="tableAction" >
                                            <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                                        fill="#7A7A7A"></path>
                                                </svg>
                                            </a>
                                            <ul class="dropdown-menu add_location page-shadow"
                                                aria-labelledby="dropdownMenuButton1">
                                                
                                                <li data-sidebar="#updateLocation" (click)="editLocation(item)">Edit Location</li>
                                                <li (click)="deleteLocale(item)">Delete Location</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-pagination py-3 px-4 ">
                                <div class="row">
                                    <div class="col-sm-4">
                                    </div>
                                    <div class="col-sm-8 text-end">
                                        <ul id="pagination" *ngIf="this.accountManger.locales?.length>0">
                                            <li>
                                                <a href="javascript:void(0)" class="preview-page"  (click)="nCurrentPage==1?'':loadPage(nCurrentPage-1)"
                                                        >
                                                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                                                            fill="#7A7A7A" />
                                                    </svg>
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)"><span class="add_color">
                                                    {{nCurrentPage}} 
                                                </span>of {{this.lPagination.length}}</a>
                                            </li> -->
                                            <li *ngFor="let page of this.lPagination; let i as index">
                                                <a href="javascript:void(0)"  [ngClass]="i==nCurrentPage?'add_color':''"  
                                                        (click)="loadPage(page)"  >{{page}}</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" class="next-page" (click)="nCurrentPage==lPagination.length?'':loadPage(nCurrentPage+1)"
                                                    >
                                                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                                                            fill="white" />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!-- Location side Nav Start -->
<nz-drawer
[nzClosable]="false"
[nzVisible]="visibleLocation"
[nzPlacement]="placementLocation"
(nzOnClose)="closeLocation()"
[nzWidth]="478"
>
<ng-container *nzDrawerContent>
<nav >
<div class="sidebar-header pt-2 pb-3">
<h3 class="text-gray-2 weight-700">Add a new location</h3>
<div (click)="closeLocation()" class="closeRightSideBar">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
        fill="#F2F2F5" />
    <path
        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
        fill="#A7ABB6" />
</svg>
</div>
</div>
<form [formGroup]="this.fAddLocationForm" *ngIf="this.fAddLocationForm">
<div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
<div class="formItm mb-4">
    <input id="name" type="text" placeholder="" required="" formControlName="name">
    <label for="name" class=" fs-6 fw-normal">Name</label>
    <span *ngIf="this.fAddLocationForm?.controls['name']?.hasError('required') && fAddLocationForm?.controls['name']?.touched" class="error">Please enter your Name.</span>
            
</div>

<div class="formItm">
    <input id="address" type="text" placeholder="Address 1" required="" name="textfeild"
        formControlName="line1">
    <label for="loginEmail" class=" fs-6 fw-normal">Address 1</label>
    </div>
<div class="formItm">
    <input id="address" type="text" placeholder="Address 1" required="" name="textfeild"
        formControlName="line2">
    <label for="loginEmail" class=" fs-6 fw-normal">Address 2</label>
    
</div>
<div class="formItm">
    <input id="city" type="text" placeholder="Houston" required="" formControlName="city">
    <label for="FName" class=" fs-6 fw-normal">City</label>
    </div>

<div class="formItm">
    <input id="state" type="text"  placeholder="Texas" required="" name="textfeild" formControlName="state">
    <label for="loginEmail" class=" fs-6 fw-normal">State</label>
    </div>

    <!-- <div class="formItm down_arrow">
    <p class="fs-6 fw-normal text-gray-3 mb-0">State</p>
    <div class="dropdown dropdownSelect">
        <button class="dropdown-toggle text-md dropdownSelectValid" type="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            LA
        </button>
        <ul class="dropdown-menu dropdownMenu">
            <li class="active"><a class="dropdown-item text-md"href="javascript:void(0)">LA</a></li>
        <li><a class="dropdown-item text-md"href="javascript:void(0)">IU</a></li>
        <li><a class="dropdown-item text-md"href="javascript:void(0)">LA</a></li>
        </ul>           
    </div>
</div> -->

<div class="formItm">
    <input id="zip" type="text" placeholder="1234321" required="" name="textfeild" formControlName="postal_code">
    <label for="loginEmail" class=" fs-6 fw-normal">Zip</label>
    
</div>
<div class="formItm">
    <input id="phoneno" type="text" placeholder="+00 - 12345678" required="" name="textfeild"
        formControlName="phone">
    <label for="loginEmail" class=" fs-6 fw-normal">Phone Number</label>
    
</div>
<div class="formItm">
    <input id="website" type="text" placeholder="subway.com" required="" name="textfeild" formControlName="url">
    <label for="loginEmail" class=" fs-6 fw-normal">Website</label>
    
</div>
</div>

<div class="side-footer d-flex align-items-center">
<button [disabled]="this.fAddLocationForm.value.name == ''" class=" btn btn-primary btn-round-6 d-inline-block" (click)="addLocale()">
<span class="d-block">{{this.fAddLocationForm?.controls['id'].value == ''? 'Add Location' :'Edit Location'}} </span>
</button>
</div>
</form>
</nav>
</ng-container>
</nz-drawer>
<!-- contactus sidebar Modal end-->


<!-- modal Upload Location File start -->
<nz-modal
[(nzVisible)]="isVisible"
nzCentered
[nzWidth]="780"
[nzFooter]=null 
[nzClosable] = false
(nzOnCancel)="handleCancel()"
>
<div>
<div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
<div class="modal-content" style="padding: 10px 40px 40px 40px !important;">
    <div class="modal-header">
        <h5 class="modal-title pb-5" id="exampleModalLabel">Upload Location File</h5>
        <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="handleCancel()"></button>
    </div>
    <div class="modal-body mt-0">
        <div class="form-group files">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                    aria-labelledby="pills-device-tab">

                    <div class="file-drop-area">
                        <figure>
                            <svg width="23" viewBox="0 0 17 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                        fill="#7A7A7A" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16.0013" height="15.9809" fill="white"
                                            transform="translate(0 0.00958252)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </figure>
                        <span class="choose-file-button">Upload a File</span>
                        <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                            drag & drop your file here</span>
                        <span class="file-message">No file selected</span> 
                        <input #resetField (click)="LocationsComponent_ResetField()" class="file-input" type="file" accept=".csv" (change)="LocationsComponent_ReadCSVFile($event)" multiple>
                    </div>

                </div>
                <div class="tab-pane fade" id="pills-drive" role="tabpanel"
                    aria-labelledby="pills-drive-tab">
                    <div class="file-drop-area">
                        <figure>
                            <svg width="23" viewBox="0 0 17 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                        fill="#7A7A7A" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16.0013" height="15.9809" fill="white"
                                            transform="translate(0 0.00958252)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </figure>
                        <span class="choose-file-button">Upload a File (Drive)</span>
                        <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                            drag & drop your file here</span>
                        <span class="file-message">No file selected</span> 
                        <input #resetField class="file-input" type="file" multiple>
                    </div>
                </div>
                <ul class="nav mb-3" id="pills-tab" role="tablist">
                    <li class="col nav-item" role="presentation">
                        <button class="nav-link" (click)="resetField.click()" id="pills-device-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-device" type="button" role="tab"
                            aria-controls="pills-device" aria-selected="true">
                            <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                            <span>My Device</span></button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</nz-modal>
