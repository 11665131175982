import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  showError: boolean = false;
  errorMessage: string;
  errorTitle: string;
  showSubscriptionButton?: boolean = false;
  showReplaceIcon = false;
  buttonTitle: string = "Ok";
  constructor() {}

  handleError(
    error: any,
    errorTitle: string,
    showDialogue: boolean,
    autoClose?: boolean,
    showSubscription?: boolean,
    showReplaceIcon = false,
    options  : any = {}
  ) {
    this.showError = showDialogue;
    this.errorMessage = error;
    this.errorTitle = errorTitle;
    this.showSubscriptionButton = showSubscription;
    this.showReplaceIcon = showReplaceIcon;
    if (autoClose) {
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    }
  }

  closeErrorDialogue() {
    this.showError = false;
  }
}
