import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-localise-existing-videos',
  templateUrl: './localise-existing-videos.component.html',
  styleUrls: ['./localise-existing-videos.component.scss']
})
export class LocaliseExistingVideosComponent implements OnInit {

  constructor(private createvideoManager:CreateVideoManagerService, public onBoardingManager: OnboardingManagerService, private oRoutes: Router,private location: Location) { }

  ngOnInit(): void {
  }
  GenerateVideo()
  {
  this.createvideoManager.submitForm.emit("save");
  }
  Cancel()
  {
 if (this.onBoardingManager.loggedUser.videoCount > 0) {
      this.oRoutes.navigate(['dashboard/myvideos']);
    }
    else {
      this.oRoutes.navigate(['/dashboard/first-time-user']);

    }
  }
  back() {
    this.location.back();
  }
 
}
