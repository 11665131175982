import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { VideoManagerService } from './../../Managers/video-manager.service';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  bDisplaySchedularBtn: boolean = false;
  bFullHeader: boolean = false;
  oVideoDetails : any;
  bDisplayLoader : boolean = false;
  sUserEmail : string;
  sSessionId : string;
  sVideoStatus : string;
  oSetTimeOut:any;
  bIsPublish: boolean=true;
  oGeneratedVideo : any = {"url" : "", "thumb" : "",};
  showMenu: any;
  oClosePopup: any=0;

  constructor(private oRouter : Router,private _location: Location, public videoManager:VideoManagerService, private createVideoManger:CreateVideoManagerService,private oSessionService : cSessionService, private oEditVideoService : cEditVideoService, private oWebSocketService : cWebSocketService, private oDataService : cDataService) 
  { 
     
    this.showMenu = this.oRouter.url;
    this.bDisplaySchedularBtn = !(this.videoManager.selectedVideo?.schedule);
    if(this.oRouter.url === '/dashboard/download' || this.oRouter.url === '/dashboard/socialmedia' || this.oRouter.url === '/dashboard/overview' || this.oRouter.url === '/manage-comments')
    {
      this.bFullHeader = true
    } 
  }

  ngOnInit(): void 
  {
    // this.InitializeJquery();
 
    console.log("HEADER CALLLEEDDD");
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();  
    this.sVideoStatus = this.videoManager.selectedVideo?.publish_status
    console.log(this.sVideoStatus);
 
  
    console.log("Video Status is=====>>>>>",this.sVideoStatus);
    this.DashboardHeaderComponent_DisableEdit();
  }
  DashboardHeaderComponent_DisableEdit()
  {
    if(this.sVideoStatus=="published" || this.sVideoStatus=="scheduled")
    {
      this.bIsPublish=false;
    }
  }

  editVideo()
  {
   
    this.createVideoManger.setEditVideo(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo);
    this.oRouter.navigate(['wizard/overview']);
  }


  deleteVideo()
  {
    this.videoManager.deleteVideo(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo.fpath).then(_=>{

      this.oRouter.navigate(['/my-videos/draftsVideo']);
    }).catch(err=>{

    });
  }

  DashboardHeaderComponent_TogglePublishStatus()
  {
    let status = this.sVideoStatus==="draft"?"published":"draft";


this.videoManager.updateVideoStatus(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo.fpath,status).then(res=>{

  this.videoManager.selectedVideo.publish_status = status;
  if(this.sVideoStatus==="published")
  {
    
    this.oRouter.navigate(['/my-videos/draftsVideo']);
  }
  else
  {
    
    this.oRouter.navigate(['/published-video/timeline-view']);
  }
}).catch(err=>{

});
  }
  DashboardHeaderComponent_NavigateToDashboard()
  {
   
      
   
    if(this.sVideoStatus == 'draft')
    {
      this.oRouter.navigate(['/my-videos/draftsVideo']);
    }
    else
    {
      this.oRouter.navigate(['/dashboard/myvideos']);
    }

  }
  DashboardHeaderComponent_GetClickLocation(event)
  {
    this.oClosePopup=0;
    if(event.clientX <= 65)
    {
      this.oClosePopup=1;
      setTimeout(() => {this.oClosePopup=0;}, 200);
    }
  }
  

  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

}
