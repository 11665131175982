
    <header class="header header-1 drop-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="d-flex w-100">
                    <div class="col d-flex align-items-center">
                        <div class="back_cta h-auto">
                            <a href="javascript:void(0)" (click)="TimelineViewComponent_NavigateToDashboard()">
                                <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                            </a>
                        </div>
                        <h5 class="text-gray-1 pt-2">{{sVideoTitle}}</h5>
                        <span class="ms-2 text-xs bg-gray-3 text-white labelTag">{{sVideoDuration}}s</span>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <section class="accountSec main-body bg-gray-7 h-100">
        <div class="container">
            <ul class="breadcrumb-tabs nav">

                <li class="nav-item" role="presentation">
                    <a routerLink="/published-video/timeline-view" class="nav-link active">Publish</a>
                </li>
            </ul>
            <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
                <div class="tab-pane fade show active">
                    <div class="content_area border-bottom py-4 bg-gray-9">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="d-flex">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0Z"
                                            fill="#483D8F" />
                                        <g clip-path="url(#clip0_924:25977)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M24.0043 10.9704C24.2026 11.1709 24.1948 11.4885 23.987 11.6798L13.8103 21.0466C13.6092 21.2317 13.2929 21.2315 13.092 21.0463L8.01257 16.3629C7.80491 16.1714 7.79745 15.8538 7.9959 15.6534C8.19435 15.4531 8.52358 15.4459 8.73124 15.6374L13.4516 19.9897L23.269 10.9537C23.4768 10.7624 23.8061 10.7698 24.0043 10.9704Z"
                                                fill="white" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M22.7674 10.4087C23.2678 9.94805 24.0516 9.96467 24.5311 10.4496C25.0252 10.9493 25.0042 11.7503 24.4887 12.2248L14.312 21.5916C13.8272 22.0378 13.0743 22.0375 12.5899 21.5909L7.51047 16.9075C6.99529 16.4325 6.97515 15.6314 7.46965 15.1322C7.94951 14.6477 8.73333 14.6317 9.23338 15.0928L13.4521 18.9826L22.7674 10.4087ZM8.51497 15.8186L8.51472 15.8183L8.51497 15.8186Z"
                                                fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_924:25977">
                                                <rect width="17.7778" height="17.7778" fill="white"
                                                    transform="translate(7.11108 7.11133)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div class="ms-3">
                                        <p class="text-sm m-0 text-gray-2"> <b>Success!</b></p>
                                        <p  class="text-xs m-0 text-gray-3">Your video has been published on Prontopia .ai at this URL
                                            <a href="{{sPublicVideoUrl}}" target="_blank" class="text-primary-4">{{sPublicVideoUrl | slice:0:37}}</a>
                                        </p>
                                    </div>
                                    <button class="text-gray-2 text-xs d-flex align-items-center mx-3 mt-3" (click)="TimelineViewComponent_CopyToClipBoard()">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M5.58137 4.20465C5.58137 2.95738 5.83424 2.22948 6.26441 1.79932C6.69457 1.36915 7.42246 1.11628 8.66974 1.11628H11.7953C13.0426 1.11628 13.7705 1.36915 14.2007 1.79932C14.6308 2.22948 14.8837 2.95738 14.8837 4.20465V7.33023C14.8837 8.57751 14.6308 9.30541 14.2007 9.73557C13.7705 10.1657 13.0426 10.4186 11.7953 10.4186H11.5349V8.66978C11.5349 7.31241 11.2668 6.21705 10.5249 5.47512C10.4321 5.38237 10.3339 5.29703 10.2303 5.21869C9.86533 4.94278 9.43412 4.75374 8.9444 4.634C8.46086 4.51578 7.92029 4.46512 7.33021 4.46512H5.58137V4.20465ZM4.46509 4.46513V4.20465C4.46509 2.84728 4.73315 1.75192 5.47508 1.00999C6.21701 0.268057 7.31237 0 8.66974 0H11.7953C13.1527 0 14.2481 0.268057 14.99 1.00999C15.7319 1.75192 16 2.84728 16 4.20465V7.33023C16 8.68761 15.7319 9.78297 14.99 10.5249C14.2481 11.2668 13.1527 11.5349 11.7953 11.5349H11.5349V11.7954C11.5349 13.1527 11.2668 14.2481 10.5249 14.99C9.78297 15.732 8.68761 16 7.33023 16H4.20465C2.84728 16 1.75192 15.732 1.00999 14.99C0.268057 14.2481 0 13.1527 0 11.7954V8.66978C0 7.31241 0.268057 6.21705 1.00999 5.47512C1.75192 4.73319 2.84728 4.46513 4.20465 4.46513H4.46509ZM8.83929 5.7616C8.44764 5.64462 7.95386 5.58141 7.33023 5.58141H4.20465C2.95738 5.58141 2.22948 5.83428 1.79932 6.26445C1.36915 6.69461 1.11628 7.42251 1.11628 8.66978V11.7954C1.11628 13.0426 1.36915 13.7705 1.79932 14.2007C2.22948 14.6309 2.95738 14.8837 4.20465 14.8837H7.33023C8.57751 14.8837 9.30541 14.6309 9.73557 14.2007C10.1657 13.7705 10.4186 13.0426 10.4186 11.7954V10.9824C10.4186 10.9805 10.4186 10.9786 10.4186 10.9767V8.66977C10.4186 7.42249 10.1657 6.69459 9.73554 6.26443C9.70698 6.23587 9.6771 6.20808 9.64584 6.1811C9.44176 6.00494 9.17893 5.86305 8.83929 5.7616Z"
                                                fill="#7A7A7A" />
                                        </svg>
                                        <span class="ms-2">Copy URL</span>
                                    </button>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="!bExpandReach" class="content_area border-bottom py-4 bg-gray-9">
                        <div class="timelineViewOnProntoTop">
                            <div class="row">
                                <div class="col-md-5">
                                    <h6 class="text-gray-1">Expand your reach</h6>
                                    <p class="text-gray-2 text-sm mt-1">
                                       Enable Prontopia  to auto-create and publish new videos
                                    </p>
                                    <p class="text-gray-3 text-xs">
                                        Schedule custom videos to be auto-created and published on
                                        Prontopia  without ever lifting a finger.
                                    </p>
                                    <button (click)="showModal()"
                                        class="btn btn-primary btn-round-6 d-inline-block small-pad px-4">Start</button>
                                    <button class="ms-3 d-inline-block small-pad text-gray-3 font-weight-noral text-sm ms-3"
                                     (click)="bExpandReach=true">Not right now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_area py-0">
                        <div class="timelineViewOnProntoTop">
                            <ul class="breadcrumb-tabs nav">
                                <li class="nav-item ps-0 pe-0" role="presentation">
                                    <a (click)="TimelineViewComponent_DisplayProntoDownload()" href="javascript:void(0)"
                                        class="nav-link ps-0" [class.active]="bDisplayProntoDownload">Download Video</a>
                                </li>
                                <li class="nav-item px-0" role="presentation">
                                    <a (click)="TimelineViewComponent_DisplayProntoShare()" href="javascript:void(0)" class="nav-link" [class.active]="bDisplayProntoShare">Share on
                                        Social Media</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="content_area border-top px-2">
                        <app-timeline-view-pronto *ngIf="bDisplayProntoDownload"></app-timeline-view-pronto>
                        <app-timeline-view-pronto-share *ngIf="bDisplayProntoShare"></app-timeline-view-pronto-share>
                    </div>



                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </section>

    <!-- viewOnProntoModal -->
    <div class="modal fade modalCustom" id="viewOnProntoModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom">
                    <div>
                        <h5 class="modal-title text-gray-2 mb-0">View on Prontopia .ai</h5>
                        <div >
                            <span class="text-sm text-gray-2">{{sPublicVideoUrl | slice:0:37}}</span>
                            <button (click)="TimelineViewComponent_CopyToClipBoard()"
                                class="ms-3 btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad d-inline btnShadow">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.55312 5.89004C2.09273 6.34984 1.83284 7.11767 1.83284 8.40015V10.9305C1.83284 12.2129 2.09273 12.9808 2.55312 13.4406C3.01351 13.9004 3.78231 14.1599 5.06643 14.1599H7.59997C8.88409 14.1599 9.65289 13.9004 10.1133 13.4406C10.5737 12.9808 10.8336 12.2129 10.8336 10.9305V8.93285C10.8336 8.65703 11.0574 8.43344 11.3336 8.43344C11.6098 8.43344 11.8336 8.65703 11.8336 8.93285V10.9305C11.8336 12.3115 11.5601 13.4081 10.8204 14.1468C10.0807 14.8856 8.98272 15.1587 7.59997 15.1587H5.06643C3.68367 15.1587 2.58566 14.8856 1.84596 14.1468C1.10625 13.4081 0.832764 12.3115 0.832764 10.9305V8.40015C0.832764 7.01915 1.10625 5.92254 1.84596 5.18378C2.58566 4.44502 3.68367 4.17188 5.06643 4.17188H7.06659C7.34276 4.17188 7.56663 4.39547 7.56663 4.67128C7.56663 4.94709 7.34276 5.17068 7.06659 5.17068H5.06643C3.78231 5.17068 3.01351 5.43024 2.55312 5.89004Z"
                                        fill="#404243" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.87521 4.2099C7.06206 4.13261 7.27714 4.17533 7.42015 4.31816L11.6872 8.57973C11.8302 8.72256 11.8729 8.93736 11.7955 9.12397C11.7182 9.31059 11.5358 9.43226 11.3336 9.43226H9.20007C8.37231 9.43226 7.66134 9.30092 7.17969 8.81988C6.69804 8.33884 6.56653 7.62879 6.56653 6.80208V4.67129C6.56653 4.4693 6.68836 4.2872 6.87521 4.2099ZM7.56661 5.87696V6.80208C7.56661 7.57345 7.70179 7.92879 7.88685 8.11362C8.07192 8.29845 8.42771 8.43346 9.20007 8.43346H10.1264L7.56661 5.87696Z"
                                        fill="#404243" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.23328 1.34169C7.23328 1.06588 7.45715 0.842285 7.73332 0.842285H10.4002C10.6764 0.842285 10.9002 1.06588 10.9002 1.34169C10.9002 1.6175 10.6764 1.84109 10.4002 1.84109H7.73332C7.45715 1.84109 7.23328 1.6175 7.23328 1.34169Z"
                                        fill="#404243" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M6.66658 1.84109C5.83599 1.84109 5.16646 2.50977 5.16646 3.3393C5.16646 3.61511 4.94259 3.8387 4.66642 3.8387C4.39026 3.8387 4.16638 3.61511 4.16638 3.3393C4.16638 1.95814 5.28366 0.842285 6.66658 0.842285H8.41339C8.68955 0.842285 8.91343 1.06588 8.91343 1.34169C8.91343 1.6175 8.68955 1.84109 8.41339 1.84109H6.66658Z"
                                        fill="#404243" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M14.6672 4.8374C14.9434 4.8374 15.1673 5.06099 15.1673 5.3368V9.45854C15.1673 10.7665 14.1033 11.829 12.7937 11.829C12.5176 11.829 12.2937 11.6054 12.2937 11.3296C12.2937 11.0538 12.5176 10.8302 12.7937 10.8302C13.551 10.8302 14.1672 10.2148 14.1672 9.45854V5.3368C14.1672 5.06099 14.3911 4.8374 14.6672 4.8374Z"
                                        fill="#404243" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.4756 0.880315C10.6624 0.803016 10.8775 0.845743 11.0205 0.988571L15.0208 4.98379C15.1638 5.12662 15.2066 5.34142 15.1292 5.52804C15.0518 5.71465 14.8695 5.83633 14.6672 5.83633H12.6671C11.8893 5.83633 11.2117 5.71331 10.7509 5.25308C10.29 4.79285 10.1669 4.11608 10.1669 3.33931V1.3417C10.1669 1.13971 10.2887 0.957613 10.4756 0.880315ZM11.1669 2.54737V3.33931C11.1669 4.06075 11.2938 4.38279 11.458 4.54681C11.6223 4.71084 11.9447 4.83752 12.6671 4.83752H13.46L11.1669 2.54737Z"
                                        fill="#404243" />
                                </svg>
                                <span class="ms-2">Copy Link</span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center pe-3">
                        <a class="text-gray-6 text-sm me-4 d-flex align-items-center" href="javascript:void(0);" data-bs-dismiss="modal" (click)="TimelineViewComponent_DisplayProntoDownload()">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.99895 0.104004C7.72609 0.103919 7.50484 0.325043 7.50477 0.597897L7.50288 7.99192L4.38464 4.5619C4.20109 4.36 3.90351 4.36 3.71997 4.5619C3.53643 4.76379 3.53643 5.09113 3.71997 5.29302L7.65785 9.62464C7.8414 9.82653 8.13898 9.82653 8.32252 9.62464L12.2604 5.29302C12.4439 5.09113 12.4439 4.76379 12.2604 4.5619C12.0769 4.36 11.7793 4.36 11.5957 4.5619L8.49098 7.97709L8.49286 0.598202C8.49293 0.325349 8.2718 0.104088 7.99895 0.104004Z"
                                    fill="#BABCBE" />
                                <path
                                    d="M1.09234 7.21903C1.09234 6.94617 0.871147 6.72498 0.598294 6.72498C0.32544 6.72498 0.104248 6.94617 0.104248 7.21903V8.45404C0.104248 12.5468 3.42213 15.8647 7.51494 15.8647H8.505C12.5978 15.8647 15.9157 12.5468 15.9157 8.45404V7.21903C15.9157 6.94617 15.6945 6.72498 15.4216 6.72498C15.1488 6.72498 14.9276 6.94617 14.9276 7.21903V8.45404C14.9276 12.0011 12.0521 14.8766 8.505 14.8766H7.51494C3.96784 14.8766 1.09234 12.0011 1.09234 8.45404V7.21903Z"
                                    fill="#BABCBE" />
                            </svg>
                            <span class="ms-2">Download</span>
                        </a>
                        <a class="text-gray-6 text-sm me-4 d-flex align-items-center" href="javascript:void(0);" data-bs-dismiss="modal" (click)="TimelineViewComponent_DisplayProntoShare()">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0404 4.70933 14.0408 6.31064 14.2437 8.15875C14.2738 8.43324 14.0758 8.6802 13.8013 8.71034C13.5268 8.74048 13.2798 8.54239 13.2497 8.2679C13.0793 6.71602 12.2397 5.37066 11.0213 4.5239C10.7946 4.36631 10.7385 4.05473 10.8961 3.82797Z"
                                    fill="#BABCBE" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M5.13495 3.85786C5.29438 4.08333 5.24084 4.39535 5.01536 4.55478C3.80918 5.40763 2.98222 6.7527 2.8241 8.29744C2.79598 8.57215 2.55049 8.77205 2.27578 8.74393C2.00108 8.71581 1.80118 8.47032 1.8293 8.19561C2.01784 6.35369 3.00421 4.75208 4.43803 3.73827C4.66351 3.57884 4.97553 3.63239 5.13495 3.85786Z"
                                    fill="#BABCBE" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M5.01428 13.7334C5.13947 13.4873 5.44049 13.3892 5.68662 13.5144C6.3904 13.8724 7.19281 14.0734 8.03995 14.0734C8.85745 14.0734 9.62262 13.8906 10.3078 13.5572C10.5561 13.4363 10.8553 13.5396 10.9762 13.7879C11.097 14.0362 10.9937 14.3355 10.7454 14.4563C9.92394 14.8562 9.00911 15.0734 8.03995 15.0734C7.03375 15.0734 6.07616 14.8344 5.23327 14.4057C4.98714 14.2805 4.88909 13.9795 5.01428 13.7334Z"
                                    fill="#BABCBE" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.03998 1.92676C7.29255 1.92676 6.68665 2.53267 6.68665 3.28009C6.68665 4.02752 7.29255 4.63342 8.03998 4.63342C8.7874 4.63342 9.39331 4.02752 9.39331 3.28009C9.39331 2.53267 8.7874 1.92676 8.03998 1.92676ZM5.68665 3.28009C5.68665 1.98038 6.74027 0.926758 8.03998 0.926758C9.33969 0.926758 10.3933 1.98038 10.3933 3.28009C10.3933 4.5798 9.33969 5.63342 8.03998 5.63342C6.74027 5.63342 5.68665 4.5798 5.68665 3.28009Z"
                                    fill="#BABCBE" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M3.22003 10.0732C2.47261 10.0732 1.8667 10.6792 1.8667 11.4266C1.8667 12.174 2.47261 12.7799 3.22003 12.7799C3.96746 12.7799 4.57337 12.174 4.57337 11.4266C4.57337 10.6792 3.96746 10.0732 3.22003 10.0732ZM0.866699 11.4266C0.866699 10.1269 1.92032 9.07324 3.22003 9.07324C4.51974 9.07324 5.57337 10.1269 5.57337 11.4266C5.57337 12.7263 4.51974 13.7799 3.22003 13.7799C1.92032 13.7799 0.866699 12.7263 0.866699 11.4266Z"
                                    fill="#BABCBE" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12.7801 10.0732C12.0327 10.0732 11.4268 10.6792 11.4268 11.4266C11.4268 12.174 12.0327 12.7799 12.7801 12.7799C13.5275 12.7799 14.1334 12.174 14.1334 11.4266C14.1334 10.6792 13.5275 10.0732 12.7801 10.0732ZM10.4268 11.4266C10.4268 10.1269 11.4804 9.07324 12.7801 9.07324C14.0798 9.07324 15.1334 10.1269 15.1334 11.4266C15.1334 12.7263 14.0798 13.7799 12.7801 13.7799C11.4804 13.7799 10.4268 12.7263 10.4268 11.4266Z"
                                    fill="#BABCBE" />
                            </svg>
                            <span class="ms-2">Share</span>
                        </a>
                        <a class="text-gray-6 text-sm me-4 d-flex align-items-center" href="javascript:void(0);" data-bs-dismiss="modal" (click)="unPublishVideo()">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12.5545 5.00969C12.1836 3.27239 11.2966 2.06479 10.1538 1.36926C8.93674 0.628529 7.48398 0.501615 6.17402 0.856189C4.86405 1.21077 3.65702 2.05757 2.94406 3.31865C2.31037 4.43953 2.09152 5.84455 2.48842 7.43482C-1.04333 8.31104 -0.806193 13.9592 3.1392 14.2483C3.44889 14.271 3.71777 14.0302 3.73974 13.7104C3.76172 13.3907 3.52848 13.1131 3.21879 13.0904C0.426856 12.8858 0.430601 8.70977 3.20339 8.49172C3.54618 8.48829 3.89229 8.57356 4.20554 8.73832C4.48218 8.88383 4.82069 8.77024 4.96162 8.48461C5.10255 8.19898 4.99253 7.84948 4.71589 7.70397C4.37742 7.52594 4.01067 7.4081 3.63429 7.35808C3.227 5.94183 3.41929 4.78076 3.91505 3.90386C4.45875 2.94217 5.39962 2.26587 6.45914 1.97908C7.51866 1.69229 8.65698 1.80577 9.58288 2.36929C10.3836 2.85664 11.0678 3.70609 11.4021 5.02578C11.1288 5.0894 10.8638 5.18758 10.6104 5.31839C10.3327 5.46175 10.2201 5.81039 10.359 6.09711C10.4978 6.38382 10.8355 6.50004 11.1132 6.35668C11.4229 6.19677 11.7528 6.1104 12.094 6.10106C14.7774 6.52575 15.8614 10.2847 13.797 12.149L13.7889 12.1564C13.1501 12.756 12.3192 13.0878 11.4579 13.0812C11.1475 13.0788 10.8939 13.3367 10.8916 13.6572C10.8893 13.9778 11.1391 14.2396 11.4496 14.242C12.5952 14.2508 13.6965 13.8101 14.5412 13.0191C17.2453 10.5713 15.9637 5.79324 12.5545 5.00969ZM8.68899 12.1607C8.90813 12.3877 8.90751 12.7552 8.68758 12.9815L8.0266 13.6616L8.68758 14.3416C8.90751 14.5679 8.90813 14.9354 8.68899 15.1624C8.46984 15.3895 8.1139 15.3902 7.89398 15.1639L7.23018 14.4809L6.56638 15.1639C6.34645 15.3902 5.99052 15.3895 5.77137 15.1624C5.55222 14.9354 5.55285 14.5679 5.77278 14.3416L6.43376 13.6616L5.77278 12.9815C5.55285 12.7552 5.55222 12.3877 5.77137 12.1607C5.99052 11.9336 6.34645 11.933 6.56638 12.1592L7.23018 12.8422L7.89398 12.1592C8.1139 11.933 8.46984 11.9336 8.68899 12.1607Z"
                                    fill="#7A7A7A" />
                            </svg>
                            <span class="ms-2">Unpublish</span>
                        </a>
                        <a class="text-gray-6 text-sm me-4 d-flex align-items-center" href="javascript:void(0);" data-bs-dismiss="modal" (click)="deleteVideo()">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                    fill="#BABCBE" />
                            </svg>
                            <span class="ms-2">Delete</span>
                        </a>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="TimelineViewComponent_ResetVideo()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                            <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                <video  #videoPreview controls  controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350" [src]="oPublicUrl">
                                </video>
                                <button class="videoPoster js-videoPoster" (click)="TimelineViewComponent_PlayVideo()"
                                    style="background-image: url({{oGeneratedVideo?.thumbnail}})">
                                </button>
                            </div>
                            <div class="mt-5">
                                <h5 class="text-gray-1 mb-2">Comments</h5>
                                <div class="formItm ">
                                    <input id="serviceImpeccable" type="text" placeholder="Service was Impeccable."
                                        value="Service was Impeccable." required="">
                                </div>
                                <div class="formItm ">
                                    <input id="joinDiscussion" type="text" placeholder="Join the Discussion"
                                        value="Join the Discussion" required="">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <h3 class="primaryFont weight-700 text-gray-2 mb-2">{{sVideoTitle}}
                            </h3>
                            <p class="text-gray-2 text-sm pt-1 mb-0">
                                This is a low-resolution, watermarked preview of the video. The quality of the final
                                rendered video will be as you specify. Once you complete the publishing process, you'll
                                be able to download, view on pronto, or share your video right from the platform.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <nz-modal
        [(nzVisible)]="isVisible"
        nzCentered
        [nzWidth]="1800"
        [nzFooter]=null
        [nzClosable] = false
        (nzOnCancel)="handleCancel()"
      >

        <app-scheduled-popup (click)="TimelineViewComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup"  (onClose)="handleCancel()"></app-scheduled-popup>
        </nz-modal>

    <script>
        $(".selectionDurationDropDown .dropdown-item").on("click", function () {
            var dropVal = $(this).text();
            $(".durationOption").removeClass("active");
            $(".autoCreateModalTop").removeClass("autoCreateModalTopEveryYear");
            if (dropVal === "Every Week") {
                $("#durationEveryWeek").addClass("active");
            }
            else if (dropVal === "Every Month") {
                $("#durationEveryMonth").addClass("active");
            }
            else if (dropVal === "Every Year") {
                $("#durationEveryYear").addClass("active");
                $(".autoCreateModalTop").addClass("autoCreateModalTopEveryYear");
            }
        });
    </script>