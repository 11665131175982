import { cClientService } from './../../services/client.service';
import { cWebSocketService } from './../../services/websocket.service';
import { Httpinterceptor } from './../../interceptor/httpinterceptor.service';
import { SharedModuleModule } from './../../shared/shared-module.module';
import { cSessionService } from 'src/app/services/session.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatInputModule } from '@angular/material/input';

import { UsersComponent } from './users/user-list/users.component';
import { UserAccountsComponent } from './users/user-account/user-account.component'; 
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule } from 'src/app/_alert';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgWaveformModule } from 'ng-waveform';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { AdminSettingService } from './services/admin-setting-service';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BrandsIdentityComponent } from './brand-identity/brand-identity.component'; 
import { LocationsComponent } from './locations/locations.component';
import { SociallChannelsComponent } from './sociall-channels/sociall-channels.component';
import { StorageDetailsComponent } from './storage-details/storage-details.component';
import { PublisheddVideoComponent } from './publishedd-video/publishedd-video.component';
import { MediaLibrariesComponent } from './media-libraries-page/list/media-libraries.component'; 
import { MediaPageLibraryyComponent } from './media-libraries-page/media/media-libraryy.component'; 
import { UserAccountPublishedVideosComponent } from './users/user-published-videos/user-published-videos.component';
import { UserAccountScheduleVideoComponent } from './users/user-account-schedule-video/user-account-schedule-video.component';
import { UserAccountMediaLibrariesComponent } from './users/user-account-media-libraries/user-account-media-libraries.component';
import { ManageBrandComponent } from './manage-brand/manage-brand.component';
import { ManageBrandAccountComponent } from './manage-brand/account/manage-brand-account.component';
import { ManageBrandPublishedVideosComponent } from './manage-brand/manage-brand-published-videos/manage-brand-published-videos.component';
import { ManageBrandScheduleVideosComponent } from './manage-brand/manage-brand-schedule-videos/manage-brand-schedule-videos.component';
import { ManageBrandMediaLibrariesComponent } from './manage-brand/manage-brand-media-libraries/manage-brand-media-libraries.component';
import { AccountSettingComponent } from './account-setting.component';
import { ManageBrandsBusinessDetailsComponent } from './manage-brand/manage-brands-business-details/manage-brands-business-details.component';
import { BillingComponent } from './billing/billing.component';
import { LoginSecurityComponent } from './login-security/login-security.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MediaPlatformsComponent } from './media-platforms/media-platforms.component';
import { cDataService } from 'src/app/services/data.service';
import { MediaLibraryAccountComponent } from './media-libraries-page/account/media-library-account';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { Userservice } from './users/service/user-service';
import { BrandMediaLibsComponent } from './manage-brand/list-media-libs/brand-media-libs.component';
import { FontFileNameTruncatePipe } from 'src/app/services/pipes/font-file-name-truncate.pipe';

@NgModule({
  declarations: [
    UsersComponent,
    UserAccountsComponent,         
    BrandsIdentityComponent,
    LocationsComponent,
    SociallChannelsComponent,
    StorageDetailsComponent,
    PublisheddVideoComponent,
    MediaLibrariesComponent,
    MediaPageLibraryyComponent, 
    MediaLibraryAccountComponent,   
    UserAccountPublishedVideosComponent,
    UserAccountScheduleVideoComponent,
    UserAccountMediaLibrariesComponent,    
    ManageBrandComponent,
    ManageBrandAccountComponent,
    BrandMediaLibsComponent,
    ManageBrandPublishedVideosComponent,
    ManageBrandScheduleVideosComponent,
    ManageBrandMediaLibrariesComponent,
    BillingComponent,
    LoginSecurityComponent,
    MyAccountComponent,
    SubscriptionComponent,
    MediaPlatformsComponent,          
    ManageBrandsBusinessDetailsComponent,    
    AccountSettingComponent,
    FontFileNameTruncatePipe
  ],
  providers: [
    DatePipe,
    cSessionService,
    cClientService,
    cWebSocketService,
    AdminSettingService,    
    MatPaginatorIntl,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Httpinterceptor,
      multi: true
     },
    cDataService,
  ],  
  imports: [
    CommonModule,
    BrowserModule,
    AlertModule,
    NgZorroAntdModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgWaveformModule,
    SlickCarouselModule,
    MatAutocompleteModule,
    MatIconModule,
    NzDrawerModule,        
    SharedModuleModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,    
    MatInputModule,
    MatPaginatorModule
    // AppModule
  ],
  exports: [
    UsersComponent,
    UserAccountsComponent,  
  ],
})
export class AdminAppModule { }
