<app-loader *ngIf="this.loader.loading"></app-loader>
<router-outlet *ngIf="!this.showSplashScreen"></router-outlet>
<app-splashscreen *ngIf="this.showSplashScreen"></app-splashscreen>
<nz-modal
  [(nzVisible)]="this.errorService.showError"
  nzCentered
  [nzWidth]="566"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="closeErrorModal()"
  style="z-index: 2000000000"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!--  <div class="modal-body">
          <button type="button" class="btn-close cust-close closeRightBtn" style="right: -15px !important;" (click)="closeErrorModal()"></button>

          <h5>{{this.errorService.errorTitle}}</h5>
          <p class="text-sm text-gray-3 pt-4 pb-4 mb-0">{{this.errorService.errorMessage}}</p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeErrorModal()">Ok</a>
           <a href="javascript:void(0)"
              (click)="LoginSecurityComponent_SignOutFromAllDevices()" class="mt-3 text-gray-3 text-sm">Sign
              out</a>
      </div>-->

      <div class="modal-body">
        <button
          type="button"
          class="btn-close cust-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeErrorModal()"
        ></button>
        <h5 class="mb-4">{{ this.errorService.errorTitle }}</h5>
        <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
          <div class="col-1 p-0">
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                  fill="#FF8369"
                ></path>
                <path
                  d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                  fill="#FF8369"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="28" height="29" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="col-11 p-0">
            <p class="text-sm text-gray-3 mb-0 ms-2">
              {{ this.errorService.errorMessage }}
            </p>
          </div>
        </div>

        <a
          class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          data-bs-dismiss="modal" [ngStyle]="{'marginLeft': this.errorService.showSubscriptionButton && this.meta_info?.metaInfo?.stripe_enabled ? '70px' : '5px'}"
          (click)="closeErrorModal()"
          >{{ this.errorService.buttonTitle }}</a
        >

        <a class="weight-normal btn btn-primary btn-round-6 cust-pad me-4"data-bs-dismiss="modal"  style="margin-left: 20px;" (click)="goToSubscribe()"
          *ngIf="this.errorService.showSubscriptionButton && this.meta_info?.metaInfo?.stripe_enabled"
          >Renew</a>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="this.successMessageService.show"
  nzCentered
  [nzWidth]="566"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="this.successMessageService.close()"
  style="z-index: 2000000000"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-body loading-icon">
          <h5 *ngIf="this.successMessageService.showHead">{{this.successMessageService.title}}</h5>
          <div class="text-sm text-gray-3 pt-1 pb-0 mb-0">
            <span [ngClass]="{'noHead' : !this.successMessageService.showHead}">{{this.successMessageService.message}}</span>
            <div class="lds-default absolute-right" *ngIf="this.successMessageService.showLoader" [ngClass]="{'noHead' : !this.successMessageService.showHead}">
            </div>
            <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4 mt-4" *ngIf="this.successMessageService.showBtn" (click)="this.successMessageService.close()">Ok</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
  *ngIf="this.planManager.planDialogue"
  [(nzVisible)]="this.planManager.planDialogue"
  [nzWidth]="1440"
  [nzBodyStyle]="{ height: '92.1vh', marginTop: '-65px' }"
  [nzFooter]="null"
  [nzClosable]="false"
  [nzMaskClosable]="false"
  [nzMask]="false"
  (nzOnCancel)="closePlanModal()"
  style="z-index: 2000000000"
>
  <!-- <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePlanModal()"></button> -->

  <app-select-plan [showAsDialogue]="true"></app-select-plan>
</nz-modal>
