<div class="h-80vh br12 curve-bg tab-content bg-white page-shadow  m-0 p-0" id="pills-tabContent">
<div class="tab-pane fade show active">
<div class="content_area p-0">
<div class="row m-0">

<div class="col-sm-12 col-md-12 col-lg-5 h-80vh ps-5 pt-4">
    <div class="row ps-4 pt-3 mt-1">
        <p class="text-lg text-gray-3 primaryFont"><span class="counts">1. </span> Please upload the video to localize</p>
        <div>
            <button class="btn weight-600 btn-outline-gray-radius text-white btn-secondary"
                data-bs-toggle="modal" data-bs-target="#uploadModal">
                <svg class="me-1" width="17" height="16" viewBox="0 0 17 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.47648 9.27169C8.47641 9.54422 8.25514 9.76508 7.98227 9.76499C7.70939 9.76491 7.48824 9.54391 7.48831 9.27138L7.4902 1.90131L4.38519 5.31243C4.20163 5.51408 3.90403 5.51408 3.72047 5.31243C3.53691 5.11077 3.53691 4.78383 3.72047 4.58217L7.65867 0.255732C7.84222 0.054079 8.13983 0.054079 8.32339 0.255732L12.2616 4.58217C12.4451 4.78383 12.4451 5.11077 12.2616 5.31242C12.078 5.51408 11.7804 5.51408 11.5969 5.31242L8.47837 1.8865L8.47648 9.27169ZM0.598577 6.71753C0.871452 6.71753 1.09266 6.93846 1.09266 7.21098V8.44452C1.09266 11.9874 3.96839 14.8594 7.51577 14.8594H8.50591C12.0533 14.8594 14.929 11.9874 14.929 8.44452V7.21098C14.929 6.93846 15.1502 6.71753 15.4231 6.71753C15.696 6.71753 15.9172 6.93846 15.9172 7.21098V8.44452C15.9172 12.5324 12.599 15.8463 8.50591 15.8463H7.51577C3.42264 15.8463 0.104492 12.5324 0.104492 8.44452V7.21098C0.104492 6.93846 0.325701 6.71753 0.598577 6.71753Z"
                            fill="#FFFF">
                        </path>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="16.0013" height="15.9809" fill="white"></rect>
                        </clipPath>
                    </defs>
                </svg>

                Upload a File
            </button>

            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-3" (click)="showMediaModal()" 
                        >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37278 1.37278C2.36587 0.379685 3.85193 0 5.76744 0H10.2326C12.1481 0 13.6341 0.379685 14.6272 1.37278C15.6203 2.36587 16 3.85193 16 5.76744V10.2326C16 12.1481 15.6203 13.6341 14.6272 14.6272C13.6341 15.6203 12.1481 16 10.2326 16H5.76744C3.85193 16 2.36587 15.6203 1.37278 14.6272C0.379685 13.6341 0 12.1481 0 10.2326V5.76744C0 3.85193 0.379685 2.36587 1.37278 1.37278ZM2.16211 2.16211C1.48078 2.84343 1.11628 3.96203 1.11628 5.76744V10.2326C1.11628 12.038 1.48078 13.1566 2.16211 13.8379C2.84343 14.5192 3.96203 14.8837 5.76744 14.8837H10.2326C12.038 14.8837 13.1566 14.5192 13.8379 13.8379C14.5192 13.1566 14.8837 12.038 14.8837 10.2326V5.76744C14.8837 3.96203 14.5192 2.84343 13.8379 2.16211C13.1566 1.48078 12.038 1.11628 10.2326 1.11628H5.76744C3.96203 1.11628 2.84343 1.48078 2.16211 2.16211Z" fill="#FF8369"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 4.33333C0.666992 4.14924 0.907699 4 1.20463 4H14.796C15.093 4 15.3337 4.14924 15.3337 4.33333C15.3337 4.51743 15.093 4.66667 14.796 4.66667H1.20463C0.907699 4.66667 0.666992 4.51743 0.666992 4.33333Z" fill="#FF8369"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33366 0C5.70185 0 6.00033 0.246384 6.00033 0.550314V4.11635C6.00033 4.42028 5.70185 4.66667 5.33366 4.66667C4.96547 4.66667 4.66699 4.42028 4.66699 4.11635V0.550314C4.66699 0.246384 4.96547 0 5.33366 0Z" fill="#FF8369"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 0C11.0349 0 11.3333 0.265144 11.3333 0.592217V4.07445C11.3333 4.40152 11.0349 4.66667 10.6667 4.66667C10.2985 4.66667 10 4.40152 10 4.07445V0.592217C10 0.265144 10.2985 0 10.6667 0Z" fill="#FF8369"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22493 8.46551C7.14028 8.51871 7.00036 8.68819 7.00036 9.13016V10.8697C7.00036 11.3116 7.14028 11.4811 7.22493 11.5343C7.30921 11.5873 7.51299 11.6345 7.86348 11.414L9.25064 10.5442C9.25075 10.5441 9.25054 10.5443 9.25064 10.5442C9.6008 10.3238 9.66631 10.1073 9.66631 9.99992C9.66631 9.8925 9.60111 9.67626 9.25095 9.45584C9.25085 9.45577 9.25105 9.4559 9.25095 9.45584L7.86348 8.58588C7.51299 8.36535 7.30921 8.41253 7.22493 8.46551ZM8.36435 7.64477C7.82794 7.30738 7.22492 7.20957 6.7241 7.52437C6.22354 7.839 6 8.45592 6 9.13016V10.8697C6 11.5439 6.22354 12.1608 6.7241 12.4755C7.22493 12.7903 7.82796 12.6925 8.36438 12.355C8.36427 12.3551 8.36448 12.355 8.36438 12.355L9.75186 11.4851C10.2884 11.1475 10.6667 10.6281 10.6667 9.99992C10.6667 9.37169 10.2884 8.85235 9.75186 8.51475L9.05762 8.07946L8.36435 7.64477C8.36447 7.64484 8.36424 7.6447 8.36435 7.64477Z" fill="#FF8369"/>
                            </svg> 
                            &nbsp;	&nbsp; Select From Media Library
                    </button>
        </div>
    </div>


    <div class="row ps-4 pt-3 mt-1 pe-5" *ngIf="this.uploadedVdeo != null">
        <div class="video-outer media-content-inner d-flex">

            <div class="video_wrapper video_wrapper_full js-videoWrapper p-0">
                <figure class="video-baner">
                <img [src]="this.uploadedVdeo?.thumb"style="height:100px; width: 150px; object-fit: cover;">
                    </figure>
                <button class="videoPoster js-videoPoster Poster" ></button>
                <div style="position:absolute; width:100%; height: 100%; top: 0; left:0;cursor:pointer;" (click)="displayVideoPopup()"></div> 
            </div>

            <div class="ps-3">
                <p class="text-lg text-gray-2" style="cursor:pointer;" (click)="displayVideoPopup()">{{this.uploadedVdeo?.filename}}</p>
                <p class="video-duration py-1" *ngIf="this.uploadedVdeo?.duration">{{this.uploadedVdeo?.duration}}</p>
            </div>
        </div>
    </div>



</div>


<div class="col-sm-12 col-md-12 col-lg-3"></div>

<div class="col-sm-12 col-md-12 col-lg-4 small-side pt-4">
    <div class="right-sde-info enable-loc-div ps-5">
        <nz-drawer [nzClosable]="false" [nzVisible]="visibleLocation" [nzPlacement]="placementLocation"
            (nzOnClose)="closeLocation()" [nzWidth]="478" [nzKeyboard]="false">
            <ng-container *nzDrawerContent>

                <div class="overlay sidebarOverlay"></div>
                <nav class="pt-3">
                    <div class="sidebar-header pt-2">
                
                    </div>
                    <div class="sidebar-content p-0">
                        <div class="locations d-flex flex-column h-86vh">
                            <div class="top-sec px-4 ">
                                <p class="weight-700 text-lg mb-0">Select locations</p>
                                <p class="text-md text-gray-2">{{oTotalCount}} locations configured in
                                    the system</p>
                            </div>
                            <div class="mid-sec px-4">
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <div class="tableCustom">
                                            <div class="tableCheck d-flex">
                                                <div class="custom-switch">
                                                    <input type="checkbox"
                                                        [(ngModel)]="this.AllLocaleSelected"
                                                        (change)="CreateVideoOverViewComponent_SelectAllLocations($event)"
                                                        class="check form-check-input" id="checkAll">
                                                </div>
                                                <span
                                                    class="text-xs text-gray-2 ms-2 opaque-6 mt-1">Select All <br>{{this.AllLocaleSelected
                                                    ?this.oTotalCount:lSelectedLocations.length}} 
                                                    locations selected</span>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pb-0">
                                        <div
                                            class="float-end searchbox-slide d-inline-block m-0 p-0 mt-1">
                                            <!-- <div class="searchbox">
                <input type="search" placeholder="Search locations" name="search"
                    class="searchbox-input" onkeyup="buttonUp();" required="">
                <input type="submit" class="searchbox-submit" value="GO">
                <span class="searchbox-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                            fill="#A39FAA"></path>
                    </svg>
                </span>
            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="bottom-sec p-0 flex-grow-1 overflow-auto tableCustom mb-0">
                                <li *ngFor="let item of this.lLocales">
                                    <div class="py-3 px-4">
                                        <div class="tableCheck d-flex">
                                            <div class="custom-switch">
                                                <input type="checkbox"
                                                    (change)="CreateVideoOverviewComponent_SelectedLocation($event, item)"
                                                    [(ngModel)]="item.selected"
                                                    class="check form-check-input" id="checkAll">
                                            </div>
                                            <span class="text-xs text-black ms-2 mt-1"><strong>{{item.name}}
                                                </strong> <span class="ms-4">{{item.address.city}},
                                                    {{item.address.state}}</span></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2">

                     
                        <div class="table-pagination py-1 px-4">
                            <div class="row">
                                <div class="col-sm-4">
                                </div>
                                <div class="col-sm-8 text-end">
                                    <ul id="pagination" *ngIf="lLocales?.length>0">
                                        <li>
                                            <a href="javascript:void(0)" class="preview-page"
                                                (click)="nCurrentPage==1?'':CreateVideoOverviewComponent_fetchNextPage(nCurrentPage-1)">
                                                <svg width="6" height="10" viewBox="0 0 6 10"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5.25063 0.63694C5.39531 0.491231 5.39531 0.25499 5.25063 0.109281C5.10596 -0.0364277 4.8714 -0.0364277 4.72673 0.109281L0.944888 3.91824C0.351705 4.51568 0.351703 5.48432 0.944887 6.08175L4.72673 9.89072C4.8714 10.0364 5.10596 10.0364 5.25064 9.89072C5.39531 9.74501 5.39531 9.50877 5.25064 9.36306L1.46879 5.5541C1.16495 5.24808 1.16495 4.75192 1.46879 4.4459L5.25063 0.63694Z"
                                                        fill="#7A7A7A" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li *ngFor="let page of lPagination;index as i">
                                            <a href="javascript:void(0)"
                                                (click)="CreateVideoOverviewComponent_fetchNextPage(page)"
                                                [ngClass]="i+1==nCurrentPage?'add_color':''">{{page}}</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="next-page"
                                                (click)="nCurrentPage==lPagination.length?'':CreateVideoOverviewComponent_fetchNextPage(nCurrentPage+1)">
                                                <svg width="6" height="10" viewBox="0 0 6 10"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0.749366 0.63694C0.604694 0.491231 0.604694 0.25499 0.749366 0.109281C0.894037 -0.0364277 1.1286 -0.0364277 1.27327 0.109281L5.05511 3.91824C5.6483 4.51568 5.6483 5.48432 5.05511 6.08175L1.27327 9.89072C1.1286 10.0364 0.894036 10.0364 0.749364 9.89072C0.604692 9.74501 0.604693 9.50877 0.749364 9.36306L4.53121 5.5541C4.83505 5.24808 4.83505 4.75192 4.53121 4.4459L0.749366 0.63694Z"
                                                        fill="white" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </nav>

            </ng-container>
        </nz-drawer>
    </div>
</div>



</div>
</div>
</div>
<div class="wtermarklogo">
<img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
</div>
</div>


<!-- VideoPreview pop-up Start-->
<div *ngIf="videoPopup" class="videoPopupDisplay">

<div style="position:absolute; top:50%; left: 50%; transform: translate(-50%, -50%);">
<div class="closeRightSideBar" style="  top: -50px; right: -5px;" (click)="close()" >
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_6)">
    <path
        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
        fill="#F2F2F5" />
    <path
        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
        fill="#A7ABB6" />
</g>
<defs>
    <clipPath id="clip0_1_6">
        <rect width="20" height="20" fill="white" />
    </clipPath>
</defs>
</svg>
</div>

<div class="video_wrapper video_wrapper_full js-videoWrapper p-0">
<figure class="video-baner"><img [src]="this.uploadedVdeo?.thumb" style="height: 360px; width:720px; object-fit: cover;" alt="video1"></figure> 
<video #videoPreview controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" [muted]="true" style="height: 360px; width:720px; object-fit: cover;"  [src]="this.uploadedVdeo?.url" [ngStyle]="{'z-index': vidPreview ? '-1' : ''}"></video>
<button class="videoPoster js-videoPoster" (click)="previewVideo()"></button> 
</div>
</div>

</div>
<!-- VideoPreview pop-up end-->

<!-- Modal pop-up Start-->
<nz-modal
[(nzVisible)]="isModalVisible"
nzCentered
[nzWidth]="566"
[nzFooter]=null 
[nzClosable] = false
(nzOnCancel)="hidePopUP()"
>

<div class="modal-dialog modal-dialog-centered">
<div class="modal-content">

<div class="modal-body">
<button type="button" class="btn-close cust-close closeRightBtn" (click)="hidePopUP()"></button>

<h5>Processing</h5>
<p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your videos are being localized. Please check back shortly. 
</p>
<a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="hidePopUP()">Ok</a>
<!-- <a href="javascript:void(0)"
class="mt-3 text-gray-3 text-sm" (click)="updateMediaLibrary(mediaObj)">asa</a> -->
</div>
</div>
</div>
</nz-modal>


<!-- Media Library Modal pop-up Start-->
<nz-modal
[(nzVisible)]="visible"
nzCentered
[nzWidth]="825"
[nzFooter]=null 
[nzClosable] = true
(nzOnCancel)="closeMediaModal()"
>
<app-media-library-popup (lEmittedFilesToUpload)="mediaSelected($event)" [mediaPopUpLoc]="'mediaPopUpLoc'" [isSingleSelected]="true"></app-media-library-popup>
</nz-modal>
<!-- Media Library Modal pop-up End-->


<!-- Uploadmodal pop-up Start-->
<div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
style="z-index: 10000;">
<div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
<div class="modal-content">
<div class="modal-header">
<h5 class="modal-title" id="exampleModalLabel">Upload a Video</h5>
<button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
<div class="form-group files">
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
            aria-labelledby="pills-device-tab">

            <div class="file-drop-area">
                <figure>
                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                fill="#7A7A7A" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="16.0013" height="15.9809" fill="white"
                                    transform="translate(0 0.00958252)" />
                            </clipPath>
                        </defs>
                    </svg>
                </figure>
                <span class="choose-file-button">Upload a File (Device)</span>
                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                    drag & drop your file here</span>
                <span class="file-message">file selected</span>
                <input #file class="file-input" type="file"   accept="video/mp4, video/avi"  (change)="UploadVideo($event)">
            </div>
        </div>
        <ul class="nav mb-3" id="pills-tab" role="tablist">
            <li class="col nav-item" role="presentation">
                <button (click)="file.click()" class="nav-link" id="pills-device-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab"
                    aria-controls="pills-device" aria-selected="true">
                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                    <span>My Device</span></button>
            </li>
        </ul>
    </div>
</div>
</div>
</div>
</div>
</div>


<!-- Uploadmodal pop-up End-->