import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ConfigManagerService } from "../../../Managers/config-manager.service";
import { CreateVideoManagerService } from "../../../Managers/create-video-manager.service";
import { template, templatesCategory } from "../../../../app/models/template";
import { cDataService } from "../../../services/data.service";
import { cEditVideoService } from "../../../services/edit-video.service";
import { cSessionService } from "../../../services/session.service";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
import { mediaFile } from "./../../../models/media";
import { MediaManagerService } from "./../../../Managers/media-manager.service";
declare var $: any;

@Component({
  selector: "app-looknfeel",
  templateUrl: "./looknfeel.component.html",
  styleUrls: ["./looknfeel.component.scss"],
})
export class LooknFeelComponent implements OnInit, OnDestroy {
  @ViewChild("previewTemplate") previewTemplate: ElementRef;
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("closeSecondModal") closeSecondModal: ElementRef;
  bDisplayAddInputField: boolean = false;
  fLooknFeelForm: FormGroup;
  lTemplateCategory: any = [];
  sSessionID: string = "";
  sUserEmail: string = "";
  sVideoId;
  oSavedFormData: any;
  inputValue: string = "";
  lKey_terms: any = [];
  lKeyTermsTags: any = [];
  oPrimaryColor: any;
  oFontColor: any;
  sDisplayResponseErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  lSelectedTemplateCategory: any = [];
  sSelectedTemplateThumbnailURL: string;
  sSelectedTemplatePreviewVideoURL: string;
  // sTemplateChange : string;
  oUploadedLogo: any;
  nUploadedLogoSize: number = 0;
  sUploadedLogoName: string = "No file choosen.";
  bCreateVideoMode: boolean = true;
  bDisplayUploadLogoSection: boolean = true;
  oLogoDetails: any;
  sDisableEditModeToggle: string = "false";
  bSendRequestInBackground: boolean = true;

  @ViewChild("AddInputTagElement") AddInputTagElement: ElementRef;
  oTemplateConfigurations: any = [];
  bSecondLogo: any;
  nUploadedSecondLogoSize: any;
  oUploadedSecondLogo: any;
  sUploadedSecondLogoName: any;
  constructor(
    private mediaManger: MediaManagerService,
    private oRoutes: Router,
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    public videoManger: CreateVideoManagerService,
    public configManager: ConfigManagerService,
    private oEditVideoService: cEditVideoService,
    private onBoardingManager: OnboardingManagerService
  ) {}
  sub: Subscription;
  sub2: Subscription;
  ngOnInit() {
    this.sub2 = this.videoManger.previousSubmit.subscribe((emit) => {
      this.oRoutes.navigateByUrl("/wizard/overview");
    });
    this.sub = this.videoManger.submitForm.subscribe((emit) => {
      this.save(emit);
    });
    this.CreateVideoLooknFeelComponent_InitializeJqueryCode();
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    console.log(this.videoManger.selectedVideo);
    if (this.videoManger.selectedVideo.template_category) {
      this.configManager.Templates.forEach((e) => {
        if (e.category == this.videoManger.selectedVideo.template_category) {
          this.selectedCategory = e;
        }
      });
    } else {
      this.selectedCategory = this.configManager.Templates[0];
      this.videoManger.selectedVideo.template_category =
        this.configManager.Templates[0].category;
    }
    this.selectCategory(this.selectedCategory);
    if (this.videoManger.selectedVideo.template_name) {
      this.templates.forEach((e) => {
        if (e.template_name == this.videoManger.selectedVideo.template_name) {
          this.selectedTemplate = e;
          this.videoManger.changeTemplate(e);
          this.videoManger.selectedVideo.template_name = e.template_name;
        }
      });
    } else {
      this.selectedTemplate = this.templates[0];
      this.videoManger.changeTemplate(this.selectedTemplate);
    }

    this.lKey_terms = this.videoManger.selectedVideo.key_terms;

    this.oPrimaryColor = this.videoManger.selectedVideo.color;
    this.oFontColor = this.videoManger.selectedVideo.font_color;
  }

  public selectedCategory: templatesCategory;
  public selectedTemplate: template;
  public templates: template[] = [];
  selectCategory(category: templatesCategory) {
    console.log(category);
    this.selectedCategory = category;
    this.templates = [];
    this.selectedCategory?.templates?.forEach((e) => {
      e.previews.forEach((pr) => {
        if (pr.duration === this.videoManger.selectedVideo.duration) {
          e.preview_url = pr.url;
          e.thumbnail = pr.thumbnail;
          this.templates.push(e);
        }
      });
    });
    this.selectedTemplate = this.templates[0];
    this.videoManger.changeTemplate(this.selectedTemplate);
  }

  selectTemplate(template: template) {
    console.log(template);
    this.selectedTemplate = template;

    this.videoManger.changeTemplate(template);

    this.bSecondLogo = this.selectedTemplate.second_logo;
  }

  save(message) {
    this.updateVideoLocally();
    this.videoManger
      .createVideo(this.videoManger.selectedVideo)
      .then((res) => {
        if (!this.videoManger.isEditMode) {
          this.oRoutes.navigateByUrl("/wizard/content");
        } else {
          this.oRoutes.navigateByUrl("/wizard/preview");
        }
      })
      .catch((err) => {});
    //,
  }
  private updateVideoLocally() {
    this.videoManger.selectedVideo.template_category =
      this.selectedCategory.category;
    this.videoManger.selectedVideo.key_terms = this.lKey_terms;
    this.videoManger.selectedVideo.template_name =
      this.selectedTemplate.template_name;

    this.videoManger.selectedVideo.logo_img =
      this.CreateVideoLooknFeelComponent_ReturnLogo();
    this.videoManger.selectedVideo.second_logo_img =
      this.CreateVideoLooknFeelComponent_ReturnSecondLogo();
  }

  NavigateToDashboard() {
    this.oSessionService.cSessionService_ResetHighlightedTabs();
    let videoCountCheck =
      this.oSessionService.cSessionService_GetUserVideosCount();
    if (videoCountCheck > 0) {
      this.oRoutes.navigateByUrl("/dashboard/myvideos");
    } else {
      this.oRoutes.navigateByUrl("/dashboard/first-time-user");
    }
  }
  CreateVideoLooknFeelComponent_UpdateVideo(
    bUpdateVideo: boolean,
    bIncommingSendRequestInBackground: boolean
  ) {
    console.log("Update video ==> ", bUpdateVideo);
    this.bSendRequestInBackground = bIncommingSendRequestInBackground;
    if (bUpdateVideo) {
      this.oSessionService.cSessionService_SetLooknFeelForm(
        this.fLooknFeelForm.value
      );
      const oEditVideoForm =
        this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
      console.log("Updated Video Request : ==> ", oEditVideoForm);
    }
  }
  PreviewTemplate() {
    this.previewTemplate.nativeElement.play();
  }
  ngOnDestroy() {
    if (this.sub2) this.sub2.unsubscribe();
    if (this.sub) this.sub.unsubscribe();

    if (this.videoManger.isEditMode) {
      this.updateVideoLocally();
    }
  }

  CreateVideoLooknFeelComponent_LoadSelectedTemplate(oIncommingTemplate) {
    this.sSelectedTemplateThumbnailURL = oIncommingTemplate.sThumbnail;
    this.sSelectedTemplatePreviewVideoURL = oIncommingTemplate.sURL;
  }

  CreateVideoLooknFeelComponent_FocusoutFromInput() {
    if (this.inputValue !== "") {
      if (!this.lKey_terms) this.lKey_terms = [];
      this.lKey_terms.push(this.inputValue);
    }
    setTimeout(() => {
      this.bDisplayAddInputField = false;
    }, 100);
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField() {
    this.bDisplayAddInputField = false;
    if (!this.lKey_terms) this.lKey_terms = [];

    this.lKey_terms.push(this.inputValue);

    this.inputValue = "";
    console.log("key ", this.lKey_terms);
  }
  CreateVideoLooknFeelComponent_EnterKeyTermsTag(keycode) {
    if (
      (keycode == 13 && this.inputValue != "") ||
      (keycode == 188 && this.inputValue != "")
    ) {
      this.CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField();
    }
  }
  CreateVideoLooknFeelComponent_RemoveTagListElements(index) {
    this.lKey_terms.splice(index, 1);
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  CreateVideoLooknFeelComponent_GetInputValue(event) {
    this.inputValue = event.target.value;
    console.log("Input Value ==>", this.inputValue);
  }
  async CreateVideoLooknFeelComponent_UploadedLogoFile(event) {
    let file = event.target.files[0];
    this.nUploadedLogoSize = file.size;
    console.log(
      "CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ",
      file
    );

    let media = new mediaFile();
    media.file = file;
    media.name = file.name;

    this.mediaManger
      .uploadMediaFilesAdmin(this.sUserEmail, this.sSessionID, media)
      .then((res) => {
        this.oUploadedLogo = res;
        this.sUploadedLogoName = file.name;
        this.closeModal.nativeElement.click();
      })
      .catch((error) => {
        this.oUploadedLogo = null;
        this.sDisplayResponseErrorMessage = error.error.errorMessage;
        this.bDisplayErrorMessage = true;
        this.closeModal.nativeElement.click();
        setTimeout(() => {
          this.bDisplayErrorMessage = false;
        }, 5000);
      });
  }

  async CreateVideoLooknFeelComponent_UploadSecondaryLogoFile(event) {
    let file = event.target.files[0];
    this.nUploadedSecondLogoSize = file.size;
    console.log(
      "CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ",
      file
    );

    let media = new mediaFile();
    media.file = file;
    media.name = file.name;

    this.mediaManger
      .uploadMediaFilesAdmin(this.sUserEmail, this.sSessionID, media)
      .then((res) => {
        console.log(
          "CreateVideoLooknFeelComponent_UploadSecondaryLogoFile : Response ==>",
          res
        );
        this.oUploadedSecondLogo = res;
        this.sUploadedSecondLogoName = file.name;
        this.closeSecondModal.nativeElement.click();
      })
      .catch((error) => {
        this.oUploadedLogo = null;
        this.sDisplayResponseErrorMessage = error.error.errorMessage;
        this.bDisplayErrorMessage = true;
        this.closeModal.nativeElement.click();
        setTimeout(() => {
          this.bDisplayErrorMessage = false;
        }, 5000);
      });
  }
  //
  CreateVideoLooknFeelComponent_GetPrimaryColor(event) {
    const primaryColor = event.target.value;
    console.log("Primary Color ==>", primaryColor);
    this.oPrimaryColor = primaryColor;
  }
  CreateVideoLooknFeelComponent_GetFontColor(event) {
    const fontColor = event.target.value;
    console.log("Font Color ==>", fontColor);
    this.oFontColor = fontColor;
  }
  CreateVideoLooknFeelComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  CreateVideoLooknFeelComponent_ToggleView(event) {
    if (event.target.checked) {
      this.oRoutes.navigateByUrl("/wizard/overview");
    } else {
      this.oRoutes.navigateByUrl("edit-video/timeline-view");
    }
  }
  async CreateVideoLooknFeelComponent_ToggleLogoSection(event: any) {
    let value = event.target.value;
    if (value === "upload-logo") {
      this.bDisplayUploadLogoSection = true;
    } else {
      if (this.onBoardingManager.profile.logo_file) {
        this.oUploadedLogo =
          await this.oEditVideoService.EditVideoService_ReturnFileDetails(
            this.onBoardingManager.profile.logo_file
          );
      } else {
        this.bDisplayUploadLogoSection = false;
      }
    }
    this.fLooknFeelForm.patchValue({
      bDisplayUploadLogoSection: this.bDisplayUploadLogoSection,
    });
  }
  CreateVideoLooknFeelComponent_ReturnLogo() {
    let logo_path;
    if (this.bDisplayUploadLogoSection) {
      logo_path = this.oUploadedLogo ? this.oUploadedLogo.visual_path : "";
    } else {
      logo_path = this.oLogoDetails ? this.oLogoDetails.fpath : "";
    }
    return logo_path;
  }
  CreateVideoLooknFeelComponent_ReturnSecondLogo() {
    let logo_path;
    if (this.bSecondLogo) {
      logo_path = this.oUploadedSecondLogo
        ? this.oUploadedSecondLogo.visual_path
        : "";
    }
    return logo_path;
  }
  CreateVideoLooknFeelComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    $(document).ready(function() {
      $(window).keydown(function(event){
        if(event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    });
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }
}
