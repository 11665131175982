<div class="py-4 px-2 account-heading">
    <div class="row">
        <div class="col-md-8">
            <h3 class="weight-700 text-gray-2">Login and Security</h3>
            <p class="mx-0 my-0 text-sm">Easily manage your login information and account security</p>
        </div>
        <div class="col-md-4 text-end">
            <a *ngIf="bEditButton" href="javascript:void(0)"
                class="weight-normal btn btn-secondary btn-round-6 px-4 py-3"
                (click)="LoginSecurityComponent_EditForm()">Edit</a>
            <a *ngIf="bDisabledButtons" class="text-gray-3 text-sm me-4" (click)="LoginSecurityComponent_ResetView()"
                href="javascript:void(0)">Cancel
                Edits</a>
            <button *ngIf="bDisabledButtons" type="submit" (click)="LoginSecurityComponent_SendProfileUpdateRequest()"
                class="weight-normal btn btn-secondary btn-round-6 px-4 py-3 ">Save Changes</button>
        </div>
    </div>
</div>
<div class="accountSecInner flex-grow-1">
    <!-- <app-account-sidebar class="accountSecNav"></app-account-sidebar> -->
    <div class="accountSecContent py-5">

        <div class="row row-cols-md-2 g-0 justify-content-between">
            <!-- Alert for Response Error Message start -->
            <div *ngIf="bDisplayErrorMessageSignout"
                class=" alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                        fill="#842029" />
                </svg>
                <div>
                    {{sDisplayResponseErrorMessage}}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><svg width="16"
                            height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                fill="#FF8369" />
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Alert for Response Error Message End -->
            <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">

                <p class="text-lg text-gray-2 pb-1">
                    Login and Security
                </p>

                <div class="row">
                    <div class="col-md-5">


                        <div class="formItm mb-4">
                            <input type="email" [value]="this.onboardingManager.profile?.email"
                                placeholder="Email Address" readonly>
                            <label for="loginEmail" class="text-sm">Email Address</label>
                        </div>
                        <a *ngIf="!bEditButton" href="javascript:void(0)" (click)="openChangePassword()"
                            class="text-sm mt-3 d-block text-primary-4 d-inline-block">Change
                            Password</a>
                        <a *ngIf="bEditButton" href="javascript:void(0)"
                            class="text-sm mt-3 d-block text-primary-4 d-inline-block">Change
                            Password</a>
                    </div>

                    <!-- change password sidebar start -->
                    <nz-drawer [nzClosable]="false" [nzVisible]="visibleChangePassword"
                        (nzOnClose)="closeChangePassword()" [nzWidth]="478">
                        <ng-template nzDrawerContent>
                            <form [formGroup]="fChangePasswordForm" *ngIf="fChangePasswordForm">
                                <nav>


                                    <div class="sidebar-header pt-2">
                                        <h3 class="text-gray-2 weight-700 ps-3">Change Password</h3>
                                        <div id="dismiss" class="mt-2" (click)="closeChangePassword()">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="11.8"
                                                height="11.8" viewBox="0 0 50 50" style=" fill:#7A7A7A;">
                                                <path
                                                    d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>

                                    <div class="row row mt-2 mx-0 px-2">

                                        <div class="col-md-12">
                                            <div class="formItm mb-4">
                                                <input id="loginEmail" type="email"
                                                    [value]="this.onboardingManager.profile?.email"
                                                    placeholder="Email Address" readonly>
                                                <label for="loginEmail" class="text-sm">Email
                                                    Address</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="formItm formItmPasswordView mb-4">
                                                <button type="button">
                                                    <svg *ngIf="!bDisplayCurrentPassword"
                                                        (click)="LoginSecurityComponent_ToggleCurrentResetPasswordVisibility()"
                                                        class="showpass" width="16" height="16" viewBox="0 0 16 16"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                                            fill="#BABCBE" />
                                                    </svg>

                                                    <svg *ngIf="bDisplayCurrentPassword"
                                                        (click)="LoginSecurityComponent_ToggleCurrentResetPasswordVisibility()"
                                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                                            fill="#BABCBE" />
                                                        <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536"
                                                            stroke="#BABCBE" />
                                                    </svg>

                                                </button>
                                                <input id="input" [type]="bDisplayCurrentPassword?'text':'password'"
                                                    placeholder="Current Password" required
                                                    formControlName="currentPassword">
                                                <label for="input" class="text-sm">Current Password</label>
                                                <span
                                                    *ngIf="fChangePasswordForm.controls['currentPassword'].hasError('required') && fChangePasswordForm.controls['currentPassword'].touched"
                                                    class="error">Please enter your current password.</span>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="formItm formItmPasswordView mb-4">
                                                <button type="button">
                                                    <svg *ngIf="!bDisplayNewPassword"
                                                        (click)="LoginSecurityComponent_ToggleNewResetPasswordVisibility()"
                                                        class="showpass" width="16" height="16" viewBox="0 0 16 16"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                                            fill="#BABCBE" />
                                                    </svg>

                                                    <svg *ngIf="bDisplayNewPassword"
                                                        (click)="LoginSecurityComponent_ToggleNewResetPasswordVisibility()"
                                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                                            fill="#BABCBE" />
                                                        <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536"
                                                            stroke="#BABCBE" />
                                                    </svg>

                                                </button>
                                                <input id="inputPassword" [type]="bDisplayNewPassword?'text':'password'"
                                                    placeholder="New Password" required formControlName="newPassword">
                                                <label for="inputPassword" class="text-sm">New
                                                    Password</label>
                                                <span
                                                    *ngIf="fChangePasswordForm.controls['newPassword'].hasError('required') && fChangePasswordForm.controls['newPassword'].touched"
                                                    class="error">Please enter your new password.</span>
                                                <span
                                                    *ngIf="fChangePasswordForm.controls['newPassword'].hasError('pattern') && fChangePasswordForm.controls['newPassword'].dirty"
                                                    class="error" style="margin-bottom: -20px !important;">Passwords
                                                    must be at least 8 characters long and contain at least one each of
                                                    uppercase and lowercase alphabets, one numeral and one special
                                                    character</span>

                                            </div>
                                        </div>

                                    </div>

                                    <!-- Alert for Reset Password Error Message start -->
                                    <div *ngIf="bDisplayErrorMessage"
                                        class=" alert alert-danger d-flex align-items-center alert-dismissible fade show"
                                        style="margin: 15px;" role="alert">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                                fill="#842029" />
                                        </svg>
                                        <div>
                                            {{sDisplayPasswordErrorMessage}}
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"
                                                aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                        fill="#FF8369" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- Alert for Reset Password Error Message End -->

                                    <ul class="sideNav-btn px-3">

                                        <button class="weight-normal btn btn-primary btn-round-6 px-4 py-3"
                                            [disabled]="!fChangePasswordForm.valid"
                                            (click)="ChangePassword_SendUpdatePasswordRequest()">Save
                                            Password</button>
                                        <a class=" text-gray-3 text-sm ms-4" href="javascript:void(0)"
                                            (click)="closeChangePassword()">Cancel</a>
                                    </ul>

                                </nav>
                            </form>
                        </ng-template>

                    </nz-drawer>
                    <!-- change password sidebar end -->

                    <div class="col-md-10 px-4">
                        <div class="row mt-4 ac-overview">
                            <div class="col-md-7 px-0">
                                <p class="text-lg text-gray-2 pb-0 mb-2">Sign out from all devices
                                </p>
                                <p class="text-xs text-gray-3 pb-1">Logged in on a shared device but
                                    forgot to sign out? End all sessions by signing out
                                    from all devices. You'll need to sign back in to access your
                                    account.</p>
                            </div>
                            <div class="col-md-5 text-end px-0 pe-4">
                                <button (click)="LoginSecurityComponent_SignOutFromAllDevicesModal()"
                                    href="javascript:void(0)" [disabled]="!bDisabledButtons"
                                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-pad btn-d"><svg
                                        width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.38394 2.83483C6.85696 3.26189 6.52889 3.95277 6.43198 5.07678C6.40829 5.35157 6.16603 5.55516 5.89088 5.5315C5.61574 5.50783 5.4119 5.26588 5.43559 4.99109C5.54536 3.71797 5.93735 2.72087 6.75381 2.05923C7.55646 1.40877 8.67822 1.15857 10.0741 1.15857H10.1608C11.7004 1.15857 12.9083 1.4634 13.7188 2.27285C14.5293 3.0823 14.8345 4.28871 14.8345 5.82632V10.1678C14.8345 11.7054 14.5293 12.9118 13.7188 13.7213C12.9083 14.5307 11.7004 14.8355 10.1608 14.8355H10.0741C8.68836 14.8355 7.57335 14.5887 6.77234 13.9487C5.95687 13.2972 5.55969 12.3146 5.44257 11.0598C5.41694 10.7852 5.61907 10.5418 5.89404 10.5162C6.16901 10.4906 6.4127 10.6925 6.43833 10.9671C6.54123 12.0695 6.87078 12.7483 7.39708 13.1688C7.93784 13.6008 8.77966 13.8367 10.0741 13.8367H10.1608C11.6015 13.8367 12.4803 13.5456 13.0116 13.015C13.5429 12.4844 13.8344 11.6066 13.8344 10.1678V5.82632C13.8344 4.38749 13.5429 3.50972 13.0116 2.97911C12.4803 2.4485 11.6015 2.15737 10.1608 2.15737H10.0741C8.76979 2.15737 7.92473 2.39659 7.38394 2.83483Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.91345 7.99037C1.91345 7.71456 2.13733 7.49097 2.41349 7.49097H10.0008C10.2769 7.49097 10.5008 7.71456 10.5008 7.99037C10.5008 8.26618 10.2769 8.48977 10.0008 8.48977H2.41349C2.13733 8.48977 1.91345 8.26618 1.91345 7.99037Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.25388 5.40665C4.44916 5.60168 4.44916 5.91788 4.25388 6.11291L2.37395 7.99044L4.25388 9.86798C4.44916 10.063 4.44916 10.3792 4.25388 10.5742C4.0586 10.7693 3.74199 10.7693 3.54672 10.5742L1.31321 8.34357C1.11793 8.14855 1.11793 7.83234 1.31321 7.63731L3.54672 5.40665C3.74199 5.21162 4.0586 5.21162 4.25388 5.40665Z"
                                            fill="#7A7A7A" />
                                    </svg>
                                    Sign out from all devices</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10 px-4">
                        <div class="row mt-4 ac-overview">
                            <div class="col-md-7 px-0">
                                <p class="text-lg text-gray-2 pb-0 mb-2">Delete your account</p>
                                <p class="text-xs text-gray-3 pb-1">By deleting your account, you'll
                                    no longer be able to access any of your videos.</p>
                            </div>
                            <div class="col-md-5 text-end px-0 pe-4">
                                <button (click)="LoginSecurityComponent_DeleteModal()" [disabled]="!bDisabledButtons"
                                    href="javascript:void(0)"
                                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-pad btn-d">

                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.49994 0.998805C6.94762 0.998805 6.49987 1.44599 6.49987 1.99761H10.5002C10.5002 1.44599 10.0524 0.998805 9.5001 0.998805H7.49994ZM11.5003 1.99761C11.5003 0.894361 10.6048 0 9.5001 0H7.49994C6.39529 0 5.49979 0.894361 5.49979 1.99761H2.49955H1.49947H0.999429C0.723265 1.99761 0.49939 2.2212 0.49939 2.49701C0.49939 2.77283 0.723265 2.99642 0.999429 2.99642H1.49947V10.9869C1.49947 13.745 3.73822 15.9809 6.49987 15.9809H10.5002C13.2618 15.9809 15.5006 13.745 15.5006 10.9869V2.99642H16.0006C16.2768 2.99642 16.5007 2.77283 16.5007 2.49701C16.5007 2.2212 16.2768 1.99761 16.0006 1.99761H15.5006H14.5005H11.5003ZM2.49955 10.9869V2.99642H6.49987H10.5002H14.5005V10.9869C14.5005 13.1934 12.7095 14.9821 10.5002 14.9821H6.49987C4.29055 14.9821 2.49955 13.1934 2.49955 10.9869ZM6.49987 11.4863V5.49343C6.49987 5.21762 6.27599 4.99403 5.99983 4.99403C5.72366 4.99403 5.49979 5.21762 5.49979 5.49343V11.4863C5.49979 11.7621 5.72366 11.9857 5.99983 11.9857C6.27599 11.9857 6.49987 11.7621 6.49987 11.4863ZM11.5003 5.49343V11.4863C11.5003 11.7621 11.2764 11.9857 11.0002 11.9857C10.7241 11.9857 10.5002 11.7621 10.5002 11.4863V5.49343C10.5002 5.21762 10.7241 4.99403 11.0002 4.99403C11.2764 4.99403 11.5003 5.21762 11.5003 5.49343Z"
                                            fill="#7A7A7A" />
                                    </svg>
                                    Delete Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="overlay"></div>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="handleCancel()">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

                <h5>Are you sure you want to sign out from all devices?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll be signed out from all devices your Prontopia  account
                    is <br>
                    signed in to.</p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
                <a href="javascript:void(0)" (click)="LoginSecurityComponent_SignOutFromAllDevices()"
                    class="mt-3 text-gray-3 text-sm">Sign
                    out</a>
            </div>
        </div>
    </div>
</nz-modal>


<!-- Modal pop-up Start-->
<div class="modal fade" id="delete-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>Are you sure you want to delete your Prontopia  account?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">This cannot be reversed. You will no longer have access to
                    your Prontopia
                    account, including all drafted, scheduled, and published videos.</p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
                <a href="javascript:void(0)" (click)="LoginSecurityComponent_SendDeleteUserAccountRequest()"
                    data-bs-dismiss="modal" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm">Delete Account</a>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->

<!-- password reset pop-up Start-->
<div class="modal fade" id="password-reset-popup" data-bs-backdrop="static" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"
                    routerLink="/login"></button>
                <h5 class="pb-2 mb-4">Your password has updated</h5>
                <!-- <p class="text-sm text-gray-3 pt-1">A verification email has been sent to your email.</p> -->
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4 px-5" data-bs-dismiss="modal"
                    routerLink="/login">Ok</a>
            </div>
        </div>
    </div>
</div>
<!-- password reset  End-->