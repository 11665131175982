<ul class="breadcrumb-tabs nav">
    <span *ngIf="!this.videoManger.previewMode">
    <li class="nav-item" role="presentation">
        <a routerLink="/ft/overview" routerLinkActive="active" class="nav-link">Overview</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/ft/looknfeel" routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link ">Look & Feel</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/ft/content" routerLinkActive="active"  [class.disabled-breadcrumb]="!this.enableMenu" class="nav-link ">Content</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/ft/other-setting" routerLinkActive="active"  [class.disabled-breadcrumb]="!this.enableMenu"  class="nav-link ">Other Settings</a>
    </li>
    </span>

    <span *ngIf="this.videoManger.previewMode && this.videoManger.isVideoGenerated" class="ml-2" style="margin-left:20px">
        <li class="nav-item" role="presentation"   >
            <a routerLink="/ft/overview"  class="nav-link ">Edit Video</a>
        </li>
        
        <!-- <li class="nav-item" role="presentation">
            <a href="javascript:void(0)" class="nav-link">Video Options</a>
        </li> -->
        <li class="nav-item " role="presentation">
            <a  class="nav-link active">Preview</a>
        </li>
       </span>
  
</ul>