import { EventEmitter, Injectable } from '@angular/core';


import { scheduledVideos, video } from '../models/video';

import { Observable, interval, timer, observable } from 'rxjs';



import { takeWhile } from 'rxjs/operators';
import { VideoService } from '../services/dataServices/video.service';
import { LoaderService } from '../services/loader.service';


@Injectable({
  providedIn: 'root'
})
//Creation Date: 27/04/2022
//Created By: Wasim khan
// This is manager layer service that will responsible for maintain the state of video's and also perform different CURD actions 
export class VideoManagerService {

  constructor(public dataservice: VideoService, public loader: LoaderService) { }
  submitForm: EventEmitter<number> = new EventEmitter();
  public search: EventEmitter<string> = new EventEmitter();
  selectedVideo: any;
  public numItems: number = 10;
  public num_skip: number = 0;

  draftVideoCount: number = 0;
  publishedVideoCount: number = 0;
  ScheduledVideoCount: number = 0;
  LocationVideoCount: number = 0;




  public videos: video[] = [];
  public schedulevideos: scheduledVideos[] = [];

  deteSchedule(email, session_id, schedule_id) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.deleteScheduleVideo(email, session_id, schedule_id).then(res => {
        this.loader.hideLoader();
        resolve(res);
      }).catch(err => {
        this.loader.hideLoader();
        reject(err);
      });
    });
    return promise;
  }

  getVideos(email, session_id, location_videos, publish_status, sortType, num_items, num_skip): Promise<unknown> {
    this.loader.showLoader();
    this.videos = [];
    let promise = new Promise((resolve, reject) => {
      this.dataservice.Videos(email, session_id, location_videos, publish_status, sortType, num_items, num_skip)
        .then(res => {
          this.loader.hideLoader();


          this.videos = res['video_list'] as video[];
          this.videos.map(c => c.isLocationVideo = location_videos);

          if (publish_status === 'scheduled') {
            this.createScheduleList();

            //  console.log(this.schedulevideos)
          }



          this.getsignedThumbandURL(email, session_id, this.videos);
          resolve(res);
        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }


  getVideoById(email, session_id, video_id): Promise<unknown> {

    console.log('video_id', video_id);
    let promise = new Promise((resolve, reject) => {
      this.dataservice.getVideo(email, session_id, video_id)
        .then(res => {
          console.log("get vidoe", res);
          resolve(res['video']);
        }).catch(err => {


          reject(err);
        });
    });
    return promise;
  }
  getAutomatedVideos(email, session_id): Promise<unknown> {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.schedulevideos = [];
      this.dataservice.generatedAutomatedVideos(email, session_id)
        .then(res => {
          this.loader.hideLoader();
          const keys = Object.keys(res);
          keys.forEach((key, index) => {
            let sch = new scheduledVideos();
            if (index == 0) {
              sch.isActive = true;
            }
            sch.date = key;
            sch.videos = res[key] as video[];
            this.schedulevideos.push(sch);
          });


          console.log(this.schedulevideos);
          this.getsignedThumbandURLForSchedule(email, session_id);
          resolve(res);
        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }

  public createSchedule(payload): Promise<unknown> {


    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {

      this.dataservice.saveScheduleVideo(payload).then(res => {
        this.loader.hideLoader();
        resolve(res);
      }).catch(err => {
        this.loader.hideLoader();
        reject(err);
      });

    });
    return promise;
  }

  public createVideo(video: video) {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.createVideo(video)
        .then(res => {
          this.loader.hideLoader();

          resolve(res);
        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }


  private createScheduleList() {
    this.schedulevideos = [];

    this.videos.forEach((video) => {


      let isfound = false;
      this.schedulevideos.forEach((vide: scheduledVideos) => {

        if (vide.date == video.created) {
          vide.videos.push(video);
          isfound = true;
        }
      });
      if (!isfound) {
        let sch = new scheduledVideos();
        sch.date = video.created;
        this.schedulevideos.push(sch);
      }



    });
  }


  getUserVideoCount(email, session_id) {
    let promise = new Promise((resolve, reject) => {
      this.dataservice.getUserVideoCount(email, session_id).then(res => {


        //LocationVideoCount 
        resolve(res);
      }).catch(err => {

        reject(err);
      });

    });


    return promise;
  }
  getSchedulesVideos(email, session_id, schedule_id): Promise<unknown> {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.getScheduleVideos(email, session_id, schedule_id)
        .then(res => {
          this.loader.hideLoader();


          let vidList = res['video_list'] as video[];


          this.getsignedThumbandURL(email, session_id, vidList);
          resolve(res);
        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }

  cloneVideo(email, session_id, video_id): Promise<unknown> {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.cloneVideo(email, session_id, video_id)
        .then(res => {
          this.loader.hideLoader();


          let vid = res as video;
          var index = this.videos.findIndex(x => x.fpath === video_id);
          this.videos.splice(index + 1, 0, vid);
          //this.videos.push(vid);

          this.dataservice.getSignedURL(email, session_id, vid.fpath).then(res => {
            vid.loadingUrl = false;
            vid.video_url = res['url'];


            //  console.log("singed-------------------" + JSON.stringify(vid));
          }).catch(err => {
            vid.loadingUrl = false;
          });

          this.dataservice.getSignedURL(email, session_id, vid.thumb).then(res => {
            vid.loadingUrl = false;
            vid.thumbnail = res['url'];


            //  console.log("singed-------------------" + JSON.stringify(vid));
          }).catch(err => {
            vid.loadingUrl = false;
          });
          resolve(res);
        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });


    return promise;
  }


  searchVideos(email, session_id, title, location_videos, publish_status, sortType, num_items, num_skip): Promise<unknown> {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice.searchVideo(email, session_id, title, location_videos, publish_status, sortType, num_items, num_skip)
        .then(res => {
          this.loader.hideLoader();


          this.videos = res['results'] as video[];
          this.videos.map(c => c.isLocationVideo = location_videos);
          this.getsignedThumbandURL(email, session_id, this.videos);

          resolve(res);

        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }


  getsignedThumbandURL(email, session_id, vidList: video[]) {

    let batchUrl = [];
    vidList.forEach(vid => {

      vid.loadingUrl = true;
      vid.loadingThumbUrl = true;
      if (vid.fpath != ''){        
        batchUrl.push(vid.fpath);
      }
      if (vid.thumb != '')
        batchUrl.push(vid.thumb);

    });

    this.dataservice.getBatchSignedURL(email, session_id, batchUrl).then(res => {

      let output = res['output'];
      console.log('output', output);
      vidList.forEach((e, index) => {
        e.loadingUrl = false;
        e.loadingThumbUrl = false;
        console.log(e.thumb);
        e.video_url = output.filter(a => a[e.fpath])[0][e.fpath];
        e.thumbnail = output.filter(a => a[e.thumb])[0][e.thumb];
        console.log('thumb', output.filter(a => a[e.thumb]));
        console.log(e);
      });
      //  console.log("singed-------------------" + JSON.stringify(vid));
    }).catch(err => {
      vidList.forEach((e, index) => {
        e.loadingUrl = false;
        e.loadingThumbUrl = false;


      });
    });



  }

  getsignedThumbandURLForSchedule(email, session_id) {

    let fpaths = [];
    this.schedulevideos.forEach(sc => {



      sc.videos.forEach(vid => {

        vid.loadingUrl = true;
        fpaths.push(vid.fpath);
        fpaths.push(vid.thumb);
        vid.loadingThumbUrl = true;


      });
    });

    this.dataservice.getBatchSignedURL(email, session_id, fpaths).then(res => {

      let output = res['output'];
      console.log('output', output);
      this.schedulevideos.forEach(sc => {
        sc.videos.forEach(e => {

          e.loadingUrl = false;
          e.loadingThumbUrl = false;
          console.log(e.thumb);
          e.video_url = output.filter(a => a[e.fpath])[0][e.fpath];
          e.thumbnail = output.filter(a => a[e.thumb])[0][e.thumb];
          console.log('thumb', output.filter(a => a[e.thumb]));
          console.log(e);
        });
      });
      //  console.log("singed-------------------" + JSON.stringify(vid));
    }).catch(err => {
      this.schedulevideos.forEach(sc => {
        sc.videos.forEach(e => {

          e.loadingUrl = false;
          e.loadingThumbUrl = false;


        });
      });
    });



  }
  updateVideoStatus(email, session_id, video_id, publish_status): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.updateStatus(email, session_id, video_id, publish_status)
        .then(res => {
          this.loader.hideLoader();
          this.selectedVideo.public_url = res['public_url'];
          this.selectedVideo.publish_status = res['publish_status'];

          resolve(res);

        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }


  replaceClip(email, session_id, video_id, replace_id): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.replaceClip(email, session_id, video_id, replace_id)
        .then(res => {
          this.loader.hideLoader();


          resolve(res);

        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }


  deleteVideo(email, session_id, video_id): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.deleteVideo(email, session_id, video_id)
        .then(res => {
          this.loader.hideLoader();


          resolve(res);

        }).catch(err => {

          this.loader.hideLoader();
          reject(err);
        });
    });
    return promise;
  }

  generateVideo(email, session_id, video_id): Promise<unknown> {

    let promise = new Promise((resolve, reject) => {
      this.dataservice.generateVideo(email, session_id, video_id).then(res => {

        this.dataservice.getSignedURL(email, session_id, video_id).then(result => {
          this.selectedVideo.video_url = result['url'];

        });
        this.gv_task_id = res['gv_task_id'];

        resolve(res)
      }).catch(err => {
        reject(err);
      });

    });
    return promise;
  }
  public gv_task_id: string;
  public isVideoGenerated: boolean = false;
  generateVideoStatus(email, session_id, gv_task_id): Promise<unknown> {
    if (this.isVideoGenerated) {
      this.isVideoGenerated = false;
    }

    let promise = new Promise((resolve, reject) => {
      interval(2000).pipe(takeWhile(() => !this.isVideoGenerated)).subscribe(() => {




        this.dataservice.generatedVideoStatus(email, session_id, gv_task_id).then(data => {
          if (data['task_status'] == 'success') {
            this.isVideoGenerated = true;
            resolve(data)
          } else {
            this.isVideoGenerated = false;
          }

        }).catch(err => {
          this.isVideoGenerated = true;
          reject(err);
        });
      });
    });

    return promise;
  }


  public addComment(vidoe_id, comment) {
    let promise = new Promise((resolve, reject) => {
      this.dataservice.addcomment(vidoe_id, comment).then(res => {
        this.selectedVideo.comments.push(comment);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
    return promise;
  }



  deleteComment(vidoe_id, comment) {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice.deleteComment(vidoe_id, comment).then(res => {
        this.loader.hideLoader();

        this.selectedVideo.comments.forEach((element, index) => {
          console.log("Comments1 " + element);
          if (element == comment) {
            this.selectedVideo.comments.splice(index, 1);
          }
        })
        resolve(res);
      }).catch(err => {

        this.loader.hideLoader();
        reject(err);
      });
    });
    return promise;
  }


}
