import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { profile } from './../../../models/onboarding';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cIntercomService } from './../../../services/intercom.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
 
import { environment } from './../../../../environments/environment';
declare var $ : any;
@Component({
  selector: 'app-first-time-dashboard',
  templateUrl: './first-time-dashboard.component.html',
  styleUrls: ['./first-time-dashboard.component.scss']
})
export class FirstTimeDashboardComponent implements OnInit {
  sSessionId: string;
    sUserEmail: string;
    oMetaInfo: any;
    sIndustry : string = "";
  oSetTime: any;
  sUserName : string = "";
  lTemplateNames : any = [];
  lTemplateCategory : any = [];

  bAlert: boolean = false;
  lIndustryKeyTermsArray : any = [];

  constructor(private oSessionService : cSessionService,
     private oRouter : Router,
 
     private oEditVideoService : cEditVideoService,
     private oIntercomService : cIntercomService,
     private oTemplateConfigService : cTemplateConfigService,public videoManager:CreateVideoManagerService ,public onboardinManager:OnboardingManagerService,public configManager:ConfigManagerService) { }

  ngOnInit(): void {

   
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    if(!this.onboardinManager.profile)
    {
      this.getProfile(this.sUserEmail,this.sSessionId);
    }else
    {
      if(this.onboardinManager.loggedUser.videoCount > 0)
      {
        this.oRouter.navigateByUrl('/dashboard/myvideos');
      }
    }
    this.InitializeJqueryCode;
  }

  async getProfile(email,session)
  {
   await this.onboardinManager.getUserProfile(email,session).then((res:profile)=>{



      this.configManager.getTemplates2(email,session,res.allowed_templates);
    });
  
   await    this.onboardinManager.getUserVideosCount(email,session).then(count=>{
    if (count> 0) {this.oRouter.navigateByUrl('/dashboard/myvideos');
  
    }

    
   });
    
      this.configManager.getVoices(email,session);
  }

  FirstTimeDashboardComponent_NavigateToCreateVideo()
  {
    this.videoManager.selectedVideo = undefined;
    this.oRouter.navigate(['wizard/overview']);
  }
 
 
  FirstTimeDashboardComponent_DismissSidePanel()
  {
    console.log("function called =====>");
    
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
       $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
     $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    
    });
  }
  NavigateToDashboard()
  {
    let videoCountCheck = this.oSessionService.cSessionService_GetUserVideosCount()
    if(videoCountCheck > 0)
    {
      this.oRouter.navigateByUrl('/dashboard/myvideos');
      console.log('if blockkk');
      
    }
    else
    {
      this.oRouter.navigateByUrl('/dashboard/first-time-user');
      console.log('else blockk');
      
    }
  }
  NavigateToBusinessProfile()
  {
    if(environment.business_plan === 'enterprise_agency')
    {
      this.oRouter.navigateByUrl('/business-profile/brands')
    }
    else
    {
      this.oRouter.navigateByUrl('/business-profile/brand-identity')
    }
  }
  LogOut(){
    localStorage.clear();
    this.oIntercomService.cIntercomService_RemoveLoggedInUserIntercomCredentials();
  }
  InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
   
    jQuery('.button-gl').on('click', function (e) {
      jQuery('.button-gl').removeClass('active');
      jQuery(this).addClass('active');

      if (jQuery(this).hasClass('grid')) {
          jQuery('#container-view div.row').removeClass('list').addClass('grid');
      }
      else if (jQuery(this).hasClass('list')) {
          jQuery('#container-view div.row').removeClass('grid').addClass('list');
      }
  });

  jQuery(function () {
      jQuery('.showSingle').click(function () {
          jQuery('.targetDiv').hide('.cnt');
          jQuery('#div' + $(this).attr('target')).slideToggle();

      });
  });

  $(document).ready(function () {
      var submitIcon = $('.searchbox-icon');
      var inputBox = $('.searchbox-input');
      var searchBox = $('.searchbox');
      var isOpen = false;
      submitIcon.click(function () {
          if (isOpen == false) {
              searchBox.addClass('searchbox-open');
              inputBox.focus();
              isOpen = true;
          } else {
              searchBox.removeClass('searchbox-open');
              inputBox.focusout();
              isOpen = false;
          }
      });
      submitIcon.mouseup(function () {
          return false;
      });
      searchBox.mouseup(function () {
          return false;
      });
      $(document).mouseup(function () {
          if (isOpen == true) {
              $('.searchbox-icon').css('display', 'block');
              submitIcon.click();
          }
      });
  });
  
    `;
    document.body.appendChild(chatScript);
  }

}
