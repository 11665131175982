import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";
import "@stripe/stripe-js";
import * as moment from "moment";
import { AccountManagerService } from "./Managers/account-manager.service";
import { ConfigManagerService } from "./Managers/config-manager.service";
import { OnboardingManagerService } from "./Managers/onboarding-manager.service";
import { SubscriptionplanManagerService } from "./Managers/subscriptionplan-manager.service";
import { VideoManagerService } from "./Managers/video-manager.service";
import { profile, user } from "./models/onboarding";
import { video } from "./models/video";
import { ErrorService } from "./services/error.service";
import { LoaderService } from "./services/loader.service";
import { cSessionService } from "./services/session.service";
import { SuccessMessageModalService } from "./services/success-message-modal.service";
import { SettingsService } from "./components/myAccount/services/settings.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "prontoV2";
  sessionId: any;
  email: any;

  showSplashScreen: boolean = true;
  constructor(
    public meta_info: ConfigManagerService,
    public loader: LoaderService,
    public subscriptionManager: SubscriptionplanManagerService,
    public videoManager: VideoManagerService,
    public accountManager: AccountManagerService,
    public oSessionService: cSessionService,
    public onboardinManger: OnboardingManagerService,
    public router: Router,
    public planManager: SubscriptionplanManagerService,
    public errorService: ErrorService,
    public successMessageService: SuccessMessageModalService,
    private activatedRoute: ActivatedRoute,
    private settingProntoService : SettingsService
  ) {
    window.addEventListener('click', this.clickDocument, true);
  }

  clickDocument = ( event : any) : void => {
    this.settingProntoService.hideAllDropDown();
  }

  async ngOnInit() {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();

    // await this.meta_info.getMetaInfo().then(async res => {

    if (this.sessionId && this.email && this.email != undefined) {
      // this.meta_info.getVoices(this.email, this.sessionId);

      await this.onboardinManger
        .getUserProfile(this.email, this.sessionId, true)
        .then((_) => {
          // this.meta_info.getTemplates2(this.email, this.sessionId, this.onboardinManger.profile.allowed_templates);

          var route = this.router.url.split("?")[0];

          if (
            this.router.url.split("?")[0] == "/" ||
            this.router.url.split("?")[0] == "/login" ||
            this.router.url.split("?")[0] == "/dashboard/myvideos" ||
            this.router.url.split("?")[0] == "/dashboard/first-time-user"
          ) {
            if (
              this.onboardinManger.profile.user_status ==
              "confirmed_not_subscribed"
            ) {
              this.loader.hideLoader();

              this.subscriptionManager.showPlanDialogue();
              //this.router.navigate(['/create-account/select-plan']);
            } else {
              if (this.onboardinManger.profile.user_status == "canceled") {
                var date = new Date(
                  this.onboardinManger.profile.next_billing_date
                );
                var date2 = moment(
                  new Date(this.onboardinManger.profile.next_billing_date)
                );
                var tempDate = moment(new Date());

                const diffDays = date2.diff(tempDate, "days");
                if (diffDays > 0) {
                  let errorMessage =
                    "Your subscription expires in " + diffDays + " days";
                  setTimeout(() => {
                    this.errorService.handleError(
                      errorMessage,
                      "Subscription",
                      true,
                      false,
                      true
                    );
                  }, 3000);
                } else {
                  this.loader.hideLoader();
                  if (this.meta_info.metaInfo.stripe_enabled) {
                    this.router.navigate(["/create-account/select-plan"]);
                  }
                }

                // }
              }

              this.checkforredirect();
            }
          }
        })
        .catch((err) => {
          this.router.navigate(["login"]);
        });
    } else {
      if (
        this.router.url.split("?")[0] != "/verify_reset_password" &&
        this.router.url.split("?")[0] != "/verify_email" &&
        this.router.url.split("?")[0] != "/createAccount" &&
        this.router.url.split("?")[0] != "/create-account/paywall-success" &&
        this.router.url.split("?")[0] != "/create-account/paywall-failed"
      ) {
        // console.log("login");
        this.router.navigate(["login"]);
      }
    }

    // }).catch(err => {

    //   console.log(err);
    // }).finally(() => {

    this.showSplashScreen = false;
    // });
  }

  private checkforredirect() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let video_id = params["video_id"];

      const authResult =
        this.activatedRoute.snapshot.queryParams["auth_result"];

      //social share response
      const socialShareResult =
        this.activatedRoute.snapshot.queryParams["result"];

      this.oSessionService.cSessionService_SetRedirectionMessage(
        this.activatedRoute.snapshot.queryParams["result"]
      );
      //redirection message
      const message = this.activatedRoute.snapshot.queryParams["message"];
      let nRedirectURl = this.oSessionService.cSessionService_GetRedirectURL();
      this.router.navigateByUrl("/account-setting/users");

      // if (nRedirectURl == 1) {
      //   this.router.navigateByUrl("/account-setting/social-channels");
      //   this.resetRedirectCache();
      //   return;
      // } else if (nRedirectURl == 2) {
      //   this.router.navigateByUrl("/account-setting/myAccount");

      //   this.resetRedirectCache();
      // } else if (nRedirectURl == 3) {
      //   video_id = this.oSessionService.SessionService_GetVideoId();
      //   this.router.navigateByUrl("/dashboard/socialmedia");
      //   this.resetRedirectCache();
      //   return;
      // } else if (nRedirectURl == 4) {
      //   video_id = this.oSessionService.SessionService_GetVideoId();
      //   this.router.navigateByUrl("/published-video/timeline-view");

      //   this.resetRedirectCache();
      //   return;
      // } else {
      //   if (this.onboardinManger.loggedUser.getVideoCount() > 0) {
      //     this.router.navigateByUrl("/dashboard/myvideos");
      //     this.router.navigateByUrl("/account-setting/users");
      //   } else {
      //     this.router.navigateByUrl("/dashboard/first-time-user");
      //   }
      // }
    });
  }

  private resetRedirectCache() {
    setTimeout(() => {
      localStorage.removeItem("lsSetRedirectionMessage");
      localStorage.removeItem("lsSocialConnectRedirectURL");
    }, 3000);
  }

  // titleCaseWord(word: string) {/*  */
  //   if (!word) return word;
  //   return word[0].toUpperCase() + word.substr(1).toLowerCase();
  // }
  closeErrorModal() {
    this.errorService.closeErrorDialogue();
  }
  goToSubscribe() {
    this.accountManager
      .ManageBilling(
        this.onboardinManger.loggedUser.email,
        this.onboardinManger.loggedUser.session_id
      )
      .then((url: string) => {
        window.location.href = url;
      });
    this.errorService.closeErrorDialogue();
  }
  closePlanModal() {
    this.planManager.closePlanDialogue();
  }
}
