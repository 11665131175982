import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MediaManagerService } from "./../../Managers/media-manager.service";
import { mediaFile, mediaLib, visual } from "./../../models/media";
import { cDataService } from "./../../services/data.service";
import { cSessionService } from "./../../services/session.service";
import { cWebSocketService } from "./../../services/websocket.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ErrorService } from "./../../services/error.service";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import {
  MediaLibrary,
  Media_Lib,
  SearchData,
  user,
} from "src/app/models/admin";
import { AdminMediaLibraryManagerService } from "src/app/Managers/adminManager/admin-media-library-manager.service";
import { Userservice } from "src/app/components/myAccount/users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { AdminService } from "src/app/services/dataServices/admin.service";
import { AdminMediaLibraryPageService } from "src/app/components/myAccount/media-libraries-page/service/media-libraries-page.service";
@Component({
  selector: "app-media-library-shared",
  templateUrl: "./media-library-shared.component.html",
  styleUrls: ["./media-library-shared.component.scss"],
})
export class MediaLibrarySharedComponent implements OnInit, OnChanges {
  CopyText = "";
  @ViewChild("myVideo") myVideo: ElementRef;
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("uploadFileField", {static: false}) uploadFileField : ElementRef;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  bDisplayMediaButtons: boolean = false;
  sAddActiveClass: any;
  lBreadcrumbOptions: any = [
    { name: "All", filter: "all" },
    { name: "Videos", filter: "clip" },
    { name: "Audio", filter: "audio" },
    { name: "Images", filter: "image" },
    // {name : 'Icons', filter : 'Icons'},
    // {name : 'Text Snippets', filter : 'Snippets'},
  ];
  selectedOption: any;
  public imageStaticThumb = "../assets/images/business_Profile/audio-line.png";
  sSessionId: string;
  sUserEmail: string;
  public commonTags = [];
  sResponseStatus: string;
  fUserVisualListForm: FormGroup;
  fUserVisualCountForm: FormGroup;
  lUserVisualsList: any = [];
  nUserVisualID: number = 0;
  nUserVisualsCount: number;
  nUserImagesCount: number;
  nUserClipsCount: number;
  nUserAudiosCount: number;
  public search: string = "";
  sVisualType: string = "all";
  nAlreadyFetchedVisuals: number = 0;
  nPageSize: number = 18;
  lPagination: any = [];
  nCurrentPage: number = 1;
  lSelectedUserVisualsList: any = [];
  sSelectedVideoId: string;
  oSingleVisual: any;
  bDisplayAddInputField: boolean = false;
  inputValue: string = "";
  lKeyTerms: any = [];
  @Input() bSelectMultipleVisuals: boolean = false;
  oAudio = new Audio();
  currentKey;
  oSelectedImage: any = "";
  lSelectedKeyTermsTags: any = [];
  @ViewChild("AddInputTagElement") AddInputTagElement: ElementRef;
  @ViewChild("AddInputElement") AddInputElement: ElementRef;
  lFilesToBeUploaded: any = [];
  nFilesToUploadCount: number = 0;
  @Output() lEmittedFilesToUpload = new EventEmitter<string[]>();
  sSelectedMediaLibrary: string;
  sUrl: any = [];
  bMediaLoader: boolean = false;
  @Input() sInputVisualPath: string;
  @Input() lInputVisualsToDelete: any = [];
  bDisplaySelect: boolean = false;
  bSelectListDisplay: boolean = false;
  selectedItemId = -1;
  selectedItemArray = [];
  isSpinning = false;
  multiselectmedia: boolean = false;
  selectmedia: boolean = false;
  uploadingMedia: boolean = false;
  @Input() userAccountMediaLib: boolean = false;
  @Input() manageBrandMediaLib: boolean = false;
  @Input() showMediaLibSelector: boolean = false;
  @Input() showMediaLibAccess: boolean = false;
  @Input() selectedBrandId: string;

  public modelUserSearchChanged = new Subject<string>();
  showAssignUserDropDown = false;
  userListLoopLimit = 3;
  userList: any = [];
  isUserSearchLoading: boolean = false;
  selectedAddUsers: user[] = [];
  mediaLibraryAccessUsage: FormGroup;
  Access_Control = {
    global: "global",
    limited: "limited",
  };
  selectedDelUser: any;
  deleteUserModal: boolean = false;
  allowAddNewUser = false;
  mediaLibraryDetail: mediaLib = {};
  // MediaLibUsers : any[]
  offset = 0;
  limit = 18;
  media_lib_id = "";
  searchTerm = "";
  searchBy = "name";
  sortBy = "created";
  sortType = -1;
  mediaType = "all";
  mediaLibraryUsers: any;
  searchListText = "";
  searchUserText = "";
  selectListView = false;
  listview = false;

  public searchLibraryForm: FormGroup;
  public searchLibraryNameChanged = new Subject<string>();
  public searchListMediaLibraries: any;
  public isMediaLibSearchLoading = false;
  public seletedMediaLibraryList: any[] = [];
  otherMediaLibSelected = [];
  private userMediaLibs: any[];
  constructor(
    private oRouter: Router,
    public mediaManager: MediaManagerService,
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    public oFormBuilder: FormBuilder,
    private oWebSocketService: cWebSocketService,
    private sanitizer: DomSanitizer,
    public errorService: ErrorService,
    private navSite: ActivatedRoute,
    private userService: Userservice,
    private brandManager: AdminBrandManagerService,
    private formBuilder: FormBuilder,
    private mediaLibraryManager: AdminMediaLibraryManagerService,
    private mediaLibraryAccessUsageBuilder: FormBuilder,
    private successMessageService: SuccessMessageModalService,
    private mediaLibService: AdminMediaLibraryPageService,
  ) {
    if (
      this.oRouter.url === "/business-profile/business-plus/manage-libraries" ||
      this.oRouter.url === "/business-profile/franchesees/media-library" ||
      this.oRouter.url === "/business-profile/brands/media-library"
    ) {
      this.bDisplayMediaButtons = true;
    }
    this.mediaManager.visuals = [];
    this.searchLibraryForm = this.formBuilder.group({
      name: "",
    });
    this.userMediaLibs = this.userService.selectedMediaId;
  }

  searchUserMediaLibrary() {}

  hideDropDowns() {
    const __this = this;
    setTimeout(() => {
      if (__this.searchListMediaLibraries) {
        __this.searchListMediaLibraries.result = [];
      }
    }, 200);
  }
  ngAfterViewInit(): void {
    this.mediaManager.visuals = [];
    const __this = this;
    this.searchLibraryNameChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value): void => {
        if (value.length > 1) {
          __this.searchListMediaLibraries = undefined;
          this.isMediaLibSearchLoading = true;
          this.mediaLibraryManager
            .getListUserMediaLibrary(
              this.userService.selectedUser._id,
              value,
              false
            )
            .then((mediaLibrary: Media_Lib) => {
              if (mediaLibrary && mediaLibrary.result) {
                __this.searchListMediaLibraries = mediaLibrary;
                __this.isMediaLibSearchLoading = false;
                const spliceDefaultLibrary = mediaLibrary.result.filter(
                  (item: any) => {
                    return (
                      item._id.$oid !=
                      this.userService?.selectedMediaId[0]._id.$oid
                    );
                  }
                );
                __this.searchListMediaLibraries.result = spliceDefaultLibrary;
              }
              return __this.searchListMediaLibraries;
            });
        }
      });

    this.modelUserSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          if (value.length > 1) {
            this.userList = [];
            this.userList = await this.userService
              .getUsers(
                this.offset,
                this.limit,
                "created",
                -1,
                value,
                "_id",
                false
              )
              .then((resList) => {
                return (this.userList = resList);
              });

            this.isUserSearchLoading = false;
            return this.userList;
          }
        } else {
          return [];
        }
      });
  }

  getMediaLibraryUsers() {
    this.mediaLibraryUsers = [];
    this.mediaLibraryManager
      .getMediaLibraryUsers(this.userService?.selectedMediaId[0]?._id?.$oid)
      .then((users: any) => {
        if (users.count > 0) {
          this.mediaLibraryUsers = users?.result;
        }
      });
  }

  testMethod(media) {
    console.log("testing test method...");
    console.log("testing test method...", media);
  }

  setMediaLibrayToContainer(media: any) {
    const indexPos = this.seletedMediaLibraryList.findIndex((item) => {
      return item._id.$oid === media._id.$oid;
    });

    if (indexPos == -1) {
      this.seletedMediaLibraryList.push(media);
    }

    this.searchLibraryForm.controls["name"].setValue("");
  }

  setUpMediaUpload() {
    /*var media = new mediaFile();
     media.status = "UPLOADED";
     media.name = "File Name customer video";
     this.mediaManager.uploadMediaList.push(media);

     var media = new mediaFile();
     media.status = "UPLOADED";
     media.name = "File 2 customer video";
     this.mediaManager.uploadMediaList.push(media);

    var media = new mediaFile();
     media.status = "UPLOADING";
     media.name = "File Name customer video";
     this.mediaManager.uploadMediaList.push(media);

       var media = new mediaFile();
     media.status = "CANCEL";
     media.name = "File 3 customer video";
     this.mediaManager.uploadMediaList.push(media); */
  }
  async ngOnInit(): Promise<void> {
    this.setUpMediaUpload();
    // await this.mediaManager.getMediaLibrary(this.adminManager.selectedMediaId);
    // this.MediaLibrarySharedCom]ponent_InitializeJqueryCode();

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.fUserVisualListForm = this.oFormBuilder.group({
      email: this.sUserEmail,
      session_id: this.sSessionId,
      num_skip: 0,
      num_items: 10,
      visual_type: "",
      media_lib: this.sSelectedMediaLibrary,
    });
    this.fUserVisualCountForm = this.oFormBuilder.group({
      email: this.sUserEmail,
      session_id: this.sSessionId,
      visual_type: "",
      media_lib: this.sSelectedMediaLibrary,
    });

    if(this.showMediaLibSelector){
      this.seletedMediaLibraryList = this.userService.userAllowedMediaLists;
      this.seletedMediaLibraryList.map((item) => {
        item.isSelected = true;
      });
      this.resetSearchMediaLibrarties();
    } else {
      this.loadVisual();
    }
    // this.skipVideos = 0;


    var self = this;

    if (this.userAccountMediaLib || this.manageBrandMediaLib) {
      document.getElementById("main-content").classList.add("main-area");
      document.getElementById("media-view").classList.add("media-lib-resize");
    }
    this.accessUsageSidepanel();
    if (this.showMediaLibAccess) {
      this.getMediaLibraryUsers();
    }
    this.getMediaLibDetail();
  }

  ngOnDestroy() {
    this.oAudio.pause();
    this.oAudio.currentTime = 0;
    this.myVideo?.nativeElement?.pause();
    this.userService.clearUserAllowedMediaLists();
}
  accessUsageSidepanel() {
    this.showAssignUserDropDown = false;
    this.mediaLibraryAccessUsage = this.mediaLibraryAccessUsageBuilder.group({
      access: "",
      searchUserText: "",
      user_id: "",
      searchListText: "",
    });
  }

  loadMedia() {
    let emailSearch = this.userService.selectedUserMediaEmail;

    if (this.navSite.snapshot.routeConfig.path.indexOf("user-account/") > -1) {
      this.mediaManager.selectedPage = "user-account";
    } else if (
      this.navSite.snapshot.routeConfig.path.indexOf("manage-brand/") > -1
    ) {
      this.mediaManager.selectedPage = "brand-account";
      emailSearch = undefined;
    } else if (
      this.navSite.snapshot.routeConfig.path.indexOf("media-library/") > -1
    ) {
      this.mediaManager.selectedPage = "media-library-account";
      emailSearch = undefined;
    }

    this.mediaManager.visuals = [];
    this.mediaManager.uploadMediaList = [];

    const mediaLibId = (this.userMediaLibs) ? this.userMediaLibs.filter((item) => item != undefined) : [];

    const mediaLibsCombined = mediaLibId;

    if (
      this.otherMediaLibSelected != undefined &&
      this.otherMediaLibSelected.length > 0
    ) {
      mediaLibsCombined.push(...this.otherMediaLibSelected);
    }
    const mediaReq = [];
    mediaLibsCombined.map((mediaLib: any) => {
      if (
        mediaLib != undefined &&
        mediaLib != null &&
        mediaLib != "undefined" &&
        mediaLib != "" &&
        mediaLib != "null" &&
        mediaLib?._id.$oid != undefined &&
        mediaLib?._id?.$oid
      ) {
        mediaReq.push(mediaLib?._id?.$oid);
      }
    });

    const searchData: SearchData = {
      email : this.sUserEmail,
      offset: this.offset,
      limit: this.limit,
      visual_id: this.searchTerm,
      search_by: this.searchBy,
      sort_by: (this.sortType == -1) ? this.sortBy : this.sortBy,
      asc: this.sortType,
      visual_type: this.mediaType,
      media_lib_ids: mediaReq,
    };

    this.mediaManager.getAdminMediaList(searchData).then((mediaList) => {
      this.createPagination();
    });
  }

  loadVisual(visualType: string = "all") {
    this.loadMedia();
  }
  sortBySearch(val){
    this.sortBy = val;
    this.loadMedia();
  }
  searchMediaLibrary() {
    this.searchTerm = this.search;
    this.searchBy = "name";
    // this.loadMedia();

    this.offset = 0;
      this.nCurrentPage = 1;
      this.mediaManager.visualsTotalCount = 0;
      this.loadVisual(this.sVisualType);
  }

  loadPage(pageNumber: number, forceReload = false) {
    if (pageNumber !== this.nCurrentPage) {
      this.nCurrentPage = pageNumber;
      let skipVideos = (pageNumber - 1) * 18;
      this.offset = skipVideos;
      this.loadMedia();
    }
  }

  MediaLibrarySharedComponent_DisplayMyMediaLibrary() {
    this.oWebSocketService.WebSocket_RegisterEvent(
      "visual_count_result",
      this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse
    );
    this.MediaLibrarySharedComponent_GetUserImagesCount();
    this.MediaLibrarySharedComponent_GetUserClipsCount();
    this.MediaLibrarySharedComponent_GetUserAudioCount();
  }
  MediaLibrarySharedComponent_FetchPage(nIncommingPageNumber: number) {
    if (nIncommingPageNumber !== this.nCurrentPage) {
      this.MediaLibrarySharedComponent_ResetView();
      this.nCurrentPage = nIncommingPageNumber;
      let skipVideos = (nIncommingPageNumber - 1) * 18;
      if (this.sVisualType === "clip") {
        this.MediaLibrarySharedComponent_GetUserClips(18, skipVideos);
      } else if (this.sVisualType === "image") {
        this.MediaLibrarySharedComponent_GetUserImage(18, skipVideos);
      } else if (this.sVisualType === "audio") {
        this.MediaLibrarySharedComponent_GetUserAudios(18, skipVideos);
      } else {
        this.nPageSize = 18 / this.nUserVisualsCount;
        // console.log("Total : ==> ", this.nPageSize);
        let nImages = Math.floor(this.nPageSize * this.nUserImagesCount);
        let nClips = Math.floor(this.nPageSize * this.nUserClipsCount);
        let nAudios = Math.floor(this.nPageSize * this.nUserAudiosCount);
        let imageSkip = (nIncommingPageNumber - 1) * nImages;
        let clipSkip = (nIncommingPageNumber - 1) * nClips;
        let audioSkip = (nIncommingPageNumber - 1) * nAudios;
        this.MediaLibrarySharedComponent_GetUserImage(nImages, imageSkip);
        this.MediaLibrarySharedComponent_GetUserClips(nClips, clipSkip);
        this.MediaLibrarySharedComponent_GetUserAudios(nAudios, audioSkip);
      }
    }
  }
  MediaLibrarySharedComponent_ResetView() {
    this.mediaManager.selectedVisual = null;
    this.mediaManager.selectedVisuals = [];
  }
  MediaLibrarySharedComponent_GetUserImage(
    nIemsToFetch: number,
    nItemsToSkip: number
  ) {
    this.fUserVisualListForm.patchValue({
      visual_type: "image",
      num_items: nIemsToFetch,
      num_skip: nItemsToSkip,
    });
    if (nIemsToFetch !== 0) {
      this.oWebSocketService.WebSocket_SendMessage(
        "user_visual_list_request",
        JSON.stringify(this.fUserVisualListForm.value)
      );
    }
  }
  MediaLibrarySharedComponent_GetUserClips(
    nIemsToFetch: number,
    nItemsToSkip: number
  ) {
    this.fUserVisualListForm.patchValue({
      visual_type: "clip",
      num_items: nIemsToFetch,
      num_skip: nItemsToSkip,
    });
    if (nIemsToFetch !== 0) {
      this.oWebSocketService.WebSocket_SendMessage(
        "user_visual_list_request",
        JSON.stringify(this.fUserVisualListForm.value)
      );
    }
  }
  MediaLibrarySharedComponent_GetUserAudios(
    nIemsToFetch: number,
    nItemsToSkip: number
  ) {
    this.fUserVisualListForm.patchValue({
      visual_type: "audio",
      num_items: nIemsToFetch,
      num_skip: nItemsToSkip,
    });
    if (nIemsToFetch !== 0) {
      this.oWebSocketService.WebSocket_SendMessage(
        "user_visual_list_request",
        JSON.stringify(this.fUserVisualListForm.value)
      );
    }
  }
  MediaLibrarySharedComponent_GetUserImagesCount() {
    this.fUserVisualCountForm.patchValue({ visual_type: "image" });
    this.oWebSocketService.WebSocket_SendMessage(
      "visual_count_request",
      JSON.stringify(this.fUserVisualCountForm.value)
    );
  }
  MediaLibrarySharedComponent_GetUserClipsCount() {
    this.fUserVisualCountForm.patchValue({ visual_type: "clip" });
    this.oWebSocketService.WebSocket_SendMessage(
      "visual_count_request",
      JSON.stringify(this.fUserVisualCountForm.value)
    );
  }
  MediaLibrarySharedComponent_GetUserAudioCount() {
    this.fUserVisualCountForm.patchValue({ visual_type: "audio" });
    this.oWebSocketService.WebSocket_SendMessage(
      "visual_count_request",
      JSON.stringify(this.fUserVisualCountForm.value)
    );
  }
  MediaLibrarySharedComponent_SelectMediaType(oIncommingMediaCategory: any) {
    this.close();
    if (this.sVisualType !== oIncommingMediaCategory) {
      this.sVisualType = oIncommingMediaCategory;
      this.searchTerm = "";
      this.search = "";
      this.mediaType = oIncommingMediaCategory;

      this.bSelectMultipleVisuals = false;
      this.MediaLibrarySharedComponent_ResetView();

      // this.loadMedia();
      // this.loadPage(1, true);

      this.offset = 0;
      this.nCurrentPage = 1;
      this.mediaManager.visualsTotalCount = 0;
      this.loadVisual(this.sVisualType);
    }
  }
  MediaLibrarySharedComponent_PlayVideo(visual: visual) {
    visual.isSelected = !visual.isSelected;

    if (visual.visual_type === "clip") {
      var video = <HTMLVideoElement>document.getElementById(visual.visual_path);
      var play_pause_status = "pause";

      if (play_pause_status == "pause") {
        video.play();
        console.log("Play");
      } else {
        video.pause();
        console.log("Pause");
      }
    }
  }

  MediaLibrarySharedComponent_PassSingleVisualToSidebar(oIncommingVisual: any) {
    oIncommingVisual.isSelected = !oIncommingVisual.isSelected;

    if (oIncommingVisual.isSelected)
      this.mediaManager.selectedVisual = oIncommingVisual;

    this.bDisplaySelect = true;
    document.getElementById("main-content").classList.add("main-area");
    document.getElementById("selectmedia").classList.add("active");
    this.selectListView = true;

    this.bSelectMultipleVisuals = false;
    this.oSingleVisual = oIncommingVisual;
    this.lKeyTerms = this.oSingleVisual.key_terms;
  }

  selectVisual(oIncommingVisual: visual) {
    this.selectmedia = true;
    this.oAudio.pause();
    this.oAudio.currentTime = 0;
    this.myVideo?.nativeElement?.pause();

    document.getElementById("uploadingMedia").classList.remove("active");
    document.getElementById("media-view").classList.add("media-lib-resize");
    oIncommingVisual.isSelected = !oIncommingVisual.isSelected;

    if (oIncommingVisual.isSelected) {
      this.mediaManager.selectedVisual = oIncommingVisual;
      this.mediaManager.selectedVisuals.push(oIncommingVisual);
      if (!this.mediaManager.selectedVisual.key_terms) {
        this.mediaManager.selectedVisual.key_terms = [];
      }

      this.getCommonTag();
    } else {
      this.mediaManager.selectedVisuals.forEach((ele: visual, index) => {
        if (ele.visual_path === oIncommingVisual.visual_path) {
          this.mediaManager.selectedVisuals.splice(index, 1);
        }
      });
    }

    this.bDisplaySelect = true;
    document.getElementById("main-content").classList.add("main-area");
    document.getElementById("selectmedia").classList.add("active");
    this.selectListView = true;
  }

  MediaLibrarySharedComponent_SelectedImage(nIncomingIndex) {
    this.oSelectedImage = nIncomingIndex;
    console.log(
      "MediaLibrarySharedComponent_SelectedImage ==>",
      this.oSelectedImage
    );
    if (this.oSelectedImage.visual_type == "audio") {
      this.MediaLibrarySharedComponent_PlayAudio(nIncomingIndex);
    }
    console.log(
      "MediaLibrarySharedComponent_SelectedImage Incoming Tags ==>",
      this.lSelectedKeyTermsTags
    );
  }
  CreateVideoLooknFeelComponent_EnableSingleSelection() {
    this.bSelectMultipleVisuals = false;
  }

  removeTag(tag) {
    //this.lKeyTerms.splice(index , 1);
    if (this.bSelectMultipleVisuals) {
      this.mediaManager.selectedVisuals.forEach((visual) => {
        visual.key_terms.forEach((c, index) => {
          if (c == tag) {
            visual.key_terms.splice(index, 1);
          }
        });

        this.updateVisual(visual);
      });
    } else {
      this.mediaManager.selectedVisual.key_terms.forEach((c, index) => {
        if (c == tag) {
          this.mediaManager.selectedVisual.key_terms.splice(index, 1);
        }
      });
      this.updateVisual(this.mediaManager.selectedVisual);
    }
  }

  updateVisual(visual: visual) {
    this.mediaManager.updateVisual(
      this.mediaManager.selectedVisual?.visual_type == "clip"
        ? "video"
        : this.mediaManager.selectedVisual?.visual_type,
      visual.visual_path,
      visual.key_terms
    );
  }

  CreateVideoLooknFeelComponent_RemoveTag(index, tag?) {
    //this.lKeyTerms.splice(index , 1);
    if (this.bSelectMultipleVisuals) {
      this.mediaManager.selectedVisuals.forEach((visual) => {
        visual.key_terms.forEach((element, index) => {
          if (tag == element) visual.key_terms.splice(index, 1);
        });
        this.mediaManager.updateVisual(
          this.mediaManager.selectedVisual?.visual_type == "clip"
            ? "video"
            : this.mediaManager.selectedVisual?.visual_type,
          visual.visual_path,
          visual.key_terms
        );
      });
    } else {
      this.mediaManager.selectedVisual.key_terms.splice(index, 1);
      this.mediaManager.updateVisual(
        this.mediaManager.selectedVisual?.visual_type == "clip"
          ? "video"
          : this.mediaManager.selectedVisual?.visual_type,
        this.mediaManager.selectedVisual.visual_path,
        this.mediaManager.selectedVisual.key_terms
      );
    }
  }
  CreateVideoLooknFeelComponent_FocusoutFromInput() {
    if (this.inputValue !== "") {
      this.CreateVideoLooknFeelComponent_AddTag();
    }
    setTimeout(() => {
      this.bDisplayAddInputField = false;
    }, 100);
  }
  CreateVideoLooknFeelComponent_AddTag() {
    this.bDisplayAddInputField = false;

    if (this.bSelectMultipleVisuals) {
      this.mediaManager.selectedVisuals.forEach((element) => {
        let isTagExist = false;
        element.key_terms.forEach((tag) => {
          if (this.inputValue == tag) {
            isTagExist = true;
          }
        });

        if (!element.key_terms || !isTagExist) {
          element.key_terms.push(this.inputValue);
        }
        this.mediaManager.updateVisual(
          this.mediaManager.selectedVisual?.visual_type == "clip"
            ? "video"
            : this.mediaManager.selectedVisual?.visual_type,
          element.visual_path,
          element.key_terms
        );
      });
    } else {
      let isTagExist = false;
      this.mediaManager.selectedVisual.key_terms.forEach((tag) => {
        if (this.inputValue == tag) {
          isTagExist = true;
        }
      });

      if (!isTagExist) {
        this.mediaManager.selectedVisual.key_terms.push(this.inputValue);
      }

      this.mediaManager.updateVisual(
        this.mediaManager.selectedVisual?.visual_type == "clip"
          ? "video"
          : this.mediaManager.selectedVisual?.visual_type,
        this.mediaManager.selectedVisual.visual_path,
        this.mediaManager.selectedVisual.key_terms
      );
    }
    this.getCommonTag();
    this.inputValue = "";
  }

  getCommonTag() {
    let data = [];

    this.mediaManager.selectedVisuals.forEach((elemnet) => {
      data.push(elemnet.key_terms);
    });

    this.commonTags = data.reduce((a, b) => a.filter((c) => b.includes(c)));
  }
  CreateVideoLooknFeelComponent_EnterKeyTermsTag(keycode) {
    if (
      (keycode == 13 && this.inputValue != "") ||
      (keycode == 188 && this.inputValue != "")
    ) {
      this.CreateVideoLooknFeelComponent_AddTag();
    }
  }
  CreateVideoLooknFeelComponent_GetInputValue(event) {
    this.inputValue = event.target.value;
  }
  CreateVideoLooknFeelComponent_DisplayAddTagInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  CreateVideoLooknFeelComponent_DisplayAddTagMultiSelectedInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  BusinessProfileSidepanelsComponent_UpdateKeyterms(oIncommingVisual) {
    let oUpdateVisualPayload = {
      key_terms: this.lKeyTerms,
      email: this.sUserEmail,
      session_id: this.sSessionId,
      visual_id: oIncommingVisual.visual_path,
    };
    console.log(
      "BusinessProfileSidepanelsComponent_UpdateKeyterms : Keyterms Request ==>",
      oUpdateVisualPayload
    );
    this.oWebSocketService.WebSocket_SendMessage(
      "update_visual_request",
      JSON.stringify(oUpdateVisualPayload)
    );
  }

  BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse = (
    IncomingMessage
  ) => {
    if (IncomingMessage.errorMessage) {
      console.error(
        "BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse : Error ==>",
        IncomingMessage.errorMessage
      );
    } else {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log(
        "BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse : ==>",
        formatedIcomingMessage
      );
      this.lUserVisualsList.forEach((element) => {
        if (element.visual_path == formatedIcomingMessage.visual_id) {
          element.key_terms = [...this.lKeyTerms];
        }
      });
      this.lSelectedUserVisualsList.forEach((element) => {
        if (element.visual_path == formatedIcomingMessage.visual_id) {
          element.key_terms = [...this.lKeyTerms];
        }
      });
    }
  };
  MediaLibrarySharedComponent_DeleteVisual() {
    if (this.bSelectMultipleVisuals) {
      this.mediaManager.deleteAllSelectedVisuals(
        this.sUserEmail,
        this.sSessionId
      );
      this.bSelectMultipleVisuals = false;
    } else {
      this.mediaManager
        .deleteVisual(
          this.sUserEmail,
          this.sSessionId,
          this.mediaManager.selectedVisual.visual_path
        )
        .then((res) => {
          this.close();
          this.mediaManager.selectedVisual = undefined;
        });
    }
  }

  BusinessProfileSidepanelsComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async MediaLibrarySharedComponent_GenerateDisplayList(oIncommingVisual: any) {
    const imageStaticThumb = "../assets/images/business_Profile/audio-line.png";
    this.nUserVisualID = this.nUserVisualID + 1;
    let visual_path = oIncommingVisual.visual_path;
    let filename = visual_path.substr(
      visual_path.indexOf(".com/") + 5,
      visual_path.length
    );
    let key_terms = oIncommingVisual.key_terms
      ? oIncommingVisual.key_terms
      : [];
    let visual_type = oIncommingVisual.visual_type;
    let thumbnail =
      !oIncommingVisual.thumb && visual_type !== "audio"
        ? await this.oDataService.DataService_GetUserVisualThumbnail(
            visual_path
          )
        : oIncommingVisual.thumb;
    let url = await this.oDataService.DataService_GetSignedURLForDownload(
      visual_path
    );
    let size = oIncommingVisual.size;
    let duration = oIncommingVisual.duration;
    let library = oIncommingVisual.media_lib;
    let used_in = oIncommingVisual.videos_used_in
      ? oIncommingVisual.videos_used_in
      : [];
    let sThumbnail: string;
    let checked: boolean = false;

    if (visual_type == "audio") {
      sThumbnail = imageStaticThumb;
    } else {
      sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(
        thumbnail
      );
    }
    //if user do Bulk upload then populate data accordingly
    if (this.sVisualType === "audio" && visual_type === "audio") {
      this.lUserVisualsList.unshift({
        id: this.nUserVisualID,
        thumb: sThumbnail,
        url: url,
        visual_path: visual_path,
        key_terms: key_terms,
        visual_type: visual_type,
        name: filename,
        size: size,
        library: library,
        used_in: used_in,
        checked: checked,
        duration: duration,
      });
    } else if (this.sVisualType === "image" && visual_type === "image") {
      this.lUserVisualsList.unshift({
        id: this.nUserVisualID,
        thumb: sThumbnail,
        url: url,
        visual_path: visual_path,
        key_terms: key_terms,
        visual_type: visual_type,
        name: filename,
        size: size,
        library: library,
        used_in: used_in,
        checked: checked,
        duration: duration,
      });
    } else if (this.sVisualType === "clip" && visual_type === "clip") {
      this.lUserVisualsList.unshift({
        id: this.nUserVisualID,
        thumb: sThumbnail,
        url: url,
        visual_path: visual_path,
        key_terms: key_terms,
        visual_type: visual_type,
        name: filename,
        size: size,
        library: library,
        used_in: used_in,
        checked: checked,
        duration: duration,
      });
    } else if (this.sVisualType === "all") {
      this.lUserVisualsList.unshift({
        id: this.nUserVisualID,
        thumb: sThumbnail,
        url: url,
        visual_path: visual_path,
        key_terms: key_terms,
        visual_type: visual_type,
        name: filename,
        size: size,
        library: library,
        used_in: used_in,
        checked: checked,
        duration: duration,
      });
    }
    // console.log('List to display :', this.lUserVisualsList);
  }
  MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse = (
    IncomingMessage
  ) => {
    if (IncomingMessage.errorMessage) {
      console.error(
        "MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Error ==> ",
        JSON.parse(IncomingMessage.errorMessage)
      );
    } else {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log(
        "MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : ",
        formatedIcomingMessage
      );
      if (formatedIcomingMessage.visual_type == "image") {
        this.nUserImagesCount = formatedIcomingMessage.count;
      } else if (formatedIcomingMessage.visual_type == "clip") {
        this.nUserClipsCount = formatedIcomingMessage.count;
      } else if (formatedIcomingMessage.visual_type == "audio") {
        this.nUserAudiosCount = formatedIcomingMessage.count;
      }
      this.nUserVisualsCount =
        this.nUserImagesCount + this.nUserClipsCount + this.nUserAudiosCount;
      console.log(
        "MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Total visuals count ==> ",
        this.nUserVisualsCount
      );
      if (this.nUserVisualsCount) {
        this.MediaLibrarySharedComponent_SelectMediaType("all");
        this.oWebSocketService.WebSocket_UnRegisterEvent(
          "visual_count_result",
          this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse
        );
      }
    }
  };
  createPagination() {
    this.lPagination = [];
    let pages = Math.ceil(this.mediaManager.visualsCount / this.nPageSize);
    for (
      let nPaginationIndex = 1;
      nPaginationIndex <= pages;
      nPaginationIndex++
    ) {
      this.lPagination.push(nPaginationIndex);
    }
  }
  MediaLibrarySharedComponent_PlayAudio(key) {
    if (this.currentKey !== key) {
      this.oAudio.src = key;
      this.currentKey = key;
    }
    if (this.oAudio.paused) {
      this.oAudio.play();
    } else {
      this.oAudio.pause();
      this.oAudio.currentTime = 0;
    }
  }
  MediaLibrarySharedComponent_StopAudio(filepath) {
    this.myVideo.nativeElement.pause();
    let filename = filepath.replace(/^.*(\\|\/|\:)/, "");
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, "") == filename) {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
    }
    this.bSelectMultipleVisuals = false;
  }
  MediaLibrarySharedComponent_CloseSidebar() {
    this.bSelectMultipleVisuals = false;

    document.getElementById("multiselectmedia").classList.remove("active");
    this.lSelectedUserVisualsList.forEach((element) => {
      if (element.checked) {
        element.checked = false;
      }
    });
    this.lSelectedUserVisualsList = [];
    this.oSelectedImage = "";
    this.lKeyTerms = [];
  }

  clearUploadField() {
    this.uploadFileField.nativeElement.value = "";
    console.log("Clear filed..");
  }

  uploadMediaFiles(event: any) {
    let lUserSelectedFiles = event.target.files;

    for (
      let nFileToReadIndex = 0;
      nFileToReadIndex < lUserSelectedFiles.length;
      nFileToReadIndex++
    ) {
      var isfound = this.mediaManager.uploadMediaList.filter(
        (c) => c.name == lUserSelectedFiles[nFileToReadIndex].name
      )[0];

      if (
        !isfound &&
        (lUserSelectedFiles[nFileToReadIndex].type === "image/jpg" ||
          lUserSelectedFiles[nFileToReadIndex].type === "image/png" ||
          lUserSelectedFiles[nFileToReadIndex].type === "image/jpeg" ||
          lUserSelectedFiles[nFileToReadIndex].type === "audio/mp3" ||
          lUserSelectedFiles[nFileToReadIndex].type === "audio/mpeg" ||
          lUserSelectedFiles[nFileToReadIndex].type === "video/mp4" ||
          lUserSelectedFiles[nFileToReadIndex].type === "video/mov" ||
          lUserSelectedFiles[nFileToReadIndex].type === "video/quicktime")
      ) {
        let mf = new mediaFile();

        mf.name = lUserSelectedFiles[nFileToReadIndex].name;
        mf.file = lUserSelectedFiles[nFileToReadIndex];
        mf.status = undefined;
        mf.uri = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(mf.file)
        );
        this.mediaManager.uploadMediaList.push(mf);
      } else {
        this.clearUploadField();
        if(isfound){
          this.errorService.handleError(
            "Duplicate file found.", "File Upload failed.",
            true
          );
        } else {
          this.errorService.handleError(
            "Incorrect media format or type not supported",
            "Unsupported Media ",
            true
          );
        }
      }
    }
    let ownerType = "organization";
    if (this.mediaManager.uploadMediaList.length > 0) {
      let pageName = "default";
      let selectedMediaLibraryId = "";
      let emailToPass = this.sUserEmail;

      if (
        this.navSite &&
        this.navSite.snapshot &&
        this.navSite.snapshot.routeConfig &&
        this.navSite.snapshot.routeConfig.path &&
        (this.navSite.snapshot.routeConfig.path.indexOf("user-account/") > -1 ||
          this.navSite.snapshot.routeConfig.path.indexOf("manage-brand/") >
            -1 ||
          this.navSite.snapshot.routeConfig.path.indexOf("media-libraries/") >
            -1) &&
        this.userService?.selectedMediaId &&
        this.userService?.selectedMediaId.length > 0
      ) {
        if (
          this.navSite.snapshot.routeConfig.path.indexOf("user-account/") > -1
        ) {
          // TODO put undefined condition
          emailToPass = this.userService.selectedUser._id;
          this.mediaManager.selectedPage = "user-account";
          ownerType = "user";
        } else if (
          this.navSite.snapshot.routeConfig.path.indexOf("manage-brand/") > -1
        ) {
          ownerType = "brand";
          this.mediaManager.selectedPage = "brand-account";
        } else if (
          this.navSite.snapshot.routeConfig.path.indexOf("media-libraries/") >
          -1
        ) {
          ownerType = "organization";
          this.mediaManager.selectedPage = "media-library";
        }

        pageName = "adminPage";
        selectedMediaLibraryId =
          this.userService?.selectedMediaId[0]?._id?.$oid;
      }

      if (pageName === "adminPage" && selectedMediaLibraryId != undefined) {
        console.log(" Upload media ", ownerType, this.navSite.snapshot.routeConfig.path);
        this.mediaManager.uploadAdminMedia(
          this.sUserEmail,
          this.sSessionId,
          selectedMediaLibraryId,
          emailToPass,
          this.manageBrandMediaLib ? this.selectedBrandId : undefined,
          ownerType
        );
        this.clearUploadField();
      } else {
        this.clearUploadField();
        this.errorService.handleError(
          "Library does not found.",
          "Upload file ",
          true
        );
        console.log(" you can not upload media wit...");
      }

      this.openLoading();
    }
    setTimeout(() => {
      this.closeModal.nativeElement.click();
    }, 2000);
  }
  ngOnChanges() {
    if (this.sInputVisualPath) {
      console.log(
        "Incomming Visual path : NgOnChanges ==> ",
        this.sInputVisualPath
      );
      this.sInputVisualPath = "";
    }

    if (this.lInputVisualsToDelete.length > 0) {
      console.log(
        "Incomming Visual To delete list : NgOnChanges ==> ",
        this.lInputVisualsToDelete
      );
      for (
        let nVisualTodeleteIndex = 0;
        nVisualTodeleteIndex < this.lInputVisualsToDelete.length;
        nVisualTodeleteIndex++
      ) {
        const sFileName = this.lInputVisualsToDelete[
          nVisualTodeleteIndex
        ].name.replace(/ /g, "_");
        const oVisualToDelete = this.lUserVisualsList.find(
          ({ name }) => name === sFileName
        );
        if (oVisualToDelete) {
        }
      }
      this.lInputVisualsToDelete = [];
    }
  }

  MediaLibrarySharedComponent_PlayVideoPosterButton(visual: visual) {
    visual.isSelected = !visual.isSelected;
  }
  deleteSelectedUploadingMedia(media) {
    for (
      let index = 0;
      index < this.mediaManager.uploadMediaList.length;
      index++
    ) {
      const element = this.mediaManager.uploadMediaList[index];
      if (element.name == media.name) {
        console.log("Deleted Index = ", index);
        this.mediaManager.uploadMediaList.splice(index, 1);
      }
    }
  }

  getFileByPath(media) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(media));
  }
  openslect() {
    document.getElementById("main-content").classList.remove("main-area");
    document.getElementById("selectmedia").classList.remove("active");
    document.getElementById("multiselectmedia").classList.remove("active");
    document.getElementById("uploadingMedia").classList.remove("active");

    document.getElementById("media-view").classList.remove("media-lib-resize");
    this.bDisplaySelect = false;
    this.multiselectmedia = false;
    this.selectmedia = false;
    this.uploadingMedia = false;

    this.selectedItemId = -1;
    this.bSelectMultipleVisuals = false;
    this.lSelectedUserVisualsList = [];
    this.selectListView = false;
  }
  SelectListDisplay() {
    console.log("SelectListDisplay");
  }
  openMultiSelectMedia() {
    document.getElementById("main-content").classList.add("main-area");
    document.getElementById("multiselectmedia").classList.add("active");
    document.getElementById("selectmedia").classList.remove("active");
    document.getElementById("uploadingMedia").classList.remove("active");
    document.getElementById("media-view").classList.add("media-lib-resize");
    this.bDisplaySelect = true;
    this.multiselectmedia = true;
    this.uploadingMedia = false;
    this.selectmedia = false;
    this.bSelectMultipleVisuals = true;
    this.mediaManager.visuals.map((c) => (c.isSelected = false));
    this.mediaManager.selectedVisuals = [];
    this.selectListView = true;
  }

  openLoading() {
    document.getElementById("main-content").classList.add("main-area");
    document.getElementById("uploadingMedia").classList.add("active");
    document.getElementById("multiselectmedia").classList.remove("active");
    document.getElementById("selectmedia").classList.remove("active");
    document.getElementById("media-view").classList.add("media-lib-resize");
    this.bDisplaySelect = true;
    this.multiselectmedia = false;
    this.uploadingMedia = true;
    this.selectmedia = false;
    this.bSelectMultipleVisuals = true;
    this.mediaManager.selectedVisuals = [];
    this.selectListView = true;
  }

  close() {
    document.getElementById("selectmedia").classList.remove("active");
    document.getElementById("uploadingMedia").classList.remove("active");
    document.getElementById("multiselectmedia").classList.remove("active");
    this.selectListView = false;

    this.bDisplaySelect = false;
    this.multiselectmedia = false;
    this.selectmedia = false;
    this.uploadingMedia = false;

    this.selectedItemId = -1;
    this.selectedItemArray = [];
    if (!this.userAccountMediaLib && !this.manageBrandMediaLib) {
      document
        .getElementById("media-view")
        .classList.remove("media-lib-resize");
      document.getElementById("main-content").classList.remove("main-area");
      this.selectListView = false;
    }
    this.oAudio.pause();
    this.oAudio.currentTime = 0;
    this.myVideo?.nativeElement?.pause();
  }

  isActiveMultiSelection(visual: visual) {
    return (
      this.mediaManager.selectedVisuals.filter(
        (o1) => o1.visual_path === visual.visual_path
      ).length > 0
    );
  }

  resetSearchMediaLibrarties() {
    this.otherMediaLibSelected = undefined;
    const activeLibs = this.seletedMediaLibraryList.filter((item) => {
      if (
        item.isSelected != undefined &&
        item.isSelected == true &&
        item._id.$oid != undefined
      ) {
        if (
          item._id &&
          item._id.$oid != null &&
          item._id.$oid != undefined &&
          item._id.$oid != "undefined" &&
          item._id.$oid != "null"
        ) {
          return item;
        }
      }
    });
    if (activeLibs != undefined && activeLibs.length > 0) {
      this.otherMediaLibSelected = activeLibs;
    }
    this.loadMedia();
  }

  selectLibraryForSearch(item) {
    const __this = this;
    item.isSelected = item.isSelected ? false : true;
    setTimeout(() => {
      __this.resetSearchMediaLibrarties();
    }, 200);
  }

  toggleMediaLibs(value: string) {
    let activeStatus = false;
    if (value == "all") {
      activeStatus = true;
    }
    this.seletedMediaLibraryList.map((item) => {
      item.isSelected = activeStatus;
    });
    this.resetSearchMediaLibrarties();
    // this.loadMedia();
  }
  setLibraryAccess(incomingLibraryAccess: string) {
    const mediaLibraryInfo: MediaLibrary = {
      _id: this.userService?.selectedMediaId[0]?._id?.$oid,
      library_access: incomingLibraryAccess,
    };
    // let mediaLibraryId = this.adminManager?.selectedMediaId[0]?._id?.$oid;
    let __this = this;
    this.mediaLibService
      .updateMediaLibDetails(mediaLibraryInfo)
      .then((res: any) => {
        if (res) {
          this.getMediaLibraryUsers();
          this.successMessageService.display(
            "Library Access has been changed successfully.",
            "Library Access",
            true,
            true
          );
          this.getMediaLibDetail();
        }
      });
  }
  changeAccessLimit(value) {
    console.log("accval", value);
    if (value == this.Access_Control.global) {
      this.showAssignUserDropDown = false;
    } else {
      this.showAssignUserDropDown = true;
    }
    this.setLibraryAccess(value);
  }
  setAddUsers(user: user): void {
    console.log("chkuser", user);

    const pos = this.selectedAddUsers.some((item) => {
      return item._id == user;
    });

    if (!pos) {
      this.selectedAddUsers.push(user);
    }
    this.mediaLibraryAccessUsage.controls["searchUserText"].setValue("");
  }

  removeAddUsers(user: user): void {
    var indexBrand = this.selectedAddUsers.indexOf(user);
    if (indexBrand > -1) {
      this.selectedAddUsers.splice(indexBrand, 1);
    }
  }

  viewAllSelectedUsers(val) {
    this.userListLoopLimit = val;
  }
  allowAddUser() {
    this.allowAddNewUser = !this.allowAddNewUser;
  }
  addUserMediaLibrary() {
    let userIds: any[] = this.selectedAddUsers;
    let mediaLibraryId = this.userService?.selectedMediaId[0]?._id?.$oid;
    // const mediaLibId = this.userMediaLibs.filter((item) => (item != undefined));
    // console.log(
    //   "chklib",
    //   mediaLibraryId[0]?._id?.$oid,
    //   userIds,
    //   this.selectedAddUsers
    // );

    this.mediaLibraryManager
      .addUserMediaLibrary([mediaLibraryId], userIds)
      .then((res) => {
        if (res) {
          this.getMediaLibraryUsers();
          this.successMessageService.display(
            "User has been added successfully.",
            "Add User",
            true,
            true
          );
          this.selectedAddUsers = [];
        }
      });
  }

  delMedLibUser(user) {
    console.log("chkuser", user);

    this.selectedDelUser = user;
    this.deleteUserModal = true;
  }
  deleteMedLibUser() {
    let userId = this.selectedDelUser?._id?.$oid;
    this.mediaLibraryManager.deleteMediaLibraryUsers(userId).then((res) => {
      if (res) {
        var indexUser = this.mediaLibraryUsers.indexOf(this.selectedDelUser);
        if (indexUser > -1) {
          this.mediaLibraryUsers.splice(indexUser, 1);
        }
        this.deleteUserModal = false;
        this.successMessageService.display(
          "User has been unassigned",
          "Remove Assigned User",
          true,
          true
        );
      }
    });
  }
  getMediaLibDetail() {
    let mediaLibraryId = this.userService?.selectedMediaId[0]?._id?.$oid;
    console.log("GET MEDIA LIN DETAIL ...", mediaLibraryId);
    if (!mediaLibraryId) {
      mediaLibraryId = this.userService?.selectedMediaId[0]?._id;
    }
    let __this = this;
    this.mediaLibService
      .getMediaLibraryProfile(mediaLibraryId)
      .then((mediaLibData: any) => {
        __this.mediaLibraryDetail = mediaLibData;
        if (
          __this.mediaLibraryDetail.library_access == "" ||
          __this.mediaLibraryDetail.library_access == "limited"
        ) {
          this.showAssignUserDropDown = true;
          this.mediaLibraryAccessUsage.controls["access"].setValue("limited");
        } else {
          this.showAssignUserDropDown = false;
          this.mediaLibraryAccessUsage.controls["access"].setValue("global");
        }

        console.log("chklib", __this.mediaLibraryDetail);
      })
      .catch((err) => {
        this.showAssignUserDropDown = true;
        this.mediaLibraryAccessUsage.controls["access"].setValue("limited");
      });
  }

  loadPageEvent($event) {
    if ($event.pageSize != this.nPageSize) {
      this.nPageSize = $event.pageSize;
      this.loadPage(1, true);
    } else {
      this.loadPage($event.pageIndex + 1);
    }
  }
  sortTypeSearch(val : number){
    this.sortType = val;
    this.loadMedia();
  }

  gridView() {
    document.getElementById('gridView')?.classList?.add('active');
    document.getElementById('listView')?.classList?.remove('active');
    this.listview = false;
    console.log("chkgrid",this.listview );
}
listView() {
    document.getElementById('listView')?.classList?.add('active');
    document.getElementById('gridView')?.classList?.remove('active');
    this.listview = true;
    console.log("chklist",this.listview );
}

returnFileName(filename) {
  var split = filename?.split('.');
  var filename = split[0];
  var extension = split[1];
  if (filename.length > 15) {
      filename = filename.substring(0, 14);
  }
  var result = filename + '.' + extension;
  return result;
}
}
