import { Component, OnInit,ChangeDetectorRef, ViewChild, HostListener  } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from "rxjs/operators";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { AdminMediaLibraryManagerService } from "src/app/Managers/adminManager/admin-media-library-manager.service";
import {
  BrandResponse,
  MediaLibraryResponse,
  permission,
  user,
  UserList,
} from "src/app/models/admin";
import { BrandInfo } from "src/app/models/brand";
import { mediaLib, MediaLibSearch } from "src/app/models/media";
import { UserDataInfo } from "src/app/models/user";
import { ErrorService } from "src/app/services/error.service";
import { cSessionService } from "src/app/services/session.service";
import { SubscriptionplanManagerService } from "src/app/Managers/subscriptionplan-manager.service";
import { AdminSettingService } from "../../services/admin-setting-service";
import { Userservice } from "../service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { SettingsService } from "../../services/settings.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  showAddNewUserDrawer = false;
  isDeactivateModalVisible = false;
  isUserLimitModalVisible = false;
  showAccountDetailsDrawer = false;
  showFilterUserDrawer = false;
  showResetPasswordDrawer: boolean = false;
  placementUser: NzDrawerPlacement = "right";
  selectedAddUserBrands: BrandResponse[] = [];
  selectedAddUserMediaLibraries: MediaLibraryResponse[] = [];
  selectedUserEmail: string = "";
  searchBrand = new FormControl();
  searchBrandText: string = "";
  searchMediaLibrary = new FormControl();
  searchTermText = "";
  brandList: BrandInfo;
  isBrandSearchLoading: boolean = false;
  isMediaSearchLoading: boolean = false;
  modelMediaSearchChanged = new Subject<string>();
  modelBrandSearchChanged = new Subject<string>();
  mediaLibraryList: MediaLibSearch;
  showSearchBox = false;
  loadingSearchUser = false;
  showCreatedUserMsg = false;

  // Add user Form
  public addUserForm: FormGroup;
  addUserFormSubmitted = false;
  listPages: number[] = [];
  public deActiveUserId: string = "";
  limit = 15;
  offset = 0;
  currentPage = 1;
  totalPages = 1;
  searchTerm = "";
  searchBy = "created";
  brandListLoopLimit = 3;
  mediaLibrariesListLoopLimit = 3;
  sortType = -1;
  userList: UserList = {
    offset: 0,
    limit: this.limit,
    count: 0,
    result: [],
  };
  MediaLibSearch: { result: any[] };
  showSortByDD = false;

  @ViewChild('brandListAutoComplete', { read: MatAutocompleteTrigger })
    autoCompleteBrand: MatAutocompleteTrigger;

  @ViewChild('mediaLibraryListAutoComplete', { read: MatAutocompleteTrigger })
    mediaLibraryListAutoComplete: MatAutocompleteTrigger;

  constructor(
    public oSessionService: cSessionService,
    public settingService: AdminSettingService,
    private addUserFormBuilder: FormBuilder,
    private adminManager: Userservice,
    private mediaLibraryManager: AdminMediaLibraryManagerService,
    private brandManager: AdminBrandManagerService,
    private errorService: ErrorService,
    public router: Router,
    public subscriptionManager: SubscriptionplanManagerService,
    public cd : ChangeDetectorRef,
    private successMessageModal : SuccessMessageModalService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private settingProntoService : SettingsService
  ) {
    // TODO We will replace this with api
    this.brandList = {
      count: 0,
      result: [],
    };
    // {id : "as", name : "asdfasdf"}

    this.MediaLibSearch = { result: [] };
  }

  populatePagination() {
    if (this.userList && this.userList.total_count > 0) {
      const maxPage = Math.ceil(this.userList?.total_count / this.limit) || 1;
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
      } else {
        this.listPages = [1];
      }
    }
  }

  reloadUserSearch() {
    let __this = this;
    this.loadingSearchUser = true;
    let searchByField = this.searchBy;
    if(this.searchBy == "created"){
      searchByField = "username";
    }
    this.adminManager
      .getUsers(this.offset, this.limit, this.searchBy, this.sortType, this.searchTerm, searchByField )
      .then(function (list: UserList) {
        __this.userList = list;
        setTimeout(() => {
          __this.populatePagination();
        }, 200);
        __this.loadingSearchUser = false;
      }).catch((err)=>{
        __this.userList = undefined;
        __this.loadingSearchUser = false;
      });
  }


  loadPageEvent($event){
    console.log($event);
    if($event.pageSize != this.limit){
      this.limit = $event.pageSize;
      this.loadPage(1);
    } else {
      this.loadPage($event.pageIndex + 1);
    }
  }

  loadPage(page: number) {
    if( page < 1 ) {
      page = 1;
    } else {
      if( page > this.listPages.length ) {
        page = 1;
      }
      this.userList.result = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.reloadUserSearch();
    }
  }

  ngAfterViewInit(): void {
    this.loadingSearchUser = true;
    this.cd.detectChanges();
    this.reloadUserSearch();

    // TODO autocomplete Brands change trigger
    this.modelBrandSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value.length > 1) {
          this.brandManager
            .getListBrand("", "", 0, 30, "name", -1, value, "name", false)
            .then((brand: BrandInfo) => {
              this.brandList = brand;
            });
          this.isBrandSearchLoading = false;
          return this.brandList;
        } else {
          return [];
        }
      });

    // TODO autocomplete Media library change trigger
    this.modelMediaSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value.length > 1) {
          this.mediaLibraryManager
            .getListMedia("", "", 0, 30, "name", -1, value, "name", false)
            .then((mediaList) => {
              this.isMediaSearchLoading = false;
              return (this.mediaLibraryList = mediaList);
            });
        }
      });
  }

  ngOnInit(): void {

    this._MatPaginatorIntl.firstPageLabel = '';
    this._MatPaginatorIntl.lastPageLabel = '';
    this._MatPaginatorIntl.nextPageLabel = '';
    this._MatPaginatorIntl.previousPageLabel = '';

    this.settingService.setHeaderBreadCrumps("user", undefined);
    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('click', this.clickDocument, true);


  }

  clickDocument = ( event : any) : void => {
    console.log(" .. click  event called...");
    this.settingProntoService.hideAllDropDown();
  }
  //  TODO autocomplete position update
  scrollEvent = (event: any): void => {
    console.log(" .. scroll event called...");
    this.settingProntoService.hideAllDropDown();
    if(this.autoCompleteBrand && this.autoCompleteBrand.panelOpen){
      this.autoCompleteBrand.updatePosition();
    }

    console.log(this.mediaLibraryListAutoComplete);
    if(this.mediaLibraryListAutoComplete && this.mediaLibraryListAutoComplete.panelOpen){
      this.mediaLibraryListAutoComplete.updatePosition();
    }
  };

  // add new user drawer
  openAddNewUserDrawer(): void {
   // TODO - have removed this condition
    // if (this.userList?.total_count < 95) {
      if( this.selectedAddUserBrands)
        this.selectedAddUserBrands = [];


      if( this.selectedAddUserMediaLibraries)
        this.selectedAddUserMediaLibraries = [];

      this.addUserFormSubmitted = false;
      this.addUserForm = this.addUserFormBuilder.group({
        name: "",
        email: "",
        role: "creator",
        companyAccounts: false,
        brandAccount: false,
        personalAccounts: false,
        videoDonwload: false,
        brands: "",
        libraries: "",
        searchBrand: "",
        searchBrandText: "",
        searchMediaText: "",
      });
      this.showAddNewUserDrawer = true;
    // } else {
    //   this.isUserLimitModalVisible = true;
    // }
  }

  closeAddNewUserDrawer(): void {
    this.showAddNewUserDrawer = false;
  }

  closeResetPasswordDrawer(): void {
    this.showResetPasswordDrawer = false;
  }

  openResetPasswordDrawer(email): void {
    this.selectedUserEmail = email;
    this.showResetPasswordDrawer = true;
  }

  //acount detail drawer
  openAccountDetailsDrawer(): void {
    this.showAccountDetailsDrawer = true;
  }
  closeAccountDetailsDrawer(): void {
    this.showAccountDetailsDrawer = false;
  }
  //Filter User drawer
  openFilterUserDrawer(): void {
    this.showFilterUserDrawer = true;
  }
  closeFilterUserDrawer(): void {
    this.showFilterUserDrawer = false;
  }

  // modal Deactivate user
  openDeactivateModal(user_id: string): void {
    this.deActiveUserId = user_id;
    this.isDeactivateModalVisible = true;
  }
  closeDeactivateModal(): void {
    this.isDeactivateModalVisible = false;
  }
  isActivateModalVisible = false;
  openActivateModal(user_id: string): void {
    this.deActiveUserId = user_id;
    this.isActivateModalVisible = true;
  }
  closeActivateModal(): void {
    this.isActivateModalVisible = false;
  }

  // modal User Limit
  openUserLimitModal(): void {
    this.isUserLimitModalVisible = true;
  }
  closeUserLimitModal(): void {
    this.isUserLimitModalVisible = false;
  }
  UpgradeUserLimitModal(): void {
    this.isUserLimitModalVisible = false;
    this.subscriptionManager.showPlanDialogue();
  }

  setAddUserRole(role: string): void {
    this.addUserForm.get("role").setValue(role);
  }

  setAddUserBrands(brand: BrandResponse): void {
    const pos = this.selectedAddUserBrands.some((item) => {
      return item._id.$oid == brand._id.$oid;
    });
    if (!pos) {
      this.selectedAddUserBrands.push(brand);
    }
    this.addUserForm.controls["searchBrandText"].setValue("");
  }

  removeAddUserBrands(brand: BrandResponse): void {
    var indexBrand = this.selectedAddUserBrands.indexOf(brand);
    if (indexBrand > -1) {
      this.selectedAddUserBrands.splice(indexBrand, 1);
    }
  }

  setAddUserMediaLibrary(media: MediaLibraryResponse): void {
    const pos = this.selectedAddUserMediaLibraries.some((item) => {
      return item._id.$oid == media._id.$oid;
    });

    if (!pos) {
      this.selectedAddUserMediaLibraries.push(media);
    }
    this.addUserForm.controls["searchMediaText"].setValue("");
  }

  removeAddUserMediaLibrary(media: MediaLibraryResponse): void {
    var indexBrand = this.selectedAddUserMediaLibraries.indexOf(media);
    if (indexBrand > -1) {
      this.selectedAddUserMediaLibraries.splice(indexBrand, 1);
    }
  }

  hideDropDowns() {
    this.brandList.records = [];
    if( this.mediaLibraryList ){
    // this.mediaLibraryList.result = [];
    this.mediaLibraryList.records = [];
    }
  }

  async addNewUser(): Promise<any> {
    console.log("chkres");

    this.addUserFormSubmitted = true;
    if (this.addUserForm.invalid) {
      } else if (!this.selectedAddUserBrands || this.selectedAddUserBrands.length === 0)  {
      this.errorService.handleError('Please assign brand to user', 'Add User Brand', true);
    } else {
      const userInfo: user = {
        _id: this.addUserForm.controls["email"].value,
        user_role: [this.addUserForm.controls["role"].value],
        username: this.addUserForm.controls["name"].value,
        public_videos: 0,
        published_video_count: 0,
        media_uploads: 0,
        login: false,
        subscribe_email_changes: true,
        get_pronto_newsletter: true,
        allow_share_personal_account: this.addUserForm.controls["personalAccounts"].value,
        allow_share_brand_account: this.addUserForm.controls["brandAccount"].value,
        allow_share_company_account: this.addUserForm.controls["companyAccounts"].value,
        allow_download_videos: this.addUserForm.controls["videoDonwload"].value,
        plan: 'enterprise',
      };



      await this.adminManager.addUser(userInfo).then((res: any) => {
        const __this = this;
        setTimeout(async () => {
          // await __this.updateUserPermission(res.id);
          await this.updateUserMediaLibraries(res.record._id);
          await this.updateUserBrands(res.record._id);
          await this.loadNewlyAddedUser(res.record._id);
          this.successMessageModal.display("User created successfully. Invitation has been sent.", "Add User", true, true);
        }, 500);
      });
      this.showAddNewUserDrawer = false;
    }
  }

  async loadNewlyAddedUser(id: string) {
    this.adminManager.getUserProfile(id).then((res: any) => {
      this.userList.result.unshift(res);
    });
  }
  async updateUserBrands(user_id: string) {
    const brandIds: string[] = [];
    if (this.selectedAddUserBrands.length > 0) {
      this.selectedAddUserBrands.map((mediaLib) => {
        return brandIds.push(mediaLib._id.$oid);
      });
    }
    this.adminManager.updateUserBrands(user_id, brandIds);
  }

  async updateUserMediaLibraries(user_id: string) {
    const mediaLibsIds: string[] = [];
    if (this.selectedAddUserMediaLibraries.length > 0) {
      this.selectedAddUserMediaLibraries.map((mediaLib) => {
        return mediaLibsIds.push(mediaLib._id.$oid);
      });
    }
    this.adminManager.updateUserMediaLibs(user_id, mediaLibsIds);
  }

  activateUser() {
    if (this.deActiveUserId != undefined) {
      this.adminManager
        .activateUserAccount(this.deActiveUserId)
        .then((result) => {

          const indexRecrod = this.userList.result.findIndex(
            (user) => user._id == this.deActiveUserId
          );

          if (indexRecrod > -1) {
            this.isActivateModalVisible = false;
            this.userList.result[indexRecrod].status = 'active';
            this.successMessageModal.display("User has been activated.", "Activate User", true, true);
            // this.userList.result.splice(indexRecrod, 1);
            // --this.userList.total_count;
            // --this.userList.count;
          }
        })
        .catch((err) => {
          this.isActivateModalVisible = false;
          this.errorService.handleError(err.errorMessage, err.errorType, true);
        });
    }
  }
  deActivateUser() {
    if (this.deActiveUserId != undefined) {
      this.adminManager
        .deActivateUserAccount(this.deActiveUserId)
        .then((result) => {

          const indexRecrod = this.userList.result.findIndex(
            (user) => user._id == this.deActiveUserId
          );

          if (indexRecrod > -1) {
            this.isDeactivateModalVisible = false;
            this.userList.result[indexRecrod].status = 'inactive';
            this.successMessageModal.display("User has been Deactivated.", "Deactivate User", true, true);
            // this.userList.result.splice(indexRecrod, 1);
            // --this.userList.total_count;
            // --this.userList.count;
          }
        })
        .catch((err) => {
          this.isDeactivateModalVisible = false;
          this.errorService.handleError(err.errorMessage, err.errorType, true);
        });
    }
  }

  async updateUserPermission(id: string) {
    const permission = {
      _id: id,
      subscribe_email_changes: true,
      allow_share_personal_account:
        this.addUserForm.controls["personalAccounts"].value,
      allow_share_brand_account:
        this.addUserForm.controls["brandAccount"].value,
      allow_share_company_account:
        this.addUserForm.controls["companyAccounts"].value,
      allow_download_videos: this.addUserForm.controls["videoDonwload"].value,
    };
    await this.adminManager.updateUserPermission(permission);
  }

  async updatePermission(field: string, value: boolean | string, _id: string) {
    let permission: any;
    switch (field) {
      case "personal":
        permission = { _id, allow_share_personal_account: value };
        break;
      case "brand":
        permission = { _id, allow_share_brand_account: value };
        break;
      case "company":
        permission = { _id, allow_share_company_account: value };
        break;
      case "download":
        permission = { _id, allow_download_videos: value };
        break;
    }

    await this.adminManager.updateUserPermission(permission);
  }

  loadUserProfile(id: string) {
    this.router.navigate(["/account-setting/user-account", id]);
  }

  loadUserProfileMedia(id: string) {
    this.router.navigate([
      `/account-setting/user-account/${id}/user-media-libraries`,
    ]);
  }

  searchUser(){
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.reloadUserSearch();
  }

  clearSearchTerm(){
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.reloadUserSearch();
  }

  sortBySearchResult(value){
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.reloadUserSearch();
  }


  openSearchSortTypeDD(){
    this.showSortByDD = ( this.showSortByDD ) ? false : true;
  }

  hideSearchSortTypeDD($event){
    if(this.showSortByDD){
      const __this = this;
      setTimeout(() =>{
        __this.showSortByDD = false;
      }, 200);

    }
  }

  selectSearchType(){

  }

  viewAllSelectedBrands(val){
    this.brandListLoopLimit = val;
  }

  viewAllSelectedMediaLibraries(val){
    this.mediaLibrariesListLoopLimit = val;
  }
  sortTypeSearch(val : number){

    this.sortType = val;
    this.reloadUserSearch();
  }

  sortDataBy(field){
    if( field == this.searchBy ) {
      this.sortType = ( this.sortType == -1) ? 1 : -1;
      this.sortTypeSearch(this.sortType);

    } else {
      this.sortBySearchResult(field);
    }
  }



  resetUserPwd(email){
    this.adminManager.resetUserPermission(email).then((res) => {
      this.showResetPasswordDrawer = false;
      this.successMessageModal.display("Email has been sent to user", "Reset Password", true , true);
    }).catch((err) => {
      this.showResetPasswordDrawer = false;
      this.errorService.handleError("Issue resetting password", "Reset Password", true , true);
    });
  }
}
