import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VideoManagerService } from '../../../Managers/video-manager.service';
import { cSessionService } from '../../../services/session.service';
import {Location} from '@angular/common';
import { ErrorService } from './../../../services/error.service';
 
 

@Component({
  selector: 'app-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.scss']
})
export class TimelineViewComponent implements OnInit {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  bDisplayProntoShare: boolean = false;
  bDisplayProntoDownload: boolean = true;
  sVideoTitle : string = "";
  sVideoDuration : string = "";
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  oGeneratedVideo : any;
  oPublicUrl: string;
  oVideoDetails: any;
  sPublicVideoUrl: string;
  oClosePopup: any=0;
  bExpandReach:boolean=false;
  
  constructor(private oSessionService : cSessionService, private _location: Location,  private oRouter : Router, public videoManager:VideoManagerService,public errorService:ErrorService ) { }

 async ngOnInit() 
  {
    this.oGeneratedVideo = this.videoManager.selectedVideo;
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();



console.log('step1',this.videoManager.selectedVideo)

  
    if(!this.videoManager.selectedVideo)
  {
    console.log('step2',this.videoManager.selectedVideo)
    this.sVideoId  = this.oSessionService.SessionService_GetVideoId()?.toString();

 
    await this.videoManager.getVideoById(this.sUserEmail,this.sSessionId,this.sVideoId).then(res=>{
      console.log('step3',this.videoManager.selectedVideo)
      this.oVideoDetails = res;
      this.videoManager.selectedVideo = res;
    })

    
  }

  this.sVideoId =  this.videoManager.selectedVideo?.fpath;
    console.log("public url",this.videoManager.selectedVideo)
    this.oPublicUrl = this.videoManager.selectedVideo.public_url;
    this.sVideoTitle = this.videoManager.selectedVideo.title;
    this.sVideoDuration =  this.videoManager.selectedVideo.duration.toString();
    this.oVideoDetails = this.videoManager.selectedVideo;
  this.sPublicVideoUrl = this.videoManager.selectedVideo.public_url;
  }
  TimelineViewComponent_CopyToClipBoard() 
  {
    navigator.clipboard.writeText(this.oPublicUrl);
    console.log(navigator.clipboard.writeText(this.oPublicUrl));
  }
  TimelineViewComponent_DisplayProntoShare()
  {
    this.bDisplayProntoShare = true
    this.bDisplayProntoDownload = false
  }
  TimelineViewComponent_DisplayProntoDownload()
  {
    this.bDisplayProntoDownload = true
    this.bDisplayProntoShare = false
  }
  TimelineViewComponent_PlayVideo()
  {
    this.videoPreview.nativeElement.play();
  }
  TimelineViewComponent_ResetVideo()
  {
    this.videoPreview.nativeElement.load();
    var element = document.getElementById("TemplateThumbnail");
    element.classList.remove("videoWrapperActive");
  }

  unPublishVideo()
  {
    this.videoManager.updateVideoStatus(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo.fpath,'draft').then(res=>{
      this.oRouter.navigate(['/my-videos/draftsVideo']);
    }).catch(err=>{
      
      this.errorService.handleError(err.errorMessage,err.errorType,true);
    });
  }

  deleteVideo()
  {
    this.videoManager.deleteVideo(this.sUserEmail,this.sSessionId,this.videoManager.selectedVideo.fpath).then(res=>{
      this.oRouter.navigate(['/my-videos/draftsVideo']);
    }).catch(err=>{
      
      this.errorService.handleError(err.errorMessage,err.errorType,true);
    });
  }
 
  TimelineViewComponent_NavigateToDashboard()
  {
    
    this.oRouter.navigate(['my-videos/draftsVideo']);
  }
  TimelineViewComponent_GetClickLocation(event)
  {
    this.oClosePopup=0;
    if(event.clientX <= 65)
    {
      this.oClosePopup=1;
      setTimeout(() => {this.oClosePopup=0;}, 200);
    }
  }

 

  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  checktag(){
  console.log(this.videoManager.selectedVideo?.key_terms, "checktag")
}




}
