import { AdminManagerService } from 'src/app/Managers/adminManager/admin-manager.service';
import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { observable, of } from 'rxjs';

import { profile, user } from '../models/onboarding';
import { cDataService } from '../services/data.service';
import { OnboardingDataService } from '../services/dataServices/onboarding-data.service';
import { AccountsettingService } from '../services/dataServices/accountsetting.service';
import { LoaderService } from '../services/loader.service';
import { cSessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { ConfigManagerService } from './config-manager.service';
import { cIntercomService } from '../services/intercom.service';
import * as moment from 'moment';
import { ErrorService } from '../services/error.service';
import { SubscriptionplanManagerService } from './subscriptionplan-manager.service';


@Injectable({
  providedIn: 'root'
})
export class OnboardingManagerService {

  constructor(private intercomService: cIntercomService,
    private subscrptionManager: SubscriptionplanManagerService,
    public errorService: ErrorService, public dataService: OnboardingDataService,
    private route: Router, private oSessionService: cSessionService,
    private loader: LoaderService, private configManager: ConfigManagerService,
    private adminManager: AdminManagerService) {


    let email = this.oSessionService.cSessionService_GetUserEmail()
    let sessionid = this.oSessionService.SessionService_GetSessionId();
    this.loggedUser = this.oSessionService.cSessionService_GetLoggedUser();


    if (email && sessionid) {
      let oName: any = []
      oName = email.split('@')[0];
      this.loggedUser = new user();
      this.loggedUser.email = email;
      this.loggedUser.name = oName,
        this.loggedUser.videoCount = 0;
      this.loggedUser.session_id = sessionid;


      // this.getUserProfile(email,sessionid).then();
    } else {

      // this.route.navigate(['/login']);
    }

  }

  public loggedUser: user;
  public profile: profile;

  async signin(email: string, password: string): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService.signIn(email, password)
        .toPromise()
        .then(
          async (res: any) => {
            console.log('into this methd ............................');
            this.adminManager.getUserProfile(email).then(async (profile) => {
              console.log(profile);
              this.profile = profile;
              this.profile.email = email;
              this.profile.session_id = res.session_id;

              this.oSessionService.cSessionService_SetUserEmail(email)
              this.oSessionService.cSessionService_SetSessionId(res.session_id)
              this.oSessionService.SessionService_StartSessionExpiryTimer();
              this.oSessionService.setUserProfile(this.profile);

              console.log("profile", this.profile);
              let oName: any = []
              oName = email.split('@')[0];
              this.loggedUser = new user();
              this.loggedUser.email = email;
              this.loggedUser.name = oName,
                this.loggedUser.videoCount = 0;
              this.loggedUser.session_id = res.session_id;
              this.oSessionService.cSessionService_SetLoggedUser(this.loggedUser);
              resolve(this.loggedUser);

              // await this.configManager.getTemplates2(email, res.session_id,this.profile.allowed_templates);

              // await  this.getUserVideosCount(email,res.session_id).then(countRes=>{

              //     let videosCount = countRes as number;

              //     let oName: any = []
              //    oName = email.split('@')[0];
              //    this.loggedUser = new user();
              //    this.loggedUser.email = email;
              //    this.loggedUser.name = oName,
              //    this.loggedUser.videoCount = videosCount;
              //    this.loggedUser.session_id = res.session_id;
              //    this.oSessionService.cSessionService_SetLoggedUser(this.loggedUser);
              //    resolve(this.loggedUser);




              //    this.loader.hideLoader();
              //    console.log('logged User'+this.loggedUser)
              //   })
            });
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }
        );
    });
    return promise;
  }

  async signUp(profile: profile): Promise<unknown> {
    this.loader.showLoader();
    profile.is_onboarding = false;
    let promise = new Promise((resolve, reject) => {
      this.dataService.signUp(profile)
        .toPromise()
        .then(
          (res: any) => {
            this.loader.hideLoader();
            this.profile = profile;
            // console.log(res);
            resolve(res);
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }

        );
    });
    return promise;
  }


  async updateProfile(is_onboarding: boolean): Promise<unknown> {
    this.loader.showLoader();

    this.profile.is_onboarding = is_onboarding;
    let promise = new Promise((resolve, reject) => {
      this.dataService.updateProfile(this.profile)
        .toPromise()
        .then(
          (res: any) => {
            this.loader.hideLoader();
            resolve("Updated");
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }
        );
    });
    return promise;
  }


  async singOut(): Promise<unknown> {
    let promise = new Promise((resolve, reject) => {

      this.dataService.singOut(this.loggedUser.email, this.loggedUser.session_id)
        .toPromise()
        .then(
          (res: any) => {

            console.log(res);

            resolve("SignOut");
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  async singOutAll(): Promise<unknown> {
    let promise = new Promise((resolve, reject) => {

      this.dataService.signoutAll(this.loggedUser.email, this.loggedUser.session_id)
        .toPromise()
        .then(
          (res: any) => {

            console.log(res);

            resolve("SignOut");
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  async deleteAccount(): Promise<unknown> {
    let promise = new Promise((resolve, reject) => {

      this.dataService.deleteUserProfile(this.loggedUser.email, this.loggedUser.session_id)
        .toPromise()
        .then(
          (res: any) => {

            console.log(res);

            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  async forgotPassword(email): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {

      this.dataService.forgotPassword(email)
        .toPromise()
        .then(
          (res: any) => {
            this.loader.hideLoader();

            console.log(res);

            resolve("SignOut");
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }
        );
    });
    return promise;
  }

  async updateForgotPassword(email, code, password): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {

      this.dataService.updateForgotPassword(email, code, password)
        .toPromise()
        .then(

          (res: any) => {

            this.loader.hideLoader();
            console.log(res);

            resolve("password reset");
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }
        );
    });
    return promise;
  }


  async resetPassword(email, old_password, password, bypassInterceptor?: boolean): Promise<unknown> {



    console.log('old password', old_password);
    console.log('new password', password);
    this.loader.hideLoader();
    let promise = new Promise((resolve, reject) => {

      this.dataService.updatePassword(email, old_password, password, bypassInterceptor)
        .toPromise()
        .then(

          (res: any) => {

            this.loader.hideLoader();
            console.log(res);

            resolve("password reset");
          },
          msg => { // Error
            this.loader.hideLoader();
            reject(msg);
          }
        );
    });
    return promise;
  }

  async getUserProfile(email: string, session_id: string, Loader?): Promise<unknown> {
    this.loader.loading = !Loader;
    let promise = new Promise(async (resolve, reject) => {
      if (email == undefined || email == null || email == 'null') {
        reject(false);
      }
      await this.dataService.getUserProfile(email, session_id).toPromise().then(
        async (res: any) => {
          // TODO remove this for admin
          // console.log("response",res);
          this.loader.hideLoader();
          this.profile = res;
          //this.profile.user_status = "confirmed_not_subscribed";
          this.profile.email = email;
          this.profile.session_id = session_id;

          // this.profile.localization_enabled = false;
          //  console.log('profile',this.profile);
          this.configManager.bDisplayLocales = this.profile.localization_enabled;

          // await this.getUserVideosCount(email,session_id).then(countRes=>{

          // let videosCount = countRes as number;

          let oName: any = []
          oName = email.split('@')[0];
          this.loggedUser = new user();
          this.loggedUser.email = email;
          this.loggedUser.name = oName,
            this.loggedUser.videoCount = 0;
          this.loggedUser.session_id = res.session_id;
          this.oSessionService.cSessionService_SetLoggedUser(this.loggedUser);
          // })
          this.intercomService.cIntercomService_InitializeIntercomForLoggedInUser(res);

          resolve(res);
        },
        (err: any) => {
          this.loader.hideLoader();
          reject(err);
        }
      )
    });
    return promise;
  }

  updateUserProfile(profile: profile): Promise<unknown> {

    profile.email = this.loggedUser.email;
    profile.session_id = this.loggedUser.session_id;
    profile.is_onboarding = false;
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService.updateUserProfile(profile).toPromise().then(
        (res: any) => {
          this.loader.hideLoader();
          // this.profile = res;

          resolve(res);
        },
        (err: any) => {
          this.loader.hideLoader();
          reject(err);
        }
      )
    });
    return promise;
  }


  verifyEmail(email: string, code: string): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService.verifyEmail(email, code).toPromise().then(
        (res: any) => {
          this.loader.hideLoader();

          resolve(res);
        },
        (err: any) => {
          this.loader.hideLoader();
          reject(err);
        }
      )
    });
    return promise;
  }

  getUserVideosCount(email: string, sessionId: string) {
    // this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataService.getUserVideoCount(email, sessionId).then(countRes => {
        console.log("count", countRes);
        //  this.loader.hideLoader();
        let videosCount = countRes['draft_video_count'] + countRes['published_video_count'];
        if (this.loggedUser)
          this.loggedUser.videoCount = videosCount;
        resolve(videosCount);
      }).catch(err => {

        //  this.loader.hideLoader();
        reject(err);
      });

    })
    return promise;
  }

  selectPlan(email, plan) {
    let promise = new Promise((resolve, reject) => {
      this.dataService.seletPayment(email, plan).toPromise().then(redirectUrl => {

        if (redirectUrl['redirect_url'] != '') {
          resolve(redirectUrl['redirect_url']);
        } else {
          reject('');
        }
      }).catch(err => {
        reject(err);
      });

    });
    return promise;
  }

}


