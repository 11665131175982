<div class="video-header row m-0 ms-3 px-4 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
          fill="#404243" />
      </svg>
    </a>
    <span>{{this.brandDetail.name? this.brandDetail.name: "Brand Name"}}</span>

  </p>
</div>
<div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
  <div class="row filter-post">
    <div class="col-md-12 p-0">
      <ul class="breadcrumb-tabs nav m-0">
        <li class="nav-item ps-0">
          <a href="javascript:void(0)" class="nav-link " (click)="loadBrandAccount()">Account</a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandBusiness()" class="nav-link">Business Details</a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandMediaLibraryList()" class="nav-link active">Media Libraries</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="row video-header mx-0">
  <ul class="container p-0 mb-1 d-flex justify-content-between align-items-center">
    <li class="col-md-4 mb-2 pe-3">
      <div class="formItm search-icon searchDropdown">
        <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search brand"
          required="" (keyup.enter)="searchBrand()" />
        <label for="search-feild" class="text-sm" style="cursor: default">Search Media Library</label>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="searchBrand()"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
            fill="#A39FAA"></path>
        </svg>
      </div>
      <div class="searchtem" *ngIf="searchTerm != ''" (click)="clearSearchTerm()">
        <div>{{ searchTerm }}</div>
        <mat-icon class="link">close</mat-icon>
      </div>
    </li>
    <li class="col-md-2 mb-2 ps-2" id="selectpreff">
      <div class="formItm down_arrow me-1 mt-4 search-sort-panel">
        <div class="dropdown dropdownBtn">
          <button class="dropdown-toggle text-md" type="button" (click)="openSearchSortTypeDD()"
            (blur)="hideSearchSortTypeDD($event)">
            <span class="dropdownBtnText"><strong>Sort by </strong>
              {{ searchBy == "created" ? "Created" : "" }}
              {{ searchBy == "_id" ? "Email" : "" }}
              {{ searchBy == "name" ? "Name" : "" }}
            </span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdownMenu" [ngClass]="{ showDropDown: showSortByDD }">
            <li>
              <a class="dropdown-item text-md" href="javascript:void(0)" (click)="sortBySearchResult('name')">By Name
              </a>
            </li>
            <li>
              <a class="dropdown-item text-md" (click)="sortBySearchResult('created')">Last Created
              </a>
            </li>
          </ul>
        </div>
        <div class="sort-arrows pos-right">
          <!-- <mat-icon *ngIf="sortType == -1" (click)="sortTypeSearch(1)"
              >download</mat-icon
            >
            <mat-icon *ngIf="sortType == 1" (click)="sortTypeSearch(-1)"
              >upload</mat-icon> -->

          <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sortType == -1"
            (click)="sortTypeSearch(1)">
          <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sortType == 1"
            (click)="sortTypeSearch(-1)">
        </div>
        <!-- <select>
                        <option selected>Last Modified</option>
                    </select> -->
      </div>
    </li>

    <li class="col-md-4 text-end me-4">
      <button type="button" class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-0 float-end mt-1 text-xs"
        data-sidebar="#addNewBrand" (click)="openAddMediaLibraryBrand()">
        Add a new library
      </button>
      <div class="float-end dropdown dropdownBtn btn-dropDown d-inline-block ms-4 divader-left"></div>

      <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
        <li>
          <a class="dropdown-item text-md" href="javascript:void(0);">Bulk Action</a>
        </li>
      </ul>

    </li>
  </ul>
</div>
<div class="flex-grow-1 overflow-auto">
  <div class="my-3">
    <p>
      <span class="text-lg">Total Media Libraries: </span>
      <b class="weight-600"> {{this.brandMediaLibList?.length? this.brandMediaLibList?.length : '0'}}</b>

    </p>
  </div>
  <div class="overflow-auto overflow-table brandmedlist">
    <table class="table tableCustom table-hover mb-0">
      <thead>
        <tr>
          <th scope="col" class="tableCheck">
            <div class="custom-switch">
              <input type="checkbox" class="check form-check-input" id="checkAll" />
            </div>
          </th>
          <th scope="col" class="uname-width">Library Name</th>
          <th scope="col">Users</th>
          <th scope="col">Brands</th>
          <th scope="col">Assets</th>
          <th scope="col">Status</th>
          <th scope="col" class="tableAction">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let media of this.brandMediaLibList">
          <td scope="row" class="tableCheck">
            <div class="custom-switch">
              <input type="checkbox" class="check form-check-input" />
            </div>
          </td>
          <td>

            <a class="b_userName" (click)="loadBrandMediaLib(media)" href="javascript:void(0)">{{media.name}}</a>
          </td>
          <td>{{media.users? media.users: "-"}}</td>
          <td>{{media.brands? media.brands: "-"}}</td>
          <td>{{media?.assets ? media?.assets : '0'}}</td>
          <td>{{media.activation_status? media.activation_status: '-'}}</td>
          <td class="tableAction">
            <a href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown">
              <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                  fill="#7A7A7A"></path>
              </svg>
            </a>
            <ul class="dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">

              <li (click)="isDeleteModalVisible = true; selectedMedia = media;"><a href="javascript:void(0)"
                  class="dropdown-item text-md">Delete</a></li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row" *ngIf="this.brandMediaLibList?.length > 0">
  <div class="col-sm-12 text-end">
    <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="this.oTotalCount" [pageSize]="this.limit"
      [pageSizeOptions]="[10, 20, 25, 50]" (page)="loadPageEvent($event)" showFirstLastButtons></mat-paginator>
  </div>
</div>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isDeleteModalVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=false
  (nzOnCancel)="handleCancel()">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="handleCancel()"></button>

        <h5>Are you sure you want to Delete this Brand's Media Library?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Media Library again.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="handleCancel()">Cancel</a>
        <a href="javascript:void(0)"  (click)="deleteBrandMediaLibrary()" class="mt-3 text-gray-3 text-sm">Delete</a>
      </div>
    </div>
  </div>
</nz-modal>


<!-- Add brand media library (Admin) sidbar start -->
<nz-drawer [nzClosable]="false" [nzVisible]="showAddMediaLibraryBrand" [nzPlacement]="placementMediaLibraryAdmin"
  (nzOnClose)="closeAddMediaLibraryBrand()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Add Media Library</h3>
        <div class="closeRightSideBar" (click)="closeAddMediaLibraryBrand()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
              fill="#F2F2F5" />
            <path
              d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
              fill="#A7ABB6" />
          </svg>
        </div>
      </div>
      <form [formGroup]="addMediaLibraryAdminForm" *ngIf="addMediaLibraryAdminForm">
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
          <div class="formItm mb-0">
            <input formControlName="name" type="text" name="name" required="" />
            <label for="name" class="text-sm">Library Name</label>
            <span *ngIf="
                  addMediaLibraryAdminForm?.controls['name']?.hasError(
                    'required'
                  ) &&
                  (addMediaLibraryAdminForm?.controls['name']?.touched ||
                    addMediaFormSubmitted)
                " class="error">
              Please enter Library Name.</span>
          </div>
          <div class="formItm mb-3">
            <div class="d-flex">
              <label class="btnSwitch">
                <input class="px-2" formControlName="status" type="checkbox" formControlName="activation_status" />
                <span class="btnSwitchCheck"></span>
                Active
              </label>
            </div>
          </div>
          <!-- <div class="formItm down_arrow">
              <p class="text-md text-gray-3 mt-4 mb-0">Asset Source</p>
              <div class="dropdown dropdownSelect">
                <button
                  class="dropdown-toggle text-md"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ addMediaLibraryAdminForm?.controls["asset_source"].value }}
                </button>
                <ul class="dropdown-menu dropdownMenu">
                  <li class="selected">
                    <a
                      class="dropdown-item text-md"
                      (click)="setAssetSource('Manual Upload')"
                      >Manual Upload</a
                    >
                  </li>
                </ul>
              </div>
               <select class="form-select" aria-label="Default select example"
    (change) = "getAssetSource($any($event.target).value)">
    <option value="default" >select option</option>
      <option value="Manual Upload" >Manual Upload</option>

    </select>
            </div> -->

          <!-- <div class="formItm pt-0 mb-3">
              <a
                href="javascript:void(0)"
                class="text-sm mt-3 d-block text-primary-4 d-inline-block"
              >
                Configure Access
              </a>
            </div> -->

          <div class="mt-4 pt-5">
            <p class="text-lg text-black weight-600">
              Library Access and Usage
            </p>

            <div class="form-check ms-1">
              <input class="form-check-input" type="radio" name="access" formControlName="access" value="global_access"
                (click)="changeAccessLimit('global_access')" />
              <label class="form-check-label"> Global Access </label>
            </div>
            <div class="form-check  ms-1">
              <input formControlName="access" value="limited_access" class="form-check-input" type="radio" name="access"
                (click)="changeAccessLimit('limited_access')" />
              <label class="form-check-label"> Limited Access </label>
            </div>
          </div>

          <div class="formItm down_arrow pt-15" *ngIf="showAssignUserDropDown">
            <div class="lib-access">
              <span *ngIf="selectedAddUsers.length == 0 && addMediaFormSubmitted" class="error">
                Please select atleast one user.</span>
              <p class="weight-600 my-4">Assign Users</p>
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3">
                  Search Users
                  <!-- <button
                  type="button"
                  class="btn-close float-end"
                  aria-label="Close"
                ></button> -->
                  <mat-form-field>
                    <input formControlName="searchUserText" #mediaLibraryListAutoComplete (ngModelChange)="
                          this.modelUserSearchChanged.next($event)
                        " matInput aria-label="State" [matAutocomplete]="autoCompleteUser" />
                    <mat-autocomplete #autoCompleteUser="matAutocomplete">
                      <mat-option *ngIf="isUserSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isUserSearchLoading">
                        <mat-option *ngFor="let user of userList.result" [value]="user?._id"
                          (click)="setAddUsers(user)">
                          <span><b>{{ user._id }}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                </p>
                <ul class="p-0">
                  <div class="part1">
                    <li class="d-block text-md" *ngFor="
                          let selecteduser of selectedAddUsers
                            | slice: 0:userListLoopLimit
                        ">
                      {{ selecteduser._id }}
                      <button type="button" (click)="removeAddUsers(selecteduser)" class="btn-close float-end"
                        aria-label="Close"></button>
                    </li>
                  </div>
                  <a href="javascript:void(0)" *ngIf="
                        selectedAddUsers.length > 3 && userListLoopLimit == 3
                      " (click)="viewAllSelectedUsers(2000)" class="vall text-md">View All</a>
                  <a href="javascript:void(0)" *ngIf="
                        selectedAddUsers.length > 3 && userListLoopLimit > 3
                      " (click)="viewAllSelectedUsers(3)" class="vall text-md">Show Less</a>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div class="side-footer side-content d-flex align-items-center ps-0">
          <button (click)="addNewMediaLibraryBrand()" class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
            <span class="py-1 d-block">Add Media Library</span>
          </button>

        </div>
      </form>
    </nav>
  </ng-container>
</nz-drawer>
<!-- Add brand media library (Admin) sidbar end -->