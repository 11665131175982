
<header class="header header-1 drop-shadow">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <a routerLink="/login">
                <img src="../../../assets/images/logo-mobile.svg" alt="logo-mobile" width="120"/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation"> <span class="text-md">Menu</span></button>
            <div class="collapse navbar-collapse" id="mainNav">
                <div class="d-flex justify-content-end align-items-center w-100">
                    <ul class="navbar-nav mb-2 mb-lg-0 pt-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link active text-sm text-gray-2" href="javascript:void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg class="me-1" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="6" transform="matrix(-1 0 0 1 32 0)" fill="#3E3670"/>
                                    <path d="M14.9062 11.228V18.088C14.9062 19.04 14.6309 19.782 14.0802 20.314C13.5295 20.8367 12.7922 21.098 11.8682 21.098C10.9349 21.098 10.1789 20.8273 9.60019 20.286C9.03085 19.7447 8.74619 18.9887 8.74619 18.018H10.7062C10.7155 18.438 10.8135 18.7647 11.0002 18.998C11.1962 19.2313 11.4762 19.348 11.8402 19.348C12.1949 19.348 12.4655 19.236 12.6522 19.012C12.8389 18.788 12.9322 18.48 12.9322 18.088V11.228H14.9062ZM20.5666 21.098C19.8852 21.098 19.2692 20.9813 18.7186 20.748C18.1772 20.5147 17.7479 20.1787 17.4306 19.74C17.1132 19.3013 16.9499 18.7833 16.9406 18.186H19.0406C19.0686 18.5873 19.2086 18.9047 19.4606 19.138C19.7219 19.3713 20.0766 19.488 20.5246 19.488C20.9819 19.488 21.3412 19.3807 21.6026 19.166C21.8639 18.942 21.9946 18.6527 21.9946 18.298C21.9946 18.0087 21.9059 17.7707 21.7286 17.584C21.5512 17.3973 21.3272 17.2527 21.0566 17.15C20.7952 17.038 20.4312 16.9167 19.9646 16.786C19.3299 16.5993 18.8119 16.4173 18.4106 16.24C18.0186 16.0533 17.6779 15.778 17.3886 15.414C17.1086 15.0407 16.9686 14.546 16.9686 13.93C16.9686 13.3513 17.1132 12.8473 17.4026 12.418C17.6919 11.9887 18.0979 11.662 18.6206 11.438C19.1432 11.2047 19.7406 11.088 20.4126 11.088C21.4206 11.088 22.2372 11.3353 22.8626 11.83C23.4972 12.3153 23.8472 12.9967 23.9126 13.874H21.7566C21.7379 13.538 21.5932 13.2627 21.3226 13.048C21.0612 12.824 20.7112 12.712 20.2726 12.712C19.8899 12.712 19.5819 12.81 19.3486 13.006C19.1246 13.202 19.0126 13.4867 19.0126 13.86C19.0126 14.1213 19.0966 14.3407 19.2646 14.518C19.4419 14.686 19.6566 14.826 19.9086 14.938C20.1699 15.0407 20.5339 15.162 21.0006 15.302C21.6352 15.4887 22.1532 15.6753 22.5546 15.862C22.9559 16.0487 23.3012 16.3287 23.5906 16.702C23.8799 17.0753 24.0246 17.5653 24.0246 18.172C24.0246 18.6947 23.8892 19.18 23.6186 19.628C23.3479 20.076 22.9512 20.4353 22.4286 20.706C21.9059 20.9673 21.2852 21.098 20.5666 21.098Z" fill="#F2F2F5"/>
                                </svg>
                                My Account
                                <svg class="ms-1" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.509553 0.0874255C0.392986 -0.0291418 0.203993 -0.0291418 0.0874254 0.0874255C-0.0291418 0.203993 -0.0291418 0.392986 0.0874254 0.509553L3.1346 3.55672C3.61255 4.03467 4.38745 4.03467 4.8654 3.55672L7.91257 0.509553C8.02914 0.392986 8.02914 0.203993 7.91257 0.0874254C7.79601 -0.029142 7.60701 -0.0291416 7.49045 0.0874256L4.44328 3.1346C4.19846 3.37941 3.80154 3.37941 3.55672 3.1346L0.509553 0.0874255Z" fill="#7A7A7A"/>
                                </svg>
                            </a>
                            <ul class="dropdown-menu custom-dropdown d-none" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" routerLink="/my-account">Account Settings</a></li>
                              <li><a class="dropdown-item" routerLink="/business-profile/brand-identity">Business Profile</a></li>
                              <li><a class="dropdown-item" routerLink="/login">Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>
<section class="main-body line-bg bg-gray-7 h-100">
    <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
            <div class="col-sm-12 col-lg-10 ">
                <div class="row justify-content-center mb-1">
                    <div class="col-sm-12 col-md-12 col-lg-8 text-center">
                        <div class="text-center mb-2">
                            <span class="status_icon wow pulse" style="visibility: visible; animation-name: pulse;"> <img src="../assets/images/onBoarding/we_are_sorry.svg" alt="we_are_sorry" class="we_are_sorry" /></span>
                        </div>
                        <h2 class="heading-h1 text-gray-2 pb-2 mt-4" style="visibility: visible; animation-name: fadeInUp;">We're not there yet, {{sUsername}} </h2>
                        <p class="text-lg text-gray-3 mt-1 mb-4">We don't offer video for your industry yet, but we'll notity you as soon as we do.
                            Get email updates to stay in the loop.</p>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="bDisplayErrorMsg" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show"
                        role="alert"> 
                             <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                            fill="#842029" />
                            </svg>
                                <div>
                                    {{sErrorMessage}}
                                         <button type="button" class="btn-close" data-bs-dismiss="alert"
                                              aria-label="Close">
                                                <svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                                fill="#842029" />
                                                </svg>
                                         </button>
                                </div>
                        </div>
                    <div class="col-sm-12 text-center">
                        <a routerLink="/create-account/select-plan" class="btn btn-secondary btn-round-6" (click)="SorryComponent_GetEmailUpdates()">OK, Continue</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>