<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <div class="container">
        <ul class="breadcrumb py-3 mb-0">
            <li>Business Profile</li>
            <li><a href="javascript:void(0)">Media</a></li>
        </ul>
        <div class="br12 bg-white page-shadow curve-bg h-80vh">
            <p class="py-4 px-4 account-heading">
                <strong class="weight-700 text-gray-2 ml-2">Business Profile</strong><br>
                <span class="mx-0 my-0 text-sm">A complete profile will help us to understand your business and provide you with the best videos.</span>
            </p>
            <div class="accountSecInner">
                <app-business-sidebar></app-business-sidebar>
                <div class="accountSecContent py-5">
                    <div class="row justify-content-between">
                        <div class="col-md-8">
                            <h4 class="text-lg text-gray-2 mb-2">Social Channels</h4>
                            <p class="text-sm mb-3">Connect the social channels for your business here to share your videos instantly.</p>
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="alert alert-warning row">
                                        <div class="col-1 text-center"><img src="../assets/images/onBoarding/Google.svg" alt="Google" class="connect-facebook"></div>
                                        <div class="col-7 text-gray-3 text-xs">
                                            <h3 class="text-sm text-gray-1 mb-1">Google</h3>
                                            <p>We need you to connect your YouTube account to Prontopia  to enable us to publish the video directly to your YouTube channel.</p>
                                        </div>
                                        <div class="col-4 text-center align-self-center">
                                            <a *ngIf="connected_social_platforms && !connected_social_platforms.google" href="{{sGoogleSigninLink}}" class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white">Connect with Google</a>
                                            <a *ngIf="connected_social_platforms && connected_social_platforms.google" href="javascript:void(0)" class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1" (click)="openModal('google')">Disconnect</a>
                                        </div>
                                    </div>
                                    <div class="alert bg-light row">
                                        <div class="col-1 text-center"><img src="../assets/images/accountSetting/Facebook.svg" alt="connect-facebook" class="connect-facebook"></div>
                                        <div class="col-7 text-gray-3 text-xs align-self-center">
                                            <h3 class="text-sm text-gray-1 mb-1">Facebook</h3>
                                            Your Facebook account is connected to Prontopia . <a href="javascript:void(0)">[Aaron Ronstadt]</a>
                                        </div>
                                        <div class="col-4 text-center align-self-center">
                                            <a *ngIf="connected_social_platforms && !connected_social_platforms.facebook" href="{{sFacebookSigninLink}}" class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white">Connect with Facebook</a>
                                            <a *ngIf="connected_social_platforms && connected_social_platforms.facebook" href="javascript:void(0)" class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1" (click)="openModal('facebook')">Disconnect</a>
                                        </div>
                                    </div>
                                    <div class="alert bg-light row">
                                        <div class="col-1 text-center"><img src="../assets/images/onBoarding/Linkedin.svg" alt="Linkedin" class="connect-facebook"></div>
                                        <div class="col-7 text-gray-3 text-xs align-self-center">
                                        <h3 class="text-sm text-gray-1 align-self-center">LinkedIn</h3>
                                            Your LinkedIn account is connected to Prontopia . <a href="javascript:void(0)">[Aaron Ronstadt]</a>
                                        </div>
                                        <div class="col-4 text-center align-self-center">
                                            <a *ngIf="connected_social_platforms && !connected_social_platforms.linkedin" href="{{sLinkedInSigninLink}}" class="btn btn-danger weight-600 text-xs btn-outline-gray-radius text-white">Connect with LinkedIn</a>
                                            <a *ngIf="connected_social_platforms && connected_social_platforms.linkedin" href="javascript:void(0)" class="btn btn-white weight-600 text-xs btn-outline-gray-radius text-gray-2 mt-1" (click)="openModal('linkedin')">Disconnect</a>
                                        </div>
                                    </div>
                                    <h3 class="text-sm text-primary mb-2">Connect Other Channels</h3>
                                    <a href="javascript:void(0)" style="width:50px; height:50px; margin:0 15px 10px 0; display: inline-block; border-radius:50%; background-color: rgb(0,0,0,0.1);"></a>
                                    <a href="javascript:void(0)" style="width:50px; height:50px; margin:0 15px 10px 0; display: inline-block; border-radius:50%; background-color: rgb(0,0,0,0.1);"></a>
                                    <a href="javascript:void(0)" style="width:50px; height:50px; margin:0 15px 10px 0; display: inline-block; border-radius:50%; background-color: rgb(0,0,0,0.1);"></a>
                                    <a href="javascript:void(0)" style="width:50px; height:50px; margin:0 15px 10px 0; display: inline-block; border-radius:50%; background-color: rgb(0,0,0,0.1);"></a>
                                    <a href="javascript:void(0)" style="width:50px; height:50px; margin:0 15px 10px 0; display: inline-block; border-radius:50%; background-color: rgb(0,0,0,0.1);"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Disconnect Channel Modal -->
<div class="modal fade" id="disconnect" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Are you sure want to disconnect {{oSocialChannel | uppercase}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-lg">
                <form [formGroup]="DisconnectSocialForm">
                    <input type="text" class="form-Control disconnect-form" readonly formControlName="platform">
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary rounded btn-sm-same-pad" data-bs-dismiss="modal" >Cancel</button>
                <button type="button" class="btn btn-primary rounded btn-sm-same-pad" data-bs-dismiss="modal" (click)="disconnectChannel()">Disconnect</button>
            </div>
        </div>
    </div>
</div>
