import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateVideoManagerService } from '../../Managers/create-video-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedVideoGaurd implements CanActivate {

  constructor(private videoManger:CreateVideoManagerService,public router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!this.videoManger.selectedVideo)
    {
   
      return this.router.parseUrl('/wizard/overview');
    }

    return true;
  }
  
}
