<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Media</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">Keep your business information up to date for the best results
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accountSecInner">
                    <div class="accountSecNav py-5">
                        <app-business-sidebar></app-business-sidebar>
                    </div>
                    <div class="accountSecContent py-5 pe-4 pb-2">
                        <div class="video-header row m-0 px-2">
                            <p class="text-lg text-gray-2 pb-1 px-0 col-lg-8 mb-0">
                                <a routerLink="/business-profile/brands" class="me-2">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
                                            fill="#404243" />
                                    </svg>
                                </a>
                                Lakme Fashion
                            </p>
                        </div>
                        <div class="container mx-0 px-0 business-media" id="container-view">
                            <div class="row filter-post">
                                <div class="col-md-12">
                                    <ul class="breadcrumb-tabs nav mx-2">
                                        <li class="nav-item">
                                            <a class="nav-link "
                                                routerLink="/business-profile/brands/account">Account</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link"
                                                routerLink="/business-profile/brands/business-details">Business
                                                Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link"
                                                routerLink="/business-profile/brands/published-video">Published
                                                Video</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link active"
                                                routerLink="/business-profile/brands/media-library">Media Library</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class=" px-4 py-2 mb-0">
                                <div class="video-header row mx-0 my-2">
                                    <p class="text-lg text-gray-2 col-lg-6 mb-0 px-0 pt-2">
                                        Media Library Access
                                    </p>
                                    <ul class="text-end d-block col-lg-6 mb-1">
                                        <div class="d-flex justify-content-end">
                                            <li class="searchbox-slide m-0 p-0">
                                                <div class="searchbox">
                                                    <input type="search" placeholder="Search your video" name="search"
                                                        class="searchbox-input" onkeyup="buttonUp();" required>
                                                    <input type="submit" class="searchbox-submit" value="GO">
                                                    <span class="searchbox-icon">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                                fill="#A39FAA"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </li>
                                            <li id="selectpreff" class="m-0 p-0">
                                                <div class="formItm down_arrow me-0">
                                                    <div class="dropdown dropdownBtn">
                                                        <button class="dropdown-toggle text-md" type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span class="ms-2 dropdownBtnText">All Libraries</span>
                                                        </button>
                                                        <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                                            <li><a class="dropdown-item text-md" href="#;">Select
                                                                    1</a></li>
                                                            <li><a class="dropdown-item text-md" href="#;">Select
                                                                    2</a></li>
                                                            <li><a class="dropdown-item text-md" href="#;">Select
                                                                    3</a></li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    class="text-sm text-primary-4 my-2 mx-3 d-inline-block">View
                                                    All</a>
                                            </li>
                                        </div>
                                    </ul>

                                </div>
                                <ul class="library-access p-0 m-0">
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox" checked><label
                                                class="text-md">Prontopia</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                4</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                4</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                4</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox" checked><label
                                                class="text-md">Estelle Bates</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                5</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                5</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                5</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-switch custom-slidetoggle">
                                            <input class="form-check-input" type="checkbox"><label class="text-md">User
                                                3</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="row filter-post">
                                <p class="text-lg text-gray-2 px-4 py-2 mb-0">User Media Library</p>
                                <app-media-library-shared></app-media-library-shared>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<div class="overlay sidebarOverlay"></div>