<body class="{{sAddContentSlide}}">
   <app-dashboard-header></app-dashboard-header>
   <main class="main">
       <section class="page accountSec main-body bg-gray-7 h-100">

           <div class="container">
               <ul class="breadcrumb-tabs nav">
                   <li class="nav-item" role="presentation">
                       <a routerLink="/dashboard/overview" class="nav-link">Overview</a>
                   </li>
                   <li class="nav-item" role="presentation">
                       <a routerLink="/manage-comments" class="nav-link active">Manage Comments</a>
                   </li>
               </ul>
               <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
                   <div class="tab-pane fade show active">

                       <div class="content_area">
                           <div class="container" id="container-view">
                               <h5>Manage Comments</h5>
                               <div class="manageComments">
                                   <div class="manageCommentsItm manageCommentsAdd align-items-baseline">
                                       <!-- <div class="manageCommentsItmImg">
                                           <img src="../assets/images/videoTimeline/user1.png" alt="user1" />
                                       </div> -->
                                       <div class="manageCommentsItmText align-items-center">
                                           <div class="formItm mb-0 w-100">
                                               <form [formGroup]="fCommentsForm">
                                                   <input id="thediscussion"formControlName="comment" type="text" value=""
                                                       placeholder="Add your comment" name="textfeild" required
                                                       class="manageCommentsItmInput" required>
                                                   <label for="thediscussion" class="text-sm">Add your comment</label>
                                               </form>
                                           </div>
                                           <div class="manageCommentsItmAction">
                                               <button class="text-sm" [disabled]="fCommentsForm.invalid" (click)="ManageCommentsComponent_AddCommentRequest()">
                                                   Post
                                               </button>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="manageCommentsItm">
                                       <!-- <div class="manageCommentsItmImg">
                                           <img src="../assets/images/videoTimeline/user2.png" alt="user2" />
                                       </div> -->
                                       <div class="manageCommentsItmText" *ngFor="let item of sComments;let i as index">
                                           <div class="flex-grow-1">
                                               <div class="text-xs text-gray-2">
                                                   <!-- <b>Roseli Santana </b> -->
                                                   <!-- <span class="ms-1 text-gray-6">15 mins ago</span> -->
                                               </div>
                                               <p class="text-sm text-gray-2 mb-1" >
                                                  {{item}}
                                               </p>
                                               <!-- <a href="#;" class="text-xs text-gray-6">2 Replies</a> -->
                                           </div>

                                           <div class="manageCommentsItmAction pt-1">
                                               <button (click)="delete(i)">
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                               <!-- <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button> -->
                                           </div>
                                           <!-- <div class="manageCommentsItm">
                                               <div class="manageCommentsItmImg">
                                                   <img src="../assets/images/videoTimeline/user3.png" alt="user3" />
                                               </div>
                                               <div class="manageCommentsItmText">
                                                   <div class="flex-grow-1">
                                                       <div class="text-xs text-gray-2">
                                                           <b>Scarlett Barber </b>
                                                           <span class="ms-1 text-gray-6">10 mins ago</span>
                                                       </div>
                                                       <p class="text-sm text-gray-2 mb-1">
                                                           You live in our heart
                                                       </p>
                                                   </div>
                                                   <div class="manageCommentsItmAction pt-1">
                                                       <button>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                                                               <path fill-rule="evenodd" clip-rule="evenodd"
                                                                   d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                                   fill="#7A7A7A" />
                                                           </svg>
                                                       </button>
                                                       <button>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                                                               <path fill-rule="evenodd" clip-rule="evenodd"
                                                                   d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                                   fill="#7A7A7A" />
                                                           </svg>
                                                       </button>
                                                   </div>
                                               </div>
                                           </div> -->
                                           <!-- <div class="manageCommentsItm">
                                               <div class="manageCommentsItmImg">
                                                   <img src="../assets/images/videoTimeline/user3.png" alt="user3" />
                                               </div>
                                               <div class="manageCommentsItmText">
                                                   <div class="flex-grow-1">
                                                       <div class="text-xs text-gray-2">
                                                           <b>Kris Mills </b>
                                                           <span class="ms-1 text-gray-6">11 mins ago</span>
                                                       </div>
                                                       <p class="text-sm text-gray-2 mb-1">
                                                           Simply dummy text of the printing
                                                       </p>
                                                   </div>
                                                   <div class="manageCommentsItmAction pt-1">
                                                       <button>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                                                               <path fill-rule="evenodd" clip-rule="evenodd"
                                                                   d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                                   fill="#7A7A7A" />
                                                           </svg>
                                                       </button>
                                                       <button>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                                                               <path fill-rule="evenodd" clip-rule="evenodd"
                                                                   d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                                   fill="#7A7A7A" />
                                                           </svg>
                                                       </button>
                                                   </div>
                                               </div>
                                           </div> -->
                                       </div>
                                   </div>
                                   <!-- <div class="manageCommentsItm">
                                       <div class="manageCommentsItmImg">
                                           <img src="../assets/images/videoTimeline/user4.png" alt="user4" />
                                       </div>
                                       <div class="manageCommentsItmText">
                                           <div class="flex-grow-1">
                                               <div class="text-xs text-gray-2">
                                                   <b>Johny Wilsons </b>
                                                   <span class="ms-1 text-gray-6">2 days ago</span>
                                               </div>
                                               <p class="text-sm text-gray-2 mb-1">
                                                   Heavy hearts, like heavy clouds in the sky, are best relieved by the
                                                   letting of a little water.
                                               </p>
                                           </div>
                                           <div class="manageCommentsItmAction pt-1">
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                           </div>
                                       </div>
                                   </div> -->
                                   <!-- <div class="manageCommentsItm">
                                       <div class="manageCommentsItmImg">
                                           <img src="../assets/images/videoTimeline/user5.png" alt="user5" />
                                       </div>
                                       <div class="manageCommentsItmText">
                                           <div class="flex-grow-1">
                                               <div class="text-xs text-gray-2">
                                                   <b>Sara Mirza </b>
                                                   <span class="ms-1 text-gray-6">2 days ago</span>
                                               </div>
                                               <p class="text-sm text-gray-2 mb-1">
                                                   Simply dummy text of the printing
                                               </p>
                                           </div>
                                           <div class="manageCommentsItmAction pt-1">
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                           </div>
                                       </div>
                                   </div> -->
                                   <!-- <div class="manageCommentsItm">
                                       <div class="manageCommentsItmImg">
                                           <img src="../assets/images/videoTimeline/user6.png" alt="user6" />
                                       </div>
                                       <div class="manageCommentsItmText">
                                           <div class="flex-grow-1">
                                               <div class="text-xs text-gray-2">
                                                   <b>Robert Milton </b>
                                                   <span class="ms-1 text-gray-6">2 days ago</span>
                                               </div>
                                               <p class="text-sm text-gray-2 mb-1">
                                                   Thanks for this master piece🔥
                                               </p>
                                           </div>
                                           <div class="manageCommentsItmAction pt-1">
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M7 1C6.44772 1 6 1.44772 6 2H10C10 1.44772 9.55228 1 9 1H7ZM11 2C11 0.895431 10.1046 0 9 0H7C5.89543 0 5 0.895431 5 2H2H1H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H1V11C1 13.7614 3.23858 16 6 16H10C12.7614 16 15 13.7614 15 11V3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H15H14H11ZM2 11V3H6H10H14V11C14 13.2091 12.2091 15 10 15H6C3.79086 15 2 13.2091 2 11ZM6 11.5V5.5C6 5.22386 5.77614 5 5.5 5C5.22386 5 5 5.22386 5 5.5V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5ZM11 5.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V5.5C10 5.22386 10.2239 5 10.5 5C10.7761 5 11 5.22386 11 5.5Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                               <button>
                                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
                                                       <path fill-rule="evenodd" clip-rule="evenodd"
                                                           d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744ZM9.42875 11.0735H5.76735C5.46223 11.0735 5.20921 10.8205 5.20921 10.5153C5.20921 10.2102 5.46223 9.9572 5.76735 9.9572H9.42875C10.3813 9.9572 11.1627 9.18325 11.1627 8.22325C11.1627 7.26325 10.3887 6.4893 9.42875 6.4893H5.64078L5.84178 6.6903C6.05759 6.90611 6.05759 7.26332 5.84178 7.47914C5.73759 7.58332 5.5962 7.64285 5.44736 7.64285C5.30596 7.64285 5.16457 7.59077 5.05294 7.47914L3.88457 6.31077C3.66876 6.09495 3.66876 5.73774 3.88457 5.52192L5.05294 4.35355C5.26876 4.13774 5.62596 4.13774 5.84178 4.35355C6.05759 4.56937 6.05759 4.92658 5.84178 5.14239L5.61115 5.37302H9.42875C10.999 5.37302 12.279 6.65302 12.279 8.22325C12.279 9.79349 10.999 11.0735 9.42875 11.0735Z"
                                                           fill="#7A7A7A" />
                                                   </svg>
                                               </button>
                                           </div>
                                       </div>
                                   </div> -->
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="wtermarklogo">
                       <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                   </div>
               </div>
           </div>
       </section>
       <app-dashboard-sidepanels></app-dashboard-sidepanels>
   </main>

   <div class="overlay"></div>
   <script src="../assets/js/jquery-3.6.0.min.js"></script>
   <script src="../assets/js/popper.min.js"></script>
   <script src="../assets/js/bootstrap.min.js"></script>
   <script src="../assets/js/wow.min.js"></script>
   <script src="../assets/js/jquery.validate.min.js"></script>
   <script src="../assets/js/jquery.nicescroll.min.js"></script>
   <script src="../assets/js/custom.js"></script>

   <script
       src="https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.concat.min.js"></script>

   <script type="text/javascript">
       $(document).ready(function () {

           $(".manageCommentsItmInput").on("keyup", function () {
               var textVal = $(this).val();
               textVal = textVal.replace(/\s/g, '');
               if (textVal !== "") {
                   // alert("hello");
                   $(this).parent().siblings(".manageCommentsItmAction").children("button").attr("disabled", false);
               }
               else {
                   $(this).parent().siblings(".manageCommentsItmAction").children("button").attr("disabled", true);
               }
           });

           $('.enable-loc').change(function () {
               if (this.checked)
                   $('.enable-loc-div').fadeIn('slow');
               else
                   $('.enable-loc-div').fadeOut('slow');
           });

           $("#sidebar-edit").mCustomScrollbar({
               theme: "minimal"
           });



    
           var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
           var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
               return new bootstrap.Tooltip(tooltipTriggerEl)
           })
       });

   </script>

</body>