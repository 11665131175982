import { Component, OnDestroy, OnInit } from '@angular/core';
import { cDataService } from './../../../services/data.service';
import { Router } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';

@Component({
  selector: 'app-scheduled-videos',
  templateUrl: './scheduled-videos.component.html',
  styleUrls: ['./scheduled-videos.component.scss']
})
export class ScheduledVideosComponent implements OnInit, OnDestroy {
  nScheduledVideosCount : number = 0;
  bLoading : boolean = true;
  oSetTimeOut: any;
  sVideoToSearch : string="";
  sSelectedSortingCategory  : string = "Last Modified";
  lSortBy : any = [{name  : 'Last Modified', value : 'last_modified'},{name  : 'Last Created', value : 'last_created'}];

  constructor(private oSessionService : cSessionService, private oRouter : Router, private oDataService : cDataService,private videoManger:CreateVideoManagerService) { }

  ngOnInit() 
  {
    this.ScheduledVideosComponent_SetTimeOut();
  }
  ngOnDestroy()
  {
    this.oDataService.sSearchVideoByTitle.next({title : "",type : ""});
  }
  ScheduledvideosComponent_SortVideos(oIncommingSortingFilter : any)
  {
    this.sSelectedSortingCategory  = oIncommingSortingFilter.name;
  }
  ScheduledVideosComponent_SetTimeOut()
  {
    this.oSetTimeOut=setTimeout(() => {this.bLoading=false;}, 10000);
  }
  ScheduledVideosComponent_NavigateToCreateVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigate(['wizard/overview']);
  }
  createNewVideo(){
   
    this.videoManger.newVideo();
    this.oRouter.navigate(['wizard/overview']);
  }
  SchuledVideosComponent_GetVideosCount(nIcommingVideosCount : any )
  {
    this.nScheduledVideosCount = nIcommingVideosCount;
    clearTimeout(this.oSetTimeOut);
    this.bLoading = false;
  }
  ScheduledVideosComponent_SearchVideo(sIncommingVideoTitle : string)
  {
    if(this.sVideoToSearch!==sIncommingVideoTitle)
    {
      this.sVideoToSearch = sIncommingVideoTitle;
      this.bLoading=true;
      this.oDataService.sSearchVideoByTitle.next({title : sIncommingVideoTitle,type : "scheduled"});
      this.ScheduledVideosComponent_SetTimeOut();
      console.log("Video Title : ==> ", sIncommingVideoTitle);
    }
  }

}
