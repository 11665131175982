import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { ActivatedRoute, Router } from "@angular/router";
import { UserProfile } from "src/app/models/admin";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { Userservice } from "../service/user-service";
import { AdminService } from "src/app/services/dataServices/admin.service";

@Component({
  selector: "app-user-account-media-libraries",
  templateUrl: "./user-account-media-libraries.component.html",
  styleUrls: ["./user-account-media-libraries.component.scss"],
})
export class UserAccountMediaLibrariesComponent implements OnInit {
  showMediaLibraryAccessDrawer = true;
  placementUser: NzDrawerPlacement = "right";
  userAccountId : string = "";
  profileData : UserProfile;
  showSharedLibraryComponent = false;
  userDetail: any;
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private userService : Userservice,
    private mediaManager: MediaManagerService,
    private adminService : AdminService,
  ) {
    this.showSharedLibraryComponent = false;
  }

  ngOnInit(): void {
    this.userService.clearUserAllowedMediaLists();
    if( this.route.snapshot && this.route.snapshot.params["id"]) {
      this.userAccountId = this.route.snapshot.params["id"];
      this.userService.getUserProfile(this.userAccountId).then((profile : UserProfile) => {
        this.userDetail = profile.media_libs[0];
        this.userService.selectedMediaId = (profile?.media_libs && profile?.media_libs.length > 0) ? [profile?.media_libs[0]] : [];

        this.mediaManager.selectedmedialib = profile?.media_lib;
        this.userService.selectedUserMediaEmail = profile?._id;
        this.profileData = profile;
        this.userService.setUserHeaderBreadCrumps(profile);
        this.userService.selectedUser = profile;
        // this.showSharedLibraryComponent = true;
      });
      this.adminService.getUserMediaLibraries(this.userAccountId, "").then((response : any) => {
         let mediaLibrariesList = response;
         this.showSharedLibraryComponent = true;
        this.userService.userAllowedMediaLists = response.result;
      }).catch((error) => {
        this.showSharedLibraryComponent = true;
      });
    }
  }

  loadUserProfile(){
    this.router.navigate(['/account-setting/user-account', this.userAccountId]);
  }

  backward() {
    this.location.back();
  }
}
