
    <section class="loginSec">
        <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
        <div class="loginSecItm loginSecRight pb-3">
            <div class="loginSecItmTop loginSecItmTop-right">
                <div class="text-end text-xs text-gray-3">
                    Already a creator? <a routerLink="/login" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btnShadow">Sign In</a>
                </div>
            </div>
            <div class="loginSecItmContent p-0 pt-2" style="overflow-y: auto;">
                <div class="d-lg-none pb-5">
                    <a routerLink="/login">
                        <img src="../../../assets/images/logo-mobile.svg" alt="logo-mobile" width="120"/>
                    </a>
                </div>
                <h2 class="text-gray-1">Create an Account</h2>
                <div class="loginSecItmForm" aria-labelledby="loginYourself">
                    <form [formGroup]="fCreateAccountform">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="formItm">
                                    <input id="companyName" type="text" required
                                        placeholder="John Smith"  formControlName="name">
                                    <label for="companyName" class="text-sm">Name</label>
                                    <span *ngIf="fCreateAccountform.controls['name'].hasError('required') && fCreateAccountform.controls['name'].touched" class="error">Please enter your name.</span>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="formItm">
                                    <input id="loginEmail" type="email" value="" required
                                        placeholder="Email Address" name="email" formControlName="email">
                                    <label for="loginEmail" class="text-sm">Email Address</label>
                                    <span *ngIf="fCreateAccountform.controls['email'].hasError('required') && fCreateAccountform.controls['email'].touched" class="error">Please enter your email address.</span>
                                    <span *ngIf="fCreateAccountform.controls['email'].hasError('pattern') && fCreateAccountform.controls['email'].touched" class="error">Please enter a valid email address.</span>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="formItm formItmPasswordView mb-4">
                                    <button type="button">
                                        <svg *ngIf="!bDisplayPassword" (click)="bDisplayPassword = !bDisplayPassword" class="showpass" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z" fill="#BABCBE"/>
                                            </svg>
                                            
                                        <svg *ngIf="bDisplayPassword" (click)="bDisplayPassword = !bDisplayPassword" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z" fill="#BABCBE"/>
                                            <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE"/>
                                            </svg>
                                            
                                    </button>
                                    <input id="inputPassword" [type]="bDisplayPassword?'text':'password'" required placeholder="Password" formControlName="password" >
                                    <label for="inputPassword" class="text-sm">Password</label>
                                   <div>
                                       <div>
                                    <span style="bottom:-30px!important;" *ngIf="fCreateAccountform.controls['password'].hasError('required')  && fCreateAccountform.controls['password'].touched " class="error">Please provide a password.</span>
                                </div>
                                <!-- <div>
                                    <span *ngIf="fCreateAccountform.controls['password'].hasError('minlength')  && fCreateAccountform.controls['password'].touched" class="error" id="letr">Your password must be at least 8 characters long.</span>
                                </div> -->
                            <div> 
                                    <span style="bottom:-40px!important;" *ngIf="fCreateAccountform.controls['password'].hasError('pattern') && fCreateAccountform.controls['password'].dirty " class="error" >Passwords must be at least 8 characters long and contain at least one each of uppercase and lowercase alphabets, one numeral and one special character</span>
                                </div>  
                                </div>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="formItm formItmPasswordView mb-4">
                                    <button type="button">
                                        <svg *ngIf="!bDisplayConfirmPassword" (click)="bDisplayConfirmPassword = !bDisplayConfirmPassword" class="showpass" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z" fill="#BABCBE"/>
                                            </svg>
                                            
                                        <svg *ngIf="bDisplayConfirmPassword" (click)="bDisplayConfirmPassword = !bDisplayConfirmPassword" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z" fill="#BABCBE"/>
                                            <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE"/>
                                            </svg>
                                            
                                    </button>
                                    <input id="inputConfirmPassword" [type]="bDisplayConfirmPassword?'text':'password'" required placeholder="Confirm Password" formControlName="confirmPassword" >
                                    <label for="inputConfirmPassword" class="text-sm">Confirm Password</label>
                                   <div>
                                       <div>
                                    <span *ngIf="fCreateAccountform.controls['confirmPassword'].hasError('required') && fCreateAccountform.controls['confirmPassword'].touched" class="error">Please provide a password.</span>
                                </div>
                                
                            <div> 
                                    <span *ngIf="fCreateAccountform.hasError('notSame') && fCreateAccountform.controls['confirmPassword'].dirty && !fCreateAccountform.controls['confirmPassword'].hasError('required')" class="error" >Passwords do not match<br></span>
                                </div>  
                                </div>
                                </div>
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-md-6">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="mb-3">
                                            <button class="btn btn-secondary btn-block btn-round-6" [disabled]="!fCreateAccountform.valid" type="submit" (click)="singup()">Get Started</button>
                                           
                                        </div>
                                    </div>
                              
                                </div>
                                <div *ngIf="bDisplayErrorMsg" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show"
                                    role="alert">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                            fill="#842029" />
                                    </svg>

                                    <div>
                                        You already have an account. <a class="sign-in-link" href="javascript:vod(0)" routerLink="/login">Sign in</a>
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                    fill="#FF8369" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5">
                            <p class="text-gray-2 text-xs"> Or sign in with a social account</p>
                            <ul class="socialLoginIcons">
                                
                                <li>
                                    <a href="{{sFacebookSigninLink}}" class="btn-round btn btn-outline-gray">
                                        <img src="../assets/images/onBoarding/facebook.svg" alt="facebook" class="facebook-svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="{{sGoogleSigninLink}}" class="btn-round btn btn-outline-gray">
                                        <img src="../assets/images/onBoarding/Google.svg" alt="Google" class="google-svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="{{sLinkedInSigninLink}}" class="btn-round btn btn-outline-gray">
                                        <img src="../assets/images/onBoarding/Linkedin.svg" alt="Linkedin" class="linkedin-svg" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                  
                    </form>
                </div>
            </div>
            <div class="loginSecItmBottom d-flex align-items-center text-md-end ">
                <div class="privacy-terms-links text-start text-lg-end text-xs text-md-end ps-md-0 ps-md-0">
                    <img src="../../../assets/images/onBoarding/help-icon.svg" alt="help-icon"/>
                    <p><a class="text-gray-2" id="Redirect_to_Intercom" href="javascript:void(0)">Need Support?</a><br>
                    We're here to help.</p>
                  </div>
                
            </div>
        </div>
    </section>
