import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';


@Component({
  selector: 'app-draft-videos',
  templateUrl: './draft-videos.component.html',
  styleUrls: ['./draft-videos.component.scss']
})
export class DraftVideosComponent implements OnInit, OnDestroy {
  bDraftLoader : boolean = true;
  oSetTimeOut: any;
  sVideoToSearch : string="";
  sSessionId:string;
  sUserEmail:string;
  public title:string="";
  constructor(private oSessionService : cSessionService,public videoManager:VideoManagerService,private oRouter : Router, private oDataService : cDataService,public videoManger:CreateVideoManagerService) {}
  ngOnDestroy()
  {
    this.oDataService.sSearchVideoByTitle.next({title : "",type : ""});
  }
  ngOnInit() 
  {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
  
  }

  SearchVideo()
  {
    this.videoManager.search.emit(this.title);
  }

  
  createNewVideo(){
   
      this.videoManger.newVideo();
    this.oRouter.navigate(['wizard/overview']);

  }
   
  
}
