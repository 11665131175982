import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-brand-published-videos',
  templateUrl: './manage-brand-published-videos.component.html',
  styleUrls: ['./manage-brand-published-videos.component.scss']
})
export class ManageBrandPublishedVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    


$("[data-sidebar]").click(function() {
  var sideBarId = $(this).attr("data-sidebar");
  $(".rightSideBar").removeClass("active");
  $(".sidebarOverlay").addClass("active");
  $(sideBarId).addClass("active");
});


$(".sidebarOverlay, .closeRightSideBar , .closeSideBar").click(function() {
  $(".rightSideBar").removeClass("active");
  $(".sidebarOverlay").removeClass("active");
  $("body").removeClass("sidbar_contentslide");
});

$("[data-sidebar2]").click(function() {
  var sideBarId = $(this).attr("data-sidebar2");
  $(".rightSideBarTutorialsVideo").removeClass("active");
  $(".sidebarOverlay2").addClass("active");
  $(sideBarId).addClass("active");
});


$(".sidebarOverlay2, .closeRightSideBar2 , .closeSideBar").click(function() {
  $(".rightSideBarTutorialsVideo").removeClass("active");
  $(".sidebarOverlay2").removeClass("active");
  $("body").removeClass("sidbar_contentslide");
});

// data-custom-select
$("[data-custom-select]").click(function() {
  $("body").addClass("sidbar_contentslide");
});
// end data-custom-select

  `;
    document.body.appendChild(chatScript);
  }
}
