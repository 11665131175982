import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, Router } from '@angular/router';
import {  Subscription } from 'rxjs';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { profile } from './../../models/onboarding';
import { cSessionService } from './../../services/session.service';
 
import { CreateVideoManagerService } from  './../../Managers/create-video-manager.service';
 
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
@Component({
  selector: 'app-create-video-wizard',
  templateUrl: './create-video-wizard.component.html',
  styleUrls: ['./create-video-wizard.component.scss']
})
export class CreateVideoWizardComponent implements OnInit,OnDestroy {
  showNext:boolean=false;
  showPrevious:boolean=false;
  sUserEmail : any;
  sSessionId : any;
  showCancel:boolean=false;
  showPreview:boolean=false;
  showSave:boolean =false;
  saveButtonTitle:string= 'Generate';
  constructor(public videoManger:CreateVideoManagerService,private oSessionService : cSessionService, public router:Router,public onboardinManger:OnboardingManagerService,private location:Location ,private config:ConfigManagerService) {

  }
sub:Subscription;
  ngOnInit(): void {

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    
 
console.log("Tmeplates",this.config.Templates);
  
  if(this.sSessionId !== '' && this.sUserEmail !== '')
  {
    if(!this.onboardinManger.profile)
    {
    this.getProfile(this.sUserEmail,this.sSessionId);
    }else  if(this.config.Templates.length === 0)
    {
          this.config.getTemplates2(this.sUserEmail,this.sSessionId ,this.onboardinManger.profile.allowed_templates);
    }
  }

    this.UpdateMenu();

  this.sub =  this.router.events.subscribe((event: Event) => {
      this.UpdateMenu();
    })

  }
  ngOnDestroy(): void {
    if(this.sub)
    {
      this.sub.unsubscribe();
    }
  }

  async getProfile(email,session)
  {
   await this.onboardinManger.getUserProfile(email,session).then( async (res:profile)=>{



       await this.config.getTemplates2(email,session,res.allowed_templates);
    });
  
   await    this.onboardinManger.getUserVideosCount(email,session).then(count=>{
 });
    
      this.config.getVoices(email,session);
  }
  private UpdateMenu() {
    console.log(this.router.url);

     
    if (this.router.url === '/wizard/overview') {
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
      }
      this.saveButtonTitle = 'Generate';
    } else if (this.router.url === '/wizard/looknfeel') {
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = true;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
      }
    } else if (this.router.url === '/wizard/content') {
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = true;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = true;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
      }

    } else if (this.router.url === '/wizard/other-setting') {
      this.saveButtonTitle = 'Generate';
      if (!this.videoManger.isEditMode) {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = true;
        this.showPrevious = true;
      }
      else {
        this.showNext = false;
        this.showCancel = true;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
      }
    } else if (this.router.url === '/wizard/preview') {
      if (!this.videoManger.isEditMode) {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.saveButtonTitle = 'Back';

      }
      else {
        this.showNext = false;
        this.showCancel = false;
        this.showPreview = false;
        this.showPrevious = false;
        this.showSave = true;
        this.saveButtonTitle = 'Back';
      }
    }
  }
  back()
  {
//this.location.back();
 
this.router.navigateByUrl('/my-videos/draftsVideo');
  }
  next(){
  this.videoManger.submitForm.emit();  
    this.UpdateMenu();
  }

  save()
  {
   
    this.videoManger.submitForm.emit("save");  ;
    this.UpdateMenu();
  }

  previous()
  {
   
    this.videoManger.previousSubmit.emit();
    this.UpdateMenu();
  }
  cancel(){
    console.log('cancel');
      this.router.navigateByUrl('/my-videos/draftsVideo');
      this.UpdateMenu();
    
  }
  isTimeLineView=false;
  changeview(event)
  {
    console.log(event.target.checked);
    if(event.target.checked)
    {
      this.isTimeLineView=false;
        this.router.navigateByUrl('/wizard/overview');
    }else
    {
      this.isTimeLineView=true;

      this.router.navigateByUrl('/wizard/timeline-view');
  
    }

  }

}
