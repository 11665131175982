export const ApiURL = {
  admin: {
    user: {
      get: "list_users",
      search_user: "list_users_light",
      add: "create_user",
      assignBrand: "user_assign_brand",
      unAssignBrand: "user_unassign_brand",
      profile: "get_user",
      brands: "list_user_brands",
      addMediaLib: "add_user_media_lib",
      addBrands: "add_user_brand",
      update: "update_user",
      deActivate: "delete_user",
      delete_user_brand: "delete_user_brand",
      reset_user_password: "reset_password",
    },
    brand: {
      add: "create_brand",
      getUserBrands: "get_brand",
      getAll: "list_brands",
      addUserBrand: "add_user_brand",
      addbrandMediaLib: "add_brand_media_lib",
      update: "update_brand",
      delete: "delete_brand",
      delete_brand_media_library: "delete_brand_media_lib",
      fbAccessToken: "connect_brand_facebook",
      linkedInAccessToken: "connect_brand_linkedin",
      getfbAccessToken: "get_brand_facebook_conn",
      getLinkedinAccessToken: "get_brand_linkedin_conn",
      deletefbAccessToken: "del_brand_facebook_conn",
      deleteLinkedInAccessToken: "del_brand_linkedin_conn",
      instagramAccessToken: "connect_brand_instagram",
      getInstagramAccessToken: "get_brand_instagram_conn",
      deleteInstagramAccessToken: "del_brand_instagram_conn",
      youTubeAccessToken: "connect_brand_youtube",
      getyouTubeAccessToken: "get_brand_youtube_conn",
      deleteyouTubeAccessToken: "del_brand_youtube_conn",
      addBrandFont: "add_brand_font",
      deleteBrandFont: "delete_brand_font",
      getAllFont: "list_default_fonts"
    },
    visual: {
      getAll: "visuals",
      get: "get_visual",
      delete: "delete_visual",
      getThumbnails: "get_thumbnails",
      signedUrlMultiDownload: "signed_url_multi_download",
      signedUrlForDownload: "signed_url_for_download",
      updateVisual : "update_visual",
    },
    media: {
      getAll: "list_media_libs",
      getOrgAll: "list_org_media_libs",
      add: "create_media_lib",
      addUserMediaLib: "add_user_media_lib",
      getUserMediaLib: "list_users_media_libs",
      get: "get_media_lib",
      delete: "delete_media_lib",
      update: "update_media_lib",
      get_media_lib_users : "list_user_media_libs",
      del_media_lib_users : "delete_user_media_lib",
      list_brand_media_libs: "list_brand_media_libs",
      list_published_videos: "list_user_published_videos"
    },
    industry: {
      getAll: "list_industries"
    },
  },
};
