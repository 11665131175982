<section class="accountSec main-body bg-gray-7 h-100">
    <div class="container">
        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow mt-4" id="pills-tabContent">
            <div class="p-4 border-bottom">
                <h5 class="modal-title text-gray-2 mb-0">View on Prontopia .ai</h5>
                <div >
                    <span class="text-sm text-gray-2">{{oPublicUrl | slice:0:37}}</span>
                    <button
                        class="ms-3 btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad d-inline btnShadow" (click)="TimelineViewComponent_CopyToClipBoard()">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.55312 5.89004C2.09273 6.34984 1.83284 7.11767 1.83284 8.40015V10.9305C1.83284 12.2129 2.09273 12.9808 2.55312 13.4406C3.01351 13.9004 3.78231 14.1599 5.06643 14.1599H7.59997C8.88409 14.1599 9.65289 13.9004 10.1133 13.4406C10.5737 12.9808 10.8336 12.2129 10.8336 10.9305V8.93285C10.8336 8.65703 11.0574 8.43344 11.3336 8.43344C11.6098 8.43344 11.8336 8.65703 11.8336 8.93285V10.9305C11.8336 12.3115 11.5601 13.4081 10.8204 14.1468C10.0807 14.8856 8.98272 15.1587 7.59997 15.1587H5.06643C3.68367 15.1587 2.58566 14.8856 1.84596 14.1468C1.10625 13.4081 0.832764 12.3115 0.832764 10.9305V8.40015C0.832764 7.01915 1.10625 5.92254 1.84596 5.18378C2.58566 4.44502 3.68367 4.17188 5.06643 4.17188H7.06659C7.34276 4.17188 7.56663 4.39547 7.56663 4.67128C7.56663 4.94709 7.34276 5.17068 7.06659 5.17068H5.06643C3.78231 5.17068 3.01351 5.43024 2.55312 5.89004Z"
                                fill="#404243" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.87521 4.2099C7.06206 4.13261 7.27714 4.17533 7.42015 4.31816L11.6872 8.57973C11.8302 8.72256 11.8729 8.93736 11.7955 9.12397C11.7182 9.31059 11.5358 9.43226 11.3336 9.43226H9.20007C8.37231 9.43226 7.66134 9.30092 7.17969 8.81988C6.69804 8.33884 6.56653 7.62879 6.56653 6.80208V4.67129C6.56653 4.4693 6.68836 4.2872 6.87521 4.2099ZM7.56661 5.87696V6.80208C7.56661 7.57345 7.70179 7.92879 7.88685 8.11362C8.07192 8.29845 8.42771 8.43346 9.20007 8.43346H10.1264L7.56661 5.87696Z"
                                fill="#404243" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.23328 1.34169C7.23328 1.06588 7.45715 0.842285 7.73332 0.842285H10.4002C10.6764 0.842285 10.9002 1.06588 10.9002 1.34169C10.9002 1.6175 10.6764 1.84109 10.4002 1.84109H7.73332C7.45715 1.84109 7.23328 1.6175 7.23328 1.34169Z"
                                fill="#404243" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.66658 1.84109C5.83599 1.84109 5.16646 2.50977 5.16646 3.3393C5.16646 3.61511 4.94259 3.8387 4.66642 3.8387C4.39026 3.8387 4.16638 3.61511 4.16638 3.3393C4.16638 1.95814 5.28366 0.842285 6.66658 0.842285H8.41339C8.68955 0.842285 8.91343 1.06588 8.91343 1.34169C8.91343 1.6175 8.68955 1.84109 8.41339 1.84109H6.66658Z"
                                fill="#404243" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.6672 4.8374C14.9434 4.8374 15.1673 5.06099 15.1673 5.3368V9.45854C15.1673 10.7665 14.1033 11.829 12.7937 11.829C12.5176 11.829 12.2937 11.6054 12.2937 11.3296C12.2937 11.0538 12.5176 10.8302 12.7937 10.8302C13.551 10.8302 14.1672 10.2148 14.1672 9.45854V5.3368C14.1672 5.06099 14.3911 4.8374 14.6672 4.8374Z"
                                fill="#404243" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10.4756 0.880315C10.6624 0.803016 10.8775 0.845743 11.0205 0.988571L15.0208 4.98379C15.1638 5.12662 15.2066 5.34142 15.1292 5.52804C15.0518 5.71465 14.8695 5.83633 14.6672 5.83633H12.6671C11.8893 5.83633 11.2117 5.71331 10.7509 5.25308C10.29 4.79285 10.1669 4.11608 10.1669 3.33931V1.3417C10.1669 1.13971 10.2887 0.957613 10.4756 0.880315ZM11.1669 2.54737V3.33931C11.1669 4.06075 11.2938 4.38279 11.458 4.54681C11.6223 4.71084 11.9447 4.83752 12.6671 4.83752H13.46L11.1669 2.54737Z"
                                fill="#404243" />
                        </svg>
                        <span class="ms-2">Copy Link</span>
                    </button>
                </div>
            </div>
            <div class="row p-4">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                    <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                        <video  #videoPreview controls controlsList="nodownload noplaybackrate"  class="videoIframe js-videoIframe" width="620" height="350" [src]="oPublicUrl">
                        </video>
                        <button class="videoPoster js-videoPoster"
                           >
                        </button>
                    </div>
                    <div class="mt-5">
                        <h5 class="text-gray-1 mb-2">Comments</h5>
                        <form [formGroup]="fCommentsForm">
                            <div class="formItm ">
                                <input id="serviceImpeccable" type="text" formControlName="comment" placeholder="Service was Impeccable." (keyup.enter)="CommentSectionComponent_AddCommentRequest()"
                                    value="Service was Impeccable.">
                                <!-- <label for="serviceImpeccable" class="text-sm">Service was Impeccable.</label> -->
                            </div>
                        </form>
                        <!-- <div class="formItm ">
                            <input id="joinDiscussion" type="text" placeholder="Join the Discussion"
                                value="Join the Discussion" required="">
                            <label for="joinDiscussion" class="text-sm">Join the Discussion</label>
                        </div> -->
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <h3 class="primaryFont weight-700 text-gray-2 mb-2"> {{sVideoTitle}}
                    </h3>
                    <p class="text-gray-2 text-sm pt-1 mb-0">
                        This is a low-resolution, watermarked preview of the video. The quality of the final
                        rendered video will be as you specify. Once you complete the publishing process, you'll
                        be able to download, view on pronto, or share your video right from the platform.
                    </p>
                </div>
            </div>

            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
    </div>
</section>