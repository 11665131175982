import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchInUserList', pure: true })
export class SearchInUserList implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchListText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchListText) {
      return items;
    }
    searchListText = searchListText.toLocaleLowerCase();

    return items.filter(it => {
      const userId = it.user_id;
      console.log(userId, searchListText);
      return userId.toLocaleLowerCase().includes(searchListText);
    });
  }
}