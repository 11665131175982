import { ComponentFactoryResolver, Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { meta_info } from '../models/meta_info';
import { template, templateBlockConfiguration, templateConfig, templatesCategory } from '../models/template';
import { block_args, voice } from '../models/video';
import { cClientService } from '../services/client.service'
import { CommonCallService } from '../services/dataServices/common-call.service';
import { OnboardingManagerService } from './onboarding-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigManagerService {

  public bDisplayLocales: boolean = true;
  public text_effect_names: string[] = [];
  public transition_names: string[] = [];
  public durations: number[] = [];
  public clip_effect_names: string[] = [];
  public template_config: any[] = [];

  public voices: voice[] = [];

  constructor(public clientService: cClientService, public httpCommon: CommonCallService) {

    // this.getMetaInfo();
  }
  public Templates: templatesCategory[] = [];
  public metaInfo: meta_info;
  async getMetaInfo() {
    await this.clientService.getMetaInfo().subscribe(
      (res: any) => {

        //console.log(res);
        this.metaInfo = res as meta_info;
        // this.metaInfo.stripe_enabled = false;
        console.log(this.metaInfo);
      }
      , (err: any) => {
        console.log(err);

      })

  }
  public getVoices(email, session) {
    this.httpCommon.getVoicelist(email, session).then((res: any) => {
      console.log("Started5");

      this.voices = [];
      const keys = Object.keys(res);

      keys.forEach((key, index) => {

        let vi = new voice();
        vi.name = key;
        vi.sample_voice = res[key].sample_voice;
        vi.gender = res[key].gender;

        this.voices.push(vi);
      });


      console.log('step', this.voices);
    });
  }


  public async getTemplates2(email, session,allowedTemplates: string[]) {
    this.Templates = [];
    await this.clientService.getTemplates().then((res: any) => {

      this.Templates =[];


      for (const key in res.template_meta_data['Categories']) {

        let tc = new templatesCategory();
        tc.category = key;

        this.Templates.push(tc)
      }

      let batchUrls = [];

      for (const key in res.template_meta_data) {
        console.log(key);

        if (key != 'Categories') {
          if (Object.prototype.hasOwnProperty.call(res.template_meta_data, key)) {



            let template = res.template_meta_data[key] as template;
            template.template_name = key;
         
          
            allowedTemplates?.forEach(ele => {

             
              if (ele == key) {
            template.categories.forEach(c => {

              this.Templates.forEach(tc => {

                if (tc.category == c) {
                  if (!tc.templates) {
                    tc.templates = [];
                  }

                  template.previews.forEach(preview => {
                    if (preview.preview && !batchUrls.includes(preview.preview))
                      batchUrls.push(preview.preview);

                    if (preview.thumb && !batchUrls.includes(preview.thumb))
                      batchUrls.push(preview.thumb);

                  });
                  tc.templates.push(template);
                }
              });

            });
          }});


          }
        }
  

      }

   this.clientService.getThumbBatchSignedURL(email,session,batchUrls).then((bUrls:any)=>{

        console.log('urls',bUrls);

        let output = bUrls['output'];
        this.Templates?.forEach(ele=>{
          ele.templates?.forEach(temp=>{
            temp.previews?.forEach(pr=>{

              
             pr.url = output.filter(a => a[pr.preview])[0][pr.preview];
             pr.thumbnail =  output.filter(a => a[pr.thumb])[0][pr.thumb];   
            });
          });
        });

        console.log('templates2----2', this.Templates);
      });
      console.log('templates2', this.Templates);
    });

  }

  public async getTemplates(email, session, allowedTemplates: string[]) {
    console.log('allowed tempaltes', allowedTemplates);

    this.Templates = [];

    await this.clientService.getVideoTemplates(email, session).then((res: any) => {
      this.Templates = [];
      console.log(res);

      //this.clip_effect_names = res['clip_effect_names'];
      // this.text_effect_names = res['text_effect_names'];
      this.durations = res['durations'];
      //this.transition_names = res['transition_names'];
      this.template_config = res['template_config'];

      for (const key in res.template_categories) {
        //  console.log(key);
        if (Object.prototype.hasOwnProperty.call(res.template_categories, key)) {

          let tc = new templatesCategory();
          tc.category = key;
          let templates = res.template_categories[key] as template[];
          tc.templates = [];
          templates.forEach(e => {
            allowedTemplates?.forEach(ele => {
              if (ele == e.template_name) {

                tc.templates.push(e);
              }
            })

          });
          console.log(tc.templates);

          if (tc.templates) {
            tc.templates.forEach((c: template, index) => {

              c.second_logo = res['second_logos'][c.template_name];

              console.log("Allowed Tmeplates", allowedTemplates);

              // console.log("Tempalte Found",allowedTemplates.find(x => x === c.template_name));
              //  if (allowedTemplates.find(x => x === c.template_name)) {
              c.loadingPreviewUrl = true;
              this.httpCommon.getSignedURL(email, session, c.preview_path).then(imgfres => {
                c.loadingPreviewUrl = false;
                c.preview_url = imgfres['url'];
              }).catch(errimg => {

                c.loadingPreviewUrl = false;
              });



              c.loadingThumbnailUrl = true;
              this.httpCommon.getSignedURL(email, session, c.thumb).then(imgfres => {
                c.loadingThumbnailUrl = false;
                c.thumbnail = imgfres['url'];

                // console.log(this.Templates);
              }).catch(errimg => {

                c.loadingThumbnailUrl = false;
              });
              c.templateBlockConfiguration = [];

              this.extractblocks(res, c, 6);
              this.extractblocks(res, c, 15);
              this.extractblocks(res, c, 30);
              this.extractblocks(res, c, 60);



              //  } else {

              //  console.log("Before remove,", tc.templates);
              //   console.log("index,", index);
              //   tc.templates.splice(index, 1);
              //   console.log("After remove,", tc.templates);
              // }
            });
          }


          this.Templates.push(tc);

        }
      }
      console.log('Generated Tempaltes', this.Templates);

    });




  }



  private extractblocks(res: templateConfig, c: template, index: number) {

    if (res.template_config[c.template_name][index.toString()]) {
      // console.log("step1",res.template_config[c.template_name]);
      //console.log("step2",res.template_config[c.template_name][index.toString()]);
      // console.log("step3",res.template_config[c.template_name][index.toString()]['blocks']);

      let block = new templateBlockConfiguration();
      block.timeframe = index;
      block.blocks = [];

      res.template_config[c.template_name][index.toString()]['blocks'].forEach(element => {




        //  console.log('step4',res.block_config);
        // console.log('step5', element);
        // let bc = res.block_config[element['block_name']] as block_args;
        let bc = element as block_args;
        // console.log(bc);
        block.blocks.push(bc);

      });
      c['templateBlockConfiguration'].push(block);
    }
  }

  isDurationSupported(duration, category, templateName): boolean {
    let isSupported = false;

    if (templateName && templateName != '' && category && category != '') {
      this.Templates.forEach(c => {
        if (c.category == category) {
          c.templates.forEach(temp => {
            if (temp.template_name === templateName) {
              temp.templateBlockConfiguration.forEach(bc => {

                if (bc.timeframe === duration) {

                  isSupported = true;
                }
              })
            }
          })
        }
      });
    } else {
      isSupported = true;
    }

    return isSupported;
  }
}
