import { Injectable } from "@angular/core";
import { MediaLibrary } from "src/app/models/admin";
import { cSessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class AdminSettingService {
  selectedMediaLibrary: MediaLibrary;

  constructor(
    public oSessionService: cSessionService,
    private location: Location
  ) {}

  setHeaderBreadCrumps(currentPage: string, object: any = undefined) {
    this.oSessionService.headerBreadCrumps.currentPage = currentPage;
    switch (currentPage) {
      case "brand":
        this.oSessionService.setAccountSettingHeaderName("Manage Brands");
        this.oSessionService.setHeaderBreadcrumps("brandDetail", object);
        break;
      case "user":
        this.oSessionService.setAccountSettingHeaderName("Users");
        this.oSessionService.setHeaderBreadcrumps("userProfile", object);
        break;
      case "library":
        this.oSessionService.setAccountSettingHeaderName("Media Libraries");
        this.oSessionService.setHeaderBreadcrumps(
          "mediaLibraryProfile",
          object
        );
        break;
    }
  }

  moveBack() {
    this.location.back();
  }
}
