import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-paywall-payment-success',
  templateUrl: './paywall-payment-success.component.html',
  styleUrls: ['./paywall-payment-success.component.scss']
})
export class PaywallPaymentSuccessComponent implements OnInit {

  constructor( private activatedRoute: ActivatedRoute) { 


    this.activatedRoute.queryParams.subscribe((params: Params) => {
this.plan  = params['plan'];
 
    });
  }
plan:string='';
  ngOnInit(): void {
  }

}
