<div class="fixsidebar myvideo-flex">
    <ul>
        <li>
            <a href="javascript:void(0)" (click)="openReach()">
                <figure>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M19.3383 2.89462C19.6122 2.89462 19.865 3.06314 19.9703 3.31593C20.0756 3.56872 20.0124 3.86364 19.8018 4.0743L17.7373 6.13874C17.6109 6.26514 17.4424 6.32833 17.2528 6.32833H15.2305L11.3965 9.88844C11.7336 10.4362 11.9232 11.0681 11.9232 11.7422C11.9232 13.7856 10.2801 15.4287 8.23668 15.4287C6.1933 15.4287 4.55017 13.7856 4.55017 11.7422C4.55017 9.69885 6.1933 8.05572 8.23668 8.05572C8.93185 8.05572 9.56382 8.26638 10.1115 8.58237L13.6927 4.7484V2.74716C13.6927 2.55756 13.7559 2.38904 13.8823 2.26264L15.9467 0.198201C16.1363 0.00860893 16.4313 -0.0545883 16.684 0.0507404C16.9368 0.156069 17.1054 0.408858 17.1054 0.682713V2.89462H19.3383ZM14.2615 11.7633C14.2615 11.1524 14.1772 10.5626 14.0087 9.99379L15.7361 8.3928C16.2206 9.42502 16.4945 10.5626 16.4945 11.7633C16.4945 16.3135 12.7869 20 8.25777 20C3.70757 20 0 16.3135 0 11.7633C0 7.21312 3.70757 3.52661 8.25777 3.52661C9.45852 3.52661 10.5961 3.80047 11.6283 4.26391L10.0062 6.01237C9.45852 5.82278 8.86868 5.73851 8.25777 5.73851C4.92938 5.73851 2.2119 8.43493 2.2119 11.7633C2.2119 15.0917 4.92938 17.7881 8.25777 17.7881C11.5651 17.7881 14.2826 15.0917 14.2615 11.7633Z"
                                fill="#7A7A7A" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </figure>
                Reach
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" (click)="openEngagement()">
                <figure>
                    
                    <img src="../../../assets/images/dashboardVideo/icon-Engagement.svg" height="20px" width="20px">
                </figure>
                Engagement
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" (click)="openStats()">
                <figure>
                    <img src="../../../assets/images/dashboardVideo/Icon-Audience.svg" height="20px" width="20px">
                </figure>
                Audience
            </a>
        </li>

    </ul>
</div>


<!-- reach side bar start -->
<nz-drawer
[nzClosable]="false"
[nzVisible]="visibleReach"
(nzOnClose)="closeReach()"
[nzWidth]="440">
<ng-template nzDrawerContent >
<nav id="sidebar1" class="{{sRightSidebar}} sidebar1 bg-gray-9 sidebar-block p-3 dashboard-reach">
    <div class="sidebar-header bg-gray-9">
        <div class="header-inner">
            <h3 class="text-gray-2 weight-400 text-lg">Reach</h3>
            <!-- <div class="formItm down_arrow">
                <select class="border bg-white" >
                    <option  *ngFor="let duration of lStatsTimeFrame" (select)="ReportsReachComponent_UpdateDuration(duration)">{{duration.name}}</option> -->
                    <!-- <option>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option> -->
                <!-- </select>

            </div> -->
            <div class="formItm down_arrow" style="margin-top: -13px;">
                <div class="dropdown dropdownBtn">
                    <button class="dropdown-toggle text-md" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="ms-2 dropdownBtnText">{{sSeletedTimeFrame?.name}}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                        <li [ngClass]="sSeletedTimeFrame.name==duration.name?'active':''" *ngFor="let duration of lStatsTimeFrame">
                            <a class="dropdown-item text-md" href="javascript:void(0);" (click)="ReportsReachComponent_UpdateDuration(duration)">{{duration.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="dismiss"  (click)="closeReach()" (click)="DashboardSidepanelsComponent_DismissContentSlideClass()">

            <svg width="20" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>

        </div>

    </div>
    <!-- if no reports are there -->
    <div class="row m-0" *ngIf="bDisplayEmptyStatsSection">
        <app-empty-reports></app-empty-reports>
    </div>
    <div class="side-content" *ngIf="!bDisplayEmptyStatsSection">
        <div class="row">
            <div class="col col-xl-6 col-lg-6 col-md-6 ">
                <article class="bg-white p-3">
                    <h6 class="text-gray-2 pb-1">Viewed</h6>
                        <p class="text-gray-3 text-xs h-end">How many times your video was played</p>
                    <h5 class="align-self-end">{{oTotalStats?.total_events.video_play}}
                        
                        </h5>
                </article>
            </div>
            <div class="col col-xl-6 col-lg-6 col-md-6">
                <article class="bg-white p-3">
                    <h6 class="text-gray-2 pb-1">Completed</h6>
                        <p class="text-gray-3 text-xs h-end">How many times your video was watched completely</p>
                    <h5 class="align-self-end">{{oTotalStats?.total_events.video_complete}}
                    
                    </h5>
                </article>
            </div>
        </div>
        <div class="row my-4">
            <div class="col col-xl-12 col-lg-12 col-md-12">
                <article class="bg-white p-3">
                    <h6 class="text-gray-2 pb-1">Paused</h6>
                    <p class="text-gray-3 text-xs h-end">How many times someone paused your video while playing</p>
                    <h5 class="align-self-end">{{oTotalStats?.total_events.video_pause}}
                        </h5>
                </article>
            </div>
        </div>
        <div class="row mt-4 py-1 align-items-center">
            <div class="col col-xl-12 col-lg-12 col-md-12 mp-4 pb-1 ps-4">
                <article class="p-3 no-bg align-items-center">
                    <div class="left-text">
                        <h6 class="text-gray-2 pb-1">Traffic Source</h6>
                        <p class="text-gray-3 text-xs h-end">How viewers came to find your content. Websites and apps
                            that embed or link to videos from your channel.</p>
                    </div>
                    <div class="dropdown dropdownBtn">
                        <button class="dropdown-toggle text-md" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="ms-2 dropdownBtnText">Line Graph</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                            <li class="active">
                                <a class="dropdown-item text-md" href="javascript:void(0);">Line Graph</a>
                            </li>
                        </ul>
                    </div>
                </article>
            </div>
            <div class="col col-xl-12 col-lg-12 col-md-12 bg-white" *ngIf="oTotalStats">
                <apx-chart [series]="salesoverviewChartOptions.series" [chart]="salesoverviewChartOptions.chart"
                    [xaxis]="salesoverviewChartOptions.xaxis" [yaxis]="salesoverviewChartOptions.yaxis"
                    [grid]="salesoverviewChartOptions.grid" [stroke]="salesoverviewChartOptions.stroke"
                    [tooltip]="salesoverviewChartOptions.tooltip" [dataLabels]="salesoverviewChartOptions.dataLabels"
                    [legend]="salesoverviewChartOptions.legend" [colors]="salesoverviewChartOptions.colors"
                    [markers]="salesoverviewChartOptions.markers">
                </apx-chart>
                <ul class="social-t p-0 my-3 m-4">
                    <li class="youtube-gh">Viewed</li>
                    <li class="facebook-gh">Completed</li>
                    <li class="instagram-gh">Paused</li>
                </ul>
            </div>
        </div>
    </div>
</nav>


</ng-template>
                                  
</nz-drawer>

<!-- reach side bar end -->




<!-- 3rd side bar start -->
<nz-drawer
[nzClosable]="false"
[nzVisible]="visibleEngagement"
(nzOnClose)="closeEngagement()"
[nzWidth]="440">
<ng-template nzDrawerContent >
<nav id="sidebar2" class="{{sRightSidebar}} sidebar2 sidebar-block bg-gray-9 dashboard-engagement ">
    <div class="sidebar-header bg-gray-9">
        <div class="header-inner">
            <h3 class="text-gray-2 weight-400 text-lg">Engagement</h3>
            <div class="formItm down_arrow">
                <select class="border bg-white">
                    <option>Last 30 days</option>
                    <option>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option>
                </select>

            </div>
        </div>
        <div id="dismiss" (click)="closeEngagement()"  (click)="DashboardSidepanelsComponent_DismissContentSlideClass()">

            <svg width="20" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>

        </div>

    </div>

    <div class="side-content">
        <div class="row my-4">
            <div class="col col-xl-12 col-lg-12 col-md-12">
                <div class="side-content">
                    <article class="bg-white rounded border p-3 engagement1">
                        <h6 class="text-gray-2 pb-1">Watch Time</h6>
                        <p class="text-gray-3 text-xs h-end">The total amount of time your video has been watched across
                            all social platforms.</p>
                        <h5 class="align-self-end">3hrs<span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                        fill="#00C599"></path>
                                </svg>
                                20%</span></h5>
                    </article>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col col-xl-12 col-lg-12 col-md-12 engagement-card">
                <div class="side-content">
                    <article class="bg-white rounded border p-4">
                        <h6 class="text-gray-2 pb-3">Top Performing video</h6>
                        <div class="video-outer top-perform">
                            <div class="video_wrapper video_wrapper_full js-videoWrapper  videoWrapperActive">
                                <iframe src="https://www.youtube.com/embed/XULUBg_ZcAU"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen="" class="videoIframe js-videoIframe"></iframe>
                                <figure class="video-baner"><img
                                        src="../assets/images/myVideos/v2.png" alt="video1"></figure>
                                        <button class="videoPoster js-videoPoster"></button>
                            </div>
                            <div class="top-perform-text">
                                <p class="text-md p-0 m-0 text-gray-2">Family chiropractic center of richmond
                                </p>
                                <p class="d-flex p-0 m-0"><span
                                        class="video-view text-xxs pe-5 text-gray-3">Views <b
                                            class="d-block text-md text-gray-2">675</b></span><span
                                     class="video-view text-xxs text-gray-3">Watch Time <b
                                         class="d-block text-md text-gray-2">30 Min</b></span>
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <div class="row mt-4 py-1 align-items-center">
            <div class="col col-xl-12 col-lg-12 col-md-12 mp-4 pb-1 engagement-card">
                <div class="side-content">
                    <article class="bg-white rounded border p-3">
                        <h6 class="text-gray-2 pb-1">Shares</h6>
                        <p class="text-gray-3 text-xs h-end">How many times your video has been shared across all
                            platforms.</p>
                        <h5 class="align-self-end">425<span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                        fill="#00C599"></path>
                                </svg>
                                10%</span></h5>
                    </article>
                </div>
            </div>
            <div class="col col-xl-12 col-lg-12 col-md-12">
                <div class="side-content">
                    <article class="bg-white rounded border p-3">
                        <h6 class="text-gray-2 pb-1">Link 
                            <h5 class="align-self-end right-top link-count">500<span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                        fill="#00C599"></path>
                                </svg>
                                10%</span></h5>
                        </h6>

                

                        <p class="text-gray-3 d-none text-xs h-end">Total Interactions</p>
                       
                        <div id="chart">
                            <!-- <apx-chart [grid]="chartOptions.grid" [series]="chartOptions.series"
                                    [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
                                    [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis"
                                    [colors]="chartOptions.colors" [markers]="chartOptions.markers"
                                    [legend]="chartOptions.legend" [tooltip]="chartOptions.tooltip"
                                    [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis"></apx-chart> -->
                            <div class="progress-bars">
                                <ul>
                                    <li>
                                        <span>Website</span>
                                        <p><span style="width: 30%;"></span>30%</p>
                                    </li>
                                    <li>
                                        <span>E-mail</span>
                                        <p><span style="width: 80%;"></span>20%</p>
                                    </li>
                                    <li>
                                        <span>Phone</span>
                                        <p><span style="width: 50%;"></span>20%</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>

</nav>


</ng-template>
                                  
</nz-drawer>

<!-- Stats side bar start -->
<nz-drawer
[nzClosable]="false"
[nzVisible]="visibleStats"
(nzOnClose)="closeStats()"
[nzWidth]="440">
<ng-template nzDrawerContent >
<nav id="sidebar3" class="{{sRightSidebar}} sidebar3 sidebar-block bg-gray-9 p-3 dashboard-reach ">
    <div class="sidebar-header bg-gray-9">
        <div class="header-inner">
            <h3 class="text-gray-2 weight-400 text-lg">Audience</h3>
            <div class="formItm down_arrow">
                <select class="border bg-white">
                    <option>Last 30 days</option>
                    <option>Select 1</option>
                    <option>Select 2</option>
                    <option>Select 3</option>
                </select>

            </div>
        </div>
        <div id="dismiss" (click)="closeStats()" (click)="DashboardSidepanelsComponent_DismissContentSlideClass()">

            <svg width="20" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>

        </div>

    </div>

    <div class="side-content">
        <div class="row my-4">
            <div class="col col-xl-12 col-lg-12 col-md-12 ">
                <div class="audienceGraph bg-white p-3 engagement1" >
                    <article class="bg-white p-3">
                        <h5 class="align-self-end">785<span>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                        fill="#00C599" />
                                </svg>
                                10%</span></h5>
                    </article>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="lineGraph">
                                <div style="height:300px;" class="d-flex flex-column">
                                    <apx-chart [series]="socialMediaChartOptions.series"
                                        [chart]="socialMediaChartOptions.chart" [xaxis]="socialMediaChartOptions.xaxis"
                                        [yaxis]="socialMediaChartOptions.yaxis" [grid]="socialMediaChartOptions.grid"
                                        [stroke]="socialMediaChartOptions.stroke"
                                        [tooltip]="socialMediaChartOptions.tooltip"
                                        [dataLabels]="socialMediaChartOptions.dataLabels"
                                        [legend]="socialMediaChartOptions.legend"
                                        [colors]="socialMediaChartOptions.colors"
                                        [markers]="socialMediaChartOptions.markers">
                                    </apx-chart>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="lineGraph">
                                <div style="height:300px;" class="d-flex flex-column">
                                    <apx-chart [series]="socialMediaChartOptions.series"
                                        [chart]="socialMediaChartOptions.chart" [xaxis]="socialMediaChartOptions.xaxis"
                                        [yaxis]="socialMediaChartOptions.yaxis" [grid]="socialMediaChartOptions.grid"
                                        [stroke]="socialMediaChartOptions.stroke"
                                        [tooltip]="socialMediaChartOptions.tooltip"
                                        [dataLabels]="socialMediaChartOptions.dataLabels"
                                        [legend]="socialMediaChartOptions.legend"
                                        [colors]="socialMediaChartOptions.colors"
                                        [markers]="socialMediaChartOptions.markers">
                                    </apx-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col col-xl-12 col-lg-12 col-md-12">
                <div class="side-content">
                    <article class="bg-white rounded border p-3">
                        <h6 class="text-gray-2 pb-4">Gender</h6>
                        <div class="progress-bars">
                            <ul>
                                <li>
                                    <span>Female</span>
                                    <p><span style="width: 30%;"></span>30%</p>
                                </li>
                                <li>
                                    <span>Male</span>
                                    <p><span style="width: 80%;"></span>80%</p>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <div class="row mt-4 py-1 align-items-center">
            <div class="col col-xl-12 col-lg-12 col-md-12 mp-4 pb-1">
                <div class="side-content">
                    <article class="bg-white rounded border p-3">
                        <h6 class="text-gray-2 pb-1">Age</h6>
                        <div class="row lineGraph mt-4">
                            <div class="col">
                                <apx-chart [series]="ageChartOptions.series" [chart]="ageChartOptions.chart"
                                    [dataLabels]="ageChartOptions.dataLabels" [legend]="ageChartOptions.legends"
                                    [colors]="ageChartOptions.colors" [stroke]="ageChartOptions.stroke"
                                    [labels]="ageChartOptions.labels" [tooltip]="ageChartOptions.tooltip"
                                    [plotOptions]="ageChartOptions.plotOptions"></apx-chart>
                            </div>
                            <div class="col">

                                <table class="table vm font-14 m-b-0 text-center">
                                    <tr>
                                        <td class="border-0"> 13-17</td>
                                        <td class="text-right font-medium border-0">10%</td>
                                    </tr>
                                    <tr>
                                        <td class="border-0">18-24</td>
                                        <td class="text-right font-medium border-0">45%</td>
                                    </tr>
                                    <tr>
                                        <td class="border-0">25-34</td>
                                        <td class="text-right font-medium border-0">20%</td>
                                    </tr>
                                    <tr>
                                        <td>35-44</td>
                                        <td class="text-right font-medium">25%</td>
                                    </tr>
                                    <tr>
                                        <td>45-54</td>
                                        <td class="text-right font-medium">10%</td>
                                    </tr>
                                    <tr>
                                        <td>55-64</td>
                                        <td class="text-right font-medium">45%</td>
                                    </tr>
                                    <tr>
                                        <td>65+</td>
                                        <td class="text-right font-medium">20%</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div class="col col-xl-12 col-lg-12 col-md-12">
                <div class="side-content">
                    <article class="bg-white rounded border p-3">
                        <h6 class="text-gray-2 pb-1">Top viewer locations</h6>
                        <div class="progress-bars mt-3">
                            <ul>
                                <li>
                                    <span>India</span>
                                    <p><span style="width: 30%;"></span>30%</p>
                                </li>
                                <li>
                                    <span>USA</span>
                                    <p><span style="width: 80%;"></span>20%</p>
                                </li>
                                <li>
                                    <span>Australia</span>
                                    <p><span style="width: 20%;"></span>20%</p>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>

</nav>

</ng-template>
                                  
</nz-drawer>