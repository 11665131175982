<!-- no video message -->
<!--  <div *ngIf="!this.lScheduledVideos.length" class="align-items-center justify-content-center d-flex flex-grow-1" id="pills-tabContent">
    <div class="text-center pb-4 custom-text-center {{addCustomClass}}">
        <h4 class="text-gray-2 m-0 p-0">You don't have any scheduled videos.</h4>
        <p class="text-gray-3 mt-3 mb-4 pb-1 text-md">Prontopia can create and publish videos to your connected social<br> channels automatically.</p>
        <a (click)="CreatedVideosComponent_NavigateToCreateVideo()" class="btn btn-secondary btn-round-6 md-btn d-flex justify-content-center d-inline-block px-3">
        <span class="py-2 ps-1 d-inline-block"> Schedule Autocreate</span></a>
    </div>
</div> -->
<!-- right bottom bg svg -->
<!--<div *ngIf="!this.lScheduledVideos.length" class="emptyWatermark">
    <svg width="263" height="296" viewBox="0 0 263 296" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M262.298 29.8416C262.298 45.8893 249.289 58.8988 233.241 58.8988C217.193 58.8988 204.184 45.8893 204.184 29.8416C204.184 13.7937 217.193 0.784424 233.241 0.784424C249.289 0.784424 262.298 13.7937 262.298 29.8416Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M236.302 40.5385L232.906 30.1006L216.288 19.51L216.716 18.8381L233.575 29.5825L237.06 40.2918L236.302 40.5385Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.398438 81.938V130.889C0.398438 136.198 4.70252 140.502 10.0119 140.502H81.9806C87.29 140.502 91.5939 136.198 91.5939 130.889V81.938H0.398438Z" fill="white"/>
        <rect x="8" y="92.0002" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="8" y="113" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="28" y="92.0002" width="16" height="16" rx="2" fill="#FF8369"/>
        <path d="M32 100.429L34.5263 103L40 97.0002" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="28" y="113" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="48" y="92.0002" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="48" y="113" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="68" y="92.0002" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <rect x="68" y="113" width="16" height="16" rx="2" fill="#F2F2F5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.796541 82.3361V130.889C0.796541 135.97 4.93049 140.104 10.0116 140.104H81.9802C87.0615 140.104 91.1954 135.97 91.1954 130.889V82.3361H0.796541ZM81.9802 140.901H10.0116C4.49122 140.901 0 136.409 0 130.889V81.5396H91.992V130.889C91.992 136.409 87.501 140.901 81.9802 140.901Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M91.5939 73.9668C91.5939 68.6576 87.29 64.3533 81.9806 64.3533H10.0119C4.70252 64.3533 0.398438 68.6576 0.398438 73.9668V81.9379H91.5939V73.9668Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.796541 81.5397H91.1954V73.9669C91.1954 68.8856 87.0615 64.7516 81.9802 64.7516H10.0116C4.93049 64.7516 0.796541 68.8856 0.796541 73.9669V81.5397ZM91.992 82.3363H0V73.9669C0 68.4463 4.49122 63.9551 10.0116 63.9551H81.9802C87.501 63.9551 91.992 68.4463 91.992 73.9669V82.3363Z" fill="#D2D2D2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5081 70.4904C11.0441 70.4904 9.85294 71.6816 9.85294 73.1455C9.85294 74.6095 11.0441 75.8007 12.5081 75.8007C13.972 75.8007 15.1632 74.6095 15.1632 73.1455C15.1632 71.6816 13.972 70.4904 12.5081 70.4904ZM12.5081 76.5972C10.6049 76.5972 9.0564 75.0487 9.0564 73.1455C9.0564 71.2423 10.6049 69.6938 12.5081 69.6938C14.4113 69.6938 15.9598 71.2423 15.9598 73.1455C15.9598 75.0487 14.4113 76.5972 12.5081 76.5972Z" fill="#BABCBE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M133.557 247.835L130.158 295.632H226.306L225.86 247.835L183.499 240.256L133.557 247.835Z" fill="#BABCBE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M181.459 295.806L180.665 295.776L181.347 277.949L168.806 274.998L168.989 274.223L182.169 277.324L181.459 295.806Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M201.407 148.48C209.245 133.212 202.731 120.998 193.265 117.537C196.624 85.9825 155.094 86.6949 158.894 110.14L176.266 146.649C176.266 146.649 201.713 148.48 201.407 148.48Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M193.164 94.5327C193.164 99.1425 189.425 102.879 184.816 102.879C180.207 102.879 176.47 99.1425 176.47 94.5327C176.47 89.923 180.207 86.186 184.816 86.186C189.425 86.186 193.164 89.923 193.164 94.5327Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M133.557 247.859H225.86V214.417H133.557V247.859Z" fill="#F2F2F5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M232.704 156.08C228.993 148.242 221.569 142.821 212.977 141.657L174.471 136.368L140.53 143.873C131.856 146.162 125.214 153.149 123.364 161.925L109.479 227.794H231.797L243.11 227.758C251.143 227.733 256.683 219.697 253.849 212.18L232.704 156.08Z" fill="#F2F2F5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M122.512 165.966L122.538 165.842L122.512 165.966ZM143.389 143.24L143.653 143.18L143.389 143.24Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M210.757 141.352L210.199 141.276L210.757 141.352Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M240.64 203.586L223.292 202.082L223.36 201.287L240.708 202.791L240.64 203.586Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M223.213 201.812L211.265 166.499L212.021 166.244L223.969 201.557L223.213 201.812Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M143.803 203.575L143.009 203.511L145.899 167.136L146.693 167.2L143.803 203.575Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M231.159 208.722C231.676 216.893 229.411 225.443 223.687 227.516C222.208 228.051 220.573 227.325 220.012 225.855C218.583 222.101 220.598 216.347 223.591 214.164L231.159 208.722Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M194.103 227.703H215.86V198.496H194.103V227.703Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M184.002 118.962V141.457C184.002 146.628 179.807 150.823 174.637 150.823C169.467 150.823 165.273 146.628 165.273 141.457V118.962H184.002Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M180.557 126.079C176.377 132.382 171.36 137.056 165.273 139.653V123.4L180.557 126.079Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M183.322 121.542C182.617 123.809 181.396 125.652 179.862 127.091C176.058 130.665 170.319 131.785 165.677 130.771C165.539 130.739 165.407 130.707 165.274 130.66C162.566 129.826 160.988 126.762 160.08 123.246C159.719 121.86 159.459 120.4 159.284 118.982C158.689 114.352 158.896 110.141 158.896 110.141L161.711 102.372L171.65 98.7395L182.271 106.546L182.613 111.378L182.876 115.217L183.144 118.961L183.276 120.894L183.322 121.542Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M158.894 110.14C160.557 105.835 162.167 102.726 165.408 101.811C166.365 101.541 167.392 101.691 168.271 102.151C170.004 103.055 172.855 105.096 173.62 108.987C174.739 114.687 177.794 116.824 182.373 115.399C186.953 113.974 187.259 108.376 187.259 108.376L186.241 100.945L174.127 96.8738L164.357 99.3167L161.066 102.269L158.894 110.14Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M188.361 117.432C187.233 120.128 184.538 121.569 182.339 120.649C180.143 119.729 179.276 116.797 180.406 114.1C181.534 111.404 184.23 109.964 186.428 110.884C188.624 111.804 189.491 114.736 188.361 117.432Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M182.265 118.011C182.303 117.083 182.698 116.152 183.339 115.437C183.983 114.72 184.854 114.235 185.767 114.075L185.886 114.363C185.158 114.88 184.48 115.362 183.928 115.97C183.365 116.571 182.955 117.288 182.566 118.085L182.265 118.011Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M162.759 115.658C162.759 116.35 162.364 116.911 161.877 116.911C161.39 116.911 160.995 116.35 160.995 115.658C160.995 114.966 161.39 114.405 161.877 114.405C162.364 114.405 162.759 114.966 162.759 115.658Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M168.469 115.286C168.469 115.978 168.074 116.539 167.585 116.539C167.099 116.539 166.704 115.978 166.704 115.286C166.704 114.594 167.099 114.033 167.585 114.033C168.074 114.033 168.469 114.594 168.469 115.286Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M164.793 117.367C164.653 117.756 164.479 118.112 164.335 118.463C164.195 118.809 164.067 119.163 164.06 119.452C164.058 119.573 164.077 119.665 164.147 119.764C164.21 119.867 164.327 119.968 164.465 120.066C164.746 120.26 165.106 120.414 165.466 120.612L165.39 120.914C164.977 120.916 164.554 120.869 164.129 120.68C163.918 120.585 163.704 120.446 163.525 120.229C163.351 120.011 163.244 119.697 163.268 119.418C163.303 118.904 163.507 118.504 163.733 118.145C163.964 117.788 164.233 117.477 164.532 117.197L164.793 117.367Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M163.047 123.06C163.623 123.433 164.191 123.805 164.793 124.03C165.397 124.249 166.008 124.289 166.564 124.07C167.124 123.856 167.617 123.41 168.086 122.941C168.558 122.465 168.991 121.932 169.463 121.421L169.692 121.58C169.371 122.202 168.997 122.794 168.551 123.354C168.101 123.903 167.562 124.445 166.82 124.731C166.078 125.02 165.214 124.924 164.559 124.584C163.889 124.257 163.344 123.783 162.863 123.27L163.047 123.06Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M162.843 111.168C162.857 111.255 162.845 111.358 162.799 111.478C162.517 112.217 161.359 112.485 160.672 112.587C160.069 112.677 158.7 112.663 159.565 111.78C160.109 111.226 160.935 110.92 161.682 110.769C162.151 110.673 162.778 110.76 162.843 111.168Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M165.21 110.492C165.176 110.587 165.177 110.694 165.224 110.813C165.517 111.546 167.16 111.643 168.144 111.64C169.008 111.637 171.005 111.402 169.885 110.611C169.184 110.113 168.031 109.924 166.966 109.885C166.297 109.86 165.37 110.052 165.21 110.492Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M233.322 206.532C233.513 203.554 228.538 201.4 226.327 200.682C223.084 199.631 219.634 199.325 216.248 199.136C215.006 199.206 213.325 198.853 212.75 200.873C211.972 203.607 220.271 207.628 226.251 208.815C228.247 209.213 231.493 210.141 232.831 207.97C233.137 207.473 233.292 206.993 233.322 206.532Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M227.916 219.325C230.688 215.708 225.541 212.182 213.789 208.632C211.909 208.012 211.157 207.66 209.916 210.043C208.757 212.271 216.038 217.634 220.919 219.892C223.347 221.018 225.112 222.981 227.916 219.325Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M223.642 223.714C225.662 219.848 222.398 216.8 213.6 214.142C206.571 213.773 213.328 221.789 218.404 224.139C220.223 224.98 222.816 225.29 223.642 223.714Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M231.181 211.566C232.666 207.528 226.268 204.896 212.754 203.222C211.909 203.278 211.191 202.999 210.581 204.711C209.298 208.322 216.629 212.426 225.41 214.954C228.496 215.842 229.322 216.626 231.181 211.566Z" fill="#FFE0B2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M226.185 208.584L212.824 203.135L213.158 202.32L226.518 207.768L226.185 208.584Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M221.816 213.038L212.202 208.868L212.552 208.059L222.166 212.229L221.816 213.038Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M219.874 219.376L211.873 214.516L212.329 213.762L220.331 218.622L219.874 219.376Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M135.731 227.735H174.816V221.785H135.731V227.735Z" fill="#7A7A7A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M153.996 227.703H96.6446L80.0059 176.724H137.357L153.996 227.703Z" fill="#A7A7A7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M149.925 295.632H159.483V240.76H149.925V295.632Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M150.19 295.368H159.217V241.025H150.19V295.368ZM149.659 295.897H159.748V240.494H149.659V295.897Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M150.19 248.351H159.217V243.738H150.19V248.351Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M159.217 248.351H159.483V243.738H159.217V248.351ZM149.925 248.351H150.19V243.738H149.925V248.351Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M140.72 243.502H168.687V232.263H140.72V243.502Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M140.954 243.268H168.454V232.497H140.954V243.268ZM140.487 243.738H168.921V232.03H140.487V243.738Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M140.954 239.619H168.454V232.497H140.954V239.619Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M168.687 239.619H168.454V232.497H140.954V239.619H140.72V232.263H168.687V239.619Z" fill="#E0E0E0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M64.783 235.76H244.624V227.703H64.783V235.76Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M65.0488 235.494H244.359V227.968H65.0488V235.494ZM64.5178 236.025H244.89V227.437H64.5178V236.025Z" fill="#E0E0E0"/>
        </svg>

</div> -->


<nz-collapse nzAccordion class="pt-0">
    <nz-collapse-panel *ngFor="let panel of this.videoManager.schedulevideos" [nzHeader]="header"
        [nzActive]="panel.isActive" [nzShowArrow]="false" (nzActiveChange)="changePanel($event,panel)"
        [nzExtra]="extraTpl">

        <div>
            <ng-template #header context="panel">
                <p style="display: inline; font-size: 16px; font-weight: bold;"
                    [ngStyle]="{'color': !panel.isActive ? 'grey' : 'black' }">{{panel.date}}
                    <span class="accord-pill">{{panel.videos.length}}</span>

                </p>
            </ng-template>
            <ng-template #extraTpl context="panel">
                <!-- You can use stopPropagation if you don't want the panel to toggle -->
                <img *ngIf="!panel.isActive" src="../../../assets/images/dashboardVideo/Checkbox Round.svg" width="20"
                    height="20">
                <img *ngIf="panel.isActive" src="../../../../assets/images/dashboardVideo/schedule.svg" width="20"
                    height="20">
            </ng-template>
        </div>
        <div class="schedule-accodion-inner">

            <div class="d-flex flex-wrap scheduled_title ms-0 me-0">
                <div *ngFor="let video of panel.videos;">


                    <div class="video-outer pe-4 pt-3" style="position:relative">
                        <div class="pageloader media-loader-outer" style="width: 285px;
                    min-height: 210px;" *ngIf="video.loadingThumbUrl">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper video_wrapper_full js-videoWrapper"
                            style="width:280px;height: 200px;border: 1px solid #EEEEEE;border-radius: 6px;padding-top: 0px;">

                            <div class="video-tag-container">
                                <p class="video-tag">Scheduled</p>
                            </div>
                            <div class="share-edit">
                                <button id="toggle-slow" class="showSingle" target="1"
                                    (click)="editList(video.title)"><svg width="16" height="16" viewBox="0 0 16 16"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6ZM14 9C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9ZM12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10C12.8954 10 12 9.10457 12 8Z"
                                            fill="#7A7A7A" />
                                    </svg>
                                </button>
                            </div>

                            <ul class="editListActive d-none" [id]="video.title">
                                <!-- <li>Edit Video</li> -->
                                <li (click)="showModal(video)">Change Publish Date</li>
                                <li (click)="delete()">Cancel Publishing</li>
                            </ul>




                            <video controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe"
                                [muted]="true" [src]="video.video_url" [id]="video.fpath" style="height: auto">

                            </video>
                            <span *ngIf="!video.is_hide_duration" class="video-duration mr-2"
                                style="bottom:70px">00:{{video.duration}}</span>
                            <!-- <figure class="video-baner"><img  [src]="video.thumbnail" alt="video1">
                                <span class="video-duration">00:{{video.duration}}</span>
                            </figure> -->
                            <button class="videoPoster js-videoPoster" (click)="videoduration(video)"></button>
                            <a href="javascript:void(0)" (click)="navigateToOverview(video)"
                                class=" text-sm text-gray-2 d-block text-truncate ps-3 pb-2"
                                style="position:absolute; bottom:5px;" [title]="video.title ">{{video.title}}</a>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </nz-collapse-panel>
</nz-collapse>

<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" nzCentered [nzWidth]="1800" [nzBodyStyle]="{height:'94vh'}"
    [nzFooter]=null [nzClosable]=false (nzOnCancel)="handleCancel()">
    <app-scheduled-popup (click)="DashboardHeaderComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup"
        (onClose)="handleCancel()"></app-scheduled-popup>
</nz-modal>