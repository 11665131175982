<app-loader *ngIf="bDisplayLoader"></app-loader>
    <header class="header header-1 drop-shadow" [ngClass]="bFullHeader?'':'width-100'">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="d-flex w-100">
                    <div class="col-4 d-flex align-items-center">
                        <div class="back_cta"> <a href="javascript:void(0)" (click)="DashboardHeaderComponent_NavigateToDashboard()">
                                <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                            </a></div>
                        <h5 class="text-gray-1 pt-3">{{this.videoManager.selectedVideo?.title}}
                            <span class="d-block text-xs text-gray-3 secondaryFont weight-400">{{this.videoManager.selectedVideo?.publish_status==='published'?'Published':'Created'}} {{this.videoManager.selectedVideo?.created | date}}</span>
                        </h5>
                    </div>
                    <div class="col text-end pt-2">

                        <ul class="d-inline-block dashboard-nav" [class.border-right]="bDisplaySchedularBtn">

                            <li *ngIf="sVideoStatus=='draft' && !this.videoManager.selectedVideo.isLocationVideo">
                                <a href="javascript:void(0)" (click)="editVideo()" *ngIf="bIsPublish">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2.06309 2.06315C2.95274 1.17351 4.284 0.833374 5.99998 0.833374H7.33331C7.60946 0.833374 7.83331 1.05723 7.83331 1.33337C7.83331 1.60952 7.60946 1.83337 7.33331 1.83337H5.99998C4.38263 1.83337 3.38055 2.15991 2.7702 2.77026C2.15985 3.38061 1.83331 4.38269 1.83331 6.00004V10C1.83331 11.6174 2.15985 12.6195 2.7702 13.2298C3.38055 13.8402 4.38263 14.1667 5.99998 14.1667H9.99998C11.6173 14.1667 12.6194 13.8402 13.2298 13.2298C13.8401 12.6195 14.1666 11.6174 14.1666 10V8.66671C14.1666 8.39056 14.3905 8.16671 14.6666 8.16671C14.9428 8.16671 15.1666 8.39056 15.1666 8.66671V10C15.1666 11.716 14.8265 13.0473 13.9369 13.9369C13.0472 14.8266 11.716 15.1667 9.99998 15.1667H5.99998C4.284 15.1667 2.95274 14.8266 2.06309 13.9369C1.17345 13.0473 0.833313 11.716 0.833313 10V6.00004C0.833313 4.28406 1.17345 2.9528 2.06309 2.06315Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12.1311 0.675749C12.8734 0.602892 13.6093 0.92885 14.3402 1.6598C15.0712 2.39076 15.3971 3.12664 15.3243 3.86887C15.254 4.58488 14.8222 5.17829 14.3402 5.66024L9.08108 10.9194C8.93095 11.0647 8.73471 11.1916 8.54536 11.2868C8.35713 11.3814 8.13541 11.4648 7.92403 11.495L5.91482 11.782C5.4201 11.8501 4.94675 11.715 4.61465 11.3843C4.28184 11.0528 4.14571 10.5793 4.21848 10.0817C4.21852 10.0814 4.21857 10.0811 4.21862 10.0807L4.50479 8.07759C4.50483 8.0773 4.50487 8.077 4.50491 8.07671C4.53493 7.86255 4.61795 7.63924 4.71324 7.44965C4.80874 7.25966 4.93731 7.06226 5.08643 6.91314L10.3398 1.6598C10.8217 1.17785 11.4151 0.746032 12.1311 0.675749ZM12.2288 1.67097C11.8582 1.70735 11.4716 1.9422 11.0469 2.36691L5.79354 7.62024C5.74266 7.67112 5.67124 7.77039 5.60673 7.89873C5.54216 8.02721 5.50527 8.14355 5.49519 8.21579L5.49496 8.2174L5.2083 10.2241L5.20802 10.226C5.17445 10.4547 5.24169 10.5974 5.32032 10.6758C5.39976 10.7549 5.54604 10.823 5.7773 10.7915C5.7777 10.7915 5.77809 10.7914 5.77849 10.7914L7.78261 10.505C7.85123 10.4952 7.96618 10.4587 8.09628 10.3933C8.22344 10.3294 8.32613 10.2575 8.38318 10.2031L13.6331 4.95314C14.0578 4.52842 14.2927 4.14184 14.329 3.77118C14.3629 3.42675 14.2355 2.96929 13.6331 2.36691C13.0307 1.76453 12.5733 1.63716 12.2288 1.67097Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M9.80498 2.28523C10.0709 2.21069 10.3468 2.36581 10.4214 2.6317C10.8208 4.05645 11.936 5.17276 13.3696 5.57894C13.6353 5.65421 13.7896 5.93062 13.7143 6.1963C13.6391 6.46199 13.3627 6.61634 13.097 6.54106C11.3306 6.04058 9.95243 4.66356 9.45851 2.90163C9.38397 2.63574 9.53909 2.35977 9.80498 2.28523Z"
                                            fill="#7A7A7A" />
                                    </svg>

                                    Edit
                                </a>
                            </li>
                            <li>
                                <a href="javascrip:void(0)" data-bs-toggle="modal" data-bs-target="#del-popup">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.00055 0.998805C6.44823 0.998805 6.00048 1.44599 6.00048 1.99761H10.0008C10.0008 1.44599 9.55304 0.998805 9.00071 0.998805H7.00055ZM11.0009 1.99761C11.0009 0.894361 10.1054 0 9.00071 0H7.00055C5.8959 0 5.0004 0.894361 5.0004 1.99761H2.00016H1.00008H0.50004C0.223875 1.99761 0 2.2212 0 2.49701C0 2.77283 0.223875 2.99642 0.50004 2.99642H1.00008V10.9869C1.00008 13.745 3.23883 15.9809 6.00048 15.9809H10.0008C12.7624 15.9809 15.0012 13.745 15.0012 10.9869V2.99642H15.5012C15.7774 2.99642 16.0013 2.77283 16.0013 2.49701C16.0013 2.2212 15.7774 1.99761 15.5012 1.99761H15.0012H14.0011H11.0009ZM2.00016 10.9869V2.99642H6.00048H10.0008H14.0011V10.9869C14.0011 13.1934 12.2101 14.9821 10.0008 14.9821H6.00048C3.79116 14.9821 2.00016 13.1934 2.00016 10.9869ZM6.00048 11.4863V5.49343C6.00048 5.21762 5.7766 4.99403 5.50044 4.99403C5.22427 4.99403 5.0004 5.21762 5.0004 5.49343V11.4863C5.0004 11.7621 5.22427 11.9857 5.50044 11.9857C5.7766 11.9857 6.00048 11.7621 6.00048 11.4863ZM11.0009 5.49343V11.4863C11.0009 11.7621 10.777 11.9857 10.5008 11.9857C10.2247 11.9857 10.0008 11.7621 10.0008 11.4863V5.49343C10.0008 5.21762 10.2247 4.99403 10.5008 4.99403C10.777 4.99403 11.0009 5.21762 11.0009 5.49343Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Delete
                                </a>

                            </li>
                            <li>
                                <!-- <a routerLink="/dashboard/download" [class.active]="showMenu === '/dashboard/download'"> -->

                                <a routerLink="/dashboard/download">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M7.99933 0.104126C7.72645 0.104042 7.50519 0.324901 7.50512 0.597429L7.50322 7.98262L4.38473 4.55669C4.20117 4.35504 3.90357 4.35504 3.72001 4.55669C3.53645 4.75835 3.53645 5.08529 3.72001 5.28694L7.65821 9.61339C7.84176 9.81504 8.13937 9.81504 8.32293 9.61339L12.2611 5.28694C12.4447 5.08529 12.4447 4.75835 12.2611 4.55669C12.0776 4.35504 11.78 4.35504 11.5964 4.55669L8.4914 7.96781L8.49329 0.597734C8.49336 0.325206 8.2722 0.10421 7.99933 0.104126Z"
                                                fill="#FF8369" />
                                            <path
                                                d="M1.09217 7.21065C1.09217 6.93812 0.870964 6.71719 0.598089 6.71719C0.325213 6.71719 0.104004 6.93812 0.104004 7.21065V8.44418C0.104004 12.5321 3.42215 15.846 7.51528 15.846H8.50542C12.5985 15.846 15.9167 12.5321 15.9167 8.44418V7.21065C15.9167 6.93812 15.6955 6.71719 15.4226 6.71719C15.1497 6.71719 14.9285 6.93812 14.9285 7.21065V8.44418C14.9285 11.987 12.0528 14.8591 8.50542 14.8591H7.51528C3.9679 14.8591 1.09217 11.987 1.09217 8.44418V7.21065Z"
                                                fill="#FF8369" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Download
                                </a>
                            </li>
                            <li>
                                <!-- <a routerLink="/dashboard/socialmedia" [class.active]="showMenu === '/dashboard/socialmedia'"> -->

                                <a routerLink="/dashboard/socialmedia">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M10.8961 3.82797C11.0537 3.60122 11.3653 3.54515 11.592 3.70275C13.0403 4.70933 14.0407 6.31064 14.2437 8.15875C14.2738 8.43324 14.0757 8.6802 13.8012 8.71034C13.5267 8.74048 13.2798 8.54239 13.2496 8.2679C13.0792 6.71602 12.2397 5.37066 11.0213 4.5239C10.7945 4.36631 10.7385 4.05473 10.8961 3.82797Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M5.13491 3.85798C5.29433 4.08346 5.24079 4.39548 5.01532 4.5549C3.80914 5.40776 2.98218 6.75282 2.82405 8.29756C2.79593 8.57227 2.55045 8.77217 2.27574 8.74405C2.00103 8.71593 1.80113 8.47044 1.82925 8.19573C2.0178 6.35381 3.00417 4.7522 4.43799 3.73839C4.66346 3.57897 4.97548 3.63251 5.13491 3.85798Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M5.0143 13.7334C5.13949 13.4873 5.44051 13.3892 5.68664 13.5144C6.39042 13.8724 7.19283 14.0734 8.03997 14.0734C8.85747 14.0734 9.62264 13.8906 10.3078 13.5572C10.5561 13.4363 10.8554 13.5396 10.9762 13.7879C11.0971 14.0362 10.9937 14.3355 10.7455 14.4563C9.92396 14.8562 9.00914 15.0734 8.03997 15.0734C7.03378 15.0734 6.07618 14.8344 5.23329 14.4057C4.98716 14.2805 4.88911 13.9795 5.0143 13.7334Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.03999 1.92664C7.29256 1.92664 6.68665 2.53254 6.68665 3.27997C6.68665 4.02739 7.29256 4.6333 8.03999 4.6333C8.78741 4.6333 9.39332 4.02739 9.39332 3.27997C9.39332 2.53254 8.78741 1.92664 8.03999 1.92664ZM5.68665 3.27997C5.68665 1.98026 6.74028 0.926636 8.03999 0.926636C9.3397 0.926636 10.3933 1.98026 10.3933 3.27997C10.3933 4.57968 9.3397 5.6333 8.03999 5.6333C6.74028 5.6333 5.68665 4.57968 5.68665 3.27997Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M3.21999 10.0734C2.47256 10.0734 1.86665 10.6793 1.86665 11.4267C1.86665 12.1741 2.47256 12.78 3.21999 12.78C3.96741 12.78 4.57332 12.1741 4.57332 11.4267C4.57332 10.6793 3.96741 10.0734 3.21999 10.0734ZM0.866653 11.4267C0.866653 10.127 1.92028 9.07336 3.21999 9.07336C4.5197 9.07336 5.57332 10.127 5.57332 11.4267C5.57332 12.7264 4.5197 13.78 3.21999 13.78C1.92028 13.78 0.866653 12.7264 0.866653 11.4267Z"
                                            fill="#7A7A7A" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12.78 10.0734C12.0326 10.0734 11.4267 10.6793 11.4267 11.4267C11.4267 12.1741 12.0326 12.78 12.78 12.78C13.5275 12.78 14.1334 12.1741 14.1334 11.4267C14.1334 10.6793 13.5275 10.0734 12.78 10.0734ZM10.4267 11.4267C10.4267 10.127 11.4803 9.07336 12.78 9.07336C14.0797 9.07336 15.1334 10.127 15.1334 11.4267C15.1334 12.7264 14.0797 13.78 12.78 13.78C11.4803 13.78 10.4267 12.7264 10.4267 11.4267Z"
                                            fill="#7A7A7A" />
                                    </svg>
                                    Share
                                </a>
                            </li>
                        </ul>
                            <a  *ngIf="bDisplaySchedularBtn" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#unpublish-popup"
                            class="btn text-xs btn-outline-gray-radius text-gray-2 small-pad">
                            <svg class="me-1" *ngIf="sVideoStatus==='published'"
                                width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12.5545 5.01018C12.1836 3.27288 11.2966 2.06528 10.1538 1.36975C8.93674 0.629017 7.48398 0.502103 6.17402 0.856677C4.86405 1.21125 3.65702 2.05806 2.94406 3.31914C2.31037 4.44002 2.09152 5.84504 2.48842 7.43531C-1.04333 8.31153 -0.806193 13.9597 3.1392 14.2488C3.44889 14.2715 3.71777 14.0307 3.73974 13.7109C3.76172 13.3912 3.52848 13.1136 3.21879 13.0909C0.426856 12.8863 0.430601 8.71026 3.20339 8.49221C3.54618 8.48878 3.89229 8.57405 4.20554 8.73881C4.48218 8.88432 4.82069 8.77073 4.96162 8.4851C5.10255 8.19947 4.99253 7.84996 4.71589 7.70446C4.37742 7.52643 4.01067 7.40859 3.63429 7.35857C3.227 5.94232 3.41929 4.78125 3.91505 3.90434C4.45875 2.94266 5.39962 2.26635 6.45914 1.97957C7.51867 1.69278 8.65698 1.80626 9.58288 2.36978C10.3836 2.85713 11.0678 3.70658 11.4021 5.02626C11.1288 5.08989 10.8638 5.18807 10.6104 5.31888C10.3327 5.46224 10.2201 5.81088 10.359 6.0976C10.4978 6.38431 10.8355 6.50052 11.1132 6.35717C11.4229 6.19726 11.7528 6.11089 12.094 6.10155C14.7774 6.52623 15.8614 10.2852 13.797 12.1495L13.7889 12.1569C13.1501 12.7565 12.3192 13.0883 11.4579 13.0817C11.1475 13.0793 10.8939 13.3372 10.8916 13.6577C10.8893 13.9783 11.1391 14.2401 11.4496 14.2425C12.5952 14.2513 13.6965 13.8106 14.5412 13.0196C17.2453 10.5718 15.9637 5.79373 12.5545 5.01018ZM8.68899 12.1612C8.90813 12.3882 8.9075 12.7557 8.68758 12.982L8.0266 13.662L8.68758 14.3421C8.9075 14.5684 8.90813 14.9359 8.68899 15.1629C8.46984 15.39 8.1139 15.3906 7.89398 15.1644L7.23018 14.4814L6.56638 15.1644C6.34645 15.3906 5.99052 15.39 5.77137 15.1629C5.55222 14.9359 5.55285 14.5684 5.77278 14.3421L6.43376 13.662L5.77278 12.982C5.55285 12.7557 5.55222 12.3882 5.77137 12.1612C5.99052 11.9341 6.34645 11.9334 6.56638 12.1597L7.23018 12.8427L7.89398 12.1597C8.1139 11.9334 8.46984 11.9341 8.68899 12.1612Z"
                                    fill="#BABCBE" />
                            </svg>
                            <svg class="me-1" width="17" height="16" viewBox="0 0 17 16" fill="none" *ngIf="sVideoStatus==='draft'"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.9455 1.297C13.4774 0.787123 14.4804 1.00765 14.9904 1.51767C15.501 2.0282 15.7214 3.0324 15.2149 4.56205L15.2147 4.56263L12.8248 11.7216L12.8248 11.7218C12.4343 12.8936 11.9931 13.7334 11.5491 14.2703C11.1037 14.8088 10.7017 14.9918 10.3769 14.9918C10.052 14.9918 9.65002 14.8088 9.20467 14.2703C8.76066 13.7334 8.3195 12.8936 7.92901 11.7218L7.92892 11.7216L7.26682 9.73822L10.2839 6.71726C10.4789 6.52197 10.4785 6.20575 10.2829 6.01096C10.0874 5.81617 9.77078 5.81658 9.57574 6.01187L6.55203 9.03951C6.5218 9.06977 6.49627 9.10295 6.47543 9.13812L4.77474 8.57207L4.77449 8.57199C3.60077 8.18202 2.75971 7.74197 2.22196 7.29918C1.68255 6.85501 1.49965 6.45448 1.49965 6.13104C1.49965 5.80745 1.68271 5.4063 2.22236 4.96099C2.76023 4.51716 3.60134 4.07563 4.77493 3.68362L4.77523 3.68352L11.9455 1.297ZM16.1643 4.87632C16.7217 3.19312 16.6001 1.71383 15.6981 0.811814C14.7955 -0.0907919 13.3154 -0.211819 11.6293 0.349368L4.45853 2.73606L4.45803 2.73623C3.22041 3.14959 2.2531 3.63993 1.58533 4.19096C0.919299 4.74054 0.499512 5.39728 0.499512 6.13104C0.499512 6.86496 0.919458 7.52125 1.58573 8.06987C2.25362 8.61983 3.22099 9.1085 4.45853 9.5197L4.45877 9.51978L6.34974 10.1492L6.98005 12.0373L6.98013 12.0375C7.39199 13.2734 7.88199 14.2394 8.43354 14.9063C8.98377 15.5717 9.64176 15.9906 10.3769 15.9906C11.112 15.9906 11.77 15.5717 12.3202 14.9063C12.8718 14.2394 13.3618 13.2734 13.7736 12.0375L13.7737 12.0373L16.1639 4.87744L16.1643 4.87632Z"
                                            fill="white" />
                            </svg>
                            {{sVideoStatus==='published'?' Unpublish':'Publish'}}
                            </a>
                            <a *ngIf="bDisplaySchedularBtn" href="javascript:void(0)" (click)="showModal()"
                            class=" btn btn-primary btn-round-6 d-inline-block small-pad ms-2"><svg class="me-1"
                                width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 8C0 12.413 3.58698 16 8 16C12.413 16 16 12.413 16 8C16 3.58698 12.413 0 8 0C3.58698 0 0 3.58698 0 8ZM1.11628 8C1.11628 4.20465 4.20465 1.11628 8 1.11628C11.7953 1.11628 14.8837 4.20465 14.8837 8C14.8837 11.7953 11.7953 14.8837 8 14.8837C4.20465 14.8837 1.11628 11.7953 1.11628 8ZM10.5186 10.5867C10.6047 10.6449 10.6979 10.6667 10.7912 10.6667C10.9705 10.6667 11.1499 10.5722 11.2575 10.3977C11.4081 10.136 11.3292 9.80153 11.071 9.64886L8.84713 8.30389C8.62475 8.17303 8.40954 7.78771 8.40954 7.52599V4.54526C8.40954 4.24718 8.16564 4 7.87152 4C7.5774 4 7.3335 4.24718 7.3335 4.54526V7.52599C7.3335 8.17303 7.74239 8.90731 8.29476 9.24173L10.5186 10.5867Z"
                                    fill="#FF8369" />
                            </svg>
                            Schedule Video</a>
                    </div>
                </div>
            </nav>
        </div>
    </header>
     <!-- TimeOut Error -->
     <!-- <div *ngIf="bAlert" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show  col-md-2 m-4" role="alert">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"  xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z" fill="#842029" />
        </svg>
        <div>
            Prontopia  is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"  d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z" fill="#FF8369" />
                </svg>
            </button>
        </div>
    </div>  -->
     <!-- Timeout Error End  -->

<!-- autoCreateModal -->
<!-- <div class="modal fade modalCustom" id="autoCreateModal" tabindex="-1" aria-labelledby="exampleModalLabel"
aria-hidden="true"> -->
<nz-modal
[(nzVisible)]="isVisible"
nzCentered
[nzWidth]="1800"
[nzFooter]=null
[nzClosable] = false
(nzOnCancel)="handleCancel()"
>
         <app-scheduled-popup (click)="DashboardHeaderComponent_GetClickLocation($event)" [lInputClosePopup]="oClosePopup" (onClose)="handleCancel()"></app-scheduled-popup>
        </nz-modal>
         <!-- </div> -->
    <!-- Modal pop-up Start-->
    <div class="modal fade" id="del-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body p-4">
                    <h5>Are you sure you want to delete this Video?</h5>
                    <p class="text-sm text-gray-3 pt-1 pb-4">The video will be permanently deleted from your account.
                    </p>
                    <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        data-bs-dismiss="modal">Cancel</a>
                    <a href="javascript:void(0)" (click)="deleteVideo()" id="sidebarCollapse" data-bs-dismiss="modal" class="mt-3 text-gray-3 text-sm"> Delete</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->
    <!-- Modal pop-up Start-->
    <div class="modal fade" id="unpublish-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body p-4">
                    <h5>Are you sure you want to {{sVideoStatus==='published'?' unpublish':'publish'}} this Video.</h5>
                    <p *ngIf="sVideoStatus==='draft'" class="text-sm text-gray-3 pt-1 pb-4">This video will be published and moved out of your drafts.</p>
                    <p *ngIf="sVideoStatus==='published'" class="text-sm text-gray-3 pt-1 pb-4">You'll be able to edit or republish it from your drafts.</p>

                    <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                        data-bs-dismiss="modal">Cancel</a>
                    <a href="javscript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal" (click)="DashboardHeaderComponent_TogglePublishStatus()"
                        class="mt-3 text-gray-3 text-sm">{{sVideoStatus==='published'?' Unpublish':'Publish'}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal pop-up End-->