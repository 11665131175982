import { Component, Input, OnInit, ElementRef, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { VideoManagerService } from '../../Managers/video-manager.service';
import { video } from '../../models/video';
import { cDataService } from '../../services/data.service';
import { cSessionService } from '../../services/session.service';

import { ErrorService } from './../../services/error.service';
import { AdminService } from 'src/app/services/dataServices/admin.service';
import { SearchData } from 'src/app/models/admin';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { LoaderService } from 'src/app/services/loader.service';
import { MediaService } from 'src/app/services/dataServices/media.service';
@Component({
  selector: 'created-videos',
  templateUrl: './created-videos.component.html',
  styleUrls: ['./created-videos.component.scss']
})
export class CreatedVideosComponent implements OnInit, OnDestroy {
  @Input() sInputVideoStatus: string;
  @Input() status: string;
  @Input() isLocations: boolean = false;
  @Input() title: string;
  @Input() displayCountSection: boolean = true;
  @Input() dashboardPublished: boolean = false;

  @Output() bEmittedDisplayLoader = new EventEmitter<boolean>();
  lPublishedVideos: any = [];
  fSearchVideo: FormGroup;
  isSearchboxOpen = false;
  public videoList: video[] = [];
  videoListForm: FormGroup;
  lVideoList: any = [];
  sSessionId: string;
  sUserEmail: string;

  sSelectedVideoId: string;
  bDisplayCreateVideoSection: boolean = false;
  lTemplateCategory: any = [];
  lTemplateNames: any = [];
  bAlert: boolean = false;
  oSetTime: any;
  bIsPublish: boolean = false;
  sSelectedSortingCategory: string = "Last Modified";
  lSortBy: any = [{ name: 'Last Modified', value: 'last_modified' }, { name: 'Last Created', value: 'last_created' }];
  oSearchVideoSubscription: any;
  bPublishedEmptyScreenView: boolean = false;
  customGridLayout: string;
  addCustomClass: string;
  thumbListView: boolean = false;
  session: cSessionService;
  

  currentPage = 1;
  offset = 0;
  limit = 20;
  searchBy = "_id";
  sortType = "created";
  searchTerm = "";
  asc = -1;
  public publishedVideos: video[];
  listPages: number[] = [];
  showSortByDD = false;
  searchTermText = "";

  constructor(
    public videoManager: VideoManagerService,
    public createVideoManger: CreateVideoManagerService,
    public oSessionService: cSessionService,
    public adminService: AdminService,
    private el: ElementRef,
    private oFormBuilder: FormBuilder,
    private oRouter: Router,
    private mediaManger: MediaManagerService,
    private oDataService: cDataService, public errorService: ErrorService,
    private loader: LoaderService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    public mediaService: MediaService) {
    this.session = oSessionService;
    if (this.oRouter.url === '/dashboard/myvideos') {

      this.bPublishedEmptyScreenView = true;
      this.addCustomClass = 'custom-empty-state'
    }
    if (this.oRouter.url === '/account-setting/published-videos') {
      this.customGridLayout = 'custom-grid-list-view'
    }
    else {
      this.customGridLayout = ''
    }
  }
  search: string = "";
  sub: Subscription;

  populatePagination() {
    if (this.videoCount && this.videoCount > 0) {
      const maxPage = Math.ceil(this.videoCount / this.limit) || 1;
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
      } else {
        this.listPages = [1];
      }
    }
  }

  loadPageEvent($event) {
    console.log($event);
    if ($event.pageSize != this.limit) {
      this.limit = $event.pageSize;
      this.loadPages(1);
    } else {
      this.loadPages($event.pageIndex + 1);
    }
  }

  loadPages(page: number) {
    if (page < 1) {
      page = 1;
    } else {
      if (page > this.listPages.length) {
        page = 1;
      }
      this.videoManager.videos = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.getPublishedVideos();
    }
  }
  ngOnInit(): void {


    this._MatPaginatorIntl.firstPageLabel = '';
    this._MatPaginatorIntl.lastPageLabel = '';
    this._MatPaginatorIntl.nextPageLabel = '';
    this._MatPaginatorIntl.previousPageLabel = '';

    this.fSearchVideo = this.oFormBuilder.group({ title: [""] });
    this.search = '';
    this.sub = this.videoManager.search.subscribe(search => {
      this.search = search;
      this.searchVideo(search, 18, 0);
    });
    this.session.dashboardVideoGridView = true;
    this.session.dashboardVideoListView = false;
    // this.InitializeJqueryCode()
    //this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    //this.getvideoCount();
    // this.fetchVideos(18,0);
    this.getPublishedVideos();


  }

  getPublishedVideos() {

    this.loader.showLoader();
    this.adminService.getPublishVideos(this.sUserEmail, this.offset, this.limit, this.searchBy, this.sortType, this.searchTerm, this.asc).then((res: SearchData) => {

      if (res.result) {

        console.log("chkres11", res.result);
        this.publishedVideos = [];
        this.videoManager.videos = [];
        const _this = this;

        res.result.map((element: any) => {
          console.log("element", element);

          element.video_url = element.public_url;
          element.last_modified = element.last_modified;
          element.created = element.created?.$date;
          element.owner = element?.owner;
          element.thumbnail = element?.thumbnail_path_signed_url;
          element.video_url = element?.fpath_signed_url;
          
          // element.video.thumbnail = undefined;
          // element.fpath = element?.video.thumbnail_path;
          element.publish_status = element?.publish_status;
          if (element.thumb == undefined) {
            element.thumb = element?.thumbnail_path;
          }
          _this.publishedVideos.push(element);
          _this.videoManager.videos.push(element);
        });
        // let batchUrl = [];
        // _this.videoManager.videos.forEach(vid => {

        //   vid.loadingUrl = true;
        //   vid.loadingThumbUrl = true;
        //   if (vid.fpath != '') {
        //     batchUrl.push(vid.fpath);
        //   }
        //   if (vid.thumb != '')
        //     batchUrl.push(vid.thumb);

        // });

      //   console.log("chkres22", _this.videoManager.videos);
      //   this.mediaService.getBatchSignedURL(
      //     _this.sUserEmail,
      //     _this.sSessionId,
      //     batchUrl
      //   ).then(()=>{
      //     _this.videoManager.videos.forEach(vid => {

      //       vid.loadingUrl = false;
      //       vid.loadingThumbUrl = false;
      //   });
      // });
      }

      // console.log("chkres222", this.videoManager.videos);
      this.loader.hideLoader();
      this.bPublishedEmptyScreenView = false;
      this.videoCount = res['total_count'];
      setTimeout(() => {
        this.populatePagination();
      }, 200);
    }).catch(err => {
      this.errorService.handleError(err, err.errorType, true);
    });

  }

  searchPublishVideo() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.getPublishedVideos();
    // this.searchTermText = '';
  }
  clearSearchTerm() {
    this.searchTerm = "";
    this.searchTermText = "";
    this.getPublishedVideos();
  }


  sortBySearchResult(value) {
    this.offset = 0;
    this.searchTerm = '';
    this.sortType = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.getPublishedVideos();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }
  }
  videoCount = 0;
  getvideoCount() {
    this.videoManager.getUserVideoCount(this.sUserEmail, this.sSessionId).then(res => {

      if (this.title.toLowerCase() == 'published') {
        this.videoCount = res['published_video_count'];
      } else if (this.title.toLowerCase() == 'draft') {
        this.videoCount = res['draft_video_count'];
      } else if (this.title.toLowerCase() == 'scheduled') {
        this.videoCount = res['scheduled_video_count'];
      } else if (this.title.toLowerCase() == 'location') {

      } else {
        this.videoCount = res['scheduled_video_count'] + res['scheduled_video_count'] + res['published_video_count'];
      }
      this.createPagination();

    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);

    });
  }
  private fetchVideos(num_items: number = 2, num_skip: number = 0) {



    console.log(this.isLocations);
    this.videoManager.getVideos(this.sUserEmail, this.sSessionId, this.isLocations, this.sInputVideoStatus, this.sSelectedSortingCategory, num_items, num_skip).then(res => {

      this.bPublishedEmptyScreenView = false;

      this.videoCount = res['total_count'];
      this.createPagination();





    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);

    });
  }


  private searchVideo(search, num_items: number = 18, num_skip: number = 0) {
    this.videoManager.searchVideos(this.sUserEmail, this.sSessionId, search, this.isLocations, this.sInputVideoStatus, this.sSelectedSortingCategory, num_items, num_skip).then(res => {


      this.videoCount = res['count'];

      this.bPublishedEmptyScreenView = false;


      this.createPagination();
    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);

    });
  }

  ngOnDestroy() {

    if (this.sub) {
      this.sub.unsubscribe();
    }
    //  this.oSearchVideoSubscription.unsubscribe(); 


  }
  CreatedVideosComponent_SortVideos(oIncommingSortingFilter: any) {

    if (this.sSelectedSortingCategory != oIncommingSortingFilter.name) {
      this.sSelectedSortingCategory = oIncommingSortingFilter.name;
      this.getVideos(0);
    }
  }
  CreatedVideosComponent_PlayVideo(nIncommingVideoId) {
    this.sSelectedVideoId = nIncommingVideoId;
    this.lPublishedVideos.forEach(element => {
      var video = <HTMLVideoElement>document.getElementById(element.id);
      if (element.id == nIncommingVideoId) {
        video.play();
      }
      else {
        video.pause();
      }
    });
  }
  editVideo(video: video) {

    console.log("edit video");
    this.videoManager.selectedVideo = video;
    this.createVideoManger.setEditVideo(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo);
    this.oRouter.navigate(['wizard/overview']);

  }

  nCurrentPage: number = 1;
  lPagination: number[] = [];
  nPageSize: number = 18;
  loadPage(pageNumber: number) {
    if (pageNumber !== this.nCurrentPage) {
      this.nCurrentPage = pageNumber;
      let skipVideos = (pageNumber - 1) * this.nPageSize;
      this.getVideos(skipVideos);
    }
  }
  private getVideos(skipVideos: number) {
    if (this.search == '') {
      this.videoManager.getVideos(this.sUserEmail, this.sSessionId, this.isLocations, this.sInputVideoStatus, this.sSelectedSortingCategory, this.nPageSize, skipVideos).then(res => {

      }).catch(err => {

        this.errorService.handleError(err.errorMessage, err.errorType, true);

      });
    }
    else {
      this.videoManager.searchVideos(this.sUserEmail, this.sSessionId, this.search, this.isLocations, this.sInputVideoStatus, this.sSelectedSortingCategory, this.nPageSize, skipVideos).then(res => {

      }).catch(err => {

        this.errorService.handleError(err.errorMessage, err.errorType, true);

      });
    }
  }

  createPagination() {

    this.lPagination = [];
    let pages = Math.ceil(this.videoCount / this.nPageSize);
    for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) {
      this.lPagination.push(nPaginationIndex);
    }
    console.log("Pagination List : ==> ", this.lPagination);
  }

  navigateToCreateVideo() {
    this.videoManager.selectedVideo = undefined;
    this.createVideoManger.newVideo();
    this.oRouter.navigate(['wizard/overview']);
  }
  navigateToOverview(video: video) {
    // this.videoManager.selectedVideo = video;
    // this.createVideoManger.setEditVideo(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo);

    // this.oRouter.navigate(['/dashboard/overview']);
  }
  navigateToShare(video: video) {
    this.videoManager.selectedVideo = video;
    this.oRouter.navigate(['/dashboard/socialmedia']);
  }

  selectedDuplicateVideo: any;
  selectDuplicateVideo(video: any) {
    this.selectedDuplicateVideo = video;
    console.log(this.selectedDuplicateVideo);



  }

  duplicateVideo() {
    this.videoManager.cloneVideo(this.sUserEmail, this.sSessionId, this.selectedDuplicateVideo.fpath).then(rs => {
      this.videoCount++;
    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);

    });
  }


  CreateDuplicateVideoResponse = (InComingMessage) => {
    console.log(InComingMessage);
  }


  gridView() {
    this.thumbListView = false;
    console.log("grid click");

    document.querySelector('#container-view div.row').classList.remove("list");
    document.querySelector('#container-view div.row').classList.add("grid");
    document.getElementById("gridView").classList.add("active");
    document.getElementById("listView").classList.remove("active");
    this.session.dashboardVideoGridView = true;
    this.session.dashboardVideoListView = false;

  }
  listView() {
    this.thumbListView = true;
    console.log("list click");

    document.getElementById("cardListing").classList.remove("grid");
    document.getElementById("cardListing").classList.add("list");
    document.getElementById("listView").classList.add("active");
    document.getElementById("gridView").classList.remove("active");
    this.session.dashboardVideoListView = true;
    this.session.dashboardVideoGridView = false;
  }

  buttonUp() {
    this.isSearchboxOpen = true;
  }

}