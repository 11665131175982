
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { VideoManagerService } from '../../../Managers/video-manager.service';
import { ErrorService } from './../../../services/error.service';

@Component({
  selector: 'app-timeline-view-pronto',
  templateUrl: './timeline-view-pronto.component.html',
  styleUrls: ['./timeline-view-pronto.component.scss']
})
export class TimelineViewProntoComponent implements OnInit {

  oGeneratedVideo : any;

  constructor(private videoManager:VideoManagerService,public errorService:ErrorService,public loader:LoaderService) { }

  ngOnInit(): void 
  {
    this.oGeneratedVideo = this.videoManager.selectedVideo;
  }
  TimelineViewProntoComponent_DownloadVideo()
  {    
  this.loader.showLoader();
    fetch( this.videoManager.selectedVideo.video_url)
    .then(response => response.blob())
    .then(blob => 
    {

      this.loader.hideLoader();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "pronto.mp4";
      link.click();
    })
    .catch(error=>
    {
       this.loader.hideLoader();
      console.error("TimelineViewProntoComponent_DownloadVideo : ==>",error);
    });
  }
}
