<!-- <app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
  <div class="container d-flex flex-column h-100">
    <ul class="breadcrumb my-3">
      <li><a href="#">Manage Account</a></li>
      <li>Manage Brand</li>
    </ul>
    <div
      class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden"
      id="row-main"
    >
      <app-account-sidebar
        class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
        id="sidebar-left"
      ></app-account-sidebar>
      <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content"> -->

<div class="video-header row m-0 ms-3 px-4 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
          fill="#404243" />
      </svg>
    </a>
    <span>{{
      this.brandDetail.name ? this.brandDetail.name : "Brand Name"
      }}</span>
  </p>
</div>
<div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
  <div class="row filter-post">
    <div class="col-md-12 p-0">
      <ul class="breadcrumb-tabs nav m-0">
        <li class="nav-item ps-0">
          <a href="javascript:void(0)" class="nav-link active">Account</a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandBusiness()" class="nav-link">Business Details</a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadBrandMediaLibraryList()" class="nav-link">Media Libraries</a>
        </li>
        <!-- <li class="nav-item">
                  <a href="javascript:void(0)" (click)="loadBrandMediaLibrary()"
                    class="nav-link"
                    >Media Libraries</a
                  >
                </li> -->
      </ul>
    </div>
  </div>
</div>
<div class="px-4 flex-grow-1 ps-0">
  <div class="" id="container-view">
    <div class="">
      <div class="tab-pane fade show active pb-5">
        <div class="content_area pb-0 ps-0">
          <div class="row p-0 m-0">
            <div class="col-md-4">
              <div class="formItm f-left relative">
                <input id="brandName" type="text" placeholder="Brand Name" [(ngModel)]="brandName" required
                  [disabled]="!this.isEdit" />
                <!-- <label *ngIf="errorBrandEmptry">Please enter a brand name</label> -->
                <label for="brandName" class="text-sm">Brand Name</label>

                <a *ngIf="!this.isEdit" href="javascript:void(0)" (click)="Change()"
                  class="btn text-xs d-inline-block btn-outline-gray-radius text-gray-2 btn-change">Change</a>
                <a *ngIf="this.isEdit" href="javascript:void(0)" (click)="saveBrandDetail()"
                  class="btn btn-secondary btn-round-6 d-inline-block ms-5 text-xs btn-change">Change</a>
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>

          <!-- <div class="row p-0 m-0 mt-3">
            <div class="col-md-6 px-4">
              <div class="row mt-1 ac-overview">
                <div class="col-md-10 px-0">
                  <p class="text-lg text-gray-2 pb-0 mb-2">
                    Email Preferences
                  </p>
                  <p class="text-xs text-gray-3 pb-1">
                    The choice is yours. Decide if you'd like to receive
                    emails from us.
                  </p>
                </div>
                <div class="col-md-2 text-end px-0 pe-4">
                  <a href="javascript:void(0)"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btnShadow">Unsbscribe</a>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="mt-4 px-3">
          <div class="content_area py-0 p-0">
            <div class="row mx-0 align-items-center">
              <div class="col-md-4 ps-0">
                <h6 class="weight-400 mb-2">Users</h6>
                <p class="text-xs m-0 text-gray-3">
                  Total Users <b> {{ this.brandUserList.length }}</b>
                </p>
              </div>
              <div class="col px-0 text-end">
                <button (click)="openAssignNewUserDrawer()"
                  class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-5 text-xs"
                  data-sidebar="#assignNewUser" data-custom-select>
                  Assign New User
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="table tableCustom table-hover mt-3">
          <thead>
            <tr>
              <th class="tableCheck px-4">
                <div class="custom-switch">
                  <input type="checkbox" class="check form-check-input" id="checkAll" />
                </div>
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>Brands</th>
              <th>Published Videos</th>
              <th>Media Uploads</th>
              <th>Last Login</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of brandUserList">
              <td class="tableCheck px-4">
                <div class="custom-switch">
                  <input type="checkbox" class="check form-check-input" id="checkAll" />
                </div>
              </td>
              <td>
                <a href="javascript:void(0)" [routerLink]="[
                    '/account-setting/user-account/',
                    list.user?._id
                  ]">{{
                  list?.user?.username
                  ? list?.user?.username
                  : list?.user?.first_name
                  }}</a>
              </td>
              <td>{{ list?.user_id }}</td>
              <td>{{ list.user?.brand_count }}</td>
              <td>{{ list.user?.published_video_count }}</td>
              <td>
                {{ list.user?.media_uploads ? list.user?.media_uploads : "0" }}
              </td>
              <td>
                {{
                list.user?.last_login?.$date
                ? list.user?.last_login?.$date
                : "Never"
                }}
              </td>
              <td>
                <div class="dropdown dropdownAction">
                  <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.5 12C1.5 12.8284 2.17157 13.5 3 13.5C3.82843 13.5 4.5 12.8284 4.5 12C4.5 11.1716 3.82843 10.5 3 10.5C2.17157 10.5 1.5 11.1716 1.5 12ZM3 9C1.34315 9 0 10.3431 0 12C0 13.6569 1.34315 15 3 15C4.65685 15 6 13.6569 6 12C6 10.3431 4.65685 9 3 9ZM10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM21 13.5C20.1716 13.5 19.5 12.8284 19.5 12C19.5 11.1716 20.1716 10.5 21 10.5C21.8284 10.5 22.5 11.1716 22.5 12C22.5 12.8284 21.8284 13.5 21 13.5ZM18 12C18 10.3431 19.3431 9 21 9C22.6569 9 24 10.3431 24 12C24 13.6569 22.6569 15 21 15C19.3431 15 18 13.6569 18 12Z"
                        fill="#7A7A7A"></path>
                    </svg>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                    <li>
                      <a class="dropdown-item text-md" (click)="showDeleteUserModel(list?._id?.$oid)">Delete</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="pb-3" style="border-bottom: 2px solid #eeee">
  <p class="text-lg text-gray-2 pb-0 mb-2 pb-3">Connected Social Account</p>
  <div class="d-flex justify-content-between pe-5">
    <div class="d-flex select-channels social-channels-hover col-lg-6">
      <img width="40px" height="40px" src="../assets/images/dashboardVideo/Facebook.png" alt="sc" />
      <div class="row w-100">
        <div class="col-lg-6 px-3 ps-4">
          <b class="d-block">Facebook
            <img class="ms-2" *ngIf="this.socialConnectData.isFBConnected" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="this.socialConnectData.isFBConnected" class="text-xs text-gray-3 d-block">
            Your Facebook account is connected to
            <a class="text-primary-1">{{
              this.brandDetail.name ? this.brandDetail.name : "Brand Name"
              }}</a>
          </span>
          <span *ngIf="!this.socialConnectData.isFBConnected" class="text-xs text-gray-3 d-block">
            Your Facebook account is not connected to
            <a class="text-primary-1">{{
              this.brandDetail.name ? this.brandDetail.name : "Brand Name"
              }}</a>
          </span>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-secondary btn-round-6 text-xs w-172 small-pad ms-2"
            *ngIf="!this.socialConnectData.isFBConnected" (click)="showFacebookTokenInput = true">
            Connect
          </button>
          <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow ms-2"
            *ngIf="this.socialConnectData.isFBConnected" (click)="saveFacebookAccessToken(true)">
            Disconnect
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex select-channels social-channels-hover col-lg-6">
      <img width="40px" height="40px" src="../assets/images/dashboardVideo/Youtube.png" alt="sc" />
      <div class="row w-100">
        <div class="col-lg-6 px-3 ps-4">
          <b class="d-block">Youtube
            <img class="ms-2" *ngIf="socialConnectData.isYTConnected" width="12px" height="12px"
              src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc" /></b>
          <span *ngIf="socialConnectData.isYTConnected" class="text-xs text-gray-3 d-block">Your Youtube account is
            connected to
            <a class="text-primary-1"> {{ this.brandDetail.name ? this.brandDetail.name : "Brand Name" }}</a></span>
          <span *ngIf="!socialConnectData.isYTConnected" class="text-xs text-gray-3 d-block">Your Youtube account is
            not connected to
            <a class="text-primary-1"> {{ this.brandDetail.name ? this.brandDetail.name : "Brand Name" }}</a></span>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-secondary btn-round-6 text-xs w-172 small-pad ms-3"
            *ngIf="!this.socialConnectData.isYTConnected" (click)="this.socialConnectData.showYTInputDialog = true">
            Connect
          </button>
          <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow ms-3"
            *ngIf="this.socialConnectData.isYTConnected" (click)="saveYouTubeAccessToken(true)">
            Disconnect
          </button>
        </div>
      </div>
    </div>

    <!-- Linkedin Panel -->
  </div>


</div>

<!-- LinkedIn Account Connect -->
<div class="d-flex justify-content-between pe-5 pt-4">
  <div class="d-flex select-channels social-channels-hover col-lg-6">
    <img src="../assets/images/dashboardVideo/Linkedin.png" alt="Linkedin" height="40px" width="40px" class="s-icon">
    <div class="row w-100">
      <div class="col-lg-6 px-3 ps-4">
        <b class="d-block fw-bolder">LinkedIn<img class="ms-2"
            *ngIf="socialConnectData && socialConnectData.isLinkedInConnected" width="12px" height="12px"
            src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
        <span *ngIf="socialConnectData && socialConnectData.isLinkedInConnected" class="text-xs text-primary-1 d-block">
          Your LinkedIn account is connected to <span *ngIf="socialConnectData && socialConnectData.isLinkedInConnected"
            class="text-xs text-primary-1">{{ this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</span>
        </span>
        <span *ngIf="socialConnectData && !socialConnectData.isLinkedInConnected">
          Your LinkedIn account is
          not connected to <a class="text-primary-1">{{ this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</a>.
        </span>

      </div>
      <div class="col-lg-6">
        <a *ngIf="socialConnectData && !socialConnectData.isLinkedInConnected" href="javascript:void(0)"
          (click)=" socialConnectData.showLinkedInConnectModal = true"
          class=" btn btn-secondary btn-round-6 text-xs w-172 small-pad ms-2">Connect</a>
        <a *ngIf="socialConnectData && socialConnectData.isLinkedInConnected" href="javascript:void(0)"
          (click)="saveLinkedInAccessToken(true)"
          class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow ms-2">Disconnect</a>
      </div>
    </div>
  </div>
  <div class="d-flex select-channels social-channels-hover col-lg-6">
    <img src="../assets/images/dashboardVideo/Instagram.png" alt="Instagram" height="40px" width="40px" class="s-icon">
    <div class="row w-100">
      <div class="col-lg-6 px-3 ps-4">
        <b class="d-block fw-bolder">Instagram
          <img class="ms-2" *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" width="12px"
            height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
        <span *ngIf="socialConnectData && socialConnectData.isIsntagramConnected"
          class="text-xs text-primary-1 d-block">
          Your Instagram account is connected to <span
            *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" class="text-xs text-primary-1">{{
            this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</span>
        </span>
        <span *ngIf="socialConnectData && !socialConnectData.isIsntagramConnected" class="text-xs">
          Your Instagram account is
          not connected to <a class="text-primary-1">{{ this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</a>.
        </span>

      </div>
      <div class="col-lg-6">
        <a *ngIf="socialConnectData && !socialConnectData.isIsntagramConnected" href="javascript:void(0)"
          (click)=" socialConnectData.showInstagramConnectModal = true"
          class=" btn btn-secondary btn-round-6 text-xs w-172 small-pad ms-3">Connect</a>
        <a *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" href="javascript:void(0)"
          (click)="updateInstagramAccessToken(true)"
          class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow ms-3">Disconnect</a>
      </div>
    </div>
  </div>

  <!-- <div class="d-flex select-channels social-channels-hover col-lg-6">
    <img src="../assets/images/dashboardVideo/Instagram.png" alt="Instagram" height="40px" width="40px" class="s-icon">
    <div class="px-5">
      <div class="col-lg-6 px-3 ps-4">
        <b class="d-block fw-bolder">Instagram
          <img *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" width="12px" height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
        <span *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" class="text-xs text-primary-1 d-block">
          Your Instagram account is connected to <span *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" class="text-xs text-primary-1">{{ this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</span>
        </span>
        <span *ngIf="socialConnectData && !socialConnectData.isIsntagramConnected">
          Your Instagram account is
          not connected to <a class="text-primary-1">{{ this.brandDetail.name ? this.brandDetail.name : "Brand Name"
            }}</a>.
        </span>

      </div>
      <div class="col-lg-6">
        <a *ngIf="socialConnectData && !socialConnectData.isIsntagramConnected" href="javascript:void(0)" (click)=" socialConnectData.showInstagramConnectModal = true" class=" btn btn-secondary btn-round-6 text-xs w-172 small-pad ms-4">Connect</a>
        <a *ngIf="socialConnectData && socialConnectData.isIsntagramConnected" href="javascript:void(0)" (click)="updateInstagramAccessToken(true)" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow ms-4">Disconnect</a>
      </div>
    </div>
  </div> -->
</div>


<!-- Instagram Account Connect -->

<!-- <div class="row my-3 ms-2">
  <div class="col-md-6 address-sec">
    <p class="text-lg text-gray-2 pt-3 mb-1">Address</p>
    <span class="text-xs text-gray-3 d-block">
      Family, Chiropractic center of Richmond 123 Main Street City, State 0000
    </span>
  </div>
</div> -->


<!-- </div>
    </div>
  </div>
</section> -->
<div class="overlay sidebarOverlay"></div>
<div class="overlay sidebarOverlay2"></div>

<nz-drawer [nzClosable]="false" [nzVisible]="showAssignNewUserDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeAssignNewUserDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <!-- <nav id="assignNewUser" class="rightSideBar rightSideBarRight sidebar-block"> -->
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Assign new user</h3>
        <div class="closeRightSideBar" (click)="closeAssignNewUserDrawer()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
              fill="#F2F2F5" />
            <path
              d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
              fill="#A7ABB6" />
          </svg>
        </div>
      </div>
      <form [formGroup]="assignUserForm" *ngIf="this.assignUserForm">
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
          <div class="formItm">
            <input type="text" value="{{
                this.brandDetail.name ? this.brandDetail.name : 'Brand Name'
              }}" placeholder="brandName" name="textfeild" disabled required="" />
            <label for="brandName" class="text-sm">Brand Name</label>
          </div>
          <div class="formItm down_arrow pt-0">
            <p class="text-md text-gray-3 mb-0">Select user</p>

            <!-- <select class="form-select" aria-label="Default select example"
    (change) = "getUserId($any($event.target).value)" formControlName="user">
    <option value="default" disabled>please select user</option>
      <option value="{{users._id}}" *ngFor="let users of userList">{{users.first_name !=''? users.first_name: users._id}}</option>
    </select> -->

            <div class="lib-access">
              <!-- <p class="weight-600 my-4">Assign Users</p> -->
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3">
                  <!-- Search Users -->
                  <mat-form-field>
                    <mat-label style="position: absolute;">Search users</mat-label>
                    <input formControlName="searchUserText" #userListAutoComplete #searchUserField
                      placeholder="Search users" class="searchBrandAutoComplete"
                      (ngModelChange)="this.modelUserSearchChanged.next($event)" matInput
                      [matAutocomplete]="autoCompleteUser" />
                    <mat-autocomplete #autoCompleteUser="matAutocomplete">
                      <mat-option *ngIf="isUserSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isUserSearchLoading">
                        <mat-option *ngFor="let user of userList.result" [value]="user?._id"
                          (click)="getUserId(user._id)">
                          <span><b>{{ user?._id }}</b></span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                </p>
                <div>
                  <div>{{ userId }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side-footer side-content d-flex align-items-center ps-0" style="position: absolute; bottom: 30px">
          <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5" (click)="assignUserBrand()">
            <span class="py-1 d-block">Assign User</span>
          </button>
          <!-- <button class="d-inline-block small-pad text-gray-2 font-weight-noral text-md ms-3">
                  Deactivate User
              </button> -->
        </div>
      </form>
    </nav>
  </ng-container>
</nz-drawer>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="showDeleteUserModal" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
  (nzOnCancel)="showDeleteUserModal = false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="showDeleteUserModal = false"></button>

        <h5>Are you sure you want to Delete this User?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          You'll not be able to watch this User again.
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          (click)="showDeleteUserModal = false">Cancel</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm" (click)="deleteUser()">Delete</a>
      </div>
    </div>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="showFacebookTokenInput" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
  (nzOnCancel)="showFacebookTokenInput = false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn"
          (click)="showFacebookTokenInput = false"></button>

        <h5>Enter Facebook Access Token</h5>
        <!-- <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span>You'll not be able to watch this User again.</span>
        </p> -->
        <p>
          <input type="text" placeholder="Enter Facebook Access Token" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.fbAccessToken" />
        </p>
        <p>
          <input type="text" placeholder="Enter Brand Page Id" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.fbPageId" />
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          (click)="saveFacebookAccessToken()">Save</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm"
          (click)="showFacebookTokenInput = false">Cancel</a>
      </div>
    </div>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="socialConnectData.showLinkedInConnectModal" nzCentered [nzWidth]="566" [nzFooter]="null"
  [nzClosable]="false" (nzOnCancel)="showFacebookTokenInput = false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn"
          (click)="socialConnectData.showLinkedInConnectModal = false"></button>

        <h5>Enter LinkedIn Access Token</h5>
        <!-- <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span>You'll not be able to watch this User again.</span>
        </p> -->
        <p>
          <input type="text" placeholder="Enter LinkedIn Client Id" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.linkedInClientId" />
        </p>
        <p>
          <input type="text" placeholder="Enter LinkedIn Client Secret" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.linkedInClientSecret" />
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          (click)="saveLinkedInAccessToken()">Save</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm"
          (click)="showFacebookTokenInput = false">Cancel</a>
      </div>
    </div>
  </div>
</nz-modal>
<!-- Instagram Modal Form -->
<nz-modal [(nzVisible)]="socialConnectData.showInstagramConnectModal" nzCentered [nzWidth]="566" [nzFooter]="null"
  [nzClosable]="false" (nzOnCancel)="socialConnectData.showInstagramConnectModal = false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn"
          (click)="socialConnectData.showInstagramConnectModal = false"></button>

        <h5>Enter Instagram Access Token</h5>
        <!-- <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span>You'll not be able to watch this User again.</span>
        </p> -->
        <p>
          <input type="text" placeholder="Enter Instagram Page Id" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.instagramPageId" />
        </p>
        <p>
          <input type="text" placeholder="Enter Instagram Access Token" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.instagramAccessToken" />
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
          (click)="updateInstagramAccessToken()">Save</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm"
          (click)="socialConnectData.showInstagramConnectModal = false">Cancel</a>
      </div>
    </div>
  </div>
</nz-modal>


<nz-modal [(nzVisible)]="socialConnectData.showYTInputDialog" nzCentered [nzWidth]="566" [nzFooter]="null"
  [nzClosable]="false" (nzOnCancel)="socialConnectData.showYTInputDialog = false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn"
          (click)="socialConnectData.showYTInputDialog = false"></button>

        <h5>Enter Youtube Account Information</h5>
        <!-- <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          <span>You'll not be able to watch this User again.</span>
        </p> -->
        <!-- <p>

          <input type="text" placeholder="Enter Youtube Client Id" class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.youtubeClientId" />
        </p> -->
        <p>
          <input class="" style="opacity:1" type="file" #file accept="application/json"
            (change)="uploadYouTubeSecretFile($event)" />
          <!-- <Label>Client Secret</Label> -->
          <input type="text" readonly class="w-100 input-txt pb-2"
            [(ngModel)]="socialConnectData.youtubeClientSecret" />
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="saveYouTubeAccessToken()">Save</a>
        <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm"
          (click)="socialConnectData.showYTInputDialog = false">Cancel</a>
      </div>
    </div>
  </div>
</nz-modal>