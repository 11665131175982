
<!-- Create video Header -->
<header class="header header-1 drop-shadow" *ngIf="bCreateVideoMode">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <div class="d-flex w-100">
                <div class="col d-flex align-items-center">
                    <div class="back_cta">
                        <a href="javascript:void(0)" (click)="FTUEOverviewComponent_NavigateToUserLogin()">
                            <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                        </a>
                    </div>
                    <h3 class="text-gray-1 mb-0">Create a Video</h3>
                </div>
                <div class="col text-end">
                    <a class="text-gray-6 text-sm me-4" (click)="FTUEOverviewComponent_NavigateToLogin()">Cancel</a>
                    <a (click)="CreateVideoOverviewComponent_SendOverviewRequest()" class="btn btn-md btn-secondary btn-round-6 ">Next</a>
                </div>
            </div>

        </nav>
    </div>
</header>
<!-- Edit video Header -->
<!-- <app-edit-video-header *ngIf="!bCreateVideoMode" (bEmittedSendUpdateVideoRequest)="CreateVideoOverviewComponent_UpdateVideo($event)"></app-edit-video-header> -->

<section class="accountSec main-body bg-gray-7 h-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
               <app-ftue-create-video-breadcrumb></app-ftue-create-video-breadcrumb>
            </div>
            <!-- <div class="col-md-6" *ngIf="!bCreateVideoMode">
                <div class="justify-content-end d-flex">
                    <div class="pt-0 form-check form-switch custom-switch d-flex align-items-center toggle_switch">
                        <input checked class="form-check-input mx-2 order-2 form-check-input-active-both" type="checkbox" id="MyMediaLibrary" (change)="CreateVideoOverviewComponent_ToggleView($event)">
                        <label class="text-md order-1" for="MyMediaLibrary">Timeline</label>
                        <label class="text-md order-3 text-gray-6" for="MyMediaLibrary">Wizard</label>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow  m-0 p-0" id="pills-tabContent">
            <div class="tab-pane fade show active">
                <div class="content_area p-0">
                    <div class="row m-0">
                        <div class="col-sm-12 col-md-12 col-lg-5 h-80vh ps-5 pt-4">
                            <div class="row ps-4 pt-3 mt-1">
                                <form [formGroup]="fOverviewForm">
                                    <div class="col-sm-12">
                                        <div class="formItm static_lable order_list pt-0">
                                            <label for="new-video"><span class="counts">1.</span>What would you like to call your new video?</label>
                                            <input id="new-video" type="text" placeholder="" name="textfeild" formControlName="title">
                                            <span *ngIf="fOverviewForm.controls['title'].hasError('required') && fOverviewForm.controls['title'].touched" class="error">This field is required.</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="formItm static_lable order_list mb-2">
                                            <label for="aboutvideo" class="d-flex align-items-center">
                                                <span class="counts">2.</span>
                                                What’s your video about?
                                                <div class="ms-2 tooltip_link">
                                                    <a class=" text-xs text-gray-3">
                                                        <svg class="me-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M13.6569 13.6569C16.7811 10.5327 16.7811 5.46735 13.6569 2.34315C10.5327 -0.78105 5.46735 -0.78105 2.34315 2.34315C-0.78105 5.46732 -0.78105 10.5327 2.34315 13.6569C5.46732 16.7811 10.5327 16.7811 13.6569 13.6569ZM7.99922 5.6C7.5574 5.6 7.19922 5.24183 7.19922 4.8C7.19922 4.35818 7.5574 4 7.99922 4C8.44105 4 8.79922 4.35818 8.79922 4.8C8.79922 5.24183 8.44105 5.6 7.99922 5.6ZM7.99922 11.9999C7.5574 11.9999 7.19922 11.6418 7.19922 11.1999V7.19994C7.19922 6.75812 7.5574 6.39994 7.99922 6.39994C8.44105 6.39994 8.79922 6.75812 8.79922 7.19994V11.1999C8.79922 11.6418 8.44105 11.9999 7.99922 11.9999Z"
                                                                fill="#A39FAA" />
                                                        </svg>
                                                        <span>
                                                            Explain what your video is about in a few sentences. Try to use descriptive words for Prontopia  to find the best footage for your video. This is just for Prontopia  and won't be shared with anyone.
                                                        </span>
                                                    </a>

                                                </div>
                                            </label>
                                            <input id="aboutvideo" type="text" placeholder="What’s your video about?" name="textfeild" formControlName="about">
                                            <span *ngIf="fOverviewForm.controls['about'].hasError('required') && fOverviewForm.controls['about'].touched" class="error">This field is required.</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="formItm static_lable order_list">
                                            <label for=""><span class="counts">3.</span>How long should the video be?</label>
                                            <div class="radio_select_wrapp">

                                                <label class="radio_select" *ngFor="let duration of lVideoDurations">
                                                    <input type="radio" name="duration" [value]="duration.value" class="radio_input_element"  formControlName="duration"/>
                                                <div class="radio_caption"><span>{{duration.name}}</span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-none cus-select aspect-ratio">
                                        <div class=" formItm static_lable order_list">
                                            <label for=""><span class="counts">4.</span>What aspect ratio should this video be?
                                            </label>
                                            <div class="dropdown dropdownSelect">
                                                <button class="dropdown-toggle text-md dropdownSelectValid" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <input class="dropdownBtnText mt-2" formControlName="aspect_ratio" style="border: none;margin: 0; padding: 0;">
                                                    <!-- <span class="dropdownBtnText">{{aspect_ratio}}</span> -->
                                                </button>
                                                <ul class="dropdown-menu dropdownMenu">
                                                    <li *ngFor="let item of lAspectRatio;let i = index">
                                                        <a class="dropdown-item text-md" (click)="CreateVideoOverviewComponent_SelectAspectRation(item)">{{item}}
                                                            <!-- <span class="ratio-sel">{{item}}</span> -->
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cus-select aspect-ratio">
                                        <div class=" formItm static_lable order_list">
                                            <label for=""><span class="counts">4.</span>What aspect ratio should this video be?
                                            </label>

                                            <div class="dropdown dropdownSelect">
                                                <button class="dropdown-toggle text-md dropdownSelectValid" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="dropdownBtnText">{{sSelectedAspectRatio}}</span>
                                                </button>
                                                <ul class="dropdown-menu dropdownMenu">
                                                    <h6 class="text-sm weight-700">Most Common Formats</h6>
                                                    <li>
                                                        <span class="fa fa-check"></span>
                                                        <a class="dropdown-item text-md active" href="javascript:void(0)" (click)="CreateVideoOverviewComponent_SelectAspectRation('(16:9)960x540')">
                                                         960x540
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                     </a>
                                                    </li>
                                                    <!-- <li><a class="dropdown-item text-md" href="javascript:void(0)">
                                                        960x540
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                    </a></li> -->
                                                    <h6 class="text-sm weight-700"><img src="../assets/images/Youtube.png" alt="yt">Youtube </h6>
                                                    <li><a class="dropdown-item text-md disable" href="javascript:void(0)">Youtube 1920x1080
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                    </a></li>
                                                    <li><a class="dropdown-item text-md disable" href="javascript:void(0)">Youtube 1024x768
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                    </a></li>
                                                    <h6 class="text-sm weight-700"><img src="../assets/images/Facebook.png" alt="fb">Facebook</h6>
                                                    <li><a class="dropdown-item text-md disable" href="javascript:void(0)">Facebook 1080x1080
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                    </a></li>
                                                    <li><a class="dropdown-item text-md disable"  href="javascript:void(0)">Facebook 720x1080
                                                        <span class="ratio-sel">
                                                            16:9
                                                        </span>
                                                    </a></li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                 </form>

                                     <!-- Alert for Response Error Message start -->
                                     <div *ngIf="bDisplayErrorMessage" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                                fill="#842029" />
                                        </svg>
                                        <div>
                                            {{sDisplayResponseErrorMessage}}
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"
                                                aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                        fill="#FF8369" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- Alert for Response Error Message End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wtermarklogo">
                <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
            </div>
        </div>
    </div>
</section>