<app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <div class="container">
        <ul class="breadcrumb py-3 mb-0">
            <li>Account Settings</li>
            <li><a href="javascript:void(0)">{{session.accountSettingHeaderName}}</a></li>
            <li *ngIf=" ( this.oSessionService?.accountSettingHeaderName == 'Users') &&
            this.oSessionService?.headerBreadCrumps && 
                this.oSessionService?.headerBreadCrumps?.userProfile">{{this.oSessionService?.headerBreadCrumps?.userProfile?._id}}</li>
      

        </ul>
        <div class="br12 bg-white page-shadow curve-bg h-80vh d-flex flex-column p-0">
            <div class="accountSecInner flex-grow-1 row" id="row-main">
            <app-account-sidebar class="col-md-3 pe-0 pb-5 position-relative sidebar" id="sidebar-left"></app-account-sidebar> 
                <div class="col-md-9 border-start ps-0" id="content" [ngClass]="this.oSessionService.isSidebarActive == true? '':'isSidebarActiveFalse'" >
                <router-outlet></router-outlet>
            </div>
            </div>
        </div>        
    </div>
</section>
 
