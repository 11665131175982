import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { cDataService } from './../../../services/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OnboardingManagerService } from '../../../Managers/onboarding-manager.service';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { ErrorService } from './../../../services/error.service';
import { HttpParams } from '@angular/common/http';
import { cClientService } from 'src/app/services/client.service';
import { environment } from 'src/environments/environment';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
declare var $ : any;

@Component({
  selector: 'app-timeline-view-pronto-share',
  templateUrl: './timeline-view-pronto-share.component.html',
  styleUrls: ['./timeline-view-pronto-share.component.scss']
})
export class TimelineViewProntoShareComponent implements OnInit {

  DisconnectSocialForm : FormGroup;
  connected_social_platforms: any;
  oSocialChannel : string;
  oUserName : any;
  sDisplayDisconnectErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  sSessionId: string;
  sUserEmail: string;
  pages: any = [];
  nIncomingPage : any;
  oVideoDetails : any;
  sDisplayResponseMessage : string; 
  bDisplaySuccessMessage : boolean = false;
  oSocialPlatformValue : any = '';
  return_url:string;

 

  constructor( 
    private oSessionService: cSessionService, 
    private oFormBuilder: FormBuilder, 
    private oClientService: cClientService,
    private oDataService: cDataService,
    private accountManager:AccountManagerService,
    private onboardingManger:OnboardingManagerService,
    public videoManager:VideoManagerService,
    public errorService:ErrorService
    ) 
    {
      
    }

  async ngOnInit() {

    
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

if(!this.onboardingManger.profile)
{
 await this.onboardingManger.getUserProfile(this.sUserEmail,this.sSessionId).then(res=>{
    this.connected_social_platforms = this.onboardingManger.profile.connected_social_platforms;
  });
}else
{
  this.connected_social_platforms = this.onboardingManger.profile.connected_social_platforms;
}

if(!this.videoManager.selectedVideo)
{
    let sVideoId  = this.oSessionService.SessionService_GetVideoId();
  await this.videoManager.getVideoById(this.sUserEmail,this.sSessionId,sVideoId).then(res=>{

    this.oVideoDetails = res;
    this.videoManager.selectedVideo = res;
  })
}

this.oVideoDetails = this.videoManager.selectedVideo;

this.return_url = environment.WS_HOST + '/published-video/timeline-view?video_id='+this.videoManager.selectedVideo.fpath;

    this.DisconnectSocialForm = this.oFormBuilder.group(
      {
        platform : '',
      });
  }
  TimelineViewProntoShareComponent_ShareVideoOnSocialChannel(value: any)
  {
    if(value == 'google' && this.connected_social_platforms.google)
    {
      $("#share-modal-Youtube").modal("show")
    }else
    if(value == 'facebook' && this.connected_social_platforms.facebook)
    {
      $("#share-modal").modal("show")
      this.pages = [];
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      let url = cDataService.faceBookPages + '?' + params.toString();
      try
      {
        this.oDataService.DataService_GetFacebookPages(url).subscribe(oResponse =>
        {
          console.log('pages',oResponse.pages);
          this.pages = oResponse.pages;
          if(this.pages)
          {
            this.nIncomingPage = this.pages[0];
          }
          console.log("CustomEditVideo_FacebookChange : Reponse ==> ", oResponse);
        },
        (error)=>
        {
          if(error.url)
          {
            console.log("CustomEditVideo_FacebookChange : Error ==> ", error);
            window.location.assign(error.url);
          }
          else
          {
            console.log("CustomEditVideo_FacebookChange : Error ==> ", error);
          }
        });
      }
      catch (oError) 
      {
        console.log('CustomEditVideo_FacebookInstaChange: exception:');
        console.log(oError);
      }
    
    }else if(value == 'linkedin' && this.connected_social_platforms.linkedin)
    {
      $("#share-modal-linkedin").modal("show");
    }
  
  }
  
  TimelineViewProntoShareComponent_SocialMediaChannelsLink(oData: any,event) {
    event.stopPropagation();
    let sURL: string;
    if(oData == 'google')
    {
      sURL = this.oDataService.googleJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId; 
    }
    else if(oData == 'facebook')
    {
      sURL = this.oDataService.faceBookJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    else if(oData == 'linkedin')
    {
      sURL = this.oDataService.linkedInJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    this.oSessionService.cSessionService_SetRedirectURL(4);
    this.oSessionService.cSessionService_SetVideoId(this.videoManager.selectedVideo.fpath);
    location.assign(sURL);
  }

  TimelineViewProntoShareComponent_DisconnectSocialChannel(value,event)
  {
    event.stopPropagation();
    this.accountManager.disconnectSocialPlatform(this.onboardingManger.loggedUser.email,this.onboardingManger.loggedUser.session_id,value).then(res=>{
      switch (value) {
        case 'google':
         { this.onboardingManger.profile.connected_social_platforms.google = false;
          break;
         }
         case 'facebook':
           { this.onboardingManger.profile.connected_social_platforms.facebook = false;
            break;
           }
           case 'linkedin':
             { this.onboardingManger.profile.connected_social_platforms.linkedin = false;
              break;
             }
        default:
          break;
      }
    }).catch(err=>{
      
      this.errorService.handleError(err.errorMessage,err.errorType,true);
      this.sDisplayDisconnectErrorMessage = err['errorMessage'];
       this.bDisplayErrorMessage = true;
            setTimeout(()=>{
              this.bDisplayErrorMessage = false
            },5000);
    });
  }



  DashboardSocialMediaComponent_SelectPageForShare(nIncomingPage)
  {
    this.nIncomingPage = nIncomingPage;
  }
  DashboardSocialMediaComponent_ShareVideo()
  {
    try
    {
      console.log("video to share"+this.oVideoDetails);
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
      params = params.set('return_url',encodeURIComponent(this.return_url));
      params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
      
      params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
      let pageId = this.nIncomingPage.id;
      let selectedPage: any;
      this.pages.forEach(function (value)
      {
        if (value.id == pageId)
        {
          selectedPage = value;
        }
      });
      let reqBody = { page: { access_token: selectedPage.access_token, id: selectedPage.id, name: selectedPage.name }, video_id: this.oVideoDetails.fpath, title: this.oVideoDetails.title, description: this.oVideoDetails.about };
      let facebookInstaParams = new HttpParams();
      facebookInstaParams = params.set('email', this.sUserEmail);
      params = facebookInstaParams.set('session_id', this.sSessionId);
       
        console.log('step1',cDataService.faceBookUpload + '?' + facebookInstaParams.toString());
        let facebookUrl = cDataService.faceBookUpload + '?' + facebookInstaParams.toString();
        this.oClientService.ClientService_PostVideoToFacebookPage(facebookUrl, reqBody).subscribe(oResponse =>
        {
          console.log('response from facebook video upload ==>', oResponse);
          this.bDisplaySuccessMessage = true;
          this.sDisplayResponseMessage = oResponse.message;
          setTimeout(() => { this.bDisplaySuccessMessage = false;},5000);
        },
        error =>
        {
          this.bDisplayErrorMessage = true;
          this.sDisplayResponseMessage = error.error.errorMessage;
          setTimeout(() => { this.bDisplayErrorMessage = false;},5000);
        });
   
     
    }
    catch (oError)
    {
      console.log('CustomEditVideo_PostVideo: exception:');
      console.log(oError);
      this.bDisplayErrorMessage = true;
      this.sDisplayResponseMessage = oError;
      setTimeout(() => { this.bDisplayErrorMessage = false;},5000);
    }
  }

  // Youtube Section
  DashboardSocialMediaComponent_ShareVideoOnYoutube()
  {
    const youTubeUploadBody =
    {
      category: '22'
    };
      let params = new HttpParams();
      params = params.set('email', this.sUserEmail);
      params = params.set('session_id', this.sSessionId);
      params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
      params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
      params = params.set('return_url',encodeURIComponent(this.return_url));
      params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
      let url = '';
   
      let keyTermCombination = this.oVideoDetails.key_terms.join(',');
      console.log("Incoming Key Terms ==>>>>",keyTermCombination);
      params = params.set('category', encodeURIComponent(youTubeUploadBody.category));
      params = params.set('keywords', encodeURIComponent(keyTermCombination));
      params = params.set('privacyStatus', encodeURIComponent('public'));
      url = cDataService.youTubeUpload + '?' + params.toString();
      console.log("YouTube URl====>",url);
       
    this.oSessionService.cSessionService_SetRedirectURL(4);
    window.open(url);
  }
  // Linkedin Section
  DashboardSocialMediaComponent_ShareVideoOnLinkedIn()
  {
    let params = new HttpParams();
    params = params.set('email', this.sUserEmail);
    params = params.set('session_id', this.sSessionId);
    params = params.set('title',encodeURIComponent(this.oVideoDetails.title));
    params = params.set('return_url',encodeURIComponent(this.return_url));
    params = params.set('video_id',encodeURIComponent(this.oVideoDetails.fpath));
    params = params.set('description', encodeURIComponent(this.oVideoDetails.about));
    let url = '';
     
      params = params.set('privacyStatus', encodeURIComponent('public'));
      url = cDataService.LinkedInUpload + '?' + params.toString();
      console.log("LinkedIn URL : ==> ",url);
     
    this.oSessionService.cSessionService_SetRedirectURL(4);
  window.open(url);
  }


}
