import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { brand, UserList } from "src/app/models/admin";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { mediaLib } from "src/app/models/media";
import { AdminMediaLibraryPageService } from "../service/media-libraries-page.service";
import { Userservice } from "../../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";

@Component({
  selector: "media-library-account",
  templateUrl: "./media-library-account.html",
  styleUrls: ["./media-library-account.scss"],
})
export class MediaLibraryAccountComponent implements OnInit {
  mediaLibId: string = '';
  mediaLibraryDetail: mediaLib = {};
  selectedMediaLibProfile: any;
  mediaLibraryName = "";
  errorBrandEmptry = false;

  //   showAssignNewUserDrawer = false;
  //   placementUser: NzDrawerPlacement = "right";
  //   brandId: string;
  //   userId: any;

  //   public assignUserForm: FormGroup;
  //   userList: any = [];
  //   modelUserSearchChanged = new Subject<string>();
  //   isUserSearchLoading: boolean = false;
  //   brandUserList: any = [];
  //   limit = 100;
  //   offset = 0;
  constructor(
    private userService: Userservice,
    private assignUserFormBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private adminBrandManager: AdminBrandManagerService,
    private mediaLibService: AdminMediaLibraryPageService,
    private successMessageService: SuccessMessageModalService,
  ) { }


  //   ngAfterViewInit(): void {
  //     console.log("afterrrrrrrr");

  //     this.modelUserSearchChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async (value) => {
  //       if(value && value != '' && value.length > 1){
  //       this.userList = await this.userService
  //       .getUsers(this.offset, this.limit, "created", -1, value, "_id").then((resList)=>{
  //       return this.userList = resList;
  //       });

  //       this.isUserSearchLoading = false;
  //       return this.userList;
  //     }
  //       else{
  //         return [];
  //       }
  //     });

  //   }

  ngOnInit(): void {
    this.mediaLibId = this.route.snapshot.params["id"];

    this.getMediaLibDetail();

    // this.getBrandUserList();
  }

  getMediaLibDetail() {
    let __this = this;
    this.mediaLibService.getMediaLibraryProfile(this.mediaLibId)
      .then((mediaLibData: any) => {
        __this.mediaLibraryDetail = mediaLibData;
        __this.userService.selectedMediaId = [mediaLibData];
        __this.selectedMediaLibProfile = mediaLibData;
        __this.mediaLibraryName = mediaLibData.name;
        console.log(__this.userService.selectedMediaId);
      });
  }

  saveMediaLibDetail() {
    const brand = {
      _id: this.mediaLibraryDetail._id.$oid,
      name: this.mediaLibraryName
    };
    if (this.mediaLibraryName != '') {
      this.mediaLibService.updateMediaLibDetails(brand).then((res) => {
        this.successMessageService.display(res.message, "Media Library", true, true);
        this.mediaLibraryDetail.name = this.mediaLibraryName;
      });
    } else {
      this.errorBrandEmptry = true;
    }
  }
  //   // add assign new user drawer
  //   openAssignNewUserDrawer(): void {
  //     // if (this.userList.length == 0) {
  //     //   this.getUserList();
  //     // }
  //     this.assignUserForm = this.assignUserFormBuilder.group({
  //       brand: "",
  //       user: "",
  //       searchUserText:""
  //     });
  //     this.showAssignNewUserDrawer = true;
  //   }
  //   closeAssignNewUserDrawer(): void {
  //     this.showAssignNewUserDrawer = false;
  //   }

  //   getUserId(id: any) {
  //     this.userId = id;
  //     console.log("userId", this.userId);
  //   }
  //   assignUserBrand() {
  //     if (this.userId !== "") {
  //       this.adminBrandManager
  //         .addUserBrand([this.brandId], [this.userId])
  //         .then((res) => {
  //           if (res) {
  //             this.getBrandUserList();
  //           }
  //         });

  //     }
  //     this.showAssignNewUserDrawer = false;
  //   }
  //   getBrandUserList() {
  //     this.adminBrandManager
  //       .getBrandUsers(this.brandId, false)
  //       .then((resList) => {
  //         this.brandUserList = resList["records"];
  //         console.log("brandUserList", this.brandUserList);

  //       });
  //   }

  //   loadBrandMediaLibrary() {
  //     this.router.navigate([`/account-setting/manage-brand/${this.brandId}/media-libraries`]);
  //   }
  //   loadBrandBusiness() {
  //     this.router.navigate([`/account-setting/manage-brand/${this.brandId}/business-details`]);
  //   }

  //   // getUserList() {
  //   //   let __this = this;
  //   //   this.userService
  //   //     .getUsers(this.offset, this.limit, "created", -1, "", "_id")
  //   //     .then((list: UserList) => {
  //   //       console.log("userList", JSON.stringify(list));

  //   //       __this.userList = list['result'];
  //   //     });
  //   // }
}
