import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { cIntercomService } from './intercom.service';
import { cSessionService } from './session.service';
import { environment } from 'src/environments/environment';
import { ConfigManagerService } from '../Managers/config-manager.service';
import { OnboardingManagerService } from '../Managers/onboarding-manager.service';

@Injectable({
  providedIn: 'root'
})
export class LocalesGuard implements CanActivate {
  constructor(private router: Router,private onBoardinfManger:OnboardingManagerService, private oSessionService: cSessionService, private oIntercomService : cIntercomService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
    {
        
       let bLocales =   this.onBoardinfManger.profile.localization_enabled; 
        if (bLocales) 
        {
          return true;
        }
        else 
        {
          return false;
        }
    }
}
