import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
 
import { Subscription } from 'rxjs';
import { CanvasMod } from './../../../models/canva'; 
import { ErrorService } from './../../../services/error.service';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { FtCreateVideoManagerService } from  './../../../Managers/ft-create-video-manager.service';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { mediaFile, mediaLib, visual } from './../../../models/media';

import { cDataService } from './../../../services/data.service';
 
import { cSessionService } from './../../../services/session.service';
import { duration } from 'src/app/models/video';
 
declare var $: any;

@Component({
  selector: 'app-ft-content',
  templateUrl: './ft-content.component.html',
  styleUrls: ['./ft-content.component.scss']
})
export class FtContentComponent implements OnInit, OnDestroy {
  // video content
  @ViewChild('closeModal') closeModal: ElementRef;
  bLetProntoChoose: boolean = true;
  bDisplayMyMediaSection: boolean = false;
  bDisplayUploadSection: boolean = false;
  bUploadVoiceOverFile: boolean;
  lFilestoUpload: any = [];
  lUploadedFiles: any = [];
  lFailedToUpload: any = [];
  nFileToUploadIndex: number = 0;
  loading: boolean = false;
  spinner: string;
  oUploadedVoiceOverFile: any;
  sUploadedVoiceOverFileName: string = "No file choosen."
  public nVoiceOverFileSize: number = 0;
  bDisplayFailedToUploadSection: boolean = false;
  fContentForm: FormGroup;
  sSelectedVoiceGender: string;
  sUploadedVoiceOverPlayableFile = new Audio();
  sSessionId: any;
  sUserEmail: any;
  sVideoId: any;
  public bShowTextToSpeechSection: boolean = true;
  oUserVoiceOverList: any = [];
  oVoiceOverList: any = [];
  sVoiceName: string;
  oAudio = new Audio();
  nSelectedVoiceIndex: number;
  sDisplayResponseMessage: string;
  bDisplayErrorMessage: boolean = false;
  bSuccessMessage: boolean = false;
  oSavedFormData: any;
  lBlockConfigurations: any = [];
  sResetTextOverLays: string;
  sSelectedBlockID: string;
  lTextList: any = [];
  bCreateVideoMode: boolean = true;
  lUserSelectedVisuals: any = [];
  oInputVisualToRemove: any;
  lImagesList: any = [];
  lClipsList: any = [];
  sDisableEditModeToggle: string = "false";
  uploadModalHeaderText: string = "Upload Media";
  sSelectedTab: string = "video content";
  public oHighlightedTabs: any;
  imageSrc : any;
  allCapss : boolean= false;

  public vo_text: string = "";

  valX= "";
  valY = "";
  valWidth = "";
  valHeight = "";

  context : any;
  wpx = '300px';
  hpx = '300px';
  canvasArray : any = [];
  index = 0;
  CANV = [
    
    {x: 10,    y: 10,    w: 200,    h: 100},
    {x: 10,    y: 10,    w: 250,    h: 200},
    {x: 10,    y: 10,    w: 280,    h: 200},
    {x: 10,    y: 10,    w: 200,    h: 200},
    {x: 10,    y: 10,    w: 150,    h: 150}
  ]
  constructor
    (
      private oDataService: cDataService,


      private oSessionService: cSessionService,
      public mediaManger:MediaManagerService,
      private oFormBuilder: FormBuilder,
      private oRouter: Router,
      public videoManger: FtCreateVideoManagerService,
      public configManager: ConfigManagerService,public errorService:ErrorService,private sanitizer: DomSanitizer

    ) {



  }
  sub: Subscription;
  sub2: Subscription;
  ngOnInit() {
    
    this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();
    this.sSessionId  = this.oSessionService.cSessionService_GetGuestSession();
    console.log("duration", this.videoManger.durations);
    
    this.configManager.voices.sort(this.sortAlphaNum);
    this.sub2 = this.videoManger.previousSubmit.subscribe(emit => {
      this.oRouter.navigateByUrl('/ft/looknfeel');
    });

    this.sub = this.videoManger.submitForm.subscribe(c => {
      console.log(c);

      this.videoManger.selectedVideo.email = this.sUserEmail;
      this.videoManger.selectedVideo.session_id = this.sSessionId;
      console.log(this.sSelectedTab);
      if (this.sSelectedTab == 'video content') {
        this.savecontent();
        if (!c || c =='') {
          this.sSelectedTab = 'text overlays';
        }
      } else if (this.sSelectedTab == 'text overlays') {

        console.log("Text overlay",this.sSelectedTab);
        console.log("Text overlay c",c);
        if (!c || c =='') {
          console.log("Text overlay",this.sSelectedTab);
          this.sSelectedTab = 'voiceover text';
        }
        this.saveTextOverylay();
        

      } else {
        console.log('step2');
        this.savevoice();

     

      }


    });
    this.ContentComponent_InitializeJqueryCode();


    

    this.ContentComponent_SelectVoiceGender('male');

    this.sSelectedTab = 'video content';

    this.populateTextoverlay();
    this.setUpCanvasData();
  }
  setUpCanvasData(){
    

    if(this.videoManger!=null && this.videoManger.durations!=null && this.videoManger.durations.length>0){
      for (let index = 0; index < this.videoManger.durations.length; index++) {
      
        var canvasVal = new CanvasMod();
        if(this.videoManger.durations[index].pos != null){
          
        canvasVal.valX = (this.videoManger.durations[index].pos[0]) * 465;
        canvasVal.valY = (this.videoManger.durations[index].pos[1]) * 256;
        }
        else{
          canvasVal.valX = 100;
          canvasVal.valY = 100;

        }
        if(this.videoManger.durations[index].size != null){
          canvasVal.valWidth = ((this.videoManger.durations[index].size[0])  * 465 );
          canvasVal.valHeight =  ((this.videoManger.durations[index].size[1]) * 256) ;
          }
          else{
            canvasVal.valWidth = 0;
            canvasVal.valHeight =  0 ;
  
          }
        canvasVal.id = this.videoManger.durations[index].id;
        console.log("ValWidth:" +canvasVal.valWidth);
        console.log("Valheight:" +canvasVal.valHeight);

        this.canvasArray.push(canvasVal);
      }
    }

  }

  private populateTextoverlay() {
    let i = 0;
    if(this.videoManger.selectedVideo && this.videoManger.selectedVideo.text_list)
    {
    this.videoManger.durations.forEach(dur => {
      dur.textlist.forEach(txt => {
      
        txt.value = this.videoManger.selectedVideo.text_list[i];
        i++;
      });
    });
  }

  this.vo_text = this.videoManger.selectedVideo.vo_text;
  }

  saveTextOverylay() {

    this.updateTextOverlay();
    this.videoManger.createVideo(this.videoManger.selectedVideo).then(res=>{
      if (this.videoManger.isEditMode) {
        
        this.oRouter.navigateByUrl("/ft/preview");
      }
    });

  }
  private updateTextOverlay() {
    this.lTextList = [];
    this.videoManger.durations.forEach(element => {
      console.log('element', element.textlist);
      element.textlist.forEach(ele => {
        console.log(ele);
        if(this.allCapss)
        {
         ele.value = ele.value.toUpperCase();
        }
        this.lTextList.push(ele.value?ele.value:'');
      });
    });
    this.videoManger.selectedVideo.text_list = this.lTextList;
  }

  savecontent() {

    this.updateContent();

    console.log(this.videoManger.selectedVideo);
    this.videoManger.createVideo(this.videoManger.selectedVideo).then(res=>{
      if (this.videoManger.isEditMode) {
        
        this.oRouter.navigateByUrl("/ft/preview");
      }
    });
  }

  private updateContent() {
    this.videoManger.selectedVideo.use_pronto_clips = this.bLetProntoChoose;


    this.lImagesList = [];
    this.lClipsList = [];
    //Extract user images and clips from uploaded images
    if (this.bDisplayUploadSection) {
      for (let nVisualIndex = 0; nVisualIndex < this.lUploadedFiles.length; nVisualIndex++) {
        const element = this.lUploadedFiles[nVisualIndex];
        const extension = element.filename.split('.').pop();
        if (extension === "png" || extension === "jpg" || extension === "jpeg") {
          this.lImagesList.push(element.fpath);
        }
        else {
          this.lClipsList.push(element.fpath);
        }
      }
    }
    //Extract images and clips from user media
    if (this.bDisplayMyMediaSection) {
      for (let nVisualIndex = 0; nVisualIndex < this.lUserSelectedVisuals.length; nVisualIndex++) {
        const element = this.lUserSelectedVisuals[nVisualIndex] as visual;
        const extension = element.visual_type;
        if (element.visual_type == 'image') {
          this.lImagesList.push(element.visual_path);
        }
        else if (element.visual_type == 'clip') {
          this.lClipsList.push(element.visual_path);
        }
      }
    }
    this.videoManger.selectedVideo.user_clips = this.lClipsList;
    this.videoManger.selectedVideo.user_images = this.lImagesList;
  }

  savevoice() {
    this.updateVoice();

    this.videoManger.createVideo(this.videoManger.selectedVideo).then(res=>{
      if (this.videoManger.isEditMode) {
        
        this.oRouter.navigateByUrl("/ft/preview");
      }else{
        this.oRouter.navigateByUrl("/ft/other-setting");
      }
    });
  }
  private updateVoice() {
    if (this.bShowTextToSpeechSection) {
      this.videoManger.selectedVideo.vo_text = this.vo_text;
      this.videoManger.selectedVideo.selected_voice = this.sVoiceName;
      this.videoManger.selectedVideo.voice_file = '';

    }

    //if user uploads voice over file
    else {

      this.videoManger.selectedVideo.voice_file = this.oUploadedVoiceOverFile.fpath;
      this.videoManger.selectedVideo.selected_voice = '';
      this.videoManger.selectedVideo.vo_text = '';
    }
  }

  ContentComponent_HighlightTabs() {
    if (this.oHighlightedTabs.overlays) {
      this.ContentComponent_CheckTextOverlaysTab();
    }
    if (this.oHighlightedTabs.voiceover) {
      this.ContentComponent_CheckVoiceOverTextTab();
    }
    if (this.oHighlightedTabs.content) {
      this.ContentComponent_CheckVideoContentTab()
    }
  }
  ContentComponent_CheckVideoContentTab() {
    this.sSelectedTab = "video content";
    let selectedTab = document.getElementById('Video-Content-tab');
    if (selectedTab.classList.contains('active')) {
      this.uploadModalHeaderText = "Upload Media";
    }
    else {
      this.uploadModalHeaderText = "Upload Media"
    }
    this.oHighlightedTabs.content = true;
  }
  ContentComponent_CheckTextOverlaysTab() {
    this.sSelectedTab = "text overlays";
  }
  ContentComponent_CheckVoiceOverTextTab() {
    this.sSelectedTab = "voiceover text";
    let selectedTab = document.getElementById('Video-Content-tab');
    if (selectedTab.classList.contains('active')) {
      this.uploadModalHeaderText = "Upload Media";
    }
    else {
      this.uploadModalHeaderText = "Upload Media"
    }
  }
  ngOnDestroy() {
    if (this.sub2)
      this.sub2.unsubscribe();
    if (this.sub)
      this.sub.unsubscribe();
     
     
      if(this.videoManger.isEditMode)
      {
        this.updateContent();
        this.updateTextOverlay();
        this.updateVoice();
      }
  }

  ///////////////////////////// Create video request /////////////////////////////////
  CreateVideoContentComponent_SendContentFormRequest() {
    this.oHighlightedTabs = this.oSessionService.cSessionService_GetHighlightTabs();
    //send request if all tabs are visited
    if (this.oHighlightedTabs.overlays && this.oHighlightedTabs.voiceover) {
      this.ContentComponent_UpdateTextList();
      let oCreateVideoPayload =
      {
        session_id: this.oSessionService.cSessionService_GetGuestEmail(),
        email: this.oSessionService.cSessionService_GetGuestSession(),
        video_id: this.sVideoId,
        user_images: [],
        user_clips: [],
        pronto_clips: this.bLetProntoChoose,
        text_list: this.lTextList,
        vo_text: '',
        selected_voice: '',
        voice_file: ''
      }
      this.lImagesList = [];
      this.lClipsList = [];
      //Extract user images and clips from uploaded images
      if (this.bDisplayUploadSection) {
        for (let nVisualIndex = 0; nVisualIndex < this.lUploadedFiles.length; nVisualIndex++) {
          const element = this.lUploadedFiles[nVisualIndex];
          const extension = element.filename.split('.').pop();
          if (extension === "png" || extension === "jpg" || extension === "jpeg") {
            this.lImagesList.push(element.fpath);
          }
          else {
            this.lClipsList.push(element.fpath);
          }
        }
      }
      //Extract images and clips from user media
      if (this.bDisplayMyMediaSection) {
        for (let nVisualIndex = 0; nVisualIndex < this.lUserSelectedVisuals.length; nVisualIndex++) {
          const element = this.lUserSelectedVisuals[nVisualIndex];
          const extension = element.name.split('.').pop();
          if (extension === "png" || extension === "jpg" || extension === "jpeg") {
            this.lImagesList.push(element.visual_path);
          }
          else {
            this.lClipsList.push(element.visual_path);
          }
        }
      }
      oCreateVideoPayload.user_clips = this.lClipsList;
      oCreateVideoPayload.user_images = this.lImagesList;
      //if user enters voice over text
      if (this.bShowTextToSpeechSection) {
        oCreateVideoPayload.vo_text = this.fContentForm.get('vo_text').value;
        oCreateVideoPayload.selected_voice = this.fContentForm.get('selected_voice').value;
        delete oCreateVideoPayload.voice_file;
      }
      //if user uploads voice over file
      else {
        const oVoiceOverFile = this.fContentForm.get('oUploadedVoiceOverFile').value;
        oCreateVideoPayload.voice_file = oVoiceOverFile.fpath;
        delete oCreateVideoPayload.vo_text;
        delete oCreateVideoPayload.selected_voice;
      }
      console.log("Payload ==> ", oCreateVideoPayload);
    }
    // else visit remaining tabs
    else {
      if (this.oHighlightedTabs.overlays) {
        this.sSelectedTab = "voiceover text";
        this.ContentComponent_CheckVoiceOverTextTab();
      }
      else {
        this.sSelectedTab = "text overlays";
        this.ContentComponent_CheckTextOverlaysTab();
      }
    }
  }
  CreateVideoContentComponent_HandleContentResponse = (InComingMessage) => {
    if (JSON.parse(InComingMessage)["errorMessage"]) {
      // this.sDisplayResponseMessage = JSON.parse(InComingMessage)['errorMessage'];
      this.sDisplayResponseMessage = 'An error occurred while creating video. Please try again.';
      console.log("CreateVideoContentComponent_HandleContentResponse : Request Error ==>", InComingMessage)
      this.bDisplayErrorMessage = true;
      $('#error-popup').modal("show");
      setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
    }
    else {
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetResetTextOverlayCheck('false');
      console.log("CreateVideoContentComponent_HandleContentResponse : Request result response ==> ", oResponse);
      this.oSessionService.cSessionService_SetContentForm(this.fContentForm.value);
      if (this.bCreateVideoMode) {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
        this.oRouter.navigateByUrl('/ft/other-setting');
      }
      else {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oSessionService.cSessionService_SetResetTextOverlayCheck(false);
        this.oRouter.navigateByUrl('/dashboard/overview');
      }
    }
  }
  CreateVideoContentComponent_UpdateVideo(bUpdateVideo: boolean) {

  }

  ///////////////////////////// VoiceOver Section /////////////////////////////////

  async ContentComponent_UploadedVoiceOverFile(event) {
    this.ContentComponent_UploadVoiceover();
    let file = event.target.files[0];
    this.nVoiceOverFileSize = file.size;
    console.log("ContentComponent_UploadFile : File To Upload ==> ", file);
    var oFormData: any = new FormData();
    oFormData.append('session_id', this.sSessionId);
    oFormData.append('email', this.sUserEmail);
    oFormData.append('file', file);
    try {
      await this.oDataService.DataService_UploadUserVoiceOverFile(oFormData).subscribe((result: any) => {
        console.log("ContentComponent_UploadedVoiceOverFile : Response ==>", result);
        this.oUploadedVoiceOverFile = result;
        this.sUploadedVoiceOverFileName = result.filename;
        this.bSuccessMessage = true;
        setTimeout(() => { this.bSuccessMessage = false; }, 5000);
        this.sDisplayResponseMessage = "file " + this.sUploadedVoiceOverFileName + " uploaded successfully.";
        this.closeModal.nativeElement.click();
        this.fContentForm.patchValue({ oUploadedVoiceOverFile: this.oUploadedVoiceOverFile, nVoiceOverFileSize: this.nVoiceOverFileSize });
      },
        (error) => {
          this.oUploadedVoiceOverFile = null;
          this.sDisplayResponseMessage = 'An error occurred while creating video. Please try again.';
          $('#error-popup').modal("show");
          this.bDisplayErrorMessage = true;
          setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
          console.error("ContentComponent_UploadedVoiceOverFile : Error ==>", error);
          this.closeModal.nativeElement.click();
        });
    }
    catch (error) {
      this.errorService.handleError(error.errorMessage,error.errorType,true);
      console.error("ContentComponent_UploadedVoiceOverFile : ERROR ==>", error);
    }
  }
  ContentComponent_TextToSpeech() {
    this.bShowTextToSpeechSection = true;
    this.nVoiceOverFileSize = 0;
    this.ContentComponent_SelectVoiceGender('male');
  }
  ContentComponent_UploadVoiceover() {
    this.bShowTextToSpeechSection = false;
  }
  ContentComponent_SelectVoiceGender(value: any) {

    console.log("MaleFemaleButtonPressed");
    this.sSelectedVoiceGender = value;
    this.sVoiceName = "";
    this.oVoiceOverList = [];
    if (this.configManager.voices) {
      console.log(this.configManager.voices);
      this.configManager.voices.forEach(element => {
        if (element.gender == value) {
          this.oVoiceOverList.push(element);
        }
      });

      try {
        this.sVoiceName = this.oVoiceOverList[0]?.name;
      } catch (ex) {

      }

    }


  }
  ContentComponent_SelectVoice(select, index) {
    this.nSelectedVoiceIndex = index;
    this.sVoiceName = select?.name;
  }
  ContentComponent_PlayAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    this.oAudio = new Audio();
    this.oAudio.src = filepath;
    this.oAudio.load();
    this.oAudio.play();
  }
  ContentComponent_StopAudio(filepath) {
    let filename = filepath.replace(/^.*(\\|\/|\:)/, '');
    console.log(filepath);
    if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
      this.oAudio.pause();
      this.oAudio = new Audio();
      this.oAudio.src = filepath;
      this.oAudio.load();
    }
  }


  ///////////////////////////// Text overlay Section /////////////////////////////////
  selectedDuration: duration;
  ContentComponent_HighlightSelectedBlock(sIncommingBlockID: string) {
    this.sSelectedBlockID = sIncommingBlockID;
    this.selectedDuration = this.videoManger.durations.filter(c=> c.id==sIncommingBlockID)[0];
    console.log("SelectedDuration:",this.selectedDuration.id);
    for (let i = 0; i < this.canvasArray.length; i++) {

      if (this.selectedDuration.id == this.canvasArray[i].id) {
        console.log("SelectedDurationIndex:", i);
        this.index = i;
        this.updateCanvas();
      }
}
    
  }
  ContentComponent_PopulateTextList(event: any, nIncommingOuterIndex: number, nIncommingInnerIndex: number) {
    let sValue = event.target.value;
    this.lBlockConfigurations[nIncommingOuterIndex].lTextAreaDetails[nIncommingInnerIndex].value = sValue;
    console.log("ContentComponent_PopulateTextList At block {%s} with index {%s}", nIncommingOuterIndex, nIncommingInnerIndex);
  }
  ContentComponent_UpdateTextList() {
    this.lTextList = [];
    this.lBlockConfigurations.forEach(element => {
      element.lTextAreaDetails.forEach(element => {
        this.lTextList.push(element.value);
      });
    });
    console.log("ContentComponent_UpdateTextList : Text List==> ", this.lTextList);
  }

  ///////////////////////////// Video content Section /////////////////////////////////

  ContentComponent_ToggleLetProntoChoose(event: any) {
    this.bLetProntoChoose = event.target.checked;
    this.fContentForm.patchValue(
      {
        pronto_clips: this.bLetProntoChoose,
        bLetProntoChooseToggle: this.bLetProntoChoose
      });
  }
  LetProntoChooseToggle(event: any) {
    this.bLetProntoChoose = event.target.checked;
  }

  ContentComponent_ToggleMyMediaLibrary(event: any) {
    this.bDisplayMyMediaSection = event.target.checked;

  }
  ContentComponent_ToggleFileUploadType(nIncommingType: boolean) {
    this.bUploadVoiceOverFile = nIncommingType;
  }
  ContentComponent_ToggleFileUploadSection(event: any) {
    this.bDisplayUploadSection = event.target.checked;
  }
  ContentComponent_ToggleFailedToUploadSection(event: any) {
    this.bDisplayFailedToUploadSection = event.target.checked;
  }
  ContentComponent_RemoveFileFromList(sIncommingVisualPath: string) {
    console.log("income",sIncommingVisualPath);
    console.log("income",this.lUploadedFiles);
    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lUploadedFiles.length; nFiletoRemoveIndex++) {
      if (this.lUploadedFiles[nFiletoRemoveIndex].fpath === sIncommingVisualPath) {

        console.log('remove Index',nFiletoRemoveIndex);

      this.lUploadedFiles.splice(nFiletoRemoveIndex, 1);
      }
    }
  }
  ContentComponent_RemoveFailedFileFileFromList(sIncommingFileName: string) {
    for (let nFiletoRemoveIndex = 0; nFiletoRemoveIndex < this.lFailedToUpload.length; nFiletoRemoveIndex++) {
      if (this.lFailedToUpload[nFiletoRemoveIndex].name === sIncommingFileName) {
        this.lFailedToUpload.splice(nFiletoRemoveIndex, 1);
      }
    }
  }
  ContentComponent_SelectFilesForUpload(event) {
    console.log("event", event)
    this.nFileToUploadIndex = 0;
    this.lFilestoUpload = event.target.files;
    for (let nFileUploadIndex = 0; nFileUploadIndex < this.lFilestoUpload.length; nFileUploadIndex++) {
      if (this.lFilestoUpload[nFileUploadIndex].type.indexOf('image') > -1 || this.lFilestoUpload[nFileUploadIndex].type.indexOf('video') > -1) {
        this.ContentComponent_UploadFile(this.lFilestoUpload[nFileUploadIndex]);
      }
      else {
        console.log("ContentComponent_SelectFilesForUpload : Please upload image/video file");
      }
    }
  }
  async ContentComponent_UploadFile(fileToUpload: any) {
    console.log("ContentComponent_UploadFile : File To Upload ==> ", fileToUpload);
    let media = new mediaFile();
  media.file = fileToUpload;
  media.name = fileToUpload.name;

    try {
      await this.mediaManger.uploadMediaFilesAdmin(this.sUserEmail,this.sSessionId,media).then((result: visual) => {
        console.log("ContentComponent_UploadFile : Response ==> ", result);
        this.lUploadedFiles.push({ filename: result.name, fpath: result.visual_path, thumb: result.thumbnail, url: result.url, size: this.lFilestoUpload[this.nFileToUploadIndex].size });
        this.nFileToUploadIndex++;
        this.closeModal.nativeElement.click();
      },
        (error) => {
          this.lFailedToUpload.push(fileToUpload);
          console.error("ContentComponent_UploadFile : Fialed list ==>", this.lFailedToUpload);
          console.error("ContentComponent_UploadFile : Error ==>", error);
          this.closeModal.nativeElement.click();
        
        });
    }
    catch (error) {
      this.errorService.handleError(error.errorMessage,error.errorType,true);
      console.error("ContentComponent_UploadFile : ERROR ==>", error);
    }
  }
  ContentComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  ContentComponent_lEmittedFilesToUpload(lIncommingFiles: any) {
    console.log("ContentComponent_lEmittedFilesToUpload : Selected files ==> ", lIncommingFiles);
    this.lUserSelectedVisuals = lIncommingFiles;
    this.fContentForm.patchValue({ lUserSelectedVisuals: this.lUserSelectedVisuals });
  }
  removeSelectedVisuals(oIncommingVisual: any) {

    let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
    this.lUserSelectedVisuals.splice(nVisualIndex, 1);
    this.oInputVisualToRemove = oIncommingVisual;
  }
  ContentComponent_ToggleView(event) {
    if (event.target.checked) {
      this.oRouter.navigateByUrl('/ft/overview');
    }
    else {
      this.oRouter.navigateByUrl('edit-video/timeline-view');
    }
  }
  ContentComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `var elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
     var elSelectCustomValue = elSelectCustom.children[0];
     var elSelectCustomOptions = elSelectCustom.children[1];
     var defaultLabel = elSelectCustomValue.getAttribute("data-value");
     
     // Listen for each custom option click
     Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
       elOption.addEventListener("click", (e) => {
         // Update custom select text too
         elSelectCustomValue.textContent = e.target.textContent;
         // Close select
         elSelectCustom.classList.remove("isActive");
       });
     });
     
     // Toggle select on label click
     elSelectCustomValue.addEventListener("click", (e) => {
       elSelectCustom.classList.toggle("isActive");
     });
     
     // close the custom select when clicking outside.
     document.addEventListener("click", (e) => {
       const didClickedOutside = !elSelectCustom.contains(event.target);
       if (didClickedOutside) {
         elSelectCustom.classList.remove("isActive");
       }
     });
     
     
         // smooth Loaded  start
     document.addEventListener("DOMContentLoaded", function(){
     
     var scrollSpy = new bootstrap.ScrollSpy(document.body, {
     target: '#main_nav',
     })
     
     // add smooth animated scroll via javascript
     document.querySelectorAll('.scrollto').forEach(function(element){
     element.addEventListener('click', function(e) {
         e.preventDefault();
         var section_name = element.getAttribute('data-bs-target');
         var offset_num = document.querySelector(section_name).offsetTop;
           window.scrollTo({ top:(offset_num - 0), behavior:'smooth' });
     });
     })
     
     }); 
     // smooth Loaded  end
     
                     var selector = '#main_nav li a';   
                       
                       $(selector).on('click', function(){
                           $(selector).removeClass('active');
                           $(this).addClass('active');
           
                           $('.video-timeline').removeClass('active')
                           $($(this).attr("data-bs-target")).addClass('active')
                       });`;
    document.body.appendChild(chatScript);
  }
  mediaSelected(event) {
    console.log("visual", event);
    this.lUserSelectedVisuals = event;
    console.log(this.lUserSelectedVisuals);
    this.visible = false;
  }
  // modal media library
  visible = false;
  showMediaModal() {
    this.visible = true;
  }
  handleMediaOk(): void {
    console.log('Button ok clicked!');
    this.visible = true;
  }
  closeMediaModal(): void {
    console.log('Button cancel clicked!');
    this.visible = false;
  }

  sortAlphaNum(a, b) {
    console.log(a);
    console.log(b);
   let reA = /[^a-zA-Z]/g;
   let reN = /[^0-9]/g;
  var aA = a.name.replace(reA, "");
  var bA = b.name.replace(reA, "");
  
  if (aA === bA) {
    var aN = parseInt(a.name.replace(reN, ""), 10);
    var bN = parseInt(b.name.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}

//below both functions is for showing thumbnail of img(local file), while uploading failed
readURL(file){

  this.imageSrc = URL.createObjectURL(file);
  return this.imageSrc;  
}
sanitizeImageUrl(imageUrl: string): SafeUrl {
return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
}
allCaps(event){
  if(event.target.checked){
    this.videoManger.durations.forEach(element=>{
      element.textlist.forEach(el=>{
        el.value=el.value.toUpperCase();
      })
    })
  }
  else{this.videoManger.durations.forEach(element=>{
    element.textlist.forEach(el=>{
      el.value=el.value.toLowerCase();
    })
  })

  }
}


ngAfterViewInit(): void {

  this.updateCanvas();
  
  var sIncommingBlockID = this.videoManger.durations[this.index].id;
  this.sSelectedBlockID = sIncommingBlockID;
  this.selectedDuration = this.videoManger.durations.filter(c=> c.id==sIncommingBlockID)[0];
  }
  updateCanvas(){
    this.valX = this.canvasArray[this.index].valX + "px";
    this.valY = this.canvasArray[this.index].valY + "px";
    this.valWidth = this.canvasArray[this.index].valWidth + "px";
    this.valHeight = this.canvasArray[this.index].valHeight + "px";
 
    console.log("valx", this.canvasArray[this.index].valX);
    console.log("valy", this.canvasArray[this.index].valY);
    console.log("valw", this.canvasArray[this.index].valWidth);
    console.log("valh", this.canvasArray[this.index].valHeight);
    
  
  } 
  nextcanva(){
    if(this.index != this.canvasArray.length-1){
  
  
      
     this.index++;
      var sIncommingBlockID = this.videoManger.durations[this.index].id;
      this.sSelectedBlockID = sIncommingBlockID;
      this.selectedDuration = this.videoManger.durations.filter(c=> c.id==sIncommingBlockID)[0];
      this.updateCanvas();
    }
  }
  
  precanva(){
    if(this.index!=0){
      this.index--;
      var sIncommingBlockID = this.videoManger.durations[this.index].id;
      this.sSelectedBlockID = sIncommingBlockID;
      this.selectedDuration = this.videoManger.durations.filter(c=> c.id==sIncommingBlockID)[0];
      this.updateCanvas();
    }
  }
}
