import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { cSessionService } from './../../../services/session.service';

import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { MustMatch } from './must-match.validator';
import { ErrorService } from './../../../services/error.service';
declare var $: any
@Component({
  selector: 'app-login-security',
  templateUrl: './login-security.component.html',
  styleUrls: ['./login-security.component.scss']
})
export class LoginSecurityComponent implements OnInit {
  public fChangePasswordForm: FormGroup;
  public fUserProfileForm: FormGroup;
  sSessionId: string;
  sUserEmail: string;
  bDisplayPassword: boolean = false;
  bDisplayConfirmPassword: boolean = false;
  bDisplayCurrentPassword: boolean = false;
  bDisplayNewPassword: boolean = false;
  sUserPassword: string;
  sDisplayPasswordErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  sDisplayResponseErrorMessage: string;
  bDisplayErrorMessageSignout: boolean = false;
  bShowError: boolean = false;
  bDisabledButtons: boolean = false;
  bEditButton: boolean = true;

  constructor(private oSessionService: cSessionService, private oFormBuilder: FormBuilder, public onboardingManager: OnboardingManagerService, private oRouter: Router,
    public errorService: ErrorService) { }
  ngOnInit(): void {

    if (!this.onboardingManager.profile && this.onboardingManager.loggedUser) {
      this.onboardingManager.getUserProfile(this.onboardingManager.loggedUser.email, this.onboardingManager.loggedUser.session_id).then().catch();
    }
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Login & Security");

    console.log(this.sSessionId + "---" + this.sUserEmail);


    this.LoginSecurityComponent_InitializeUserForms();

  }
  LoginSecurityComponent_InitializeUserForms() {
    const passRejex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*]).{8,}$/;
    //change password form
    this.fChangePasswordForm = this.oFormBuilder.group(
      {
        session_id: [this.sSessionId],
        email: [this.sUserEmail],
        currentPassword: [null, [Validators.required]],
        newPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(passRejex)]],
      }
      // ,{ validators: this.checkPasswords }
      // {validator: MustMatch('password', 'confirmPassword')}
    );
  }
  //for user profile form
  LoginSecurityComponent_TogglePasswordVisibility() {
    this.bDisplayPassword = !this.bDisplayPassword;
  }
  //for reset password form (current password)
  LoginSecurityComponent_ToggleCurrentResetPasswordVisibility() {
    this.bDisplayCurrentPassword = !this.bDisplayCurrentPassword;
  }
  //for reset password form (new password)
  LoginSecurityComponent_ToggleNewResetPasswordVisibility() {
    this.bDisplayNewPassword = !this.bDisplayNewPassword;
  }
  //for reset password form (confirm password)
  LoginSecurityComponent_ToggleConfirmPasswordVisibility() {
    this.bDisplayConfirmPassword = !this.bDisplayConfirmPassword;
  }


  LoginSecurityComponent_ResetView() {
    this.ngOnInit();
    this.bDisabledButtons = false;
    this.bEditButton = true;
  }
  //update password event
  ChangePassword_SendUpdatePasswordRequest() {
    console.log(this.fChangePasswordForm);
    if (this.fChangePasswordForm.valid) {

      this.onboardingManager.resetPassword(this.sUserEmail, this.fChangePasswordForm.get('currentPassword').value, this.fChangePasswordForm.get('newPassword').value, true).then(res => {
        localStorage.clear();
        $('#password-reset-popup').modal('show');
      }).catch(er => {


        this.errorService.handleError(er.error.errorMessage, er.error.errorType, true);

      });
    }
    else {
      this.fChangePasswordForm.markAllAsTouched();
    }
  }
  LoginSecurityComponent_HandleUpdatePassowordResponse = (InComingMessage) => {

    console.log('LoginSecurityComponent_HandleUpdatePassowordResponse : Update Passoword Response ==> ', InComingMessage);

    if (InComingMessage['errorMessage']) {
      this.sDisplayPasswordErrorMessage = InComingMessage;
      console.error('LoginSecurityComponent_HandleUpdatePassowordResponse: Update Passoword Error ==> ', InComingMessage);
      this.bDisplayErrorMessage = true;
      setTimeout(() => { this.bDisplayErrorMessage = false }, 5000);
    }
    else {
      console.log('LoginSecurityComponent_HandleUpdatePassowordResponse: Update Passoword Response ==> ', InComingMessage);
      localStorage.clear();
      $('#password-reset-popup').modal('show')
    }
  }
  LoginSecurityComponent_SendDeleteUserAccountRequest() {

    this.onboardingManager.deleteAccount().then(res => {
      localStorage.clear();

      this.oRouter.navigate(["/login"]);

    }).catch(err => {

      this.errorService.handleError(err.errorMessage, err.errorType, true);
      this.sDisplayResponseErrorMessage = err;

      this.bDisplayErrorMessageSignout = true;
      setTimeout(() => { this.bDisplayErrorMessageSignout = false }, 5000);
    });


  }

  //update user profile event
  LoginSecurityComponent_SendProfileUpdateRequest() {
    this.bDisabledButtons = false;
    this.bEditButton = true;
  }

  LoginSecurityComponent_HandleUpdateProfileResponse = (InComingMessage) => {
    console.log('LoginSecurityComponent_HandleUpdateProfileResponse : Profile Update Response ==> ', InComingMessage);

    if (InComingMessage['errorMessage']) {
      this.sDisplayResponseErrorMessage = InComingMessage;
      console.error('LoginSecurityComponent_HandleUpdateProfileResponse: Profile Update Error ==> ', InComingMessage);
      this.bDisplayErrorMessageSignout = true;
      setTimeout(() => { this.bDisplayErrorMessageSignout = false }, 5000);
    }
    else {
      console.log('LoginSecurityComponent_HandleUpdateProfileResponse: Profile Update Response ==> ', InComingMessage);
      this.oRouter.navigate(["/billing"]);
    }
  };
  LoginSecurityComponent_SignOutFromAllDevices() {

    this.handleCancel();
    this.onboardingManager.singOutAll().then(res => {
      localStorage.clear();

      this.oRouter.navigate(["/login"]);


    }).catch(err => {


      this.errorService.handleError(err.errorMessage, err.errorType, true);
      this.sDisplayResponseErrorMessage = err;

      this.bDisplayErrorMessageSignout = true;
      setTimeout(() => { this.bDisplayErrorMessageSignout = false }, 5000);

    });

  }


  LoginSecurityComponent_EditForm() {
    this.bEditButton = false;
    this.bDisabledButtons = true;
  }
  LoginSecurityComponent_SignOutFromAllDevicesModal() {
    $('#signout-popup').modal('show')
  }

  LoginSecurityComponent_DeleteModal() {
    $('#delete-popup').modal('show')
  }

  visibleChangePassword = false;
  placementChangePassword: NzDrawerPlacement = 'right';
  openChangePassword(): void {

    this.visibleChangePassword = true;
  }
  closeChangePassword(): void {
    this.visibleChangePassword = false;
  }

  // modal sign out all devices
  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }


}
