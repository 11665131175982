import { Component, OnInit } from '@angular/core';
import { cSessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-dashboard-videos',
  templateUrl: './dashboard-videos.component.html',
  styleUrls: ['./dashboard-videos.component.scss']
})
export class DashboardVideosComponent implements OnInit {

  constructor(public oSessionService: cSessionService) { }

  ngOnInit(): void {
  }

}
