import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
 

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
 
export class VerifyEmailComponent implements OnInit {
  sUserEmail: any;
  code: any;
  errorMsg =""

  constructor(private oActivatedRoute: ActivatedRoute ,  private oRouter : Router , private onboardingManager : OnboardingManagerService) {
      const verifyEmailResult = this.oActivatedRoute.snapshot.queryParams;
 
    this.sUserEmail = verifyEmailResult.email;
    this.code = verifyEmailResult.code;
    this.VerifyEmailComponent_VerifyUserEmail()
  
     }

  ngOnInit(): void {
  }
  VerifyEmailComponent_VerifyUserEmail()
  {
    
   this.onboardingManager.verifyEmail(this.sUserEmail ,this.code).then(res=>{
  
 
   this.oRouter.navigateByUrl('/login')

 
   },(error)=>
   {
    console.log(error);
    this.errorMsg =  error.error.errorMessage;
   }
   )
  }

}
