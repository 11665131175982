import { StandardId } from './onboarding';
export class SearchData {
  total_count?: number;
  count?: number;
  offset?: number;
  limit?: number;
  sort_by?: string;
  search_by?: string | "_id";
  search_term?: string | "";
  asc?: number;
  visual_type? : string;
  media_lib_id? : string | "";
  media_lib_ids? : string[] | any[];
  email?: string | "";
  visual_id?: string | "";
  brand_id?: string;
  result? : any[];
}

export class user {
  _id?: string;
  email?: string;
  session_id?: string;
  user_email?: string;
  media_libs?: string[];
  user_role?: string[];
  first_name?: string;
  username?: string;
  last_name?: string;
  brands?: string[];
  permissions?: permission;
  public_videos?: number | 0;
  published_video_count?: number | 0;
  media_uploads?: number | 0;
  login?: boolean | false;
  created?: object | any;
  allow_download_videos?: string;
  allow_share_brand_account?: string;
  allow_share_company_account?: string;
  allow_share_personal_account?: string;
  subscribe_email_changes?: any;
  get_pronto_newsletter?: any;
  brand_count?: number | 0;
  logo_file? :string;
  status? :string;
  last_login?: any;
  plan?: string;
  user_logo_file?: string
}

export class permission {
  company_accounts: boolean;
  brand_accounts: boolean;
  personal_accounts: boolean;
  download_access: boolean;
}

export class UserList {
  total_count?: number;
  count?: number;
  offset?: number;
  limit?: number;
  sort_by?: string;
  search_by?: string | "_id";
  search_term?: string | "";
  asc?: number;
  result: user[];
}
export class brand {
  _id?: any;
  name?: string;
  website?: string;
  industry?: string;
  status?: boolean;
  email?: string;
  login?: string;
  brands?: number | 0;
  users?: string[];
  user_count?: number | 0;
  public_videos?: number | 0;
  published_video_count?: number | 0;
  media_uploads?: number | 0;
  media_lib?: any;
  media_libs?: MediaLibrary[] | any;
  logo_file? :string;
  logo_files? : any[];
  result? : any[];
  count? : number | 0;
  secondary_color? : any;
  secondary_colors? : any;
  primary_color? : any;
  industry_icon? :string;
  primary_font? : string;
  fonts? : string;
}

export class MediaLibraryResponse {
  category?: string;
  created?: string;
  description?: string;
  key_terms?: string;
  name?: string;
  owner?: string;
  owner_type?: string;
  _id?: any;
}

export class BrandResponse {
  category?: string;
  email?: string;
  industry?: string;
  session_id?: string;
  name?: string;
  users?: string;
  _id?: any;
}

export class brandList {
  total_count?: number;
  offset?: number;
  limit?: number;
  count?: number;
  sort_by?: string;
  search_by?: string | "name";
  search_term?: string | "";
  asc?: number;
  result?: brand[];
  records?: brand[];
  _id?: string;
  // $oid: string;
  //     name?: string;
  //     website?: string;
  //     industry?: string;
  //     status?: boolean;
  //     users?: string[];
  //     public_videos? : number | 0;
  //     media_uploads? : number | 0;
}

export class Media_Lib {
  _id?: string;
  result?: MediaLibrary[];
  count?: number;
  total_count?: number;
}
export class MediaLibrary {
  name?: string;
  asset_source?: string;
  library_access?: boolean | string;
  users?: String[];
  brands?: number;
  user_id? : string;
  user_id_list? : String[];
  _id?: string | any;
  activation_status?: string;
  total_items?: number;
  industry?: string;
  owner?: string;
  owner_type?: String[] | string;
  created?: string;
  brand_id?: string;
  limited_access?: boolean | string;
  published_video_count?: number | 0;
  media_uploads?: number | 0;
  organization_id?: StandardId |  string;
}

export class UserProfile {
  _id?: string;
  allow_download_videos?: string;
  allow_share_brand_account?: string;
  allow_share_company_account?: string;
  allow_share_personal_account?: string;
  created?: string;
  first_name?: string;
  last_name?: string;
  subscribe_email_changes?: string;
  updated?: string;
  username?: string;
  media_lib?: string | any;
  media_libs?: MediaLibrary[] | any;
  media_uploads?: number | 0;
  public_videos?: number | 0;
  session_id?: string;
  user_role?: string | string[];
  organization_id?: string;
  brand_accounts?: boolean | false;
  personal_accounts?: boolean | false;
  download_access?: boolean | false;
  company_accounts?: boolean | false;
  get_pronto_newsletter?: boolean | false;
  account_changes?: boolean | false;
}

export class VisualList {
  count?: number;
  status?: string;
  visuals?: visual[] | any;
}
export class visual {
  isSelected: boolean = false;
  ext: string;
  key_terms: string[] = [];
  media_lib: string;
  name: string;
  size: number;
  thumb: string;
  duration: string;
  videos_used_in: [];
  visual_path: string;
  visual_type: string;
  assetType: string; //pronoto| user
  url: string;
  fpath?: string;
  status?: string;
  thumbnail: string;
  loadingThumbnail: boolean = true;
  loadingUrl: boolean = true;
}
