import { OnboardingManagerService } from './../../../../Managers/onboarding-manager.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { mediaLib, MediaLibSearch } from "src/app/models/media";
import { cSessionService } from "src/app/services/session.service";
import { cWebSocketService } from "src/app/services/websocket.service";
import { AdminMediaLibraryManagerService } from "src/app/Managers/adminManager/admin-media-library-manager.service";
import { MediaLibrary, Media_Lib, user } from "src/app/models/admin";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ErrorService } from "src/app/services/error.service";
import { AdminMediaLibraryPageService } from "../service/media-libraries-page.service";
import { Userservice } from "../../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "app-media-libraries",
  templateUrl: "./media-libraries.component.html",
  styleUrls: ["./media-libraries.component.scss"],
})
export class MediaLibrariesComponent implements OnInit {
  lMediaLibraries: any = [];
  sSessionId: string;
  sUserEmail: string;
  lMediaLibrariesCount: any;
  fAddLibraryForm: FormGroup;
  mediaObj: any;
  isActive: false;
  isDeactive: false;
  mediaLibStatus = "all";
  MediaModal: boolean = false;
  userId: string;

  limit = 20;
  offset = 0;
  currentPage = 1;
  totalPages = 1;
  mediaLibList: Media_Lib;
  listPages: number[] = [];
  userList: any = [];
  modelUserSearchChanged = new Subject<string>();
  isUserSearchLoading: boolean = false;
  selectedAddUsers: user[] = [];
  addMediaFormSubmitted = false;
  addMediaLibraryAdminForm: FormGroup;
  userListLoopLimit = 3;
  sortType = -1;
  Access_Control = {
    global : "global_access",
    limited : "limited_access",
  }
  showAssignUserDropDown = true;



  @ViewChild('mediaLibraryListAutoComplete', { read: MatAutocompleteTrigger })
    autoCompleteUser: MatAutocompleteTrigger;

  constructor(
    private oRouter: Router,
    private oFormBuilder: FormBuilder,
    private addMediaLibraryAdminFormBuilder: FormBuilder,
    private oWebSocketService: cWebSocketService,
    public mediaManger: MediaManagerService,
    public oSessionService: cSessionService,
    public errorService: ErrorService,
    private mediaLibraryManager: AdminMediaLibraryManagerService,
    private mediaPageLibraryManager: AdminMediaLibraryPageService,
    private addBrandFormBuilder: FormBuilder,
    private userService: Userservice,
    private successMessageService : SuccessMessageModalService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private onBoarding : OnboardingManagerService,
  ) {}

  ngAfterViewInit(): void {
    // TODO autocomplete Brands change trigger
    this.modelUserSearchChanged
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          if (value.length > 1) {
            this.userList = [];
            this.userList = await this.userService
              .searchUser(
                this.offset,
                this.limit,
                "created",
                -1,
                "_id",
                value
              )
              .then((resList) => {
                return (this.userList = resList);
              });

            this.isUserSearchLoading = false;
            return this.userList;
          }
        } else {
          return [];
        }
      });
  }

  ngOnInit(): void {

    this._MatPaginatorIntl.firstPageLabel = '';
    this._MatPaginatorIntl.lastPageLabel = '';
    this._MatPaginatorIntl.nextPageLabel = '';
    this._MatPaginatorIntl.previousPageLabel = '';

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Media Libraries");
    // this.mediaManger
    //   .getMediaLibraries(this.sUserEmail, this.sSessionId, this.mediaLibStatus)
    //   .then();

    this.reloadMediaLibrarySearch();
    window.addEventListener('scroll', this.scrollEvent, true);

  }

  scrollEvent = (event: any): void => {
    if(this.autoCompleteUser && this.autoCompleteUser.panelOpen){
      this.autoCompleteUser.updatePosition();
    }
  }

  populatePagination() {
    if (this.mediaLibList && this.mediaLibList.total_count > 0) {
      const maxPage = Math.ceil(this.mediaLibList?.total_count / this.limit) || 1;
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
      } else {
        this.listPages = [1];
      }
    }
  }

  reloadMediaLibrarySearch() {
    let __this = this;
    this.mediaLibraryManager
      .getListMedia(
        this.sUserEmail,
        this.sSessionId,
        this.offset,
        this.limit,
        this.searchBy,
        this.sortType,
        this.searchTerm,
        "name",
        true,
        true
      )
      .then(function (brandList: Media_Lib) {
        __this.mediaLibList = brandList;
        __this.populatePagination();
      });
  }

  loadPage(page: number) {
    if( page < 1 ) {
      page = 1;
    } else {
      if( page > this.listPages.length ) {
        page = 1;
      }
      this.mediaLibList.result = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.reloadMediaLibrarySearch();
    }
  }

  checkValueActive(event: boolean) {}
  checkValueDeactive(event: boolean) {}

  clearAll() {
    this.isActive = false;
    this.isDeactive = false;
  }

  // addMediaLib() {
  //   if (this.fAddLibraryForm.valid) {
  //     const lib_name = this.fAddLibraryForm.value.lib_name;
  //     const description = this.fAddLibraryForm.value.description;
  //     const global_access = this.fAddLibraryForm.controls["access"].value;
  //     const limited_access = this.fAddLibraryForm.controls["access"].value;
  //     const keyterms = [];
  //     const category = "";

  //     try {
  //       this.mediaManger
  //         .addMediaLib(
  //           this.sUserEmail,
  //           this.sSessionId,
  //           lib_name,
  //           keyterms,
  //           description,
  //           category
  //         )
  //         .then((res) => {
  //           this.fAddLibraryForm.controls["lib_name"].setValue("");
  //           this.fAddLibraryForm.controls["description"].setValue("");
  //         });
  //     } catch (err) {
  //       console.log(err);
  //       this.errorService.handleError(err.error, 'Add Media Library', true);
  //     }
  //     this.closeMedia();
  //   }
  // }

  updateMediaLibrary(media) {
    console.log("Status Value = ", media.activation_status);
    if (media.activation_status == "inactive") {
      media.activation_status = "active";

      this.mediaManger.updateMediaLibrary(
        media.name,
        media.activation_status,
        this.sUserEmail,
        this.sSessionId
      );
      this.handleCancel();
    } else {
      media.activation_status = "inactive";

      this.mediaManger.updateMediaLibrary(
        media.name,
        media.activation_status,
        this.sUserEmail,
        this.sSessionId
      );
      this.handleCancel();
    }
  }

  UserMediaComponent_HandleMediaLibraryListResponse = (IncomingMessage) => {
    console.log(
      "UserMediaComponent_HandleMediaLibraryListResponse: IncomingMessage",
      JSON.parse(IncomingMessage)
    );

    if (JSON.parse(IncomingMessage).errorMessage) {
      console.error(
        "UserMediaComponent_HandleMediaLibraryListResponse : Error ==> ",
        JSON.parse(IncomingMessage).errorMessage
      );
    } else {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
      this.lMediaLibraries = oFormatedIncommingMessage.media_libs;
      this.lMediaLibrariesCount = oFormatedIncommingMessage.media_libs.length;
    }
  };
  UserMediaComponent_NavigateToMediaSection(media: mediaLib) {
    console.log(media);
    const testmedia = {
      activation_status: "active",
      name: "leotheone677@gmail.com_default_lib",
      total_items: 10,
    };

    this.mediaManger.selectedmedialib = testmedia;
    this.mediaManger.uploadMediaList = [];
    // this.mediaManger.selectedmedialib = media;
    // this.oSessionService.setAccountSettingHeaderName(
    //   "Media Libraries > " + media.name
    // );
    this.oSessionService.setAccountSettingHeaderName(
      "Media Libraries > " + "leotheone677@gmail.com_default_lib"
    );
    this.oRouter.navigate(["/account-setting/media-library"]);
  }
  UserMediaComponent_nIncomingMediaLibraries(event) {
    this.lMediaLibraries = [];
    this.lMediaLibraries = event[0];
  }

  visibleMedia = false;
  placementMedia: NzDrawerPlacement = "right";
  openMedia(): void {
    this.visibleMedia = true;
  }
  closeMedia(): void {
    this.visibleMedia = false;
  }

  showAddMediaLibraryAdmin = false;
  placementMediaLibraryAdmin: NzDrawerPlacement = "right";

  openAddMediaLibraryAdmin(): void {
    this.addMediaFormSubmitted = false;
    this.showAssignUserDropDown = true;
    this.addMediaLibraryAdminForm = this.addMediaLibraryAdminFormBuilder.group({
      name: "",
      activation_status: true,
      asset_source: "",
      access: this.Access_Control.limited,
      searchUserText: "",
      user_id: "",
    });
    this.showAddMediaLibraryAdmin = true;
  }
  closeAddMediaLibraryAdmin(): void {
    this.showAddMediaLibraryAdmin = false;
  }

  visibleFilter = false;
  placementFilter: NzDrawerPlacement = "right";
  openFilter(): void {
    this.visibleFilter = true;
  }
  closeFilter(): void {
    this.visibleFilter = false;
  }

  // modal sign out all devices
  isVisible = false;
  showModal(media) {
    // console.log("dd",media);
    this.mediaObj = media;
    this.isVisible = true;
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }
  mediaSelected(event) {
    console.log("visual", event);
  }

  // modal media library
  visible = false;
  showMediaModal() {
    this.visible = true;
  }
  handleMediaOk(): void {
    console.log("Button ok clicked!");
    this.visible = true;
  }
  closeMediaModal(): void {
    console.log("Button cancel clicked!");
    this.visible = false;
  }

  setLibraryAccess(incomingLibraryAccess: string) {
    this.addMediaLibraryAdminForm
      .get("library_access")
      .setValue(incomingLibraryAccess);
    console.log("incomingLibraryAccess", incomingLibraryAccess);
  }
  setAssetSource(incomingAssetSource: string) {
    this.addMediaLibraryAdminForm
      .get("asset_source")
      .setValue(incomingAssetSource);
    console.log("assetSource", incomingAssetSource);
  }


  async addNewMediaLibraryAdmin(): Promise<any> {
    this.addMediaFormSubmitted = true;

    if (this.addMediaLibraryAdminForm.invalid) {
      // TODO show Error notification
    } else if ( this.showAssignUserDropDown && (!this.selectedAddUsers || this.selectedAddUsers.length === 0) ) {
      this.errorService.handleError('Please assign user to media library.', 'Add Media Library', true);
    } else {
      const accessControl = this.addMediaLibraryAdminForm.controls["access"].value;
      // TODO add brand
      const mediaLibraryInfo: MediaLibrary = {
        name: this.addMediaLibraryAdminForm.controls["name"].value,
        activation_status: this.addMediaLibraryAdminForm.controls["activation_status"].value,
        asset_source: this.addMediaLibraryAdminForm.controls["asset_source"].value,
        library_access: (accessControl == this.Access_Control.global ) ? 'global' : 'limited',
        user_id: this.sUserEmail,
        owner_type : 'organization'
        // users:
        //   this.selectedAddUsers.length > 0
        //     ? this.selectedAddUsers
        //     : []
      };
      await this.mediaLibraryManager
        .addMediaLibray(mediaLibraryInfo)
        .then((libRes: any) => {
          this.addUserMediaLibrary(libRes.id.$oid);
          this.mediaPageLibraryManager
            .getMediaLibraryProfile(libRes.id.$oid)
            .then((profile: any) => {
              this.mediaLibList.result.unshift(profile);
              this.successMessageService.display("Media Library has been created.", "Add Media Library", true, true);
            }).catch((err) => {
              this.errorService.handleError(err.errorMessage ? err.errorMessage : err.error, 'Add Media Library', true);
            });
        });
      this.showAddMediaLibraryAdmin = false;
    }
  }
  addUserMediaLibrary(mediaLibraryId: any) {
    let userIds: string[] = this.selectedAddUsers.map((item) => {
      return item._id;
    });

    if(userIds.length == 0) {
      return;
    }
    this.mediaLibraryManager
      .addUserMediaLibrary([mediaLibraryId], userIds)
      .then((res) => {
        this.selectedAddUsers = [];
      }).catch((error) => {
        this.selectedAddUsers = [];
      });
  }
  setAddUsers(user: user): void {
    const pos = this.selectedAddUsers.some((item) => {
      return item._id == user._id;
    });

    if (!pos) {
      this.selectedAddUsers.push(user);
    }
    this.addMediaLibraryAdminForm.controls["searchUserText"].setValue("");
  }

  changeAccessLimit (value){
    console.log(value);
    if(value == this.Access_Control.global){
      this.showAssignUserDropDown = false;
    } else {
      this.showAssignUserDropDown = true;
    }
  }

  removeAddUsers(user: user): void {
    var indexBrand = this.selectedAddUsers.indexOf(user);
    if (indexBrand > -1) {
      this.selectedAddUsers.splice(indexBrand, 1);
    }
  }

  viewAllSelectedUsers(val) {
    this.userListLoopLimit = val;
  }

  async deleteMediaLib(media : any){

    this.mediaPageLibraryManager.deleteMediaLibrary(media?._id?.$oid).then( (response) =>{
      if(response){
        const indexRecrod = this.mediaLibList?.result.findIndex(
          (item) => item?._id?.$oid == media?._id?.$oid
        );
        if (indexRecrod > -1) {
          this.isVisible = false;
          this.successMessageService.display("Media Library successfully deleted.", "Delete Media Library", true,true);

          this.mediaLibList.result.splice(indexRecrod, 1);
          --this.mediaLibList.total_count;
          --this.mediaLibList.count;
        }
      }
    }).catch((error) => {
      console.log(error);
      this.isVisible = false;
      this.successMessageService.display("You can not delete default library.", "Delete Media Library", true,true);
    });

  }

  searchTerm : string = '';
  searchTermText : string = '';
  searchBy : string = 'created';
  showSortByDD = false;
  searchBrand() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.reloadMediaLibrarySearch();
  }

  clearSearchTerm() {
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.reloadMediaLibrarySearch();
  }

  sortBySearchResult(value) {
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.sortType = -1;
    this.hideSearchSortTypeDD(undefined);
    this.reloadMediaLibrarySearch();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }
  }

  sortTypeSearch(val : number){
    this.sortType = val;
    this.reloadMediaLibrarySearch();
  }

  loadPageEvent($event){
    if($event.pageSize != this.limit){
      this.limit = $event.pageSize;
      this.loadPage(1);
    } else {
      this.loadPage($event.pageIndex + 1);
    }
  }
}
