import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
declare var $ : any;
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  sSessionId: string;
  sUserEmail: any;
  fBillingForm: FormGroup;
  getBillingUrl: any;
  sDisplayBillingErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  bStripeIntegrationSuccess : boolean = false;

  constructor(
    private oFormBuilder: FormBuilder,
    private oSessionService: cSessionService,
    private oWebSocketService: cWebSocketService,
    private oActivatedRoute : ActivatedRoute
  ) { }

  ngOnInit() {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.InitializeJqueryCode()
    const sStripeResult = this.oActivatedRoute.snapshot.queryParams["pronto_session_id"];
    console.log("BillingComponent ngonInit : Manage Billing Response => ",sStripeResult)
    if(sStripeResult)
    {
      this.bStripeIntegrationSuccess = true;
      setTimeout(()=>{ this.bStripeIntegrationSuccess = false },5000);
    }
    else
    {
      this.BillingComponent_ManageBilling();
    }
  }

  BillingComponent_ManageBilling() 
  {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.fBillingForm = this.oFormBuilder.group({
      email: this.sUserEmail, 
      session_id: this.sSessionId
    });
    this.oWebSocketService.WebSocket_RegisterEvent('manage_billing_result', this.showBillingResult_HandleBillingResultResponse);
    this.oWebSocketService.WebSocket_SendMessage('manage_billing', JSON.stringify(this.fBillingForm.value));
  }

  showBillingResult_HandleBillingResultResponse = (InComingMessage) => 
  {

    if (JSON.parse(InComingMessage)['errorMessage']) 
    {
      this.sDisplayBillingErrorMessage = JSON.parse(InComingMessage)['errorMessage'];      
      this.bDisplayErrorMessage = true;
      setTimeout(()=>{this.bDisplayErrorMessage = false},5000);
    }
    else 
    {
      this.getBillingUrl = JSON.parse(InComingMessage).portal_redirect_url;
      window.location.assign(this.getBillingUrl);      
    }
  };

  BillingComponent_DeleteModal()
  {
    $('#delete_payment').modal('show')
  }

  InitializeJqueryCode() 
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      // end data-custom-select
    
    
      $(".customAccordionAction").on("click", function(){
        $(".customAccordion").removeClass("active");
        $(this).parent(".customAccordion").addClass("active");
      });
    
    // dropdownBtn
      $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    // end dropdownBtn
    
    });
    `;
    document.body.appendChild(chatScript);
  }

}