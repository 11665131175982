<div class="video-header row m-0 ms-3 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a class="me-2" (click)="backward()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
          fill="#404243" />
      </svg>
    </a>
    <span>{{mediaLibraryDetail?.name}} </span>
  </p>
</div>
<div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
  <div class="row filter-post">
    <div class="col-md-12 p-0">
      <ul class="breadcrumb-tabs nav m-0">
        <li class="nav-item ps-0">
          <a [routerLink]="['/account-setting/media-libraries/',mediaLibId,'account']" class="nav-link">Account</a>

        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" routerLink="/account-setting/user-media-libraries"
            class="nav-link active">Media</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div>
  <div class="py-4 account-heading border-bottom-0">
    <!-- <div class="row filter-post pm-4  pt-4 ps-3"><h4>Media Library </h4></div> -->
    <div class="container mx-0 px-0 business-media ps-4" id="container-view">
      <app-media-library-shared *ngIf="mediaLibraryDetail && mediaLibraryDetail?._id && mediaLibraryDetail._id != ''"
        [bSelectMultipleVisuals]="bSelectMultipleVisuals"
        [sInputVisualPath]="sInputVisualPath"></app-media-library-shared>
    </div>
  </div>


  <div class="overlay sidebarOverlay"></div>