<div class="timelineViewOnProntoTop">
    <h3 class="primaryFont weight-700 text-gray-2 text-md">Connect your accounts
    </h3>
    <p class="text-xs text-gray-2 mb-0">Share your Prontopia  creations to social</p>
    <div class="row">
        <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6">
            <div (click)="TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('google')"
                [ngClass]="connected_social_platforms && connected_social_platforms.google?'cursorClass':''"
                class="d-flex select-channels p-4 me-4  mt-3">
                <img width="40px" height="40px" src="../assets/images/dashboardVideo/Youtube.png" alt="sc">
                <p class="px-3"><b class="d-block">Youtube <img
                            *ngIf="connected_social_platforms && connected_social_platforms.google" width="12px"
                            height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.google"
                        class="text-xs text-primary-1 d-block"> Your Youtube account is connected to
                        Prontopia .</span>
                    <span *ngIf="connected_social_platforms && !connected_social_platforms.google">
                        Connect your YouTube account to allow Prontopia  to publish
                        videos to your channel.
                    </span>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.google"
                        class="text-xs text-primary-1 d-block">{{oUserName}}</span>
                </p>
                <a *ngIf="connected_social_platforms && !connected_social_platforms.google"
                    (click)=" TimelineViewProntoShareComponent_SocialMediaChannelsLink('google',$event)"
                    class=" btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1">Connect with
                    Youtube</a>
                <a *ngIf="connected_social_platforms && connected_social_platforms.google" href="javascript:void(0)"
                    (click)="TimelineViewProntoShareComponent_DisconnectSocialChannel('google',$event)"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow">Disconnect</a>

            </div>
        </div>

        <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6">
            <div (click)="TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('facebook')"
                [ngClass]="connected_social_platforms && connected_social_platforms.facebook?'cursorClass':''"
                class="d-flex select-channels p-4 me-4 mt-3">
                <img width="40px" height="40px" src="../assets/images/dashboardVideo/Facebook.png" alt="sc">
                <p class="px-3">
                    <b class="d-block">Facebook<img
                            *ngIf="connected_social_platforms && connected_social_platforms.facebook" width="12px"
                            height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.facebook"
                        class="text-xs text-primary-1 d-block"> Your Facebook account is connected
                        to Prontopia .</span>
                    <span *ngIf="connected_social_platforms && !connected_social_platforms.facebook">
                        Connect your Facebook account to allow Prontopia  to publish
                        videos to your channel.
                    </span>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.facebook"
                        class="text-xs text-primary-1 d-block">{{oUserName}}</span>
                </p>
                <a *ngIf="connected_social_platforms && !connected_social_platforms.facebook"
                    (click)=" TimelineViewProntoShareComponent_SocialMediaChannelsLink('facebook',$event)"
                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1">Connect with
                    Facebook</a>
                <a *ngIf="connected_social_platforms && connected_social_platforms.facebook" href="javascript:void(0)"
                    (click)="TimelineViewProntoShareComponent_DisconnectSocialChannel('facebook',$event)"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow">Disconnect</a>
            </div>
        </div>


        <div class="col-sm-12 social-channels-hover col-md-12 col-lg-6">
            <div (click)="TimelineViewProntoShareComponent_ShareVideoOnSocialChannel('linkedin')"
                [ngClass]="connected_social_platforms && connected_social_platforms.linkedin?'cursorClass':''"
                class="d-flex select-channels p-4 me-4">
                <img src="../assets/images/dashboardVideo/Linkedin.png" alt="Linkedin" height="40px" width="40px"
                    class="s-icon">
                <p class="px-3">
                    <b class="d-block">LinkedIn<img
                            *ngIf="connected_social_platforms && connected_social_platforms.linkedin" width="12px"
                            height="12px" src="../assets/images/dashboardVideo/verifyCheck.png" alt="sc"></b>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.linkedin"
                        class="text-xs text-primary-1 d-block"> Your LinkedIn account is connected
                        to Prontopia .</span>
                    <span *ngIf="connected_social_platforms && !connected_social_platforms.linkedin">
                        Connect your LinkedIn account to allow Prontopia  to publish
                        videos to your channel.
                    </span>
                    <span *ngIf="connected_social_platforms && connected_social_platforms.linkedin"
                        class="text-xs text-primary-1 d-block">{{oUserName}}</span>
                </p>
                <a *ngIf="connected_social_platforms && !connected_social_platforms.linkedin"
                    (click)=" TimelineViewProntoShareComponent_SocialMediaChannelsLink('linkedin',$event)"
                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 text-xs px-1">Connect with
                    LinkedIn</a>
                <a *ngIf="connected_social_platforms && connected_social_platforms.linkedin" href="javascript:void(0)"
                    (click)="TimelineViewProntoShareComponent_DisconnectSocialChannel('linkedin',$event)"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 small-btn btnShadow">Disconnect</a>
            </div>
        </div>

    </div>


</div>

<div class="modal fade" id="share-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body p-4">
                <!-- if no social page -->
               <div *ngIf="pages.length == 0" class="no-page ">
                <h5>Currently no page is available!</h5>
                <p  class="text-sm text-gray-3 pt-1 pb-4">There is no page created till now. Please create a page first.</p>
               </div>
               <!-- social pages -->
               <div class="social-page" *ngIf="pages.length">
                 <h5>Select page where you want to share your video</h5>
                 <p class="text-sm text-gray-3 pt-1 pb-4">The files will be permanently share. You can't undo this action</p>
                 <div>
                    <div class="dropdown dropdownSelect pb-4">
                        <label class="text-sm text-gray-3">Select your preferred page</label>
                        <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{nIncomingPage.name}}
                        </button>
                        <ul class="dropdown-menu dropdownMenu">
                            <li *ngFor="let page of pages" [class.active]="page.name == nIncomingPage.name" (click)="DashboardSocialMediaComponent_SelectPageForShare(page)"><a class="dropdown-item text-md" href="javascript:void(0)">{{page.name}}</a></li>
                        </ul>
                    </div>
                </div>
               </div>

                <a class="btn btn-secondary btn-round-6 text-xs"
                    data-bs-dismiss="modal">Cancel</a>
                    <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal" class="ms-4 mt-3 text-gray-3 text-sm" (click)="DashboardSocialMediaComponent_ShareVideo()">Share</a>

            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->
<div class="modal fade" id="share-modal-Youtube" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-4">
                <!-- if no social page -->
               <div class="no-page ">
                <h5>Are you sure you want to share the video on Youtube</h5>
                <p  class="text-sm text-gray-3 pt-1 pb-4"> The files will be permanently shared. You can't undo this action</p>
               </div>
               <!-- social pages -->
                <a class="btn btn-secondary btn-round-6 text-xs"
                    data-bs-dismiss="modal">Cancel</a>
                    <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal" class="ms-4 mt-3 text-gray-3 text-sm"
                    (click)="DashboardSocialMediaComponent_ShareVideoOnYoutube()">Share</a>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="share-modal-linkedin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-4">
                <!-- if no social page -->
               <div class="no-page ">
                <h5>Are you sure you want to share the video on LinkedIn</h5>
                <p  class="text-sm text-gray-3 pt-1 pb-4">The files will be permanently shared. You can't undo this action</p>
               </div>
               <!-- social pages -->
                <a class="btn btn-secondary btn-round-6 text-xs"
                    data-bs-dismiss="modal">Cancel</a>
                    <a href="javascript:void(0)" id="sidebarCollapse" data-bs-dismiss="modal" class="ms-4 mt-3 text-gray-3 text-sm"
                    (click)="DashboardSocialMediaComponent_ShareVideoOnLinkedIn()">Share</a>

            </div>
        </div>
    </div>
</div>