import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { cDataService } from "./../../../services/data.service";
import { cSessionService } from "./../../../services/session.service";
import { environment } from "src/environments/environment";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
import { profile, user } from "./../../../models/onboarding";
import { ConfigManagerService } from "./../../../Managers/config-manager.service";
import { ErrorService } from "./../../../services/error.service";
import * as moment from "moment";
import { SubscriptionComponent } from "../../myAccount/subscription/subscription.component";
import { SubscriptionplanManagerService } from "src/app/Managers/subscriptionplan-manager.service";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  sFacebookSigninLink: string;
  sGoogleSigninLink: string;
  sLinkedInSigninLink: string;
  sServerSocketURL = environment.API_HOST;
  fLoginForm: FormGroup;
  bDisplayPassword: boolean = false;
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  oMetaInfo: any;
  nRedirectURl: any;
  sSessionId: any;

  constructor(
    private oCookieService: CookieService,
    private subscrptionManager: SubscriptionplanManagerService,
    private oFormBuilder: FormBuilder,
    private onboardingManager: OnboardingManagerService,
    private oRouter: Router,
    private route: ActivatedRoute,
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    private oActivatedRoute: ActivatedRoute,
    private configManager: ConfigManagerService,
    public errorService: ErrorService
  ) {}

  ngOnInit() {
    this.oSessionService.SessionService_SetVideoTimeout();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
    this.fLoginForm = this.oFormBuilder.group({
      email: ["", [Validators.required, Validators.pattern(sEmailRejex)]],
      password: ["", [Validators.required]],
    });

    if (!this.sSessionId) {
      let intercomScript1 = document.getElementById("intercom1");
      let intercomScript2 = document.getElementById("intercom2");
      if (intercomScript1) intercomScript1.remove();
      if (intercomScript2) intercomScript2.remove();
      this.oCookieService.deleteAll();
    }

    this.oActivatedRoute.queryParams.subscribe((params: Params) => {
      //facebook and google login configurations
      const authResult =
        this.oActivatedRoute.snapshot.queryParams["auth_result"];
      console.log("Auth result : ==> ", authResult);
      //social share response
      const socialShareResult =
        this.oActivatedRoute.snapshot.queryParams["result"];
      console.log("Social Share response : ==> ", socialShareResult);
      //redirection message
      const message = this.oActivatedRoute.snapshot.queryParams["message"];
      console.log("Response Message : ==> ", message);
      let emailw = this.oActivatedRoute.snapshot.queryParamMap.get("email");
      const sessionId =
        this.oActivatedRoute.snapshot.queryParamMap.get("session_id");
      this.oSessionService.cSessionService_SetUserEmail(emailw);
      this.oSessionService.cSessionService_SetSessionId(sessionId);
      this.oSessionService.SessionService_StartSessionExpiryTimer();
      // this.sErrorMessage = message;
      // if (authResult || socialShareResult) {
      //   console.log("Auth and social share ...");
      //   this.nRedirectURl = this.oSessionService.cSessionService_GetRedirectURL();
      //   if (authResult) {
      //     //connect/disconnect response
      //     if (authResult == 'google_auth_successful'
      //       || authResult == 'fb_auth_successful'
      //       || authResult == 'linkedin_auth_successful'
      //       || authResult == 'success') {
      //       if (this.nRedirectURl == 0) {
      //         const email = this.oActivatedRoute.snapshot.queryParamMap.get('email');
      //         const sessionId = this.oActivatedRoute.snapshot.queryParamMap.get('session_id');
      //         this.oSessionService.cSessionService_SetUserEmail(email);
      //         this.oSessionService.cSessionService_SetSessionId(sessionId);
      //         this.oSessionService.SessionService_StartSessionExpiryTimer();
      //         this.oRouter.navigateByUrl('/dashboard/myvideos');
      //       }
      //       else {
      //         this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
      //       }
      //     }
      //     else {
      //       if (this.nRedirectURl == 0) {
      //         this.bDisplayErrorMsg = true;
      //         setTimeout(() => { this.bDisplayErrorMsg = false }, 5000);
      //       }
      //       else {
      //         this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
      //       }
      //     }
      //   }
      //   else {
      //     //social share response
      //     this.oSessionService.cSessionService_SetRedirectionMessage(socialShareResult);
      //     this.LoginComponent_RedirectToRespectivePage(this.nRedirectURl);
      //   }
      // }
      //else {
      console.log("Get profile page /./////////");
      const session_id = this.oSessionService.SessionService_GetSessionId();

      const email = this.oSessionService.cSessionService_GetUserEmail();
      if (session_id) {
        this.getProfile(email, session_id);
        // this.LoginComponent_GetUserVideosCount(alreadyLogin)
        // this.oRouter.navigateByUrl("/dashboard/myvideos");
      }
      // }
    });
  }
  async getProfile(email, session) {
    this.onboardingManager
      .getUserProfile(email, session)
      .then(async (res: profile) => {
        await this.configManager.getTemplates2(
          email,
          session,
          res.allowed_templates
        );
        this.configManager.getVoices(email, session);
        console.log("Redirect to account settings//.");
        this.oRouter.navigateByUrl("/account-setting/myAccount");
      });
  }

  LoginComponent_RedirectToRespectivePage(sIncommingRedirection: number) {
    switch (sIncommingRedirection) {
      case 1:
        this.oRouter.navigateByUrl("/my-account");
        break;
      case 2:
        this.oRouter.navigateByUrl("/account-setting/social-channels");
        break;
      case 3:
        this.oRouter.navigateByUrl("/dashboard/socialmedia");
        break;
      case 4:
        this.oRouter.navigateByUrl("/published-video/timeline-view");
        break;
      default:
        this.oRouter.navigateByUrl("/account-setting/users");
        break;
    }
  }

  LoginComponent_CreateSigninWithSocialMediaChannelsLink(oData: string) {
    let sURL: string;
    if (oData == "facebook") {
      sURL = this.oDataService.faceBookJoinLink;
    } else if (oData == "google") {
      sURL = this.oDataService.googleJoinLink;
    } else if (oData == "linkedin") {
      sURL = this.oDataService.linkedInJoinLink;
    }
    this.oSessionService.cSessionService_SetRedirectURL(0);
    location.assign(sURL);
  }

  LoginComponent_SendLoginRequest() {
    if (this.fLoginForm.valid) {
      this.Login(
        this.fLoginForm.get("email").value,
        this.fLoginForm.get("password").value
      );
    } else {
      this.fLoginForm.markAllAsTouched();
    }
  }

  LoginComponent_HandleMetaInfoResult = (InComingMessage) => {
    console.log(
      "LoginComponent_HandleMetaInfoResult : Response ==> ",
      JSON.parse(InComingMessage)
    );
    if (InComingMessage["errorMessage"]) {
      console.error(
        "LoginComponent_HandleMetaInfoResult : Error ==> ",
        InComingMessage
      );
    } else {
      // industries
      this.oMetaInfo = JSON.parse(InComingMessage).industry_key_terms;
      this.oSessionService.cSessionService_SetMetaInfo(this.oMetaInfo);
    }
  };

  private Login(username, password) {
    console.log("on barding sign in here ...");
    this.onboardingManager.signin(username, password).then(
      (response: user) => {
        console.log("user is here after login");

        console.log("ress" + response);

        this.LoginComponent_RedirectToRespectivePage(6);
        // this.onboardingManager.getUserVideosCount(this.fLoginForm.get('email').value, response.session_id).then(count => {
        // console.log("redirect to account page..");
        // this.LoginComponent_RedirectToRespectivePage(6);
        // let params = this.route.snapshot.queryParams;
        // if (params['redirectURL']) {
        //   this.nRedirectURl = params['redirectURL'];
        // }

        // if (this.nRedirectURl) {
        //   this.oRouter.navigateByUrl(this.nRedirectURl)
        //     .catch(() => this.navigatetodashboard(count));
        // } else {
        //   this.navigatetodashboard(count);
        // }

        // }).catch(err => {
        //   // this.errorService.handleError(err.errorMessage,err.errorType,true);
        //   console.log(err);
        //   this.sErrorMessage = "Incorrect email or password.";
        //   this.bDisplayErrorMsg = true;
        //   setTimeout(() => { this.bDisplayErrorMsg = false; }, 5000);
        //   $('#error-popup').modal("show");
        // });
      },
      (err) => {
        console.log(' ---- ', err);
        console.log(err);
        this.sErrorMessage = "Invalid email or password. Please try again";
        this.bDisplayErrorMsg = true;
        setTimeout(() => {
          this.bDisplayErrorMsg = false;
        }, 5000);
        $("#error-popup").modal("show");
      }
    );
  }

  private navigatetodashboard(count: unknown) {
    if (count > 0) {
      this.oRouter.navigateByUrl("/dashboard/myvideos");
    } else {
      this.oRouter.navigateByUrl("/dashboard/first-time-user");
    }
  }
}
