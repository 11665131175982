import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../Managers/media-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { mediaFile, visual } from './../../models/media';
import { video } from './../../models/video';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';

@Component({
  selector: 'app-video-sidepanel',
  templateUrl: './video-sidepanel.component.html',
  styleUrls: ['./video-sidepanel.component.scss']
})
export class VideoSidepanelComponent implements OnInit {

  @Output() onClose = new EventEmitter<any>();
  @ViewChild('closeModal') closeModal: ElementRef;
  @Input() video;
  videoThumbUrl: any;
  oVideo: any;
  oUploadedLogo: any;
  nUploadedLogoSize: number = 0;
  sUploadedLogoName: string = "No file choosen.";
  sSessionID: string = "";
  sUserEmail: string = "";
  fLooknFeelForm : FormGroup;
  bDisplayUploadLogoSection:boolean = true;
  bRevertSettings:boolean = true;
  sDisplayResponseErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  figActive: boolean = true;
  
  constructor(public videoManager:CreateVideoManagerService,private oEditVideoService: cEditVideoService,private onBoardingManager:OnboardingManagerService, private oDataService: cDataService,
    private oSessionService: cSessionService,private mediaManager:MediaManagerService) { }

  async ngOnInit() {
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
if(this.videoManager.selectedVideo.logo_img && this.videoManager.selectedVideo.logo_img !='')
{
    this.oUploadedLogo = await this.oEditVideoService.EditVideoService_ReturnFileDetails(this.onBoardingManager.profile.logo_file); 
 }
 }
  CreateVideoLooknFeelComponent_ToggleLogoSection(event: any) {
    let value = event.target.value;
    if (value === "upload-logo") {
      this.bDisplayUploadLogoSection = true;
    }
    else {
      this.bDisplayUploadLogoSection = false;
    }
 
  }

  closeVideo(): void {

    this.onClose.emit();
        //this.visibleMedia = false;
      }
      TimelineViewSidePanelComponent_PauseVideoOnClose(){

      }
      TimelineViewSidepanelsComponent_RevertLooknFeelForm(){
        this.oUploadedLogo= '';
        let logo = '';
        if(logo== '')
        this.videoManager.selectedVideo.logo_img= logo;
        this.closeVideo();
      }
      TimelineViewSidePanelComponent_PlayVideo(){
        this.figActive = false;
      }
      TimelineViewSidepanelsComponent_SaveLooknFeelForm()
      {
       let logo = this.oUploadedLogo? this.oUploadedLogo.visual_path:'';
       if(logo != '')
this.videoManager.selectedVideo.logo_img =logo ;

this.closeVideo();
      }
      async TimelineViewSidePanelsComponent_ToggleLogoSection(event: any)
      {
        let value = event.target.value;
        if (value === "upload-logo") {
          this.bDisplayUploadLogoSection = true;
        }
        else {
        
          if(this.onBoardingManager.profile.logo_file){
            this.oUploadedLogo = await this.oEditVideoService.EditVideoService_ReturnFileDetails(this.onBoardingManager.profile.logo_file); 
          }
          else{
          this.bDisplayUploadLogoSection = false;
        }
        }
      }
      TimelineViewSidePanelComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
          return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      }

      async CreateVideoLooknFeelComponent_UploadedLogoFile(event) {
        let file = event.target.files[0];
        this.nUploadedLogoSize = file.size;
        console.log("CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ", file);


        let media = new mediaFile();
        media.file = file;
        media.name = file.name;
    
        this.mediaManager.uploadMediaFilesAdmin(this.sUserEmail,this.sSessionID,media).then((res:visual)=>{
          this.oUploadedLogo = res;
          this.sUploadedLogoName = file.name;
          this.closeModal.nativeElement.click();
       
    
        }).catch(error=>{
          this.oUploadedLogo = null;
          this.sDisplayResponseErrorMessage = error.error.errorMessage;
          this.bDisplayErrorMessage = true;
          this.closeModal.nativeElement.click();
          setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
        });


/*
        var oFormData: any = new FormData();
        oFormData.append('session_id', this.sSessionID);
        oFormData.append('email', this.sUserEmail);
        oFormData.append('file', file);
        oFormData.append("logo", true);
    
        try {
          await this.oDataService.DataService_PostUploadedVisuals(oFormData).subscribe((result: any) => {
            console.log("CreateVideoLooknFeelComponent_UploadedLogoFile : Response ==>", result);
            this.oUploadedLogo = result;
            this.sUploadedLogoName = result.filename;
            //this.fLooknFeelForm.patchValue({oUploadedLogo : this.oUploadedLogo, nUploadedLogoSize : this.nUploadedLogoSize});
            this.closeModal.nativeElement.click();
          },
            (error) => {
              console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : Error ==>", error);
              this.oUploadedLogo = null;
              this.sDisplayResponseErrorMessage = error.error.errorMessage;
              this.bDisplayErrorMessage = true;
              this.closeModal.nativeElement.click();
              setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
            });
        }
        catch (error) {
          console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : ERROR ==>", error);
        }*/
      }
}
