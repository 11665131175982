import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { cSessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class cIntercomService {
  constructor(
    private oCookieService: CookieService,
    private oSessionService: cSessionService,
    private http: HttpClient
  ) {}
  cIntercomService_InitializeIntercomForLoggedInUser(oIncommingObject: any) {
    let intercomScript = document.getElementById("intercom1");

    if (intercomScript) {
      console.log("Intercom script already initialized");
    } else {
      let created_at = oIncommingObject?.created_at;
      let name = "";
      let email = this.oSessionService.cSessionService_GetUserEmail();
      let intercom: any;
      if (oIncommingObject.first_name) {
        name = oIncommingObject.first_name;
      } else if (oIncommingObject.last_name) {
        name = oIncommingObject.last_name;
      } else if (oIncommingObject.name) {
        name = oIncommingObject.name;
      } else {
        name = email.substring(0, email.lastIndexOf("@"));
      }
      // for intercom
      intercom = {
        api_base: "https://api-iam.intercom.io",
        app_id: "pyztwqyr",
        name: name,
        email: email,
        created_at: created_at,
      };

      this.cIntercomService_InitializeIntercomCode(intercom);
    }
  }
  cIntercomService_InitializeIntercomCode(intercom) {
    const chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.id = "intercom1";
    chatScript.text = "window.intercomSettings = " + JSON.stringify(intercom);
    document.body.appendChild(chatScript);
    this.cIntercomService_InitializeIntercomCode1();
  }

  cIntercomService_InitializeIntercomCode1() {
    const chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.id = "intercom2";
    chatScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pyztwqyr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    document.body.appendChild(chatScript);
  }
  cIntercomService_RemoveLoggedInUserIntercomCredentials() {
    //Remove intercom code
    console.log('logout called..');
    this.http
      .post("/admin/logout", "")
      .toPromise()
      .then((response) => {
        let intercomScript1 = document.getElementById("intercom1");
        let intercomScript2 = document.getElementById("intercom2");
        if (intercomScript1) intercomScript1.remove();
        if (intercomScript2) intercomScript2.remove();
        this.oCookieService.deleteAll();
        window.location.assign("/login");
        console.log("Redirecting to login ");
      }).catch((error) => {
        this.oCookieService.deleteAll();
        window.location.assign("/login");
        console.log("Redirecting to login ");
      });
  }
}
