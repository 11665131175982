import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { cDataService } from '../../services/data.service';
import { cSessionService } from '../../services/session.service';
import { cWebSocketService } from   '../../services/websocket.service';
import { MediaManagerService } from '../../Managers/media-manager.service';
import { mediaLib } from '../../../app/models/media';

@Component({
  selector: 'app-media-library-popup',
  templateUrl: './media-library-popup.component.html',
  styleUrls: ['./media-library-popup.component.scss']
})
export class MediaLibraryPopupComponent implements OnInit, OnChanges {

  lBreadcrumbOptions : any = [
    {name : 'All' , filter : 'all'},
    {name : 'Videos' , filter : 'clip'},
    {name : 'Images', filter : 'image'},
    // {name : 'Audio', filter : 'audio'},
    // {name : 'Icons', filter : 'Icons'},
    // {name : 'Text Snippets', filter : 'Snippets'},
  ]
  selectedOption: any;

  sSessionId : string;
  sUserEmail : string;
  sResponseStatus : string;
  fUserVisualListForm: FormGroup;
  fUserVisualCountForm: FormGroup;
  lUserVisualsList: any = [];
  nUserVisualID: number = 0;
  nUserVisualsCount: number;
  nUserImagesCount: number;
  nUserClipsCount: number;
  // nUserAudiosCount: number;
  search:string='';
  sVisualType: string = 'all';
  nAlreadyFetchedVisuals: number = 0;
  nPageSize : number = 8;
  lPagination : any = [];
  nCurrentPage : number = 1;
  sSelectedMediaLibrary : string;
  bMediaLoader : boolean = false;
  mediaLibStatus ="all";
  lSelectedUserVisualsList: any =[];
  @Output() lEmittedFilesToUpload = new EventEmitter<any[]>();
  @Input() oInputVisualToRemove : any;
  @Input('bInputEnableMultiSelection') bInputEnableMultiSelection : boolean;
  @Input() isSlideout : boolean =false;

  
  bSelectionType : boolean = false;
  lMediaLibraryList : any= [];
  lMediaLibraryListCopy : any= [];
  selectedVisualArr : any= [];
  fSearchLibraryForm: FormGroup;
  @Input('sInputLibraryName') sInputLibraryName : any;
  bHideMediaLibrarySearchOptions : boolean = false;
  @Output() onClose = new EventEmitter<any>();
  @Input() mediaPopUpLoc : any;
  @Input() isSingleSelected = false;

  
  constructor
  (
    private oDataService: cDataService, private oSessionService: cSessionService, public oFormBuilder: FormBuilder,
      public mediaManager:MediaManagerService, 
  ){}

  ngOnInit(): void 
  {
  // this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
     this.fUserVisualListForm = this.oFormBuilder.group({ email: this.sUserEmail, session_id: this.sSessionId, num_skip: 0, num_items: 10, visual_type: '',media_lib: this.sSelectedMediaLibrary });
     this.fUserVisualCountForm = this.oFormBuilder.group({email: this.sUserEmail, session_id: this.sSessionId, visual_type: '',media_lib: this.sSelectedMediaLibrary });
    //this.oWebSocketService.WebSocket_RegisterEvent('user_visual_list_result', this.MediaLibrarySharedComponent_HandleUserVisualsResponse);
    this.mediaManager.getMediaLibraries(this.sUserEmail,this.sSessionId, this.mediaLibStatus).then();
    this.loadVisual('all');
    //for enabling multi selection (by default user can only select single visual)
    if(!this.bInputEnableMultiSelection && this.bInputEnableMultiSelection==false)
    {
      this.bSelectionType=true;
      console.log("Selection type  : ==> ",this.bSelectionType);
    }
    console.log("Selection type (bInputEnableMultipleSelection) : ==> ",this.bInputEnableMultiSelection);
    this.fSearchLibraryForm = this.oFormBuilder.group({ name: '' });
    this.MediaLibrarySharedComponent_SearchMediaLibrary();
    if(this.sInputLibraryName)
    {
      this.bSelectionType=true;
      this.bHideMediaLibrarySearchOptions = true;
      // this.sSelectedMediaLibrary = this.sInputLibraryName;
      this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
    }
    else
    {
      this.bHideMediaLibrarySearchOptions = false;
      this.MediaLibrarySharedComponent_DisplayGetMediaLibraryList();
    }
    console.log("vis log", this.mediaManager.visuals);
  }

  searchMediaLibrary()
{
   console.log(this.mediaManager.selectedmedialib);
  this.mediaManager.getVisuals(
    this.sUserEmail,
    this.sSessionId,
    this.sVisualType,
    (this.mediaManager.selectedmedialib) ? this.mediaManager.selectedmedialib.name : '',
    this.nPageSize,0,
    this.search).then(_=>{  
      this.createPagination();
     
  });
}

  loadVisual(visualType:string)
{
  if(this.mediaPopUpLoc){
    this.sVisualType = visualType;
    
this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,'clip',this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,0,this.search).then(_=>{
  this.createPagination();
});
  }
  else{
  this.sVisualType = visualType;
this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,visualType,this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,0,this.search).then(_=>{
  this.createPagination();
});
  }
}


  ngOnChanges(): void 
  {
    if(this.oInputVisualToRemove)
    {
      for(let nSlectedVisualIndex=0; nSlectedVisualIndex<this.lUserVisualsList.length;nSlectedVisualIndex++)
      {
        if(this.lUserVisualsList[nSlectedVisualIndex].visual_path === this.oInputVisualToRemove.visual_path)
        {
          this.lUserVisualsList[nSlectedVisualIndex].checked = false;
        }  
      }
    }
  }
  MediaLibrarySharedComponent_DisplayGetMediaLibraryList()
  {
    //this.oWebSocketService.WebSocket_RegisterEvent('user_media_libs_result', this.MediaLibrarySharedComponent_HandleMediaLibraryListResponse);
   // this.oWebSocketService.WebSocket_SendMessage('user_media_libs_request', JSON.stringify({email : this.sUserEmail,session_id : this.sSessionId}));
  }
  MediaLibrarySharedComponent_HandleMediaLibraryListResponse = (IncomingMessage) =>
  {
    console.log("MediaLibrarySharedComponent_HandleMediaLibraryListResponse: IncomingMessage",JSON.parse(IncomingMessage));
    
    if(JSON.parse(IncomingMessage).errorMessage)
    {
      console.error("MediaLibrarySharedComponent_HandleMediaLibraryListResponse : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
    }
    else
    {
      let oFormatedIncommingMessage =  JSON.parse(IncomingMessage);
      this.lMediaLibraryList = oFormatedIncommingMessage.media_libs;
      this.lMediaLibraryListCopy = JSON.parse(JSON.stringify(this.lMediaLibraryList));
      this.sSelectedMediaLibrary = this.lMediaLibraryList[0].name?this.lMediaLibraryList[0].name:'default';
      this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
    }
    //this.oWebSocketService.WebSocket_UnRegisterEvent('user_media_libs_result', this.MediaLibrarySharedComponent_HandleMediaLibraryListResponse);
  }  
  MediaLibrarySharedComponent_DisplayMyMediaLibrary() 
  {
  //  this.oWebSocketService.WebSocket_RegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);
   // this.MediaLibrarySharedComponent_GetUserImagesCount();
   // this.MediaLibrarySharedComponent_GetUserClipsCount();
    // this.MediaLibrarySharedComponent_GetUserAudioCount();
  }
  
  MediaLibrarySharedComponent_ChangeMedialibrary(event : any, selectedMedia)
  {
    const bChecked  = event.target.checked;

    this.mediaManager.selectedmedialib = selectedMedia;
   
    console.log('change Medialibrary' )

    if(this.mediaPopUpLoc){
      this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,'clip' ,this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,0,this.search).then(_=>{
        this.createPagination();
      });
    }
    else{
      this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,this.sVisualType ,this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,0,this.search).then(_=>{
        this.createPagination();
      });

    }
      

    const sValue = event.target.value;
    // console.log("Media library checked {%s} with Name {%s}",bChecked,sValue);
    if(bChecked)
    {
      if(this.sSelectedMediaLibrary!==sValue)
      {
        this.sSelectedMediaLibrary = sValue;
        this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
        //if user stays on same tab then we have to reset view
        
        this.nCurrentPage=1;
        this.nUserImagesCount=undefined;
        this.nUserClipsCount=undefined;
        this.nUserVisualsCount=undefined;
      }
    }
  }

createPagination()
{
  console.log(this.mediaManager.visualsCount);
  this.lPagination=[];
   let pages= Math.ceil(this.mediaManager.visualsCount/this.nPageSize);
  for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) 
  {
    this.lPagination.push(nPaginationIndex);
  }
  console.log("Pagination List : ==> ",this.lPagination);
}

selectedVisual(visual){
  visual.isSelected = !visual.isSelected;
  this.selectedVisualArr.push[visual];
}



  MediaLibrarySharedComponent_SearchMediaLibrary()
  {
    this.fSearchLibraryForm.get('name').valueChanges.pipe(debounceTime(1000),distinctUntilChanged())
    .subscribe(data=>
    {
      // console.log("Search media  : ==> ", data)
      if(data=="")
      {
        this.lMediaLibraryList = JSON.parse(JSON.stringify(this.lMediaLibraryListCopy));
      }
      else
      {
        this.lMediaLibraryList = this.lMediaLibraryListCopy.filter(library => {return library.name.indexOf(data) !== -1}); 
      }
    });

  }


  MediaLibrarySharedComponent_FetchPage(nIncommingPageNumber : number) 
  {
    
    this.nCurrentPage=nIncommingPageNumber;
    let skipVideos = (nIncommingPageNumber-1)* 8;
    console.log("skipvid",skipVideos)
    if(this.mediaPopUpLoc){
      this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,'clip',this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,skipVideos,this.search).then();
    }
    else{
      this.mediaManager.getVisuals(this.sUserEmail,this.sSessionId,this.sVisualType,this.mediaManager.selectedmedialib?this.mediaManager.selectedmedialib.name:'',this.nPageSize,skipVideos,this.search).then();
}
    /* if(nIncommingPageNumber!==this.nCurrentPage)
    {
      this.MediaLibrarySharedComponent_ResetView();  
      this.nCurrentPage=nIncommingPageNumber;
      let skipVideos = (nIncommingPageNumber-1)*8;
      if (this.sVisualType == 'clip') {
        this.MediaLibrarySharedComponent_GetUserClips(8,skipVideos);
      }
      else if (this.sVisualType == 'image') {
        this.MediaLibrarySharedComponent_GetUserImage(8,skipVideos);
      }
      // else if (this.sVisualType == 'audio') {
      //   this.MediaLibrarySharedComponent_GetUserAudios(18,skipVideos);
      // }
      else
      {
        this.nPageSize = 8/this.nUserVisualsCount
        console.log("Total : ==> ", this.nPageSize);
        let nImages  = Math.floor(this.nPageSize*this.nUserImagesCount);
        let nClips  = Math.floor(this.nPageSize*this.nUserClipsCount);
        let imageSkip  = (nIncommingPageNumber-1)*nImages;
        let clipSkip  = (nIncommingPageNumber-1)*nClips;
        this.MediaLibrarySharedComponent_GetUserImage(nImages,imageSkip);
        this.MediaLibrarySharedComponent_GetUserClips(nClips,clipSkip);
        // this.MediaLibrarySharedComponent_GetUserAudios(nAudios,audioSkip);
      }
    } */
  }
  MediaLibrarySharedComponent_ResetView() 
  {
    console.log("Reset Called ===>>>>")
    this.lUserVisualsList = [];
    // this.lSelectedUserVisualsList = [];
    this.nAlreadyFetchedVisuals = 0;
    this.nUserVisualID = 0;
    this.fUserVisualListForm = this.oFormBuilder.group({email: this.sUserEmail, session_id: this.sSessionId, num_skip: 0, num_items: 10, visual_type: '',media_lib: this.sSelectedMediaLibrary });
  }
  MediaLibrarySharedComponent_GetUserImage(nIemsToFetch : number, nItemsToSkip : number) 
  {
    this.fUserVisualListForm.patchValue({visual_type: 'image', num_items : nIemsToFetch, num_skip : nItemsToSkip,media_lib: this.sSelectedMediaLibrary});
  //  this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
  }
  MediaLibrarySharedComponent_GetUserClips(nIemsToFetch : number, nItemsToSkip : number) 
  {
    this.fUserVisualListForm.patchValue({visual_type: 'clip', num_items : nIemsToFetch, num_skip : nItemsToSkip,media_lib: this.sSelectedMediaLibrary});
  //  this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
  }
  // MediaLibrarySharedComponent_GetUserAudios(nIemsToFetch : number, nItemsToSkip : number)
  // {
  //   this.fUserVisualListForm.patchValue({visual_type: 'audio', num_items : nIemsToFetch, num_skip : nItemsToSkip,media_lib: this.sSelectedMediaLibrary});
  //   this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
  // }
  MediaLibrarySharedComponent_GetUserImagesCount() 
  {
    this.fUserVisualCountForm.patchValue({visual_type: 'image',media_lib: this.sSelectedMediaLibrary});
 //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
  } 
  MediaLibrarySharedComponent_GetUserClipsCount() 
  {
    this.fUserVisualCountForm.patchValue({visual_type: 'clip',media_lib: this.sSelectedMediaLibrary});
 //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
  }
  // MediaLibrarySharedComponent_GetUserAudioCount() 
  // {
  //   this.fUserVisualCountForm.patchValue({ visual_type: 'audio'});
  //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
  // }
  MediaLibrarySharedComponent_SelectMediaType(oIncommingMediaCategory : any) 
  {
    console.log("Incomming visual type : ==> ", oIncommingMediaCategory)
    if(this.sVisualType!==oIncommingMediaCategory)
    {
    this.sVisualType = oIncommingMediaCategory;
    this.MediaLibrarySharedComponent_ResetView();

    this.loadVisual(this.sVisualType)
   /*  if (this.sVisualType == 'clip') 
    {
      this.MediaLibrarySharedComponent_GetUserClips(8,0);
      this.MediaLibrarySharedComponent_DrawPagination(this.nUserClipsCount);
    }
    else if (this.sVisualType == 'image') 
    {
      this.MediaLibrarySharedComponent_GetUserImage(8,0);
      this.MediaLibrarySharedComponent_DrawPagination(this.nUserImagesCount);
    }
    // else if (this.sVisualType == 'audio') 
    // {
    //   this.MediaLibrarySharedComponent_GetUserAudios(18,0);
    //   this.MediaLibrarySharedComponent_DrawPagination(this.nUserAudiosCount);
    // }
    else
    {
      this.MediaLibrarySharedComponent_DrawPagination(this.nUserVisualsCount);
      this.nPageSize = 8/this.nUserVisualsCount;
      console.log("Page Size  : ==> ", this.nPageSize);
      this.MediaLibrarySharedComponent_GetUserImage(Math.ceil(this.nPageSize*this.nUserImagesCount),0);
      this.MediaLibrarySharedComponent_GetUserClips(Math.ceil(this.nPageSize*this.nUserClipsCount),0);
      // this.MediaLibrarySharedComponent_GetUserAudios(Math.floor(this.nPageSize*this.nUserAudiosCount),0);
    } */
    }
  }
  BusinessProfileSidepanelsComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  MediaLibrarySharedComponent_HandleUserVisualsResponse = async (IncomingMessage) => {
    if (IncomingMessage.errorMessage) 
    {
      console.error('MediaLibrarySharedComponent_HandleUserVisualsResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
    }
    else 
    {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log('MediaLibrarySharedComponent_HandleUserVisualsResponse (Raw_Response): ', formatedIcomingMessage);
      this.bMediaLoader=true;
      if (formatedIcomingMessage.length !== 0) 
      {
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < formatedIcomingMessage.length; nVisualToPushIndex++) 
        {
          await this.MediaLibrarySharedComponent_GenerateDisplayList(formatedIcomingMessage[nVisualToPushIndex]);
        }
        // this.MediaLibrarySharedComponent_InitializeJqueryCode();
      }
      else 
      {
        console.log('You have got a empty array in the response');
      }
      this.bMediaLoader=false;
      console.log('List to display :', this.lUserVisualsList);
    }
  }

  async MediaLibrarySharedComponent_GenerateDisplayList(oIncommingVisual : any)
  {
    const imageStaticThumb = "../assets/images/business_Profile/audio-line.png";
    this.nUserVisualID = this.nUserVisualID + 1;
    let visual_path = oIncommingVisual.visual_path;
    let filename = visual_path.substr(visual_path.indexOf('.com/')+5,visual_path.length);
    let key_terms = oIncommingVisual.key_terms?oIncommingVisual.key_terms:[];
    let url = await this.oDataService.DataService_GetSignedURLForDownload(visual_path);
    let visual_type = oIncommingVisual.visual_type;
    let thumbnail = (!oIncommingVisual.thumb && visual_type!=="audio")?await this.oDataService.DataService_GetUserVisualThumbnail(visual_path):oIncommingVisual.thumb;
    let size = oIncommingVisual.size;
    let duration = oIncommingVisual.duration
    let library = oIncommingVisual.media_lib;
    let used_in = oIncommingVisual.videos_used_in?oIncommingVisual.videos_used_in:[];
    let checked : boolean = false ;
    let type = this.bSelectionType==true?'radio':'checkbox';
    let sThumbnail : string; 
    if(visual_type=='audio')
    {
      sThumbnail = imageStaticThumb
    }
    else
    {
      sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(thumbnail);
    }
    if(this.sVisualType==="image" && visual_type==="image")
    {
      this.lUserVisualsList.unshift({id: this.nUserVisualID, thumb: sThumbnail, url: url, visual_path: visual_path, key_terms: key_terms,visual_type : visual_type, name : filename, size : size, library : library, used_in : used_in,checked : checked, duration : duration, type : type});
    }
    else if(this.sVisualType==="clip" && visual_type==="clip")
    {
      this.lUserVisualsList.unshift({ id: this.nUserVisualID, thumb: sThumbnail, url: url, visual_path: visual_path, key_terms: key_terms,visual_type : visual_type, name : filename, size : size, library : library, used_in : used_in,checked : checked, duration : duration, type : type});
    }
    else if(this.sVisualType==="all")
    {
      this.lUserVisualsList.unshift({id: this.nUserVisualID, thumb: sThumbnail, url: url, visual_path: visual_path, key_terms: key_terms,visual_type : visual_type, name : filename, size : size, library : library, used_in : used_in,checked : checked, duration : duration, type : type});
    }
    // console.log('List to display :', this.lUserVisualsList);

  }

  MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse = (IncomingMessage) => {
    if (IncomingMessage.errorMessage) 
    {
      console.error('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
    }
    else 
    {
      let formatedIcomingMessage = JSON.parse(IncomingMessage);
      console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : ', formatedIcomingMessage);
      if (formatedIcomingMessage.visual_type == 'image') 
      {
        this.nUserImagesCount = formatedIcomingMessage.count;
      }
      else if (formatedIcomingMessage.visual_type == 'clip') 
      {
        this.nUserClipsCount = formatedIcomingMessage.count;
      }
      // else if (formatedIcomingMessage.visual_type == 'audio') 
      // {
      //   this.nUserAudiosCount = formatedIcomingMessage.count;
      // }
      // this.nUserVisualsCount = this.nUserImagesCount + this.nUserClipsCount + this.nUserAudiosCount;
      this.nUserVisualsCount = this.nUserImagesCount + this.nUserClipsCount;
      console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Total visuals count ==> ', this.nUserVisualsCount);
      if (this.nUserVisualsCount)
      {
        this.MediaLibrarySharedComponent_SelectMediaType('all');
     //   this.oWebSocketService.WebSocket_UnRegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);
      }
    }
  };
  MediaLibrarySharedComponent_DrawPagination(nIncommingVisualCount : number)
  {
    this.lPagination=[];
    this.nPageSize = Math.ceil(nIncommingVisualCount/8);
    for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) 
    {
      this.lPagination.push(nPaginationIndex)
    }
    console.log("Paginationlist : ==> ", this.lPagination);
  }
  MediaLibrarySharedComponent_PushSelectedVisual(oIncommingVisual : any)
  {
 
    if(this.isSingleSelected){
      this.mediaManager.visuals.forEach(element => {
      element.isSelected = false;
      
      });

      this.lSelectedUserVisualsList = [];
    }

    console.log("incoming visual",this.bSelectionType);
    console.log("Selection Type Is Single:",this.bSelectionType);
    //pass single values


    oIncommingVisual.isSelected =! oIncommingVisual.isSelected;
    if(this.bSelectionType)
    {
      this.mediaManager.visuals.forEach(c=>{
          if(c.visual_path != oIncommingVisual.visual_path) 
          {
            
          c.isSelected =false;
        
        }else
        {
          console.log(oIncommingVisual);
        }
        });
      console.log("List Updated",this.mediaManager.visuals);
      this.lSelectedUserVisualsList=[];
      this.lSelectedUserVisualsList.push(oIncommingVisual);
      for(let nSlectedVisualIndex=0; nSlectedVisualIndex<this.lSelectedUserVisualsList.length;nSlectedVisualIndex++)
      {
        if(this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path===oIncommingVisual.visual_path)
        {
          this.lSelectedUserVisualsList[nSlectedVisualIndex].isSelected = true;
        }
        else
        {
          this.lSelectedUserVisualsList[nSlectedVisualIndex].isSelected = false;
        }  
      }

         // oIncommingVisual.isSelected = !oIncommingVisual.isSelected;
    console.log("List Updated2",this.mediaManager.visuals);
    this.MediaLibrarySharedComponent_PassSelectedVisual(false);
    }
    //pass multiple values
    else
    
    {
      let nVisualIndex = this.lSelectedUserVisualsList.indexOf(oIncommingVisual);
      if(nVisualIndex== -1)
      {
        this.lSelectedUserVisualsList.push(oIncommingVisual);
        for(let nSlectedVisualIndex=0; nSlectedVisualIndex<this.lSelectedUserVisualsList.length;nSlectedVisualIndex++)
        {
          if(this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path===oIncommingVisual.visual_path)
          {
            this.lSelectedUserVisualsList[nSlectedVisualIndex].checked = !this.lSelectedUserVisualsList[nSlectedVisualIndex].checked;
          }  
        }
      }
      else
      {
        for(let nSlectedVisualIndex=0; nSlectedVisualIndex<this.lSelectedUserVisualsList.length;nSlectedVisualIndex++)
        {
          if(this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path === oIncommingVisual.visual_path)
          {
            this.lSelectedUserVisualsList[nSlectedVisualIndex].checked = !this.lSelectedUserVisualsList[nSlectedVisualIndex].checked;
          }  
        }
        this.lSelectedUserVisualsList.splice(nVisualIndex,1);
      }

    }


  }
  MediaLibrarySharedComponent_PassSelectedVisual(selectClicked)
  {
    console.log("Files : ==> ", this.lSelectedUserVisualsList);
    this.lEmittedFilesToUpload.emit(this.lSelectedUserVisualsList);
    if(selectClicked){
      this.closeMediaModal();
    }
  }


  closeMediaModal(): void {
    this.onClose.emit();
    this.lSelectedUserVisualsList=[];
    this.mediaManager.visuals.forEach(c=>{
      c.isSelected =false;
    });
  }


}
