<ul class="breadcrumb-tabs nav">
    <span *ngIf="!this.videoManger.previewMode && !localiseExistingVideos">
    <li class="nav-item" role="presentation">
        <a routerLink="/wizard/overview" routerLinkActive="active" class="nav-link">Overview</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/wizard/looknfeel" routerLinkActive="active"
        [class.disabled-breadcrumb]="!this.enableMenu"
        class="nav-link ">Look & Feel</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/wizard/content" routerLinkActive="active"  [class.disabled-breadcrumb]="!this.enableMenu" class="nav-link ">Content</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/wizard/other-setting" routerLinkActive="active"  [class.disabled-breadcrumb]="!this.enableMenu"  class="nav-link ">Other Settings</a>
    </li>
    </span>

    <span *ngIf="this.videoManger.previewMode && this.videoManger.isVideoGenerated && !localiseExistingVideos" class="ml-2" style="margin-left:20px">
        <li class="nav-item" role="presentation"   >
            <a routerLink="/wizard/overview"  class="nav-link ">Edit Video</a>
        </li>
        
        <!-- <li class="nav-item" role="presentation">
            <a href="javascript:void(0)" class="nav-link">Video Options</a>
        </li> -->
        <li class="nav-item " role="presentation">
            <a  class="nav-link active">Preview</a>
        </li>
       </span>

       <span *ngIf="localiseExistingVideos">
        <li class="nav-item" role="presentation">
            <a routerLink="/localise-existing-videos/single-video" routerLinkActive="active" class="nav-link">Single Video</a>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <a routerLink="/localise-existing-videos/multiple-videos" routerLinkActive="active"  class="nav-link ">Multiple Videos</a>
        </li> -->
        </span>
  
</ul>