<div class="upload-box" style="margin: auto; max-width: 800px; z-index:1200; width: 100%">
    <div class="modal-content">
        <div class="modal-header pt-0 pb-5">
          <div class="col-md-12">
              <h5 class="modal-title text-center" id="exampleModalLabel">{{heading}}</h5>
          </div>
            <button type="button" (click)="close()"  class="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>      
        </div>
        <div class="modal-body pt-3">       
            <div class="form-group files">
                <div class="tab-content" id="pills-tabContent">
                    <div  appDragDropFileUpload (fileDropped)="initiateUploadProcess($event, 'dragdrop')"  class="tab-pane fade show active" id="pills-device" role="tabpanel"
                        aria-labelledby="pills-device-tab">
  
                        <div class="file-drop-area">
                            <figure class="d-flex justify-content-center">
                                
                                <span class="upload-icon"></span>
                            </figure>
                            <span class="choose-file-button">Upload a File</span>
                            <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                drag & drop your file here</span>
                                <span class="file-message" *ngIf="sFileName">{{sFileName}}</span> 
                            <input #file class="file-input" type="file" 
                            accept="{{accept}}"                           
                            (change)="initiateUploadProcess($event)">
                        </div>
                    </div>
                    
  
                    <ul class="nav mb-3" id="pills-tab" role="tablist">
                        <li class="col device-body" role="presentation">
                            <button class="device-btn" (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device" aria-selected="true">
                                <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                <span class="d-block pt-3">My Device</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <video #videoValidator height="100" width="100" controls style="opacity: 0.2; z-index:-1; position: absolute"></video>
    </div>
  </div>