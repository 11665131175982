<app-header></app-header>

<section class="accountSec main-body bg-gray-7 h-100">
    <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Media</li>
            </ul>
            <div class=" br12 bg-white page-shadow curve-bg h-80vh">
                <div class="py-4 px-4 account-heading">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="weight-700 text-gray-2">Business Profile</h3>
                            <p class="mx-0 my-0 text-sm">A complete profile will help us to understand your business and
                                provide you with the best videos.</p>
                        </div>

                    </div>
                </div>
                <div class="accountSecInner">
                    <div class="accountSecNav py-5">
                        <app-business-sidebar></app-business-sidebar>
                    </div>
                    <div class="accountSecContent py-5 pe-4">
                        <div class="data-table m-0">
                            <div class="table-header row m-0">
                                <h3 class=" text-gray-2 col-sm-4 col-md-4 col-lg-4 p-0">
                                    Media Libraries
                                    <span class="d-block text-lg pt-3 weight-400">Total Media Libraries <b>12</b></span>
                                </h3>

                                <div class="col-sm-8 col-md-8 col-lg-8 px-0 text-end">
                                    <div class="searchbox-slide d-inline-block m-0 p-0">
                                        <div class="searchbox">
                                            <input type="search" placeholder="Search your video" name="search"
                                                class="searchbox-input" onkeyup="buttonUp();" required>
                                            <input type="submit" class="searchbox-submit" value="GO">
                                            <span class="searchbox-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                        fill="#A39FAA"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-5 px-4 divader-left float-end mt-1 text-xs"
                                        data-sidebar="#addAgencyLibrary">Add a New Library
                                    </button>
                                    <a href="javascript:void(0)" data-sidebar="#datafilter"
                                        class="filter-btn float-end mt-2"><svg width="14" height="11"
                                            viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                                fill="#404243"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <table class="table tableCustom table-hover mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col" class="tableCheck">
                                            <div class="custom-switch">
                                                <input type="checkbox" class="check form-check-input" id="checkAll">
                                            </div>
                                        </th>
                                        <th scope="col">Library Name</th>
                                        <th scope="col">Users</th>
                                        <th scope="col">Assets</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="tableAction">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lMediaLibraries">
                                        <td scope="row" class="tableCheck">
                                            <div class="custom-switch">
                                                <input type="checkbox" class="check form-check-input">
                                            </div>
                                        </td>
                                        <td><a class="b_userName"
                                                routerLink="/business-profile/enterprise-media-library">Prontopia</a>
                                        </td>
                                        <td>23,409</td>
                                        <td>321</td>
                                        <td>
                                            <div class="form-switch custom-slidetoggle p-0">
                                                <label class="btnSwitch p-0">
                                                    <input type="checkbox" />
                                                    <span class="btnSwitchCheck"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td class="tableAction">
                                            <a href="javascript:void(0)" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown">
                                                <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                                        fill="#7A7A7A" />
                                                </svg>
                                            </a>
                                            <ul class="dropdown-menu add_location page-shadow" id="dropdown"
                                                aria-labelledby="dropdownMenuButton1">
                                                <li>Edit</li>
                                                <li>Deactivate</li>
                                            </ul>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>
        </div>
    </form>
</section>
<div class="overlay sidebarOverlay"></div>

<app-business-profile-sidepanels></app-business-profile-sidepanels>