 
    <header class="header header-1 drop-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="d-flex w-100">
                    <div class="col d-flex align-items-center">
                        <div class="back_cta"> <a routerLink="/dashboard/myvideos">
                                <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                            </a></div>
                        <h3 class="text-gray-1 ">Create a Video</h3>
                    </div>
                    <div class="col text-end">
                        <a class="text-gray-6 text-sm me-4" [routerLink]="['/dashboard/myvideos']">Cancel</a>
                        <!-- <a class="text-gray-6 text-sm me-4" href="#">Previous</a> -->
                        <a  (click)="NavigateToNextTab()" class="btn btn-md btn-secondary btn-round-6 ">{{value}}</a>
                    </div>

                </div>

            </nav>
        </div>
    </header>