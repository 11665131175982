import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from '../../../Managers/config-manager.service';
import { FtCreateVideoManagerService } from  './../../../Managers/ft-create-video-manager.service';
import { video } from '../../../models/video';
import { cSessionService } from '../../../services/session.service';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ErrorService } from './../../../services/error.service';
declare var $: any;

@Component({
  selector: 'app-ft-overview',
  templateUrl: './ft-overview.component.html',
  styleUrls: ['./ft-overview.component.scss']
})
export class FtOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('close') close: ElementRef;
  sSessionID: string = "";
  sUserEmail: string = "";
  sVideoID: string = "";
  fOverviewForm: FormGroup;
  oSavedFormData: any;
  lVideoDurations= [{ name: "6s", value: 6, checked: false, isEnabled:true }, { name: "15s", value: 15, checked: false, isEnabled:true  }, { name: "30s", value: 30, checked: false, isEnabled:true  }, { name: "60s", value: 60, checked: false, isEnabled:true  },]
  lAspectRatio = ["(16:9)960x540"];
  sSelectedAspectRatio = "(16:9)960x540";
  sDisplayResponseErrorMessage: string;
  bCreateVideoMode: boolean = true;
  sDisableEditModeToggle: string = "false";
  oTotalCount: any;
  lLocales: any = [];
  lSelectedLocations: any = [];
  nCurrentPage = 1;
  lPagination: any = [];
  nPageSize: number = 100;
  bAuthorizationError: boolean = false;
  bLocales: boolean=true;

  submitFormSubscription: Subscription;
  guestEmail: any;
  guestSession :any;
  constructor(private oFormBuilder: FormBuilder, public configManager: ConfigManagerService, public videoManager: FtCreateVideoManagerService, private oSessionService: cSessionService, public onBoardingManager: OnboardingManagerService, private oRoutes: Router, private accountManager: AccountManagerService,public errorService:ErrorService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log("URL param",params);
  });

  }
  ngOnInit(): void {

    this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();


    this.sSessionID = this.oSessionService.cSessionService_GetGuestSession();
   
    this.submitFormSubscription = this.videoManager.submitForm.subscribe(c => {

    
      this.submitForm(c);
    });

    if (this.videoManager.selectedVideo) {
      this.CreateVideoOverviewComponent_SetOverviewForm();
      this.lVideoDurations.forEach(ee=>{
        if(ee.value == this.videoManager.selectedVideo.duration)
        {
          ee.isEnabled = true;
        }else
        {
          ee.isEnabled = false;
        }
       });
    }
    else {
      this.CreateVideoOverviewComponent_InitializeOverviewForm();
    }
     }
  ngOnDestroy() {
    this.submitFormSubscription.unsubscribe();

    if(this.videoManager.isEditMode)
    {
      this.updateVideoLocally();
    }
  }

  toggleLocation(event) {
    console.log(event);
    this.bLocales = !this.bLocales;
    if (this.bLocales) {

   
    } else {

      this.lSelectedLocations = [];
    }
  }

  CreateVideoOverviewComponent_SetOverviewForm() {
    this.fOverviewForm = this.oFormBuilder.group(
      {
        
        session_id: [this.sSessionID],
        email: [this.sUserEmail],
        title: [this.videoManager.selectedVideo.title, [Validators.required]],
        duration: [this.videoManager.selectedVideo.duration, [Validators.required]],
        about: [this.videoManager.selectedVideo.about, [Validators.required]],
        aspect_ratio: ['(16:9)960x540'],
        video_id: [this.videoManager.selectedVideo.fpath]
      }
    );
   console.log("session",this.videoManager.selectedVideo.session_id)
  }

  CreateVideoOverviewComponent_InitializeOverviewForm() {
    this.fOverviewForm = this.oFormBuilder.group(
      {
        session_id: [this.sSessionID],
        email: [this.sUserEmail],
        title: ['', [Validators.required]],
        duration: [30, [Validators.required]],
        about: ['', [Validators.required]],
        aspect_ratio: ['(16:9)960x540'],
      }
    );
  }

  submitForm(submitFrom:string) {
    console.log(this.fOverviewForm);
    if (this.fOverviewForm.valid) {
      let vid = this.updateVideoLocally();

      console.log(vid);
      this.videoManager.createVideo(vid).then(res => {

        vid['video_id'] = res['video_id'];
        vid.fpath = res['video_id'];
        this.videoManager.selectedVideo = vid;
        
        
        this.oRoutes.navigateByUrl('/ft/looknfeel');

      }).catch(err => {
        
      this.errorService.handleError(err.errorMessage,err.errorType,true);
        this.bAuthorizationError = false;

        let oErrorMessage = JSON.parse(err)["errorType"];


        if (oErrorMessage === 'AuthorizationError') {
          this.bAuthorizationError = true;
          this.sDisplayResponseErrorMessage = 'You have either exceeded your monthly video limit or not allowed to perform this operation';
          $('#error-popup').modal("show");
        }
        else {
          this.sDisplayResponseErrorMessage = 'An internal error occurred. Please try again later.';
          $('#error-popup').modal("show");

        }
        console.log(err);
      });



    }
    else {
      this.fOverviewForm.markAllAsTouched()
    }
  }





  private updateVideoLocally() {
    let vid = new video();
    if (this.videoManager.selectedVideo) {
      vid = this.videoManager.selectedVideo;
      vid['video_id'] = this.fOverviewForm.value.video_id;

    }

    vid.session_id = this.oSessionService.cSessionService_GetGuestSession(),
    vid.email = this.oSessionService.cSessionService_GetGuestEmail(),
    vid.title = this.fOverviewForm.value.title;
    vid.duration = this.fOverviewForm.value.duration;
    vid.about = this.fOverviewForm.value.about;
    vid.aspect_ratio = '16x9';
    vid.locales = this.lSelectedLocations;
    return vid;
  }

  CreateVideoOverviewComponent_SelectAspectRation(value) {
    this.sSelectedAspectRatio = value;
    console.log("ratio:::", this.sSelectedAspectRatio);

  }
  CreateVideoOverviewComponent_ToggleView(event) {
    if (event.target.checked) {
      this.oRoutes.navigateByUrl('/wizard/overview');
    }
    else {
      this.oRoutes.navigateByUrl('edit-video/timeline-view');
    }
  }
  CreateVideoOverviewComponent_GetLocations(numItems, numSkip) {
    let body = {
      session_id: this.sSessionID,
      email: this.sUserEmail,
      num_items: numItems,
      num_skip: numSkip
    }
    console.log("LocationsComponent_GetLocales: send get locales request to web socket");
   }
  CreateVideoOverviewComponent_GetLocalesResponse = (InCommingMessage) => {
    let oFormattedIncommingMessage = JSON.parse(InCommingMessage)
    if (oFormattedIncommingMessage.status == "success") {
      console.log("CreateVideoOverviewComponent_GetLocalesResponse: Response ==>>", oFormattedIncommingMessage);
      this.lLocales = oFormattedIncommingMessage.results;
      this.oTotalCount = oFormattedIncommingMessage.count;
      this.CreateVideoOverviewComponent_DrawPagination(this.oTotalCount);
    }
    else {
      console.error("CreateVideoOverviewComponent_GetLocalesResponse: Error ", oFormattedIncommingMessage.status);
    }
  }
  CreateVideoOverviewComponent_SelectedLocation(event, oData) {
    if (event.target.checked) {
      this.lSelectedLocations.push(oData);
    }
    else {
      let removeIndex = this.lSelectedLocations.findIndex(itm => itm === oData);
      if (removeIndex !== -1)
        this.lSelectedLocations.splice(removeIndex, 1);
    }
    console.log("the Selected location List is ===>>>", this.lSelectedLocations);
  }
  CreateVideoOverViewComponent_SelectAllLocations(event) {
    if (event.target.checked) {
      this.lSelectedLocations = this.lLocales;
    }
    else {
      this.lSelectedLocations = [];
    }
    console.log("the All locations List is ===>>>", this.lSelectedLocations);
  }
  CreateVideoOverviewComponent_CloseSidebar() {
    this.close.nativeElement.click();
  }
  CreateVideoOverviewComponent_DrawPagination(nIncommingVisualCount: number) {
    this.lPagination = [];
    this.nPageSize = Math.ceil(nIncommingVisualCount / 10);
    for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
      this.lPagination.push(nPaginationIndex);
    }
    console.log("Pagination List : ==> ", this.lPagination);
  }
  CreateVideoOverviewComponent_fetchNextPage(incommingPage) {
    if (incommingPage !== this.nCurrentPage) {
      this.nCurrentPage = incommingPage;
      this.lLocales = [];
      let num_items = 10;
      let num_skip = (incommingPage - 1) * 10;
      this.accountManager.getLocale(this.sUserEmail,this.sSessionID,num_items, num_skip);
    }
  }
  OverViewComponent_LocalesCheck() {

    this.bLocales = this.configManager.bDisplayLocales;

  }

  visibleLocation = false;
  placementLocation: NzDrawerPlacement = 'right';
  openLocation(): void {

    if(!(this.lLocales.length>0))
    {
    this.accountManager.getLocale(this.sUserEmail, this.sSessionID,10,0).then(res => {
      console.log('locations',this.lLocales);
      this.lLocales = res['results'];
      this.oTotalCount = res['count']
      this.CreateVideoOverviewComponent_DrawPagination(this.oTotalCount);
      this.visibleLocation = true;
    });
  }else
  {
    this.visibleLocation = true;
  }
 
  }
  closeLocation(): void {
    this.visibleLocation = false;
  }

}









