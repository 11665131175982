import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { cClientService } from './client.service';

 const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin' : '*',
  })
};

@Injectable(
  {
    providedIn: 'root'
  })

export class cDataService {
  oGetUserDetails = new BehaviorSubject<any>({});
  bRemovedInputHighlighter = new Subject<boolean>()
  sAddClassContentSlide = new BehaviorSubject<string>('');
  bDisplaySubscriptionToggler = new BehaviorSubject<boolean>(false);
  // oBulkUploadCount = new BehaviorSubject<any>({"current": 0, "total": 0}); 
  sSearchVideoByTitle = new BehaviorSubject<any>({title : "",type : ""}); 
  public static guestFileURL: string = environment.API_HOST + 'guest_file';
  public static userFileURL: string = environment.API_HOST + 'user_file';
  public static clipFileURL: string = environment.API_HOST + 'pronto_file';
  public static userVoiceOverFileURL: string = environment.API_HOST + 'audio_file';
  public  googleJoinLink: string = environment.API_HOST + 'google_signin';
  public static youTubeUpload: string = environment.API_HOST + 'youtube_upload';
  public static hubSpotUpload: string = environment.API_HOST + 'hubspot_upload';
  public static LinkedInUpload: string = environment.API_HOST + 'linkedin_upload';
  public static youtubeAnalytics: string = environment.API_HOST + 'youtube_analytics';
  public  faceBookJoinLink: string = environment.API_HOST + 'fb_signin';
  public  linkedInJoinLink: string = environment.API_HOST + 'linkedin_signin';
  public static faceBookUpload: string = environment.API_HOST + 'fb_upload';
  public static faceBookPages: string = environment.API_HOST + 'fb_get_pages';
  public static instagramUpload: string = environment.API_HOST + 'insta_upload';
  public static bulkUploadSignedUrl: string = environment.API_HOST + 'signed_url_for_upload';
  public static AudioSignedUrl: string = environment.API_HOST + 'signed_url_for_download';
  public static cloneVideo: string = environment.API_HOST + 'clone_video';



  constructor(private oHttpClient: HttpClient, private oClientService: cClientService) { }

  DataService_NavigateByURL(sURL: string | UrlTree) {
    return this.oClientService.ClientService_NavigateByURL(sURL)
  }

  DataService_GetConfigurationFileURL() {
    return this.oClientService.ClientService_GetConfigurationFileURL('config');
  }

  //uploadvisuals
  DataService_PostUploadedVisuals(sUploadedVisualsData: any) {
    return this.oClientService.ClientService_PostUploadedVisuals('user_file', sUploadedVisualsData);
  }

  DataService_GetUploadedVisuals(sUploadedVisualsData: string) {
    return this.oClientService.ClientService_GetUploadedVisuals(sUploadedVisualsData);
  }
  DataService_PostYoutubeUpload(sYoutubeUploadData: any, email: string, sessionId: string) {
    return this.oClientService.ClientService_PostYoutubeUpload('youtube_upload', sYoutubeUploadData, email, sessionId);
  }

  DataService_GuestPostUploadedVisuals(sUploadedVisualsData: any) {
    return this.oClientService.ClientService_PostUploadedVisuals('guest_file', sUploadedVisualsData);
  }

  //clone Video
   cloneVideo(body: FormData):  Observable<any> {
    return this.oHttpClient.post(cDataService.cloneVideo, body);
    
  }

  //BulkUploadvisuals
  DataService_BulkUpload(body: any): Observable<any> {
    return this.oHttpClient.post(environment.API_HOST + "signed_url_for_upload", body);
    //return this.oClientService.ClientService_GetSignedUrl('signed_url_for_upload', sUploadedVisualsData);
  }
  //bulk upload
  async DataService_UploadFileOnSignedUrl(nFileToUploadIndex: any, sSignedURL: string)
  {
    return await this.oHttpClient.post(sSignedURL, nFileToUploadIndex).toPromise();
  }
  async DataService_PostAddUserMedia(oAddMedia: any) {
    return await this.oHttpClient.post(environment.API_HOST + "add_user_media", oAddMedia).toPromise();
  }
  //upload voice over
  DataService_UploadUserVoiceOverFile(sUploadedVoiceOverData: any) {
    return this.oClientService.ClientService_PostUploadedVoiceOverFile('audio_file', sUploadedVoiceOverData);
  }
  //get file signed url
  async DataService_GetSignedURLForDownload(sFilePath)
  {
    let sURL : string="";
    await this.oClientService.ClientService_PostFilePathForSignedURL('signed_url_for_download', sFilePath).toPromise()
    .then((result : any) => 
    {
      console.log("DataService_GetSignedURLForDownload : Signed url reponse ==> ");
      // console.log(result.url);
      sURL = result.url;
    })
    .catch((error) =>
    {
      console.error("DashboardHeaderComponent_GetVideoURL : Signed url Error ==> ", error);
    });
    return sURL;
  }

  // media file thumbnail
  async DataService_GetUserVisualThumbnail(sFilePath)
  {
    let sThmbnail : string="";
    await this.oClientService.ClientService_GetThumbnail('create_thumbnail', sFilePath).toPromise()
    .then((result : any) => 
    {
      if(!result.errorMessage)
      {
        sThmbnail = result.thumbnail;
        console.log("DataService_GetUserVisualThumbnail : Get thumbnail reponse ==> ");
        // console.log(result.thumbnail);
      }
      else
      {
        console.error("DataService_GetUserVisualThumbnail : Get thumbnail reponse ==> ", result.errorMessage);
      }
    })
    .catch((error) =>
    {
      console.error("DataService_GetUserVisualThumbnail : Get thumbnail reponse ==> ", error);
    });
    return sThmbnail;
  }

  // get public file signed url
  async DataService_GetPublicSignedURLForDownload(sFilePath)
  {
    let sURL : string="";
    await this.oClientService.ClientService_PostPublicFilePathForSignedURL('signed_url_for_download_pub', sFilePath).toPromise()
    .then((result : any) => 
    {
      console.log("DataService_GetSignedURLForDownload : Signed url reponse ==> ");
      // console.log(result.url);
      sURL = result.url;
    })
    .catch((error) =>
    {
      console.error("DashboardHeaderComponent_GetVideoURL : Signed url Error ==> ", error);
    });
    return sURL;
  }
  // get facebook pages
  DataService_GetFacebookPages(sIncommingURL : string)
  {
    return this.oClientService.ClientService_GetPages(sIncommingURL)
  }
  //Verify user email
  
}
