import { map } from "rxjs/operators";
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { OnboardingManagerService } from "src/app/Managers/onboarding-manager.service";
import { brand } from "src/app/models/admin";
import { mediaFile } from "src/app/models/media";
import { LoaderService } from "src/app/services/loader.service";
import { cSessionService } from "src/app/services/session.service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { BrandService } from "../service/BrandService";
import { VideoService } from "src/app/services/dataServices/video.service";
import { SettingsService } from "../../services/settings.service";
declare let FontFace: any;

@Component({
  selector: "app-manage-brands-business-details",
  templateUrl: "./manage-brands-business-details.component.html",
  styleUrls: ["./manage-brands-business-details.component.scss"],
})
export class ManageBrandsBusinessDetailsComponent implements OnInit, OnDestroy {
  brandDetail: any = [];
  brandId: string;
  isEdit: boolean = false;
  public updateForm: FormGroup;
  updateFormSubmitted = false;
  logoFile: any;
  brandLogoPath = "";
  bDisplayErrorMessage: boolean = false;
  showDeleteBrandModal = false;
  defaultMediaLib: any;
  selectedFont = "";
  selectedFontStyle = "";
  industryList: any;
  selectedIndustry: any;
  selectedIndustryIcon: any;
  IndustryIcon: any;
  IndustryName: any;
  isModalVisible: boolean = false;
  isFontModalVisible: boolean = false;
  email: string;
  loadMoreFont = 10;
  brandLogoList: any = [
    // {fpath:"1",
    //  imgSrc:'../../../../../assets/images/business_Profile/dummy-logo.png'},
    //  {fpath:"2",
    //   imgSrc:'../../../../../assets/images/business_Profile/dummy-logo.png'},
    //   {fpath:"3",
    //    imgSrc:'../../../../../assets/images/business_Profile/dummy-logo.png'},
  ];
  brandFontArray: any = [];
  otherFontArray: any = [];

  oPrimaryColor = undefined;
  secondaryColor = [];
  sessionId = undefined;
  defaultColor = "#ffffff";
  externalFontsStyleSheet = "";
  internalFontsStyleSheet = "";
  selectedFontSignedURL = undefined;
  private elementRef: ElementRef;
  constructor(
    private adminBrandManager: AdminBrandManagerService,
    public router: Router,
    private route: ActivatedRoute,
    private updateFormGroupBuilder: FormBuilder,
    private mediaManger: MediaManagerService,
    private oSessionService: cSessionService,
    private successModal: SuccessMessageModalService,
    public onboardingManger: OnboardingManagerService,
    private brandService: BrandService,
    private loader: LoaderService,
    private videoService: VideoService,
    private settingService : SettingsService
  ) {}

  ngOnInit(): void {
    this.brandId = this.route.snapshot.params["id"];
    this.InitializeJqueryCode();
    this.getBrandProfile();
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();

    // this.brandDetail = this.adminBrandManager.brandDetail;
    // this.brandLogoPath = this.adminBrandManager.brandDetail.logo_file;
    // this.updateBrandAvatarPath(this.brandLogoPath);
    this.getDefaultFonts();
    let __this = this;
    document.querySelector(".brand-font-list").addEventListener(
      "scroll",
      function () {
        __this.closeFontWeightSubmenu();
      },
      false
    );

    this.settingService.dropDownHideSubscribe().subscribe( (value) => {
      this.closeFontWeightSubmenu();
    });
  }

  getBrandProfile() {
    let __this = this;
    this.adminBrandManager
      .getBrandProfile(this.brandId)
      .then((brand: brand) => {
        __this.brandDetail = brand;
        this.selectedIndustry = brand.industry;
        this.IndustryName = brand.industry;
        this.getIndustry();
        this.brandLogoPath = brand.logo_file;
        this.defaultMediaLib =
          brand.media_libs && brand.media_libs.length > 0
            ? brand.media_libs[0]?._id?.$oid
            : undefined;
        if (this.brandLogoPath && this.brandLogoPath != "") {
          // this.updateBrandAvatarPath(this.brandLogoPath);
        }
        // console.log('Loaded brand profile data ', brand);
        if (brand.fonts) {
          this.brandFontArray = brand.fonts;
          this.generateSelectFontView();
        }
        if (brand.primary_font) {
          this.selectedFont = brand.primary_font;
          this.selectedFontStyle = this.fontStyle(brand.primary_font);
        }
        if (brand.primary_color) {
          this.oPrimaryColor = brand.primary_color;
        }
        if (brand.secondary_colors && brand.secondary_colors != "") {
          brand.secondary_colors.map((item, index) => {
            this.secondaryColor.push({ index: index, color: item });
          });
        } else {
          this.secondaryColor = [{ index: 0, color: undefined }];
        }
        this.loadBrandLogo();
        this.initializeForm();
        if (this.brandFontArray && this.brandFontArray.length > 0) {
          this.externalFontsStyleSheet = "";
          this.videoService
            .getAllSignedURLs({
              email: this.email,
              session_id: this.sessionId,
              fpaths: this.brandFontArray,
            })
            .then((result: any) => {
              result.output.map(async (item: any) => {
                // console.log(" item ", item);
                // console.log(Object.values(item));
                // console.log(Object.keys(item));

                let fontPath = Object.keys(item)[0];
                let fontSignedURLPath = Object.values(item)[0];

                this.externalFontsStyleSheet += this.getFontFaceStyleCSS(
                  fontPath,
                  fontSignedURLPath
                );
              });

              var head = document.getElementsByTagName("head")[0];
              let styleElement = document.createElement("style");
              styleElement.textContent = this.externalFontsStyleSheet;
              head.appendChild(styleElement);

              // console.log(" urls of brand fonts ", result);
            });
        }
      });
  }

  initializeForm() {
    this.updateFormSubmitted = false;

    this.updateForm = this.updateFormGroupBuilder.group({
      name: this.brandDetail?.name ? this.brandDetail?.name : "Business Name",
      website: this.brandDetail?.website
        ? this.brandDetail?.website
        : "websitehgghg",
      industry: this.brandDetail?.industry
        ? this.brandDetail?.industry
        : "Industry Name",
    });
  }

  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }

  loadBrandAccount() {
    this.router.navigate([
      `/account-setting/manage-brand/account/${this.brandId}`,
    ]);
  }
  loadBrandBusiness() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/business-details`,
    ]);
  }
  loadBrandMediaLibrary() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/media-libraries`,
    ]);
  }
  loadBrandMediaLibraryList() {
    this.router.navigate([
      `/account-setting/manage-brand/${this.brandId}/list-media-libraries`,
    ]);
  }

  editBusinessBrand() {
    this.isEdit = true;
    this.toggleAddMoreSecondaryBtn();
  }
  cancelBusinessBrand() {
    this.isEdit = false;
    this.selectedIndustryIcon = this.IndustryIcon;
    this.selectedIndustry = this.IndustryName;

    if (this.brandDetail.primary_font) {
      this.selectedFont = this.brandDetail.primary_font;
      this.selectedFontStyle = this.fontStyle(this.selectedFont);
    } else {
      this.selectedFont = "";
    }
  }
  updateBusinessBrand() {
    this.updateFormSubmitted = true;
    const colorSecondary = [];
    this.secondaryColor.map((item) => {
      if (item.color && item.color != "") {
        colorSecondary.push(item.color);
      }
    });

    if (this.updateForm.invalid) {
    } else {
      const brandInfo: brand = {
        _id: this.brandId,
        name: this.updateForm.controls["name"].value,
        website: this.updateForm.controls["website"].value,
        industry: this.updateForm.controls["industry"].value,
        industry_icon: this.selectedIndustryIcon,
        secondary_colors: colorSecondary,
        primary_color: this.oPrimaryColor,
        primary_font: this.selectedFont,
      };

      // console.log("brandInfo", brandInfo);

      this.adminBrandManager.updateBrand(brandInfo).then((res: any) => {
        this.brandDetail = res["result"];
      });
      this.isEdit = false;
    }
  }
  uploadBrandLogo(event) {
    let file = event.target.files[0];

    if (file) {
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;
      // this.successModal.display(
      //   "Uploading file",
      //   "Brand Logo Upload",
      //   true,
      //   false,
      //   false
      // );

      if (this.defaultMediaLib) {
        try {
          this.mediaManger
            .uploadUserAvatar(
              this.oSessionService.cSessionService_GetUserEmail(),
              this.oSessionService.SessionService_GetSessionId(),
              this.defaultMediaLib,
              media,
              this.brandId
            )
            .then((res: any) => {
              // this.successModal.close();
              this.logoFile = res;
              this.logoFile.size = file.size;
              // this.onboardingManger.profile.logo_file = res.fpath;
              if (this.brandLogoList.length > 0) {
                this.updateBrandAvatarPath(res.fpath);
              } else {
                this.brandDetail.logo_file = res.fpath;
                this.adminBrandManager
                  .updateBrand({ _id: this.brandId, logo_file: res.fpath })
                  .then(() => {
                    this.updateBrandAvatarPath(res.fpath);
                  });
              }
            })
            .catch((error) => {
              // this.successModal.close();
              setTimeout(() => {
                this.bDisplayErrorMessage = false;
              }, 5000);
            });
        } catch (error) {
          // this.successModal.close();
        }
      }
    }
  }
  loadBrandLogo() {
    const fpahArray = this.brandDetail?.logo_files;
    // fpahArray.map((file : any) => {
    //   console.log("find signed url for ", file);
    this.mediaManger
      .getMediaThumbandURL(
        this.oSessionService.cSessionService_GetUserEmail(),
        this.oSessionService.SessionService_GetSessionId(),
        fpahArray
      )
      .then((resThumb: any) => {
        resThumb.output.map((item, index) => {
          this.brandLogoList.push({
            fpath: fpahArray[index],
            src: resThumb.output[index][fpahArray[index]],
          });
        });
      });
    // })
  }
  updateBrandAvatarPath(fPath) {
    const fpahArray = [fPath];
    // console.log(fpahArray);
    this.mediaManger
      .getMediaThumbandURL(
        this.oSessionService.cSessionService_GetUserEmail(),
        this.oSessionService.SessionService_GetSessionId(),
        fpahArray
      )
      .then((resThumb: any) => {
        if (resThumb && resThumb.output) {
          this.brandLogoPath = resThumb.output[0][fpahArray[0]];
          this.brandLogoList.push({ fpath: fPath, src: this.brandLogoPath });
          if (this.brandLogoList.length == 1) {
            this.brandLogoPath = resThumb.output[0][fpahArray[0]];
            this.brandDetail.logo_files = fPath;
          }
          this.showPopUp();
          const brandLogoFpaths = [];
          this.brandLogoList.map((item) => {
            brandLogoFpaths.push(item.fpath);
          });
          this.adminBrandManager
            .updateBrand({ _id: this.brandId, logo_files: brandLogoFpaths })
            .then(() => {});
        }
      });
  }

  selectedLogoFile(index: number, logoFile): void {
    // this.brandLogoPath = this.brandLogoList[index].fpath;
    this.adminBrandManager
      .updateBrand({ _id: this.brandId, logo_file: logoFile })
      .then(() => {
        this.brandDetail.logo_file = logoFile;
        this.brandLogoPath = logoFile.src;
      });
  }

  deleteLogo(fpath, index) {
    let isSelectedLogoDeleted = false;
    if (this.brandDetail.logo_file == fpath) {
      isSelectedLogoDeleted = true;
    }
    const remainLogo = [];
    this.brandLogoList.splice(index, 1);
    this.brandLogoList.filter((item: any) => {
      if (item.fpath !== fpath) {
        remainLogo.push(item.fpath);
      }
    });

    let selectedLogo = this.brandDetail.logo_file;
    if (isSelectedLogoDeleted) {
      if (remainLogo.length > 0) {
        selectedLogo = remainLogo[0];
        this.brandDetail.logo_file = selectedLogo;
      } else {
        selectedLogo = "";
      }
    }

    this.adminBrandManager
      .updateBrand(
        { _id: this.brandId, logo_file: selectedLogo, logo_files: remainLogo },
        false
      )
      .then(() => {
        this.brandLogoPath = "";
        this.showDeleteBrandModal = false;
      });
  }
  deleteBrandPic() {
    this.adminBrandManager
      .updateBrand({ _id: this.brandId, logo_file: "" }, false)
      .then(() => {
        this.brandLogoPath = "";
        this.showDeleteBrandModal = false;
      });
  }
  deleteBrandPicModal() {
    this.showDeleteBrandModal = true;
  }
  showPopUp(): void {
    this.isModalVisible = true;
    setTimeout(() => {
      this.isModalVisible = false;
    }, 5000);
  }
  hidePopUP(): void {
    console.log("Button cancel clicked!");
    this.isModalVisible = false;
  }

  showFontPopUp(): void {
    this.isFontModalVisible = true;
    setTimeout(() => {
      this.isFontModalVisible = false;
    }, 5000);
  }
  hideFontPopUP(): void {
    // console.log("Button cancel clicked!");
    this.isFontModalVisible = false;
  }
  selectFont(incommingFont) {

    this.selectedFont = incommingFont;
    this.selectedFontStyle = this.fontStyle(this.selectedFont);
  }
  selectOtherFont(incommingFont) {

    this.selectedFont = incommingFont.value[0].name;
    this.selectedFontStyle = this.fontStyle(this.selectedFont);
  }
  fontStyle(incommingFont) {
    // console.log("default style called");
    if (!incommingFont.value) {
      let fontNameSpliter = incommingFont.split("/");
      let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
      return nameFont.split(".")[0];
    }
  }

  otherFontStyle(fontObj) {
    // console.log(" other font called");
    if (fontObj && fontObj.value && fontObj.value.length > 0) {
      let fontNameSpliter = fontObj.value[0]?.name.split("/");
      let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
      return nameFont.split(".")[0];
    }

    return "";
  }

  fontWeightName(name): string {
    let nameWeight = name.toLowerCase();
    if (nameWeight.indexOf("semibolditalic") > -1) {
      return "Semi Bold Italic";
    }
    if (nameWeight.indexOf("extrabolditalic") > -1) {
      return "Extra Bold Italic";
    }
    if (nameWeight.indexOf("lightbolditalic") > -1) {
      return "Light Bold Italic";
    }
    if (nameWeight.indexOf("mediumbolditalic") > -1) {
      return "Medium Bold Italic";
    }
    if (nameWeight.indexOf("regularbolditalic") > -1) {
      return "Bold Italic";
    }
    if (nameWeight.indexOf("ExtraLightItalic") > -1) {
      return "Extra Light Italic";
    }
    if (nameWeight.indexOf("variable") > -1) {
      return "Variable Font";
    }
    if (nameWeight.indexOf("mediumitalic") > -1) {
      return "Medium Italic";
    }
    if (nameWeight.indexOf("lightitalic") > -1) {
      return "Light Italic";
    }
    if (nameWeight.indexOf("semibold") > -1) {
      return "Semi Bold";
    }
    if (nameWeight.indexOf("bolditalic") > -1) {
      return "Bold Italic";
    }
    if (nameWeight.indexOf("thinitalic") > -1) {
      return "Thin Italic";
    }
    if (nameWeight.indexOf("regulartalic") > -1) {
      return "Regular Italic";
    }
    if (nameWeight.indexOf("extralight") > -1) {
      return "Extra Light";
    }
    if (nameWeight.indexOf("extrabold") > -1) {
      return "Extra Bold ";
    }
    if (nameWeight.indexOf("bold") > -1) {
      return "Bold";
    }
    if (nameWeight.indexOf("thin") > -1) {
      return "Thin";
    }
    if (nameWeight.indexOf("light") > -1) {
      return "Light";
    }
    if (nameWeight.indexOf("italic") > -1) {
      return "italic";
    }
    if (nameWeight.indexOf("regular") > -1) {
      return "Regular";
    }
    if (nameWeight.indexOf("medium") > -1) {
      return "Medium";
    }
    if (nameWeight.indexOf("normal") > -1) {
      return "Normal";
    }
    return name;
  }

  fontStyleWithoutSub(incommingFont) {
    let fontNameSpliter = incommingFont.split("/");
    let nameFont = fontNameSpliter[fontNameSpliter.length - 1];
    let fontText = nameFont.split(".")[0];
    let realName = nameFont.split(".")[0];

    var mapObj = {
      semibolditalic: "semibolditalic",
      extrabolditalic: "extrabolditalic",
      lightbolditalic: "lightbolditalic",
      mediumbolditalic: "mediumbolditalic",
      regularbolditalic: "regularbolditalic",
      mediumitalic: "mediumitalic",
      bolditalic: "bolditalic",
      thinitalic: "thinitalic",
      regulartalic: "regulartalic",
      variablefont_wght: "variablefont wght",
      extralight: "extralight",
      extrabold: "extrabold",
      semibold: "semibold",
      bold: "bold",
      thin: "thin",
      light: "light",
      italic: "italic",
      regular: "regular",
      medium: "medium",
      hyphon: "-",
      underscore: "_",
      undef: "undefined",
    };

    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    fontText = fontText.replace(re, function (matched) {
      return mapObj[matched];
    });

    if (realName == fontText) {
      fontText = "Regular";
    }
    fontText = fontText.replaceAll("undefined", "");
    fontText = fontText.replaceAll("-", "");
    fontText = fontText.replaceAll("_", "");
    return fontText.replace("undefined", "");
  }

  getIndustry() {
    let __this = this;
    this.adminBrandManager.getIndustryList().then((res: any) => {
      this.industryList = res["result"];
      this.industryList.map((industry) => {
        if (industry.name == this.selectedIndustry) {
          this.selectedIndustryIcon = industry.icon;
          this.IndustryIcon = industry.icon;
        }
      });
    });
  }
  selectIndustry(incommingIndustry) {
    this.selectedIndustry = incommingIndustry.name;
    this.updateForm.get("industry").setValue(this.selectedIndustry);
    this.selectedIndustryIcon = incommingIndustry.icon;
  }

  setPrimaryColor(event) {
    // console.log(event);
    const primaryColor = event.target.value;
    this.oPrimaryColor = primaryColor;
  }

  setSecondaryColor(event, index = undefined) {
    // console.log(event);
    const Color = event.target?.defaultValue;
    this.secondaryColor[index].color = Color;
  }

  showAddMoreSecondaryColorBtn = true;
  toggleAddMoreSecondaryBtn() {
    if (this.secondaryColor.length > 5) {
      this.showAddMoreSecondaryColorBtn = false;
    } else {
      this.showAddMoreSecondaryColorBtn = true;
    }
  }

  addSecondaryColor() {
    const i = this.secondaryColor.length;
    const modifiedObj = { index: i, color: undefined };
    this.secondaryColor.push(modifiedObj);
    // console.log("chk1", this.secondaryColor);
    this.toggleAddMoreSecondaryBtn();
  }
  uploadFont(event) {
    let file = event.target.files[0];
    // console.log("filetype", file);

    if (file) {
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;

      if (this.defaultMediaLib) {
        this.loader.showLoader();
        try {
          const data = {
            email: this.oSessionService.cSessionService_GetUserEmail(),
            session_id: this.oSessionService.SessionService_GetSessionId(),
            media_lib: this.defaultMediaLib,
            file: file,
            content_type: "application/octet-stream",
            brandId: this.brandId,
            ownerType: "brand",
          };

          this.brandService
            .uploadFontFile(data)
            .then()
            .then((res: any) => {
              this.clearFileUploadInputField();
              console.log(document.querySelector("#fontUploader"));
              console.log(document.querySelector("#fontUploader")[0]);
              if (res) {
                this.loader.hideLoader();
                this.showFontPopUp();
                this.brandFontArray.push(res.fpath);
                this.brandFontArray.map((item) => {
                  item.fontStyle = this.fontStyle(item.name);
                });

                this.externalFontsStyleSheet = "";
                this.videoService
                  .getAllSignedURLs({
                    email: this.email,
                    session_id: this.sessionId,
                    fpaths: [res.fpath],
                  })
                  .then((result: any) => {
                    result.output.map(async (item: any) => {
                      // console.log(" item ", item);
                      // console.log(Object.values(item));
                      // console.log(Object.keys(item));

                      let fontPath = Object.keys(item)[0];
                      let fontSignedURLPath = Object.values(item)[0];

                      this.externalFontsStyleSheet += this.getFontFaceStyleCSS(
                        fontPath,
                        fontSignedURLPath
                      );
                    });

                    var head = document.getElementsByTagName("head")[0];
                    let styleElement = document.createElement("style");
                    styleElement.textContent = this.externalFontsStyleSheet;
                    head.appendChild(styleElement);

                    // console.log(" urls of brand fonts ", result);
                  });
              }
            })
            .catch((error) => {
              this.clearFileUploadInputField();
              this.loader.hideLoader();
            });
        } catch (error) {
          this.clearFileUploadInputField();
          this.loader.hideLoader();
        }
      }
    }
  }

  clearFileUploadInputField(){
    let inputUplo9adField = <HTMLInputElement> (document.querySelector("#fontUploader"));
    inputUplo9adField.value = "";
  }

  generateSelectFontView() {
    // if(!this.selectedFont ){
    //   return;
    // }
    // let internalFontsStyleSheet = "";
    // let isBrandFont = false;
    // if(this.brandFontArray && this.brandFontArray.length > 0) {
    //   let brandMatched = this.brandFontArray.filter( (item) => ( item == this.selectedFont));
    //   if(brandMatched && brandMatched.length > 0) {
    //     isBrandFont = true;
    //   }
    // }
    // if (!isBrandFont) {
    //   let getFontFile = this.otherFontArray.filter( (item : any) => (item.name = this.selectedFont));
    //   if(getFontFile && getFontFile.length > 0) {
    //     let fontPath = getFontFile[0].name;
    //     let fontSignedURLPath = getFontFile[0].signed_url;
    //     this.selectedFontSignedURL  = fontSignedURLPath;
    //     internalFontsStyleSheet = this.getFontFaceStyleCSS(
    //       fontPath,
    //       fontSignedURLPath
    //     );
    //     var head = document.getElementsByTagName("head")[0];
    //     let styleElement = document.createElement("style");
    //     styleElement.textContent = internalFontsStyleSheet;
    //     head.appendChild(styleElement);
    //   }
    // } else {
    //   this.videoService
    //     .getAllSignedURLs({
    //       email: this.email,
    //       session_id: this.sessionId,
    //       fpaths: [this.selectedFont],
    //     })
    //     .then((result: any) => {
    //       console.log("Load other ..font data ...");
    //       result.output.map(async (item: any) => {
    //         let fontPath = Object.keys(item)[0];
    //         let fontSignedURLPath = Object.values(item)[0];
    //         this.selectedFontSignedURL  = fontSignedURLPath;
    //         internalFontsStyleSheet = this.getFontFaceStyleCSS(
    //           fontPath,
    //           fontSignedURLPath
    //         );
    //       });
    //       var head = document.getElementsByTagName("head")[0];
    //       let styleElement = document.createElement("style");
    //       styleElement.textContent = internalFontsStyleSheet;
    //       head.appendChild(styleElement);
    //     });
    // }
  }

  getFontFaceStyleCSS(fontPath, fontSignedURLPath) {
    this.selectedFontSignedURL = fontSignedURLPath;
    let fontFamily = "";
    let path = fontPath.split(".");
    let fontExtension = path[path.length - 1];
    let pathName = fontPath.split("/");
    let fontFamilyName = pathName[pathName.length - 1]
      ? pathName[pathName.length - 1].split(".")[0]
      : pathName[pathName.length - 1];

    let fontFormate = fontExtension;
    if (
      fontFormate == "otf" ||
      fontFormate == "OTF" ||
      fontFormate == "ttf" ||
      fontFormate == "TTF"
    ) {
      fontFormate = "opentype";
    }
    return `
    @font-face {
      font-family: '${fontFamilyName}';
      font-style: normal;
      font-weight: 500;
      src: url('${fontSignedURLPath}') format('${fontFormate}');}`;
  }

  deleteFont(incommingFont, index) {
    this.loader.showLoader();
    this.brandService
      .deleteBrandFont(this.brandId, incommingFont)
      .then()
      .then((res: any) => {
        if (res) {
          this.loader.hideLoader();
          this.brandFontArray.splice(index, 1);
        }
      })
      .catch((error) => {
        this.loader.hideLoader();
      });
  }

  otherFontParent = [];

  public otherFontParentMap: Map<string, any[]> = new Map<string, any[]>();
  collectOtherFontsParent() {
    let loop = 0;
    this.otherFontArray.forEach((font) => {
      // console.log("call font other itenrate here ...");
      // font.style = this.fontStyle(font.name);
      // font.style = this.fontStyle(font.name);
      // font.fontStyle = this.otherFontStyle(font.name);
      let nameFont = this.fontStyleWithoutSub(font.name);
      // console.log(" name of font :: ", nameFont);
      font.fontStyle = this.fontStyle(font.name);
      if (this.otherFontParentMap.get(nameFont)) {
        // if(this.otherFontParentMap.get(nameFont)){
        let mapValue: any[] = this.otherFontParentMap.get(nameFont);
        //  console.log( nameFont ,"  ::: ", mapValue);

        mapValue.push(font);
        this.otherFontParentMap.set(nameFont, mapValue);
        //  console.log(" set value found for ", this.otherFontParentMap.get(nameFont));
        // }
      } else {
        // font.fontStyle = this.fontStyle(font);
        // console.log(" no no value found ", nameFont);
        this.otherFontParentMap.set(nameFont, [font]);
      }
      // if(this.otherFontParentMap.filter( (item) => (item.name != nameFont)).length == 0){
      //   console.log(" no no value found ", nameFont);
      //  this.otherFontParentMap.set(nameFont, []);
      // } else {
      //   console.log(" set value found for ", nameFont)
      //  if(this.otherFontParentMap.get(nameFont)){
      //   let mapValue = this.otherFontParentMap.get(nameFont).values;
      //   this.otherFontParentMap.set(nameFont, [mapValue, font]);
      //  }
      // }
    });
    // console.log( " otherFontParentMap ", this.otherFontParentMap);
  }
  loadFontData(index, limit) {
    var filterArray = [];
    for (let i = index; i < index + limit; i++) {
      if (this.otherFontArray.length >= i) {
        filterArray.push(this.otherFontArray[i]);
      }
    }
    let internalFontsStyleSheet = "";
    filterArray.map((item: any) => {
      // console.log(item)
      if (item) {
        let fontPath = item.name;
        let fontSignedURLPath = item.signed_url;
        internalFontsStyleSheet += this.getFontFaceStyleCSS(
          fontPath,
          fontSignedURLPath
        );
      }
    });
    var head = document.getElementsByTagName("head")[0];
    let styleElement = document.createElement("style");
    styleElement.textContent = internalFontsStyleSheet;
    head.appendChild(styleElement);
  }
  getDefaultFonts() {
    this.brandService.getDefaultFontLists().then((list: any) => {
      this.generateSelectFontView();
      this.otherFontArray = list.result;
      this.collectOtherFontsParent();
      if (this.otherFontArray && this.otherFontArray.length > 0) {
        this.internalFontsStyleSheet = "";
        let loopIndex = 0;
        let continueLoop = true;
        let fontGeneration = setInterval(() => {
          // console.log(loopIndex, " > = ", this.otherFontArray.length);
          if (loopIndex >= this.otherFontArray.length) {
            continueLoop = false;
            // console.log("Clear loop");

            clearInterval(fontGeneration);
          } else {
            this.loadFontData(loopIndex, 40);
          }
          loopIndex += 40;
        }, 300);
      }
    });
  }
  removeColor(incommingIndex) {
    // console.log("clicked");
    this.secondaryColor.splice(incommingIndex, 1);
    this.updateBusinessBrand();

    const i = this.secondaryColor.length;
    const modifiedObj = { index: incommingIndex, color: undefined };
    this.secondaryColor.splice(incommingIndex, 0, modifiedObj);
  }

  submenuFontWeight = ".submenuFontWeight";
  submenuFontWeightList = [];

  sortFontWeight(name) : number {
    let sortNum = 0;

    switch(name.toLowerCase()) {
      case "thin light": sortNum = 1;  break;
      case "thin": sortNum = 2;  break;
      case "regular italic": sortNum = 3;  break;
      case "thin italic": sortNum = 1;  break;
      case "light bold italic": sortNum = 4;  break;
      case "extra light": sortNum = 5;  break;
      case "extra light italic": sortNum = 6;  break;
      case "light": sortNum = 7;  break;
      case "light italic": sortNum = 8;  break;
      case "italic": sortNum = 9;  break;
      case "regular": sortNum = 10;  break;
      case "regular": sortNum = 11;  break;
      case "normal": sortNum = 12;  break;
      case "medium": sortNum = 12;  break;
      case "medium italic": sortNum = 13;  break;
      case "semi bold": sortNum = 14;  break;
      case "bold": sortNum = 15;  break;
      case "bold italic": sortNum = 16;  break;
      case "light bold italic": sortNum = 17;  break;
      case "medium bold italic": sortNum = 18;  break;
      case "semi bold italic": sortNum = 19;  break;
      case "extra bold": sortNum = 20;  break;
      case "extra bold": sortNum = 21;  break;
      case "extra bold": sortNum = 22;  break;
      case "bolder": sortNum = 23;  break;
      default : sortNum = 24;  break;
    }
    return sortNum;
  }
  loadFontWeightSubMenu(font) {
    let weightList = font?.value;
    this.submenuFontWeightList = [];

    let fontWeightDropDown = document.querySelectorAll(this.submenuFontWeight);
    if (fontWeightDropDown && fontWeightDropDown.length > 0) {
      let dropDown = fontWeightDropDown[0];
      if (weightList && weightList.length > 1) {
        weightList.map((weight) => {
          let weightStyle = this.fontStyle(weight.name);
          let weightName = this.fontWeightName(weight.name);
          weight.styleName = weightStyle;
          weight.titleName = weightName;
          if(!weight.sort){
            weight.sort  = this.sortFontWeight(weight.titleName);
          }
          this.submenuFontWeightList.push(weight);
        });
        this.submenuFontWeightList.sort((a,b) => (a.sort - b.sort));
        dropDown.classList.add("show");
        let fontListDD = document.querySelectorAll(".brand-font-list");
        if (fontListDD && fontListDD[0]) {

          var divOffset = this.offsetElement(fontListDD[0]);
          // console.log(divOffset.left, divOffset.top);
          dropDown["style"].left = divOffset.left + 230 + "px";
          dropDown["style"].top = divOffset.top - 120 + "px";
          // console.log(fontListDD[0] .top)
        }
      } else {
        dropDown.classList.remove("show");
      }
      // dropDown.innerHTML = strElement;

    }
  }

  offsetElement(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  // ngAfterViewInit() {
  //   this.elementRef.nativeElement.querySelector('my-element')
  //                                 .addEventListener('click', this.onClick.bind(this));
  // }
  @HostListener("click", ["$event"]) onClick(event) {
    // console.log('component is clicked');
    // console.log(event);
    let fontWeightDropDown = document.querySelectorAll(this.submenuFontWeight);
    // console.log(" drop down field ", fontWeightDropDown.length);
    if (fontWeightDropDown && fontWeightDropDown.length > 0) {
      let dropDown = fontWeightDropDown[0];
      let strElement = "";
      dropDown.classList.remove("show");
    }
  }

  closeFontWeightSubmenu() {
    let fontWeightDropDown = document.querySelectorAll(this.submenuFontWeight);
    // console.log(" drop down field ", fontWeightDropDown.length);
    if (fontWeightDropDown && fontWeightDropDown.length > 0) {
      let dropDown = fontWeightDropDown[0];
      let strElement = "";
      dropDown.classList.remove("show");
    }
  }

  ngOnDestroy() {
    document.querySelector(".brand-font-list").removeEventListener(
      "scroll",
      undefined,
      false
    );
  }

  loadMoreOtherFonts(event){
    this.loadMoreFont += 10;
    event.stopPropagation();
    event.preventDefault();

  }
}