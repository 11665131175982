<!-- <app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">

    <div class="container d-flex flex-column h-100">
        <ul class="breadcrumb my-3">
            <li><a href="#">Business Profile</a></li>
            <li>Users</li>
        </ul>
        <div class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden" id="row-main">
                <app-account-sidebar class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
                id="sidebar-left"></app-account-sidebar>
            <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content"> -->

<div class="video-header row m-0 ms-3 px-4 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a class="me-2" (click)="backward()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
          fill="#404243" />
      </svg>
    </a>
    {{ (profileData?.username) ? profileData?.username : (profileData?.first_name)}}
  </p>
  <div class="ps-4 ms-2 mb-3">
    <a href="#" class="text-xs text-gray-2">Admin</a> .
    <a href="#" class="text-xs text-gray-3">Active</a>
  </div>
</div>
<div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
  <div class="row filter-post">
    <div class="col-md-12 p-0">
      <ul class="breadcrumb-tabs nav m-0">
        <li class="nav-item ps-0">
          <a href="javascript:void(0)" class="nav-link active">Account</a>
        </li>
        <!-- <li class="nav-item">
                                    <a routerLink="/account-setting/user-published-videos" href="javascript:void(0)" class="nav-link">Published Video</a>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" routerLink="/account-setting/user-schedule-video" class="nav-link">Scheduled Video</a>
                                </li> -->
        <li class="nav-item">
          <a href="javascript:void(0)" (click)="loadUserMediaLibrary()" class="nav-link ">Media
            Libraries</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="px-4 flex-grow-1 ps-0">
  <div class="container mx-0 ms-1 ps-3 business-media d-flex flex-column h-100" id="container-view">
    <div class="py-4 mt-3">
      <div class="row row-cols-md-2 g-0 justify-content-between">
        <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
          <form [formGroup]="profileForm" *ngIf="profileForm" (ngSubmit)="updateProfile()">
            <div class="row">
              <div class="col-md-5">
                <div class="formItm">
                  <input id="name" type="text" formControlName="name" placeholder="Name" name="name" required>
                  <label for="name" class="text-sm">User Name</label>
                </div>
              </div>

              <div class="offset-md-1 col-md-6">
                <div class="formItm">
                  <input id="_id" name="_id" type="email" formControlName="_id" readonly placeholder="Email Address" required>
                  <label for="_id" class="text-sm">Email Address</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="formItm down_arrow pt-0">
                  <p class="text-md text-gray-3 mb-0">Select Role</p>
                  <div class="dropdown dropdownSelect">
                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                        <a class="dropdown-item text-md text-capitalize"> {{profileForm?.controls['role']?.value == 'org_admin' ? 'Admin' : profileForm?.controls['role']?.value }}</a>

                    </button>
                    <ul class="dropdown-menu dropdownMenu">
                      <li class="selected" (click)="updateUserRole('org_admin')">
                        <a class="dropdown-item text-md">Admin</a>
                      </li>
                      <!-- <li class="selected" (click)="updateUserRole('Billing')">
                        <a class="dropdown-item text-md">Billing</a>
                      </li> -->
                      <li class="selected" (click)="updateUserRole('creator')">
                        <a class="dropdown-item text-md">Creator</a>
                      </li>
                      <li class="selected" (click)="updateUserRole('viewer')">
                        <a class="dropdown-item text-md">Viewer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-12 px-4 ">
                <div class="row ac-overview">
                  <div class="col-md-12 px-0">
                    <p class="text-lg text-gray-2 pb-0 mb-2">Email Preferences</p>
                    <p class="text-xs text-gray-3 pb-1">You're currently subscribed
                      to our email list.</p>
                    <div class="form-check mb-3 tableCustom">
                      <div class="tableCheck">
                        <input *ngIf="profileForm?.controls['subscribe_email_changes'].value == true" checked="checked"
                          class="form-check-input shadow-none" type="checkbox"
                          formControlName="subscribe_email_changes">
                        <input *ngIf="!profileForm?.controls['subscribe_email_changes'].value"
                          class="form-check-input shadow-none" type="checkbox"
                          formControlName="subscribe_email_changes">
                      </div>
                      <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                        Account Changes
                      </label>
                    </div>
                    <div class="form-check mb-3 tableCustom" *ngIf="profileForm?.controls['subscribe_email_changes'].value == true">
                      <div class="tableCheck">
                        <input *ngIf="profileForm?.controls['get_pronto_newsletter'].value == true" checked="checked"
                          class="form-check-input shadow-none" type="checkbox" formControlName="get_pronto_newsletter">
                        <input *ngIf="!profileForm?.controls['get_pronto_newsletter'].value"
                          class="form-check-input shadow-none" type="checkbox" formControlName="get_pronto_newsletter">
                      </div>
                      <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                        Prontopia Newsletter
                      </label>
                    </div>
                    <div class="form-check mb-3 tableCustom" *ngIf="!profileForm?.controls['subscribe_email_changes'].value">
                      <div class="tableCheck">
                        <input disabled
                          class="form-check-input shadow-none" type="checkbox">
                      </div>
                      <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                        Prontopia Newsletter
                      </label>
                    </div>
                    <div class="my-4 pb-2" *ngIf="subscribedNews == true || subscribedEmail == true">
                      <a href="#" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btnShadow"
                        data-bs-toggle="modal" data-bs-target="#subscription-popup" (click)="unSubscribed()">Unsubscribe</a>
                    </div>
                    <div class="my-4 pb-2" *ngIf="subscribedNews == false && subscribedEmail == false">
                      <a href="#" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btnShadow"
                        data-bs-toggle="modal" data-bs-target="#subscription-popup" (click)="subscribed()">Subscribe</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 ac-overview mt-4  d-flex justify-content-between">
                <div class="px-0 mx-0">
                  <p class="text-lg text-black weight-600 pb-0 mb-2">Archive Account</p>
                  <p class="text-xs text-gray-3 pb-1">This will deactivate the user's
                    account and the user will no longer be able to access his account.
                  </p>
                </div>
                <div class="px-0 mx-0" *ngIf="profileData?.status == 'active'">
                  <a href="javascript:void(0)" (click)="openDeactivateModal()"
                    class="weight-normal btn btn-secondary btn-round-6 px-3 py-2 btn-180">Deactivate Account</a>
                </div>
                <div class="px-0 mx-0" *ngIf="profileData?.status == 'inactive'">
                  <a href="javascript:void(0)"  (click)="openActivateModal()"
                    class="weight-normal btn btn-secondary btn-round-6 px-3 py-2 btn-180">Activate Account</a>
                </div>
              </div>
              <div class="col-md-12 ac-overview mt-4  d-flex justify-content-between">
                <div class="px-0 mx-0">
                  <p class="text-lg text-black weight-600 pb-0 mb-2">Reset Password</p>
                  <p class="text-xs text-gray-3 pb-1">This will create a reset password
                    link for the user.</p>
                </div>
                <div class="px-0 mx-0">
                  <a href="javascript:void(0)" class="weight-normal btn btn-secondary btn-round-6 py-2 btn-180" style="padding-left:1.8rem; padding-right:1.8rem;" (click)="resetUserPwd()">Reset
                    Password</a>

                </div>
              </div>

              <div class="flex-grow-1 overflow-auto">
                <div class="d-flex justify-content-between mt-4">
                  <div class="px-0 mx-0">
                    <p class="pb-0 mb-0">
                      <span class="text-lg">Brands</span>
                    </p>
                    <p class="text-xs text-gray-3 pb-1">
                      Total Brands
                      <b class="weight-600">{{userBrandsList?.length}}</b>
                    </p>

                  </div>
                  <div class="px-0 mx-0">
                    <a href="javascript:void(0)" (click)="openAssignNewBrandDrawer()"
                      class="weight-normal btn btn-secondary btn-round-6 px-3 py-2 btn-180">Assign New Brand</a>

                  </div>
                </div>
                <div class="overflow-auto">
                  <table class="table tableCustom table-hover mb-0">
                    <thead>
                      <tr>
                        <!-- <th scope="col" class="tableCheck">
                          <div class="custom-switch">
                            <input type="checkbox" class="check form-check-input" id="checkAll" />
                          </div>
                        </th> -->
                        <th scope="col" class="uname-width">Name</th>
                        <th scope="col">Website</th>
                        <th scope="col">Industry</th>
                        <th scope="col">Published Videos</th>
                        <th scope="col">Media Uploads</th>
                        <th scope="col" class="tableAction">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let userB of userBrandsList">
                        <!-- <td scope="row" class="tableCheck">
                          <div class="custom-switch">
                            <input type="checkbox" class="check form-check-input" />
                          </div>
                        </td> -->
                        <td>
                          <a class="b_userName" [routerLink]="['/account-setting/manage-brand/account/', userB?.brand?._id?.$oid]" href="javascript:void(0)">{{userB?.brand?.name}}</a>
                        </td>
                        <td>{{userB?.brand?.website}}</td>
                        <td>{{userB?.brand?.industry}}</td>
                        <td>{{userB?.brand?.published_video_count}}</td>
                        <td>0</td>

                        <td class="tableAction">
                          <a href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                            <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                fill="#7A7A7A"></path>
                            </svg>
                          </a>
                          <ul class="margin-0 dropdown-menu add_location page-shadow" aria-labelledby="dropdownMenuButton1">
                            <li class="link mb-0" (click)="showDeleteBrandModel(userB?._id?.$oid)"><a >Delete</a></li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div>
                <p class="text-lg text-black weight-600 mt-4 mb-0 weight-normal">
                  Permissions</p>
                <span class="text-xs text-gray-3 d-inline-block mt-1 mb-3">share on
                  social accounts</span>
                <div class="d-flex justify-content-start">
                  <div class="form-check mb-2 tableCustom w-auto me-4">
                    <div class="tableCheck">
                      <input *ngIf="profileForm?.controls['allow_share_company_account'].value == true"
                        formControlName="allow_share_company_account" checked="checked" class="form-check-input  shadow-none "
                        type="checkbox">
                      <input *ngIf="profileForm?.controls['allow_share_company_account'].value == false"
                        formControlName="allow_share_company_account" class="form-check-input  shadow-none " type="checkbox">
                    </div>
                    <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                      Company Accounts
                    </label>
                  </div>
                  <div class="form-check mb-2 tableCustom w-auto me-4">
                    <div class="tableCheck">
                      <input *ngIf="profileForm?.controls['allow_share_brand_account'].value == true"
                        formControlName="allow_share_brand_account" checked="checked" class="form-check-input  shadow-none "
                        type="checkbox">
                      <input *ngIf="profileForm?.controls['allow_share_brand_account'].value == false"
                        formControlName="allow_share_brand_account" class="form-check-input  shadow-none " type="checkbox">
                    </div>
                    <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                      Brand Accounts
                    </label>
                  </div>
                  <div class="form-check mb-2 tableCustom w-auto me-4">
                    <div class="tableCheck">
                      <input *ngIf="profileForm?.controls['allow_share_personal_account'].value == true"
                        formControlName="allow_share_personal_account" checked="checked" class="form-check-input  shadow-none "
                        type="checkbox">
                      <input *ngIf="profileForm?.controls['allow_share_personal_account'].value == false"
                        formControlName="allow_share_personal_account" class="form-check-input  shadow-none " type="checkbox">
                    </div>
                    <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                      Personal Accounts
                    </label>
                  </div>
                </div>
                <span class="text-xs text-gray-1 my-3 d-inline-block">Downloads</span>
                <div class="form-check mb-4 tableCustom">
                  <div class="tableCheck">
                    <input *ngIf="profileForm?.controls['allow_download_videos'].value == true"
                      formControlName="allow_download_videos" checked="checked" class="form-check-input  shadow-none "
                      type="checkbox">
                    <input *ngIf="profileForm?.controls['allow_download_videos'].value == false"
                      formControlName="allow_download_videos" class="form-check-input  shadow-none " type="checkbox">
                  </div>
                  <label class="form-check-label text-sm text-gray-1 mt-1" for="flexCheckDefault">
                    Download Video Access
                  </label>
                </div>
              </div>
              <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-5" style="max-width: 200px">
                <span class="py-1 d-block">Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div>
        </div>
    </div>
</section> -->


<!-- Deactivate Modal pop-up Start-->

<nz-modal [(nzVisible)]='isDeactivateModalVisible' nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=true
  (nzOnCancel)="closeDeactivateModal()">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h5>Are you sure you want to deactivate the account of the selected user?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">The selected user will no longer have access to the Prontopia
          account.</p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeDeactivateModal()">Cancel</a>
        <a href="javascript:void(0)" data-bs-dismiss="modal" id="sidebarCollapse"
          class="mt-3 text-gray-3 text-sm" (click)="closeDeactivateModal();deactivateUser()">Deactivate</a>
      </div>
    </div>
  </div>
</nz-modal>

<nz-modal
[(nzVisible)]='isactivateModalVisible' nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]=true
  (nzOnCancel)="closeActivateModal()">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <h5>Are you sure you want to activate the account of the selected user?</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">The selected user will get access to his Prontopia
          account.</p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="closeActivateModal()">Cancel</a>
        <a href="javascript:void(0)" data-bs-dismiss="modal" id="sidebarCollapse"
          class="mt-3 text-gray-3 text-sm" (click)="closeActivateModal();activateUser()">Activate</a>
      </div>
    </div>
  </div>
</nz-modal>

<!-- Deactivate Modal pop-up End-->


<!-- Assign New Brand Drawer Start-->
<nz-drawer [nzClosable]="false" [nzVisible]="showAssignNewBrandDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeAssignNewBrandDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav>
      <form [formGroup]="assignBrandToUserForm" *ngIf="assignBrandToUserForm" (ngSubmit)="assignBranToUser()">
        <div class="sidebar-header">
          <h3 class="text-gray-2 weight-700">Assign Brand</h3>
          <div class="closeRightSideBar" (click)="closeAssignNewBrandDrawer()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                fill="#F2F2F5" />
              <path
                d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                fill="#A7ABB6" />
            </svg>
          </div>
        </div>
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
          <div class="formItm">
            <input readonly id="FName" type="text" value="{{assignBrandToUserForm?.controls['name']?.value}}" placeholder="Estelle" name="textfeild" required="" />
            <!-- <label for="FName" class="text-sm">{{assignBrandToUserForm?.controls['name']?.value}}</label> -->
          </div>
          <div class="formItm down_arrow pt-0">
            <div class="lib-access">
              <p class="weight-600 my-4">Assign Brand</p>
              <div class="search-sec p-3 mt-3">
                <p class="text-md pb-3">

                  <mat-form-field>
                    <mat-label style="position: absolute;">Search Brands</mat-label>
                    <input formControlName="searchBrandText"
                     placeholder="Search Brands"
                      #brandListAutoComplete
                      #searchBrandField
                      class="searchBrandAutoComplete"
                      (ngModelChange)="this.modelBrandSearchChanged.next($event)"
                      matInput
                      [matAutocomplete]="autoCompleteBrand">
                    <mat-autocomplete #autoCompleteBrand="matAutocomplete">
                      <mat-option *ngIf="isBrandSearchLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isBrandSearchLoading">
                        <mat-option *ngFor="let brand of brandList?.result" [value]="brand.name"
                          (click)="setAddUserBrands(brand)">
                          <span><b>{{brand.name}}</b></span>
                        </mat-option>

                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <span class="error-message" *ngIf="assignBrandSubmit && (selectedAddUserBrands == undefined || selectedAddUserBrands.length == 0)">
                    Please assign atleast one brand.
                  </span>
                </p>

                <!-- Search Brands
            <button type="button" class="btn-close float-end" aria-label="Close"></button>
           -->
                <ul class="p-0" *ngIf="selectedAddUserBrands != undefined">
                  <div class="part1">
                    <li class="d-block text-md" *ngFor="let selectedBrand of selectedAddUserBrands"> {{selectedBrand.name}}
                      <button type="button" (click)="removeAddUserBrands(selectedBrand)" class="btn-close float-end"
                        aria-label="Close"></button>
                    </li>
                  </div>
                  <a *ngIf="selectedAddUserBrands?.length > 3" href="javascript:void(0)" class="vall text-md">View All</a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="side-footer side-content d-flex align-items-center ps-0" style="position:absolute; bottom:30px;">
          <button  class="btn btn-primary btn-round-6 d-inline-block small-pad px-5">
            <span class="py-1 d-block">Assign Brand</span>
          </button>
        </div>
      </form>
    </nav>
  </ng-container>
</nz-drawer>

<!-- Assign New Brand Drawer End-->


<!--resert password drawer starts here-->
<nz-drawer [nzClosable]="false" [nzVisible]="showResetPasswordDrawer" [nzPlacement]="placementUser"
  (nzOnClose)="closeResetPasswordDrawer()" [nzWidth]="478">
  <ng-container *nzDrawerContent>
    <nav id="resetPasswordUser">
      <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Reset Password Link</h3>
        <div class="closeRightSideBar" (click)="closeResetPasswordDrawer()">
          <img src="../assets/images/close-drawer.svg" alt="close">
        </div>
      </div>
      <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
        <p class="text-md">
          The reset password link is created and copied to the clipboard.
        </p>
        <a href="#" class="text-primary-3 text-lg">https://pronto.ai/resetpassword/erifhdjfdah</a>

        <button class="btn btn-primary btn-round-6 d-inline-block small-pad px-3 mt-4" >
          <span class="py-1 d-block">Send Link to User’s Email</span>
        </button>
      </div>
    </nav>
  </ng-container>
</nz-drawer>

<!-- Modal pop-up Start-->
<nz-modal
[(nzVisible)]="showDeleteBrandModal"
nzCentered
[nzWidth]="566"
[nzFooter]=null
[nzClosable] = false
(nzOnCancel)="showDeleteBrandModal = false"
>

 <div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">

      <div class="modal-body">
          <button type="button" class="btn-close cust-close closeRightBtn" (click)="showDeleteBrandModal = false"></button>

          <h5>Are you sure you want to Delete this Brand?</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">You'll not be able to watch this Brand again.
              </p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="showDeleteBrandModal = false">Cancel</a>
        <a href="javascript:void(0)"
               class="mt-3 text-gray-3 text-sm" (click)="deleteBrand()">Delete</a>
      </div>
  </div>
</div>
</nz-modal>
<!--resert password drawer ends here-->