
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { cSessionService } from '../../../services/session.service';

import { address } from '../../../models/common';
import { FtCreateVideoManagerService } from  './../../../Managers/ft-create-video-manager.service';
declare var $: any;

@Component({
  selector: 'app-ft-other-setting',
  templateUrl: './ft-other-setting.component.html',
  styleUrls: ['./ft-other-setting.component.scss']
})
export class FtOtherSettingComponent implements OnInit, OnDestroy {

  sSessionId: string = "";
  sUserEmail: string = "";
  fOtherSettingForm: FormGroup;
  oSavedFormData: any;
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  bURLToggle: boolean = true;
  bAddressToggle: boolean = false;
  bPhoneToggle: boolean = false;
  sVideoId: string;
  bCreateVideoMode: boolean = true;
  sDisableEditModeToggle: string = "false";
  oOverviewForm: any;
  oLooknFeelForm: any;
  isError: boolean = false;

  constructor(private oFormBuilder: FormBuilder, private oRouter: Router, private oSessionService: cSessionService, public videoManager: FtCreateVideoManagerService) {

  }
  sub: Subscription;
  sub2:Subscription;
  ngOnInit() {

    this.sub2 = this.videoManager.previousSubmit.subscribe(emit => {
      this.oRouter.navigateByUrl('/ft/content');
    });


    this.OtherSettingComponent_InitializeJqueryCode()

    
    this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();
    this.sSessionId  = this.oSessionService.cSessionService_GetGuestSession();
    this.sub = this.videoManager.submitForm.subscribe(c => {
     
      this.save();
    });
    this.OtherSettingComponent_InitializeOverviewForm();
  }
  ngOnDestroy() {
    if(this.sub2)
    this.sub2.unsubscribe();
    if(this.sub)
    this.sub.unsubscribe();
  }

  OtherSettingComponent_InitializeOverviewForm() {
    console.log(this.videoManager.selectedVideo);

    console.log('this.videoManager.selectedVideo');
    console.log(this.videoManager.selectedVideo);
    if (!this.videoManager.selectedVideo?.address) {
      this.videoManager.selectedVideo.address = new address();
    }

    if(this.videoManager.selectedVideo?.phone && this.videoManager.selectedVideo?.phone !='')
    {
      this.bPhoneToggle = true;
    }
    this.fOtherSettingForm = this.oFormBuilder.group(
      {

        session_id: this.sSessionId,
        email: this.sUserEmail,
        url: [this.videoManager.selectedVideo.ci_dict?.url, [
          Validators.required,
          Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
        ]],
        line1: [this.videoManager.selectedVideo.ci_dict?.address?.line1,  ],
        line2: [this.videoManager.selectedVideo.ci_dict?.address?.line2,  ],
        city: [this.videoManager.selectedVideo.ci_dict?.address?.city,  ],
        state: [this.videoManager.selectedVideo.ci_dict?.address?.state,  ],
        postal_code: [this.videoManager.selectedVideo.ci_dict?.address?.postal_code,  ],
        country: [this.videoManager.selectedVideo.ci_dict?.address?.country,  ],

        phone: [this.videoManager.selectedVideo.phone],
        bDisplayUrl: this.bURLToggle,
        bDisplayAddress: this.bAddressToggle,

       
        bDisplayPhone: this.bPhoneToggle,
      });
  }

  save() {


    //clicking without validation dont send it forward.

    console.log(this.fOtherSettingForm,"otherform");
    if (this.fOtherSettingForm.valid) {



      this.videoManager.selectedVideo.session_id = this.fOtherSettingForm.value.session_id;
      this.videoManager.selectedVideo.email = this.fOtherSettingForm.value.email;


      this.updateVideoLocally();
      console.log(this.videoManager.selectedVideo, "othervideomanager");
      this.videoManager.createVideo(this.videoManager.selectedVideo).then(res => {
        
        console.log("Video Response Success"+JSON.stringify(res));
        
        this.videoManager.selectedVideo = this.videoManager.selectedVideo;

        this.oRouter.navigateByUrl("/ft/preview");
      
      });
    } else {
      this.fOtherSettingForm.markAllAsTouched();
    }
  }




  private updateVideoLocally() {
    if (this.bURLToggle) {
      this.videoManager.selectedVideo.url = this.fOtherSettingForm.value.url;
    }
    if (this.bAddressToggle) {
      this.videoManager.selectedVideo.address = new address();

      this.videoManager.selectedVideo.address.line1 = this.fOtherSettingForm.value.line1;
      this.videoManager.selectedVideo.address.line2 = this.fOtherSettingForm.value.line2;
      this.videoManager.selectedVideo.address.city = this.fOtherSettingForm.value.city;
      this.videoManager.selectedVideo.address.state = this.fOtherSettingForm.value.state;
      this.videoManager.selectedVideo.address.postal_code = this.fOtherSettingForm.value.postal_code;
      this.videoManager.selectedVideo.address.country = this.fOtherSettingForm.value.country;
    } else {
      this.videoManager.selectedVideo.address = undefined;
    }

    if (this.bPhoneToggle) {
      this.videoManager.selectedVideo.phone = this.fOtherSettingForm.value.phone;
    }
  }

  //Handling Toggle Checks on the Page Dynamically 
  OtherSettingComponent_PhoneToggle(event: any) {

    this.fOtherSettingForm.controls['phone'].setValue('');
    this.bPhoneToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayPhone: this.bPhoneToggle });
    if (this.bPhoneToggle) {


      this.fOtherSettingForm.controls['phone'].setValidators([
        Validators.required,

      ]);
    } else {
      this.fOtherSettingForm.controls['phone'].clearValidators();
    }


     this.fOtherSettingForm.controls['phone'].updateValueAndValidity();




  }
  OtherSettingComponent_AddressToggle(event: any) {
    this.bAddressToggle = event.target.checked;
    this.fOtherSettingForm?.patchValue({ bDisplayAddress: this.bAddressToggle });

    if (this.bAddressToggle) {


      this.fOtherSettingForm.controls['line1'].setValidators([
        Validators.required
      ]);
    
      this.fOtherSettingForm.controls['city'].setValidators([
        Validators.required
      ]);
      this.fOtherSettingForm.controls['state'].setValidators([
        Validators.required
      ]);
      this.fOtherSettingForm.controls['postal_code'].setValidators([
        Validators.required
      ]);
       


    } else {
      this.fOtherSettingForm.controls['line1'].clearValidators();
      this.fOtherSettingForm.controls['line2'].clearValidators();
      this.fOtherSettingForm.controls['city'].clearValidators();
      this.fOtherSettingForm.controls['state'].clearValidators();
      this.fOtherSettingForm.controls['postal_code'].clearValidators();
      this.fOtherSettingForm.controls['country'].clearValidators();


    }


    this.fOtherSettingForm.controls['line1'].updateValueAndValidity();

    this.fOtherSettingForm.controls['line2'].updateValueAndValidity();
     this.fOtherSettingForm.controls['city'].updateValueAndValidity();
     this.fOtherSettingForm.controls['state'].updateValueAndValidity();
     this.fOtherSettingForm.controls['postal_code'].updateValueAndValidity();
     this.fOtherSettingForm.controls['country'].updateValueAndValidity();






  }
  OtherSettingComponent_URLToggle(event: any) {
    this.bURLToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({ bDisplayUrl: this.bURLToggle });
    console.log(this.bURLToggle)
    if (this.bURLToggle) {
      console.log(this.fOtherSettingForm.controls['url']);

      this.fOtherSettingForm.controls['url'].setValidators([
        Validators.required,
        Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
      ]);
    } else {
      this.fOtherSettingForm.controls['url'].clearValidators();
    }

    console.log(this.fOtherSettingForm.controls['url']);
    this.fOtherSettingForm.controls['url'].updateValueAndValidity();
  }



  // OtherSettingComponent_ToggleView(event) {
  //   if (event.target.checked) {
  //     this.oRouter.navigateByUrl('/ft/overview');
  //   }
  //   else {
  //     this.oRouter.navigateByUrl('edit-video/timeline-view');
  //   }
  // }
  OtherSettingComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text =
      `{
        $(document).ready(function () {
          $('.address').change(function () {
              if (this.checked)
                  $('.address-div').fadeIn('slow');
              else
                  $('.address-div').fadeOut('slow');
          });

          $('.url').change(function () {
              if (this.checked)
                  $('.url-div').fadeIn('slow');
              else
                  $('.url-div').fadeOut('slow');

          });

          $('.phone').change(function () {
              if (this.checked)
                  $('.phone-div').fadeIn('slow');
              else
                  $('.phone-div').fadeOut('slow');

          });

      });
    }`;
    document.body.appendChild(chatScript);
  }

  _keyPress(event: any) {

    let newVal = this.fOtherSettingForm.controls['phone'].value.replace(/\D/g, '');



    this.fOtherSettingForm.controls['phone'].setValue(newVal);
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);


    if (!pattern.test(inputChar)) {



      event.preventDefault();

    }
  }
}

