import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from './../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { environment } from './../../../environments/environment';
import { cSessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-myvideos-sidebar',
  templateUrl: './myvideos-sidebar.component.html',
  styleUrls: ['./myvideos-sidebar.component.scss']
})
export class MyvideosSidebarComponent implements OnInit, OnDestroy  {
  showMenu : string;
  bLocales: boolean;
  constructor(private oRouter : Router,public configManger:ConfigManagerService,public onboarding:OnboardingManagerService, public oSessionService: cSessionService) { 
    this.showMenu = this.oRouter.url;
    console.log("Router : ==> ",this.showMenu);
  }

  ngOnInit(): void {
    this.MyVideosComponent_LocalesCheck();
  }
  ngOnDestroy() {
    this.oSessionService.isSidebarActive = true;
  }
  MyVideosComponent_LocalesCheck()
  {

    console.log("----locale---"+this.configManger.bDisplayLocales);
    this.bLocales = this.onboarding.profile.localization_enabled;
   
  }
  
   toggleSideBarNew()
   {
     var sidebarleft = document.getElementById('sidebar-left');
    sidebarleft.classList.toggle("collapsed");
    console.log(sidebarleft,"clicked");
    
  if(this.oSessionService.isSidebarActive){
    this.oSessionService.isSidebarActive = false;
  }
  else{
    
    this.oSessionService.isSidebarActive = true;
  }

  console.log("isActive2",  this.oSessionService.isSidebarActive);
   }
  }
