<body class="sidbar_contentslide">
    <header class="header header-1 drop-shadow headerWithRightSide">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a routerLink="/login">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav"
                    aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation"> <span
                        class="text-md">Menu</span>
                </button>
                <div class="collapse navbar-collapse " id="mainNav">

                    <div class="d-flex justify-content-end align-items-center w-100">

                        <ul class="navbar-nav mb-2 mb-lg-0 pt-0 divader-left">
                            <li class="nav-item dropdown"> <a class="nav-link active text-sm text-gray-2" href="#"
                                    id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <!-- <img src="../assets/images/accountSetting/ac-login-img.png" alt="ac-login-img"
                                        class="me-2" /> -->
                                    My Account <svg class="ms-1" width="8" height="4" viewBox="0 0 8 4" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0.509553 0.0874255C0.392986 -0.0291418 0.203993 -0.0291418 0.0874254 0.0874255C-0.0291418 0.203993 -0.0291418 0.392986 0.0874254 0.509553L3.1346 3.55672C3.61255 4.03467 4.38745 4.03467 4.8654 3.55672L7.91257 0.509553C8.02914 0.392986 8.02914 0.203993 7.91257 0.0874254C7.79601 -0.029142 7.60701 -0.0291416 7.49045 0.0874256L4.44328 3.1346C4.19846 3.37941 3.80154 3.37941 3.55672 3.1346L0.509553 0.0874255Z"
                                            fill="#7A7A7A" />
                                    </svg>
                                </a>

                                <ul class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" href="javascript:void(0)" (click)="NavigateToDashboard()">Dashboard</a></li>
                                    <li><a class="dropdown-item" [routerLink]="['/account-setting']">Account Settings</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)" (click)="NavigateToBusinessProfile()">Business Profile</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)" (click)="LogOut()">Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <main class="main">
        <section class="page pageWithRightSide dashboard dashboardFirstTimeUser main-body bg-gray-7 h-100 animationBg"
            style="background-image: url(../assets/images/dashboard_FirstTimeUser/dashboard_FirstTimeUserTopBg.png);">
            <div class="container">
                <div class="text-center">
                    <div class="dashboard_FirstTimeUserTop">
                        <h1 class="weight-300 mt-5 text-gray-1 h1Large">
                            Create My <b class="weight-700">First Video.</b>
                        </h1>
                        <h5 class="weight-400 mt-2 text-gray-3">Your video in 60 seconds or less. Like magic.</h5>
                        <div class="mt-4 d-flex flex-wrap justify-content-center">
                            <button (click)="FirstTimeDashboardComponent_NavigateToCreateVideo()" class="btn weight-600 btn-primary btn-round-6 px-4 py-3 d-flex align-items-center">
                                <!-- data-sidebar="#getStartedwithPronto" data-custom-select -->
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.68378 4.98148C7.46533 4.17021 5.83327 5.04255 5.83327 6.50508V9.51876C5.83327 10.9813 7.46533 11.8536 8.68378 11.0424L10.9469 9.53552C12.0355 8.81072 12.0355 7.21312 10.9469 6.48832L8.68378 4.98148ZM6.83335 6.50508C6.83335 5.84029 7.57519 5.44378 8.12903 5.81253L10.3922 7.31937C10.887 7.64883 10.887 8.37501 10.3922 8.70447L8.12903 10.2113C7.57519 10.5801 6.83335 10.1835 6.83335 9.51876V6.50508Z"
                                        fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.50015 15.9906C12.9188 15.9906 16.5008 12.4132 16.5008 8.00021C16.5008 3.58721 12.9188 0.00976562 8.50015 0.00976562C4.08152 0.00976562 0.499512 3.58721 0.499512 8.00021C0.499512 12.4132 4.08152 15.9906 8.50015 15.9906ZM8.50015 14.9918C12.3664 14.9918 15.5007 11.8616 15.5007 8.00021C15.5007 4.13883 12.3664 1.00857 8.50015 1.00857C4.63385 1.00857 1.49959 4.13883 1.49959 8.00021C1.49959 11.8616 4.63385 14.9918 8.50015 14.9918Z"
                                        fill="white" />
                                </svg>
                                <span class="ms-2">Create a New Video</span>
                            </button>
                        </div>
                        <div class="pt-5" *ngIf="false">
                            <p class="m-0 text-sm text-gray-3">Need Inspiration?</p>
                            <a class="text-sm text-primary-4" href="javascript:void(0)">Browse what others are creating...</a>
                        </div>
                    </div>
                    <div class="pt-4">
                        <img src="../assets/images/dashboard_FirstTimeUser/dashboard_FirstTimeUserImg.png"
                            alt="dashboard_FirstTimeUserImg" class="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    </main>
    <nav id="getStartedwithPronto" class="rightSideBar rightSideBarRight sidebar-block nowShadow active">
        <div class="sidebar-header">
            <h3 class="text-gray-2 weight-700 w-50">Get <span class="text-primary-4">Started</span> with Prontopia </h3>
            <div class="closeRightSideBar" (click)="FirstTimeDashboardComponent_DismissSidePanel()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                    <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                </svg>

            </div>
        </div>
        <div class="border-top">
            <div class="side-content pt-0 ">
                <div class="getStartedwithProntoVideos">
                    <div class="getStartedwithProntoVideosItm">
                        <div class="getStartedwithProntoVideosItmCount"><b class="text-sm text-gray-2 weight-600">01.</b></div>
                        <div class="getStartedwithProntoVideosItmBody">
                            <b class="text-sm text-gray-2 weight-600">Create your first video</b>
                            <p class="mt-1 text-xs m-0 text-gray-3">
                                Jump right in and start creating your first
                                video to get familiar with Prontopia .
                            </p>
                        </div>
                        <div class="getStartedwithProntoVideosItmIcon">
                            <svg width="63" height="46" viewBox="0 0 63 46" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="5" y="5" width="58" height="39" rx="6" fill="url(#paint0_linear_3789_67130)" />
                                <circle cx="53" cy="21" r="0.75" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="1.5" cy="11.5" r="1.25" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="46.5" cy="43.5" r="1.25" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="18" cy="11" r="0.75" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="13.5" cy="29.5" r="0.25" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="30.5" cy="0.5" r="0.25" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="45.5" cy="31.5" r="0.25" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="19" cy="45" r="0.75" stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="34" cy="25" r="11.5" fill="white" stroke="#F2F2F5" />
                                <path
                                    d="M30.667 28.0843C30.667 28.6034 31.0444 28.8159 31.5059 28.557L36.9875 25.4731C37.449 25.2135 37.449 24.7885 36.9875 24.5289L31.5059 21.4447C31.0444 21.1852 30.667 21.3975 30.667 21.9174V28.0843Z"
                                    fill="#FF8369" />
                                <defs>
                                    <linearGradient id="paint0_linear_3789_67130" x1="32" y1="-20" x2="34" y2="44"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#DCDCDC" />
                                        <stop offset="1" stop-color="#F2F2F5" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="getStartedwithProntoVideosItm">
                        <div class="getStartedwithProntoVideosItmCount"><b class="text-sm text-gray-2 weight-600">02.</b></div>
                        <div class="getStartedwithProntoVideosItmBody">
                            <b class="text-sm text-gray-2 weight-600">Share your video</b>
                            <p class="mt-1 text-xs m-0 text-gray-3">
                                Once you love it, share your Prontopia  creation on any of your favorite social channels.
                            </p>
                        </div>
                        <div class="getStartedwithProntoVideosItmIcon">
                            <svg width="68" height="68" viewBox="0 0 68 68" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="13.6182" y="18" width="37" height="28" rx="6"
                                    fill="url(#paint0_linear_3789_67145)" />
                                <path
                                    d="M49.3053 7.40458V2.97027C49.3045 2.94534 49.3182 2.923 49.3414 2.91183C49.3638 2.90152 49.3904 2.90581 49.4085 2.92214L58.566 10.55C58.5806 10.562 58.5892 10.5792 58.5892 10.5981C58.5892 10.617 58.5806 10.6342 58.566 10.6462L49.4085 18.2706C49.3895 18.2877 49.362 18.2912 49.3388 18.28C49.3165 18.2688 49.3027 18.2448 49.3053 18.219V14.0458C49.3053 13.8559 49.1687 13.6943 48.9822 13.6608C48.4276 13.5614 44.2693 13.6068 41.118 14.5C37.2957 15.5834 34.2484 18.8 31.8519 22.0576C31.8304 22.0877 31.7926 22.1015 31.7573 22.092C31.7212 22.0834 31.6946 22.0533 31.6903 22.0164C31.5861 20.7733 34.4021 13.5427 40.5 10C42.8502 8.63462 46.2137 7.40458 49.3053 7.40458Z"
                                    fill="#FF8369" />
                                <circle cx="32.6182" cy="32" r="6" fill="white" />
                                <g clip-path="url(#clip0_3789_67145)">
                                    <path
                                        d="M30.9512 33.5412C30.9512 33.8007 31.1399 33.907 31.3706 33.7775L34.1114 32.2356C34.3422 32.1058 34.3422 31.8933 34.1114 31.7635L31.3706 30.2214C31.1399 30.0916 30.9512 30.1978 30.9512 30.4577V33.5412Z"
                                        fill="#BABCBE" />
                                </g>
                                <circle cx="23.0283" cy="45.4099" r="0.75" transform="rotate(130.655 23.0283 45.4099)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="57.7334" cy="40.1158" r="1.25" transform="rotate(130.655 57.7334 40.1158)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="48.4473" cy="49.784" r="0.25" transform="rotate(130.655 48.4473 49.784)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="9.32422" cy="43.7079" r="0.25" transform="rotate(130.655 9.32422 43.7079)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="57.3232" cy="23.7079" r="0.25" transform="rotate(130.655 57.3232 23.7079)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="22.1797" cy="12.0661" r="0.75" transform="rotate(130.655 22.1797 12.0661)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <defs>
                                    <linearGradient id="paint0_linear_3789_67145" x1="30.8423" y1="0.0512833"
                                        x2="32.4579" y2="45.9881" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#DCDCDC" />
                                        <stop offset="1" stop-color="#F2F2F5" />
                                    </linearGradient>
                                    <clipPath id="clip0_3789_67145">
                                        <rect width="4" height="4" fill="white" transform="translate(30.6182 30)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="getStartedwithProntoVideosItm">
                        <div class="getStartedwithProntoVideosItmCount"><b class="text-sm text-gray-2 weight-600">03.</b></div>
                        <div class="getStartedwithProntoVideosItmBody">
                            <b class="text-sm text-gray-2 weight-600">Customize your videos and update your profile</b>
                            <p class="mt-1 text-xs m-0 text-gray-3">
                                After you get comfortable, explore more Prontopia  features to deliver production-level content.
                            </p>
                        </div>
                        <div class="getStartedwithProntoVideosItmIcon">
                            <svg width="48" height="53" viewBox="0 0 48 53" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M25.3076 18.709C30.1404 18.709 34.0576 14.7918 34.0576 9.95898C34.0576 5.12618 30.1404 1.20898 25.3076 1.20898C20.4748 1.20898 16.5576 5.12618 16.5576 9.95898C16.5576 14.7918 20.4748 18.709 25.3076 18.709ZM22.8076 21.25H27.8076C31.786 21.25 35.6015 22.8295 38.414 25.6436C41.2279 28.4564 42.8076 32.2719 42.8076 36.25C42.8076 36.5812 42.6763 36.9 42.442 37.1344C42.2076 37.3687 41.8889 37.5 41.5576 37.5H9.0576C8.72636 37.5 8.40762 37.3687 8.17324 37.1344C7.93887 36.9 7.80762 36.5813 7.80762 36.25C7.80762 32.2716 9.38714 28.4561 12.2012 25.6436C15.014 22.8297 18.8295 21.25 22.8076 21.25Z"
                                    fill="url(#paint0_linear_3782_66752)" />
                                <circle cx="23.3662" cy="40.1367" r="0.75" transform="rotate(90 23.3662 40.1367)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="46.2461" cy="13.5098" r="1.25" transform="rotate(90 46.2461 13.5098)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="45.5" cy="26.8926" r="0.25" transform="rotate(90 45.5 26.8926)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="11.8613" cy="47.7734" r="0.25" transform="rotate(90 11.8613 47.7734)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="35.2451" cy="1.32812" r="0.25" transform="rotate(90 35.2451 1.32812)"
                                    stroke="#7A7A7A" stroke-width="0.5" />
                                <circle cx="1" cy="15.3926" r="0.75" transform="rotate(90 1 15.3926)" stroke="#7A7A7A"
                                    stroke-width="0.5" />
                                <circle cx="25.3076" cy="41" r="11.5" fill="white" stroke="#F2F2F5" />
                                <path
                                    d="M23.5255 43.0403L21.5359 40.9753C21.1978 40.6243 20.6456 40.6243 20.3074 40.9753L20.3074 40.9753C19.9743 41.321 19.9743 41.8778 20.3074 42.2235L20.3074 42.2235L22.9077 44.9224C23.2483 45.2759 23.8041 45.2759 24.1447 44.9224L24.1453 44.9217L29.5578 39.2602C29.5579 39.2601 29.5581 39.2599 29.5582 39.2598C29.8909 38.914 29.8908 38.3588 29.5578 38.0132L29.5578 38.0132C29.2197 37.6623 28.6675 37.6623 28.3293 38.0132L28.3286 38.014L23.5255 43.0403Z"
                                    fill="#FF8369" stroke="#FF8369" stroke-width="0.5" />
                                <defs>
                                    <linearGradient id="paint0_linear_3782_66752" x1="24.1007" y1="-22.0545"
                                        x2="26.9667" y2="37.42" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#DCDCDC" />
                                        <stop offset="1" stop-color="#F2F2F5" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="overlay sidebarOverlay"></div>
    <div class="overlay sidebarOverlay2"></div>
</body>